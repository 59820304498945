<!DOCTYPE html>

@if (!('IS_MOBILE' | globals) && viewIsInitialized && loginModel.accessToken !== null) {
<div class="main-side-bar" [class.collapse]="appSettingsService.appSettings.isSidebarMenuMinimized"
     @shortFadeInOutAnimation>
    <ng-container [ngTemplateOutlet]="sideBarCornerTemplate"></ng-container>
    <ng-container [ngTemplateOutlet]="sideBarCornerTemplate"></ng-container>
    <button type="button" class="image-button collapse-menu-button ripple-effect ripple-center"
            (click)="appSettingsService.appSettings.isSidebarMenuMinimized = !appSettingsService.appSettings.isSidebarMenuMinimized">
        <i class="icon-menu"></i>
    </button>
    <img class="bar-logo" [src]="appSettingsService.isDarkMode ? 'assets/vectors/logo-icon-dark.svg' : 'assets/vectors/logo-icon-light.svg'"
         [class.collapse]="appSettingsService.appSettings.isSidebarMenuMinimized">
    <div #sidebarMenuContainer class="sidebar-menu-container" (resized)="onSidebarMenuResized(sidebarMenuContainer)">
        @for (appMenuItem of appMenuItems; track appMenuItem.routeType) {
        <div class="side-bar-menu-item ripple-effect"
             [class.ripple-fill]="!appSettingsService.appSettings.isSidebarMenuMinimized" [class.active]="activeRouteType === appMenuItem.routeType"
             (click)="onAppMenuItemClick(appMenuItem.routeType)" (mouseenter)="onAppMenuItemMouseEnter(appMenuItem)"
             (mouseleave)="onAppMenuItemMouseLeave(appMenuItem)">
            <i [class]="appMenuItem.iconClass" @fadeInOutAnimation></i>
            @if (!appSettingsService.appSettings.isSidebarMenuMinimized) {
            <div @foldFadeAnimation class="side-bar-menu-item-text select-none"
                 [innerHTML]="appMenuItem.text">
            </div>
            }
            @if (appSettingsService.appSettings.isSidebarMenuMinimized && appMenuItem.isExpanded) {
            <div class="side-bar-menu-item-alt ripple-effect ripple-fill"
                 @foldHorizontalAnimation
                 (click)="onAppMenuItemClick(appMenuItem.routeType)">
                <div class="side-bar-menu-item-text select-none" [innerHTML]="appMenuItem.text"></div>
            </div>
            }
        </div>
        }
    </div>
    <div class="main-side-bar-footer">
        @if (loginModel.userInsightPermissions.includes(loginModel.UserInsightPermission.MiniControlCenter)) {
        <button #alertsButton type="button" class="alerts-button ripple-effect ripple-center" (click)="onAppMenuItemClick(RouteType.MiniControlCenter)">
            <i class="icon-bell-regular"></i>
            @if (miniControlCenterModel.pendingAlertsCount.length > 0) {
            <div class="alerts-baloon" @fadeInOutAnimation><span>{{ miniControlCenterModel.pendingAlertsCount }}</span></div>
            }
        </button>
        }
        <div class="control-center-alerts"></div>
        <div class="dropdown extra-menu-items" dropdown [(isExpanded)]="showExtraMenuItems" [class.active]="extraAppMenuItems.length > 0">
            <button type="button" class="ripple-effect ripple-center"><i class="icon-insights-menu"></i></button>
            @if (showExtraMenuItems) {
            <div class="dropdown-container" @foldHorizontalAnimation>
                @for (appMenuItem of extraAppMenuItems; track appMenuItem.routeType) {
                <button type="button" class="menu-button"
                        (click)="onAppMenuItemClick(appMenuItem.routeType)">
                    <i [class]="appMenuItem.iconClass"></i>
                    <span [innerHTML]="appMenuItem.text"></span>
                </button>
                }
            </div>
            }
        </div>
        <button #settingsButton type="button" class="settings-button ripple-effect ripple-center" (click)="showSettingsMenu = !showSettingsMenu">
            <i class="icon-gear"></i>
        </button>
        <user-menu [dropdownPosition]="DropdownPosition.Right"></user-menu>
    </div>
</div>

@if (showAlertMessage && loginModel.userInsightPermissions.includes(loginModel.UserInsightPermission.MiniControlCenter) && newAlerts.length > 0) {
<div class="alert-message-container" @fadeSlideDownInOutAnimation (click)="onAlertMessageClick(routerOutlet)">
    <button type="button" class="cancel-button" (click)="onCloseAlertMessageButtonClick($event)"><i class="icon-cancel"></i></button>
    <div class="alert-message">
        <div class="title">Alert</div>
        <div class="value">{{ newAlerts[0].alertReasonName }}</div>
        <div class="title">Created</div>
        <div class="value">{{ newAlerts[0].createTimeFormatted }}</div>
        <div class="title">Customer</div>
        <div class="value">{{ newAlerts[0].payingAccountName }}</div>
    </div>
    @if (newAlerts.length > 1) {
    <div class="remark">and {{ newAlerts.length - 1 }} more...</div>
    }
</div>
}
}

<main [@outletAnimation]="routerOutlet.isActivated && routerOutlet.activatedRouteData ? routerOutlet.activatedRouteData['animationState'] : false">
    <router-outlet #routerOutlet="outlet"></router-outlet>
    @if (showSettingsMenu) {
    <div tabindex="-1" class="setting-overlay overlay" (click)="showSettingsMenu = false" @fadeInOutAnimation></div>
    }
    @if (showSettingsMenu) {
    <div class="settings-menu-container page-container page-image-overlay" @slideInOutAnimation>
        <settings (closed)="onSettingsMenuClosed($event)"></settings>
    </div>
    }
    @if (!viewIsReady) {
    <loader [@fadeOutAnimation] loadingMessage="&nbsp;"></loader>
    }
</main>

<ng-template #sideBarCornerTemplate>
    <svg class="side-bar-corner" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
        <path d="M 0,0 V 512 H 512 C 153.97399,510.47719 29.166109,298.41023 0,0 Z" />
    </svg>
</ng-template>