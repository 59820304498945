import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { fadeInOutAnimation, fadeOutAnimation } from '../../animations/fade.animation';
import { foldAnimation, foldBothAnimation, foldLongAnimation } from '../../animations/fold.animation';
import { Utils } from '../../utils/utils';
import { ShipmentModel } from './model/shipment.model';
import { ModalButtonType, ModalIconType, ModalResultType, ModalType } from '../../controls/modal-message/modal-message.component';
import { ModalMessageService } from '../../controls/modal-message/services/modal-message.service';
import { Constants, IApiResponse, IApiResponseData, RouteType } from '../../utils/globals';
import { CustomAttributeType, DisplayShipment } from './model/shipment-model.class';
import { StringTableUtils } from '../../utils/string-table-utils';
import { NgForm, FormsModule } from '@angular/forms';
import { RoutingHistoryService } from '../../services/routing-history.service';
import { AppSettingsService } from '../../services/app-settings.service';
import { LoginModel } from '../../user/login/model/login.model';
import { ManagerItemStatus } from '../../base/models/manager-base-model.class';
import { AttachmentsComponent } from '../../base/components/attachments.component';
import { SelectListComponent } from '../../controls/select-list/select-list.component';
import { BarcodeScannerService } from '../../controls/barcode-scanner/services/barcode-scanner.service';
import { SafePipe } from '../../pipes/safe.pipe';
import { GlobalsPipe } from '../../pipes/globals.pipe';
import { CameraComponent } from '../../controls/camera/camera.component';
import { DragDropDirective } from '../../directives/drag-drop.directive';
import { TextAreaAutosizeDirective } from '../../directives/textarea-autoresize.directive';
import { TextChractersCounterComponent } from '../../base/components/text-chracters-counter.component';
import { ClearableInputComponent } from '../../controls/clearable-input/clearable-input.component';
import { DateRangeValidatorDirective } from '../../validators/date-range.validator';
import { RequiredValidatorDirective } from '../../validators/required.validator';
import { DateTimePickerComponent } from '../../controls/datetime-picker/datetime-picker.component';
import { PorfolioViewerComponent } from '../../controls/porfolio-viewer/porfolio-viewer.component';
import { NgTemplateOutlet } from '@angular/common';
import { OverlayScrollbarDirective } from '../../directives/overlay-scrollbar/overlay-scrollbar.directive';
import { FormIgnoreEnterDirective } from '../../directives/form-ignore-enter.directive';

@Component({
    selector: 'shipment',
    templateUrl: './shipment.component.html',
    styleUrls: ['../../controls/camera/camera.component.css', '../../base/styles/manager-item-base.css', './shipment.component.css'],
    animations: [fadeInOutAnimation, foldAnimation, foldBothAnimation, foldLongAnimation, fadeOutAnimation],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [FormsModule, FormIgnoreEnterDirective, OverlayScrollbarDirective, SelectListComponent, NgTemplateOutlet, PorfolioViewerComponent,
        DateTimePickerComponent, RequiredValidatorDirective, DateRangeValidatorDirective, ClearableInputComponent, TextChractersCounterComponent,
        TextAreaAutosizeDirective, DragDropDirective, CameraComponent, GlobalsPipe, SafePipe]
})
export class ShipmentComponent extends AttachmentsComponent implements OnInit
{
    // #region Private Constants

    private readonly EXISTING_ATTACHMENT_CLASSNAME: string = 'existing-attachment';

    // #endregion

    // #region Private Members

    private _isEditingLiveDetails: boolean = false;

    @ViewChild('pageScroller', { read: ElementRef, static: false }) private _pageScrollerElementRef: ElementRef<HTMLElement> | undefined = undefined;
    @ViewChild('formShipment', { read: NgForm, static: false }) private _formShipment: NgForm | undefined = undefined;
    @ViewChild('deviceId', { read: SelectListComponent, static: false }) private _devicesList: SelectListComponent | undefined = undefined;

    // #endregion

    // #region Properties

    public get isEditingLiveDetails(): boolean
    {
        return this._isEditingLiveDetails;
    }

    public get appSettingsService(): AppSettingsService
    {
        return this._appSettingsService;
    }

    public get shipmentModel(): ShipmentModel
    {
        return this._attachmentsModel as ShipmentModel;
    }

    public get loginModel(): LoginModel
    {
        return this._loginModel;
    }

    public get containerIdColumnName(): string
    {
        return StringTableUtils.getContainerIdColumnName(this._loginModel.isAccountTypeAmazon);
    }

    public get deviceIdColumnName(): string
    {
        return StringTableUtils.getDeviceIdColumnName(this._loginModel.isAccountTypeAmazonUS);
    }

    public get installDateColumnName(): string
    {
        return StringTableUtils.getInstallDateColumnName(this._loginModel.isAccountTypeAmazon)
    }

    public get removeDateColumnName(): string
    {
        return StringTableUtils.getRemoveDateColumnName(this._loginModel.isAccountTypeAmazon)
    }

    // #endregion

    // #region Events

    @Output() public submitingShipment: EventEmitter<IApiResponseData<DisplayShipment>> = new EventEmitter<IApiResponse>();

    // #endregion

    // #region Constructor

    constructor(_shipmentModel: ShipmentModel, private _router: Router, _changeDetectorRef: ChangeDetectorRef,
        _modalMessageService: ModalMessageService, private _routingHistoryService: RoutingHistoryService,
        private _appSettingsService: AppSettingsService, private _loginModel: LoginModel, private _barcodeScannerService: BarcodeScannerService)
    {
        super(_shipmentModel, _changeDetectorRef, _modalMessageService);
    }

    // #endregion

    // #region Event Handlers

    public ngOnInit(): void
    {
        if (this._routingHistoryService.isPopState && this.shipmentModel.isInitialized)
        {
            this.setViewState(true);
            this._changeDetectorRef.markForCheck();
        }
        else if (Constants.IS_MOBILE)
        {
            this.loadShipment();
        }

        this.attachmentResponseObservable.subscribe((response: IApiResponse) => this.submitingShipment.emit(response));
    }

    public onDeviceBarcodeButtonMouseDown(event: MouseEvent): void
    {
        event.stopImmediatePropagation();
        event.preventDefault();

        this._barcodeScannerService.show().then((barcode: string | null) =>
        {
            if (barcode !== null && this._devicesList !== undefined)
            {
                this._devicesList.filter = barcode;
                this._devicesList.focus();
                this._changeDetectorRef.markForCheck();
            }
        });
    }

    public onEditLiveContentClick(event: MouseEvent, expanderButton: HTMLButtonElement): void
    {
        event.stopImmediatePropagation();
        expanderButton.ariaExpanded = 'true';

        this._isEditingLiveDetails = true;
    }

    public async onShipmentSubmit(isShipmentDataValid: boolean): Promise<void>
    {
        if (!isShipmentDataValid)
        {
            Utils.scrollToInvalidFormElement();
            return;
        }

        if (this.shipmentModel.isShipmentCanceled())
        {
            const modalResult: ModalResultType = await this._modalMessageService.show(
                {
                    title: Constants.APP_TITLE,
                    message: 'You won\'t be able to undo shipment cancelation. Are you sure?',
                    modalType: ModalType.Error,
                    modalIcon: ModalIconType.Question,
                    modalButton: ModalButtonType.YesNo
                });

            if (modalResult === ModalResultType.No)
            {
                return;
            }
        }

        const isNewShipment: boolean = this.shipmentModel.displayShipment.shipmentKey === null

        if (isNewShipment && !await this.createNewShipment())
        {
            return;
        }

        if (this.shipmentModel.attachmentsInfo[CustomAttributeType.attachments_origin].uploadAttachmentsFilesData.length > 0 ||
            this.shipmentModel.attachmentsInfo[CustomAttributeType.attachments_dest].uploadAttachmentsFilesData.length > 0)
        {
            if (!await this.uploadShipmentAttachments())
            {
                return;
            }
        }

        if (this.shipmentModel.attachmentsInfo[CustomAttributeType.attachments_origin].deleteAttachmentsFileNames.length > 0 ||
            this.shipmentModel.attachmentsInfo[CustomAttributeType.attachments_dest].deleteAttachmentsFileNames.length > 0)
        {
            if (!await this.deleteShipmentAttachments())
            {
                return;
            }
        }

        this._statusMessage = `<b>${this.shipmentModel.displayShipment.statusId === ManagerItemStatus.Canceled ? 'Canceling' : 'Updating'} shipment.</b> Please wait...`;
        this.viewIsReady = false;
        this._changeDetectorRef.detectChanges();

        this.submitingShipment.emit({ isSuccess: true, isComplete: false, message: this._statusMessage });

        const isSuccess: boolean = await this.submitShipment(isNewShipment);
        if (isSuccess)
        {
            this.shipmentModel.setValidShipment();

            if (Constants.IS_MOBILE)
            {
                this.navigateToShipmentManager();
                return;
            }

            this.viewIsReady = true;
            this._changeDetectorRef.detectChanges();
        }

        this.submitingShipment.emit({ isSuccess: isSuccess, isComplete: true, data: isSuccess ? this.shipmentModel.displayShipment : undefined });
    }

    public onDeleteExistingAttachmentButtonClick(event: Event, attachmentIndex: number, customAttributeType: CustomAttributeType): void
    {
        event.stopPropagation();

        this.showDeleteImageVerificationMessage().then((result: ModalResultType) =>
        {
            if (result === ModalResultType.Yes)
            {
                this.shipmentModel.deleteExistingShipmentAttachmentFile(attachmentIndex, customAttributeType);
                this._changeDetectorRef.detectChanges();
            }
        });
    }

    public onShipmentStatusChange(): void
    {
        this.clearComponentSubscription();

        this.initializeShipmentAttachments();
    }

    public onCancelShipmentButtonClick(): void
    {
        if (Constants.IS_MOBILE)
        {
            this.navigateToShipmentManager();
            return;
        }

        this.submitingShipment.emit({ isSuccess: false, isComplete: false });
    }

    // #endregion

    // #region Public Methods

    public loadShipment(isScrollToTop: boolean = true): void
    {
        this._formShipment?.resetForm();
        this._isEditingLiveDetails = false;

        setTimeout(() =>
        {
            if (isScrollToTop && this._pageScrollerElementRef !== undefined)
            {
                this._pageScrollerElementRef.nativeElement.scrollTop = 0;
            }

            this.shipmentModel.initialize();
            this.setViewState(true);
            this._changeDetectorRef.detectChanges();

            this.initializeShipmentAttachments();
        });
    }

    // #endregion

    // #region Protected Methods

    protected override getExtraAttachmentsUrls(attachemntElement: HTMLElement): string[]
    {
        if (attachemntElement.parentElement === null)
        {
            return [];
        }

        const attachmentsUrls: string[] = [];
        attachemntElement.parentElement.querySelectorAll(`.${this.EXISTING_ATTACHMENT_CLASSNAME}.${this.IMAGE_LOAD_CLASSNAME}`).forEach((element: Element) =>
        {
            const imageUrl: string | undefined = element.querySelector('img')?.src;
            if (imageUrl !== undefined)
            {
                attachmentsUrls.push(imageUrl);
            }
        });

        return attachmentsUrls;
    }

    // #endregion

    // #region Private Methods

    private navigateToShipmentManager(): void
    {
        this._router.navigate([`/${RouteType.ShipmentManager}`]);
    }

    private async createNewShipment(): Promise<boolean>
    {
        this._statusMessage = '<b>Creating new shipment.</b> Please wait...';
        this.viewIsReady = false;
        this._changeDetectorRef.detectChanges();

        this.submitingShipment.emit({ isSuccess: true, isComplete: false, message: this._statusMessage });

        if (!await this.submitShipment(true))
        {
            this.submitingShipment.emit({ isSuccess: false, isComplete: true });
            return false;
        }

        return true;
    }

    private async uploadShipmentAttachments(): Promise<boolean>
    {
        this._statusMessage = '<b>Uploading shipment images.</b> Please wait...';
        this._progressValue = null;
        this.viewIsReady = false;
        this._changeDetectorRef.detectChanges();

        this.submitingShipment.emit({ isSuccess: true, isComplete: false, message: this._statusMessage });

        for (const customAttributeType of [CustomAttributeType.attachments_origin, CustomAttributeType.attachments_dest])
        {
            for (let i: number = 0; i < this.shipmentModel.attachmentsInfo[customAttributeType].uploadAttachmentsFilesData.length; i++)
            {
                if (!await this.uploadAttachment(i, customAttributeType))
                {
                    this.submitingShipment.emit({ isSuccess: false, isComplete: true });
                    return false;
                }
            }
        }

        return true;
    }

    private async deleteShipmentAttachments(): Promise<boolean>
    {
        this._statusMessage = '<b>Deleting shipment images.</b> Please wait...';
        this.viewIsReady = false;
        this._changeDetectorRef.detectChanges();

        this.submitingShipment.emit({ isSuccess: true, isComplete: false });

        for (const customAttributeType of [CustomAttributeType.attachments_origin, CustomAttributeType.attachments_dest])
        {
            for (let i: number = this.shipmentModel.attachmentsInfo[customAttributeType].deleteAttachmentsFileNames.length - 1; i >= 0; i--)
            {
                if (!await this.deleteAttachment(customAttributeType, i))
                {
                    this.submitingShipment.emit({ isSuccess: false, isComplete: true, message: this._statusMessage });
                    return false;
                }
            }
        }

        return true;
    }

    private initializeShipmentAttachments(): void
    {
        this._componentSubscription = this.shipmentModel.initializeShipmentAttachments().subscribe((isCompleted: boolean) =>
        {
            this._changeDetectorRef.detectChanges();

            if (isCompleted)
            {
                this.clearComponentSubscription();
            }
        });
    }

    private submitShipment(isNewShipment: boolean): Promise<boolean>
    {
        return new Promise<boolean>((resolve) =>
        {
            this.viewIsReady = false;

            this.clearComponentSubscription();

            this._componentSubscription = this.shipmentModel.submitShipment(isNewShipment).subscribe(
                (response: IApiResponse) =>
                {
                    if (response.isSuccess)
                    {
                        resolve(true);
                    }
                    else
                    {
                        this.viewIsReady = true;
                        this._changeDetectorRef.detectChanges();

                        this._modalMessageService.show({ title: Constants.APP_TITLE, message: response.message, modalType: ModalType.Error }).then(() =>
                            resolve(false));
                    }
                });

            this._changeDetectorRef.detectChanges();
        });
    }

    private deleteAttachment(customAttributeType: CustomAttributeType, deleteAttachmentIndex: number): Promise<boolean>
    {
        return new Promise<boolean>((resolve) =>
        {
            this.clearComponentSubscription();

            this._componentSubscription = this.shipmentModel.deleteShipmentAttachmentFile(deleteAttachmentIndex, customAttributeType).subscribe(
                (response: IApiResponse) =>
                {
                    if (response.isSuccess)
                    {
                        resolve(true);

                        this.submitingShipment.emit({ isSuccess: true, isComplete: false });
                    }
                    else
                    {
                        this.viewIsReady = true;
                        this._changeDetectorRef.detectChanges();

                        this.submitingShipment.emit({ isSuccess: false, isComplete: false });

                        this._modalMessageService.show({ title: Constants.APP_TITLE, message: response.message, modalType: ModalType.Error }).then(() =>
                            resolve(false));
                    }
                });
        });
    }

    // #endregion
}
