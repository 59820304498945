import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { BarcodeScanner, BarcodeScannedEvent } from '@capacitor-mlkit/barcode-scanning';
import { PluginListenerHandle } from '@capacitor/core';

@Component({
    selector: 'barcode-scanner',
    templateUrl: './barcode-scanner.component.html',
    styleUrls: ['./barcode-scanner.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true
})
export class BarcodeScannerComponent implements OnInit, OnDestroy
{
    // #region Private Constants

    private readonly BARCODE_SCANNER_ACTIVE_CLASSNAME: string = 'barcode-scanner-active';

    // #endregion

    // #region Private Members

    private _audioElement: HTMLAudioElement = new Audio('assets/sounds/barcode-scanner.mp3');

    private _isTorchOn: boolean = false;

    private _isTorchAvailable: boolean = false;

    private _pluginListenerHandle: PluginListenerHandle | null = null;

    // #endregion

    // #region Properties

    public get isTorchAvailable(): boolean
    {
        return this._isTorchAvailable;
    }

    public get isTorchOn(): boolean
    {
        return this._isTorchOn;
    }

    // #endregion

    // #region Constructor

    constructor(private _changeDetectorRef: ChangeDetectorRef)
    {
    }

    // #endregion

    // #region Events

    @Output() close: EventEmitter<string | null> = new EventEmitter<string | null>();

    // #endregion

    // #region Event Handlers

    public async ngOnInit(): Promise<void>
    {
        this._isTorchAvailable = (await BarcodeScanner.isTorchAvailable()).available;
        this._changeDetectorRef.markForCheck();

        document.body.classList.add(this.BARCODE_SCANNER_ACTIVE_CLASSNAME);

        this._pluginListenerHandle = await BarcodeScanner.addListener('barcodeScanned', async (result: BarcodeScannedEvent) =>
        {
            this._audioElement.play();
            await this.closeBarcodeScan(result.barcode.rawValue);
        });

        await BarcodeScanner.startScan();
    }

    public async ngOnDestroy(): Promise<void>
    {
        await this.closeBarcodeScan();
    }

    public async onCloseButtonClick(): Promise<void>
    {
        await this.closeBarcodeScan();
    }

    public async onTorchButtonClick(): Promise<void>
    {
        await BarcodeScanner.toggleTorch();

        this._isTorchOn = !this._isTorchOn;
        this._changeDetectorRef.markForCheck();
    }

    // #endregion

    // #region Public Methods

    public async closeBarcodeScan(barcode: string | null = null): Promise<void>
    {
        await this._pluginListenerHandle!.remove();

        document.body.classList.remove(this.BARCODE_SCANNER_ACTIVE_CLASSNAME);

        await BarcodeScanner.stopScan();

        this.close.emit(barcode);
    }

    // #endregion
}
