import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ManagerBaseComponent } from '../../base/components/manager-base.component';
import { MiniControlCenterAlertColumnType } from './model/mini-control-center-model.class';
import { AlertStatusType, ControlCenterAlertConstants, DisplayControlCenterAlertData } from '../control-center-alert/model/control-center-alert-model.class';
import { MiniControlCenterModel } from './model/mini-control-center.model';
import { Router } from '@angular/router';
import { AppSettingsService, MiniCallCenterSettingsData } from '../../services/app-settings.service';
import { AnimationBuilder } from '@angular/animations';
import { RoutingHistoryService } from '../../services/routing-history.service';
import { ModalMessageService } from '../../controls/modal-message/services/modal-message.service';
import { Constants, IApiResponse, IListColumn, IOptionalListColumn, RouteType } from '../../utils/globals';
import { Utils } from '../../utils/utils';
import { fadeInAnimation, fadeInOutAnimation, shortFadeInOutAnimation } from '../../animations/fade.animation';
import { VirtualListInfo } from '../../controls/virtual-list/model/virtual-list-info';
import { DropdownPosition } from '../../base/components/dropdown-base.component';
import { ModalResultType, ModalType } from '../../controls/modal-message/modal-message.component';
import { Subscription } from 'rxjs';
import { ControlCenterAlertComponent } from '../control-center-alert/control-center-alert.component';
import { HttpErrorCodes } from '../../utils/http-error-codes';
import { OverlayScrollbarDirective } from '../../directives/overlay-scrollbar/overlay-scrollbar.directive';
import { DefaultColumnComponent } from '../../base/components/default-column.component';
import { VirtualListComponent } from '../../controls/virtual-list/virtual-list.component';
import { LoaderComponent } from '../../controls/loader/loader.component';
import { DropdownDirective } from '../../directives/dropdown.directive';
import { NgTemplateOutlet, NgClass } from '@angular/common';
import { TooltipDirective } from '../../directives/tooltip.directive';
import { DateTimePickerComponent } from '../../controls/datetime-picker/datetime-picker.component';
import { FormsModule } from '@angular/forms';
import { ClearableInputComponent } from '../../controls/clearable-input/clearable-input.component';

@Component({
    selector: 'mini-control-center',
    templateUrl: './mini-control-center.component.html',
    styleUrls: ['../../controls/modal-message/modal-message.component.css', './mini-control-center.component.css', '../../base/styles/manager-base.css',
        '../../base/styles/manager-table.css'],
    animations: [fadeInOutAnimation, fadeInAnimation, shortFadeInOutAnimation],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [ClearableInputComponent, FormsModule, DateTimePickerComponent, TooltipDirective, NgTemplateOutlet, DropdownDirective, ControlCenterAlertComponent,
        LoaderComponent, VirtualListComponent, NgClass, DefaultColumnComponent, OverlayScrollbarDirective]
})
export class MiniControlCenterComponent extends ManagerBaseComponent<DisplayControlCenterAlertData, MiniControlCenterAlertColumnType> implements OnInit, OnDestroy
{
    // #region Constants

    protected override readonly EXCEL_FILE_PREFIX: string = 'MiniControlCenter_';

    // #endregion

    // #region Private Members

    private _managerOptionalTableColumns: IListColumn[] = [];
    private _managerOptionalBaseTableColumns: IListColumn[] = [];

    private _getAlertsResponseSubscription: Subscription | null = null;

    // #endregion

    // #region Protected Members

    protected static _managerTablePendingInProcessListInfo: VirtualListInfo = new VirtualListInfo();
    protected static _managerTableDoneListInfo: VirtualListInfo = new VirtualListInfo();

    @ViewChild('managerItem', { read: ControlCenterAlertComponent, static: false })
    protected override _managerItemComponent: ControlCenterAlertComponent | undefined = undefined;

    // #endregion

    // #region Properties

    public showTableColumnSelection: boolean = false;

    public get DropdownPosition()
    {
        return DropdownPosition;
    }

    public get MiniControlCenterAlertColumnType()
    {
        return MiniControlCenterAlertColumnType;
    }

    public override get managerTableListInfo(): VirtualListInfo
    {
        return this.managerModel.alertsStatus === AlertStatusType.Done ? MiniControlCenterComponent._managerTableDoneListInfo :
            MiniControlCenterComponent._managerTablePendingInProcessListInfo;
    }

    public override get isDirty(): boolean
    {
        return this._managerItemComponent === undefined ? false : this._managerItemComponent.isDirty;
    }
    public get managerOptionalTableColumnsToSelect(): IOptionalListColumn[]
    {
        return this.managerModel.alertsStatus === AlertStatusType.Pending ||
            this.managerModel.alertsStatus === AlertStatusType.InProcess ?
            this._appSettingsService.appSettings.miniCallCenterSettingsData.tableOptionalPendingInProcessAlertsColumnsToSelect :
            this._appSettingsService.appSettings.miniCallCenterSettingsData.tableOptionalDoneAlertsColumnsToSelect;
    }

    public override get managerModel(): MiniControlCenterModel
    {
        return this._managerModel as MiniControlCenterModel;
    }

    public get isTableOptionalColumnsSelectAll(): boolean
    {
        for (const optionalShipmentColumn of this.managerOptionalTableColumnsToSelect)
        {
            if (!optionalShipmentColumn.selected)
            {
                return false;
            }
        }

        return true;
    }

    public set isTableOptionalColumnsSelectAll(value: boolean)
    {
        this.updateManagerTableSelectAllOptionalColumns(value, this.getTableOptionalAlertsColumnsToSelectPropertyName());
        this.initializeManagerTableColumns();
    }

    // #endregion

    // #region Constructor

    constructor(_managerModel: MiniControlCenterModel, _router: Router, _changeDetectorRef: ChangeDetectorRef,
        _routingHistoryService: RoutingHistoryService, _animationBuilder: AnimationBuilder, _appSettingsService: AppSettingsService,
        _modalMessageService: ModalMessageService)
    {
        super(_managerModel, _router, _changeDetectorRef, _routingHistoryService, _animationBuilder, _appSettingsService, _modalMessageService);

        this.initializeManagerTableColumns();

        this.initializeAlertsResponse();
    }

    // #endregion

    // #region Event Handlers

    public override ngOnInit(): void
    {
        if (this._routingHistoryService.isPopState && this.managerModel.isInitialized)
        {
            this.setViewState(true);
            this._changeDetectorRef.markForCheck();
        }
        else
        {
            MiniControlCenterComponent._managerTablePendingInProcessListInfo = new VirtualListInfo();
            MiniControlCenterComponent._managerTablePendingInProcessListInfo.sortColumn = MiniControlCenterAlertColumnType.CreateTime;
            MiniControlCenterComponent._managerTablePendingInProcessListInfo.sortOrder = -1;

            MiniControlCenterComponent._managerTableDoneListInfo = new VirtualListInfo();
            MiniControlCenterComponent._managerTableDoneListInfo.sortOrder = -1;
            MiniControlCenterComponent._managerTableDoneListInfo.sortColumn = MiniControlCenterAlertColumnType.EventTime - 1;

            this.managerModel.clear();

            this._viewIsInitialized = true;
            this._changeDetectorRef.detectChanges();

            this.updateViewReady();
        }
    }

    public override ngOnDestroy(): void
    {
        super.ngOnDestroy();

        if (this._getAlertsResponseSubscription !== null)
        {
            this._getAlertsResponseSubscription.unsubscribe();
            this._getAlertsResponseSubscription = null;
        }
    }

    public override async onManagerItemClick(managerItem: DisplayControlCenterAlertData, forceSelect: boolean = false): Promise<void>
    {
        if (!await this.canDeactivate())
        {
            return;
        }

        if (!this.managerModel.selectedAlerts.includes(managerItem))
        {
            for (const alert of this.managerModel.selectedAlerts)
            {
                alert.isSelected = false;
            }

            this.managerModel.selectedAlerts = [];
        }

        this.selectManagerItem(managerItem, forceSelect);

        if (managerItem.statusId === AlertStatusType.Pending)
        {
            this.changeSelectedPendingAlertsStatus();
        }
    }

    public override onManagerItemsListChange(items: any[]): void
    {
        if (items.length > 0 && !this._viewIsReady)
        {
            this.setViewState(true);
            this._changeDetectorRef.markForCheck();

            this.managerTableListInfo.scrollTop = null;
        }

        super.onManagerItemsListChange(items);
    }

    public onTableOptionalColumnsSelectChange(): void
    {
        this.triggerUpdateManagerTableSelectAllOptionalColumns(this.getTableOptionalAlertsColumnsToSelectPropertyName());
        this.initializeManagerTableColumns();
    }

    public async onDisplayStatusClick(event: Event, alertStatusType: AlertStatusType): Promise<void>
    {
        event.preventDefault();

        if (alertStatusType !== AlertStatusType.Done &&
            (this.managerModel.alertsStatus === AlertStatusType.Pending || this.managerModel.alertsStatus === AlertStatusType.InProcess) ||
            this.managerModel.alertsStatus === alertStatusType || !await this.canDeactivate())
        {
            return;
        }

        this.selectManagerItem(null);

        this.updateAlertsStatus(alertStatusType);
    }

    public onShipmentMonitoringButtonClick(event: MouseEvent, shipmentKey: number): void
    {
        event.stopImmediatePropagation();

        this._router.navigate([`/${RouteType.ShipmentMonitoring}/${shipmentKey}`]);
    }

    public override onExportToExcelButtonClick(): void
    {
        this.exportToExel([...this._managerTableColumns, ...this._managerOptionalBaseTableColumns]);
    }

    public onCopyEventSummaryButtonClick(event: MouseEvent, eventSummary: string | null): void
    {
        event.stopImmediatePropagation();
        event.preventDefault();

        if (eventSummary !== null)
        {
            navigator.clipboard.writeText(eventSummary);
        }
    }

    public onSelectAllAlertsClick(event: MouseEvent): void
    {
        event.stopImmediatePropagation();
        event.preventDefault();

        for (const alert of this.managerModel.selectedAlerts)
        {
            alert.isSelected = false;
        }

        this.managerModel.selectedAlerts = (this.managerModel.groupedAlerts.length > 0 &&
            this.managerModel.groupedAlerts.length !== this.managerModel.selectedAlerts.length) ? this.managerModel.groupedAlerts : [];

        for (const alert of this.managerModel.selectedAlerts)
        {
            alert.isSelected = true;
        }

        this._changeDetectorRef.detectChanges();

        this.changeSelectedPendingAlertsStatus();
    }

    public onSelectAlertClick(event: MouseEvent, alert: DisplayControlCenterAlertData): void
    {
        event.stopImmediatePropagation();
        event.preventDefault();

        alert.isSelected = !alert.isSelected;

        const updateAlertPendingStatus: boolean = alert.isSelected && this.managerModel.selectedManagerItem !== null &&
            alert.statusId === AlertStatusType.Pending;

        if (alert.isSelected)
        {
            if (this.managerModel.selectedManagerItem === null)
            {
                this.onManagerItemClick(alert);
            }
            else
            {
                this.managerModel.selectedAlerts.push(alert);
            }
        }
        else
        {
            this.managerModel.selectedAlerts.splice(this.managerModel.selectedAlerts.indexOf(alert), 1);
            if (this.managerModel.selectedManagerItem === alert)
            {
                this.selectManagerItem(null);
            }
        }

        this.managerModel.selectedAlerts = [...this.managerModel.selectedAlerts];

        if (updateAlertPendingStatus)
        {
            this.changeSelectedPendingAlertsStatus();
        }
    }

    // #endregion

    // #region Public Methods

    public async selectAlert(id: number): Promise<void>
    {
        if (this.managerModel.selectedManagerItem !== null && this.managerModel.selectedManagerItem.id === id)
        {
            return;
        }

        if (!await this.canDeactivate())
        {
            return;
        }

        for (const alert of this.managerModel.newAlerts)
        {
            if (alert.id === id)
            {
                await this.updateAlertsStatus(alert.statusId!);
                await this.onManagerItemClick(alert, true);

                this.scrollSelectedManagerItemIntoView();

                break;
            }
        }
    }

    // #endregion

    // #region Protected Methods

    protected override selectManagerItem(managerItem: DisplayControlCenterAlertData | null, forceSelect: boolean = false): void
    {
        super.selectManagerItem(managerItem, forceSelect);

        this.managerModel.updateGroupedAndSelectedAlerts();

        this._changeDetectorRef.detectChanges();
    }

    protected override loadManagerElements(): void
    {
        this.clearGetMnagerItemsSubscription();

        this._statusMessage = Constants.LOADING_DATA_STRING;

        this._getManagerItemsSubscription = this.managerModel.getManagerItems().subscribe();

        this._changeDetectorRef.detectChanges();
    }

    protected override initializeManagerTableColumns(): void
    {
        const alert: DisplayControlCenterAlertData = new DisplayControlCenterAlertData();

        this._managerTableColumns =
            [
                {
                    columnType: MiniControlCenterAlertColumnType.CreateTime,
                    text: `Create Time<span class="small-units">${this._appSettingsService.appSettings.isUsingUTCTime ? ' (UTC)' : ''}</span>`,
                    classes: 'col-date',
                    propertyName: Utils.getPropertyNameof<DisplayControlCenterAlertData>(alert, alert => alert.createTimeFormatted),
                    sortPropertyName: Utils.getPropertyNameof<DisplayControlCenterAlertData>(alert, alert => alert.created),
                    isFixedWidth: true
                },
                {
                    columnType: MiniControlCenterAlertColumnType.EventTime,
                    text: `Event Time<span class="small-units">${this._appSettingsService.appSettings.isUsingUTCTime ? ' (UTC)' : ''}</span>`,
                    classes: 'col-date',
                    propertyName: Utils.getPropertyNameof<DisplayControlCenterAlertData>(alert, alert => alert.eventTimeFormatted),
                    sortPropertyName: Utils.getPropertyNameof<DisplayControlCenterAlertData>(alert, alert => alert.eventTime),
                    isFixedWidth: true
                },
                {
                    columnType: MiniControlCenterAlertColumnType.EventType,
                    text: 'Event Type',
                    classes: 'col-event-type',
                    propertyName: Utils.getPropertyNameof<DisplayControlCenterAlertData>(alert, alert => alert.alertReasonName)
                },
                {
                    columnType: MiniControlCenterAlertColumnType.Customer,
                    text: 'Customer',
                    classes: 'col-customer',
                    propertyName: Utils.getPropertyNameof<DisplayControlCenterAlertData>(alert, alert => alert.payingAccountName)
                },
                {
                    columnType: MiniControlCenterAlertColumnType.Route,
                    text: 'Route',
                    classes: 'col-route',
                    propertyName: Utils.getPropertyNameof<DisplayControlCenterAlertData>(alert, alert => alert.routeName)
                },
                {
                    columnType: MiniControlCenterAlertColumnType.CargoId,
                    text: 'Cargo ID',
                    classes: 'col-container-id',
                    propertyName: Utils.getPropertyNameof<DisplayControlCenterAlertData>(alert, alert => alert.containerId)
                }
            ];

        this.initializeManagerTableOptionalColumns();

        this.managerTableColumns = [...this._managerTableColumns, ...this._managerOptionalTableColumns];

        if (this.managerModel.alertsStatus === AlertStatusType.Done)
        {
            this._managerTableColumns.push(
                {
                    columnType: MiniControlCenterAlertColumnType.IsReportAnomaly,
                    text: 'Anomaly',
                    classes: 'center',
                    propertyName: Utils.getPropertyNameof<DisplayControlCenterAlertData>(alert, alert => alert.isReportAnomaly)
                });
        }
        else
        {
            this._managerTableColumns.unshift(
                {
                    columnType: MiniControlCenterAlertColumnType.Status,
                    classes: 'col-status',
                    propertyName: Utils.getPropertyNameof<DisplayControlCenterAlertData>(alert, alert => alert.statusId),
                    isFixedWidth: true
                });
        }

        this._managerTableColumns.unshift(
            {
                columnType: MiniControlCenterAlertColumnType.Select,
                classes: 'col-select',
                propertyName: Utils.getPropertyNameof<DisplayControlCenterAlertData>(alert, alert => alert.isSelected),
                isFixedWidth: true
            });

        this._managerTableColumns.push(
            {
                columnType: MiniControlCenterAlertColumnType.Copy,
                classes: 'col-button center',
                isFixedWidth: true
            },
            {
                columnType: MiniControlCenterAlertColumnType.Track,
                classes: 'col-button center',
                isFixedWidth: true
            });
    }

    // #endregion

    // #region Private Methods

    private changeSelectedPendingAlertsStatus(): void
    {
        this.clearGetMnagerItemsSubscription();

        this._getManagerItemsSubscription = this.managerModel.changePendingAlertsStatus().subscribe((response: IApiResponse) =>
        {
            this._changeDetectorRef.detectChanges();

            if (response.isSuccess)
            {
                this.loadManagerItem();
            }
            else
            {
                this._modalMessageService.show({ title: Constants.APP_TITLE, message: response.message, modalType: ModalType.Error }).then(() =>
                {
                    if (response.status === HttpErrorCodes.CONFLICT)
                    {
                        this.loadManagerElements();
                    }
                });
            }
        });

        this._changeDetectorRef.detectChanges();
    }

    private async updateAlertsStatus(alertStatusType: AlertStatusType): Promise<void>
    {
        return new Promise<void>((resolve) =>
        {
            requestAnimationFrame(() =>
            {
                this.managerModel.alertsStatus = alertStatusType;
                this.initializeManagerTableColumns();
                setTimeout(() =>
                {
                    this._changeDetectorRef.detectChanges();
                    requestAnimationFrame(() => resolve());
                });
            });
        });
    }

    private updateViewReady(): void
    {
        if (this.managerModel.isAlertsInitialized && !this._viewIsReady && this.managerModel.filteredSortedManagerItems.length === 0)
        {
            this.setViewState(true);
            this._changeDetectorRef.markForCheck();
        }
    }

    private initializeAlertsResponse(): void
    {
        this._getAlertsResponseSubscription = this.managerModel.alertsResponseObservable.subscribe((response: IApiResponse) =>
        {
            this._changeDetectorRef.detectChanges();

            if (response.isSuccess)
            {
                if (response.isComplete)
                {
                    if (this.managerModel.selectedManagerItem !== null)
                    {
                        if (this.managerModel.selectedManagerItem.statusId !== null &&
                            (this.managerModel.alertsStatus === AlertStatusType.Done && this.managerModel.selectedManagerItem.statusId !== AlertStatusType.Done ||
                                this.managerModel.alertsStatus !== AlertStatusType.Done && this.managerModel.selectedManagerItem.statusId === AlertStatusType.Done))
                        {
                            const selectedManagerItem: DisplayControlCenterAlertData = this.managerModel.selectedManagerItem;
                            this.managerModel.alertsStatus = this.managerModel.selectedManagerItem.statusId;

                            this.managerModel.selectedManagerItem = selectedManagerItem;

                            this.initializeManagerTableColumns();
                            this._changeDetectorRef.detectChanges();
                        }

                        this.managerModel.updateSelectedManagerItem();
                        this.selectManagerItem(this.managerModel.selectedManagerItem, true);
                    }

                    this.updateViewReady();
                }
            }
            else if (this._viewIsReady)
            {
                if (this._modalMessageService.instance === null)
                {
                    this._modalMessageService.show(
                        {
                            title: Constants.APP_TITLE,
                            message: response.status === HttpErrorCodes.CONFLICT ? ControlCenterAlertConstants.CONFLICT_WITH_CHANGES_MESSAGE : response.message,
                            modalType: ModalType.Error
                        });
                }
            }
            else
            {
                this.setViewState(false, response.message);
                this._changeDetectorRef.detectChanges();
            }
        });
    }

    private getTableOptionalAlertsColumnsToSelectPropertyName(): string
    {
        return Utils.getPropertyNameof<MiniCallCenterSettingsData>(new MiniCallCenterSettingsData(),
            miniCallCenterSettingsData =>
                this.managerModel.alertsStatus === AlertStatusType.Done ? miniCallCenterSettingsData.tableOptionalDoneAlertsColumnsToSelect :
                miniCallCenterSettingsData.tableOptionalPendingInProcessAlertsColumnsToSelect);
    }

    private triggerUpdateManagerTableSelectAllOptionalColumns(tableOptionalAlertsColumnsToSelectPropertyName: string): void
    {
        (this._appSettingsService.appSettings.miniCallCenterSettingsData as any)[tableOptionalAlertsColumnsToSelectPropertyName] =
            [...(this._appSettingsService.appSettings.miniCallCenterSettingsData as any)[tableOptionalAlertsColumnsToSelectPropertyName]];
    }

    private updateManagerTableSelectAllOptionalColumns(value: boolean, tableOptionalAlertsColumnsToSelectPropertyName: string): void
    {
        for (const optionalShipmentColumn of (this._appSettingsService.appSettings.miniCallCenterSettingsData as any)[tableOptionalAlertsColumnsToSelectPropertyName])
        {
            optionalShipmentColumn.selected = value;
        }

        this.triggerUpdateManagerTableSelectAllOptionalColumns(tableOptionalAlertsColumnsToSelectPropertyName);
    }

    private filterManagerTableOptionalColumns(tableOptionalAlertsColumnsToSelectPropertyName: string): void
    {
        if ((this._appSettingsService.appSettings.miniCallCenterSettingsData as any)[tableOptionalAlertsColumnsToSelectPropertyName].length === 0 ||
            (this._appSettingsService.appSettings.miniCallCenterSettingsData as any)[tableOptionalAlertsColumnsToSelectPropertyName].length !==
            this._managerOptionalBaseTableColumns.length)
        {
            (this._appSettingsService.appSettings.miniCallCenterSettingsData as any)[tableOptionalAlertsColumnsToSelectPropertyName] =
                this._managerOptionalBaseTableColumns.map((column: IListColumn) => 
                {
                    return { columnType: column.columnType, text: column.text, selected: false };
                });

            return;
        }

        this._managerOptionalTableColumns = this._managerOptionalBaseTableColumns.filter((column: IListColumn) =>
        {
            for (const optionalColumn of (this._appSettingsService.appSettings.miniCallCenterSettingsData as any)[tableOptionalAlertsColumnsToSelectPropertyName])
            {
                if (optionalColumn.columnType === column.columnType)
                {
                    return optionalColumn.selected;
                }
            }

            return false;
        });
    }

    private initializeManagerTableOptionalColumns(): void
    {
        this._managerOptionalTableColumns = [];

        const alert: DisplayControlCenterAlertData = new DisplayControlCenterAlertData();

        this._managerOptionalBaseTableColumns =
            [
                {
                    columnType: MiniControlCenterAlertColumnType.Segment,
                    text: 'Segment',
                    classes: 'col-segment',
                    propertyName: Utils.getPropertyNameof<DisplayControlCenterAlertData>(alert, alert => alert.segmentName)
                },
                {
                    columnType: MiniControlCenterAlertColumnType.Location,
                    text: 'Location',
                    classes: 'col-location',
                    propertyName: Utils.getPropertyNameof<DisplayControlCenterAlertData>(alert, alert => alert.eventLocation)
                },
                {
                    columnType: MiniControlCenterAlertColumnType.Device,
                    text: 'Device',
                    propertyName: Utils.getPropertyNameof<DisplayControlCenterAlertData>(alert, alert => alert.deviceId)
                },
                {
                    columnType: MiniControlCenterAlertColumnType.ShipmentKey,
                    text: 'Shipment Key',
                    classes: 'col-shipment-key',
                    propertyName: Utils.getPropertyNameof<DisplayControlCenterAlertData>(alert, alert => alert.shipmentKey)
                }
            ];

        if (this.managerModel.alertsStatus === AlertStatusType.Done)
        {
            this._managerOptionalBaseTableColumns.push(
                {
                    columnType: MiniControlCenterAlertColumnType.Operator,
                    text: 'Operator',
                    classes: 'col-operator',
                    propertyName: Utils.getPropertyNameof<DisplayControlCenterAlertData>(alert, alert => alert.operatorName)
                },
                {
                    columnType: MiniControlCenterAlertColumnType.Escalation,
                    text: 'Escalation',
                    propertyName: Utils.getPropertyNameof<DisplayControlCenterAlertData>(alert, alert => alert.escalationRequirementFormatted),
                    sortPropertyName: Utils.getPropertyNameof<DisplayControlCenterAlertData>(alert, alert => alert.escalationRequirement)
                }
            );
        }

        this.filterManagerTableOptionalColumns(this.getTableOptionalAlertsColumnsToSelectPropertyName());
    }

    // #endregion
}
