import { NgZone, Renderer2 } from "@angular/core";
import { Observable, Subject } from "rxjs";

export class IdleCheck
{
    // #region Private Members

    private _idleTimeout: NodeJS.Timeout | null = null;
    private _disposeWindowMouseMoveHandler: (() => void) | null = null;
    private _disposeWindowPointerDownHandler: (() => void) | null = null;
    private _disposeWindowKeyDownHandler: (() => void) | null = null;

    private _idleTimeoutDuration: number;

    private _idleOccuredSubject: Subject<boolean> = new Subject<boolean>();

    // #endregion

    // #region Properties
    public get idleOccured(): Observable<boolean>
    {
        return this._idleOccuredSubject;
    }  

    // #endregion

    // #region Constructors

    constructor(private _ngZone: NgZone, private _renderer: Renderer2, idleTimeoutDuration: number)
    {
        this._idleTimeoutDuration = idleTimeoutDuration;
    }

    // #endregion

    // #region Event Handlers

    private onWindowUserAction(): void
    {
        this.clearIdleTimeouts();
        this.initializeIdleCheck();
    }

    // #endregion

    // #region Public Methods

    public start(): void
    {
        this.initializeIdleCheckEvents();
        this.initializeIdleCheck();
    }

    public stop(): void
    {
        this.clearIdleTimeouts();
        this.clearIdleCheckEvents();
    }

    // #endregion

    // #region Private Methods

    private initializeIdleCheck(): void
    {
        this._idleTimeout = setTimeout(() =>
        {
            this._idleOccuredSubject.next(true);
        }, this._idleTimeoutDuration);
    }

    private initializeIdleCheckEvents(): void
    {
        this._ngZone.runOutsideAngular(() =>
        {
            this._disposeWindowPointerDownHandler = this._renderer.listen('window', 'pointerdown', this.onWindowUserAction.bind(this));
            this._disposeWindowMouseMoveHandler = this._renderer.listen('window', 'mousemove', this.onWindowUserAction.bind(this));
            this._disposeWindowKeyDownHandler = this._renderer.listen('window', 'keydown', this.onWindowUserAction.bind(this));
        });
    }

    private clearIdleTimeouts(): void
    {
        if (this._idleTimeout !== null)
        {
            clearTimeout(this._idleTimeout);
            this._idleTimeout = null;
        }
    }

    private clearIdleCheckEvents(): void
    {
        if (this._disposeWindowPointerDownHandler !== null)
        {
            this._disposeWindowPointerDownHandler();
            this._disposeWindowPointerDownHandler = null;
        }

        if (this._disposeWindowMouseMoveHandler !== null)
        {
            this._disposeWindowMouseMoveHandler();
            this._disposeWindowMouseMoveHandler = null;
        }

        if (this._disposeWindowKeyDownHandler !== null)
        {
            this._disposeWindowKeyDownHandler();
            this._disposeWindowKeyDownHandler = null;
        }
    }

    // #endregion
}