import { CGIMarkerIconFactory } from "../../controls/google-map/utils/cgi-marker-icon-factory";
import { Constants } from "../../utils/globals";
import { MonitoringUtils, DisplayProperties } from "../../utils/monitoring-utils";
import { DemurrageContractDeviationType } from "../model/demurrage-monitoring-model.class";

export class CGIMarkerDemurrageIconFactory extends CGIMarkerIconFactory
{
    // #region Public Methods

    public createCGIMarkerDemmurageContractDeviationElement(iconClassName: string, demurrageDeviationId: DemurrageContractDeviationType): Element
    {
        return this.createCGIMarkerElement(this._bodyCssStyleDeclaration.getPropertyValue(
            MonitoringUtils.SHIPMENT_DEMMURAGE_CONTRACT_DEVIATION_DISPLAY_PROPERTIES[demurrageDeviationId].color), this.CGIMARKER_CLASSNAME, iconClassName);
    }

    public createCGIClusterDemmurageContractDeviationElement(demurrageDeviations: number[], clusterCount: number): Element
    {
        const markerElement: HTMLElement = document.createElement("div");
        markerElement.className = `${this.CGIMARKER_CLUSTER_CLASSNAME} ${this.CGIMARKER_CIRCLE_CLASSNAME} ${Constants.ACTIVE_CLASSNAME}`;
        markerElement.style.background =
            `conic-gradient(${this.getCGIClusterMarkerGradient(demurrageDeviations, clusterCount, this.getDeviationsColors())})`;

        markerElement.innerHTML = `<div class="${this.CGIMARKER_DATA_CLASSNAME}"><span>${clusterCount}</span></div>`;

        return markerElement;
    }

    public createCGIClusterPinMarkerDemmurageContractDeviationElement(demurrageDeviations: number[], clusterCount: number): Element
    {
        const markerElement: HTMLElement = document.createElement("div");
        markerElement.className = `${this.CGIMARKER_CLUSTER_CLASSNAME} ${CGIMarkerIconFactory.CGIMARKER_PIN_CLASSNAME} ${Constants.ACTIVE_CLASSNAME}`;
        const background: string = `conic-gradient(${this.getCGIClusterMarkerGradient(demurrageDeviations, clusterCount, this.getDeviationsColors())})`;

        markerElement.innerHTML = this.createClusterPinHtmlContent(background, clusterCount);

        return markerElement;
    }

    // #endregion

    // #region Private Methods

    private getDeviationsColors(): string[]
    {
        return MonitoringUtils.SHIPMENT_DEMMURAGE_CONTRACT_DEVIATION_DISPLAY_PROPERTIES.map((styleProperties: DisplayProperties) => styleProperties.color);
    }

    // #endregion
}