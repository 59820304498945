<!DOCTYPE html>

<input #numericInput type="text" class="form-control clearable" [class.has-icon]="icon !== null || isCurrency" placeholder=" " [(ngModel)]="value" [disabled]="disabled"
       [readonly]="readonly" [attr.maxLength]="maxLength" name="clearable-input" (paste)="onPaste($event)" (input)="onInput()" (keypress)="onKeyPress($event)"
       (focus)="onFocus($event)" (blur)="onBlur($event)" (focusout)="onFocusOut()">
<div class="control-label" [class.required]="isRequired">{{ label }}</div>
@if (icon !== null) {
<i class="control-icon" [class]="icon"></i>
}
@if (isCurrency) {
<i class="control-icon">{{ isPercentage ? '%' : currencySymbol }}</i>
}
@if (!readonly && value !== null && value !== undefined && clearable) {
<i class="icon-cancel control-icon press-icon ripple-effect ripple-center"
   @fadeInOutAnimation (click)="value = null">
</i>
}

