<!DOCTYPE html>

<div class="page-container">
    @if (viewIsInitialized) {
    <div class="page-content">
        <div class="map-section" [@foldVerticalStateAnimation]="!collapseShipmentsMonitoringMapSection">
            @if (!collapseMonitoringItemsFilters) {
            <div class="live-filters-wrapper animation-overflow" [class.full-height]="collapseShipmentsMonitoringTable" [class.hidden]="collapseShipmentsMonitoringMapSection"
                 @foldHorizontalAnimation>
                <ng-container [ngTemplateOutlet]="liveFiltersTemplate"></ng-container>
            </div>
            }
            <div class="map-wrapper" [class.full-height]="collapseShipmentsMonitoringTable && collapseMonitoringItemsFilters"
                 [class.full-width]="collapseMonitoringItemsFilters">
                <google-map #googleMap [showTreetView]="true" (initialize)="onGoogleMapInitialize($event)">
                    <div class="map-controls-container top">
                        @if (!('IS_MOBILE' | globals) && collapseMonitoringItemsFilters) {
                        <button type="button" [disabled]="!monitoringItemsModel.isMonitoringFiltersActive" @fadeInOutAnimation
                                class="clear-filters-button floating-button ripple-effect ripple-fill" (click)="onClearMonitoringItemsFiltersButtonClick()">
                            <i class="icon-filter-circle-xmark"></i>
                        </button>
                        }
                        <div class="popup-button-container more-options">
                            <div class="dropdown" dropdown [(isExpanded)]="showMonitoringPopups[currentMonitoringPopup]"
                                 (isExpandedChange)="onShowMonitoringItemsPopupsChange()">
                                <button type="button" class="floating-button ripple-effect ripple-fill" (click)="onMapMoreOptionsButtonClick($event)">
                                    <i class="icon-more"></i>
                                </button>
                                @if (showMonitoringPopups[MonitoringPopupType.More]) {
                                <div class="dropdown-container left" @foldHorizontalAnimation
                                     (@foldHorizontalAnimation.done)="onShowMapMoreOptionsFoldAnimationDone()">
                                    <button type="button" class="menu-button" (click)="onRefreshButtonClick()"><i class="icon-refresh"></i>Refresh</button>
                                    <button type="button" class="menu-button" (click)="onExportToExcelButtonClick()">
                                        <i class="icon-excel"></i>Export to Excel
                                    </button>
                                    <button type="button" class="menu-button" (click)="onShareSnapshotMenuItemClick()">
                                        <i class="icon-share-nodes"></i>Share Snapshot
                                    </button>
                                    <button type="button" class="menu-button" (click)="onHelpButtonClick($event)"><i class="icon-question"></i>Help</button>
                                    <div class="seperator"></div>
                                    <button type="button" class="menu-button" (click)="showMapRailways = !showMapRailways">
                                        <i class="icon-train-station"></i>Show Railways
                                        <i [class.hide]="!showMapRailways" class="icon-check"></i>
                                    </button>
                                    <div class="seperator"></div>
                                    <button type="button" class="menu-button" (click)="onMapModeButtonClick(true)">
                                        <i class="icon-earth-americas"></i>Satellite View
                                        @if (isSatelliteView) {
                                        <i class="icon-check"></i>
                                        }
                                    </button>
                                    <button type="button" class="menu-button" (click)="onMapModeButtonClick(false)">
                                        <i class="icon-map"></i>Map View
                                        @if (!isSatelliteView) {
                                        <i class="icon-check"></i>
                                        }
                                    </button>
                                </div>
                                }
                                @if (showMonitoringPopups[MonitoringPopupType.Help]) {
                                <div class="popup-button-content-container help select-none" [class.small]="!collapseShipmentsMonitoringTable"
                                     @foldHorizontalAnimation>
                                    <ng-container [ngTemplateOutlet]="popupHelpContentTemplate"></ng-container>
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div class="map-controls-container bottom">
                        <button type="button" class="center-button floating-button ripple-effect ripple-fill" (click)="onReCenterMapButtonClick()">
                            @if (!isMarkerActive) {
                            <i class="icon-target" @fadeInOutAnimation></i>
                            }
                            @if (isMarkerActive) {
                            <i class="icon-center" @fadeInOutAnimation></i>
                            }
                        </button>
                    </div>
                </google-map>
                <div class="vertical-expander" [class.expanded]="collapseMonitoringItemsFilters">
                    <button type="button" class="ripple-effect ripple-center ripple-inverse-color dropdown-shadow" [class.flipped]="collapseMonitoringItemsFilters"
                            (click)="collapseMonitoringItemsFilters = !collapseMonitoringItemsFilters">
                        <i class="icon-arrow-left"></i>
                    </button>
                </div>
                <div class="horizontal-expander">
                    <button type="button" class="ripple-effect ripple-center ripple-inverse-color dropdown-shadow" [class.flipped]="collapseShipmentsMonitoringTable"
                            (click)="collapseShipmentsMonitoringTable = !collapseShipmentsMonitoringTable">
                        <i class="icon-arrow-down"></i>
                    </button>
                </div>
            </div>
        </div>
        @if (!collapseShipmentsMonitoringMapSection && !collapseShipmentsMonitoringTable) {
        <div class="divider" @foldHorizontalAnimation></div>
        }
        <div class="live-manager-table-wrapper animation-overflow" [@foldVerticalStateAnimation]="!collapseShipmentsMonitoringTable"
             [class.full-height]="collapseShipmentsMonitoringMapSection" [class.collapse]="collapseShipmentsMonitoringTable">
            <div class="manager-table-container page-section page-section-padding">
                <virtual-list #monitoringItemsList [items]="monitoringItemsModel.filteredMonitoringItems" id="demurrage-monitoring-table" class="manager-table"
                              [isSortable]="true" tabindex="-1" [virtualListInfo]="shipmentsMonitoringTableListInfo" [isAutoColumnsWidths]="true"
                              [filterProperties]="tableMonitoringItemsSearchProperties"
                              [class.vertical-scrollable]="monitoringItemsList.element.scrollHeight > monitoringItemsList.element.clientHeight"
                              (itemsChange)="onMonitoringItemsTableItemsChange()">
                    <div #header class="list-header">
                        @for (column of tableMonitoringItemsColumns; track column.columnType) {
                        <div class="col-list" [class]="column.classes ?? ''" [attr.property-name]="column.propertyName"
                             [attr.sort-property-name]="column.sortPropertyName" [attr.fixed-width]="column.isFixedWidth ? 'true' : null">
                            @switch (column.columnType) {
                            @case (DemurrageMonitoringColumnType.Copy) {
                            }
                            @case (DemurrageMonitoringColumnType.Share) {
                            }
                            @default {
                            <span [innerHTML]="column.text"></span>
                            }
                            }
                        </div>
                        }
                    </div>
                    @for (monitoringItem of monitoringItemsList.viewPortItems; track monitoringItem.shipmentKey) {
                    <div #body class="list-row ripple-effect ripple-fill ripple-inverse-color"
                         [ngClass]="{ 'odd-row': monitoringItemsList.filteredSortedItems.indexOf(monitoringItem) % 2,
                         'selected': monitoringItem === monitoringItemsModel.selectedMonitoringItem }"
                         (click)="onMonitoringItemsTableItemClick(monitoringItem)">
                        @for (column of tableMonitoringItemsColumns; track column.columnType) {
                        <div class="col-list" [class]="column.classes ?? ''">
                            @switch (column.columnType) {
                            @case (DemurrageMonitoringColumnType.Country) {
                            <ng-container [ngTemplateOutlet]="monitoringItem.perimeterCountryCode !== null && monitoringItem.perimeterCountryName !== null ?
                                                  columnCountryTemplate : columnEmptyValueTemplate"
                                          [ngTemplateOutletContext]="{ propertyName: column.propertyName, item: monitoringItem }">
                            </ng-container>
                            }
                            @case (DemurrageMonitoringColumnType.Duration) {
                            <ng-container [ngTemplateOutlet]="columnDurationTemplate" [ngTemplateOutletContext]="{ item: monitoringItem }"></ng-container>
                            }
                            @case (DemurrageMonitoringColumnType.Deviation) {
                            <ng-container [ngTemplateOutlet]="columnDeviationTemplate" [ngTemplateOutletContext]="{ item: monitoringItem }"></ng-container>
                            }
                            @case (DemurrageMonitoringColumnType.Copy) {
                            <div class="dropdown" #showCopyOptions="dropdown" dropdown [attachToBodyTemplateRef]="dropdownCopyShipmentInfoTemplate"
                                 [attachToBodyTemplateOutletContext]="{ item: monitoringItem }">
                                <button type="button" class="image-button ripple-effect ripple-center ripple-inverse-color"
                                        tooltipPopup="Copy" (click)="onShareButtonClick($event)">
                                    <i class="icon-copy"></i>
                                </button>
                            </div>
                            }
                            @case (DemurrageMonitoringColumnType.Share) {
                            <div class="dropdown" #showShareOptions="dropdown" dropdown [attachToBodyTemplateRef]="dropdownShareShipmentInfoTemplate"
                                 [attachToBodyTemplateOutletContext]="{ item: monitoringItem }">
                                <button type="button" class="image-button ripple-effect ripple-center ripple-inverse-color"
                                        tooltipPopup="Share" (click)="onShareButtonClick($event)">
                                    <i class="icon-share-nodes"></i>
                                </button>
                            </div>
                            }
                            @case (DemurrageMonitoringColumnType.Track) {
                            <button type="button" class="image-button ripple-effect ripple-center ripple-inverse-color"
                                    tooltipPopup="Track" (click)="onMarkerShipmentTrackButtonClick($event, monitoringItem)">
                                <i class="icon-route"></i>
                            </button>
                            }
                            @default {
                            <div [tooltipPopup]="column.propertyName ? monitoringItem[column.propertyName] : ''" [showTooltipOnVerflow]="true">
                                <ng-container [ngTemplateOutlet]="columnEmptyValueTemplate"
                                              [ngTemplateOutletContext]="{ propertyName: column.propertyName, item: monitoringItem }">
                                </ng-container>
                            </div>
                            }
                            }
                        </div>
                        }
                    </div>
                    }
                </virtual-list>
            </div>
            <div class="horizontal-expander top">
                <button type="button" class="ripple-effect ripple-center ripple-inverse-color" [class.flipped]="!collapseShipmentsMonitoringMapSection"
                        (click)="collapseShipmentsMonitoringMapSection = !collapseShipmentsMonitoringMapSection">
                    <i class="icon-arrow-down"></i>
                </button>
            </div>
        </div>
    </div>
    }
    @if (!viewIsReady || monitoringItemsModel.isBusy) {
    <loader [@.disabled]="!viewIsInitialized" @fadeInOutAnimation [loadingMessage]="statusMessage"
            [showSpinner]="showSpinner || monitoringItemsModel.isBusy" [isBusyLoader]="viewIsReady && monitoringItemsModel.isBusy" [isBusyLoaderOverlay]="true">
    </loader>
    }
</div>

<!-- columnDeviationTemplate -->
<ng-template #columnDeviationTemplate let-item="item">
    <span [class.deviated]="item.demurrageDeviation !== null && item.demurrageDeviation > 0" [class.not-deviated]="item.demurrageDeviation !== null && item.demurrageDeviation <= 0">
        <span>{{ item.demurrageDeviationFormatted }}</span>
    </span>
</ng-template>

<!-- columnDurationTemplate -->
<ng-template #columnDurationTemplate let-item="item">
    <span [class.outdated]="item.shipmentOutdatedTime !== null">{{ item.demurragePortDurationFormatted }}</span>
</ng-template>

<!-- columnCountryTemplate -->
<ng-template #columnCountryTemplate let-item="item">
    <div class="segment-country" [tooltipPopup]="item.perimeterCountryName">
        <img src="assets/images/flags/{{ item.perimeterCountryCode.toLowerCase() }}.png">
        <span class="country-code">({{ item.perimeterCountryCode }})</span>
    </div>
</ng-template>

<!-- columnEmptyValueTemplate -->
<ng-template #columnEmptyValueTemplate let-propertyName="propertyName" let-item="item">
    <span>{{ !propertyName || item[propertyName] === null ? ('EMPTY_FIELD_VALUE' | globals) : item[propertyName] }}</span>
</ng-template>

<!-- dropdownShareShipmentInfoTemplate -->
<ng-template #dropdownShareShipmentInfoTemplate let-item="item">
    <div class="dropdown-container body">
        <button type="button" class="menu-button ripple-effect ripple-center ripple-inverse-color"
                (click)="onShareSelectedMonitoringInfo(item, false)">
            <i class="icon-list-ul"></i>Share shipment's entire info
        </button>
        <button type="button" class="menu-button ripple-effect ripple-center ripple-inverse-color"
                (click)="onShareSelectedMonitoringLocation(item, false)">
            <i class="icon-location-dot"></i>Share shipment's location
        </button>
    </div>
</ng-template>

<!-- dropdownCopyShipmentInfoTemplate -->
<ng-template #dropdownCopyShipmentInfoTemplate let-item="item">
    <div class="dropdown-container body">
        <button type="button" class="menu-button ripple-effect ripple-center ripple-inverse-color"
                (click)="onShareSelectedMonitoringInfo(item, true)">
            <i class="icon-list-ul"></i>Copy shipment's entire info
        </button>
        <button type="button" class="menu-button ripple-effect ripple-center ripple-inverse-color"
                (click)="onShareSelectedMonitoringLocation(item, true)">
            <i class="icon-location-dot"></i>Copy shipment's location
        </button>
    </div>
</ng-template>

<!-- popupHelpContentTemplate -->
<ng-template #popupHelpContentTemplate>
    <div class="popup-button-content-wrapper" (click)="onPopupContentClick($event)">
        <div class="popup-button-content page-scroll-padding" overlayScrollbar>
            <div class="help-title">How to use the map?</div>
            <div class="help-value">
                <p>The map shows shipments and groups of shipments.</p>
                <p>Groups of shipments are shown as a circle with the number of shipments.</p>
            </div>
            <div class="help-label">Click</div>
            <div class="help-value">
                <p>Clicking a shipment on the map shows its details.</p>
                <p>Clicking a group of shipments shows a list of their combined deviation status.</p>
            </div>
            <div class="help-label">Double Click</div>
            <div class="help-value">
                <p>Double-clicking a shipment or a group of shipments on the map zooms the map in on the clicked item. Zooming in may show some of the group's shipments separately.</p>
            </div>
            <div class="help-label">Icons</div>
            <div class="help-value icons-index">
                <i class="icon-ship"></i><span>Shipment at port</span>
                <i class="icon-demurrage late"></i><span>Shipment in Deviation</span>
                <i class="icon-demurrage early"></i><span>Shipment before Deviation</span>
                <i class="icon-demurrage unknown"></i><span>Shipment Deviation Unknown</span>
            </div>
        </div>
    </div>
</ng-template>

<!-- filterIconTemplate -->
<ng-template #filterIconTemplate>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" [class.active]="monitoringItemsModel.isMonitoringFiltersActive">
        <path d="M 490.30386,94.188357 316.45472,303.83385 v 140.51079 c 0,18.38318 -20.6905,28.91207 -35.55149,18.51503 l -75.34091,-52.7198 c -6.07436,-4.21909 -9.65305,-11.18812 -9.65305,-18.53386 V 303.83385 L 22.036581,94.188357 C 5.7064391,74.496129 20.000493,45.037835 45.886687,45.037835 H 466.4773 c 25.89843,0 39.36562,29.458294 23.82656,49.150522 z"></path>
    </svg>
</ng-template>

<!-- filterButtonIconTemplate -->
<ng-template #filterButtonIconTemplate>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" [class.active]="monitoringItemsModel.isMonitoringFiltersActive">
        <path d="m 223.1,340.7 v -68.5 c 0,-7.369 -2.539,-14.51 -7.191,-20.22 L 50.77,48.04 h 410.4 l -85.57,105.1 c -7.77,9.545 -7.061,23.42 1.643,32.13 10.06,10.06 26.62,9.219 35.6,-1.812 l 88.61,-108.8 c 11.26,-13.46 13.7,-31.89 6.225,-47.92 C 500,10.23 483.8,0 465.3,0 H 46.65 C 28.21,0 11.97,10.23 4.283,26.71 -3.195,42.73 -0.7578,61.16 10.64,74.79 l 165.4,203.1 v 67.71 c 0,12.55 6.178,24.3 16.52,31.45 l 24.81,19.15 c 15.73,12.2 38.63,1 38.63,-19 0,-7.439 -3.449,-14.46 -9.34,-19 z" />
        <path d="M 432 224 C 352.5 224 288 288.5 288 368 C 288 447.5 352.47 512 432 512 C 511.53 512 576 447.53 576 368 C 576 288.47 511.5 224 432 224 z M 386.75781 306.7793 C 390.85131 306.78287 394.94531 308.3494 398.07031 311.4707 L 431.98047 345.38086 L 465.89062 311.4707 C 472.13263 305.2287 482.26195 305.21628 488.50195 311.45898 C 494.74195 317.70168 494.73419 327.82812 488.49219 334.07812 L 454.58203 367.98828 L 488.5 401.90039 C 494.742 408.14239 494.75177 418.26953 488.50977 424.51953 C 482.26977 430.76153 472.14062 430.75011 465.89062 424.50781 L 431.98047 390.59961 L 398.07031 424.50781 C 391.82831 430.74981 381.69922 430.76193 375.44922 424.51953 C 369.19922 418.27713 369.21894 408.14844 375.46094 401.89844 L 409.36914 367.99023 L 375.46094 334.08008 C 369.21894 327.83808 369.20917 317.70898 375.45117 311.45898 C 378.57217 308.33398 382.66431 306.77572 386.75781 306.7793 z " />
    </svg>
</ng-template>

<!-- liveFiltersTemplate -->
<ng-template #liveFiltersTemplate>
    <div #filtersContainer class="live-filters-container page-section page-section-padding">
        <div class="live-filters-header-container">
            <div class="live-filters-header">
                <div class="filters-title select-none"><ng-container [ngTemplateOutlet]="filterIconTemplate"></ng-container>Filters</div>
                @if (('IS_MOBILE' | globals)) {
                <button class="back-button image-button ripple-effect ripple-center ripple-inverse-color"
                        (click)="collapseMonitoringItemsFilters = true">
                    <i class="icon-collapse-left"></i>
                </button>
                }
                <div class="filters-subtitle">
                    <div class="filters-subtitle-label"><span>Live Shipments: </span>{{ monitoringItemsModel.monitoringItemsInfo.count }}</div>
                    <div class="filters-subtitle-label"><span>Routes: </span>{{ monitoringItemsModel.monitoringItemsInfo.routesCount }}</div>
                </div>
            </div>
            <div class="live-filters-header-buttons">
                <button type="button" [disabled]="!monitoringItemsModel.isMonitoringFiltersActive"
                        class="image-button clear-filters-button ripple-effect ripple-inverse-color" (click)="onClearMonitoringItemsFiltersButtonClick()">
                    <ng-template [ngTemplateOutlet]="filterButtonIconTemplate"></ng-template>
                </button>
            </div>
        </div>
        @if (monitoringItemsModel.lastSearchMonitoringFilters !== null) {
        <div class="form-group last-filters" @foldAnimation>
            <button type="button" class="link-button" (click)="onloadLastSeachFiltersClick()">Load last filters</button>
        </div>
        }
        <div class="live-filters-content-container page-section-scroll-padding" overlayScrollbar>
            <div class="form-group">
                <div class="filters-buttons-label">
                    <span class="select-none">Demurrage Statuses</span>
                    @if (monitoringItemsModel.editMonitoringFilters.selectedShipmentMonitoringDemurrageStatuses.length > 0) {
                    <i class="icon-cancel control-icon press-icon ripple-effect ripple-center"
                       (mousedown)="onFilterDemurrageStatusSelectClear()" @fadeInOutAnimation>
                    </i>
                    }
                </div>
                <div class="demurrage-statuses-buttons-container">
                    @for (filterElement of filterDemurrageStatusElements; track filterElement) {
                    <label class="radio-container demurrage-status ripple-effect ripple-fill ripple-inverse-color"
                           [class.disabled]="filterElement.value === 0" [class]="filterElement.class" [tooltipPopup]="filterElement.tooltip">
                        <input type="radio" (change)="onFilterDemurrageStatusSelectChange(filterElement.filterType)" name="demurrage-status"
                               [checked]="monitoringItemsModel.editMonitoringFilters.selectedShipmentMonitoringDemurrageStatuses.includes(filterElement.filterType)">
                        <span class="container-background"></span>
                        <i class="icon-demurrage" [class]="DemurrageContractDeviationType[filterElement.filterType].toLowerCase()"></i>
                        <span class="radio-text">{{ filterElement.value }}</span>
                    </label>
                    }
                </div>
            </div>
            <div class="form-group" [class.disabled]="monitoringItemsModel.monitoringItemsInfo.maxDeviation === 0">
                <span class="visual-content-label select-none">Min. Days in Deviation (0 to {{ monitoringItemsModel.monitoringItemsInfo.maxDeviation }})</span>
                <div class="numeric-slider-group">
                    <numeric-input name="deviated" #deviated="ngModel" [(ngModel)]="monitoringItemsModel.editMonitoringFilters.minDeviation"
                                   [maxValue]="monitoringItemsModel.monitoringItemsInfo.maxDeviation" (change)="onFilterFieldChange()">
                    </numeric-input>
                    <slider [(ngModel)]="monitoringItemsModel.editMonitoringFilters.minDeviation" [maxValue]="monitoringItemsModel.monitoringItemsInfo.maxDeviation"></slider>
                </div>
            </div>
            <div class="form-group" [class.disabled]="monitoringItemsModel.monitoringItemsInfo.maxBeforeDeviation === 0">
                <span class="visual-content-label select-none">Max. Days to Deviation (0 to {{ monitoringItemsModel.monitoringItemsInfo.maxBeforeDeviation }})</span>
                <div class="numeric-slider-group">
                    <numeric-input name="deviated" #deviated="ngModel" [(ngModel)]="monitoringItemsModel.editMonitoringFilters.maxBeforeDeviation"
                                   [maxValue]="monitoringItemsModel.monitoringItemsInfo.maxBeforeDeviation" (change)="onFilterFieldChange()">
                    </numeric-input>
                    <slider [(ngModel)]="monitoringItemsModel.editMonitoringFilters.maxBeforeDeviation" [maxValue]="monitoringItemsModel.monitoringItemsInfo.maxBeforeDeviation"></slider>
                </div>
            </div>
            <div class="form-group" [class.disabled]="monitoringItemsModel.monitoringItemsInfo.maxPortDuration === 0">
                <span class="visual-content-label select-none">Min. Duration Days (0 to {{ monitoringItemsModel.monitoringItemsInfo.maxPortDuration }})</span>
                <div class="numeric-slider-group">
                    <numeric-input name="deviated" #deviated="ngModel" [(ngModel)]="monitoringItemsModel.editMonitoringFilters.minDuration"
                                   [maxValue]="monitoringItemsModel.monitoringItemsInfo.maxPortDuration" (change)="onFilterFieldChange()">
                    </numeric-input>
                    <slider [(ngModel)]="monitoringItemsModel.editMonitoringFilters.minDuration" [maxValue]="monitoringItemsModel.monitoringItemsInfo.maxPortDuration"></slider>
                </div>
            </div>
            <div class="form-group">
                <select-list placeholder="Port Types" [multiple]="true" emptyText="All"
                             [items]="monitoringItemsModel.filteredMonitoringDataLists.shipmentMonitoringSegments"
                             [(ngModel)]="monitoringItemsModel.editMonitoringFilters.selectedShipmentMonitoringSegments" (change)="onFilterFieldChange()">
                </select-list>
            </div>
            @if (loginModel.isAccountTypeAmazonUS) {
            <div class="form-group">
                <select-list placeholder="Trailers Numbers" [multiple]="true" emptyText="All"
                             [items]="monitoringItemsModel.filteredMonitoringDataLists.shipmentMonitoringTrailersNumbers"
                             [(ngModel)]="monitoringItemsModel.editMonitoringFilters.selectedShipmentMonitoringTrailersNumbers" (change)="onFilterFieldChange()">
                </select-list>
            </div>
            }
            @if (!loginModel.isAccountTypeAmazonUS) {
            <div class="form-group">
                <select-list placeholder="{{ containerIdColumnName }}s" [multiple]="true" emptyText="All"
                             [items]="monitoringItemsModel.filteredMonitoringDataLists.shipmentMonitoringCargosUnits"
                             [(ngModel)]="monitoringItemsModel.editMonitoringFilters.selectedShipmentMonitoringCargosUnits" (change)="onFilterFieldChange()">
                </select-list>
            </div>
            }
            <div class="form-group">
                <select-list placeholder="Carriers" [multiple]="true" emptyText="All"
                             [items]="monitoringItemsModel.filteredMonitoringDataLists.shipmentMonitoringCarriers"
                             [(ngModel)]="monitoringItemsModel.editMonitoringFilters.selectedShipmentMonitoringCarriers" (change)="onFilterFieldChange()">
                </select-list>
            </div>
            <div class="form-group">
                <select-list placeholder="Ports" [multiple]="true" emptyText="All"
                             [items]="monitoringItemsModel.filteredMonitoringDataLists.shipmentMonitoringPorts"
                             [(ngModel)]="monitoringItemsModel.editMonitoringFilters.selectedShipmentMonitoringPorts" (change)="onFilterFieldChange()">
                </select-list>
            </div>
            <div class="form-group">
                <select-list placeholder="Origins" [multiple]="true" emptyText="All"
                             [items]="monitoringItemsModel.filteredMonitoringDataLists.shipmentMonitoringOriginsRoutes"
                             [(ngModel)]="monitoringItemsModel.editMonitoringFilters.selectedShipmentMonitoringOriginsRoutes" (change)="onFilterFieldChange()">
                </select-list>
            </div>
            <div class="form-group">
                <select-list placeholder="Destinations" [multiple]="true" emptyText="All"
                             [items]="monitoringItemsModel.filteredMonitoringDataLists.shipmentMonitoringDestinationsRoutes"
                             [(ngModel)]="monitoringItemsModel.editMonitoringFilters.selectedShipmentMonitoringDestinationsRoutes" (change)="onFilterFieldChange()">
                </select-list>
            </div>
            <div class="form-group">
                <select-list placeholder="CG-IDs" [multiple]="true" emptyText="All" [items]="monitoringItemsModel.filteredMonitoringDataLists.shipmentMonitoringKeys"
                             [(ngModel)]="monitoringItemsModel.editMonitoringFilters.selectedShipmentMonitoringKeys" (change)="onFilterFieldChange()">
                </select-list>
            </div>
        </div>
    </div>
</ng-template>

<!-- infoWindowMarkerClusterTemplate -->
<ng-template #infoWindowMarkerClusterTemplate let-info>
    <div class="marker-info-container page-scroll-padding select-none">
        <div class="marker-info-content-container">
            <div class="marker-info-content select-none">
                <div class="marker-info-item-label">
                    <button type="button" class="image-button" (click)="onClusterInfoExpandButtonClick()"><i class="icon-expand"></i></button>
                    <span>Demurrage Status</span>
                </div>
                <div class="marker-info-item-value center">
                    @if (info.lateShipmentsCount > 0) {
                    <div class="arrival-status-value">
                        <span>{{ info.lateShipmentsCount }}</span><i class="icon-demurrage late"></i>
                    </div>
                    }
                    @if (info.earlyShipmentsCount > 0) {
                    <div class="arrival-status-value">
                        <span>{{ info.earlyShipmentsCount }}</span><i class="icon-demurrage early"></i>
                    </div>
                    }
                    @if (info.unknownShipmentsCount > 0) {
                    <div class="arrival-status-value">
                        <span>{{ info.unknownShipmentsCount }}</span><i class="icon-demurrage unknown"></i>
                    </div>
                    }
                </div>
            </div>
        </div>
    </div>
</ng-template>

<!-- infoWindowMarkerTemplate -->
<ng-template #infoWindowMarkerTemplate let-monitoringItems="info" let-isCollapsable="isCollapsable">
    <div class="marker-info-container page-scroll-padding" [class.multiple]="monitoringItems.length > 1">
        <virtual-list #infoItemsList [items]="monitoringItems" [isAutoSizeToContent]="monitoringItems.length > 1" [isMouseWheelScrollSnap]="false"
                      (itemsChange)="onInfoListItemsChange()">
            @for (monitoringItem of infoItemsList.viewPortItems; track monitoringItem.contractId) {
            <div #body class="marker-info-content-container">
                <div class="marker-info-content-wrapper">
                    <div class="marker-info-content" [class.clickable]="monitoringItems.length > 1" (click)="onMonitoringItemMapClick(monitoringItem.shipmentKey!)">
                        @if (monitoringItems.length > 1) {
                        <div class="marker-info-item-index">{{ monitoringItems.indexOf(monitoringItem) + 1 }} of {{ monitoringItems.length }}</div>
                        }
                        <div class="marker-info-item-title">
                            <span class="title-name" [tooltipPopup]="monitoringItem.perimeterName" [showTooltipOnVerflow]="true">{{ monitoringItem.perimeterName }}</span>
                            <ng-container [ngTemplateOutlet]="monitoringItem.perimeterCountryCode !== null && monitoringItem.perimeterCountryName !== null ?
                                                  columnCountryTemplate : columnEmptyValueTemplate"
                                          [ngTemplateOutletContext]="{ propertyName: '', item: monitoringItem }">
                            </ng-container>
                        </div>
                        <div class="marker-info-item-label">{{ loginModel.isAccountTypeAmazonUS ? 'Trailer No.' : containerIdColumnName }}</div>
                        <div class="marker-info-item-value">{{ loginModel.isAccountTypeAmazonUS ? monitoringItem.trailerNumber : monitoringItem.containerId }}</div>
                        @if (monitoringItem.demurrageDeviation !== null) {
                        <div class="marker-info-item-label">Deviation</div>
                        <div class="marker-info-item-value">
                            <ng-container [ngTemplateOutlet]="columnDeviationTemplate" [ngTemplateOutletContext]="{ item: monitoringItem }"></ng-container>
                        </div>
                        }
                        <div class="marker-info-item-label">Duration</div>
                        <div class="marker-info-item-value">
                            <ng-container [ngTemplateOutlet]="columnDurationTemplate" [ngTemplateOutletContext]="{ item: monitoringItem }"></ng-container>
                        </div>
                        <div class="marker-info-item-label">Arrival Status</div><div class="marker-info-item-value center">
                            <i [class]="arrivalStatusesStyleProperties[monitoringItem.arrivalStatusId].iconClassName"></i>
                            <span>{{ monitoringItem.delayString }}</span>
                        </div>
                        <div class="marker-info-item-label">ETA<span class="small-units">{{ appSettingsService.appSettings.isUsingUTCTime ? '&nbsp;(UTC)' : '' }}</span></div><div class="marker-info-item-value">{{ monitoringItem.etaFormatted }}</div>
                        <div class="marker-info-item-label">{{ deviceIdColumnName }}</div><div class="marker-info-item-value">{{ monitoringItem.deviceDescription }}</div>
                        @if (monitoringItems.length > 1) {
                        @for (item of monitoringItem.infoWindowExtraRows; track item) {
                        <div class="marker-info-item-label">&nbsp;</div>
                        <div class="marker-info-item-value">&nbsp;</div>
                        }
                        }
                    </div>
                    <div class="marker-info-item-buttons" (click)="onMonitoringItemMapClick(monitoringItem.shipmentKey!)">
                        <div class="dropdown" #showPopupCopyOptions="dropdown" dropdown [attachToBodyTemplateRef]="dropdownCopyShipmentInfoTemplate"
                             [attachToBodyTemplateOutletContext]="{ item: monitoringItem }">
                            <button type="button" class="image-button ripple-effect ripple-center ripple-inverse-color" tooltipPopup="Copy"
                                    (click)="onMonitoringShareCopyDropdownClick($event)">
                                <i class="icon-copy"></i>
                            </button>
                        </div>
                        <div class="dropdown" #showPopupShareOptions="dropdown" dropdown [attachToBodyTemplateRef]="dropdownShareShipmentInfoTemplate"
                             [attachToBodyTemplateOutletContext]="{ item: monitoringItem }">
                            <button type="button" class="image-button ripple-effect ripple-center ripple-inverse-color" tooltipPopup="Share"
                                    (click)="onMonitoringShareCopyDropdownClick($event)">
                                <i class="icon-share-nodes"></i>
                            </button>
                        </div>
                        @if (!('IS_MOBILE' | globals)) {
                        <button type="button" class="image-button ripple-effect ripple-fill ripple-inverse-color"
                                tooltipPopup="Track" (click)="onMarkerShipmentTrackButtonClick(null, monitoringItem)">
                            <i class="icon-route"></i>
                        </button>
                        }
                    </div>
                </div>
            </div>
            }
        </virtual-list>
        @if (isCollapsable) {
        <button type="button" class="image-button collapse-button" (click)="onClusterInfoCollapseButtonClick()">
            <i class="icon-collapse"></i>
        </button>
        }
    </div>
</ng-template>
