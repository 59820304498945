import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { fadeInAnimation, fadeInOutAnimation, fadeSlideDownInOutAnimation, shortFadeInOutAnimation } from '../../animations/fade.animation';
import { foldFadeHorizontalAnimation } from '../../animations/fold.animation';
import { InventoryColumnType } from './model/inventory-manager-model.class';
import { DisplayInventory } from '../inventory/model/inventory-model.class';
import { InventoryManagerModel } from './model/inventory-manager.model';
import { Router } from '@angular/router';
import { AnimationBuilder } from '@angular/animations';
import { AppSettingsService } from '../../services/app-settings.service';
import { RoutingHistoryService } from '../../services/routing-history.service';
import { ModalMessageService } from '../../controls/modal-message/services/modal-message.service';
import { Utils } from '../../utils/utils';
import { ModalButtonType, ModalIconType, ModalResultType, ModalType } from '../../controls/modal-message/modal-message.component';
import { Constants, IApiResponse, RouteType } from '../../utils/globals';
import { ManagerBaseComponent } from '../../base/components/manager-base.component';
import { VirtualListInfo } from '../../controls/virtual-list/model/virtual-list-info';
import { InventoryComponent } from '../inventory/inventory.component';
import { GlobalsPipe } from '../../pipes/globals.pipe';
import { LoaderComponent } from '../../controls/loader/loader.component';
import { NgClass } from '@angular/common';
import { ElementResizedDirective } from '../../directives/element-resized.directive';
import { PointerEventsDirective } from '../../directives/pointer-events.directive';
import { VirtualListComponent } from '../../controls/virtual-list/virtual-list.component';
import { OverlayScrollbarComponent } from '../../controls/overlay-scrollbar/overlay-scrollbar.component';
import { SelectListComponent } from '../../controls/select-list/select-list.component';
import { DateTimePickerComponent } from '../../controls/datetime-picker/datetime-picker.component';
import { TooltipDirective } from '../../directives/tooltip.directive';
import { FormsModule } from '@angular/forms';
import { ClearableInputComponent } from '../../controls/clearable-input/clearable-input.component';
import { TopBarComponent } from '../../controls/top-bar/top-bar.component';

@Component({
    selector: 'inventory-manager',
    templateUrl: './inventory-manager.component.html',
    styleUrls: ['../../controls/modal-message/modal-message.component.css', '../../base/styles/manager-base.css', '../../base/styles/manager-table.css',
        './inventory-manager.component.css'],
    animations: [fadeInOutAnimation, fadeInAnimation, fadeSlideDownInOutAnimation, shortFadeInOutAnimation, foldFadeHorizontalAnimation],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [TopBarComponent, ClearableInputComponent, FormsModule, TooltipDirective, DateTimePickerComponent, SelectListComponent, OverlayScrollbarComponent,
        VirtualListComponent, PointerEventsDirective, ElementResizedDirective, NgClass, InventoryComponent, LoaderComponent, GlobalsPipe]
})
export class InventoryManagerComponent extends ManagerBaseComponent<DisplayInventory, InventoryColumnType>
{
    // #region Constants

    protected override readonly EXCEL_FILE_PREFIX: string = 'InventoryManager_';

    // #endregion

    // #region Protected Members

    protected static override _managerItemsListInfo: VirtualListInfo = new VirtualListInfo();
    protected static override _managerTableListInfo: VirtualListInfo = new VirtualListInfo();
    protected static override _isGridView: boolean = Constants.IS_MOBILE;
    protected static override _gridViewItemsPerRow: number = 1;

    @ViewChild('managerItem', { read: InventoryComponent, static: false }) protected override _managerItemComponent: InventoryComponent | undefined = undefined;

    // #endregion

    // #region Properties

    public override get isDirty(): boolean
    {
        return this._managerItemComponent === undefined ? false : this._managerItemComponent.isDirty;
    }

    public get InventoryColumnType()
    {
        return InventoryColumnType;
    }

    public override get managerModel(): InventoryManagerModel
    {
        return this._managerModel as InventoryManagerModel;
    }

    // #endregion

    // #region Constructor

    constructor(_managerModel: InventoryManagerModel, _router: Router, _changeDetectorRef: ChangeDetectorRef,
        _routingHistoryService: RoutingHistoryService, _animationBuilder: AnimationBuilder, _appSettingsService: AppSettingsService,
        _modalMessageService: ModalMessageService)
    {
        super(_managerModel, _router, _changeDetectorRef, _routingHistoryService, _animationBuilder, _appSettingsService, _modalMessageService);

        this.initializeManagerTableColumns();
    }

    // #endregion

    // #region Event Handlers

    protected override selectManagerItem(managerItem: DisplayInventory | null): void
    {
        super.selectManagerItem(managerItem);

        if (Constants.IS_MOBILE)
        {
            this._router.navigate([`/${RouteType.InventoryDetails}`]);
        }
    }

    public async onInventoryDeleteButtonClick(event: MouseEvent, displayInventory: DisplayInventory): Promise<void>
    {
        event.stopImmediatePropagation();

        const modalResult: ModalResultType = await this._modalMessageService.show(
            {
                title: Constants.APP_TITLE,
                message: 'You won\'t be able to undo inventory deletions. Are you sure?',
                modalType: ModalType.Error,
                modalIcon: ModalIconType.Question,
                modalButton: ModalButtonType.YesNo
            });

        if (modalResult !== ModalResultType.Yes)
        {
            return;
        }

        this._statusMessage = '<b>Deleting inventory item.</b> Please wait...';
        this._viewIsReady = false;

        this.managerModel.deleteManagerItem(displayInventory).subscribe((response: IApiResponse) =>
        {
            if (response.isSuccess)
            {
                this.loadManagerElements();
            }
            else if (response.message !== undefined)
            {
                this._statusMessage = response.message;
                this._changeDetectorRef.detectChanges();
            }
        });

        this._changeDetectorRef.detectChanges();
    }

    // #endregion

    // #region Protected Methods

    protected override initializeManagerTableColumns(): void
    {
        const displayInventory: DisplayInventory = new DisplayInventory();

        this.managerTableColumns =
            [
                {
                    columnType: InventoryColumnType.Warehouse,
                    text: 'Warehouse',
                    classes: 'col-warehouse',
                    propertyName: Utils.getPropertyNameof<DisplayInventory>(displayInventory, displayInventory => displayInventory.warehouseName)
                },
                {
                    columnType: InventoryColumnType.Date,
                    text: `Date<span class="small-units">${(this._appSettingsService.appSettings.isUsingUTCTime ? ' (UTC)' : '')}</span>`,
                    propertyName: Utils.getPropertyNameof<DisplayInventory>(displayInventory, displayInventory => displayInventory.dateFormatted),
                    sortPropertyName: Utils.getPropertyNameof<DisplayInventory>(displayInventory, displayInventory => displayInventory.date)
                },
                {
                    columnType: InventoryColumnType.Locks,
                    text: 'Locks',
                    classes: 'center',
                    propertyName: Utils.getPropertyNameof<DisplayInventory>(displayInventory, displayInventory => displayInventory.locks)
                },
                {
                    columnType: InventoryColumnType.Shackles,
                    text: 'Shackles',
                    classes: 'center',
                    propertyName: Utils.getPropertyNameof<DisplayInventory>(displayInventory, displayInventory => displayInventory.shackles)
                },
                {
                    columnType: InventoryColumnType.Cables,
                    text: 'Cables',
                    classes: 'center',
                    propertyName: Utils.getPropertyNameof<DisplayInventory>(displayInventory, displayInventory => displayInventory.cables)
                },
                {
                    columnType: InventoryColumnType.Chargers,
                    text: 'Chargers',
                    classes: 'center',
                    propertyName: Utils.getPropertyNameof<DisplayInventory>(displayInventory, displayInventory => displayInventory.chargers)
                },
                {
                    columnType: InventoryColumnType.Notes,
                    text: 'Notes',
                    classes: 'col-note col-overflow',
                    propertyName: Utils.getPropertyNameof<DisplayInventory>(displayInventory, displayInventory => displayInventory.notes)
                }
            ];

        if (this.managerModel.isAdministrator)
        {
            this._managerTableColumns.push(
                {
                    columnType: InventoryColumnType.Delete,
                    classes: 'col-button center',
                    isFixedWidth: true
                }
            )
        }

        this._managerListColumns = [...this._managerTableColumns];

        this._managerTableSortColumns = [...this._managerTableColumns];
    }

    // #endregion
}
