import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { fadeInOutAnimation } from '../../animations/fade.animation';
import { foldAnimation } from '../../animations/fold.animation';
import { Router } from '@angular/router';
import { AppSettingsService } from '../../services/app-settings.service';
import { InventoryModel } from './model/inventory.model';
import { Constants, RouteType } from '../../utils/globals';
import { RoutingHistoryService } from '../../services/routing-history.service';
import { ModalMessageService } from '../../controls/modal-message/services/modal-message.service';
import { ManagerItemBaseComponent } from '../../base/components/manager-item-base.component';
import { DisplayInventory } from './model/inventory-model.class';
import { TextAreaAutosizeDirective } from '../../directives/textarea-autoresize.directive';
import { NumericInputComponent } from '../../controls/numeric-input/numeric-input.component';
import { DateTimePickerComponent } from '../../controls/datetime-picker/datetime-picker.component';
import { SelectListComponent } from '../../controls/select-list/select-list.component';
import { EmailValidatorDirective } from '../../validators/email.validator';
import { TextChractersCounterComponent } from '../../base/components/text-chracters-counter.component';
import { RequiredValidatorDirective } from '../../validators/required.validator';
import { ClearableInputComponent } from '../../controls/clearable-input/clearable-input.component';
import { OverlayScrollbarDirective } from '../../directives/overlay-scrollbar/overlay-scrollbar.directive';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import { FormIgnoreEnterDirective } from '../../directives/form-ignore-enter.directive';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'inventory',
    templateUrl: './inventory.component.html',
    styleUrls: ['../../base/styles/manager-item-base.css', './inventory.component.css'],
    animations: [fadeInOutAnimation, foldAnimation],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [FormsModule, FormIgnoreEnterDirective, NgClass, OverlayScrollbarDirective, NgTemplateOutlet, ClearableInputComponent, RequiredValidatorDirective,
        TextChractersCounterComponent, EmailValidatorDirective, SelectListComponent, DateTimePickerComponent, NumericInputComponent, TextAreaAutosizeDirective]
})
export class InventoryComponent extends ManagerItemBaseComponent<DisplayInventory> implements OnInit, OnDestroy
{
    // #region Constants

    private readonly EDIT_VALID_DURATION: number = 1000 * 60 * 60;

    // #endregion

    // #region Private Members

    private _isViewOnly: boolean = true;
    private _editTimeoutHandleId: NodeJS.Timeout | null = null;

    // #endregion

    // #region Properties

    public get appSettingsService(): AppSettingsService
    {
        return this._appSettingsService;
    }

    public get isViewOnly(): boolean
    {
        return this._isViewOnly;
    }

    public override get managerItemModel(): InventoryModel
    {
        return this._managerItemModel as InventoryModel;
    }

    // #endregion

    // #region Constructor

    constructor(_managerItemModel: InventoryModel, _changeDetectorRef: ChangeDetectorRef, _routingHistoryService: RoutingHistoryService,
        _modalMessageService: ModalMessageService, private _router: Router, private _appSettingsService: AppSettingsService)
    {
        super(_managerItemModel, _changeDetectorRef, _routingHistoryService, _modalMessageService);
    }

    // #endregion

    // #region Event Handlers

    public override ngOnInit(): void
    {
        if (this._routingHistoryService.isPopState && this._managerItemModel.isInitialized)
        {
            this.checkEditPermissions();
        }

        super.ngOnInit();
    }

    public override ngOnDestroy(): void
    {
        super.ngOnDestroy();

        this.clearEditTimeout();
    }

    public override onCancelManagerItemButtonClick(): void
    {
        if (Constants.IS_MOBILE)
        {
            this.navigateToManger();
            return;
        }

        super.onCancelManagerItemButtonClick();
    }

    // #endregion

    // #region Public Methods

    public override loadManagerItem(isScrollToTop: boolean = true): void
    {
        super.loadManagerItem(isScrollToTop)

        setTimeout(() =>
        {
            this.checkEditPermissions();
            this._changeDetectorRef.markForCheck();
        });
    }

    // #endregion

    // #region Protected Methods

    protected override navigateToManger(): void
    {
        this._router.navigate([`/${RouteType.InventoryManager}`]);
    }

    protected override submitManagerItem(isDelete: boolean = false): void
    {
        this._statusMessage = `<b>${isDelete ? 'Deleting' : (this.managerItemModel.managerItem.inventoryId !== null ? 'Updating' : 'Adding new')} inventory.</b> Please wait...`;

        super.submitManagerItem(isDelete);
    }

    // #endregion

    // #region Private Methods

    private checkEditPermissions(): void
    {
        this.clearEditTimeout();

        if (!this.managerItemModel.isAdministrator)
        {
            if (this.managerItemModel.managerItem.date === null)
            {
                this._isViewOnly = false;
            }
            else
            {
                const diffTimeFromNow: number = (new Date()).getTime() - this.managerItemModel.managerItem.date.getTime();
                if (diffTimeFromNow > this.EDIT_VALID_DURATION)
                {
                    this._isViewOnly = true;
                }
                else
                {
                    this._isViewOnly = false;

                    this._editTimeoutHandleId = setTimeout(() =>
                    {
                        this._isViewOnly = true;
                        this._changeDetectorRef.detectChanges();
                    }, diffTimeFromNow);
                }
            }
        }
        else
        {
            this._isViewOnly = false;
        }
    }

    private clearEditTimeout(): void
    {
        if (this._editTimeoutHandleId !== null)
        {
            clearTimeout(this._editTimeoutHandleId);
            this._editTimeoutHandleId = null;
        }
    }

    // #endregion
}
