import { Component, Input, ElementRef, HostListener, HostBinding } from '@angular/core';
import { KeyValue } from '../../utils/globals';
import { AppMenuService } from '../../services/app-menu.service';
import { NgClass, NgStyle, NgTemplateOutlet } from '@angular/common';

@Component({
    selector: 'loader',
    templateUrl: './loader.component.html',
    styleUrls: ['./loader.component.css'],
    standalone: true,
    imports: [NgClass, NgStyle, NgTemplateOutlet]
})
export class LoaderComponent
{
	// #region Private Mebers

	private _loadingIcon: string | null = null;

	// #endregion

	// #region Properties

	public get loadingIcon(): string | null
	{
		return this._loadingIcon;
	}

	// #endregion

	// #region Inputs

	@Input() public showSpinner: boolean = true;
	@Input() public loadingMessage: string | null = null;
	@Input() public loadingProgress: number | null = null;

	@HostBinding('class.busy-loader') @Input() public isBusyLoader: boolean = false;
	@HostBinding('class.busy-loader-overlay') @Input() public isBusyLoaderOverlay: boolean = false;

	// #endregion

	// #region Constructors

	constructor(private _elementRef: ElementRef<HTMLElement>, private _appMenuService: AppMenuService)
	{
		this._elementRef.nativeElement.oncontextmenu = () =>
		{
			return false;
		}

		this._loadingIcon = this._appMenuService.activeAppMenuItem?.iconClass ?? null;
	}

	// #endregion

	// #region Event Handlers

	@HostListener('document:keydown', ['$event']) onKeydownHandler(event: KeyboardEvent): void
	{
		if (this.isBusyLoader && (event.key === KeyValue.Tab ||
			(this._elementRef.nativeElement.parentElement !== null && this._elementRef.nativeElement.parentElement.contains(document.activeElement))))
		{
			event.preventDefault();
		}
	}

	// #endregion
}