import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { fadeInAnimation, fadeInOutAnimation, fadeVisibilityAnimation } from '../../animations/fade.animation';
import { foldAnimation } from '../../animations/fold.animation';
import { ModalType } from '../../controls/modal-message/modal-message.component';
import { ModalBodyAlignType, ModalMessageService } from '../../controls/modal-message/services/modal-message.service';
import { Constants, IApiResponse } from '../../utils/globals';
import { ResetPasswordModel } from './model/reset-password.model';
import { RoutingHistoryService } from '../../services/routing-history.service';
import { EmailValidatorDirective } from '../../validators/email.validator';
import { RequiredValidatorDirective } from '../../validators/required.validator';
import { ClearableInputComponent } from '../../controls/clearable-input/clearable-input.component';
import { FormsModule } from '@angular/forms';
import { RouterLink } from '@angular/router';

@Component({
    selector: 'reset-password',
    templateUrl: './reset-password.component.html',
    styleUrls: ['../login/login.component.css', './reset-password.component.css'],
    animations: [foldAnimation, fadeInOutAnimation, fadeInAnimation, fadeVisibilityAnimation],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [RouterLink, FormsModule, ClearableInputComponent, RequiredValidatorDirective, EmailValidatorDirective]
})
export class ResetPasswordComponent implements OnDestroy
{
    // #region Private Members

    private _resetPasswordSubscription: Subscription | null = null;
    private _showResetPasswordMessage: boolean = false;

    // #endregion

    // #region Properties

    public get resetPasswordModel(): ResetPasswordModel
    {
        return this._resetPasswordModel;
    }

    public get showResetPasswordMessage(): boolean
    {
        return this._showResetPasswordMessage;
    }

    // #endregion

    // #region Constructor

    constructor(private _resetPasswordModel: ResetPasswordModel, private _changeDetectorRef: ChangeDetectorRef,
        private _modalMessageService: ModalMessageService, private _routingHistoryService: RoutingHistoryService)
    {
        this._resetPasswordModel.clear();
    }

    // #endregion

    // #region Event Handlers

    public ngOnDestroy(): void
    {
        if (this._resetPasswordSubscription !== null)
        {
            this._resetPasswordSubscription.unsubscribe();
            this._resetPasswordSubscription = null;
        }
    }

    public onBackButtonClick(): void
    {
        this._routingHistoryService.navigateBack(true);
    }

    public onResetPasswordSubmit(): void
    {
        this._resetPasswordSubscription = this._resetPasswordModel.resetPassword().subscribe((response: IApiResponse) =>
        {
            if (response.isSuccess)
            {
                this._showResetPasswordMessage = true;
                this._changeDetectorRef.detectChanges();
            }
            else
            {
                this._changeDetectorRef.detectChanges();
                this._modalMessageService.show(
                    {
                        title: Constants.APP_TITLE,
                        message: response.message,
                        modalType: ModalType.Error,
                        modlaBodyAlign: ModalBodyAlignType.Left
                });
            }
        });
    }

    // #endregion

}