import { Component } from "@angular/core";
import { BaseView } from "../classes/base-view";
import { ModalMessageService } from "../../controls/modal-message/services/modal-message.service";
import { ModalButtonType, ModalIconType, ModalResultType, ModalType } from "../../controls/modal-message/modal-message.component";
import { Constants } from "../../utils/globals";
import { Observable } from "rxjs";

interface ICanComponentDeactivate
{
    canDeactivate?: () => Observable<boolean> | Promise<boolean> | boolean;
}

@Component({ template: '' })
export class SavableBaseComponent extends BaseView implements ICanComponentDeactivate
{
    // #region Properties

    public get isDirty(): boolean
    {
        return false;
    }

    // #endregion

    // #region Constructor

    constructor(protected _modalMessageService: ModalMessageService)
    {
        super();
    }

    // #endregion

    // #region ICanComponentDeactivate

    public async canDeactivate(): Promise<boolean>
    {
        if (!this.isDirty)
        {
            return true;
        }

        const modalResult: ModalResultType = await this._modalMessageService.show(
            {
                title: Constants.APP_TITLE,
                message: 'Are you sure you want to leave without saving?',
                modalType: ModalType.Error,
                modalIcon: ModalIconType.Question,
                modalButton: ModalButtonType.YesNo
            });

        return modalResult === ModalResultType.Yes;
    }

    // #endregion
}