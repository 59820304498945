import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, forwardRef, HostListener, Input, OnDestroy, Output, ViewChild } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, FormsModule } from '@angular/forms';
import { default as moment } from "moment";
import { fadeInOutAnimation, fadeZoomInOutAnimation } from '../../../animations/fade.animation';
import { Utils } from '../../../utils/utils';
import { NumericStringInputComponent } from '../../numeric-string-input/numeric-string-input.component';

export enum MeridiemType { AM, PM }
export enum TimeViewType { Editor, ClockHours, ClockMinutes }

@Component({
    selector: 'time-picker-view',
    templateUrl: './time-picker-view.component.html',
    styleUrls: ['./time-picker-view.component.css'],
    animations: [fadeInOutAnimation, fadeZoomInOutAnimation],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => TimePickerViewComponent), multi: true }
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NumericStringInputComponent, FormsModule]
})
export class TimePickerViewComponent implements ControlValueAccessor, OnDestroy, AfterViewInit
{
    // #region Private Constants

    private readonly SPIN_BUTTON_INTERVAL: number = 150;
    private readonly CLOCK_RADIUS_OFFSET: number = 28;
    private readonly CLOCK_CENTER_RADIUS: number = 5;
    private readonly COLOR_PRIMARY_PROPERTY: string = '--color-primary';
    private readonly COLOR_TEXT_PROPERTY: string = '--color-text';
    private readonly COLOR_TEXT_INVERSE_PROPERTY: string = '--color-text-inverse';
    private readonly COLOR_CLOCK_BACKGROUND_PROPERTY: string = '--color-clock-background';

    // #endregion

    // #region Private Memers

    private _isDuringMouseDrag: boolean = false;

    private _meridiemType: MeridiemType = MeridiemType.AM;

    private _meridiemValues: string[] = [];

    private _isClearNextSpinPress: boolean = false;

    private _changed = new Array<(value: Date | null) => void>();
    private _touched = new Array<() => void>();

    private _innerValue: Date | null = null;

    private _hours: string | null = null;
    private _minutes: string | null = null;

    private _spinPressIntervalHandleId: NodeJS.Timeout | null = null;

    private _canvasSize: number = 0;

    private _clockHoursCanvasElement: HTMLCanvasElement | null = null;
    @ViewChild('clockHoursCanvas', { read: ElementRef, static: false }) set clockHoursCanvas(clockHoursCanvasElementRef: ElementRef<HTMLCanvasElement>)
    {
        if (clockHoursCanvasElementRef !== undefined)
        {
            this._clockHoursCanvasElement = clockHoursCanvasElementRef.nativeElement;
            this.drawClockHours();
        }
    }

    private _clockMinutesCanvasElement: HTMLCanvasElement | null = null;
    @ViewChild('clockMinutesCanvas', { read: ElementRef, static: false }) set clockMinutesCanvas(clockMinutesCanvasElementRef: ElementRef<HTMLCanvasElement>)
    {
        if (clockMinutesCanvasElementRef !== undefined)
        {
            this._clockMinutesCanvasElement = clockMinutesCanvasElementRef.nativeElement;
            this.drawClockMinutes();
        }
    }

    // #endregion

    // #region Proterties

    public timeViewType: TimeViewType = TimeViewType.ClockHours;

    public get TimeViewType()
    {
        return TimeViewType;
    }

    public get MeridiemType()
    {
        return MeridiemType;
    }

    public get meridiemType(): MeridiemType
    {
        return this._meridiemType;
    }

    public set meridiemType(value: MeridiemType)
    {
        this._meridiemType = value;
        this.updateValueFromTimeParts();
    }

    public get meridiemValues(): string[]
    {
        return this._meridiemValues;
    }

    public get hours(): string | null
    {
        return this._hours
    }

    public set hours(value: string | null)
    {
        this._hours = value;
        this.updateValueFromTimeParts();
    }

    public get minutes(): string | null
    {
        return this._minutes
    }

    public set minutes(value: string | null)
    {
        this._minutes = value;
        this.updateValueFromTimeParts();
    }

    public get value(): Date | null
    {
        return this._innerValue;
    }

    public set value(date: Date | null)
    {
        if (date !== null && date !== undefined && this._innerValue !== date)
        {
            this._innerValue = date;
            this._changed.forEach(f => f(date));
            this._touched.forEach(f => f());
            this.change.emit(date);
        }
    }

    // #endregion

    // #region Inputs

    @Input() public time24Hours: boolean = true;

    // #endregion

    // #region Events

    @Output() change: EventEmitter<Date | null> = new EventEmitter<Date | null>();

    // #endregion

    // #region Constructor

    constructor()
    {
        this._meridiemValues.push(Utils.getMoment({ h: 11 }).local().format('a').toUpperCase());
        this._meridiemValues.push(Utils.getMoment({ h: 12 }).local().format('a').toUpperCase());
    }

    // #endregion

    // #region Event Handlers

    @HostListener('document:mouseup', ['$event']) onDocumentMouseUp(): void
    {
        if (this._isDuringMouseDrag)
        {
            this._isDuringMouseDrag = false;

            if (this.timeViewType === TimeViewType.ClockHours)
            {
                this.timeViewType = TimeViewType.ClockMinutes;
            }
        }
    }

    public ngAfterViewInit(): void
    {
        if (Utils.isNullOrUndefined(this._innerValue))
        {
            this.initializeClockParts(Utils.getMoment());
        }
    }

    public onClockMinutesCanvasMouseDown(event: MouseEvent): void
    {
        this._isDuringMouseDrag = true;
        this.handleClockMinutesPointEvent(event.pageX, event.pageY, false);
    }

    public onClockMinutesCanvasMouseMove(event: MouseEvent): void
    {
        if (this._isDuringMouseDrag)
        {
            this.handleClockMinutesPointEvent(event.pageX, event.pageY, false);
        }
    }

    public onClockMinutesCanvasMouseUp(): void
    {
        this._isDuringMouseDrag = false;
    }

    public onClockHoursCanvasMouseDown(event: MouseEvent): void
    {
        this._isDuringMouseDrag = true;
        this.handleClockHoursPointEvent(event.pageX, event.pageY, false);
    }

    public onClockHoursCanvasMouseMove(event: MouseEvent): void
    {
        if (this._isDuringMouseDrag)
        {
            this.handleClockHoursPointEvent(event.pageX, event.pageY, false);
        }
    }

    public onClockHoursCanvasMouseUp(): void
    {
        if (this._isDuringMouseDrag)
        {
            this._isDuringMouseDrag = false;

            this.timeViewType = TimeViewType.ClockMinutes;
        }
    }

    public onClockMinutesCanvasTouchStart(event: TouchEvent): void
    {
        event.preventDefault();
        this.handleClockMinutesPointEvent(event.changedTouches[0].pageX, event.changedTouches[0].pageY, true);
    }

    public onClockMinutesCanvasTouchMove(event: TouchEvent): void
    {
        event.preventDefault();
        this.handleClockMinutesPointEvent(event.changedTouches[0].pageX, event.changedTouches[0].pageY, true);
    }

    public onClockHoursCanvasTouchStart(event: TouchEvent): void
    {
        event.preventDefault();
        this.handleClockHoursPointEvent(event.changedTouches[0].pageX, event.changedTouches[0].pageY, true);
    }

    public onClockHoursCanvasTouchMove(event: TouchEvent): void
    {
        event.preventDefault();
        this.handleClockHoursPointEvent(event.changedTouches[0].pageX, event.changedTouches[0].pageY, true);
    }

    public onClockHoursCanvasTouchEnd(): void
    {
        this.timeViewType = TimeViewType.ClockMinutes;
    }

    public ngOnDestroy(): void
    {
        this.clearSpinPressInterval();
    }

    public onHoursInputWheel(event: WheelEvent)
    {
        event.preventDefault();

        if (event.deltaY !== 0)
        {
            this.updateSpinValue(event.deltaY < 0, true);
        }
    }

    public onMinutesInputWheel(event: WheelEvent)
    {
        event.preventDefault();

        if (event.deltaY !== 0)
        {
            this.updateSpinValue(event.deltaY < 0, false);
        }
    }

    public onClockHoursWheel(event: WheelEvent)
    {
        event.preventDefault();

        if (event.deltaY !== 0)
        {
            this.updateSpinValue(event.deltaY < 0, true);
            this.drawClockHours();
        }
    }

    public onClockMinutesWheel(event: WheelEvent)
    {
        event.preventDefault();

        if (event.deltaY !== 0)
        {
            this.updateSpinValue(event.deltaY < 0, false);
            this.drawClockMinutes();
        }
    }

    public onHoursSpinUpButtonMouseDown(event: MouseEvent): void
    {
        event.preventDefault();

        this.handleSpinButton(true, true);
    }

    public onHoursSpinDownButtonMouseDown(event: MouseEvent): void
    {
        event.preventDefault();

        this.handleSpinButton(false, true);
    }

    public onHoursSpinUpButtonTouchStart(event: TouchEvent): void
    {
        event.preventDefault();

        this.handleSpinButton(true, true);
    }

    public onHoursSpinDownButtonTouchStart(event: TouchEvent): void
    {
        event.preventDefault();

        this.handleSpinButton(false, true);
    }

    public onMinutesSpinUpButtonMouseDown(event: MouseEvent): void
    {
        event.preventDefault();

        this.handleSpinButton(true, false);
    }

    public onMinutesSpinDownButtonMouseDown(event: MouseEvent): void
    {
        event.preventDefault();

        this.handleSpinButton(false, false);
    }

    public onMinutesSpinUpButtonTouchStart(event: TouchEvent): void
    {
        event.preventDefault();

        this.handleSpinButton(true, false);
    }

    public onMinutesSpinDownButtonTouchStart(event: TouchEvent): void
    {
        event.preventDefault();

        this.handleSpinButton(false, false);
    }

    // #endregion

    // #region Public Methods

    public clearSpinPress(): void
    {
        if (this._spinPressIntervalHandleId !== null)
        {
            this._isClearNextSpinPress = true;
        }
    }

    public handleSpinButton(isIncrement: boolean, isHoursButton: boolean): void
    {
        if (this._isClearNextSpinPress || isHoursButton && this.hours === null || !isHoursButton && this.minutes === null)
        {
            return;
        }

        this._spinPressIntervalHandleId = setInterval(() =>
        {
            if (this._isClearNextSpinPress)
            {
                this.clearSpinPressInterval();
            }

            this.updateSpinValue(isIncrement, isHoursButton)

        }, this.SPIN_BUTTON_INTERVAL);
    }

    public touch(): void
    {
        this._touched.forEach(f => f());
    }

    public writeValue(value: Date | null): void
    {
        this._innerValue = value;
        this.initializeClockParts(Utils.getMoment(value));
    }

    public registerOnChange(fn: (value: Date | null) => void): void
    {
        this._changed.push(fn);
    }

    public registerOnTouched(fn: () => void): void
    {
        this._touched.push(fn);
    }

    // #endregion

    // #region Private Methods

    private initializeClockParts(value: moment.Moment | null): void
    {
        if (value === null || value === undefined || !value.isValid())
        {
            return;
        }

        this._minutes = this.numberToString(value.minutes());
        let hours: number = value.hours();

        if (!this.time24Hours)
        {
            if (hours < 12)
            {
                this._meridiemType = MeridiemType.AM;
                if (hours === 0)
                {
                    hours = 12;
                }
            }
            else if (hours >= 12)
            {
                this._meridiemType = MeridiemType.PM;
                if (hours > 12)
                {
                    hours -= 12;
                }
            }
        }

        this._hours = this.numberToString(hours);

        if (this.timeViewType === TimeViewType.ClockHours)
        {
            this.drawClockHours();
        }
        else if (this.timeViewType === TimeViewType.ClockMinutes)
        {
            this.drawClockMinutes();
        }
    }

    private numberToString(value: number): string
    {
        return value.toString().padStart(2, '0');
    }

    private handleClockMinutesPointEvent(pointX: number, pointY: number, isTouchEvent: boolean): void
    {
        if (this._clockMinutesCanvasElement === null)
        {
            return;
        }

        const clockCenterX: number = this._canvasSize / 2;
        const clockCenterY: number = this._canvasSize / 2;
        const clockRadius: number = this._canvasSize / 2;

        const canvasRect: DOMRect = this._clockMinutesCanvasElement.getBoundingClientRect();

        const x: number = pointX - canvasRect.left;
        const y: number = pointY - canvasRect.top;

        const selectorAngle: number = Math.round(this.getSelectorAngleByPressPoint(x, y, clockCenterX, clockCenterY) / 6);
        const selectorLength: number = this.getSelectorLengthByPressPoint(x, y, clockCenterX, clockCenterY);

        if (isTouchEvent || selectorLength < clockRadius + 10)
        {
            const currentMinute: number = x - clockCenterX >= 0 ? selectorAngle : (selectorAngle + 30) % 60;

            if (this._minutes !== null && parseInt(this._minutes) !== currentMinute)
            {
                this.minutes = this.numberToString(currentMinute);
                this.drawClockMinutes();
            }
            else if (Utils.isNullOrUndefined(this._innerValue))
            {
                this.updateValueFromTimeParts();
            }
        }
    }

    private handleClockHoursPointEvent(pointX: number, pointY: number, isTouchEvent: boolean): void
    {
        if (this._clockHoursCanvasElement === null)
        {
            return;
        }

        const clockCenterX: number = this._canvasSize / 2;
        const clockCenterY: number = this._canvasSize / 2;
        const clockRadius: number = this._canvasSize / 2;

        const canvasRect: DOMRect = this._clockHoursCanvasElement.getBoundingClientRect();

        const clockOuterRadius: number = this._canvasSize / 2 - this.CLOCK_RADIUS_OFFSET;
        const clockInnerRadius: number = this._canvasSize / 3 - this.CLOCK_RADIUS_OFFSET;

        const x: number = pointX - canvasRect.left;
        const y: number = pointY - canvasRect.top;

        const selectorAngle: number = Math.round(this.getSelectorAngleByPressPoint(x, y, clockCenterX, clockCenterY) / 30);
        const selectorLength: number = this.getSelectorLengthByPressPoint(x, y, clockCenterX, clockCenterY);

        if (isTouchEvent || selectorLength < clockRadius + 10)
        {
            let currentHour: number = x - clockCenterX >= 0 ? (selectorAngle == 0 ? 12 : selectorAngle) : selectorAngle + 6;

            if (this.time24Hours && (isTouchEvent || selectorLength < clockRadius - (clockOuterRadius - clockInnerRadius)))
            {
                currentHour = (currentHour + 12) % 24;
            }

            if (this._hours !== null && parseInt(this._hours) !== currentHour)
            {
                this.hours = this.numberToString(currentHour);

                this.drawClockHours();
            }
            else if (Utils.isNullOrUndefined(this._innerValue))
            {
                this.updateValueFromTimeParts();
            }
        }
    }

    private getSelectorAngleByPressPoint(x: number, y: number, clockCenterX: number, clockCenterY: number): number
    {
        return (360 * Math.atan((y - clockCenterY) / (x - clockCenterX)) / (2 * Math.PI)) + 90;
    }

    private getSelectorLengthByPressPoint(x: number, y: number, clockCenterX: number, clockCenterY: number): number
    {
        return Math.sqrt(Math.pow(Math.abs(x - clockCenterX), 2) + Math.pow(Math.abs(y - clockCenterY), 2));
    }

    private drawClockBackground(canvasRenderingContext: CanvasRenderingContext2D, canvasSize: number, clockCenterX: number, clockCenterY: number,
        clockRadius: number): void
    {
        const bodyCssStyleDeclaration: CSSStyleDeclaration = getComputedStyle(document.body);

        canvasRenderingContext.clearRect(0, 0, canvasSize, canvasSize);

        canvasRenderingContext.beginPath();
        canvasRenderingContext.arc(clockCenterX, clockCenterY, clockRadius, 0, 2 * Math.PI, false);
        canvasRenderingContext.fillStyle = bodyCssStyleDeclaration.getPropertyValue(this.COLOR_CLOCK_BACKGROUND_PROPERTY);
        canvasRenderingContext.fill();

        canvasRenderingContext.beginPath();
        canvasRenderingContext.arc(clockCenterX, clockCenterY, this.CLOCK_CENTER_RADIUS, 0, 2 * Math.PI, false);
        canvasRenderingContext.fillStyle = bodyCssStyleDeclaration.getPropertyValue(this.COLOR_PRIMARY_PROPERTY);
        canvasRenderingContext.fill();
    }

    private drawClockSelector(canvasRenderingContext: CanvasRenderingContext2D, selctedValue: number, clockCenterX: number, clockCenterY: number, clockRadius: number,
        isSmall: boolean): void
    {
        selctedValue -= 3;

        const bodyCssStyleDeclaration: CSSStyleDeclaration = getComputedStyle(document.documentElement);
        const clockFontSize: number = parseInt(bodyCssStyleDeclaration.fontSize);
        const selectionColor: string = bodyCssStyleDeclaration.getPropertyValue(this.COLOR_PRIMARY_PROPERTY);
        const selectorRadius: number = Math.round(clockFontSize / (isSmall ? 2 : 0.8));

        canvasRenderingContext.beginPath();
        canvasRenderingContext.moveTo(clockCenterX, clockCenterY);
        canvasRenderingContext.lineTo(clockCenterX + Math.cos(Math.PI / 6 * selctedValue) * clockRadius, clockCenterY + Math.sin(Math.PI / 6 * selctedValue) * clockRadius);
        canvasRenderingContext.lineWidth = 1;
        canvasRenderingContext.strokeStyle = selectionColor;
        canvasRenderingContext.stroke();
        canvasRenderingContext.beginPath();
        canvasRenderingContext.arc(clockCenterX + Math.cos(Math.PI / 6 * selctedValue) * clockRadius, clockCenterY + Math.sin(Math.PI / 6 * selctedValue) * clockRadius,
            selectorRadius, 0, 2 * Math.PI, false);

        canvasRenderingContext.fillStyle = selectionColor;
        canvasRenderingContext.fill();
    }

    private getIndexAngle(index: number): number
    {
        return Math.PI / 6 * (index - 3);
    }

    private drawClock(canvasElement: HTMLCanvasElement | null): void
    {
        if (canvasElement === null)
        {
            return;
        }

        const canvasRenderingContext: CanvasRenderingContext2D | null = canvasElement.getContext('2d');
        if (canvasRenderingContext === null)
        {
            return;
        }

        this._canvasSize = Math.floor(Math.min(canvasElement.clientWidth, canvasElement.clientHeight) / 2) * 2;

        const devicePixelRatio: number = window.devicePixelRatio || 1;
        canvasElement.width = this._canvasSize * devicePixelRatio;
        canvasElement.height = this._canvasSize * devicePixelRatio;
        canvasElement.style.width = `${this._canvasSize}px`;
        canvasElement.style.height = `${this._canvasSize}px`;

        canvasRenderingContext.scale(devicePixelRatio, devicePixelRatio);

        const clockCenterX: number = this._canvasSize / 2;
        const clockCenterY: number = this._canvasSize / 2;
        const clockRadius: number = this._canvasSize / 2;

        const bodyCssStyleDeclaration: CSSStyleDeclaration = getComputedStyle(document.body);
        const clockFontSize: number = parseInt(bodyCssStyleDeclaration.fontSize);
        const textSelectedColor: string = bodyCssStyleDeclaration.getPropertyValue(this.COLOR_TEXT_INVERSE_PROPERTY);
        const textColor: string = bodyCssStyleDeclaration.getPropertyValue(this.COLOR_TEXT_PROPERTY);

        canvasRenderingContext.font = bodyCssStyleDeclaration.font;

        const clockOuterRadius: number = this._canvasSize / 2 - this.CLOCK_RADIUS_OFFSET;
        const clockInnerRadius: number = this._canvasSize / 3 - 20;

        this.drawClockBackground(canvasRenderingContext, this._canvasSize, clockCenterX, clockCenterY, clockRadius);

        if (this.timeViewType === TimeViewType.ClockHours)
        {
            const currentHour: number = parseInt(this._hours ?? '');
            const currentHourRadius: number = (currentHour > 12 || currentHour === 0 ? clockInnerRadius : clockOuterRadius);

            this.drawClockSelector(canvasRenderingContext, currentHour % 12, clockCenterX, clockCenterY, currentHourRadius, false);

            for (let i = 1; i <= 12; i++)
            {
                const text: string = i.toString();
                const angle: number = this.getIndexAngle(i);

                canvasRenderingContext.fillStyle = currentHour == i ? textSelectedColor : textColor;

                canvasRenderingContext.fillText(text,
                    clockCenterX + Math.cos(angle) * clockOuterRadius - (canvasRenderingContext.measureText(text).width / 2),
                    clockCenterY + Math.sin(angle) * clockOuterRadius + (clockFontSize / 3));
            }

            if (this.time24Hours)
            {
                for (let i = 1; i <= 12; i++)
                {
                    const text: string = this.numberToString((i + 12) % 24);
                    const angle: number = Math.PI / 6 * (i - 3);

                    canvasRenderingContext.fillStyle = currentHour == i + 12 ? textSelectedColor : textColor;

                    canvasRenderingContext.fillText(text,
                        clockCenterX + Math.cos(angle) * clockInnerRadius - (canvasRenderingContext.measureText(text).width / 2),
                        clockCenterY + Math.sin(angle) * clockInnerRadius + (clockFontSize / 3));
                }
            }
        }
        else
        {
            const currentMinute: number = parseInt(this._minutes ?? '');
            const isBetweenNumbers: boolean = currentMinute % 5 !== 0;

            this.drawClockSelector(canvasRenderingContext, currentMinute / 5, clockCenterX, clockCenterY, clockOuterRadius, isBetweenNumbers);

            for (let i = 1; i <= 12; i++)
            {
                const text: string = ((i * 5) % 60).toString();
                const angle: number = this.getIndexAngle(i);

                canvasRenderingContext.fillStyle = currentMinute == i * 5 || currentMinute == 0 && i == 12 ? textSelectedColor : textColor;

                canvasRenderingContext.fillText(text,
                    clockCenterX + Math.cos(angle) * clockOuterRadius - (canvasRenderingContext.measureText(text).width / 2),
                    clockCenterY + Math.sin(angle) * clockOuterRadius + (clockFontSize / 3));
            }

            if (currentMinute % 5 !== 0)
            {
                canvasRenderingContext.beginPath();
                canvasRenderingContext.arc(clockCenterX + Math.cos(Math.PI / 6 * ((currentMinute / 5) - 3)) * clockOuterRadius,
                    clockCenterY + Math.sin(Math.PI / 6 * ((currentMinute / 5) - 3)) * clockOuterRadius,
                    2, 0, 2 * Math.PI, false);
                canvasRenderingContext.fillStyle = textSelectedColor;
                canvasRenderingContext.fill();
            }
        }
    }

    private drawClockHours(): void
    {
        if (this._hours !== null)
        {
            this.drawClock(this._clockHoursCanvasElement);
        }
    }

    private drawClockMinutes(): void
    {
        if (this._minutes !== null)
        {
            this.drawClock(this._clockMinutesCanvasElement);
        }
    }

    private updateSpinValue(isIncrement: boolean, isHours: boolean): void
    {
        if (isIncrement)
        {
            if (isHours)
            {
                if (this.hours !== null)
                {
                    this.hours = this.numberToString(this.updateSpinHoursValue(true, parseInt(this.hours) + 1));
                }
            }
            else if (this.minutes !== null)
            {
                this.minutes = this.numberToString((parseInt(this.minutes) + 1) % 60);
            }
        }
        else if (isHours)
        {
            if (this.hours !== null)
            {
                this.hours = this.numberToString(this.updateSpinHoursValue(false, parseInt(this.hours) - 1));
            }
        }
        else if (this.minutes !== null)
        {
            let newMinutesValue: number = parseInt(this.minutes) - 1;
            if (newMinutesValue < 0)
            {
                newMinutesValue = 59;
            }

            this.minutes = this.numberToString(newMinutesValue);
        }
    }

    private flipMeridienType(): void
    {
        this._meridiemType = this._meridiemType === MeridiemType.AM ? MeridiemType.PM : MeridiemType.AM;
    }

    private updateSpinHoursValue(isIncrement: boolean, newHoursValue: number): number
    {
        if (isIncrement)
        {
            if (this.time24Hours)
            {
                if (newHoursValue > 23)
                {
                    newHoursValue = 0;
                }
            }
            else
            {
                if (newHoursValue > 12)
                {
                    newHoursValue = 1;
                }

                if (newHoursValue === 12)
                {
                    this.flipMeridienType();
                }
            }
        }
        else
        {
            if (this.time24Hours)
            {
                if (newHoursValue < 0)
                {
                    newHoursValue = 23;
                }
            }
            else
            {
                if (newHoursValue < 1)
                {
                    newHoursValue = 12;

                    this.flipMeridienType();
                }
            }
        }

        return newHoursValue;
    }

    private clearSpinPressInterval(): void
    {
        this._isClearNextSpinPress = false;

        if (this._spinPressIntervalHandleId !== null)
        {
            clearInterval(this._spinPressIntervalHandleId);
            this._spinPressIntervalHandleId = null;
        }
    }

    private updateValueFromTimeParts(): void
    {
        if (this._hours === null || this._minutes === null)
        {
            return;
        }

        let hours: number = parseInt(this._hours);
        if (!this.time24Hours)
        {
            if (this._meridiemType === MeridiemType.AM)
            {
                if (hours === 12)
                {
                    hours = 0;
                }
            }
            else if (hours < 12)
            {
                hours += 12;
            }
        }

        this.value = (this._innerValue === null || this._innerValue === undefined ? Utils.getMoment() :
            Utils.getMoment(this._innerValue)).set({ 'hours': hours, 'minute': parseInt(this._minutes) }).toDate();
    }

    // #endregion
}
