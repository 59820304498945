<!DOCTYPE html>

@if (isVisible) {
<div #viewerContainer class="viewer-container select-none" [@fadeInAnimation]>
    <div class="viewer-backdrop" (click)="onBackdropClick($event)"></div>
    <div #viewerProgress class="viewer-progress"></div>
    @if (isAutoplay && porfolioViewerImages[currentViewerImageIndex].isVideo) {
    <div class="video-autoplay-message" [@fadeInOutAnimation]>
        {{ videoAutoplayMessage }}
    </div>
    }
    @for (porfolioViewerImage of porfolioViewerImages; track porfolioViewerImage; let imageIndex = $index) {
    <div class="porfolio-viewer-image-container"
         [style.zIndex]="currentViewerImageIndex === imageIndex ? 1 : 0" [class.inactive]="currentViewerImageIndex !== imageIndex"
         [@slideZoomInOutStateAnimation]="porfolioViewerImage.animationState">
        <div class="image-warapper">
            <div class="image-container">
                @if (!porfolioViewerImage.isVideo) {
                <img [src]="porfolioViewerImage.imageUrl | safe" [@fadeVisibilityAnimation]="porfolioViewerImage.isLoaded"
                     pointerEvents (swipe)="onImageSwipe($event)" (load)="onImageLoad(porfolioViewerImage)"
                     zoomGesture [isZoomGestureActive]="currentViewerImageIndex === imageIndex" />
                }
            </div>
        </div>
        @if (porfolioViewerImage.isVideo) {
        <div class="porfolio-viewer-video-wrapper">
            <iframe [src]="porfolioViewerImage.imageUrl | safe" (load)="onVideoLoad($event, porfolioViewerImage)"
                    [@fadeVisibilityAnimation]="porfolioViewerImage.isLoaded">
            </iframe>
        </div>
        }
        @if (!porfolioViewerImage.isLoaded) {
        <div class="image-loader" [@fadeInOutAnimation]>
            <div class="spinner-border"></div>
        </div>
        }
    </div>
    }
    @if (porfolioViewerImages.length > 1) {
    <div class="viewer-controls" [@fadeVisibilityAnimation]="!isIdle">
        <div class="image-indicators">
            @for (porfolioViewerImage of porfolioViewerImages; track porfolioViewerImage; let imageIndex = $index) {
            <button (click)="onImageIndicatorButtonClick($event, imageIndex)" [class.active]="currentViewerImageIndex === imageIndex">
            </button>
            }
        </div>
        <button class="viewer-button viewer-prev" (click)="onViewerPrevButtonClick($event)">
            <svg xmlns="http://www.w3.org/2000/svg" width="104" height="186" viewBox="0 0 104 186">
                <path d="m 92.964,185.67175 c 2.741,0 5.493,-1.044 7.593,-3.149 4.194,-4.194 4.194,-10.981 0,-15.175 l -74.352,-74.347 74.352,-74.352 c 4.194,-4.194 4.194,-10.987 0,-15.175 -4.194,-4.194 -10.987,-4.194 -15.18,0 l -81.934,81.934 c -4.194,4.194 -4.194,10.987 0,15.175 l 81.934,81.939 c 2.093,2.1 4.84,3.15 7.587,3.15 z" />
            </svg>
        </button>
        <button class="viewer-button viewer-next" (click)="onViewerNextButtonClick($event)">
            <svg xmlns="http://www.w3.org/2000/svg" width="104" height="186" viewBox="0 0 104 186">
                <path d="m 11.036,185.67175 c -2.741,0 -5.493,-1.044 -7.593,-3.149 -4.194,-4.194 -4.194,-10.981 0,-15.175 l 74.352,-74.347 -74.352,-74.352 c -4.194,-4.194 -4.194,-10.987 0,-15.175 4.194,-4.194 10.987,-4.194 15.18,0 l 81.934,81.934 c 4.194,4.194 4.194,10.987 0,15.175 l -81.934,81.939 c -2.093,2.1 -4.84,3.15 -7.587,3.15 z" />
            </svg>
        </button>
    </div>
    }
    <div class="viewer-toolbar">
        @if (showAutoplay && porfolioViewerImages.length > 1) {
        <button class="viewer-button" [class.hidden]="!isAutoplay && porfolioViewerImages[currentViewerImageIndex].isVideo" (click)="onViewerAutoplayButtonClick($event)">
            @if (!isAutoplay) {
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <path d="M 4.489482,3.7702243 C 3.9654121,4.0065902 3.4889821,4.5430595 3.4866811,5.2300657 v 0.00213 13.7084233 c -0.00659,0.665059 0.4377666,1.222295 1.0049481,1.47478 0.2591503,0.117659 0.5359605,0.175867 0.8181308,0.177145 H 5.3162 5.32049 c 0.3165969,-0.0025 0.627081,-0.07472 0.9104658,-0.224098 L 19.60665,13.515305 c 0.545867,-0.279302 0.919057,-0.837228 0.919057,-1.423558 0,-0.586332 -0.37319,-1.146391 -0.919057,-1.425693 L 6.226668,3.8129098 H 6.224528 C 5.6485185,3.5174829 5.0129045,3.5341506 4.489482,3.7702243 Z M 5.5373766,5.6867997 18.043398,12.091747 5.5373766,18.49456 Z"></path>
            </svg>
            }
            @if (isAutoplay) {
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <path d="M 14.309571,4.4394531 V 19.503906 h 2.142578 V 4.4394531 Z M 7.5478514,4.4980469 V 19.560547 H 9.6904296 V 4.4980469 Z"></path>
            </svg>
            }
        </button>
        }
        <button class="viewer-button" (click)="onViewerCloseButtonClick($event)">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
                <path d="M 12,10.435685 5.9662149,4.4019002 4.4019002,5.9662149 10.435685,12 4.4019002,18.033785 5.9662149,19.5981 12,13.564315 18.033785,19.5981 19.5981,18.033785 13.564315,12 19.5981,5.9662149 18.033785,4.4019002 Z"></path>
            </svg>
        </button>
    </div>
</div>
}
