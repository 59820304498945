export enum InventoryColumnType { Warehouse, Date, Locks, Shackles, Cables, Chargers, Notes, Delete }

export class Warehouse
{
    public groupCode: string | null = null;
    public warehouseId: number | null = null;
    public warehouseName: string | null = null;
}

export class InventoryLists
{
    public warehouses: Warehouse[] = [];
}

export const InventoryConstants =
{
    MISSING_VALUE: 'Unknown',
    NO_VALUE: 'None'
}
