import { Injectable } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpResponse, HttpErrorResponse, HttpStatusCode } from '@angular/common/http';
import { LoginModel } from '../user/login/model/login.model';
import { BehaviorSubject, catchError, Observable, switchMap, tap, throwError, filter, take } from 'rxjs';
import { Utils } from '../utils/utils';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class WebApiHttpInterceptor implements HttpInterceptor
{
    // #region Private Member

    private _isRefreshingLoginToken = false;
    private _refreshLoginTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(null);

    // #endregion

    // #region Constructors

    constructor(private _loginModel: LoginModel)
    {
    }

    // #endregion

    // #region HttpInterceptor Methods

    public intercept(httpRequest: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>>
    {
        return next.handle(this.cloneRequest(httpRequest)).pipe(
            tap(this.handleCloneRequestTapEvent()),
            catchError((error: HttpErrorResponse) =>
            {
                if (error.status === HttpStatusCode.Unauthorized)
                {
                    if (!this._isRefreshingLoginToken)
                    {
                        this._isRefreshingLoginToken = true;
                        this._refreshLoginTokenSubject.next(null);

                        return this._loginModel.refreshUserToken().pipe(
                            switchMap(() =>
                            {
                                this._isRefreshingLoginToken = false;
                                this._refreshLoginTokenSubject.next(this._loginModel.accessToken);

                                return next.handle(this.cloneRequest(httpRequest)).pipe(
                                    tap(this.handleCloneRequestTapEvent()));
                            }),
                            catchError((error: HttpErrorResponse) =>
                            {
                                this._isRefreshingLoginToken = false;

                                this._refreshLoginTokenSubject = new BehaviorSubject<any>(null);
                                return throwError(() => error);
                            }));
                    }
                    return this._refreshLoginTokenSubject.pipe(
                        filter(accessToken => accessToken !== null),
                        take(1),
                        switchMap(() => next.handle(this.cloneRequest(httpRequest)).pipe(tap(this.handleCloneRequestTapEvent()))));
                }

                return throwError(() => error);
            })
        );
    }

    // #endregion

    // #region Private Methods

    private handleCloneRequestTapEvent(): (event: HttpEvent<any>) => void
    {
        return (event: HttpEvent<any>) =>
            {
                if (event instanceof HttpResponse)
                {
                    Utils.convertDates(event.body);
                }
            };
    }

    private cloneRequest(httpRequest: HttpRequest<any>): HttpRequest<any>
    {
        return httpRequest.clone(httpRequest.clone({
            url: httpRequest.url.startsWith('http') ? httpRequest.url : `${environment.baseApiUrl}${httpRequest.url}`,
            headers: httpRequest.method !== 'JSONP' && this._loginModel.accessToken !== null && !this._isRefreshingLoginToken ?
                httpRequest.headers.set('Authorization', `Bearer ${this._loginModel.accessToken}`) : httpRequest.headers
        }));
    }

    // #endregion
}
