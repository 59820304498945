<!DOCTYPE html>
<ul class="nav nav-tabs">
    @for (tab of tabs; track $index; let index = $index) {
    <li [class.active]="index === tabIndex">
        <button type="button" class="tab-button ripple-effect" (click)="onTabItemButtonClick(index)">
            <span class="tab-button-content">
                <ng-template [ngTemplateOutlet]="tab.titleTemplate.templateRef"></ng-template>
            </span>
        </button>
    </li>
    }
</ul>
<div class="tab-content">
    @for (tab of tabs; track $index; let index = $index) {
    @if (tab.contentTemplate && index === tabIndex) {
    <div class="tab-pane" [@fadeInOutAnimation]>
        <ng-template [ngTemplateOutlet]="tab.contentTemplate.templateRef"></ng-template>
    </div>
    }
    }
</div>
