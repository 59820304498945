export class StringTableUtils
{
    // #region Public Methods

    public static getContainerIdColumnName(isAccountTypeAmazon: boolean): string
    {
        return isAccountTypeAmazon ? 'VRID' : 'Cargo ID';
    }

    public static getDeviceIdColumnName(isAccountTypeAmazonUS: boolean): string
    {
        return isAccountTypeAmazonUS ? 'Lock S/N' : 'Device S/N';
    }

    public static getInstallDateColumnName(isAccountTypeAmazon: boolean): string
    {
        return isAccountTypeAmazon ? 'Origin Slot' : 'Start Time';
    }

    public static getRemoveDateColumnName(isAccountTypeAmazon: boolean): string
    {
        return isAccountTypeAmazon ? 'Destination Slot' : 'End Time';
    }

    // #endregion
}