import { Component, Input, ViewChild, ElementRef, forwardRef, Output, EventEmitter, ChangeDetectionStrategy } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, FormsModule } from '@angular/forms';
import { KeyValue } from '../../utils/globals';
import { fadeInOutAnimation } from '../../animations/fade.animation';
import { getLocaleCurrencySymbol } from '@angular/common';
import { Utils } from '../../utils/utils';

@Component({
    selector: 'numeric-input',
    templateUrl: './numeric-input.component.html',
    styleUrls: ['./numeric-input.component.css'],
    animations: [fadeInOutAnimation],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: NumericInputComponent, multi: true }
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [FormsModule]
})

export class NumericInputComponent implements ControlValueAccessor
{
	// #region Private Memebrs

	private _minValue: number | null = null;
	private _maxValue: number | null = null;

	private _innerValue: number | null = null;

	private _changed = new Array<(value: number | null) => void>();
	private _touched = new Array<() => void>();

	@ViewChild('numericInput') private _inputElementRef: ElementRef<HTMLInputElement> | undefined = undefined;

	// #endregion

	// #region Properties

	public get value(): number | null
	{
		return this._innerValue;
	}

	public set value(value: number | null)
	{
		if (this._innerValue !== value)
		{
			if (value !== null)
			{
				if (value.toString().length === 0)
				{
					value = null
				}
				else if (!isNaN(Number(value)))
				{
					value = Number(value);
				}
				else
				{
					value = null
				}
			}

			this._innerValue = value;

			this._changed.forEach(f => f(value));
			this._touched.forEach(f => f());
			this.change.emit(value);
		}
	}

	public get currencySymbol(): string | null
	{
		return Utils.getCurrencySymbol();
	}

	// #endregion

	// #region Inputs

	@Input() public disabled: boolean = false;
	@Input() public readonly: boolean = false;
	@Input() public clearable: boolean = true;
	@Input() public label: string = "";
	@Input() public maxLength: number = 10;
	@Input() public icon: string | null = null;
	@Input() public isRequired: boolean = false;
	@Input() public isDecimal: boolean = false;
	@Input() public isNegative: boolean = false;
	@Input() public decimalScale: number | null = null;
	@Input() public isCurrency: boolean = false;
	@Input() public isPercentage: boolean = false;

	@Input()
	public get minValue(): number | null
	{
		return this._minValue;
	}

	public set minValue(minValue: number | null)
	{
		this._minValue = minValue;
		this.updateValueByRange();
	}

	@Input()
	public get maxValue(): number | null
	{
		return this._maxValue;
	}

	public set maxValue(maxValue: number | null)
	{
		this._maxValue = maxValue;
		this.updateValueByRange();
	}

	// #endregion

	// #region Events

	@Output() private focus: EventEmitter<FocusEvent> = new EventEmitter<FocusEvent>();
	@Output() private blur: EventEmitter<FocusEvent> = new EventEmitter<FocusEvent>();
	@Output() private change: EventEmitter<number | null> = new EventEmitter<number | null>();

	// #endregion

	// #region Event Handlers

	public onFocus(event: FocusEvent): void
	{
		this.focus.emit(event);
	}

	public onBlur(event: FocusEvent): void
	{
		this.blur.emit(event);
	}

	public onFocusOut(): void
	{
		if (!this.validateRange(this.value))
		{
			this.value = null;
		}
		else if (this._inputElementRef !== undefined && this._inputElementRef.nativeElement.value === '-')
		{
			this._inputElementRef.nativeElement.value = '';
		}
	}

	public onPaste(event: ClipboardEvent): void
	{
		if (event.clipboardData === null)
		{
			return;
		}

		if (!this.validateText(event.clipboardData.getData('Text').trim()))
		{
			event.preventDefault();
		}
	}

	public onKeyPress(event: any): void
	{
		if (event.key === KeyValue.Enter)
		{
			return;
		}

		if (!this.validateText(event.key))
		{
			event.preventDefault();
		}
	}

	public onInput(): void
	{
		if (this._innerValue === null)
		{
			if (this._inputElementRef !== undefined)
			{
				this._inputElementRef.nativeElement.value = '';
			}
		}
		else if (isNaN(this._innerValue))
		{
			this.value = null;
		}
	}

	// #endregion

	// #region Public Methods

	public touch(): void
	{
		this._touched.forEach(f => f());
	}

	public writeValue(value: number | null): void
	{
		if (value !== undefined)
		{
			this._innerValue = value;
		}
	}

	public registerOnChange(fn: (value: number | null) => void): void
	{
		this._changed.push(fn);
	}

	public registerOnTouched(fn: () => void): void
	{
		this._touched.push(fn);
	}

	// #endregion

	// #region Private Methods

	private updateValueByRange(): void
	{
		if (this._innerValue === null)
		{
			return;
		}

		if (this._minValue !== null && this._innerValue < this._minValue)
		{
			this.value = this._minValue;
		}
		else if (this._maxValue !== null && this._innerValue > this._maxValue)
		{
			this.value = this._maxValue;
		}
	}

	private validateText(newText: string): boolean
	{
		if (!this.isDecimal && !this.isCurrency && newText.indexOf('.') >= 0 || !this.isNegative && newText.indexOf('-') >= 0)
		{
			return false;
		}

		if (this._inputElementRef === undefined)
		{
			return false;
		}

		let prefixText: string = '', suffixText: string = '';
		const innerValue: string | null = this._innerValue !== null ? this._innerValue.toString() : null;

		if (innerValue !== null)
		{
			if (this._inputElementRef.nativeElement.selectionStart !== this._inputElementRef.nativeElement.selectionEnd)
			{
				prefixText = innerValue.substring(0, this._inputElementRef.nativeElement.selectionStart ?? 0);
				suffixText = innerValue.substring(this._inputElementRef.nativeElement.selectionEnd ?? 0, innerValue.length);
			}
			else
			{
				prefixText = innerValue.substring(0, this._inputElementRef.nativeElement.selectionStart ?? 0);
				suffixText = innerValue.substring(this._inputElementRef.nativeElement.selectionStart ?? 0, innerValue.length);
			}
		}

		const targetText: string = `${prefixText}${newText}${suffixText}`;
		if (targetText.length > this.maxLength)
		{
			return false;
		}

		if (targetText.length > 1 && targetText[0] === '0')
		{
			return false;
		}

		if (this.isNegative && this._inputElementRef.nativeElement.value.length === 0 && targetText.indexOf('-') === 0)
		{
			return true;
		}

		if (this.isCurrency || this.isDecimal && this.decimalScale !== null)
		{
			const decimalPointPosition: number = targetText.indexOf('.');
			if (decimalPointPosition !== -1 && targetText.length - 1 - decimalPointPosition > (this.isCurrency ? 2 : this.decimalScale!))
			{
				return false;
			}
		}

		const targetValue: number = Number(targetText);
		if (isNaN(targetValue))
		{
			return false;
		}

		return this.validateRange(targetValue);
	}

	private validateRange(value: number | null): boolean
	{
		if (this.minValue !== null && value !== null && value < Number(this.minValue))
		{
			return false;
		}

		if (this.maxValue !== null && value !== null && value > Number(this.maxValue))
		{
			return false;
		}

		return true;
	}

	// #endregion
}