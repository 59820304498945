import { ChangeDetectorRef, Component, ElementRef, EventEmitter, OnDestroy, OnInit, Output, ViewChild } from "@angular/core";
import { Subscription } from "rxjs";
import { NgForm } from "@angular/forms";
import { ManagerItemBaseModel } from "../models/managaer-item-base.model";
import { Constants, IApiResponseData } from "../../utils/globals";
import { RoutingHistoryService } from "../../services/routing-history.service";
import { Utils } from "../../utils/utils";
import { ModalMessageService } from "../../controls/modal-message/services/modal-message.service";
import { SavableBaseComponent } from "./savable-base.component";
import { cloneDeep } from "lodash";

@Component({ template: '' })
export class ManagerItemBaseComponent<ManagerItemType> extends SavableBaseComponent implements OnInit, OnDestroy
{
    // #region Protected Members

    protected _managerItemSubscription: Subscription | null = null;

    @ViewChild('pageScroller', { read: ElementRef, static: false }) protected _pageScrollerElementRef: ElementRef<HTMLElement> | undefined = undefined;
    @ViewChild('formManagerItem', { read: NgForm, static: false }) protected _formManagerItem: NgForm | undefined = undefined;

    // #endregion

    // #region Properties

    public get managerItemModel(): ManagerItemBaseModel<ManagerItemType>
    {
        return this._managerItemModel;
    }

    public override get isDirty(): boolean
    {
        return this._managerItemModel.isDirty;
    }

    // #endregion

    // #region Events

    @Output() public submitingManagerItem: EventEmitter<IApiResponseData<ManagerItemType>> = new EventEmitter();

    // #endregion

    // #region Constructor

    constructor(protected _managerItemModel: ManagerItemBaseModel<ManagerItemType>, protected _changeDetectorRef: ChangeDetectorRef,
        protected _routingHistoryService: RoutingHistoryService, _modalMessageService: ModalMessageService)
    {
        super(_modalMessageService);
    }

    // #endregion

    // #region Event Handlers

    public ngOnInit(): void
    {
        if (this._routingHistoryService.isPopState && this._managerItemModel.isInitialized)
        {
            this.setViewState(true);
            this._changeDetectorRef.markForCheck();
        }
        else if (Constants.IS_MOBILE)
        {
            this.loadManagerItem();
        }
    }

    public ngOnDestroy(): void
    {
        this.clearManagerItemSubscription();
    }

    public onManagerItemSubmit(isManagerItemDataValid: boolean): void
    {
        if (!isManagerItemDataValid)
        {
            Utils.scrollToInvalidFormElement();
            return;
        }

        this.submitManagerItem();
    }

    // #endregion

    // #region Public Methods

    public loadManagerItem(isScrollToTop: boolean = true): void
    {
        this._formManagerItem?.resetForm();

        setTimeout(() =>
        {
            if (isScrollToTop && this._pageScrollerElementRef !== undefined)
            {
                this._pageScrollerElementRef.nativeElement.scrollTop = 0;
            }

            this._managerItemModel.initialize();
            this.setViewState(true);
            this._changeDetectorRef.markForCheck();
        });
    }

    public onCancelManagerItemButtonClick(): void
    {
        this.submitingManagerItem.emit({ isSuccess: false, isComplete: false });
    }

    public deleteManagerItem(): void
    {
        this.submitManagerItem(true);
    }

    // #endregion

    // #region Protected Methods

    protected navigateToManger(): void
    {
    }

    protected submitManagerItem(isDelete: boolean = false): void
    {
        this.viewIsReady = false;

        this.clearManagerItemSubscription();

        this.submitingManagerItemEvent({ isSuccess: true, isComplete: false, message: this._statusMessage });

        this._managerItemSubscription = this.managerItemModel.submitManagerItem(isDelete).subscribe((response: IApiResponseData<ManagerItemType>) =>
        {
            const targetResponse: IApiResponseData<ManagerItemType> = cloneDeep(response);
            targetResponse.data = response.data;

            if (response.isSuccess)
            {
                this.managerItemModel.setValidManagerItem();

                if (Constants.IS_MOBILE)
                {
                    this.navigateToManger();
                    return;
                }

                targetResponse.message = this._statusMessage;
            }

            this.viewIsReady = true;
            this._changeDetectorRef.detectChanges();

            this.submitingManagerItemEvent(targetResponse);
        });

        this._changeDetectorRef.detectChanges();
    }

    protected clearManagerItemSubscription(): void
    {
        if (this._managerItemSubscription !== null)
        {
            this._managerItemSubscription.unsubscribe();
            this._managerItemSubscription = null;
        }
    }

    // #endregion

    // #region Private Methods

    private submitingManagerItemEvent(response: IApiResponseData<ManagerItemType>): void
    {
        this.submitingManagerItem.emit(
            {
                isSuccess: response.isSuccess,
                isComplete: response.isComplete,
                data: response.data,
                message: response.message,
                status: response.status
            });
    }

    // #endregion
}