import { ApplicationRef, ComponentRef, EmbeddedViewRef, Injectable, TemplateRef, createComponent, EnvironmentInjector } from "@angular/core";
import { Router, NavigationStart, Event } from "@angular/router";
import { filter } from "rxjs";
import { ModalButtonType, ModalIconType, ModalMessageComponent, ModalResultType, ModalType } from "../modal-message.component";

export enum ModalBodyAlignType { Left, Center, Right }

export interface IMessageOptions
{
    title?: string;
    message?: string;
    modalType?: ModalType;
    modalIcon?: ModalIconType;
    modalButton?: ModalButtonType;
    modalButtonsNames?: string[];
    modalIconClassName?: string;
    modalContentTemplateRef?: TemplateRef<any>;
    modalContentTemplateData?: any;
    modlaBodyAlign?: ModalBodyAlignType;
    parentElement?: HTMLElement;
}

@Injectable({ providedIn: 'root' })
export class ModalMessageService
{
    // #region Private Members

    private _modalMessageComponentRefList: ComponentRef<ModalMessageComponent>[] = [];

    // #endregion

    // #region Properties

    public get instance(): ModalMessageComponent | null
    {
        return this._modalMessageComponentRefList.length > 0 ? this._modalMessageComponentRefList[0].instance : null
    }

    // #endregion

    // #region Constructor

    constructor(private _environmentInjector: EnvironmentInjector, private _applicationRef: ApplicationRef, private _router: Router)
    {
        this._router.events.pipe(
            filter((event: Event) =>
            {
                return (event instanceof NavigationStart);
            })).subscribe(() =>
            {
                for (const modalMessageComponentRef of this._modalMessageComponentRefList)
                {
                    modalMessageComponentRef.instance.closeModal();
                }
            });
    }

    // #endregion

    // #region Public Methods

    public show(messageOptions: IMessageOptions): Promise<ModalResultType>
    {
        return new Promise<ModalResultType>((resolve) =>
        {
            const modalMessageComponentRef: ComponentRef<ModalMessageComponent> = createComponent(ModalMessageComponent, {
                environmentInjector: this._environmentInjector
            });

            this._modalMessageComponentRefList.push(modalMessageComponentRef);
            this._applicationRef.attachView(modalMessageComponentRef.hostView);

            modalMessageComponentRef.instance.modalTitle = messageOptions.title ?? '';
            modalMessageComponentRef.instance.modalMessage = messageOptions.message ?? '';
            modalMessageComponentRef.instance.modalType = messageOptions.modalType ?? ModalType.Regular;
            modalMessageComponentRef.instance.modalIcon = messageOptions.modalIcon ?? null;
            modalMessageComponentRef.instance.modalButton = messageOptions.modalButton ?? ModalButtonType.Close;
            modalMessageComponentRef.instance.modalButtonsNames = messageOptions.modalButtonsNames ?? [];
            modalMessageComponentRef.instance.modalIconClassName = messageOptions.modalIconClassName ?? '';
            modalMessageComponentRef.instance.modalContentTemplateRef = messageOptions.modalContentTemplateRef;
            modalMessageComponentRef.instance.modalContentTemplateData = messageOptions.modalContentTemplateData;
            modalMessageComponentRef.instance.modlaBodyAlign = messageOptions.modlaBodyAlign ?? ModalBodyAlignType.Center;

            modalMessageComponentRef.instance.close.subscribe((modalResult: ModalResultType) =>
            {
                this.destroy(modalMessageComponentRef);
                return resolve(modalResult);
            });

            const modalMessageElement: HTMLElement = (modalMessageComponentRef.hostView as EmbeddedViewRef<any>).rootNodes[0] as HTMLElement;
            (messageOptions.parentElement ?? document.body).appendChild(modalMessageElement);
        });
    }

    // #endregion

    // #region Private Methods

    private destroy(modalMessageComponentRef: ComponentRef<ModalMessageComponent>): void
    {
        this._applicationRef.detachView(modalMessageComponentRef.hostView);
        modalMessageComponentRef.destroy();

        this._modalMessageComponentRefList.splice(this._modalMessageComponentRefList.indexOf(modalMessageComponentRef), 1);
    }

    // #endregion
}