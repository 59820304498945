import { Directive, AfterViewInit, ElementRef, Input } from '@angular/core';

@Directive({
    selector: '[autofocus]',
    standalone: true
})
export class AutofocusDirective implements AfterViewInit
{
    // #region Inputs

    @Input() public startFocus: boolean = true;

    // #endregion

    // #region Constructors

    constructor(private _elementRef: ElementRef<HTMLElement>)
    {
    }

    // #endregion

    // #region Event Handlers

    public ngAfterViewInit(): void
    {
        if (!this.startFocus)
        {
            return;
        }

        if (document.hasFocus())
        {
            this._elementRef.nativeElement.focus();
        }
        else
        {
            document.body.addEventListener('focus', () =>
            {
                setTimeout(() => this._elementRef.nativeElement.focus(), 100);
            }, { once: true });
        }
    }

    // #endregion
}
