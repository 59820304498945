import { PluginListenerHandle, registerPlugin, WebPlugin } from '@capacitor/core';

export interface IGetAppVersionResponse
{
    appVersion: string;
}

export interface IShareRequest
{
    title?: string;
    text?: string;
    url?: string;
    filename?: string;
    base64fileData?: string;
}

export interface IExternalLoginRequest
{
    url?: string;
}

export interface IExternalLoginResponse
{
    code?: string;
}

export interface IShareResponse
{
    result: boolean;
}

export interface IThemeModeRequest
{
    isDarkMode: boolean;
}

export interface IContguardPlugin
{
    externalLogin(externalLoginRequest: IExternalLoginRequest): Promise<IExternalLoginResponse>;
    exitApp(): Promise<any>;
    hideSplashScreen(): Promise<any>;
    setOrientationPortrait(): Promise<any>;
    setOrientationSensor(): Promise<any>;
    getAppVersion(): Promise<IGetAppVersionResponse>;
    share(shareRequest: IShareRequest): Promise<IShareResponse>;
    setThemeMode(themeModeRequest: IThemeModeRequest): Promise<any>;
    addListener(eventName: 'backButtonPressed', listenerFunc: (canGoBack: boolean) => void): Promise<PluginListenerHandle>;
}

export class ContguardPluginWeb extends WebPlugin implements IContguardPlugin
{
    // #region Private Constants

    private readonly APP_VERSION: string = require('../../package.json').version;

    // #endregion

    public async externalLogin(externalLoginRequest: IExternalLoginRequest): Promise<IExternalLoginResponse>
    {
        if (externalLoginRequest.url !== undefined)
        {
            window.location.href = externalLoginRequest.url;
        }

        return Promise.reject();
    }

    public async exitApp(): Promise<any>
    {
        return Promise.resolve();
    }

    public async hideSplashScreen(): Promise<any>
    {
        return Promise.resolve();
    }

    public async setOrientationPortrait(): Promise<any>
    {
        return Promise.resolve();
    }

    public async setOrientationSensor(): Promise<any>
    {
        return Promise.resolve();
    }

    public async getAppVersion(): Promise<IGetAppVersionResponse>
    {
        return Promise.resolve({ appVersion: this.APP_VERSION });
    }

    public async share(shareRequest: IShareRequest): Promise<IShareResponse>
    {
        let result: boolean = true;

        let shareData: ShareData = {};
        if (shareRequest.filename !== undefined && shareRequest.base64fileData !== undefined)
        {
            const response: Response = await fetch(shareRequest.base64fileData);
            const arrayBuffer: ArrayBuffer = await response.arrayBuffer();
            const sharingFile: File = new File([arrayBuffer], shareRequest.filename, { type: 'image/png' });

            await navigator.clipboard.write([new ClipboardItem({ [sharingFile.type]: sharingFile })]);

            shareData = { title: shareRequest.title, text: shareRequest.text, files: [sharingFile] };
        }
        else
        {
            if (shareRequest.text !== undefined)
            {
                navigator.clipboard.writeText(shareRequest.text);
            }

            shareData = { title: shareRequest.title, text: shareRequest.text, url: shareRequest.url };
        }

        try
        {
            if (navigator.canShare(shareData))
            {
                await navigator.share(shareData);
            }
            else
            {
                console.log('Navigator Share is not supported.');
                result = false;
            }
        }
        catch (error: any)
        {
            console.log(error.message);
            result = false;
        }

        return Promise.resolve({ result: result });
    }

    public async setThemeMode(_themeModeRequest: IThemeModeRequest): Promise<any>
    {
        return Promise.resolve();
    }
}

const ContguardPlugin = registerPlugin<IContguardPlugin>('ContguardPlugin',
    {
        web: () => new ContguardPluginWeb()
    });

export default ContguardPlugin;