import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Input, NgZone, OnDestroy, OnInit, Output, Renderer2, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { fadeInAnimation, fadeInOutAnimation, fadeVisibilityAnimation } from '../../animations/fade.animation';
import { SlideAnimationStateType, slideZoomInOutStateAnimation } from '../../animations/slide.animation';
import { SwipeDirection, PointerEventsDirective } from '../../directives/pointer-events.directive';
import { KeyValue } from '../../utils/globals';
import { Utils } from '../../utils/utils';
import { AnimationsConstants } from '../../animations/constant';
import { SafePipe } from '../../pipes/safe.pipe';
import { ZoomGestureDirective } from '../../directives/zoom-gesture.directive';

declare global
{
    interface Window
    {
        onYouTubeIframeAPIReady: (() => void) | undefined;
    }
}

export class PorfolioViewerImage
{
    public imageUrl: string = '';
    public animationState: SlideAnimationStateType = SlideAnimationStateType.LeftOut;
    public isVideo: boolean = false;
    public player: YT.Player | null = null;
    public isLoaded: boolean = false;
}

@Component({
    selector: 'porfolio-viewer',
    templateUrl: './porfolio-viewer.component.html',
    styleUrls: ['./porfolio-viewer.component.css'],
    animations: [fadeInAnimation, fadeInOutAnimation, fadeVisibilityAnimation, slideZoomInOutStateAnimation],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [PointerEventsDirective, ZoomGestureDirective, SafePipe]
})
export class PorfolioViewerComponent implements OnInit, OnDestroy
{
    // #region Private Constants

    private readonly VIEWER_OPEN_CLASSNAME: string = 'viewer-open';
    private readonly IDLE_DURATION: number = 4000;
    private readonly AUTOPLAY_DURATION: number = 6000;
    private readonly YOUTUBE_ID: string = 'youtube-id';

    // #endregion

    // #region Private Memebrs

    private _porfolioViewerImages: PorfolioViewerImage[] = [];
    private _currentViewerImageIndex: number = 0;
    private _isVisible: boolean = false;

    private _disposeWindowPointerMoveHandler: (() => void) | null = null;
    private _disposeWindowPointerDownHandler: (() => void) | null = null;

    private _isIdle: boolean = false;
    private _idleTimeoutHandleId: NodeJS.Timeout | null = null;

    private _isAutoplay: boolean = false;
    private _isDuringImageTransition = false;

    private _autoPlayProgessSubscription: Subscription | null = null;

    @ViewChild('viewerProgress', { read: ElementRef, static: false })
    private _viewerProgressElementRef: ElementRef<HTMLElement> | undefined = undefined;

    @ViewChild('viewerContainer', { read: ElementRef, static: false })
    private _viewerContainerElementRef: ElementRef<HTMLElement> | undefined = undefined;

    // #endregion

    // #region Properties

    public get porfolioViewerImages(): PorfolioViewerImage[]
    {
        return this._porfolioViewerImages;
    }

    public get currentViewerImageIndex(): number
    {
        return this._currentViewerImageIndex;
    }

    public get isVisible(): boolean
    {
        return this._isVisible;
    }

    public get isAutoplay(): boolean
    {
        return this._isAutoplay;
    }

    public get isIdle(): boolean
    {
        return this._isIdle;
    }

    // #endregion

    // #region Inputs

    @Input() public videoAutoplayMessage: string = '';
    @Input() public showAutoplay: boolean = true;

    // #endregion

    // #region Events

    @Output() closing: EventEmitter<null> = new EventEmitter<null>();

	// #endregion

    // #region Constructors

    constructor(private _changeDetectorRef: ChangeDetectorRef, private _renderer: Renderer2, private _ngZone: NgZone)
    {
    }

    // #endregion

    // #region Event Handlers

    public ngOnInit(): void
    {
        this._ngZone.runOutsideAngular(() =>
        {
            this._disposeWindowPointerMoveHandler = this._renderer.listen('window', 'pointermove', this.onWindowPointerEvent.bind(this));
            this._disposeWindowPointerDownHandler = this._renderer.listen('window', 'pointerdown', this.onWindowPointerEvent.bind(this));
        });
    }

    public ngOnDestroy(): void
    {
        if (this._disposeWindowPointerMoveHandler !== null)
        {
            this._disposeWindowPointerMoveHandler();
            this._disposeWindowPointerMoveHandler = null;
        }

        if (this._disposeWindowPointerDownHandler !== null)
        {
            this._disposeWindowPointerDownHandler();
            this._disposeWindowPointerDownHandler = null;
        }

        this.close();
    }

    private onWindowPointerEvent(): void
    {
        if (this._isVisible)
        {
            this.resetIdleCheck();
        }
    }

    @HostListener('window:keydown', ['$event']) onWindowKeyDownHandler(event: KeyboardEvent)
    {
        if (event.key === KeyValue.Escape)
        {
            this.close();
        }
        else if (this._porfolioViewerImages.length > 1)
        {
            if (event.key === KeyValue.ArrowLeft)
            {
                this.playPrevViewerImage();

                this.resetIdleCheck();
            }
            else if (event.key === KeyValue.ArrowRight)
            {
                this.playNextViewerImage();

                this.resetIdleCheck();
            }
        }
    }

    public onImageSwipe(swipeDirection: SwipeDirection): void
    {
        if (this._porfolioViewerImages.length <= 1)
        {
            return;
        }

        if (swipeDirection === SwipeDirection.Right)
        {
            this.playNextViewerImage();
        }
        else if (swipeDirection === SwipeDirection.Left)
        {
            this.playPrevViewerImage();
        }

        this.resetIdleCheck();
    }

    public onBackdropClick(event: Event): void
    {
        event.preventDefault();
        this.close();
    }

    public onViewerCloseButtonClick(event: Event): void
    {
        event.preventDefault();
        this.close();
    }

    public onViewerAutoplayButtonClick(event: Event): void
    {
        event.preventDefault();

        this._isAutoplay = !this._isAutoplay;
        this.setAutoplayProgress(0);

        this.startAutoplay();
    }

    public onViewerPrevButtonClick(event: Event): void
    {
        event.preventDefault();

        this.playPrevViewerImage();
    }

    public onViewerNextButtonClick(event: Event): void
    {
        event.preventDefault();

        this.playNextViewerImage();
    }

    public onImageIndicatorButtonClick(event: Event, imageIndex: number): void
    {
        event.preventDefault();
        if (imageIndex !== this._currentViewerImageIndex)
        {
            this.playViewerImage(imageIndex, this.isLeftToRightDocument() ? imageIndex > this._currentViewerImageIndex : imageIndex < this._currentViewerImageIndex);
        }
    }

    public onVideoLoad(event: Event, porfolioViewerImage: PorfolioViewerImage): void
    {
        if (porfolioViewerImage.player !== null)
        {
            return;
        }

        const videoIframeElement: HTMLIFrameElement = event.target as HTMLIFrameElement;
        if (videoIframeElement.contentDocument === null)
        {
            this.loadYoutubeScript((isReady: boolean) =>
            {
                if (isReady)
                {
                    this.initializeYoutubePlayer(videoIframeElement, porfolioViewerImage);
                }
                else
                {
                    window.onYouTubeIframeAPIReady = () =>
                    {
                        this.initializeYoutubePlayer(videoIframeElement, porfolioViewerImage);
                    }
                }
            });
        }
    }

    public onImageLoad(porfolioViewerImage: PorfolioViewerImage): void
    {
        porfolioViewerImage.isLoaded = true;
        this._changeDetectorRef.detectChanges();
    }

    // #endregion

    // #region Public Methods

    public showPorfolio(porfolioImagesUrls: string[], startIndex: number = 0): void
    {
        this._porfolioViewerImages = [];
        this._currentViewerImageIndex = 0;

        porfolioImagesUrls.forEach((porfolioImageUrl: string) =>
        {
            const porfolioViewerImage: PorfolioViewerImage = new PorfolioViewerImage();
            porfolioViewerImage.isVideo = porfolioImageUrl.indexOf('youtube') > 0;
            porfolioViewerImage.imageUrl = porfolioImageUrl;
            this._porfolioViewerImages.push(porfolioViewerImage);
        });

        if (this._porfolioViewerImages.length > 0)
        {
            this._currentViewerImageIndex = startIndex;
            this._porfolioViewerImages[startIndex].animationState = SlideAnimationStateType.LeftIn;

            document.body.classList.add(this.VIEWER_OPEN_CLASSNAME);

            this._isVisible = true;
            this._isIdle = false;

            this.resetIdleCheck();
        }

        this._changeDetectorRef.detectChanges();
    }

    // #endregion

    // #region Private Methods

    private autoplayViewerImage(): void
    {
        if (this.isLeftToRightDocument())
        {
            this.playNextViewerImage();
        }
        else
        {
            this.playPrevViewerImage();
        }
    }

    private initializeYoutubePlayer(videoIframeElement: HTMLIFrameElement, porfolioViewerImage: PorfolioViewerImage): void
    {
        porfolioViewerImage.player = new YT.Player(videoIframeElement,
            {
                events:
                {
                    'onReady': () =>
                    {
                        porfolioViewerImage.isLoaded = true;
                        this._changeDetectorRef.detectChanges();
                    },
                    'onStateChange': (event: any) =>
                    {
                        if (event.data == 0)
                        {
                            setTimeout(() =>
                            {
                                this.autoplayViewerImage();
                            });
                        }
                    }
                }
            });
    }

    private loadYoutubeScript(loadYoutubeScriptCallback: Function): void
    {
        if (document.body.querySelector(`#${this.YOUTUBE_ID}`) !== null)
        {
            loadYoutubeScriptCallback(true);
            return;
        }

        const scriptElement: HTMLScriptElement = document.createElement("script");
        scriptElement.id = this.YOUTUBE_ID;
        scriptElement.type = 'text/javascript';
        scriptElement.src = 'https://www.youtube.com/iframe_api';
        scriptElement.async = true;
        scriptElement.defer = true;
        scriptElement.onload = () => loadYoutubeScriptCallback(false);
        document.body.appendChild(scriptElement);
    }

    private resetIdleCheck(): void
    {
        if (this._isIdle)
        {
            this._isIdle = false;
            this._changeDetectorRef.detectChanges();
        }

        if (this._idleTimeoutHandleId !== null)
        {
            clearTimeout(this._idleTimeoutHandleId);
            this._idleTimeoutHandleId = null;
        }

        this._idleTimeoutHandleId = setTimeout(() =>
        {
            this._idleTimeoutHandleId = null;

            this._isIdle = true;
            this._changeDetectorRef.detectChanges();
        }, this.IDLE_DURATION);
    }

    private playNextViewerImage(): void
    {
        this.playViewerImage(this.isLeftToRightDocument() ? this.getNextViewerImageIndex() : this.getPrevViewerImageIndex(), true);
    }

    public playPrevViewerImage(): void
    {
        this.playViewerImage(this.isLeftToRightDocument() ? this.getPrevViewerImageIndex() : this.getNextViewerImageIndex(), false);
    }

    private close(): void
    {
        this.closing.emit();

        this.stopAutoplay();

        if (this._idleTimeoutHandleId !== null)
        {
            clearTimeout(this._idleTimeoutHandleId);
            this._idleTimeoutHandleId = null;
        }

        Utils.smoothTransition(1, 0, AnimationsConstants.ANIMATION_LONG_DURATION).subscribe(
            (opacity: number) =>
            {
                if (this._viewerContainerElementRef !== undefined)
                {
                    this._viewerContainerElementRef.nativeElement.style.opacity = opacity.toString();
                }

                if (opacity === 0)
                {
                    this._isAutoplay = false;
                    this._isVisible = false;
                    this._changeDetectorRef.detectChanges();

                    document.body.classList.remove(this.VIEWER_OPEN_CLASSNAME);
                }
            });
    }

    private isLeftToRightDocument(): boolean
    {
        return document.dir === 'ltr';
    }

    private setAutoplayProgress(progress: number): void
    {
        if (this._viewerProgressElementRef !== undefined)
        {
            if (progress === 0)
            {
                this._renderer.setStyle(this._viewerProgressElementRef.nativeElement, 'opacity', '0');
            }
            else
            {
                this._renderer.setStyle(this._viewerProgressElementRef.nativeElement, 'transform', `scaleX(${progress / 100})`);
                this._renderer.removeStyle(this._viewerProgressElementRef.nativeElement, 'opacity');
            }
        }
    }

    private playViewerImage(index: number, isReversed: boolean): void
    {
        if (this._isDuringImageTransition)
        {
            return;
        }

        this._isDuringImageTransition = true;

        this.stopAutoplay();

        this.setAutoplayProgress(0);

        if (this._porfolioViewerImages[this._currentViewerImageIndex].isVideo)
        {
            this._porfolioViewerImages[this._currentViewerImageIndex].player?.pauseVideo();
        }

        if (this._porfolioViewerImages[index].isVideo)
        {
            this._porfolioViewerImages[index].player?.stopVideo();
        }

        if (isReversed)
        {
            this._porfolioViewerImages[this._currentViewerImageIndex].animationState = SlideAnimationStateType.LeftIn;
            this._porfolioViewerImages[index].animationState = SlideAnimationStateType.LeftOut;
            this._changeDetectorRef.detectChanges();
            this._porfolioViewerImages[this._currentViewerImageIndex].animationState = SlideAnimationStateType.RightOut;
            this._porfolioViewerImages[index].animationState = SlideAnimationStateType.RightIn;
        }
        else
        {
            this._porfolioViewerImages[this._currentViewerImageIndex].animationState = SlideAnimationStateType.RightIn;
            this._porfolioViewerImages[index].animationState = SlideAnimationStateType.RightOut;
            this._changeDetectorRef.detectChanges();
            this._porfolioViewerImages[this._currentViewerImageIndex].animationState = SlideAnimationStateType.LeftOut;
            this._porfolioViewerImages[index].animationState = SlideAnimationStateType.LeftIn;
        }

        this._currentViewerImageIndex = index;

        this._changeDetectorRef.detectChanges();

        setTimeout(() =>
        {
            this._isDuringImageTransition = false;
            this.startAutoplay();
        }, parseFloat(AnimationsConstants.ANIMATION_ZOOM_SLIDE_TIMING));
    }

    private stopAutoplay(): void
    {
        if (this._autoPlayProgessSubscription !== null)
        {
            this._autoPlayProgessSubscription.unsubscribe();
            this._autoPlayProgessSubscription = null;
        }
    }

    private startAutoplay(): void
    {
        this.stopAutoplay();

        if (this._isAutoplay && !this._porfolioViewerImages[this._currentViewerImageIndex].isVideo)
        {
            this._autoPlayProgessSubscription = Utils.smoothTransition(0, 100, this.AUTOPLAY_DURATION).subscribe(
                (progress: number) =>
                {
                    this.setAutoplayProgress(progress);

                    if (progress === 100)
                    {
                        if (this._autoPlayProgessSubscription !== null)
                        {
                            this._autoPlayProgessSubscription.unsubscribe();
                            this._autoPlayProgessSubscription = null;
                        }

                        this.autoplayViewerImage();
                    }
                });
        }
    }

    private getPrevViewerImageIndex(): number
    {
        return this._currentViewerImageIndex === 0 ? this._porfolioViewerImages.length - 1 : this._currentViewerImageIndex - 1;
    }

    private getNextViewerImageIndex(): number
    {
        return this._currentViewerImageIndex === this._porfolioViewerImages.length - 1 ? 0 : this._currentViewerImageIndex + 1;
    }

    // #endregion
}