export class ScrollInfo
{
	public scrollHeight: number = 0;
	public scrollTop: number = 0;
	public scrollWidth: number = 0;
	public scrollLeft: number = 0;
}

export class ItemChangePostion
{
	public item: any;
	public targetIndex: number = 0;
}

export class ViewPortInfo extends ScrollInfo
{
	public startIndex: number = 0;
	public endIndex: number = 0;
	public viewPortHeight: number = 0;
	public viewPortWidth: number = 0;
	public contentOffset: number = 0;
}

export class ListViewPortInfo extends ViewPortInfo
{
	public itemHeight: number | null = null;
}

export class ListColumnInfo
{
	public propertyName: string | null = null;
	public extraWidth: number = 0;
	public minWidth: number = 0;
	public minHeaderWidth: number = 0;
	public padding: number = 0;
	public isFixedWidth: boolean = false;
}
