import
{
	Component,
	Input,
	ContentChildren,
	QueryList,
	Directive,
	TemplateRef,
	Output,
	EventEmitter
} from '@angular/core';

import { fadeInOutAnimation } from '../../animations/fade.animation';
import { NgTemplateOutlet } from '@angular/common';

@Directive({
    selector: 'ng-template[tabContentTemplate]',
    standalone: true
})

export class TabContentTemplateDirective
{
	constructor(public templateRef: TemplateRef<any>) { }
}

@Directive({
    selector: 'ng-template[tabItemTitleTemplate]',
    standalone: true
})

export class TabItemTitleTemplateDirective
{
	constructor(public templateRef: TemplateRef<any>) { }
}

@Directive({
    selector: 'tab-item',
    standalone: true
})
export class TabItemDirective
{
	// #region Private Members

	@ContentChildren(TabItemTitleTemplateDirective, { descendants: false }) private _titleTemplates!: QueryList<TabItemTitleTemplateDirective>;
	@ContentChildren(TabContentTemplateDirective, { descendants: false }) private _contentTemplates!: QueryList<TabContentTemplateDirective>;

	// #endregion

	// #region Properties

	public titleTemplate!: TabItemTitleTemplateDirective;
	public contentTemplate!: TabContentTemplateDirective;

	// #endregion

	// #region Event Handlers

	public ngAfterContentChecked(): void
	{
		this.titleTemplate = this._titleTemplates.first;
		this.contentTemplate = this._contentTemplates.first;
	}

	// #endregion
}

export class TabIndexChangeEvent
{
	// #region Private Members

	private _currentTabIndex: number;
	private _targetTabIndex: number;
	private _isCanceled: boolean = false;

	// #endregion

	// #region Properties

	public get currentTabIndex(): number
	{
		return this._currentTabIndex;
	}

	public get targetTabIndex(): number
	{
		return this._targetTabIndex;
	}

	public get isCanceled(): boolean
	{
		return this._isCanceled;
	}

	// #endregion

	// #region Constructor

	constructor(currentTabIndex: number, targetTabIndex: number)
	{
		this._currentTabIndex = currentTabIndex;
		this._targetTabIndex = targetTabIndex;
	}

	// #endregion

	// #region Public Methods

	public preventDefault(): void
	{
		this._isCanceled = true;
	}

	// #endregion
}

@Component({
    selector: 'tabs-control',
    templateUrl: './tabs-control.component.html',
    styleUrls: ['./tabs-control.component.css'],
    animations: [fadeInOutAnimation],
    standalone: true,
    imports: [NgTemplateOutlet]
})

export class TabsControlComponent
{
	// #region Properties

	@ContentChildren(TabItemDirective) public tabs!: QueryList<TabItemDirective>;

	// #endregion

	// #region Inputs

	@Input() public tabIndex: number = 0;

	// #endregion

	// #region Events

	@Output() tabIndexChange: EventEmitter<number> = new EventEmitter<number>();
	@Output() tabIndexBeforeChange: EventEmitter<TabIndexChangeEvent> = new EventEmitter<TabIndexChangeEvent>();

	// #endregion

	// #region Event Handlers

	public onTabItemButtonClick(targetTabIndex: number): void
	{
		if (this.tabIndex !== targetTabIndex)
		{
			const tabIndexChangeEvent: TabIndexChangeEvent = new TabIndexChangeEvent(this.tabIndex, targetTabIndex);
			this.tabIndexBeforeChange.emit(tabIndexChangeEvent);

			if (!tabIndexChangeEvent.isCanceled)
			{
				this.tabIndex = targetTabIndex;
				this.tabIndexChange.emit(targetTabIndex);
			}
		}
	}

	// #endregion
}