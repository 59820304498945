export class StartFormOceanCarrier
{
    public id: number | null = null;
    public carrierName: string | null = null;
}

export class StartFormWarehouse
{
    public id: number | null = null;
    public warehouseName: string | null = null;
}

export class StartFormLists
{
    public oceanCarriers: StartFormOceanCarrier[] = [];
    public originWarehouses: StartFormWarehouse[] = [];
    public destinationWarehouses: StartFormWarehouse[] = [];
}

export class StartFormSubmitRequest
{
    public username: string | null = null;
    public email: string | null = null;
    public originWareHouseId: number | null = null;
    public destinationWareHouseId: number | null = null;
    public startTime: Date | null = null;
    public deviceSN: string | null = null;
    public cargoId: string | null = null;
    public bookingNumber: string | null = null;
    public sealNumber: string | null = null;
    public carrierId: number | null = null;
    public logisticProvider: string | null = null;
    public containerType: string | null = null;
    public deliveryType: string | null = null;
    public shippingMethod: string | null = null;
    public shippingNotes: string | null = null;
}
