import { ICGIMarkerLocation } from "../../../base/classes/cgi-marker";
import { GoogleMapUtils } from "./google-map-utils";

export interface CGIClusterOptions
{
    position?: google.maps.LatLng | google.maps.LatLngLiteral;
    markers?: (google.maps.marker.AdvancedMarkerElement | ICGIMarkerLocation)[];
}

export class CGICluster
{
    // #region Private Members

    private _position: google.maps.LatLng | undefined = undefined;

    // #endregion

    // #region Properties

    public marker: google.maps.marker.AdvancedMarkerElement | undefined = undefined;
    public markers: (google.maps.marker.AdvancedMarkerElement | ICGIMarkerLocation)[] = [];

    public get bounds(): google.maps.LatLngBounds | undefined
    {
        if (this.markers.length === 0 && this._position === undefined)
        {
            return;
        }

        const bounds: google.maps.LatLngBounds = new google.maps.LatLngBounds(this._position, this._position);
        for (const marker of this.markers)
        {
            bounds.extend(GoogleMapUtils.getMarkerLocation(marker));
        }

        return bounds;
    }

    public get position(): google.maps.LatLng
    {
        return this._position || this.bounds!.getCenter();
    }

    // #endregion

    // #region Constructors

    constructor({ markers, position }: CGIClusterOptions)
    {
        if (markers !== undefined)
        {
            this.markers = markers;
        }

        if (position !== undefined)
        {
            if (position instanceof google.maps.LatLng)
            {
                this._position = position;
            }
            else
            {
                this._position = new google.maps.LatLng(position);
            }
        }
    }

    // #endregion

    // #region Public Methods

    public delete(): void
    {
        if (this.marker)
        {
            this.marker.map = null;
            this.marker = undefined;
        }

        this.markers.length = 0;
    }

    // #endregion
}