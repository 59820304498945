<!DOCTYPE html>

<div class="page-container">
    <div class="page-header">
        <img [src]="'assets/vectors/corps-logos/' + corpId + '.svg'" />
        <div class="page-title">New Shipment Details</div>
    </div>
    <form #formStartForm="ngForm" (ngSubmit)="onStartFormSubmit(formStartForm.form.valid)" [class.form-validated]="formStartForm.submitted" ignoreEnter novalidate>
        <fieldset [disabled]="startFormModel.isBusy">
            <div class="form-content-wrapper">
                <div #pageScroller class="form-content page-scroll-padding" overlayScrollbar>
                    <div class="section-title">User Details</div>
                    <div class="row">
                        <div class="form-group">
                            <clearable-input name="username" #username="ngModel"
                                             [(ngModel)]="startFormModel.startFormSubmitRequest.username" requiredValidator [isRequired]="true" label="Username">
                            </clearable-input>
                            @if (formStartForm.submitted && username.invalid) {
                            <div class="invalid-feedback" @foldAnimation>This field is required</div>
                            }
                        </div>
                        <div class="form-group">
                            <clearable-input name="email" #email="ngModel" type="email" [(ngModel)]="startFormModel.startFormSubmitRequest.email"
                                             requiredValidator emailValidator [isRequired]="true" label="Email Address">
                            </clearable-input>
                            @if (formStartForm.submitted && email.invalid) {
                            <div @foldAnimation>
                                @if (email.errors?.['requiredValidator']) {
                                <div class="invalid-feedback">This field is required</div>
                                }
                                @if (email.errors?.['emailValidator']) {
                                <div class="invalid-feedback">Valid Email format is required</div>
                                }
                            </div>
                            }
                        </div>
                    </div>
                    <div class="section-title">Shipment Details</div>
                    <div class="row">
                        <div class="form-group">
                            <select-list name="originWarehouse" #originWarehouse="ngModel" placeholder="Origin Location"
                                         [items]="startFormModel.startFormLists.originWarehouses" [isRequired]="true"
                                         [(ngModel)]="startFormModel.startFormSubmitRequest.originWareHouseId"
                                         bindLabel="warehouseName" bindValue="id">
                            </select-list>
                            @if (formStartForm.submitted && originWarehouse.invalid) {
                            <div class="invalid-feedback" @foldAnimation>This field is required</div>
                            }
                        </div>
                        <div class="form-group">
                            <select-list name="destinationWarehouse" #destinationWarehouse="ngModel" placeholder="Destination Location"
                                         [items]="startFormModel.startFormLists.destinationWarehouses" [isRequired]="true"
                                         [(ngModel)]="startFormModel.startFormSubmitRequest.destinationWareHouseId"
                                         bindLabel="warehouseName" bindValue="id">
                            </select-list>
                            @if (formStartForm.submitted && destinationWarehouse.invalid) {
                            <div class="invalid-feedback" @foldAnimation>This field is required</div>
                            }
                        </div>
                        <div class="form-group">
                            <datetime-picker label="Start Time" name="startTime" #startTime="ngModel"
                                             [(ngModel)]="startFormModel.startFormSubmitRequest.startTime" [isRequired]="true" [withTime]="true"
                                             requiredValidator>
                            </datetime-picker>
                            @if (formStartForm.submitted && startTime.invalid) {
                            <div class="invalid-feedback" @foldAnimation>This field is required</div>
                            }
                        </div>
                        <div class="form-group">
                            <clearable-input name="deviceSN" #deviceSN="ngModel"
                                             [(ngModel)]="startFormModel.startFormSubmitRequest.deviceSN" requiredValidator [isRequired]="true" label="Device S/N">
                            </clearable-input>
                            @if (formStartForm.submitted && deviceSN.invalid) {
                            <div class="invalid-feedback" @foldAnimation>This field is required</div>
                            }
                        </div>
                        <div class="form-group">
                            <clearable-input name="cargoId" #cargoId="ngModel"
                                             [(ngModel)]="startFormModel.startFormSubmitRequest.cargoId" requiredValidator [isRequired]="true" label="Cargo ID">
                            </clearable-input>
                            @if (formStartForm.submitted && cargoId.invalid) {
                            <div class="invalid-feedback" @foldAnimation>This field is required</div>
                            }
                        </div>
                        <div class="form-group">
                            <clearable-input name="bookingNumber" #bookingNumber="ngModel" [(ngModel)]="startFormModel.startFormSubmitRequest.bookingNumber"
                                             label="Booking Number">
                            </clearable-input>
                        </div>
                        <div class="form-group">
                            <clearable-input name="sealNumber" #sealNumber="ngModel"
                                             [(ngModel)]="startFormModel.startFormSubmitRequest.sealNumber" label="Seal Number">
                            </clearable-input>
                        </div>
                        <div class="form-group">
                            <select-list name="carrierId" #carrierId="ngModel" placeholder="Carrier"
                                         [items]="startFormModel.startFormLists.oceanCarriers" [(ngModel)]="startFormModel.startFormSubmitRequest.carrierId"
                                         bindLabel="carrierName" bindValue="id">
                            </select-list>
                        </div>
                        <div class="form-group">
                            <clearable-input name="logisticProvider" #logisticProvider="ngModel"
                                             [(ngModel)]="startFormModel.startFormSubmitRequest.logisticProvider" label="logistic Provider">
                            </clearable-input>
                        </div>
                        @if (corpId === CorpIdType.PMI) {
                        <div class="form-group">
                            <select-list name="containerType" #containerType="ngModel" placeholder="Container Type"
                                         [items]="startFormModel.containerTypes" [isRequired]="true" requiredValidator
                                         [(ngModel)]="startFormModel.startFormSubmitRequest.containerType">
                            </select-list>
                            @if (formStartForm.submitted && containerType.invalid) {
                            <div class="invalid-feedback" @foldAnimation>This field is required</div>
                            }
                        </div>
                        }
                        @if (corpId === CorpIdType.PMI) {
                        <div class="form-group">
                            <select-list name="deliveryType" #deliveryType="ngModel" placeholder="Delivery Type"
                                         [items]="startFormModel.deliveryTypes" [isRequired]="true" requiredValidator
                                         [(ngModel)]="startFormModel.startFormSubmitRequest.deliveryType">
                            </select-list>
                            @if (formStartForm.submitted && deliveryType.invalid) {
                            <div class="invalid-feedback" @foldAnimation>This field is required</div>
                            }
                        </div>
                        }
                        @if (corpId === CorpIdType.EsteeLauder) {
                        <div class="form-group">
                            <select-list name="shippingMethod" #shippingMethod="ngModel" placeholder="Shiping Method"
                                         [items]="startFormModel.shippingMethods" [(ngModel)]="startFormModel.startFormSubmitRequest.shippingMethod">
                            </select-list>
                        </div>
                        }
                        <div class="form-group full-width">
                            <textarea placeholder=" " name="shippingNotes" #shippingNotes="ngModel" class="form-control" autosize
                                      [(ngModel)]="startFormModel.startFormSubmitRequest.shippingNotes">
              </textarea>
                            <label class="control-label">Shiping Notes</label>
                        </div>
                    </div>
                    <div class="section-title">Load Shipment Images</div>
                    <div class="row">
                        <div class="form-group attachments-group">
                            <div class="attachment-placeholder select-none" dragDrop
                                 [disabled]="startFormModel.attachmentsInfo[0].availableAttachmentSizeToAdd <= 0"
                                 (filesDropped)="onAttachmentsFilesDrop($event)">
                                <i class="icon-drag-drop"></i><span>Drop files here</span>
                            </div>
                            <div class="attachments-container" (wheel)="onAttachmetsWheel($event)" overlayScrollbar>
                                <div class="attachments-content">
                                    @for (attachmentFileData of startFormModel.attachmentsInfo[0].uploadAttachmentsFilesData; track attachmentFileData; let attachmentIndex = $index) {
                                    <div #attachment class="attachment ripple-effect ripple-fill ripple-inverse-color"
                                         (click)="onAttachmentClick($event)" @foldBothAnimation>
                                        <img [src]="attachmentFileData.attachmentFileUrl | safe" alt=" " (load)="onImageLoad($event)"
                                             (error)="onImageError($event, attachmentFileData.attachmentFileUrl)" />
                                        <button type="button" class="ripple-effect ripple-center ripple-inverse-color"
                                                (click)="onDeleteAttachmentButtonClick($event, attachmentIndex)">
                                            <i class="icon-cancel"></i>
                                        </button>
                                    </div>
                                    }
                                </div>
                            </div>
                            <div class="characters-counter select-none">
                                <span>Attachments size left: {{ startFormModel.attachmentsInfo[0].availableAttachmentSizeToAdd }}MB</span>
                            </div>
                            <div class="horizontal-groups" [class.disabled]="startFormModel.attachmentsInfo[0].availableAttachmentSizeToAdd <= 0">
                                <label for="file-input" class="file-input horizontal-group camera-button ripple-effect ripple-fill ripple-inverse-color">
                                    <i class="icon-image"></i>
                                </label>
                                <camera (change)="onAddCapturedAttachmentsChange($event)"></camera>
                            </div>
                            <input id="file-input" type="file" accept="image/*" multiple (change)="onAddAttachmentsChange($event)" />
                        </div>
                    </div>
                </div>
                <div class="form-group action-button-container">
                    <button type="button" class="secondary-button submit-button ripple-effect ripple-fill" [disabled]="!isDirty"
                            (click)="onClearStartFormButtonClick()">
                        Clear
                    </button>
                    <button type="submit" class="primary-button ripple-effect ripple-fill" [disabled]="!isDirty">
                        <span class="spinner-container">
                            @if (startFormModel.isBusy) {
                            <i @fadeInOutAnimation class="icon-spinner"></i>
                            }
                            Submit
                        </span>
                    </button>
                </div>
            </div>
        </fieldset>
    </form>
</div>

  @if (!viewIsReady) {
<loader @fadeOutAnimation [loadingMessage]="statusMessage" [showSpinner]="showSpinner">
</loader>
  }
