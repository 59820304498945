import { Component, ElementRef, NgZone, OnInit, Renderer2 } from '@angular/core';
import { OverlayScrollbarDirective } from '../../directives/overlay-scrollbar/overlay-scrollbar.directive';

@Component({
    selector: 'ovarlay-scrollbar',
    template: `<ng-content></ng-content>`,
    standalone: true
})
export class OverlayScrollbarComponent extends OverlayScrollbarDirective implements OnInit
{
	// #region Constructors

	constructor(_elementRef: ElementRef<HTMLElement>, _renderer: Renderer2, _ngZone: NgZone)
	{
		super(_elementRef, _renderer, _ngZone);
	}

    // #endregion

	// #region Event Handlers

	public override ngOnInit(): void
	{
		this.resizeElement = this._elementRef.nativeElement.firstElementChild as HTMLElement;

		super.ngOnInit();
	}

	// #endregion
}