import { animate, AnimationBuilder, AnimationFactory, AnimationPlayer, style } from "@angular/animations";
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Output, ViewChild } from "@angular/core";
import { Constants } from "../../utils/globals";
import { Utils } from "../../utils/utils";
import { AnimationsConstants } from "../../animations/constant";

export enum DropdownPosition { Top, Bottom, Left, Right }

export enum DropdownAlignment { Top, Center, Bottom }

export enum DropdownState { Open, Closed, Opening, Closing }

export interface IDropdownPosition
{
    dropdownVerticalPosition: DropdownPosition | null;
    dropdownHorizontalPosition: DropdownPosition | null;
}

@Component({ template: '' })
export class DropdownBaseComponent
{
    // #region Private Members

    private _isDropdownPressed: boolean = false;
    private _dorpdownPanelElement: HTMLElement | null = null;

    @ViewChild('dropdownPanel', { read: ElementRef, static: false }) set dropdownPanelElement(dropdownPanelElementRef: ElementRef<HTMLElement>)
    {
        if (dropdownPanelElementRef !== undefined)
        {
            this._dorpdownPanelElement = dropdownPanelElementRef.nativeElement;
        }
    }

    // #endregion

    // #region Protected Members

    protected _dropdownState: DropdownState = DropdownState.Closed;
    protected _dropdownPosition: DropdownPosition = DropdownPosition.Bottom;

    // #endregion

    // #region Properties

    public get DropdownState()
    {
        return DropdownState;
    }

    public get dropdownState(): DropdownState
    {
        return this._dropdownState;
    }

    // #endregion

    // #region Events

    @Output() public stateChange: EventEmitter<DropdownState> = new EventEmitter();

    // #endregion

    // #region Constructors

    constructor(protected _elementRef: ElementRef<HTMLElement>, protected _changeDetectorRef: ChangeDetectorRef, protected _animationBuilder: AnimationBuilder)
    {
    }

    // #endregion

    // #region Event Handlers

    @HostListener('document:focusout', ['$event']) onDocumentBlur(event: FocusEvent): void
    {
        if (this._isDropdownPressed)
        {
            this._isDropdownPressed = false;
            return;
        }

        if (event.relatedTarget !== null && !this._elementRef.nativeElement.contains(event.relatedTarget as Node))
        {
            this.close(false);
        }
    }

    @HostListener('document:focusin', ['$event']) onDocumentFocus(): void
    {
        if (document.activeElement !== null && this._elementRef.nativeElement.contains(document.activeElement as Node))
        {
            this.open();
        }
    }

    @HostListener('document:pointerdown', ['$event']) onDocumentPointerDown(event: MouseEvent): void
    {
        if (event.target !== null)
        {
            if (this._elementRef.nativeElement.contains(event.target as Node))
            {
                this._isDropdownPressed = true;
            }
            else
            {
                this.close(false);
            }
        }
    }

    @HostListener('keydown.escape', ['$event']) onKeyDownEscape(event: KeyboardEvent): void
    {
        if (this._dropdownState === DropdownState.Open)
        {
            event.stopImmediatePropagation();

            this.close(false);
        }
    }

    // #endregion

    // #region Protected Methods

    protected close(retainFocus: boolean): void
    {
        if (retainFocus)
        {
            this._elementRef.nativeElement.classList.add(Constants.FOCUS_CLASSNAME);
        }
        else
        {
            this._elementRef.nativeElement.classList.remove(Constants.FOCUS_CLASSNAME);
        }

        if (this._dropdownState === DropdownState.Closed)
        {
            return;
        }

        this._dropdownState = DropdownState.Closing;
        this.stateChange.emit(this._dropdownState);

        this._changeDetectorRef.detectChanges();
        this.updateDropdownStateClass();

        setTimeout(() =>
        {
            this.showDropdownPanel(false, this.onDropdownPanelClosed.bind(this));
        });
    }

    protected onDropdownPanelOpened(): void
    {
        this._dropdownState = DropdownState.Open;
        this.stateChange.emit(this._dropdownState);

        this._changeDetectorRef.detectChanges();
        this.updateDropdownStateClass();

        requestAnimationFrame(() =>
        {
            if (this._dorpdownPanelElement !== null)
            {
                Utils.scrollElementsBounderiesIntoView([this._elementRef.nativeElement, this._dorpdownPanelElement]);
            }
        });
    }

    protected onDropdownPanelClosed(): void
    {
        this._dropdownState = DropdownState.Closed;
        this.stateChange.emit(this._dropdownState);

        this._changeDetectorRef.detectChanges();
        this.updateDropdownStateClass();
        this.clearDropdownPositionClass();
    }

    protected open(): void
    {
        this._elementRef.nativeElement.classList.add(Constants.FOCUS_CLASSNAME);

        if (this._dropdownState === DropdownState.Closed)
        {
            this._dropdownState = DropdownState.Opening;
            this.stateChange.emit(this._dropdownState);

            this._changeDetectorRef.detectChanges();
            this.updateDropdownStateClass();

            setTimeout(() => this.animateOpen());
        }
    }

    protected animateOpen(): void
    {
        if (this._dorpdownPanelElement !== null)
        {
            const scrollableContainerElement: HTMLElement = Utils.getParentElementScrollableContainer(this._elementRef.nativeElement);

            const elementRect: DOMRect = this._elementRef.nativeElement.getBoundingClientRect();
            const dropdownPanelElementRect: DOMRect = this._dorpdownPanelElement.getBoundingClientRect();
            const scrollableContainerElementRect: DOMRect = scrollableContainerElement.getBoundingClientRect();

            const topDropdownOffset: number = elementRect.top - dropdownPanelElementRect.bottom;

            if (elementRect.bottom + dropdownPanelElementRect.height + Constants.DROPDOWN_SHADOW_SIZE <
                scrollableContainerElementRect.bottom)
            {
                this._dropdownPosition = DropdownPosition.Bottom;
            }
            else if (elementRect.top - dropdownPanelElementRect.height - topDropdownOffset - Constants.DROPDOWN_SHADOW_SIZE >
                scrollableContainerElementRect.top)
            {
                this._dropdownPosition = DropdownPosition.Top;
            }
            else if (elementRect.bottom + dropdownPanelElementRect.height + Constants.DROPDOWN_SHADOW_SIZE <
                scrollableContainerElementRect.bottom + scrollableContainerElement.scrollHeight - scrollableContainerElement.scrollTop -
                scrollableContainerElement.clientHeight)
            {
                this._dropdownPosition = DropdownPosition.Bottom;
            }
            else if (elementRect.top - dropdownPanelElementRect.height - topDropdownOffset - Constants.DROPDOWN_SHADOW_SIZE >
                scrollableContainerElementRect.bottom - scrollableContainerElement.scrollHeight - scrollableContainerElement.scrollTop)
            {
                this._dropdownPosition = DropdownPosition.Top;
            }
            else
            {
                this._dropdownPosition = DropdownPosition.Bottom;
            }

            this._elementRef.nativeElement.classList.add(DropdownPosition[this._dropdownPosition].toLowerCase());

            this._dorpdownPanelElement.style.opacity = '1';

            const isRtl: boolean = getComputedStyle(document.documentElement).direction === 'rtl';
            if (isRtl)
            {
                if (elementRect.right - dropdownPanelElementRect.width < scrollableContainerElementRect.left)
                {
                    this._dorpdownPanelElement.style.right = 'auto';
                    this._dorpdownPanelElement.style.left = '0';
                }
            }
            else if (elementRect.left + dropdownPanelElementRect.width > scrollableContainerElementRect.right)
            {
                this._dorpdownPanelElement.style.left = 'auto';
                this._dorpdownPanelElement.style.right = '0';
            }
        }

        this.showDropdownPanel(true, this.onDropdownPanelOpened.bind(this));
    }

    // #endregion

    // #region Private Methods

    private showDropdownPanel(isShowPanel: boolean, showDropdownPanelCompletedCallback: (() => void) | null = null): void
    {
        if (this._dorpdownPanelElement === null)
        {
            return;
        }

        let foldAnimation: AnimationFactory;
        if (isShowPanel)
        {
            foldAnimation = this._animationBuilder.build(
                [
                    style({ maxHeight: 0 }),
                    animate(AnimationsConstants.ANIMATION_TIMING, style({ maxHeight: `${this._dorpdownPanelElement.clientHeight}px` }))
                ]);
        }
        else
        {
            foldAnimation = this._animationBuilder.build(
                [
                    style({ maxHeight: `${this._dorpdownPanelElement.clientHeight}px` }),
                    animate(AnimationsConstants.ANIMATION_TIMING, style({ maxHeight: 0 }))
                ]);
        }

        const dropdownOpenAnimationPlayer: AnimationPlayer = foldAnimation.create(this._dorpdownPanelElement);

        this._dorpdownPanelElement.style.overflow = 'hidden';

        dropdownOpenAnimationPlayer.onDone(() =>
        {
            if (this._dorpdownPanelElement !== null)
            {
                this._dorpdownPanelElement.style.overflow = '';
            }

            if (showDropdownPanelCompletedCallback !== null)
            {
                showDropdownPanelCompletedCallback();
            }
        });

        dropdownOpenAnimationPlayer.play();
    }

    private updateDropdownStateClass(): void
    {
        for (const key in DropdownState)
        {
            if (typeof DropdownState[key] === 'string')
            {
                this._elementRef.nativeElement.classList.remove(DropdownState[key].toLowerCase());
            }
        }

        this._elementRef.nativeElement.classList.add(DropdownState[this._dropdownState].toLowerCase());
    }

    private clearDropdownPositionClass(): void
    {
        for (const key in DropdownPosition)
        {
            if (typeof DropdownPosition[key] === 'string')
            {
                this._elementRef.nativeElement.classList.remove(DropdownPosition[key].toLowerCase());
            }
        }
    }

    // #endregion
}