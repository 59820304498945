<!DOCTYPE html>

<div class="page-container page-image">
    <div class="page-content-conatiner">
        @if (!('IS_MOBILE' | globals)) {
        <div class="login-card-container">
            <div class="login-card-title">Welcome</div>
            <img src="assets/images/login.png" />
            <div class="login-card-copyright">CONTGUARD © All rights reserved</div>
        </div>
        }
        <div class="page-content animated">
            <img id="login-logo" src="assets/vectors/logo.svg">
            <div id="app-version">Version {{ appVersion }}</div>
            <form #formLogin="ngForm" (ngSubmit)="formLogin.form.valid && onLoginSubmit()"
                  [ngClass]="{ 'form-validated': formLogin.submitted }" novalidate>
                <fieldset class="page-padding" [disabled]="loginModel.isBusy">
                    <div class="form-group">
                        <clearable-input name="username" #username="ngModel" [(ngModel)]="loginModel.username" requiredValidator [isRequired]="true" label="Email"
                                         type="email">
                        </clearable-input>
                        @if (formLogin.submitted && username.invalid) {
                        <div class="invalid-feedback" @foldAnimation>This field is required</div>
                        }
                    </div>
                    @if (loginModel.loginExternalIdentityProvider === null) {
                    <div class="form-group" @foldAnimation>
                        <password-input label="Password" name="password" #password="ngModel" [(ngModel)]="loginModel.password"
                                        requiredValidator>
                        </password-input>
                        @if (formLogin.submitted && password.invalid) {
                        <div class="invalid-feedback" @foldAnimation>This field is required</div>
                        }
                    </div>
                    }
                    <div class="form-group">
                        <button type="submit" class="primary-button ripple-effect ripple-fill">
                            <span class="spinner-container">
                                @if (loginModel.isBusy) {
                                <i @fadeInOutAnimation class="icon-spinner"></i>
                                }
                                <span>Login {{ loginModel.loginExternalIdentityProvider === null ? '' : ' with ' }}</span>
                                @if (loginModel.loginExternalIdentityProvider !== null) {
                                <span class="external-login-provider">{{ loginModel.loginExternalIdentityProvider }}</span>
                                }
                            </span>
                        </button>
                        @if (loginModel.loginExternalIdentityProvider === null) {
                        <div class="login-links" @foldAnimation>
                            <button type="button" class="link-button" (click)="onResetPasswordButtonClick()">Forgot Password?</button>
                        </div>
                        }
                    </div>
                </fieldset>
            </form>
        </div>
    </div>
</div>
