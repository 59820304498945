<!DOCTYPE html>

<div class="page-container">
    @if (('IS_MOBILE' | globals)) {
    <top-bar></top-bar>
    }
    @if (viewIsInitialized) {
    <div class="page-content">
        <div class="map-section" [@foldVerticalStateAnimation]="!collapseShipmentsMonitoringMapSection">
            @if (('IS_MOBILE' | globals) && !collapseMonitoringItemsFilters) {
            <div tabindex="-1" class="overlay live-filters-overlay" (click)="collapseMonitoringItemsFilters = true"
                 @fadeInOutAnimation>
            </div>
            }
            @if (('IS_MOBILE' | globals) && !collapseMonitoringItemsFilters) {
            <div class="live-filters-wrapper" @slideInOutAnimation>
                <ng-container [ngTemplateOutlet]="liveFiltersTemplate"></ng-container>
            </div>
            }
            @if (!('IS_MOBILE' | globals) && !collapseMonitoringItemsFilters) {
            <div class="live-filters-wrapper animation-overflow" [class.full-height]="collapseShipmentsMonitoringTable" [class.hidden]="collapseShipmentsMonitoringMapSection"
                 @foldHorizontalAnimation>
                <ng-container [ngTemplateOutlet]="liveFiltersTemplate"></ng-container>
            </div>
            }
            <div class="map-wrapper" [class.full-height]="collapseShipmentsMonitoringTable && collapseMonitoringItemsFilters"
                 [class.full-width]="collapseMonitoringItemsFilters">
                <google-map #googleMap [showTreetView]="true" (initialize)="onGoogleMapInitialize($event)">
                    <div class="map-controls-container top">
                        @if (!('IS_MOBILE' | globals) && collapseMonitoringItemsFilters) {
                        <button type="button" [disabled]="!monitoringItemsModel.isMonitoringFiltersActive" @fadeInOutAnimation
                                class="clear-filters-button floating-button ripple-effect ripple-fill" (click)="onClearMonitoringItemsFiltersButtonClick()">
                            <i class="icon-filter-circle-xmark"></i>
                        </button>
                        }
                        @if (('IS_MOBILE' | globals)) {
                        <button type="button" class="filter-button floating-button ripple-effect ripple-fill"
                                (click)="onShowFiltersButtonClick()">
                            <ng-container [ngTemplateOutlet]="filterIconTemplate"></ng-container>
                        </button>
                        }
                        <div class="popup-button-container more-options">
                            <div class="dropdown" dropdown [(isExpanded)]="showMonitoringPopups[currentMonitoringPopup]"
                                 (isExpandedChange)="onShowMonitoringItemsPopupsChange()">
                                <button type="button" class="floating-button ripple-effect ripple-fill" (click)="onMapMoreOptionsButtonClick($event)">
                                    <i class="icon-more"></i>
                                </button>
                                @if (showMonitoringPopups[MonitoringPopupType.More]) {
                                <div class="dropdown-container left" @foldHorizontalAnimation
                                     (@foldHorizontalAnimation.done)="onShowMapMoreOptionsFoldAnimationDone()">
                                    @if (('IS_MOBILE' | globals)) {
                                    <button type="button" class="menu-button" (click)="onInfoButtonClick($event)">
                                        <i class="icon-info"></i>Information
                                    </button>
                                    }
                                    <button type="button" class="menu-button" (click)="onRefreshButtonClick()"><i class="icon-refresh"></i>Refresh</button>
                                    @if (!('IS_MOBILE' | globals)) {
                                    <button type="button" class="menu-button" (click)="onExportToExcelButtonClick()">
                                        <i class="icon-excel"></i>Export to Excel
                                    </button>
                                    }
                                    <button type="button" class="menu-button" (click)="onShareSnapshotMenuItemClick()">
                                        <i class="icon-share-nodes"></i>Share Snapshot
                                    </button>
                                    <button type="button" class="menu-button" (click)="onHelpButtonClick($event)"><i class="icon-question"></i>Help</button>
                                    <div class="seperator"></div>
                                    <button type="button" class="menu-button" (click)="showMapRailways = !showMapRailways">
                                        <i class="icon-train-station"></i>Show Railways
                                        <i [class.hide]="!showMapRailways" class="icon-check"></i>
                                    </button>
                                    <div class="seperator"></div>
                                    <button type="button" class="menu-button" (click)="onMapModeButtonClick(true)">
                                        <i class="icon-earth-americas"></i>Satellite View
                                        @if (isSatelliteView) {
                                        <i class="icon-check"></i>
                                        }
                                    </button>
                                    <button type="button" class="menu-button" (click)="onMapModeButtonClick(false)">
                                        <i class="icon-map"></i>Map View
                                        @if (!isSatelliteView) {
                                        <i class="icon-check"></i>
                                        }
                                    </button>
                                </div>
                                }
                                @if (('IS_MOBILE' | globals) && showMonitoringPopups[MonitoringPopupType.Info]) {
                                <div class="popup-button-content-container info select-none"
                                     @foldBothAnimation>
                                    <ng-container [ngTemplateOutlet]="popupInfoContentTemplate"></ng-container>
                                </div>
                                }
                                @if (('IS_MOBILE' | globals) && showMonitoringPopups[MonitoringPopupType.Help]) {
                                <div class="popup-button-content-container help select-none"
                                     @foldBothAnimation>
                                    <ng-container [ngTemplateOutlet]="popupHelpContentTemplate"></ng-container>
                                </div>
                                }
                                @if (!('IS_MOBILE' | globals) && showMonitoringPopups[MonitoringPopupType.Help]) {
                                <div class="popup-button-content-container help select-none" [class.small]="!collapseShipmentsMonitoringTable"
                                     @foldHorizontalAnimation>
                                    <ng-container [ngTemplateOutlet]="popupHelpContentTemplate"></ng-container>
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                    <div class="map-controls-container bottom">
                        <button type="button" class="center-button floating-button ripple-effect ripple-fill" (click)="onReCenterMapButtonClick()">
                            @if (!isMarkerActive) {
                            <i class="icon-target" @fadeInOutAnimation></i>
                            }
                            @if (isMarkerActive) {
                            <i class="icon-center" @fadeInOutAnimation></i>
                            }
                        </button>
                    </div>
                </google-map>
                @if (!('IS_MOBILE' | globals)) {
                <div class="vertical-expander" [class.expanded]="collapseMonitoringItemsFilters">
                    <button type="button" class="ripple-effect ripple-center ripple-inverse-color dropdown-shadow" [class.flipped]="collapseMonitoringItemsFilters"
                            (click)="collapseMonitoringItemsFilters = !collapseMonitoringItemsFilters">
                        <i class="icon-arrow-left"></i>
                    </button>
                </div>
                }
                @if (!('IS_MOBILE' | globals)) {
                <div class="horizontal-expander">
                    <button type="button" class="ripple-effect ripple-center ripple-inverse-color dropdown-shadow" [class.flipped]="collapseShipmentsMonitoringTable"
                            (click)="collapseShipmentsMonitoringTable = !collapseShipmentsMonitoringTable">
                        <i class="icon-arrow-down"></i>
                    </button>
                </div>
                }
            </div>
        </div>
        @if (!('IS_MOBILE' | globals) && !collapseShipmentsMonitoringMapSection && !collapseShipmentsMonitoringTable) {
        <div class="divider" @foldHorizontalAnimation></div>
        }
        @if (!('IS_MOBILE' | globals)) {
        <div class="live-manager-table-wrapper animation-overflow" [@foldVerticalStateAnimation]="!collapseShipmentsMonitoringTable"
             [class.full-height]="collapseShipmentsMonitoringMapSection" [class.collapse]="collapseShipmentsMonitoringTable">
            <div class="manager-table-container page-section page-section-padding">
                <virtual-list #monitoringItemsList [items]="monitoringItemsModel.filteredMonitoringItems" id="live-manager-shipments-table" class="manager-table"
                              [isSortable]="true" tabindex="-1" [virtualListInfo]="shipmentsMonitoringTableListInfo" [isAutoColumnsWidths]="true"
                              [filterProperties]="tableMonitoringItemsSearchProperties"
                              [class.vertical-scrollable]="monitoringItemsList.element.scrollHeight > monitoringItemsList.element.clientHeight"
                              (itemsChange)="onMonitoringItemsTableItemsChange()">
                    <div #header class="list-header">
                        @for (column of tableMonitoringItemsColumns; track column.columnType) {
                        <div class="col-list" [class]="column.classes ?? ''" [attr.property-name]="column.propertyName"
                             [attr.sort-property-name]="column.sortPropertyName" [attr.fixed-width]="column.isFixedWidth ? 'true' : null">
                            @switch (column.columnType) {
                            @case (ShipmentColumnType.Temperature) {
                            <i class="icon-temperature-full"></i>
                            }
                            @case (ShipmentColumnType.Humidity) {
                            <i class="icon-droplet"></i>
                            }
                            @case (ShipmentColumnType.Impact) {
                            <i class="icon-explosion-solid"></i>
                            }
                            @case (ShipmentColumnType.Security) {
                            <i class="icon-lock-open"></i>
                            }
                            @case (ShipmentColumnType.Copy) {
                            }
                            @case (ShipmentColumnType.Share) {
                            }
                            @case (ShipmentColumnType.Favorites) {
                            }
                            @case (ShipmentColumnType.Track) {
                            }
                            @default {
                            <span [innerHTML]="column.text"></span>
                            }
                            }
                        </div>
                        }
                    </div>
                    @for (monitoringItem of monitoringItemsList.viewPortItems; track monitoringItem.shipmentKey) {
                    <div #body class="list-row ripple-effect ripple-fill ripple-inverse-color"
                         [ngClass]="{ 'odd-row': monitoringItemsList.filteredSortedItems.indexOf(monitoringItem) % 2,
                         'selected': monitoringItem === monitoringItemsModel.selectedMonitoringItem }"
                         (click)="onMonitoringItemsTableItemClick(monitoringItem)">
                        <div class="col-list" *ngFor="let column of tableMonitoringItemsColumns" [class]="column.classes ?? ''">
                            <ng-container [ngSwitch]="column.columnType">
                                <ng-container *ngIf="!monitoringItemsModel.showCompletedMonitoringItems">
                                    <ng-container *ngSwitchCase="LiveShipmentColumnType.RouteProgress">
                                        <div class="route-progress-container">
                                            <div class="route-progress-value" [style.width]="monitoringItem.progress + '%'"></div>
                                        </div>
                                        <div class="route-progress-text">{{ monitoringItem.progress }}%</div>
                                    </ng-container>
                                </ng-container>
                                <ng-container *ngSwitchCase="ShipmentColumnType.Copy">
                                    <div class="dropdown" #showCopyOptions="dropdown" dropdown [attachToBodyTemplateRef]="dropdownCopyShipmentInfoTemplate"
                                         [attachToBodyTemplateOutletContext]="{ item: monitoringItem }">
                                        <button type="button" class="image-button ripple-effect ripple-center ripple-inverse-color"
                                                tooltipPopup="Copy" (click)="onShareButtonClick($event)">
                                            <i class="icon-copy"></i>
                                        </button>
                                    </div>
                                </ng-container>
                                <ng-container *ngSwitchCase="ShipmentColumnType.Share">
                                    <div class="dropdown" #showShareOptions="dropdown" dropdown [attachToBodyTemplateRef]="dropdownShareShipmentInfoTemplate"
                                         [attachToBodyTemplateOutletContext]="{ item: monitoringItem }">
                                        <button type="button" class="image-button ripple-effect ripple-center ripple-inverse-color"
                                                tooltipPopup="Share" (click)="onShareButtonClick($event)">
                                            <i class="icon-share-nodes"></i>
                                        </button>
                                    </div>
                                </ng-container>
                                <ng-container *ngSwitchCase="ShipmentColumnType.Favorites">
                                    <label class="checkbox-container favorites ripple-effect ripple-center ripple-inverse-color"
                                           tooltipPopup="Favorites" (click)="onShipmentMonitoringFavoriteClick($event, monitoringItem, false)">
                                        <input name="favorites" type="checkbox" class="table-favorites" [checked]="monitoringItem.isInFovarites">
                                        <ng-container [ngTemplateOutlet]="favoriteIconTemplate"></ng-container>
                                    </label>
                                </ng-container>
                                <ng-container *ngSwitchCase="ShipmentColumnType.Track">
                                    <button type="button" class="image-button ripple-effect ripple-center ripple-inverse-color"
                                            tooltipPopup="Track" (click)="onMarkerShipmentTrackButtonClick($event, monitoringItem)">
                                        <i class="icon-route"></i>
                                    </button>
                                </ng-container>
                                <ng-container *ngSwitchDefault>
                                    <div [tooltipPopup]="column.propertyName ? monitoringItem[column.propertyName] : ''" [showTooltipOnVerflow]="true">
                                        <ng-container [ngTemplateOutlet]="columnEmptyValueTemplate"
                                                      [ngTemplateOutletContext]="{ column: column, item: monitoringItem }">
                                        </ng-container>
                                    </div>
                                </ng-container>
                            </ng-container>
                        </div>
                    </div>
                    }
                </virtual-list>
                <div class="dropdown columns-select" dropdown [(isExpanded)]="showShipmentsTableColumnSelection" [autoClose]="false"
                     [attachToBodyTemplateRef]="dropdownTableColumnsSelectTemplate">
                    <button type="button" class="image-button ripple-effect ripple-center ripple-inverse-color" [class.active]="showShipmentsTableColumnSelection">
                        <i class="icon-more"></i>
                    </button>
                </div>
            </div>
            <div class="horizontal-expander top">
                <button type="button" class="ripple-effect ripple-center ripple-inverse-color" [class.flipped]="!collapseShipmentsMonitoringMapSection"
                        (click)="collapseShipmentsMonitoringMapSection = !collapseShipmentsMonitoringMapSection">
                    <i class="icon-arrow-down"></i>
                </button>
            </div>
        </div>
        }
    </div>
    }
    @if (!viewIsReady || monitoringItemsModel.isBusy) {
    <loader [@.disabled]="!viewIsInitialized" @fadeInOutAnimation [loadingMessage]="statusMessage"
            [showSpinner]="showSpinner || monitoringItemsModel.isBusy" [isBusyLoader]="viewIsReady && monitoringItemsModel.isBusy" [isBusyLoaderOverlay]="true">
    </loader>
    }
</div>

<!-- dropdownTableColumnsSelectTemplate -->
<ng-template #dropdownTableColumnsSelectTemplate>
    <div class="dropdown-container columns body">
        <div class="add-remove-columns-title">Add/Remove Columns</div>
        <label class="checkbox-container sellect-all-columns ripple-effect ripple-inverse-color">
            <input type="checkbox" [(ngModel)]="isTableShipmentOptionalColumnsSelectAll">
            <span class="checkmark"></span>
            <span>Sellect All</span>
        </label>
        <div class="dropdown-container-content" overlayScrollbar>
            @for (column of tableShipmentsMonitoringOptionalColumns; track column.columnType) {
            <label class="checkbox-container ripple-effect ripple-inverse-color">
                <input type="checkbox" [(ngModel)]="column.selected" (change)="onTableShipmentOptionalColumnsSelectChange()">
                <span class="checkmark"></span>
                <span>{{ column.text }}</span>
            </label>
            }
        </div>
    </div>
</ng-template>

<!-- dropdownShareShipmentInfoTemplate -->
<ng-template #dropdownShareShipmentInfoTemplate let-item="item">
    <div class="dropdown-container body">
        <button type="button" class="menu-button ripple-effect ripple-center ripple-inverse-color"
                (click)="onShareSelectedMonitoringInfo(item, false)">
            <i class="icon-list-ul"></i>Share shipment's entire info
        </button>
        <button type="button" class="menu-button ripple-effect ripple-center ripple-inverse-color"
                (click)="onShareSelectedMonitoringLocation(item, false)">
            <i class="icon-location-dot"></i>Share shipment's location
        </button>
    </div>
</ng-template>

<!-- dropdownCopyShipmentInfoTemplate -->
<ng-template #dropdownCopyShipmentInfoTemplate let-item="item">
    <div class="dropdown-container body">
        <button type="button" class="menu-button ripple-effect ripple-center ripple-inverse-color"
                (click)="onShareSelectedMonitoringInfo(item, true)">
            <i class="icon-list-ul"></i>Copy shipment's entire info
        </button>
        <button type="button" class="menu-button ripple-effect ripple-center ripple-inverse-color"
                (click)="onShareSelectedMonitoringLocation(item, true)">
            <i class="icon-location-dot"></i>Copy shipment's location
        </button>
    </div>
</ng-template>

<!-- popupInfoContentTemplate -->
<ng-template #popupInfoContentTemplate>
    <div class="popup-button-content-wrapper" (click)="onPopupContentClick($event)">
        <div class="popup-button-content page-scroll-padding">
            <div class="info-title"><span>Live Shipments</span><span>{{ monitoringItemsModel.monitoringItemsInfo.count }}</span></div>
            <div class="info-label">Arrival Late<i class="icon-hourglass-end"></i></div>
            <div class="info-value">{{ monitoringItemsModel.monitoringItemsInfo.lateCount }}</div>
            <div class="info-label">Arrival Early / On Time<i class="icon-hourglass-start"></i></div>
            <div class="info-value">{{ monitoringItemsModel.monitoringItemsInfo.earlyOrOnTimeCount }}</div>
            <div class="info-label">Arrival Unknown<i class="icon-hourglass-empty"></i></div>
            <div class="info-value">{{ monitoringItemsModel.monitoringItemsInfo.unknownCount }}</div>
            <div class="info-label">Routes</div><div class="info-value">{{ monitoringItemsModel.monitoringItemsInfo.routesCount }}</div>
            <div class="info-subtitle">Shipments with Events</div>
            <div class="info-label">Security<span class="event-types"><i class="icon-lock-open"></i></span></div>
            <div class="info-value">{{ monitoringItemsModel.monitoringItemsInfo.securityEventsCount }}</div>
            <div class="info-label">
                Quality
                <span class="event-types">
                    <i class="icon-temperature-full"></i><i class="icon-droplet"></i><i class="icon-explosion-solid"></i>
                </span>
            </div>
            <div class="info-value">{{ monitoringItemsModel.monitoringItemsInfo.qualityEventsCount }}</div>
        </div>
    </div>
</ng-template>

<!-- popupHelpContentTemplate -->
<ng-template #popupHelpContentTemplate>
    <div class="popup-button-content-wrapper" (click)="onPopupContentClick($event)">
        <div class="popup-button-content page-scroll-padding" overlayScrollbar>
            <div class="help-title">How to use the map?</div>
            <div class="help-value">
                <p>The map shows shipments and groups of shipments.</p>
                <p>Groups of shipments are shown as a circle with the number of shipments.</p>
            </div>
            <div class="help-label">Click</div>
            <div class="help-value">
                <p>Clicking a shipment on the map shows its details.</p>
                <p>Clicking a group of shipments either shows a list of their combined arrival status.</p>
            </div>
            <div class="help-label">Double Click</div>
            <div class="help-value">
                <p>Double-clicking a shipment or a group of shipments on the map zooms the map in on the clicked item. Zooming in may show some of the group's shipments separately.</p>
            </div>
            <div class="help-label">Icons</div>
            <div class="help-value icons-index">
                <i class="icon-warehouse"></i><span>Shipment in warehouse</span>
                <i class="icon-container"></i><span>Shipment on route</span>
                <i class="icon-waypoint"></i><span>Shipment at waypoint</span>
                <i class="icon-airport"></i><span>Shipment at airport</span>
                <i class="icon-train-station"></i><span>Shipment at train station</span>
                <i class="icon-destination-port"></i><span>Shipment at port</span>
                <i class="icon-hourglass-end"></i><span>Arrival Status Late</span>
                <i class="icon-hourglass-start"></i><span>Arrival Status Early / On Time</span>
                <i class="icon-hourglass-empty"></i><span>Arrival Status Unknown</span>
            </div>
        </div>
    </div>
</ng-template>

<!-- favoriteIconTemplate -->
<ng-template #favoriteIconTemplate>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 580 580">
        <path d="m 506.10056,304.4846 -193.1,199.7 c -12.64,13.07 -33.27,13.08 -45.91,0.0107 l -193.199997,-199.7 c -56.1,-58.0107 -52.99,-153.8107 9.15,-207.650693 54.259997,-46.98 137.359997,-38.44 187.259997,13.119993 l 19.7,20.27 19.7,-20.27 c 49.95,-51.559993 132.1,-60.099993 187.3,-13.119993 62.1,53.739993 65.2,149.639993 9.1,207.639993 z"></path>
    </svg>
</ng-template>

<!-- filterIconTemplate -->
<ng-template #filterIconTemplate>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" [class.active]="monitoringItemsModel.isMonitoringFiltersActive">
        <path d="M 490.30386,94.188357 316.45472,303.83385 v 140.51079 c 0,18.38318 -20.6905,28.91207 -35.55149,18.51503 l -75.34091,-52.7198 c -6.07436,-4.21909 -9.65305,-11.18812 -9.65305,-18.53386 V 303.83385 L 22.036581,94.188357 C 5.7064391,74.496129 20.000493,45.037835 45.886687,45.037835 H 466.4773 c 25.89843,0 39.36562,29.458294 23.82656,49.150522 z"></path>
    </svg>
</ng-template>

<!-- filterButtonIconTemplate -->
<ng-template #filterButtonIconTemplate>
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 576 512" [class.active]="monitoringItemsModel.isMonitoringFiltersActive">
        <path d="m 223.1,340.7 v -68.5 c 0,-7.369 -2.539,-14.51 -7.191,-20.22 L 50.77,48.04 h 410.4 l -85.57,105.1 c -7.77,9.545 -7.061,23.42 1.643,32.13 10.06,10.06 26.62,9.219 35.6,-1.812 l 88.61,-108.8 c 11.26,-13.46 13.7,-31.89 6.225,-47.92 C 500,10.23 483.8,0 465.3,0 H 46.65 C 28.21,0 11.97,10.23 4.283,26.71 -3.195,42.73 -0.7578,61.16 10.64,74.79 l 165.4,203.1 v 67.71 c 0,12.55 6.178,24.3 16.52,31.45 l 24.81,19.15 c 15.73,12.2 38.63,1 38.63,-19 0,-7.439 -3.449,-14.46 -9.34,-19 z" />
        <path d="M 432 224 C 352.5 224 288 288.5 288 368 C 288 447.5 352.47 512 432 512 C 511.53 512 576 447.53 576 368 C 576 288.47 511.5 224 432 224 z M 386.75781 306.7793 C 390.85131 306.78287 394.94531 308.3494 398.07031 311.4707 L 431.98047 345.38086 L 465.89062 311.4707 C 472.13263 305.2287 482.26195 305.21628 488.50195 311.45898 C 494.74195 317.70168 494.73419 327.82812 488.49219 334.07812 L 454.58203 367.98828 L 488.5 401.90039 C 494.742 408.14239 494.75177 418.26953 488.50977 424.51953 C 482.26977 430.76153 472.14062 430.75011 465.89062 424.50781 L 431.98047 390.59961 L 398.07031 424.50781 C 391.82831 430.74981 381.69922 430.76193 375.44922 424.51953 C 369.19922 418.27713 369.21894 408.14844 375.46094 401.89844 L 409.36914 367.99023 L 375.46094 334.08008 C 369.21894 327.83808 369.20917 317.70898 375.45117 311.45898 C 378.57217 308.33398 382.66431 306.77572 386.75781 306.7793 z " />
    </svg>
</ng-template>

<!-- columnEmptyValueTemplate -->
<ng-template #columnEmptyValueTemplate let-column="column" let-item="item">
    <span>{{ !column.propertyName || !item[column.propertyName] ? ('EMPTY_FIELD_VALUE' | globals) : item[column.propertyName] }}</span>
</ng-template>

<!-- liveFiltersTemplate -->
<ng-template #liveFiltersTemplate>
    <div #filtersContainer class="live-filters-container page-section page-section-padding" pointerEvents [useMouseWheel]="false"
         (swipe)="onFiltersSwipe($event)">
        <div class="live-filters-header-container">
            <div class="live-filters-header">
                <div class="filters-title select-none"><ng-container [ngTemplateOutlet]="filterIconTemplate"></ng-container>Filters</div>
                @if (('IS_MOBILE' | globals)) {
                <button class="back-button image-button ripple-effect ripple-center ripple-inverse-color"
                        (click)="collapseMonitoringItemsFilters = true">
                    <i class="icon-collapse-left"></i>
                </button>
                }
            </div>
            @if (!('IS_MOBILE' | globals)) {
            <div class="live-filters-header-buttons">
                <button type="button" [disabled]="!monitoringItemsModel.isMonitoringFiltersActive"
                        class="image-button clear-filters-button ripple-effect ripple-inverse-color" (click)="onClearMonitoringItemsFiltersButtonClick()">
                    <ng-template [ngTemplateOutlet]="filterButtonIconTemplate"></ng-template>
                </button>
                <button type="button" class="image-button search-shipments ripple-effect ripple-inverse-color" (click)="showSearchShipments = true">
                    <i class="icon-search"></i>
                </button>
                <div class="search-container" dropdown [(isExpanded)]="showSearchShipments" [autoClose]="false" [class.active]="showSearchShipments">
                    @if (showSearchShipments) {
                    <div class="dropdown-container search" @foldHorizontalAnimation>
                        <clearable-input class="search-filter" [(ngModel)]="monitoringSearchFilter" label="Search for Shipment" icon="icon-search"
                                         autofocus tabindex="0">
                        </clearable-input>
                        <button type="button" class="image-button collapse-button ripple-effect ripple-inverse-color" (click)="showSearchShipments = false">
                            <i class="icon-arrow-right"></i>
                        </button>
                    </div>
                    }
                    @if (showSearchShipments && monitoringSearchFilter !== null && monitoringSearchFilter.trim().length > 0) {
                    <div class="shipments-monitoring-filter-table-container animation-overflow" @foldAnimation>
                        <virtual-list #itemsList [items]="monitoringItemsModel.allMonitoringItems" [maxViewportItems]="5" tabindex="-1"
                                      id="shipments-monitoring-filter-table" [filterProperties]="tableExtrenalShipmentsMonitoringSearchProperties"
                                      [filter]="monitoringSearchFilter" emptyPlaceholder="No Results"
                                      [isSortable]="true" [isAutoColumnsWidths]="true" [isAutoWidthByRow]="true" [virtualListInfo]="externalShipmentsFilterTableListInfo"
                                      overlayScrollbar>
                            <div #header class="list-header">
                                @for (column of tableExternalShipmentsMonitoringSearchColumns; track column.columnType) {
                                <div class="col-list" [class]="column.classes ?? ''"
                                     [attr.property-name]="column.propertyName"
                                     [attr.sort-property-name]="column.sortPropertyName" [attr.fixed-width]="column.isFixedWidth ? 'true' : null">
                                    <span [innerHTML]="column.text"></span>
                                </div>
                                }
                            </div>
                            @for (item of itemsList.viewPortItems; track item.shipmentKey) {
                            <div #body class="list-row" (click)="onMonitoringItemsFilterTableItemClick(item)">
                                @for (column of tableExternalShipmentsMonitoringSearchColumns; track column.columnType) {
                                <div class="col-list" [class]="column.classes ?? ''">
                                    <ng-container [ngTemplateOutlet]="columnEmptyValueTemplate"
                                                  [ngTemplateOutletContext]="{ column: column, item: item }">
                                    </ng-container>
                                </div>
                                }
                            </div>
                            }
                        </virtual-list>
                    </div>
                    }
                </div>
            </div>
            }
        </div>
        @if (!('IS_MOBILE' | globals)) {
        <div class="shipments-type-container">
            <label class="radio-container ripple-effect ripple-inverse-color">
                <input type="radio" name="shipments-type" [value]="false" [(ngModel)]="monitoringItemsModel.showCompletedMonitoringItems"
                       (change)="onShipmentTypeChange()" />
                <span class="checked"></span>
                <span class="radio-text-container">
                    <i class="icon-signal-stream"></i>
                    <span class="radio-text">
                        <span class="radio-text-label">Shipments</span>
                        <span class="radio-text-value">{{ monitoringItemsModel.showCompletedMonitoringItems ? monitoringItemsModel.monitoringItemsSourceInfo.count : monitoringItemsModel.monitoringItemsInfo.count }}</span>
                        <span class="radio-text-label">Routes</span>
                        <span class="radio-text-value">{{ monitoringItemsModel.showCompletedMonitoringItems ? monitoringItemsModel.monitoringItemsSourceInfo.routesCount : monitoringItemsModel.monitoringItemsInfo.routesCount }}</span>
                    </span>
                </span>
                <span class="placeholder"><span class="palaceholder-background"></span><span>Live</span></span>
            </label>
            <label class="radio-container ripple-effect ripple-inverse-color busy-support" [class.busy]="monitoringItemsModel.isBackgroundFetchBusy">
                <input type="radio" name="shipments-type" [value]="true" [(ngModel)]="monitoringItemsModel.showCompletedMonitoringItems"
                       (change)="onShipmentTypeChange()" />
                <span class="checked"></span>
                <span class="radio-text-container">
                    <i class="icon-flag"></i>
                    <span class="radio-text">
                        <span class="radio-text-label">Shipments</span>
                        <span class="radio-text-value">{{ monitoringItemsModel.showCompletedMonitoringItems ? monitoringItemsModel.monitoringItemsInfo.count : monitoringItemsModel.completedMonitoringItemsSourceInfo.count }}</span>
                        <span class="radio-text-label">Routes</span>
                        <span class="radio-text-value">{{ monitoringItemsModel.showCompletedMonitoringItems ? monitoringItemsModel.monitoringItemsInfo.routesCount : monitoringItemsModel.completedMonitoringItemsSourceInfo.routesCount }}</span>
                    </span>
                </span>
                @if (!monitoringItemsModel.isBackgroundFetchBusy) {
                <span class="placeholder" @fadeInOutAnimation>
                    <span class="palaceholder-background"></span><span>Recently Completed</span>
                </span>
                }
                @if (monitoringItemsModel.isBackgroundFetchBusy) {
                <i class="icon-spinner" @fadeInOutAnimation></i>
                }
            </label>
        </div>
        }
        @if (monitoringItemsModel.lastSearchMonitoringFilters !== null) {
        <div class="form-group last-filters" @foldAnimation>
            <button type="button" class="link-button" (click)="onloadLastSeachFiltersClick()">Load last filters</button>
        </div>
        }
        <div class="live-filters-content-container page-section-scroll-padding" overlayScrollbar>
            @if (!('IS_MOBILE' | globals)) {
            <div class="form-group filter-events-group">
                <div class="filters-buttons-label">
                    <span class="select-none">Shipments with events</span>
                    @if (monitoringItemsModel.editMonitoringFilters.selectedShipmentMonitoringEvents.length > 0) {
                    <i class="icon-cancel control-icon press-icon ripple-effect ripple-center"
                       (mousedown)="onFilterEventSelectClear()" @fadeInOutAnimation>
                    </i>
                    }
                </div>
                <div class="events-buttons-container">
                    @for (filterElement of filterEventElements; track filterElement) {
                    <label class="radio-container events ripple-effect ripple-fill ripple-inverse-color"
                           [class]="filterElement.class" [class.disabled]="filterElement.value === 0"
                           [tooltipPopup]="filterElement.tooltip">
                        <input type="radio" (change)="onFilterEventSelectChange(filterElement.filterType)" name="events"
                               [checked]="monitoringItemsModel.editMonitoringFilters.selectedShipmentMonitoringEvents.includes(filterElement.filterType)">
                        <span class="container-background"></span>
                        <i [class]="filterElement.iconClassName"></i>
                        <span class="radio-text">{{ filterElement.value }}</span>
                    </label>
                    }
                </div>
            </div>
            }
            @if (!('IS_MOBILE' | globals)) {
            <div class="form-group">
                <div class="filters-buttons-label">
                    <span class=" select-none">Arrival Statuses <!--(Late, Early / On Time, Unknown)--></span>
                    @if (monitoringItemsModel.editMonitoringFilters.selectedShipmentMonitoringArrivalStatuses.length > 0) {
                    <i class="icon-cancel control-icon press-icon ripple-effect ripple-center"
                       (mousedown)="onFilterArrivalStatusSelectClear()" @fadeInOutAnimation>
                    </i>
                    }
                </div>
                <div class="arrival-statuses-buttons-container">
                    @for (filterElement of filterArrivalStatusElements; track filterElement) {
                    <label class="radio-container arrival-status ripple-effect ripple-fill ripple-inverse-color"
                           [class.disabled]="filterElement.value === 0" [class]="filterElement.class" [tooltipPopup]="filterElement.tooltip">
                        <input type="radio" (change)="onFilterArrivalStatusSelectChange(filterElement.filterType)" name="arrival-status"
                               [checked]="monitoringItemsModel.editMonitoringFilters.selectedShipmentMonitoringArrivalStatuses.includes(filterElement.filterType)">
                        <span class="container-background"></span>
                        <i [class]="filterElement.iconClassName"></i>
                        <span class="radio-text">{{ filterElement.value }}</span>
                    </label>
                    }
                </div>
            </div>
            }
            @if (loginModel.isAccountTypeAmazonUS) {
            <div class="form-group">
                <select-list placeholder="Trailers Numbers" [multiple]="true" emptyText="All"
                             [items]="monitoringItemsModel.filteredMonitoringDataLists.shipmentMonitoringTrailersNumbers"
                             [(ngModel)]="monitoringItemsModel.editMonitoringFilters.selectedShipmentMonitoringTrailersNumbers" (change)="onFilterFieldChange()">
                </select-list>
            </div>
            }
            @if (!loginModel.isAccountTypeAmazonUS) {
            <div class="form-group">
                <select-list placeholder="{{ containerIdColumnName }}s" [multiple]="true" emptyText="All"
                             [items]="monitoringItemsModel.filteredMonitoringDataLists.shipmentMonitoringCargosUnits"
                             [(ngModel)]="monitoringItemsModel.editMonitoringFilters.selectedShipmentMonitoringCargosUnits" (change)="onFilterFieldChange()">
                </select-list>
            </div>
            }
            <div class="form-group">
                <select-list placeholder="Origins" [multiple]="true" emptyText="All"
                             [items]="monitoringItemsModel.filteredMonitoringDataLists.shipmentMonitoringOriginsRoutes"
                             [(ngModel)]="monitoringItemsModel.editMonitoringFilters.selectedShipmentMonitoringOriginsRoutes" (change)="onFilterFieldChange()">
                </select-list>
            </div>
            <div class="form-group">
                <select-list placeholder="Destinations" [multiple]="true" emptyText="All"
                             [items]="monitoringItemsModel.filteredMonitoringDataLists.shipmentMonitoringDestinationsRoutes"
                             [(ngModel)]="monitoringItemsModel.editMonitoringFilters.selectedShipmentMonitoringDestinationsRoutes"
                             (change)="onFilterFieldChange()">
                </select-list>
            </div>
            <div class="form-group">
                <select-list placeholder="Favorites" [multiple]="true" emptyText="None"
                             [items]="monitoringItemsModel.filteredMonitoringDataLists.shipmentMonitoringFavorites"
                             bindLabel="name" bindValue="shipmentKey" [(ngModel)]="monitoringItemsModel.editMonitoringFilters.selectedShipmentMonitoringFavorites"
                             (change)="onFilterFieldChange()">
                </select-list>
            </div>
            @if (('IS_MOBILE' | globals)) {
            <div class="form-group">
                <select-list placeholder="Shipments with Events" [multiple]="true" emptyText="All"
                             [items]="monitoringItemsModel.filteredMonitoringDataLists.monitoringItemsEvents"
                             bindLabel="value" bindValue="id" [(ngModel)]="monitoringItemsModel.editMonitoringFilters.selectedShipmentMonitoringEvents"
                             (change)="onFilterFieldChange()">
                </select-list>
            </div>
            }
            <div class="form-group">
                <select-list placeholder="{{ deviceIdColumnName }}s" [multiple]="true" emptyText="All"
                             [items]="monitoringItemsModel.filteredMonitoringDataLists.shipmentMonitoringDevices"
                             [(ngModel)]="monitoringItemsModel.editMonitoringFilters.selectedShipmentMonitoringDevices" (change)="onFilterFieldChange()">
                </select-list>
            </div>
            <div class="form-group">
                <select-list placeholder="References" [multiple]="true" emptyText="All"
                             [items]="monitoringItemsModel.filteredMonitoringDataLists.shipmentMonitoringBookingReferences"
                             [(ngModel)]="monitoringItemsModel.editMonitoringFilters.selectedShipmentMonitoringBookingReferences"
                             (change)="onFilterFieldChange()">
                </select-list>
            </div>
            @if (('IS_MOBILE' | globals)) {
            <div class="form-group">
                <select-list placeholder="Arrival Statuses" [multiple]="true" emptyText="All"
                             [items]="monitoringItemsModel.filteredMonitoringDataLists.monitoringItemsArrivalStatuses"
                             bindLabel="value" bindValue="id" [(ngModel)]="monitoringItemsModel.editMonitoringFilters.selectedShipmentMonitoringArrivalStatuses"
                             (change)="onFilterFieldChange()">
                </select-list>
            </div>
            }
            <div class="form-group">
                <select-list placeholder="CG-IDs" [multiple]="true" emptyText="All" [items]="monitoringItemsModel.filteredMonitoringDataLists.shipmentMonitoringKeys"
                             [(ngModel)]="monitoringItemsModel.editMonitoringFilters.selectedShipmentMonitoringKeys" (change)="onFilterFieldChange()">
                </select-list>
            </div>
        </div>
        @if (('IS_MOBILE' | globals)) {
        <div class="form-group action-button-container">
            <button type="button" class="secondary-button submit-button ripple-effect ripple-fill" [disabled]="!monitoringItemsModel.isMonitoringFiltersActive"
                    (click)="onClearMonitoringItemsFiltersButtonClick()">
                Clear
            </button>
            <button type="button" class="primary-button submit-button ripple-effect ripple-fill ripple-inverse-color"
                    (click)="onApplyMonitoringItemsFiltersButtonClick()" [disabled]="!monitoringItemsModel.isMonitoringFiltersDirty">
                Apply
            </button>
        </div>
        }
    </div>
</ng-template>

<!-- shipmentFriendlyNameTemplate -->
<ng-template #shipmentFriendlyNameTemplate>
    <div class="modal-content">
        <div class="modal-body page-scroll-padding favorites">
            <form #formShipmentFriendlyName="ngForm"
                  (ngSubmit)="formShipmentFriendlyName.form.valid && !monitoringItemsModel.isShipmentMonitoringFavoriteFriendlyNameExists(monitoringItemsModel.shipmentFriendlyName) &&
                  onShipmentFriendlyNameSubmit()" [class.form-validated]="formShipmentFriendlyName.submitted" novalidate>
                <div class="modal-info">This shipment will be added to the Favorites filter on the filters pane.</div>
                <div class="form-group">
                    <clearable-input name="friendlyName" #friendlyName="ngModel" [(ngModel)]="monitoringItemsModel.shipmentFriendlyName" [maxLength]="60"
                                     requiredValidator [isRequired]="true" label="Shipment Friendly Name">
                    </clearable-input>
                    @if (formShipmentFriendlyName.submitted && friendlyName.invalid) {
                    <div class="invalid-feedback" @foldAnimation>This field is required</div>
                    }
                    @if (formShipmentFriendlyName.submitted && monitoringItemsModel.isShipmentMonitoringFavoriteFriendlyNameExists(monitoringItemsModel.shipmentFriendlyName)) {
                    <div class="invalid-feedback" @foldAnimation>This name already exists</div>
                    }
                </div>
                <div class="form-group action-button-container">
                    <button type="submit" class="image-button ripple-effect ripple-center ripple-inverse-color">
                        <i class="icon-check"></i>
                    </button>
                    <button type="button" class="image-button ripple-effect ripple-center ripple-inverse-color" (click)="onModalButtonCloseClick()">
                        <i class="icon-cancel"></i>
                    </button>
                </div>
            </form>
        </div>
    </div>
</ng-template>

<!-- shipmentRemoveFromFavoritesTemplate -->
<ng-template #shipmentRemoveFromFavoritesTemplate>
    <div class="modal-content">
        <div class="modal-body page-scroll-padding favorites">
            <div class="modal-info">Are you sure you want to remove this shipment from your favorites?</div>
            <div class="form-group action-button-container">
                <button type="button" class="image-button ripple-effect ripple-center ripple-inverse-color" (click)="onRemoveFromFavoriesButtonClick()">
                    <i class="icon-check"></i>
                </button>
                <button type="button" class="image-button ripple-effect ripple-center ripple-inverse-color" (click)="onModalButtonCloseClick()">
                    <i class="icon-cancel"></i>
                </button>
            </div>
        </div>
    </div>
</ng-template>

<!-- shipmentMoreInfoTemplate -->
<ng-template #shipmentMoreInfoTemplate let-item="data">
    <div class="modal-content marker-info-content">
        <button type="button" class="image-button ripple-effect ripple-center ripple-inverse-color" (click)="onModalButtonCloseClick()">
            <i class="icon-cancel"></i>
        </button>
        <div class="modal-body page-scroll-padding" overlayScrollbar>
            <div class="marker-info-item-subtitle">General Info</div>
            <div class="marker-info-item-label">Arrival Status</div>
            <div class="marker-info-item-value center">
                <i [class]="arrivalStatusesStyleProperties[item.arrivalStatusId].iconClassName"></i>
                <span>{{ item.delayString }}</span>
            </div>
            <div class="marker-info-item-label">{{ loginModel.isAccountTypeAmazonUS ? 'Trailer No.' : containerIdColumnName }}</div>
            <div class="marker-info-item-value">{{ loginModel.isAccountTypeAmazonUS ? item.trailerNumber : item.containerId }}</div>
            <div class="marker-info-item-label">{{ installDateColumnName }}<span class="small-units">{{ appSettingsService.appSettings.isUsingUTCTime ? '&nbsp;(UTC)' : '' }}</span></div>
            <div class="marker-info-item-value">{{ item.installDateFormatted }}</div>
            <div class="marker-info-item-label">Last Update<span class="small-units">{{ appSettingsService.appSettings.isUsingUTCTime ? '&nbsp;(UTC)' : '' }}</span></div>
            <div class="marker-info-item-value">{{ item.lastUpdateFormatted }}</div>
            <div class="marker-info-item-label">ETA<span class="small-units">{{ appSettingsService.appSettings.isUsingUTCTime ? '&nbsp;(UTC)' : '' }}</span></div>
            <div class="marker-info-item-value">{{ item.etaFormatted }}</div>
            <div class="marker-info-item-label">Reference</div>
            <div class="marker-info-item-value">{{ item.bookingReference }}</div>
            <div class="marker-info-item-label">{{ deviceIdColumnName }}</div>
            <div class="marker-info-item-value">{{ item.deviceDescription }}</div>
            <div class="marker-info-item-label">CG-ID</div>
            <div class="marker-info-item-value">{{ item.shipmentKey }}</div>
            <div class="marker-info-item-label">Coordinates</div>
            <div class="marker-info-item-value">{{ item.currentLat }}°, {{ item.currentLon }}°</div>
            <div class="marker-info-item-label">Events</div>
            <div class="marker-info-item-value center">
                @if (!item.humidityEvents && !item.impactEvents && !item.temperatureEvents && !item.securityEvents) {
                <span>
                    <span>{{ NO_EVENTS_VALUE }}</span>
                </span>
                }
                @if (item.temperatureEvents) {
                <span class="event-value">
                    <span>{{ item.temperatureEvents }}</span>
                    <i class="icon-temperature-full"></i>
                </span>
                }
                @if (item.humidityEvents) {
                <span class="event-value">
                    <span>{{ item.humidityEvents }}</span>
                    <i class="icon-droplet"></i>
                </span>
                }
                @if (item.impactEvents) {
                <span class="event-value">
                    <span>{{ item.impactEvents }}</span>
                    <i class="icon-explosion-solid"></i>
                </span>
                }
                @if (item.securityEvents) {
                <span class="event-value">
                    <span>{{ item.securityEvents }}</span>
                    <i class="icon-lock-open"></i>
                </span>
                }
            </div>
            <div class="marker-info-item-subtitle">Route Info</div>
            <div class="marker-info-item-label">Origin</div>
            <div class="marker-info-item-value">{{ item.originName }}</div>
            <div class="marker-info-item-label">Destination</div>
            <div class="marker-info-item-value">{{ item.destinationName }}</div>
            <div class="marker-info-item-label">Segment</div>
            <div class="marker-info-item-value">{{ item.currentLocDes }}</div>
            <div class="marker-info-item-label">Progress</div>
            <div class="marker-info-item-value route-progress">
                <div class="route-progress-container">
                    <div class="route-progress-value" [style.width]="item.progress + '%'"></div>
                </div>
                <div class="route-progress-text">{{ item.progress }}%</div>
            </div>
        </div>
    </div>
</ng-template>

<!-- infoWindowMarkerClusterTemplate -->
<ng-template #infoWindowMarkerClusterTemplate let-info>
    <div class="marker-info-container page-scroll-padding select-none">
        <div class="marker-info-content-container">
            <div class="marker-info-content select-none">
                <div class="marker-info-item-label">
                    <button type="button" class="image-button" (click)="onClusterInfoExpandButtonClick()"><i class="icon-expand"></i></button>
                    <span>Arrival Status</span>
                </div>
                <div class="marker-info-item-value center">
                    @if (info.lateShipmentsCount > 0) {
                    <div class="arrival-status-value">
                        <span>{{ info.lateShipmentsCount }}</span><i [class]="arrivalStatusesStyleProperties[MonitoringArrivalStatusType.Late].iconClassName"></i>
                    </div>
                    }
                    @if (info.onTimeShipmentsCount > 0) {
                    <div class="arrival-status-value">
                        <span>{{ info.onTimeShipmentsCount }}</span><i [class]="arrivalStatusesStyleProperties[MonitoringArrivalStatusType.OnTime].iconClassName"></i>
                    </div>
                    }
                    @if (info.unknownShipmentsCount > 0) {
                    <div class="arrival-status-value">
                        <span>{{ info.unknownShipmentsCount }}</span><i [class]="arrivalStatusesStyleProperties[MonitoringArrivalStatusType.Unknown].iconClassName"></i>
                    </div>
                    }
                </div>
            </div>
        </div>
    </div>
</ng-template>

<!-- infoWindowMarkerTemplate -->
<ng-template #infoWindowMarkerTemplate let-monitoringItems="info" let-isCollapsable="isCollapsable">
    <div class="marker-info-container page-scroll-padding" [class.multiple]="monitoringItems.length > 1">
        <virtual-list #infoItemsList [items]="monitoringItems" [isAutoSizeToContent]="monitoringItems.length > 1" [isMouseWheelScrollSnap]="false"
                      (itemsChange)="onInfoListItemsChange()">
            @for (monitoringItem of infoItemsList.viewPortItems; track monitoringItem.shipmentKey) {
            <div #body class="marker-info-content-container">
                <div class="marker-info-content-wrapper">
                    <div class="marker-info-content" [class.clickable]="monitoringItems.length > 1"
                         (click)="onMonitoringItemMapClick(monitoringItem.shipmentKey!)">
                        @if (monitoringItems.length > 1) {
                        <div class="marker-info-item-index">{{ monitoringItems.indexOf(monitoringItem) + 1 }} of {{ monitoringItems.length }}</div>
                        }
                        <div class="marker-info-item-label">Arrival Status</div><div class="marker-info-item-value center">
                            <i [class]="arrivalStatusesStyleProperties[monitoringItem.arrivalStatusId].iconClassName"></i>
                            <span>{{ monitoringItem.delayString }}</span>
                        </div>
                        <div class="marker-info-item-label">{{ loginModel.isAccountTypeAmazonUS ? 'Trailer No.' : containerIdColumnName }}</div>
                        <div class="marker-info-item-value">{{ loginModel.isAccountTypeAmazonUS ? monitoringItem.trailerNumber : monitoringItem.containerId }}</div>
                        <div class="marker-info-item-label">ETA<span class="small-units">{{ appSettingsService.appSettings.isUsingUTCTime ? '&nbsp;(UTC)' : '' }}</span></div><div class="marker-info-item-value">{{ monitoringItem.etaFormatted }}</div>
                        <div class="marker-info-item-label">{{ deviceIdColumnName }}</div><div class="marker-info-item-value">{{ monitoringItem.deviceDescription }}</div>
                        <div class="marker-info-item-label">Events</div><div class="marker-info-item-value center">
                            @if (!monitoringItem.humidityEvents && !monitoringItem.impactEvents && !monitoringItem.temperatureEvents && !monitoringItem.securityEvents) {
                            <span>
                                <span>{{ NO_EVENTS_VALUE }}</span>
                            </span>
                            }
                            @if (monitoringItem.temperatureEvents) {
                            <i class="icon-temperature-full"></i>
                            }
                            @if (monitoringItem.humidityEvents) {
                            <i class="icon-droplet"></i>
                            }
                            @if (monitoringItem.impactEvents) {
                            <i class="icon-explosion-solid"></i>
                            }
                            @if (monitoringItem.securityEvents) {
                            <i class="icon-lock-open"></i>
                            }
                        </div>
                    </div>
                    <div class="marker-info-item-buttons" (click)="onMonitoringItemMapClick(monitoringItem.shipmentKey!)">
                        <label class="checkbox-container favorites ripple-effect ripple-fill ripple-inverse-color"
                               (click)="onShipmentMonitoringFavoriteClick($event, monitoringItem, true)"
                               tooltipPopup="Favorites">
                            <input type="checkbox" [name]="'fovirites-' + monitoringItems.indexOf(monitoringItem)" class="favorites"
                                   [checked]="monitoringItem.isInFovarites">
                            <ng-container [ngTemplateOutlet]="favoriteIconTemplate"></ng-container>
                        </label>
                        <div class="dropdown" #showPopupCopyOptions="dropdown" dropdown [attachToBodyTemplateRef]="dropdownCopyShipmentInfoTemplate"
                             [attachToBodyTemplateOutletContext]="{ item: monitoringItem }">
                            <button type="button" class="image-button ripple-effect ripple-center ripple-inverse-color" tooltipPopup="Copy"
                                    (click)="onMonitoringShareCopyDropdownClick($event)">
                                <i class="icon-copy"></i>
                            </button>
                        </div>
                        <div class="dropdown" #showPopupShareOptions="dropdown" dropdown [attachToBodyTemplateRef]="dropdownShareShipmentInfoTemplate"
                             [attachToBodyTemplateOutletContext]="{ item: monitoringItem }">
                            <button type="button" class="image-button ripple-effect ripple-center ripple-inverse-color" tooltipPopup="Share"
                                    (click)="onMonitoringShareCopyDropdownClick($event)">
                                <i class="icon-share-nodes"></i>
                            </button>
                        </div>
                        <button type="button" class="image-button ripple-effect ripple-fill ripple-inverse-color" tooltipPopup="More Info"
                                (click)="onMarkerShipmentMoreInfoButtonClick($event, monitoringItem)">
                            <i class="icon-more-info"></i>
                        </button>
                        @if (!('IS_MOBILE' | globals)) {
                        <button type="button" class="image-button ripple-effect ripple-fill ripple-inverse-color"
                                tooltipPopup="Track" (click)="onMarkerShipmentTrackButtonClick(null, monitoringItem)">
                            <i class="icon-route"></i>
                        </button>
                        }
                    </div>
                </div>
            </div>
            }
        </virtual-list>
        @if (isCollapsable) {
        <button type="button" class="image-button collapse-button" (click)="onClusterInfoCollapseButtonClick()">
            <i class="icon-collapse"></i>
        </button>
        }
    </div>
</ng-template>
