import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { ControlCenterAlertModel } from './model/control-center-alert.model';
import { ModalMessageService } from '../../controls/modal-message/services/modal-message.service';
import { DisplayControlCenterAlertData } from './model/control-center-alert-model.class';
import { ManagerItemBaseComponent } from '../../base/components/manager-item-base.component';
import { RoutingHistoryService } from '../../services/routing-history.service';
import { fadeInOutAnimation, shortFadeInOutAnimation } from '../../animations/fade.animation';
import { foldAnimation, foldLongAnimation } from '../../animations/fold.animation';
import { TextAreaAutosizeDirective } from '../../directives/textarea-autoresize.directive';
import { TextChractersCounterComponent } from '../../base/components/text-chracters-counter.component';
import { RequiredValidatorDirective } from '../../validators/required.validator';
import { ClearableInputComponent } from '../../controls/clearable-input/clearable-input.component';
import { SelectListComponent } from '../../controls/select-list/select-list.component';
import { OverlayScrollbarDirective } from '../../directives/overlay-scrollbar/overlay-scrollbar.directive';
import { NgClass, NgTemplateOutlet } from '@angular/common';
import { FormIgnoreEnterDirective } from '../../directives/form-ignore-enter.directive';
import { FormsModule } from '@angular/forms';

@Component({
    selector: 'control-center-alert',
    templateUrl: './control-center-alert.component.html',
    styleUrls: ['../../base/styles/manager-item-base.css', './control-center-alert.component.css'],
    animations: [shortFadeInOutAnimation, fadeInOutAnimation, foldLongAnimation, foldAnimation],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [FormsModule, FormIgnoreEnterDirective, NgClass, OverlayScrollbarDirective, SelectListComponent, NgTemplateOutlet, ClearableInputComponent,
        RequiredValidatorDirective, TextChractersCounterComponent, TextAreaAutosizeDirective]
})
export class ControlCenterAlertComponent extends ManagerItemBaseComponent<DisplayControlCenterAlertData>
{
    // #region Properties

    public override get managerItemModel(): ControlCenterAlertModel
    {
        return this._managerItemModel as ControlCenterAlertModel;
    }

    // #endregion

    // #region Constructor

    constructor(_managerItemModel: ControlCenterAlertModel, _changeDetectorRef: ChangeDetectorRef, _routingHistoryService: RoutingHistoryService,
        _modalMessageService: ModalMessageService)
    {
        super(_managerItemModel, _changeDetectorRef, _routingHistoryService, _modalMessageService);
    }

    // #endregion

    // #region Protected Methods

    protected override submitManagerItem(isDelete: boolean = false): void
    {
        this._statusMessage = '<b>Updating alerts.</b> Please wait...';

        super.submitManagerItem(isDelete);
    }

    // #endregion
}
