import { Component, Input } from '@angular/core';
import { LoginModel } from '../../user/login/model/login.model';
import { ModalBodyAlignType, ModalMessageService } from '../modal-message/services/modal-message.service';
import { Constants, RouteType } from '../../utils/globals';
import { foldAnimation, foldHorizontalAnimation } from '../../animations/fold.animation';
import { DropdownPosition } from '../../base/components/dropdown-base.component';
import { Router } from '@angular/router';
import { NgTemplateOutlet } from '@angular/common';
import { DropdownDirective } from '../../directives/dropdown.directive';

@Component({
    selector: 'user-menu',
    templateUrl: './user-menu.component.html',
    styleUrls: ['./user-menu.component.css'],
    animations: [foldHorizontalAnimation, foldAnimation],
    standalone: true,
    imports: [DropdownDirective, NgTemplateOutlet]
})
export class UserMenuComponent
{
    // #region Properties

    public showUserMenu: boolean = false;

    public get loginModel(): LoginModel
    {
        return this._loginModel;
    }

    public get DropdownPosition()
    {
        return DropdownPosition;
    }

    // #endregion

    // #region Inputs

    @Input() public dropdownPosition: DropdownPosition = DropdownPosition.Bottom;

    // #endregion

    // #region Constructor

    constructor(private _modalMessageService: ModalMessageService, private _loginModel: LoginModel, private _router: Router)
    {
    }

    // #endregion

    // #region Events Handlers

    public onLogoutButtonClick(): void
    {
        this._loginModel.logout();
    }

    public onChangePasswordButtonClick(): void
    {
        this._router.navigate([`/${RouteType.ChangePassword}`]);
    }

    public onSupportButtonClick(): void
    {
        this._modalMessageService.show(
            {
                title: Constants.APP_TITLE,
                message: 'If you need assistance please contact:<br><a href="mailto:support@contguard.com">support@contguard.com</a><br>24/7 control center: +1-347-919-9166<br><br>Standard working hours are 08:00-17:00 CET, Sun-Thu.<br>We will do our best effort to handle your request as needed.',
                modlaBodyAlign: ModalBodyAlignType.Left
            });
    }

    // #endregion
}
