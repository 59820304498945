import { ICGILocation, ICGIMarkerLocation } from "../../base/classes/cgi-marker";
import { ManagerItemStatus, MonitoringSegmentType } from "../../base/models/manager-base-model.class";
import { MonitoringBaseInfo, MonitoringDetailedEventType } from "../../base/models/monitoring-items-base-model.class";
import { DisplayShipmentMonitoring } from "../../shipments-monitoring/shipment-monitoring/model/shipment-monitoring-model.class";
import { IIdValue, IRange } from "../../utils/globals";

export enum EventDurationType { Minutes, Hours, Days, Weeks, Months }

export enum EventsMonitoringColumnType
{
    ContainerId, RouteDescription, Type, Started, Duration, Location, ShipmentKey, Copy, Share, Track
}

export class EventDurationFilterData
{
    public durationType: EventDurationType = EventDurationType.Days;
    public duration: number | null = null;
}

export class EventsMonitoringFilters
{
    public selectedShipmentMonitoringKeys: number[] = [];
    public selectedShipmentMonitoringCargosUnits: string[] = [];
    public selectedShipmentMonitoringTrailersNumbers: string[] = [];
    public selectedShipmentMonitoringDevices: number[] = [];
    public selectedShipmentMonitoringBookingReferences: number[] = [];
    public selectedShipmentMonitoringOriginsRoutes: string[] = [];
    public selectedShipmentMonitoringDestinationsRoutes: string[] = [];
    public selectedShipmentMonitoringSegmentsTypes: MonitoringSegmentType[] = [];
    public selectedShipmentMonitoringEventTypes: MonitoringDetailedEventType[] = [];
    public selectedEventMonitoringDatesRange: IRange<(Date | null)> = { from: null, to: null };
    public selectedEventMonitoringDurationData: EventDurationFilterData = new EventDurationFilterData();
}

export class EventsMonitoringDataLists
{
    public shipmentMonitoringKeys: number[] = [];
    public shipmentMonitoringCargosUnits: string[] = [];
    public shipmentMonitoringTrailersNumbers: string[] = [];
    public shipmentMonitoringDevices: number[] = [];
    public shipmentMonitoringBookingReferences: number[] = [];
    public shipmentMonitoringOriginsRoutes: string[] = [];
    public shipmentMonitoringDestinationsRoutes: string[] = [];
    public monitoringItemsSegments: IIdValue[] = [];
    public monitoringItemsEvents: IIdValue[] = [];
    public monitoringItemsEventDatesRange: IRange<(Date | null)> = { from: null, to: null };
    public monitoringItemsEventDurationTypes: IIdValue[] = [];
}

export class EventsMonitoringInfo extends MonitoringBaseInfo
{
    public shipmentsCount: number = 0;
}

export class EventMonitoring
{
    public id: string | null = null;
    public shipmentKey: number | null = null;
    public eventId: MonitoringDetailedEventType = MonitoringDetailedEventType.None;
    public fromDate: Date | null = null;
    public toDate: Date | null = null;
    public eventDesc: string | null = null;
    public firstLevelSegmentId: MonitoringSegmentType = MonitoringSegmentType.Origin;
    public longitude: number = 0;
    public latitude: number = 0;
    public demoFlag: number | null = null;
    public minTemperature: number | null = null;
    public maxTemperature: number | null = null;
    public minHumidity: number | null = null;
    public maxHumidity: number | null = null;
    public maxLight: number | null = null;
    public perimeterType: string | null = null;
    public eventLocation: string | null = null;
    public statusId: ManagerItemStatus = ManagerItemStatus.Live;
}

export class DisplayEventMonitoring extends EventMonitoring implements ICGIMarkerLocation
{
    public containerId: string | null = null;
    public routeDescription: string | null = null;
    public fromDateFormatted: string | null = null;
    public duration: number | null = null;
    public durationFormatted: string | null = null;
    public eventType: MonitoringDetailedEventType = MonitoringDetailedEventType.None;
    public shipment: DisplayShipmentMonitoring = new DisplayShipmentMonitoring();
    public perimeterIconClass: string = '';
    public units: string = '';
    public valueTitle: string = '';
    public value: number | null = null;
    public minThreshold: number | null = null;
    public maxThreshold: number | null = null;
    public timeBasedMinThreshold: number | null = null;
    public timeBasedMaxThreshold: number | null = null;
    public location: ICGILocation = { lat: 0, lng: 0 };
    public infoWindowExtraRows: number[] = [];
}