export enum AnimationDurationType {Short, Normal, Long }
export enum AnimationState { Show, Hide, Showing, Hiding }

export class AnimationsConstants
{
    public static readonly SMOOTH_SCROLL_DURATION: number = 500;
    public static readonly ROTATE_ANIMATION_DURATION: number = 300;

    public static readonly ANIMATION_LONG_DURATION: number = 400;
    public static readonly ANIMATION_DURATION: number = 250;
    public static readonly ANIMATION_SHORT_DURATION: number = 150;
    public static readonly ANIMATION_ZOOM_SLIDE_DURATION: number = 800;

    public static readonly ANIMATION_ZOOM_SLIDE_TIMING: string = `${AnimationsConstants.ANIMATION_ZOOM_SLIDE_DURATION}ms  ease-in-out`;
    public static readonly ANIMATION_LONG_TIMING: string = `${AnimationsConstants.ANIMATION_LONG_DURATION}ms  ease-in-out`;
    public static readonly ANIMATION_TIMING: string = `${AnimationsConstants.ANIMATION_DURATION}ms  ease-in-out`;
    public static readonly ANIMATION_SHORT_TIMING: string = `${AnimationsConstants.ANIMATION_SHORT_DURATION}ms  ease-in-out`;

    public static readonly SLIDE_LEFT_STYLE: string = 'translateX(-100%)';
    public static readonly SLIDE_RIGHT_STYLE: string = 'translateX(100%)';
    public static readonly SLIDE_DOWN_STYLE: string = 'translateY(100%)';
    public static readonly SLIDE_IN_STYLE: string = 'none';
    public static readonly SLIDE_IN_TRANSFORM_STYLE: string = 'none';
    public static readonly SLIDE_ZOOM_OUT_TRANSFORM_STYLE: string = 'scale(0)';

    public static getAnimationTiming(animationDurationType: AnimationDurationType): string
    {
        switch (animationDurationType)
        {
            case AnimationDurationType.Long:
                {
                    return AnimationsConstants.ANIMATION_LONG_TIMING;
                }

            case AnimationDurationType.Normal:
                {
                    return AnimationsConstants.ANIMATION_TIMING;
                }

            case AnimationDurationType.Short:
                {
                    return AnimationsConstants.ANIMATION_SHORT_TIMING;
                }
        }
    }
}