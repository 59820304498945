import
    {
        ChangeDetectionStrategy, Component, ElementRef, ViewChild, Input, Output, EventEmitter, forwardRef, AfterViewInit,
        OnDestroy, Renderer2, NgZone, ChangeDetectorRef
    } from "@angular/core";
import { NG_VALUE_ACCESSOR, ControlValueAccessor } from '@angular/forms';

@Component({
    selector: 'slider',
    templateUrl: './slider.component.html',
    styleUrls: ['./slider.component.css'],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => SliderComponent), multi: true }
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true
})
export class SliderComponent implements OnDestroy, AfterViewInit, ControlValueAccessor
{
    // #region Private Members

    private _minValue: number = 0;
    private _maxValue: number = 100;

    private _innerValue: number | null = null;

    private _changed = new Array<(value: number | null) => void>();
    private _touched = new Array<() => void>();

    private _switchClientXPanStartValue: number = 0;
    private _switchPanStartValue: number | null = null;
    private _switchPanMaxPosition: number = 0;

    private _disposeWindowMouseUpHandler: (() => void) | null = null;
    private _disposeWindowMouseMoveHandler: (() => void) | null = null;

    @ViewChild('switch', { read: ElementRef, static: false }) private _switchElementRef: ElementRef<HTMLElement> | undefined = undefined;
    @ViewChild('selection', { read: ElementRef, static: false }) private _selectionElementRef: ElementRef<HTMLElement> | undefined = undefined;

    // #endregion

    // #region Properties

    public get value(): number | null
    {
        return this._innerValue;
    }

    public set value(value: number | null)
    {
        if (this._innerValue !== value)
        {
            this._innerValue = value;
            this._changed.forEach(f => f(value));
            this._touched.forEach(f => f());
            this.change.emit(value);
        }
    }

    // #endregion

    // #region Inputs

    @Input() public label: string = '';

    @Input()
    public get minValue(): number
    {
        return this._minValue;
    }

    public set minValue(minValue: number)
    {
        this._minValue = minValue;
        this.updateSliderSwitch();
    }

    @Input()
    public get maxValue(): number
    {
        return this._maxValue;
    }

    public set maxValue(maxValue: number)
    {
        this._maxValue = maxValue;
        this.updateSliderSwitch();
    }

    // #endregion

    // #region Events

    @Output() change: EventEmitter<number | null> = new EventEmitter<number | null>();

    // #endregion

    // #region Constructors

    constructor(private _changeDetectorRef: ChangeDetectorRef, private _ngZone: NgZone, private _renderer: Renderer2)
    {
    }

    // #endregion

    // #region Event Handlers

    public ngAfterViewInit(): void
    {
        this.initialize();
    }

    public ngOnDestroy(): void
    {
        this.clearMouseEvents();
    }

    public onSwitchMouseDown(event: MouseEvent): void
    {
        if (this._switchElementRef !== undefined)
        {
            this._switchClientXPanStartValue = event.clientX;
            this._switchPanStartValue = parseInt(this._switchElementRef.nativeElement.style.left);

            this._ngZone.runOutsideAngular(() =>
            {
                this._disposeWindowMouseUpHandler = this._renderer.listen(window, 'mouseup', this.onWindowMouseUp.bind(this));
                this._disposeWindowMouseMoveHandler = this._renderer.listen(window, 'mousemove', this.onWindowMouseMove.bind(this));
            });
        }
    }

    public onWindowMouseUp(): void
    {
        this._switchPanStartValue = null;
        this.clearMouseEvents();
    }

    public onWindowMouseMove(event: MouseEvent): void
    {
        if (this._switchPanStartValue !== null && this._selectionElementRef !== undefined && this._switchElementRef !== undefined &&
            this._switchElementRef.nativeElement.parentElement !== null)
        {
            this.updateSwitchPosition(Math.max(0, Math.min(this._switchPanMaxPosition, this._switchPanStartValue + event.clientX - this._switchClientXPanStartValue)));

            this.value = Math.ceil(parseInt(this._switchElementRef.nativeElement.style.left) * this.maxValue /
                (this._switchElementRef.nativeElement.parentElement.clientWidth - this._switchElementRef.nativeElement.clientWidth +
                    this._selectionElementRef.nativeElement.getBoundingClientRect().height / 2));

            this._ngZone.run(() => this._changeDetectorRef.markForCheck());
        }
    }

    // #endregion

    // #region Public Methods

    public touch(): void
    {
        this._touched.forEach(f => f());
    }

    public writeValue(value: number | null): void
    {
        const isInitialized = value === null && this.value !== null;

        this.value = value;

        if (isInitialized)
        {
            this.initialize();
        }

        if (this.value !== null)
        {
            this.updateSliderSwitch();
        }
    }

    public registerOnChange(fn: (value: number | null) => void): void
    {
        this._changed.push(fn);
    }

    public registerOnTouched(fn: () => void): void
    {
        this._touched.push(fn);
    }

    // #endregion

    // #region Private Methods

    private clearMouseEvents(): void
    {
        if (this._disposeWindowMouseMoveHandler !== null)
        {
            this._disposeWindowMouseMoveHandler();
            this._disposeWindowMouseMoveHandler = null;
        }

        if (this._disposeWindowMouseUpHandler !== null)
        {
            this._disposeWindowMouseUpHandler();
            this._disposeWindowMouseUpHandler = null;
        }
    }

    private initialize(): void
    {
        if (this._switchElementRef !== undefined && this._selectionElementRef !== undefined)
        {
            this._switchElementRef.nativeElement.style.left = '0px';
            this._selectionElementRef.nativeElement.style.width = '0px';

            if (this._switchElementRef.nativeElement.parentElement !== null)
            {
                this._switchPanMaxPosition = this._switchElementRef.nativeElement.parentElement.clientWidth - this._switchElementRef.nativeElement.clientWidth +
                    this._selectionElementRef.nativeElement.getBoundingClientRect().height / 2;
            }
        }
    }

    private updateSliderSwitch(): void
    {
        if (this.value === null)
        {
            return;
        }

        let position: number = 0;

        if (this.value !== null && this._selectionElementRef !== undefined && this._switchElementRef !== undefined &&
            this._switchElementRef.nativeElement.parentElement !== null)
        {
            position = this.value / this.maxValue *
                (this._switchElementRef.nativeElement.parentElement.clientWidth - this._switchElementRef.nativeElement.clientWidth +
                this._selectionElementRef.nativeElement.getBoundingClientRect().height / 2);

            this.updateSwitchPosition(position);
        }
    }

    private updateSwitchPosition(position: number): void
    {
        if (this._switchElementRef !== undefined && this._selectionElementRef !== undefined)
        {
            this._switchElementRef.nativeElement.style.left = `${position}px`;
            this._selectionElementRef.nativeElement.style.width = `${position}px`;
        }
    }

    // #endregion
}
