import { CGIMarkerShipmentsIconFactory } from "./cgi-marker-shipments-icon-factory";
import { CGIClusterBaseRenderer } from "../../../controls/google-map/utils/cgi-cluster-base-renderer";
import { CGICluster } from "../../../controls/google-map/utils/cgi-cluster";
import { ICGIMarker } from "../../../base/classes/cgi-marker";
import { MonitoringArrivalStatusType } from "../../../base/models/manager-base-model.class";
import { DisplayShipmentMonitoring } from "../../shipment-monitoring/model/shipment-monitoring-model.class";

export class CGIShipmentsClusterRenderer extends CGIClusterBaseRenderer
{
    // #region Constants

    private readonly SHIPMENT_ARRIVAL_STATUSES_TYPES_COUNT: number =
        (Object.values(MonitoringArrivalStatusType).filter((value: any) => typeof (value) === 'number') as number[]).length;

    // #endregion

    // #region Private Members

    private _cgiMarkerShipmentsIconFactory: CGIMarkerShipmentsIconFactory = new CGIMarkerShipmentsIconFactory();

    // #endregion

    // #region Renderer Interface

    public override render(cluster: CGICluster, map: google.maps.Map): google.maps.marker.AdvancedMarkerElement
    {
        if (cluster.markers !== undefined && cluster.markers.length === 1)
        {
            return cluster.marker!;
        }

        const arrivalStatusesOccurrences: number[] = Array(this.SHIPMENT_ARRIVAL_STATUSES_TYPES_COUNT).fill(0);

        if (cluster.markers !== undefined && cluster.markers.length > 0)
        {
            for (const marker of cluster.markers)
            {
                const displayShipmentMonitoring: DisplayShipmentMonitoring = marker instanceof google.maps.marker.AdvancedMarkerElement ?
                    (marker as ICGIMarker<DisplayShipmentMonitoring>).data : marker as DisplayShipmentMonitoring;

                arrivalStatusesOccurrences[displayShipmentMonitoring.arrivalStatusId] = arrivalStatusesOccurrences[displayShipmentMonitoring.arrivalStatusId] + 1;
            }
        }

        return this.createClusterMarkerElement(map, cluster, arrivalStatusesOccurrences);
    }

    // #endregion

    // #region Protected Methods

    protected override createClusterMarkerContentElement(cluster: CGICluster, arrivalStatusesOccurrences: number[], isClusterBoundsAroundSameLocation: boolean): Element
    {
        return isClusterBoundsAroundSameLocation ?
            this._cgiMarkerShipmentsIconFactory.createCGIClusterPinMarkerArrivalStatusElement(arrivalStatusesOccurrences, cluster.markers.length) :
            this._cgiMarkerShipmentsIconFactory.createCGIClusterMarkerArrivalStatusElement(arrivalStatusesOccurrences, cluster.markers.length);
    }

    // #endregion
}