export class BaseModel
{
    // #region Protected Members

    protected _isBusy: boolean = false;
    protected _isBackgroundFetchBusy: boolean = false;
    protected _isInitialized: boolean = false;

    // #endregion

    // #region Properties

    public get isInitialized(): boolean
    {
        return this._isInitialized;
    }

    public get isBusy(): boolean
    {
        return this._isBusy;
    }

    public get isBackgroundFetchBusy(): boolean
    {
        return this._isBackgroundFetchBusy;
    }

    // #endregion

    // #region Public Methods

    public clear(): void
    {
        this._isBusy = false;
        this._isBackgroundFetchBusy = false;
        this._isInitialized = true;
    }

    // #endregion
}