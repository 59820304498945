import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { fadeInOutAnimation } from '../../animations/fade.animation';
import { RoutingHistoryService } from '../../services/routing-history.service';
import { ShipmentComponent } from '../shipment/shipment.component';
import { ModalMessageService } from '../../controls/modal-message/services/modal-message.service';
import { SavableBaseComponent } from '../../base/components/savable-base.component';
import { LoaderComponent } from '../../controls/loader/loader.component';
import { TopBarComponent } from '../../controls/top-bar/top-bar.component';

@Component({
    selector: 'shipment-details',
    templateUrl: './shipment-details.component.html',
    styleUrls: ['./shipment-details.component.css'],
    animations: [fadeInOutAnimation],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [TopBarComponent, ShipmentComponent, LoaderComponent]
})
export class ShipmentDetailsComponent extends SavableBaseComponent
{
    // #region Private Methods

    @ViewChild('shipment', { read: ShipmentComponent, static: false }) private _shipmentComponent: ShipmentComponent | undefined = undefined;

    // #endregion

    // #region Constructor

    constructor(private _routingHistoryService: RoutingHistoryService, private _changeDetectorRef: ChangeDetectorRef,
        _modalMessageService: ModalMessageService)
    {
        super(_modalMessageService);
    }

    // #endregion

    // #region Properties

    public override get isDirty(): boolean
    {
        return this._shipmentComponent === undefined ? false : this._shipmentComponent.isDirty;
    }

    // #endregion

    // #region Event Handlers

    public onBackButtonClick(): void
    {
        this._routingHistoryService.navigateBack();
    }

    public onSubmitingShipment(): void
    {
        this._changeDetectorRef.detectChanges();
    }

    // #endregion
}
