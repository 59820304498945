import { enableProdMode, LOCALE_ID, APP_INITIALIZER, importProvidersFrom, provideZoneChangeDetection } from '@angular/core';
import { environment } from './environments/environment';
import { AppComponent } from './app/app.component';
import { PowerBIEmbedModule } from 'powerbi-client-angular';
import { initializeRoutes, routes } from './app/app-routes';
import { provideAnimations } from '@angular/platform-browser/animations';
import { BrowserModule, bootstrapApplication } from '@angular/platform-browser';
import { LoginModel } from './app/user/login/model/login.model';
import { WebApiHttpInterceptor } from './app/services/web-api.http.interceptor';
import { provideHttpClient, withInterceptorsFromDi, HTTP_INTERCEPTORS } from '@angular/common/http';
import { PreloadAllModules, Router, provideRouter, withComponentInputBinding, withInMemoryScrolling, withPreloading } from '@angular/router';

if (environment.production)
{
    enableProdMode();
}

bootstrapApplication(AppComponent, {
    providers: [
        provideZoneChangeDetection({ eventCoalescing: true }),
        {
            provide: APP_INITIALIZER,
            useFactory: initializeRoutes,
            deps: [Router, LoginModel],
            multi: true
        },
        importProvidersFrom(BrowserModule, PowerBIEmbedModule),
        {
            provide: LOCALE_ID,
            useValue: 'en-US'
        },
        provideHttpClient(withInterceptorsFromDi()),
        {
            provide: HTTP_INTERCEPTORS,
            useClass: WebApiHttpInterceptor,
            multi: true
        },
        provideAnimations(),
        provideRouter(routes, withPreloading(PreloadAllModules), withComponentInputBinding(), withInMemoryScrolling())
    ]
}).catch(err => console.error(err));
