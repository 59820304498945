import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, forwardRef, Input, ViewChild } from '@angular/core';
import { AnimationBuilder, AnimationPlayer, AnimationFactory, animate, style } from '@angular/animations';
import { default as moment } from "moment";
import { fadeInOutAnimation, fadeZoomInOutAnimation } from '../../animations/fade.animation';
import { DropdownState } from '../../base/components/dropdown-base.component';
import { TimePickerBaseComponent } from '../time-picker/time-picker-base/time-picker-base.component';
import { NG_VALUE_ACCESSOR, FormsModule } from '@angular/forms';
import { DateTimeFormatType, Utils } from '../../utils/utils';
import { SwipeDirection, PointerEventsDirective } from '../../directives/pointer-events.directive';
import { AnimationsConstants } from '../../animations/constant';
import { Constants } from '../../utils/globals';
import { AppSettingsService } from '../../services/app-settings.service';
import { GlobalsPipe } from '../../pipes/globals.pipe';
import { TimePickerViewComponent } from '../time-picker/time-picker-view/time-picker-view.component';
import { TabsControlComponent, TabItemDirective, TabItemTitleTemplateDirective, TabContentTemplateDirective } from '../tabs-control/tabs-control.component';
import { NgTemplateOutlet, NgClass } from '@angular/common';

export enum CalendarViewMode { Days, Months, Years }

@Component({
    selector: 'datetime-picker',
    templateUrl: './datetime-picker.component.html',
    styleUrls: ['../../base/styles/picker.css', './datetime-picker.component.css'],
    animations: [fadeZoomInOutAnimation, fadeInOutAnimation],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => DateTimePickerComponent), multi: true }
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgTemplateOutlet, TabsControlComponent, TabItemDirective, TabItemTitleTemplateDirective, TabContentTemplateDirective, TimePickerViewComponent,
        FormsModule, PointerEventsDirective, NgClass, GlobalsPipe]
})
export class DateTimePickerComponent extends TimePickerBaseComponent
{
    // #region Private Constants

    private readonly YEARS_RANGE: number = 20;
    private readonly MONTHS_PER_PAGE: number = 16;
    private readonly DAYS_PER_PAGE: number = 42;

    // #endregion

    // #region Private Memers

    private _isAlternateDaysView: boolean = false;

    private _daysName: string[] = [];

    private _calendarViewMode: CalendarViewMode = CalendarViewMode.Days;

    private _viewDates: moment.Moment[] = [];

    private _calendarPageAnimationPlayer: AnimationPlayer | null = null;

    @ViewChild('calendarWeeks', { read: ElementRef, static: false })
    private _calendarWeeksElementRef: ElementRef<HTMLInputElement> | undefined = undefined;

    @ViewChild('calendarMonths', { read: ElementRef, static: false })
    private _calendarMonthsElementRef: ElementRef<HTMLInputElement> | undefined = undefined;

    @ViewChild('calendarYears', { read: ElementRef, static: false })
    private _calendarYearsElementRef: ElementRef<HTMLInputElement> | undefined = undefined;

    // #endregion

    // #region Proterties

    public dateTimeTabIndex: number = 0;

    public override set value(date: Date | null)
    {
        if (!this.withTime && date !== null)
        {
            Utils.clearDateOnlyTimeValue(date);
        }

        super.value = date;
    }

    public get formattedValue(): string | null
    {
        return this._innerValue !== null && this._innerValue !== undefined ?
            (this.withTime ? Utils.getFormattedDateTime(this._innerValue, DateTimeFormatType.DateTime) :
                Utils.getFormattedDateTime(this._innerValue, DateTimeFormatType.Date)) : null;
    }

    public get isAlternateDaysView(): boolean
    {
        return this._isAlternateDaysView;
    }

    public get daysName(): string[]
    {
        return this._daysName;
    }

    public get CalendarViewMode()
    {
        return CalendarViewMode;
    }

    public get calendarViewMode(): CalendarViewMode
    {
        return this._calendarViewMode;
    }

    public get formattedDateValue(): string
    {
        const date: Date = this._innerEditValue ?? new Date();
        return `<small>${Utils.getFormattedDateTime(date, DateTimeFormatType.DateYear)}</small>\
            ${Utils.getFormattedDateTime(date, this.time24Hours || !this.withTime ? DateTimeFormatType.DateDayMonth : DateTimeFormatType.DateDayMonthShort)}th`;
    }

    public get calendarViewModeDisplay(): string | undefined
    {
        let calendarViewModeDisplayText: string | undefined = '';

        switch (this._calendarViewMode)
        {
            case CalendarViewMode.Years:
                {
                    calendarViewModeDisplayText = `${Utils.getMoment(this._viewDates[this.YEARS_RANGE]).format('YYYY')} \
                                                  -${Utils.getMoment(this._viewDates[this.YEARS_RANGE * 2 - 1]).format('YYYY')}`;
                }
                break;

            case CalendarViewMode.Months:
                {
                    calendarViewModeDisplayText = this._currentMoment.format('YYYY');
                }
                break;

            case CalendarViewMode.Days:
                {
                    calendarViewModeDisplayText = this._currentMoment.format('MMMM YYYY');
                }
                break;
        }

        return calendarViewModeDisplayText;
    }

    public get viewDates(): moment.Moment[]
    {
        return this._viewDates;
    }

    // #endregion

    // #region Inputs

    @Input() public withTime: boolean = false;
    @Input() public minDate: Date | null = null;
    @Input() public maxDate: Date | null = null;
    @Input() public clearable: boolean = true;

    // #endregion

    // #region Constractor

    constructor(_elementRef: ElementRef<HTMLElement>, _changeDetectorRef: ChangeDetectorRef, _animationBuilder: AnimationBuilder,
        _appSettingsService: AppSettingsService)
    {
        super(_elementRef, _changeDetectorRef, _animationBuilder, _appSettingsService);

        this._daysName = moment.weekdaysMin();
    }

    // #endregion

    // #region Event Handlers

    public override onClearMouseDown(event: MouseEvent): void
    {
        super.onClearMouseDown(event);

        this.dateTimeTabIndex = 0;
    }

    public onCalendarViewButtonClick(event: MouseEvent): void
    {
        event.preventDefault();

        this._calendarViewMode += 1;
        this.generateCalendar();
    }

    public onLocateButtonClick(event: MouseEvent): void
    {
        event.preventDefault();

        if (!(this._innerValue !== null && this._innerValue !== undefined ? Utils.getMoment(this._innerValue) :
            Utils.getMoment()).isSame(this._currentMoment, 'month'))
        {
            this._isAlternateDaysView = !this._isAlternateDaysView;
        }

        this.setSelectedDate();
    }

    public onPreviousButtonClick(event: MouseEvent): void
    {
        event.preventDefault();

        this.setPrevCalendarView();
    }

    public onNextButtonClick(event: MouseEvent): void
    {
        event.preventDefault();

        this.setNextCalendarView();
    }

    public onDateMouseDown(event: MouseEvent, date: moment.Moment): void
    {
        event.preventDefault();

        if (this.withTime)
        {
            const currentDate: moment.Moment = this._innerEditValue !== null ? Utils.getMoment(this._innerEditValue) : Utils.getMoment();
            date = Utils.getMoment(date).set({ 'hours': currentDate.get('hours'), 'minutes': currentDate.get('minutes') });
        }

        if (this._calendarViewMode !== CalendarViewMode.Days)
        {
            this._calendarViewMode -= 1;
            this._currentMoment = Utils.getMoment(date);
            this.generateCalendar();
            return;
        }

        const isSameMonth: boolean = (this._currentMoment.isSame(date, 'month'));

        this.editValue = date.toDate();

        if (!isSameMonth)
        {
            this._isAlternateDaysView = !this._isAlternateDaysView;
            this.generateCalendar();
        }

        if (this.withTime)
        {
            this.dateTimeTabIndex = 1;
        }
        else if (!Constants.IS_MOBILE)
        {
            this.close(true);
        }
    }

    public onCalendarViewSwipe(swipeDirection: SwipeDirection): void
    {
        if (swipeDirection === SwipeDirection.Down)
        {
            this.setPrevCalendarView();
        }
        else if (swipeDirection === SwipeDirection.Up)
        {
            this.setNextCalendarView();
        }
    }

    // #endregion

    // #region Public Methods

    public isDateEnabled(date: moment.Moment): boolean
    {
        if (this.minDate === null && this.maxDate === null)
        {
            return true;
        }

        let dateUnits: moment.unitOfTime.StartOf = 'day';

        if (this._calendarViewMode === CalendarViewMode.Years)
        {
            dateUnits = 'year';
        }

        else if (this._calendarViewMode === CalendarViewMode.Months)
        {
            dateUnits = 'month';
        }

        return (this.minDate !== null ? Utils.getMoment(this.minDate).isSameOrBefore(date, dateUnits) : true) &&
            (this.maxDate !== null ? Utils.getMoment(this.maxDate).isSameOrAfter(date, dateUnits) : true);
    }

    public isToday(date: moment.Moment): boolean
    {
        let dateUnits: moment.unitOfTime.StartOf = 'day';

        if (this._calendarViewMode === CalendarViewMode.Years)
        {
            dateUnits = 'year';
        }

        else if (this._calendarViewMode === CalendarViewMode.Months)
        {
            dateUnits = 'month';
        }

        return Utils.getMoment().isSame(date, dateUnits);
    }

    public isSelected(date: moment.Moment): boolean
    {
        if (this._innerEditValue === null)
        {
            return false;
        }

        let dateUnits: moment.unitOfTime.StartOf = 'days';

        if (this._calendarViewMode === CalendarViewMode.Years)
        {
            dateUnits = 'years';
        }
        else if (this._calendarViewMode === CalendarViewMode.Months)
        {
            dateUnits = 'months';
        }

        return Math.abs(Math.floor(Utils.getMoment(this._innerEditValue).diff(date, dateUnits, true))) === 0;
    }

    public isSelectedMonth(dateMoment: moment.Moment): boolean
    {
        return dateMoment.isSame(this._currentMoment, 'month');
    }

    public isSelectedYear(dateMoment: moment.Moment): boolean
    {
        return dateMoment.isSame(this._currentMoment, 'year');
    }

    // #endregion

    // #region Protected Method

    protected override open(): void
    {
        if (this._dropdownState === DropdownState.Closed)
        {
            this.dateTimeTabIndex = 0;

            this.setSelectedDate();
        }

        super.open();
    }

    // #endregion

    // #region Private Methods

    private setPrevCalendarView(): void
    {
        switch (this._calendarViewMode)
        {
            case CalendarViewMode.Years:
                {
                    if (this._calendarYearsElementRef !== undefined)
                    {
                        this.moveCalendar(this._calendarYearsElementRef.nativeElement, false, 'years', this.YEARS_RANGE);
                    }
                }
                break;

            case CalendarViewMode.Months:
                {
                    if (this._calendarMonthsElementRef !== undefined)
                    {
                        this.moveCalendar(this._calendarMonthsElementRef.nativeElement, false, 'years', 1);
                    }
                }
                break;

            default:
                {
                    if (this._calendarWeeksElementRef !== undefined)
                    {
                        this.moveCalendar(this._calendarWeeksElementRef.nativeElement, false, 'months', 1);
                    }
                }
                break;
        }

        this.generateCalendar();
    }

    private setNextCalendarView(): void
    {
        switch (this._calendarViewMode)
        {
            case CalendarViewMode.Years:
                {
                    if (this._calendarYearsElementRef !== undefined)
                    {
                        this.moveCalendar(this._calendarYearsElementRef.nativeElement, true, 'years', this.YEARS_RANGE);
                    }
                }
                break;

            case CalendarViewMode.Months:
                {
                    if (this._calendarMonthsElementRef !== undefined)
                    {
                        this.moveCalendar(this._calendarMonthsElementRef.nativeElement, true, 'years', 1);
                    }
                }
                break;

            default:
                {
                    if (this._calendarWeeksElementRef !== undefined)
                    {
                        this.moveCalendar(this._calendarWeeksElementRef.nativeElement, true, 'months', 1);
                    }
                }
                break;
        }
    }

    private setSelectedDate(): void
    {
        this._currentMoment = this._innerValue !== null && this._innerValue !== undefined ? Utils.getMoment(this._innerValue) : Utils.getMoment();
        if (this.minDate !== null && Utils.getMoment(this.minDate).isAfter(this._currentMoment, 'day'))
        {
            this._currentMoment = Utils.getMoment(this.minDate);
        }

        if (this.maxDate !== null && Utils.getMoment(this.maxDate).isBefore(this._currentMoment, 'day'))
        {
            this._currentMoment = Utils.getMoment(this.maxDate);
        }

        this._calendarViewMode = CalendarViewMode.Days;
        this.generateCalendar();
    }

    private getFirstDayOfMonth(month: moment.Moment): moment.Moment
    {
        const firstDayIndexOfMonth: number = Utils.getMoment(month).startOf('month').day();
        return Utils.getMoment(month).startOf('month').subtract(firstDayIndexOfMonth, 'days');
    }

    private generateMonthCalendar(): moment.Moment[]
    {
        const datesMoment: moment.Moment[] = [];

        const firstDayOfPages: moment.Moment = this.getFirstDayOfMonth(this._currentMoment).subtract(this.DAYS_PER_PAGE, 'days');
        const lastDayOfPages: moment.Moment = Utils.getMoment(firstDayOfPages).add(this.DAYS_PER_PAGE * 3, 'days');

        for (let dateDayIndex: number = firstDayOfPages.date(); dateDayIndex < firstDayOfPages.date() + lastDayOfPages.diff(firstDayOfPages, 'days'); dateDayIndex++)
        {
            datesMoment.push(Utils.getMoment(firstDayOfPages).date(dateDayIndex));
        }

        return datesMoment;
    }

    private generateCalendar(): void
    {
        const datesMoment: moment.Moment[] = [];

        switch (this._calendarViewMode)
        {
            case CalendarViewMode.Years:
                {
                    for (let i: number = -1; i < 2; i++)
                    {
                        const firstYearNumber: number = Math.floor(Utils.getMoment(this._currentMoment).year() / 10) * 10 + this.YEARS_RANGE * i;
                        for (let year: number = firstYearNumber; year < firstYearNumber + this.YEARS_RANGE; year++)
                        {
                            datesMoment.push(Utils.getMoment(this._currentMoment).add(i, 'years').year(year));
                        }
                    }
                }
                break;

            case CalendarViewMode.Months:
                {
                    const firstMonth: moment.Moment = Utils.getMoment(this._currentMoment).month(0).subtract(this.MONTHS_PER_PAGE, 'months')
                    for (let month: number = 0; month < this.MONTHS_PER_PAGE * 3; month++)
                    {
                        datesMoment.push(Utils.getMoment(firstMonth).add(month, 'months'));
                    }
                }
                break;

            case CalendarViewMode.Days:
                {
                    datesMoment.push(...this.generateMonthCalendar());
                }
                break;
        }

        this._viewDates = datesMoment;
    }

    private moveCalendar(pageElement: HTMLElement, isForward: boolean, moveUnitOfTime: moment.DurationInputArg2, moveAmount: number): void
    {
        let pageOffset: number = 0;
        if (this._calendarViewMode === CalendarViewMode.Days)
        {
            if (isForward)
            {
                const nextMonth: moment.Moment = Utils.getMoment(this._currentMoment).add(1, 'months');
                const firstDayOfNextMonth: moment.Moment = this.getFirstDayOfMonth(nextMonth);

                pageOffset = this._viewDates[this.DAYS_PER_PAGE * 2].diff(firstDayOfNextMonth, 'days') / this.DAYS_PER_PAGE * 100;
            }
            else
            {
                const previousMonth: moment.Moment = Utils.getMoment(this._currentMoment).subtract(1, 'months');
                const lastDayOfPrevoiusMonth: moment.Moment = this.getFirstDayOfMonth(previousMonth).add(this.DAYS_PER_PAGE, 'days');

                pageOffset = lastDayOfPrevoiusMonth.diff(this._viewDates[this.DAYS_PER_PAGE], 'days') / this.DAYS_PER_PAGE * 100;
            }
        }
        if (this._calendarViewMode === CalendarViewMode.Months)
        {
            pageOffset = (this.MONTHS_PER_PAGE - moment.months().length) / this.MONTHS_PER_PAGE * 100;
        }

        this.animateCalendarPage(pageElement, isForward, pageOffset, () =>
        {
            if (isForward)
            {
                this._currentMoment = Utils.getMoment(this._currentMoment).add(moveAmount, moveUnitOfTime);
            }
            else
            {
                this._currentMoment = Utils.getMoment(this._currentMoment).subtract(moveAmount, moveUnitOfTime);
            }

            this.generateCalendar();
            this._changeDetectorRef.detectChanges();
        });
    }

    private animateCalendarPage(pageElement: HTMLElement, isForward: boolean, pageOffset: number, animationDoneCallback: () => void): void
    {
        if (this._calendarPageAnimationPlayer !== null)
        {
            return;
        }

        let moveAnimation: AnimationFactory = this._animationBuilder.build(
            [
                style({ transform: `translateY(-100%)` }),
                animate(AnimationsConstants.ANIMATION_LONG_TIMING, style({ transform: `translateY(${isForward ? -(200 - pageOffset) : -pageOffset}%)` }))
            ]);

        this._calendarPageAnimationPlayer = moveAnimation.create(pageElement);

        this._changeDetectorRef.detectChanges();

        this._calendarPageAnimationPlayer.onDone(() =>
        {
            animationDoneCallback();

            if (this._calendarPageAnimationPlayer !== null)
            {
                this._calendarPageAnimationPlayer.destroy();
                this._calendarPageAnimationPlayer = null;
            }
        });

        this._calendarPageAnimationPlayer.play();
    }

    // #endregion
}
