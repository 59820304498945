import
{
    Directive, ElementRef, EmbeddedViewRef, EventEmitter, Input, OnDestroy, Output, TemplateRef,
    ViewContainerRef, Renderer2, NgZone, ChangeDetectorRef
} from '@angular/core';
import { OnInit } from '@angular/core';
import { Constants, KeyValue } from '../utils/globals';
import { DropdownPosition, DropdownAlignment } from '../base/components/dropdown-base.component';
import { NavigationEnd, Router } from '@angular/router';
import { filter } from 'rxjs';

@Directive({
    selector: '[dropdown]',
    exportAs: 'dropdown',
    standalone: true
})
export class DropdownDirective implements OnInit, OnDestroy
{
    // #region Constants

    private readonly BODY_CLASSNAME: string = 'body';
    private readonly DROPDOWN_CONTINAER_CLASSNAME: string = 'dropdown-container';
    private readonly DROPDOWN_BODY_PADDING: number = 10;
    private readonly DROPDOWN_CONTINAER_TOP_OFFSET_VARIABLE_NAME: string = '--dropdown-container-top-offset';
    private readonly HOVER_VALID_DURATION: number = 200;
    private readonly RESIZE_CHECK_INTERVAL: number = 300;

    // #endregion

    // #region Private Members

    private _isExpanded: boolean = false;
    private _isHoverValid: boolean = false;
    private _appendToBodyDropdownElement: HTMLElement | null = null;
    private _dropdownConstainerElement: HTMLElement | null = null;
    private _disposeWindowMouseWheelHandler: (() => void) | null = null;
    private _disposeWindowPointerDownHandler: (() => void) | null = null;
    private _disposeWindowMouseUpHandler: (() => void) | null = null;
    private _disposeWindowKeyUpHandler: (() => void) | null = null;
    private _disposeWindowResizeHandler: (() => void) | null = null;
    private _disposeWindowMouseMoveHandler: (() => void) | null = null;
    private _disposeMouseEnterHandler: (() => void) | null = null;
    private _disposeMouseLeaveHandler: (() => void) | null = null;
    private _disposeDropdownButtonMouseDownHandler: (() => void) | null = null;
    private _resizeObserver: ResizeObserver | null = null;
    private _checkElementResizedIntervalHandle: NodeJS.Timeout | null = null;
    private _elementResized: boolean = false;

    // #endregion

    // #region Inputs

    @Input() public autoClose: boolean = true;
    @Input() public useHover: boolean = false;
    @Input() public attachToBodyTemplateRef: TemplateRef<HTMLElement> | null = null;
    @Input() public attachToBodyTemplateOutletContext: any | null = null;
    @Input() public horizontalPosition: DropdownPosition | null = null;
    @Input() public verticalAlignment: DropdownAlignment | null = null;

    @Input()
    public get isExpanded()
    {
        return this._isExpanded;
    }

    public set isExpanded(value: boolean)
    {
        if (this._isExpanded === value)
        {
            return;
        }

        this._isExpanded = value;
        this.isExpandedChange.emit(value);

        setTimeout(() => this._ngZone.run(() => this._changeDetectorRef.detectChanges()));

        if (this._isExpanded)
        {
            this._ngZone.runOutsideAngular(() =>
            {
                if (this.attachToBodyTemplateRef !== null)
                {
                    const embeddedViewRef: EmbeddedViewRef<any> =
                        this._viewContainerRef.createEmbeddedView(this.attachToBodyTemplateRef, this.attachToBodyTemplateOutletContext ?? undefined);

                    this._appendToBodyDropdownElement = document.body.appendChild(embeddedViewRef.rootNodes[0]);
                    this._appendToBodyDropdownElement?.classList.add(this.BODY_CLASSNAME);

                    setTimeout(() => this.addAttachToBodyDropdown());

                    this._disposeWindowMouseWheelHandler = this._renderer.listen(window, 'wheel', (event: WheelEvent) =>
                    {
                        if (this._appendToBodyDropdownElement !== null && !this._appendToBodyDropdownElement.contains(event.target as HTMLElement))
                        {
                            this.isExpanded = false;
                        }
                    });
                }

                this._disposeWindowPointerDownHandler = this._renderer.listen(window, 'pointerdown', (event: PointerEvent) =>
                {
                    if (!this._elementRef.nativeElement.contains(event.target as HTMLElement) &&
                        (this._appendToBodyDropdownElement === null || !this._appendToBodyDropdownElement.contains(event.target as HTMLElement)))
                    {
                        this.isExpanded = false;
                    }
                });

                if (this.useHover)
                {
                    this._disposeWindowMouseMoveHandler = this._renderer.listen(window, 'mousemove', (event: PointerEvent) =>
                    {
                        if (!this._elementRef.nativeElement.contains(event.target as HTMLElement) &&
                            (this._dropdownConstainerElement === null || !this._dropdownConstainerElement.contains(event.target as HTMLElement)) &&
                            (this._appendToBodyDropdownElement === null || !this._appendToBodyDropdownElement.contains(event.target as HTMLElement)))
                        {
                            this._isHoverValid = false;
                            setTimeout(() =>
                            {
                                if (!this._isHoverValid && this.isExpanded)
                                {
                                    this.isExpanded = false;
                                }
                            }, this.HOVER_VALID_DURATION);
                        }
                        else
                        {
                            this._isHoverValid = true;
                        }
                    });

                    document.addEventListener('mouseleave', () => this.isExpanded = false, { once: true });
                }

                if (this.autoClose)
                {
                    this._disposeWindowMouseUpHandler = this._renderer.listen(window, 'mouseup', (event: MouseEvent) =>
                    {
                        if (this._dropdownConstainerElement === null)
                        {
                            this._dropdownConstainerElement = this._elementRef.nativeElement.querySelector(`.${this.DROPDOWN_CONTINAER_CLASSNAME}`);
                        }

                        if (this._dropdownConstainerElement !== null && this._dropdownConstainerElement.contains(event.target as HTMLElement)
                            || (this._appendToBodyDropdownElement !== null && this._appendToBodyDropdownElement.contains(event.target as HTMLElement)))
                        {
                            this.isExpanded = false;
                        }
                    });
                }

                this._disposeWindowKeyUpHandler = this._renderer.listen(window, 'keydown', (event: KeyboardEvent) =>
                {
                    if (event.key === KeyValue.Escape)
                    {
                        this.isExpanded = false;
                    }
                });

                this._disposeWindowKeyUpHandler = this._renderer.listen(window, 'resize', () =>
                {
                    this.isExpanded = false;
                });
            });
        }
        else
        {
            this.clearresizeObserver();

            this.clearCheckElementResizedInterval();
            this.clearWindowEvents();

            this._dropdownConstainerElement = null;

            if (this._appendToBodyDropdownElement !== null)
            {
                if (this._appendToBodyDropdownElement.classList.contains(Constants.ACTIVE_CLASSNAME))
                {
                    this._appendToBodyDropdownElement.classList.remove(Constants.ACTIVE_CLASSNAME);
                    const transitionDuration: number = parseFloat(getComputedStyle(this._appendToBodyDropdownElement).transitionDuration);
                    setTimeout(() => this.removeAttachToBodyDropdown(), transitionDuration * (transitionDuration < 1 ? 1000 : 1));
                }
                else
                {
                    this.removeAttachToBodyDropdown();
                }
            }
        }
    }

    // #endregion

    // #region Events

    @Output() isExpandedChange = new EventEmitter();

    // #endregion

    // #region Constructor

    constructor(private _elementRef: ElementRef<HTMLElement>, private _viewContainerRef: ViewContainerRef, private _renderer: Renderer2,
        private _ngZone: NgZone, private _router: Router, private _changeDetectorRef: ChangeDetectorRef)
    {
    }

    // #endregion

    // #region Event Handlers

    public ngOnInit(): void
    {
        const dropdownMenuButtonElement: HTMLElement | null = this._elementRef.nativeElement.querySelector('button');
        if (dropdownMenuButtonElement !== null)
        {
            this._ngZone.runOutsideAngular(() => 
            {
                if (this.useHover)
                {
                    this._disposeMouseEnterHandler = this._renderer.listen(dropdownMenuButtonElement, 'mouseenter', () =>
                    {
                        this._isHoverValid = true;

                        setTimeout(() =>
                        {
                            if (this._isHoverValid && !this.isExpanded)
                            {
                                this.isExpanded = true;
                            }
                        }, this.HOVER_VALID_DURATION);

                        this.clearHoverLeaveEvent();
                        this._disposeMouseLeaveHandler = this._renderer.listen(dropdownMenuButtonElement, 'mouseleave', () => this._isHoverValid = false);
                    });
                }
                else
                {
                    this._disposeDropdownButtonMouseDownHandler = this._renderer.listen(dropdownMenuButtonElement, 'mousedown',
                        () => this.isExpanded = !this.isExpanded);
                }
            });
        }

        this._router.events.pipe(filter((event: any) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => this.isExpanded = false);
    }

    public ngOnDestroy(): void
    {
        this.removeAttachToBodyDropdown();

        if (this._disposeDropdownButtonMouseDownHandler !== null)
        {
            this._disposeDropdownButtonMouseDownHandler();
            this._disposeDropdownButtonMouseDownHandler = null;
        }

        if (this._disposeMouseEnterHandler !== null)
        {
            this._disposeMouseEnterHandler();
            this._disposeMouseEnterHandler = null;
        }

        this.clearHoverLeaveEvent();

        this.clearWindowEvents();

        this.clearCheckElementResizedInterval();

        this.clearresizeObserver();
    }

    private onElementResize(): void
    {
        this._elementResized = true;

        if (this._checkElementResizedIntervalHandle !== null)
        {
            return;
        }

        this._elementResized = false;

        this.addAttachToBodyDropdown();

        this._checkElementResizedIntervalHandle = setInterval(() =>
        {
            this.addAttachToBodyDropdown();

            if (this._elementResized)
            {
                this._elementResized = false;
            }
            else 
            {
                this.clearCheckElementResizedInterval();
            }

        }, this.RESIZE_CHECK_INTERVAL);
    }

    // #endregion

    // #region Public Methods

    public collapse(): void
    {
        this.isExpanded = false;
    }

    // #endregion

    // #region Private Methods

    private clearHoverLeaveEvent(): void
    {
        if (this._disposeMouseLeaveHandler !== null)
        {
            this._disposeMouseLeaveHandler();
            this._disposeMouseLeaveHandler = null;
        }
    }

    private clearWindowEvents(): void
    {
        if (this._disposeWindowMouseWheelHandler !== null)
        {
            this._disposeWindowMouseWheelHandler();
            this._disposeWindowMouseWheelHandler = null;
        }

        if (this._disposeWindowPointerDownHandler !== null)
        {
            this._disposeWindowPointerDownHandler();
            this._disposeWindowPointerDownHandler = null;
        }

        if (this._disposeWindowMouseUpHandler !== null)
        {
            this._disposeWindowMouseUpHandler();
            this._disposeWindowMouseUpHandler = null;
        }

        if (this._disposeWindowKeyUpHandler !== null)
        {
            this._disposeWindowKeyUpHandler();
            this._disposeWindowKeyUpHandler = null;
        }

        if (this._disposeWindowResizeHandler !== null)
        {
            this._disposeWindowResizeHandler();
            this._disposeWindowResizeHandler = null;
        }

        if (this._disposeWindowMouseMoveHandler !== null)
        {
            this._disposeWindowMouseMoveHandler();
            this._disposeWindowMouseMoveHandler = null;
        }
    }

    private addAttachToBodyDropdown(): void
    {
        if (this._appendToBodyDropdownElement === null)
        {
            return;
        }

        let verticalDropdownPosition: DropdownPosition | null = DropdownPosition.Top;
        let horizontalDropdownPosition: DropdownPosition | null = null;

        const rectBodyElement: DOMRect = document.body.getBoundingClientRect();
        const rectElement: DOMRect = this._elementRef.nativeElement.getBoundingClientRect();
        const rectAppendToBodyDropdownElement: DOMRect = this._appendToBodyDropdownElement.getBoundingClientRect();

        if (rectElement.top - rectAppendToBodyDropdownElement.height < rectBodyElement.top)
        {
            verticalDropdownPosition = DropdownPosition.Bottom;
        }
        else if (rectElement.bottom + rectAppendToBodyDropdownElement.height > rectBodyElement.bottom)
        {
            verticalDropdownPosition = DropdownPosition.Top;
        }

        if (this.horizontalPosition === null)
        {
            if (rectElement.left + rectElement.width / 2 - rectAppendToBodyDropdownElement.width / 2 < rectBodyElement.left)
            {
                horizontalDropdownPosition = DropdownPosition.Right;
            }
            else if (rectElement.left + rectElement.width / 2 + rectAppendToBodyDropdownElement.width / 2 > rectBodyElement.right)
            {
                horizontalDropdownPosition = DropdownPosition.Left;
            }
        }

        if (horizontalDropdownPosition !== null)
        {
            if (verticalDropdownPosition === DropdownPosition.Top)
            {
                if (rectElement.bottom + rectAppendToBodyDropdownElement.height < rectBodyElement.bottom)
                {
                    verticalDropdownPosition = null;
                }
            }
            else if (verticalDropdownPosition === DropdownPosition.Bottom)
            {
                if (rectElement.top - rectAppendToBodyDropdownElement.height > rectBodyElement.top)
                {
                    verticalDropdownPosition = null;
                }
            }
        }

        if (verticalDropdownPosition !== null)
        {
            if (this.horizontalPosition !== null)
            {
                if (this.horizontalPosition === DropdownPosition.Left)
                {
                    this._appendToBodyDropdownElement.style.left = `${rectElement.left - rectAppendToBodyDropdownElement.width}px`;
                }
                else
                {
                    this._appendToBodyDropdownElement.style.left = `${rectElement.right}px`;
                }

                this._appendToBodyDropdownElement.style.right = 'auto';

                if (rectElement.top + rectAppendToBodyDropdownElement.height > rectBodyElement.bottom - this.DROPDOWN_BODY_PADDING)
                {
                    const dropdownTop: number = rectBodyElement.bottom - this.DROPDOWN_BODY_PADDING - rectAppendToBodyDropdownElement.height;
                    this._appendToBodyDropdownElement.style.top = `${dropdownTop}px`;
                    this._appendToBodyDropdownElement.style.setProperty(this.DROPDOWN_CONTINAER_TOP_OFFSET_VARIABLE_NAME, `${rectElement.top - dropdownTop}px`);
                }
                else
                {
                    this._appendToBodyDropdownElement.style.top = `${rectElement.top}px`;
                    this._appendToBodyDropdownElement.style.setProperty(this.DROPDOWN_CONTINAER_TOP_OFFSET_VARIABLE_NAME, '0px');
                }

                this._appendToBodyDropdownElement.style.bottom = 'auto';
            }
            else
            {
                this._appendToBodyDropdownElement.style.left = `${Math.round(rectElement.width / 2 + rectElement.left - rectAppendToBodyDropdownElement.width / 2)}px`;
                this._appendToBodyDropdownElement.style.right = 'auto';

                if (this.verticalAlignment === DropdownAlignment.Top)
                {
                    this._appendToBodyDropdownElement.style.top = `${rectElement.top}px`;
                }
                else if (this.verticalAlignment === DropdownAlignment.Center)
                {
                    this._appendToBodyDropdownElement.style.top = `${rectElement.top + rectElement.height / 2 - rectAppendToBodyDropdownElement.height / 2}px`;
                }
                else if (this.verticalAlignment === DropdownAlignment.Bottom)
                {
                    this._appendToBodyDropdownElement.style.top = `${rectElement.bottom}px`;
                }
                else if (verticalDropdownPosition === DropdownPosition.Top)
                {
                    this._appendToBodyDropdownElement.style.top = `${rectElement.top - rectAppendToBodyDropdownElement.height}px`;
                    this._appendToBodyDropdownElement.style.bottom = 'auto';
                }
                else
                {
                    this._appendToBodyDropdownElement.style.top = `${rectElement.bottom}px`;
                    this._appendToBodyDropdownElement.style.bottom = 'auto';
                }
            }

            this._appendToBodyDropdownElement.classList.add(DropdownPosition[verticalDropdownPosition].toLowerCase());
        }

        if (horizontalDropdownPosition !== null)
        {
            const isRtl: boolean = getComputedStyle(document.documentElement).direction === 'rtl';

            if (horizontalDropdownPosition === DropdownPosition.Left)
            {
                this._appendToBodyDropdownElement.style.left = `${rectElement.left - rectAppendToBodyDropdownElement.width}px`;
                this._appendToBodyDropdownElement.style.right = 'auto';
            }
            else
            {
                this._appendToBodyDropdownElement.style.left = `${rectElement.right + (isRtl ? 0 : rectAppendToBodyDropdownElement.width)}px`;
                this._appendToBodyDropdownElement.style.right = 'auto';
            }

            if (verticalDropdownPosition === null)
            {
                if (this.verticalAlignment === DropdownAlignment.Top)
                {
                    this._appendToBodyDropdownElement.style.top = `${rectElement.top}px`;
                }
                else if (this.verticalAlignment === DropdownAlignment.Bottom)
                {
                    this._appendToBodyDropdownElement.style.top = `${rectElement.top + rectElement.height}px`;
                }
                else
                {
                    this._appendToBodyDropdownElement.style.top = `${rectElement.top + rectElement.height / 2 - rectAppendToBodyDropdownElement.height / 2}px`;
                }

                this._appendToBodyDropdownElement.style.bottom = 'auto';
            }
        }

        if (this._appendToBodyDropdownElement.classList.contains(Constants.ACTIVE_CLASSNAME))
        {
            return;
        }

        this._appendToBodyDropdownElement.addEventListener('transitionend', () =>
            setTimeout(() => requestAnimationFrame(() =>
            {
                if (this._appendToBodyDropdownElement)
                {
                    this._appendToBodyDropdownElement.classList.add(Constants.SHOW_CLASSNAME);
                }
            })), { once: true });

        this._appendToBodyDropdownElement.classList.add(Constants.ACTIVE_CLASSNAME);

        this._resizeObserver = new ResizeObserver(() => this._ngZone.runOutsideAngular(() => this.onElementResize()));
        this._resizeObserver.observe(this._appendToBodyDropdownElement);
    }

    private removeAttachToBodyDropdown(): void
    {
        if (this._appendToBodyDropdownElement !== null)
        {
            document.body.removeChild(this._appendToBodyDropdownElement);
            this._appendToBodyDropdownElement = null;
        }

        this.clearCheckElementResizedInterval();
    }

    private clearCheckElementResizedInterval(): void
    {
        if (this._checkElementResizedIntervalHandle !== null)
        {
            clearInterval(this._checkElementResizedIntervalHandle);
            this._checkElementResizedIntervalHandle = null;
        }
    }

    private clearresizeObserver(): void
    {
        if (this._resizeObserver !== null)
        {
            this._resizeObserver.disconnect();
            this._resizeObserver = null;
        }
    }

    // #endregion
}