export enum UserInsightPermission
{
    ShipmetsMonitoring = 'monitoring',
    ShipmentManager = 'shipment_manager',
    ShipmentManagerAmazonEU = 'shipment_manager_amazon',
    ShipmentManagerAmazonUS = 'shipment_manager_amazon_us',
    Analytics = 'analytics',
    Devices = 'devices',
    TransitManager = 'transit_manager',
    InventoryManager = 'inventory_manager',
    SmartLockManager = 'smart_lock_manager',
    Alerts = 'alerts',
    MiniControlCenter = 'mini_control_center',
    AmazonForecast = 'amazon_forecast',
    DemurrageManager = 'demurrage_manager',
    DemurrageMonitoring = 'demurrage_live',
    StartForm = 'start_form'
}

export enum CustomerIdType
{
    AmazonEurope = 423, AmazonUS = 2868, Test = 2307
}

export enum CorpIdType
{
    PMI = 862, EsteeLauder = 965, Sony = 1729, JTI = 847, MSIG = 1392
}

export class UserCredetials
{
    public username: string | null = null;
    public password: string | null = null;
}

export class UserExternalLogin
{
    public identityProvider: string | null = null;
    public redirectBaseUrl: string | null = null;
}

export class UserExternalLoginAccessCode
{
    public code: string | null = null;
    public redirectBaseUrl: string | null = null;
}

export class UserExternalLoginResponse
{
    public redirectUrl: string | null = null;
}

export class UserExternalLoginAccessCodeRequest
{
    public code: string | null = null;
    public redirectBaseUrl: string | null = null;
}

export interface IUserAuthData
{
    access_token: string;
	expires_in: number;
	refresh_token: string;
	token_type: string;
}

export class UserInfo
{
    public firstName: string = '';
    public lastName: string = '';
    public initials: string = '';
    public fullName: string = '';
    public email: string = '';
    public customerId: string = '';
    public corpId: number | null = null;
    public isTest: boolean = false;
    public isAdministrator: boolean = false;
    public isDemo: boolean = false;
}

