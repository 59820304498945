import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EmbeddedViewRef, NgZone, Renderer2, ViewContainerRef } from '@angular/core';
import { fadeInOutAnimation, fadeSlideDownInOutAnimation } from "../animations/fade.animation";
import { slideInOutAnimation } from "../animations/slide.animation";
import { foldAnimation, foldBothAnimation, foldHorizontalAnimation, foldVerticalStateAnimation } from "../animations/fold.animation";
import { MonitoringItemsBaseComponent } from '../base/components/monitoring-items-base.component';
import { DemurrageContractDeviationType, DemurrageMonitoringColumnType, DemurrageMonitoringDataLists, DemurrageMonitoringFilters, ShipmentMonitoringDemurrageInfo } from './model/demurrage-monitoring-model.class';
import { DemurrageMonitoringModel } from './model/demurrage-monitoring.model';
import { AppSettingsService } from '../services/app-settings.service';
import { ModalMessageService } from '../controls/modal-message/services/modal-message.service';
import { RoutingHistoryService } from '../services/routing-history.service';
import { LoginModel } from '../user/login/model/login.model';
import { Router } from '@angular/router';
import { Utils } from '../utils/utils';
import * as XLSX from 'xlsx';
import { ICGIMarker, ICGIMarkerLocation } from '../base/classes/cgi-marker';
import { CGICluster } from '../controls/google-map/utils/cgi-cluster';
import { CGIMarkerDemurrageIconFactory } from './map/cgi-marker-demurrage-icon-factory';
import { CGIClusterBaseRenderer } from '../controls/google-map/utils/cgi-cluster-base-renderer';
import { CGIDemurrageClusterRenderer } from './map/cgi-demurrage-cluster-renderer';
import { FilterElement } from '../base/models/monitoring-items-base-model.class';
import { Constants } from '../utils/globals';
import { VirtualListInfo } from '../controls/virtual-list/model/virtual-list-info';
import { UserInsightPermission } from '../user/login/model/login-model.class';
import { DisplayShipmentMonitoring } from '../shipments-monitoring/shipment-monitoring/model/shipment-monitoring-model.class';
import { GlobalsPipe } from '../pipes/globals.pipe';
import { SelectListComponent } from '../controls/select-list/select-list.component';
import { SliderComponent } from '../controls/slider/slider.component';
import { FormsModule } from '@angular/forms';
import { NumericInputComponent } from '../controls/numeric-input/numeric-input.component';
import { OverlayScrollbarDirective } from '../directives/overlay-scrollbar/overlay-scrollbar.directive';
import { LoaderComponent } from '../controls/loader/loader.component';
import { TooltipDirective } from '../directives/tooltip.directive';
import { VirtualListComponent } from '../controls/virtual-list/virtual-list.component';
import { DropdownDirective } from '../directives/dropdown.directive';
import { GoogleMapComponent } from '../controls/google-map/google-map.component';
import { NgTemplateOutlet, NgClass } from '@angular/common';

@Component({
    selector: 'demurrage-monitoring',
    templateUrl: './demurrage-monitoring.component.html',
    styleUrls: ['../base/styles/monitoring-items-base.component.css', '../base/styles/monitoring-base.component.css',
        './demurrage-monitoring.component.css', '../base/styles/manager-table.css'],
    animations: [fadeInOutAnimation, slideInOutAnimation, foldAnimation, foldBothAnimation, fadeSlideDownInOutAnimation, foldHorizontalAnimation,
        foldVerticalStateAnimation],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgTemplateOutlet, GoogleMapComponent, DropdownDirective, VirtualListComponent, NgClass, TooltipDirective, LoaderComponent,
        OverlayScrollbarDirective, NumericInputComponent, FormsModule, SliderComponent, SelectListComponent, GlobalsPipe]
})
export class DemurrageMonitoringComponent extends MonitoringItemsBaseComponent<DisplayShipmentMonitoring, DemurrageMonitoringFilters, DemurrageMonitoringDataLists,
    ShipmentMonitoringDemurrageInfo>
{
    // #region Private Members

    private static _shipmentsMonitoringTableListInfo: VirtualListInfo = new VirtualListInfo();
    private static _collapseMonitoringItemsFilters: boolean = true;
    private static _collapseShipmentsMonitoringTable: boolean = true;
    private static _collapseShipmentsMonitoringMapSection: boolean = false;

    private _cgiMarkerDemurrageIconFactory: CGIMarkerDemurrageIconFactory = new CGIMarkerDemurrageIconFactory();
    private _filterDemurrageStatusElements: FilterElement[] = [];

    // #endregion

    // #region Properties

    public get DemurrageMonitoringColumnType()
    {
        return DemurrageMonitoringColumnType;
    }

    public get DemurrageContractDeviationType()
    {
        return DemurrageContractDeviationType;
    }

    public override get monitoringItemsModel(): DemurrageMonitoringModel
    {
        return this._monitoringItemsModel as DemurrageMonitoringModel;
    }

    public get filterDemurrageStatusElements(): FilterElement[]
    {
        return this._filterDemurrageStatusElements;
    }

    public override get collapseMonitoringItemsFilters(): boolean
    {
        return DemurrageMonitoringComponent._collapseMonitoringItemsFilters;
    }

    public override set collapseMonitoringItemsFilters(value: boolean)
    {
        DemurrageMonitoringComponent._collapseMonitoringItemsFilters = value;
    }

    public get collapseShipmentsMonitoringTable(): boolean
    {
        return DemurrageMonitoringComponent._collapseShipmentsMonitoringTable;
    }

    public set collapseShipmentsMonitoringTable(value: boolean)
    {
        if (!value)
        {
            DemurrageMonitoringComponent._shipmentsMonitoringTableListInfo.scrollToItem = this._monitoringItemsModel.selectedMonitoringItem;
        }

        DemurrageMonitoringComponent._collapseShipmentsMonitoringTable = value;
    }

    public get collapseShipmentsMonitoringMapSection(): boolean
    {
        return DemurrageMonitoringComponent._collapseShipmentsMonitoringMapSection;
    }

    public set collapseShipmentsMonitoringMapSection(value: boolean)
    {
        DemurrageMonitoringComponent._collapseShipmentsMonitoringMapSection = value;
    }

    public get shipmentsMonitoringTableListInfo(): VirtualListInfo
    {
        return DemurrageMonitoringComponent._shipmentsMonitoringTableListInfo;
    }

    // #endregion

    // #region Constructors

    constructor(_monitoringItemsModel: DemurrageMonitoringModel, _renderer: Renderer2, _appSettingsService: AppSettingsService,
        _changeDetectorRef: ChangeDetectorRef, _routingHistoryService: RoutingHistoryService, _ngZone: NgZone, _modalMessageService: ModalMessageService,
        _viewContainerRef: ViewContainerRef, _router: Router, _loginModel: LoginModel)
    {
        super(_monitoringItemsModel, _renderer, _appSettingsService, _changeDetectorRef, _routingHistoryService, _ngZone, _modalMessageService,
            _viewContainerRef, _router, _loginModel);

        this._filterDemurrageStatusElements = [
            { filterType: DemurrageContractDeviationType.Late, tooltip: 'Deviated ', value: 0, class: 'late', iconClassName: '' },
            { filterType: DemurrageContractDeviationType.Early, tooltip: 'Not deviated', value: 0, class: 'early', iconClassName: '' },
            { filterType: DemurrageContractDeviationType.Unknown, tooltip: 'Unknown', value: 0, class: 'unknown', iconClassName: '' }
        ];
    }

    // #endregion

    // #region Event Handlers

    public override ngOnInit(): void
    {
        this.initializeMonitoringItemsTableColumns();

        const isPopState: boolean = this._routingHistoryService.isPopState && this._monitoringItemsModel.isInitialized;

        super.ngOnInit();

        if (!isPopState)
        {
            DemurrageMonitoringComponent._externalShipmentsFilterTableListInfo = new VirtualListInfo();

            DemurrageMonitoringComponent._shipmentsMonitoringTableListInfo = new VirtualListInfo();
            DemurrageMonitoringComponent._shipmentsMonitoringTableListInfo.sortColumn = DemurrageMonitoringColumnType.Deviation;
            DemurrageMonitoringComponent._shipmentsMonitoringTableListInfo.sortOrder = -1;

            DemurrageMonitoringComponent._collapseMonitoringItemsFilters = false;
            DemurrageMonitoringComponent._collapseShipmentsMonitoringTable = false;
        }
    }

    public onExportToExcelButtonClick(): void
    {
        const exportData: any[] = this.monitoringItemsModel.filteredMonitoringItems.map((shipmentMonitoring: DisplayShipmentMonitoring) =>
        {
            const rowData: any = {};

            for (const shipmentColumn of this._tableMonitoringItemsColumns)
            {
                if (shipmentColumn.text !== undefined && shipmentColumn.propertyName !== undefined)
                {
                    rowData[Utils.removeTextHtmlTags(shipmentColumn.text)] = (shipmentMonitoring as any)[shipmentColumn.propertyName];
                }
            }

            rowData['Port Type'] = shipmentMonitoring.currentSegment;
            rowData['Current Latitude'] = shipmentMonitoring.currentLat;
            rowData['Current Longitude'] = shipmentMonitoring.currentLon;
            rowData['CG-ID'] = shipmentMonitoring.shipmentKey;

            return rowData;
        });

        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportData);
        const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };

        XLSX.writeFile(workbook, Utils.getFileDateName(this.EXCEL_FILE_PREFIX, '.xlsx', new Date()), { compression: true });
    }

    public override async onShareSelectedMonitoringInfo(shareCopyMonitoringInfo: DisplayShipmentMonitoring, copyOnlyToClipboard: boolean): Promise<void>
    {
        this.collapseShareOptions();

        const shareText: string = `${this.SHARING_INFO_TITLE}\r\n${''
            }Carrier: ${shareCopyMonitoringInfo.carrierName}\r\n${''
            }Port: ${shareCopyMonitoringInfo.perimeterName}\r\n${''
            }Port Code: ${shareCopyMonitoringInfo.perimeterPortCode}\r\n${''
            }Segemnt: ${shareCopyMonitoringInfo.currentSegment}\r\n${''
            }Country: ${shareCopyMonitoringInfo.perimeterCountryName ?? Constants.EMPTY_FIELD_VALUE}\r\n${''
            }Entering Date${this._appSettingsService.appSettings.isUsingUTCTime ? ' (UTC)' : ''}: ${this.getFormattedDateTime(shareCopyMonitoringInfo.currentPortStartTime)}\r\n${''
            }Duration: ${shareCopyMonitoringInfo.demurragePortDurationFormatted}\r\n${''
            }Free Days: ${shareCopyMonitoringInfo.demurrageFreeDaysFormatted}\r\n${''
            }Deviation: ${shareCopyMonitoringInfo.demurrageDeviationFormatted}\r\n${''
            }Arrival Status: ${shareCopyMonitoringInfo.delayString}\r\n${''
            }${this._loginModel.isAccountTypeAmazonUS ? 'Trailer Number' : this.containerIdColumnName}: ${this._loginModel.isAccountTypeAmazonUS ? shareCopyMonitoringInfo.trailerNumber : shareCopyMonitoringInfo.containerId} \r\n${''
            }Last Update${this._appSettingsService.appSettings.isUsingUTCTime ? ' (UTC)' : ''}: ${this.getFormattedDateTime(shareCopyMonitoringInfo.lastUpdate)}\r\n${''
            }ETA${this._appSettingsService.appSettings.isUsingUTCTime ? ' (UTC)' : ''}: ${this.getFormattedDateTime(shareCopyMonitoringInfo.eta)}\r\n${''
            }Reference: ${shareCopyMonitoringInfo.bookingReference}\r\n${''
            }${this.deviceIdColumnName}: ${shareCopyMonitoringInfo.deviceDescription}\r\n${''
            }CG-ID: ${shareCopyMonitoringInfo.shipmentKey}\r\n${''
            }Coordinates: ${shareCopyMonitoringInfo.currentLat}\xb0, ${shareCopyMonitoringInfo.currentLon}\xb0\r\n${''
            }Origin Route: ${shareCopyMonitoringInfo.originName}\r\n${''
            }Destination Route: ${shareCopyMonitoringInfo.destinationName}\r\n${''
            }Route Segment: ${shareCopyMonitoringInfo.currentLocDes}\r\n${''
            }Route Progress: ${shareCopyMonitoringInfo.progress}%`;

        await this.shareInfo(this.SHARING_INFO_TITLE, shareText, copyOnlyToClipboard);
    }

    public onFilterDemurrageStatusSelectChange(demurrageDeviationType: DemurrageContractDeviationType): void
    {
        this.monitoringItemsModel.editMonitoringFilters.selectedShipmentMonitoringDemurrageStatuses = [demurrageDeviationType];
        this.applyMonitoringFilters();

        this.monitoringItemsModel.updateMonitoringDataListsByFilters();
    }

    public onFilterDemurrageStatusSelectClear(): void
    {
        this.monitoringItemsModel.editMonitoringFilters.selectedShipmentMonitoringDemurrageStatuses = [];
        this.applyMonitoringFilters();

        this.monitoringItemsModel.updateMonitoringDataListsByFilters();
    }

    // #endregion

    // #region Protected Methods

    protected override buildClusterInfowWindow(showClusterMarkersSummary: boolean, cluster: CGICluster, isCollapsable: boolean): void
    {
        let embeddedViewRef: EmbeddedViewRef<any>;

        if (showClusterMarkersSummary)
        {
            const clusterMarkers: (google.maps.marker.AdvancedMarkerElement | ICGIMarkerLocation)[] = cluster.markers;

            let lateShipmentsCount = 0;
            let earlyShipmentsCount = 0;
            let unknownShipmentsCount = 0;
            for (const marker of clusterMarkers)
            {
                const displayShipmentMonitoring: DisplayShipmentMonitoring = this.getMarkerData(marker) as DisplayShipmentMonitoring;

                if (displayShipmentMonitoring.demurrageDeviationId === DemurrageContractDeviationType.Late)
                {
                    lateShipmentsCount++;
                }
                else if (displayShipmentMonitoring.demurrageDeviationId === DemurrageContractDeviationType.Early)
                {
                    earlyShipmentsCount++;
                }
                else
                {
                    unknownShipmentsCount++;
                }
            }

            embeddedViewRef = this._viewContainerRef.createEmbeddedView(this._infoWindowMarkerClusterTemplateRef,
                { $implicit: { lateShipmentsCount: lateShipmentsCount, earlyShipmentsCount: earlyShipmentsCount, unknownShipmentsCount: unknownShipmentsCount } });
        }
        else
        {
            let maxInfoWindowExtraRows: number = 0;
            for (const marker of cluster.markers)
            {
                const displayShipmentMonitoring: DisplayShipmentMonitoring = this.getMarkerData(marker) as DisplayShipmentMonitoring;
                displayShipmentMonitoring.infoWindowExtraRows = displayShipmentMonitoring.demurrageDeviation !== null ? [0] : [];
                if (displayShipmentMonitoring.infoWindowExtraRows.length > maxInfoWindowExtraRows)
                {
                    maxInfoWindowExtraRows = displayShipmentMonitoring.infoWindowExtraRows.length;
                }
            }

            for (const marker of cluster.markers)
            {
                const displayShipmentMonitoring: DisplayShipmentMonitoring = this.getMarkerData(marker) as DisplayShipmentMonitoring;
                displayShipmentMonitoring.infoWindowExtraRows = Array(maxInfoWindowExtraRows - displayShipmentMonitoring.infoWindowExtraRows.length);
            }

            embeddedViewRef = this._viewContainerRef.createEmbeddedView(this._infoWindowMarkerTemplateRef,
                {
                    info: cluster.markers.map((marker: google.maps.marker.AdvancedMarkerElement | ICGIMarkerLocation) =>
                        (this.getMarkerData(marker) as DisplayShipmentMonitoring)).sort((a: DisplayShipmentMonitoring, b: DisplayShipmentMonitoring) =>
                        {
                            if (a.demurrageDeviation === null || b.demurrageDeviation === null)
                            {
                                return Number.MAX_SAFE_INTEGER;
                            }

                            return b.demurrageDeviation - a.demurrageDeviation
                        }), isCollapsable: isCollapsable
                });
        }

        this.openMarkerPopup(cluster.marker!, embeddedViewRef.rootNodes[0], showClusterMarkersSummary);
    }

    protected override updateFilterElementsInfo(): void
    {
        for (const filterDemurrageStatusElement of this._filterDemurrageStatusElements)
        {
            switch (filterDemurrageStatusElement.filterType)
            {
                case DemurrageContractDeviationType.Unknown:
                    {
                        filterDemurrageStatusElement.value = this.monitoringItemsModel.monitoringItemsInfo.deviationUnknownCount;
                    }
                    break;

                case DemurrageContractDeviationType.Late:
                    {
                        filterDemurrageStatusElement.value = this.monitoringItemsModel.monitoringItemsInfo.deviationLateCount;
                    }
                    break;

                case DemurrageContractDeviationType.Early:
                    {
                        filterDemurrageStatusElement.value = this.monitoringItemsModel.monitoringItemsInfo.deviationEarlyCount;
                    }
                    break;
            }
        }
    }

    protected override getClusterRenderer(): CGIClusterBaseRenderer
    {
        return new CGIDemurrageClusterRenderer();
    }

    protected override ctreateGoogleMapMarker(shipmentMonitoring: DisplayShipmentMonitoring): ICGIMarker<DisplayShipmentMonitoring>
    {
        const marker: ICGIMarker<DisplayShipmentMonitoring> = new google.maps.marker.AdvancedMarkerElement({
            position: { lat: shipmentMonitoring.currentLat, lng: shipmentMonitoring.currentLon },
            content: this._cgiMarkerDemurrageIconFactory.createCGIMarkerDemmurageContractDeviationElement('icon-ship',
                shipmentMonitoring.demurrageDeviationId),
            map: null
        }) as ICGIMarker<DisplayShipmentMonitoring>;

        marker.data = shipmentMonitoring;

        this.addMarkerClickEvent(marker);

        return marker;
    }

    protected override initializeMonitoringItemsTableColumns(): void
    {
        const displayShipmentMonitoring: DisplayShipmentMonitoring = new DisplayShipmentMonitoring();

        this._tableMonitoringItemsColumns =
            [
                {
                    columnType: DemurrageMonitoringColumnType.ContainerId,
                    text: this._loginModel.isAccountTypeAmazonUS ? 'Trailer No.' : this.containerIdColumnName,
                    classes: 'col-container-id',
                    propertyName: Utils.getPropertyNameof<DisplayShipmentMonitoring>(displayShipmentMonitoring, displayShipmentMonitoring =>
                        this._loginModel.isAccountTypeAmazonUS ? displayShipmentMonitoring.trailerNumber : displayShipmentMonitoring.containerId)
                },
                {
                    columnType: DemurrageMonitoringColumnType.RouteDescription,
                    text: 'Route',
                    classes: 'col-route',
                    propertyName: Utils.getPropertyNameof<DisplayShipmentMonitoring>(displayShipmentMonitoring, displayShipmentMonitoring => displayShipmentMonitoring.routeDescription)
                },
                {
                    columnType: DemurrageMonitoringColumnType.Carrier,
                    text: 'Carrier',
                    classes: 'col-carrier',
                    propertyName: Utils.getPropertyNameof<DisplayShipmentMonitoring>(displayShipmentMonitoring, displayShipmentMonitoring => displayShipmentMonitoring.carrierName)
                },
                {
                    columnType: DemurrageMonitoringColumnType.Port,
                    text: 'Port',
                    classes: 'col-port',
                    propertyName: Utils.getPropertyNameof<DisplayShipmentMonitoring>(displayShipmentMonitoring, displayShipmentMonitoring => displayShipmentMonitoring.perimeterName)
                },
                {
                    columnType: DemurrageMonitoringColumnType.PortCode,
                    text: 'Port Code',
                    propertyName: Utils.getPropertyNameof<DisplayShipmentMonitoring>(displayShipmentMonitoring, displayShipmentMonitoring => displayShipmentMonitoring.perimeterPortCode)
                },
                {
                    columnType: DemurrageMonitoringColumnType.Country,
                    text: 'Country',
                    classes: 'col-country center',
                    propertyName: Utils.getPropertyNameof<DisplayShipmentMonitoring>(displayShipmentMonitoring, displayShipmentMonitoring => displayShipmentMonitoring.perimeterCountryName)
                },
                {
                    columnType: DemurrageMonitoringColumnType.EnteringDate,
                    text: `Entering Date<span class="small-units">${this._appSettingsService.appSettings.isUsingUTCTime ? ' (UTC)' : ''}</span>`,
                    propertyName: Utils.getPropertyNameof<DisplayShipmentMonitoring>(displayShipmentMonitoring, displayShipmentMonitoring =>
                        displayShipmentMonitoring.currentPortStartTimeFormatted),
                    sortPropertyName: Utils.getPropertyNameof<DisplayShipmentMonitoring>(displayShipmentMonitoring, displayShipmentMonitoring =>
                        displayShipmentMonitoring.currentPortStartTime)
                },
                {
                    columnType: DemurrageMonitoringColumnType.Duration,
                    text: 'Duration',
                    classes: 'col-duration center',
                    propertyName: Utils.getPropertyNameof<DisplayShipmentMonitoring>(displayShipmentMonitoring, displayShipmentMonitoring =>
                        displayShipmentMonitoring.demurragePortDurationFormatted),
                    sortPropertyName: Utils.getPropertyNameof<DisplayShipmentMonitoring>(displayShipmentMonitoring, displayShipmentMonitoring =>
                        displayShipmentMonitoring.demurragePortDuration)
                },
                {
                    columnType: DemurrageMonitoringColumnType.FreeDays,
                    text: 'Free Days',
                    classes: 'center',
                    propertyName: Utils.getPropertyNameof<DisplayShipmentMonitoring>(displayShipmentMonitoring, displayShipmentMonitoring =>
                        displayShipmentMonitoring.demurrageFreeDaysFormatted)
                },
                {
                    columnType: DemurrageMonitoringColumnType.Deviation,
                    text: 'Deviation',
                    classes: 'col-deviation center',
                    propertyName: Utils.getPropertyNameof<DisplayShipmentMonitoring>(displayShipmentMonitoring, displayShipmentMonitoring =>
                        displayShipmentMonitoring.demurrageDeviationFormatted),
                    sortPropertyName: Utils.getPropertyNameof<DisplayShipmentMonitoring>(displayShipmentMonitoring, displayShipmentMonitoring =>
                        displayShipmentMonitoring.demurrageSortDeviation)
                },
                {
                    columnType: DemurrageMonitoringColumnType.Copy,
                    classes: 'col-button center',
                    isFixedWidth: true
                },
                {
                    columnType: DemurrageMonitoringColumnType.Share,
                    classes: 'col-button center',
                    isFixedWidth: true
                },
            ];

        if (this._loginModel.userInsightPermissions.includes(UserInsightPermission.ShipmetsMonitoring))
        {
            this._tableMonitoringItemsColumns.push(
                {
                    columnType: DemurrageMonitoringColumnType.Track,
                    classes: 'col-button center',
                    isFixedWidth: true
                }
            );
        }
    }
}
