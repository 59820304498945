<!DOCTYPE html>

@if (isVisible) {
<div tabindex="-1" class="modal-backdrop" (click)="onButtonClick(ModalResultType.Close)" @fadeInOutAnimation></div>
}
@if (isVisible) {
<div class="modal-content-container" tabindex="-1" @fadeSlideDownInOutAnimation (@fadeSlideDownInOutAnimation.done)="onShowAnimationDone()">
    @if (modalContentTemplateRef) {
    <ng-container [ngTemplateOutlet]="modalContentTemplateRef" [ngTemplateOutletContext]="{ data: modalContentTemplateData }">
    </ng-container>
    }
    @if (!modalContentTemplateRef) {
    <div class="modal-content">
        @if (!modalHeaderTemplateRef) {
        <div class="modal-header">
            @if (modalIconClassName) {
            <div class="msg-icon" [class]="modalIconClassName"></div>
            }
            @if (modalTitle) {
            <div class="modal-title" [innerHTML]="modalTitle"></div>
            }
        </div>
        }
        @if (modalHeaderTemplateRef) {
        <ng-container [ngTemplateOutlet]="modalHeaderTemplateRef"></ng-container>
        }
        @if (!modalBodyTemplateRef) {
        <div class="modal-body page-scroll-padding" [innerHTML]="modalMessage" [class]="ModalBodyAlignType[modlaBodyAlign].toLowerCase()"></div>
        }
        @if (modalBodyTemplateRef) {
        <ng-container [ngTemplateOutlet]="modalBodyTemplateRef"></ng-container>
        }
        @if (!modalFooterTemplateRef) {
        <div class="modal-footer">
            @switch (modalButton) {
            @case (ModalButtonType.Close) {
            <div class="modal-buttons-contailer">
                <button type="button" class="primary-button ripple-effect ripple-fill" (click)="onButtonClick(ModalResultType.Close)">{{ modalButtonsNames[0] }}</button>
            </div>
            }
            @default {
            <div class="modal-buttons-contailer">
                <button type="button" class="secondary-button ripple-effect ripple-fill ripple-inverse-color"
                        (click)="onButtonClick(ModalResultType.No)">
                    {{ modalButtonsNames[0] }}
                </button>
                <button type="button" class="primary-button ripple-effect ripple-fill" (click)="onButtonClick(ModalResultType.Yes)">{{ modalButtonsNames[1] }}</button>
            </div>
            }
            }
        </div>
        }
        @if (modalFooterTemplateRef) {
        <ng-container [ngTemplateOutlet]="modalFooterTemplateRef"></ng-container>
        }
    </div>
    }
</div>
}
