import { trigger, animate, transition, style, state, query, group, AnimationTriggerMetadata, animateChild, AnimationTransitionMetadata } from '@angular/animations';
import { AnimationsConstants } from './constant';

export const fadeOutletAnimation: AnimationTriggerMetadata =

	trigger('fadeOutletAnimation',
		[
			transition('* => *',
				[
					query(':enter',
						[
							style({ opacity: 0 })
						],
						{ optional: true }
					),
					query(':leave', animateChild(), { optional: true }),
					group(
						[
							query(':leave',
								[
									style({ opacity: 1 }),
									animate(AnimationsConstants.ANIMATION_LONG_TIMING, style({ opacity: 0 }))
								],
								{ optional: true }),
							query(':enter',
								[
									style({ opacity: 0 }),
									animate(AnimationsConstants.ANIMATION_LONG_TIMING, style({ opacity: 1 }))
								],
								{ optional: true })
						]),
					query(':enter', animateChild(), { optional: true })
				])
		]);

export const fadeSlideDownInOutAnimation: AnimationTriggerMetadata =
	trigger('fadeSlideDownInOutAnimation',
		[
			transition(':enter',
				[
					style({ opacity: 0, transform: 'translateY(-8%)' }),
					animate(AnimationsConstants.ANIMATION_LONG_TIMING, style({ opacity: 1, transform: 'translateY(0)' }))
				]
			),
			transition(':leave',
				[
					style({ opacity: 1, transform: 'translateY(0)' }),
					animate(AnimationsConstants.ANIMATION_LONG_TIMING, style({ opacity: 0, transform: 'translateY(-8%)' }))
				]
			)
		]);

export const fadeSlideSidewaysInOutAnimation: AnimationTriggerMetadata =
	trigger('fadeSlideSidewaysInOutAnimation',
		[
			transition(':enter',
				[
					style({ opacity: 0, transform: 'translateX(-8%)' }),
					animate(AnimationsConstants.ANIMATION_LONG_TIMING, style({ opacity: 1, transform: 'translateX(0)' }))
				]
			),
			transition(':leave',
				[
					style({ opacity: 1, transform: 'translateX(0)' }),
					animate(AnimationsConstants.ANIMATION_LONG_TIMING, style({ opacity: 0, transform: 'translateX(-8%)' }))
				]
			)
		]);

export const fadeZoomInOutAnimation: AnimationTriggerMetadata =
	trigger('fadeZoomInOutAnimation',
		[
			transition(':enter',
				[
					style({ opacity: 0, transform: 'scale(1.15)' }),
					animate(AnimationsConstants.ANIMATION_LONG_TIMING, style({ opacity: 1, transform: 'scale(1)' }))
				]
			),
			transition(':leave',
				[
					style({ opacity: 1, transform: 'scale(1)' }),
					animate(AnimationsConstants.ANIMATION_LONG_TIMING, style({ opacity: 0, transform: 'scale(0.85)' }))
				]
			)
		]);

function getFadeEnterTransitionMetadta(isShortDuration: boolean = false): AnimationTransitionMetadata
{
	return transition(':enter',
		[
			style({ opacity: 0 }),
			animate(isShortDuration ? AnimationsConstants.ANIMATION_TIMING : AnimationsConstants.ANIMATION_LONG_TIMING, style({ opacity: 1 }))
		]
	);
}

function getFadeLeaveTransitionMetadta(isShortDuration: boolean = false): AnimationTransitionMetadata
{
	return transition(':leave',
		[
			style({ opacity: 1 }),
			animate(isShortDuration ? AnimationsConstants.ANIMATION_TIMING : AnimationsConstants.ANIMATION_LONG_TIMING, style({ opacity: 0 }))
		]
	);
}

export const fadeInOutAnimation: AnimationTriggerMetadata =
	trigger('fadeInOutAnimation',
		[
			getFadeEnterTransitionMetadta(),
			getFadeLeaveTransitionMetadta()
		]);

export const shortFadeInOutAnimation: AnimationTriggerMetadata =
	trigger('shortFadeInOutAnimation',
		[
			getFadeEnterTransitionMetadta(true),
			getFadeLeaveTransitionMetadta(true)
		]);

export const fadeInAnimation: AnimationTriggerMetadata =
	trigger('fadeInAnimation',
		[
			getFadeEnterTransitionMetadta()
		]);

export const shortFadeInAnimation: AnimationTriggerMetadata =
	trigger('shortFadeInAnimation',
		[
			getFadeEnterTransitionMetadta(true)
		]);

export const fadeOutAnimation: AnimationTriggerMetadata =
	trigger('fadeOutAnimation',
		[
			transition(':enter',
				[
					style({ opacity: 1 })
				]
			),
			getFadeLeaveTransitionMetadta()
		]);

export const shortFadeOutAnimation: AnimationTriggerMetadata =
	trigger('shortFadeOutAnimation',
		[
			transition(':enter',
				[
					style({ opacity: 1 })
				]
			),
			getFadeLeaveTransitionMetadta(true)
		]);

export const fadeVisibilityAnimation: AnimationTriggerMetadata =
	trigger('fadeVisibilityAnimation',
		[
			state('true', style({ opacity: 1, visibility: 'visible' })),
			state('false', style({ opacity: 0, visibility: 'hidden' })),
			transition('true <=> false', animate(AnimationsConstants.ANIMATION_LONG_TIMING))
		]);
