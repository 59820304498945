import
{
    AfterViewInit, ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, HostListener,
    isDevMode, NgZone, OnDestroy, OnInit, Renderer2, ViewChild
} from '@angular/core';
import { outletAnimation } from './animations/outlet.animation';
import { ModalMessageService } from './controls/modal-message/services/modal-message.service';
import { ModalButtonType, ModalIconType, ModalResultType, ModalType } from './controls/modal-message/modal-message.component';
import { Constants, IApiResponse, IApiResponseData, RouteType } from './utils/globals';
import { BaseView } from './base/classes/base-view';
import { fadeInOutAnimation, fadeOutAnimation, fadeSlideDownInOutAnimation, shortFadeInOutAnimation } from './animations/fade.animation';
import { foldFadeAnimation, foldHorizontalAnimation } from './animations/fold.animation';
import { LoginModel } from './user/login/model/login.model';
import { AppSettingsService } from './services/app-settings.service';
import { DropdownPosition } from './base/components/dropdown-base.component';
import { RoutingHistoryService } from './services/routing-history.service';
import { slideInOutAnimation } from './animations/slide.animation';
import { AppMenuItem, AppMenuService } from './services/app-menu.service';
import ContguardPlugin from '../plugins/contguard.plugin';
import { UserInsightPermission } from './user/login/model/login-model.class';
import { Subscription } from 'rxjs';
import { MiniControlCenterModel } from './mini-control-center/mini-control-center/model/mini-control-center.model';
import { Router, RouterOutlet } from '@angular/router';
import { MiniControlCenterComponent } from './mini-control-center/mini-control-center/mini-control-center.component';
import { DisplayControlCenterAlertData } from './mini-control-center/control-center-alert/model/control-center-alert-model.class';
import { GlobalsPipe } from './pipes/globals.pipe';
import { NgTemplateOutlet } from '@angular/common';
import { LoaderComponent } from './controls/loader/loader.component';
import { DropdownDirective } from './directives/dropdown.directive';
import { UserMenuComponent } from './controls/user-menu/user-menu.component';
import { SettingsComponent } from './settings/settings.component';
import { ElementResizedDirective } from './directives/element-resized.directive';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css'],
    animations: [outletAnimation, fadeOutAnimation, shortFadeInOutAnimation, foldHorizontalAnimation, foldFadeAnimation, slideInOutAnimation,
        fadeInOutAnimation, fadeSlideDownInOutAnimation],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgTemplateOutlet, RouterOutlet, GlobalsPipe, LoaderComponent, DropdownDirective, UserMenuComponent, SettingsComponent, ElementResizedDirective]
})
export class AppComponent extends BaseView implements OnInit, OnDestroy, AfterViewInit
{
    // #region Private Constants

    private readonly RIPPLE_EFFECT_CLASS: string = 'ripple-effect';
    private readonly RIPPLE_EFFECT_SELECTOR: string = `.${this.RIPPLE_EFFECT_CLASS}`;
    private readonly RIPPLE_CLASSNAME: string = 'ripple';
    private readonly RIPPLE_FILL_CLASSNAME: string = 'ripple-fill';
    private readonly RIPPLE_CENTER_CLASSNAME: string = 'ripple-center';
    private readonly RIPPLE_SMALL_CLASSNAME: string = 'ripple-small';
    private readonly RIPPLE_INVERSE_COLOR_CLASSNAME: string = 'ripple-inverse-color';
    private readonly RIPPLE_INVERSE_CLASSNAME: string = 'inverse';
    private readonly RIPPLE_MIN_SIZE: number = 18;
    private readonly MOBILE_CLASSNAME: string = 'mobile';
    private readonly NEW_ALERTS_GET_INTERVAL: number = 1000 * 60 * 2;

    // #endregion

    // #region Private Members

    private _isExitModalMessgaeShowing: boolean = false;
    private _newAlertsSubscription: Subscription | null = null;
    private _preloadFontsTimeoutHandleId: NodeJS.Timeout | null = null;
    private _newAlertsTimeoutHandleId: NodeJS.Timeout | null = null;
    private _appMenuItems: AppMenuItem[] = [];
    private _extraAppMenuItems: AppMenuItem[] = [];
    private _audioElement: HTMLAudioElement = new Audio('assets/sounds/alert.mp3');
    private _newAlerts: DisplayControlCenterAlertData[] = [];
    private _showAlertMessage: boolean = false;
    private _currentRequestAlertDate: Date | null = null;
    private _lastRequestAlertDate: Date | null = null;

    @ViewChild('alertsButton', { read: ElementRef<HTMLButtonElement>, static: false })
    private _alertsButtonElement: ElementRef<HTMLButtonElement> | undefined = undefined;

    @ViewChild('settingsButton', { read: ElementRef<HTMLButtonElement>, static: false })
    private _settingsButtonElement: ElementRef<HTMLButtonElement> | undefined = undefined;

    // #endregion

    // #region Properties

    public showSettingsMenu: boolean = false;
    public showExtraMenuItems: boolean = false;

    public get showAlertMessage(): boolean
    {
        return this._showAlertMessage
    }

    public get appSettingsService(): AppSettingsService
    {
        return this._appSettingsService;
    }

    public get loginModel(): LoginModel
    {
        return this._loginModel;
    }

    public get DropdownPosition()
    {
        return DropdownPosition;
    }

    public get activeRouteType(): RouteType
    {
        return this._appMenuService.activeRouteType;
    }

    public get appMenuItems(): AppMenuItem[]
    {
        return this._appMenuItems;
    }

    public get extraAppMenuItems(): AppMenuItem[]
    {
        return this._extraAppMenuItems;
    }

    public get RouteType()
    {
        return RouteType;
    }

    public get miniControlCenterModel(): MiniControlCenterModel
    {
        return this._miniControlCenterModel;
    }

    public get newAlerts(): DisplayControlCenterAlertData[]
    {
        return this._newAlerts;
    }

    // #endregion

    // #region Constructor

    constructor(private _appSettingsService: AppSettingsService, private _changeDetectorRef: ChangeDetectorRef, private _modalMessageService: ModalMessageService,
        private _ngZone: NgZone, private _renderer: Renderer2, private _loginModel: LoginModel, private _routingHistoryService: RoutingHistoryService,
        private _appMenuService: AppMenuService, private _miniControlCenterModel: MiniControlCenterModel, private _router: Router)
    {
        super();

        if (Constants.IS_MOBILE)
        {
            document.getElementsByTagName('html')[0].classList.add(this.MOBILE_CLASSNAME);
        }

        this.handleRippleEffects();

        this._appMenuService.appMenuUpdatedObservable.subscribe((menuInitialized: boolean) =>
        {
            if (menuInitialized)
            {
                this._appMenuItems = [...this._appMenuService.allAppMenuItems];
                this._extraAppMenuItems = [];

                this.initializeNewAlerts();
            }

            this.updateCurrentAppMenuItemPosition();
            this._changeDetectorRef.detectChanges();
        });

        this._appMenuItems = [...this._appMenuService.allAppMenuItems];
    }

    // #endregion

    // #region Event Handlers

    @HostListener('window:contextmenu', ['$event']) onWindowContextMenu(event: PointerEvent): void
    {
        if (!isDevMode)
        {
            event.preventDefault();
        }
    }

    public async ngOnInit(): Promise<void>
    {
        this.initializeNewAlerts();

        if (document.fonts === undefined)
        {
            return;
        }

        this._preloadFontsTimeoutHandleId = setTimeout(() =>
        {
            this._preloadFontsTimeoutHandleId = null;
            this.setFontsPreloadDone();
        }, Constants.LOADING_ELEMENTS_TIMEOUT_DURATION);

        await document.fonts.ready;

        if (this.clearPreloadFontTimeout())
        {
            this.setFontsPreloadDone();
        }
    }

    public ngOnDestroy(): void
    {
        this.clearPreloadFontTimeout();
        this.clearNewAlertsTimeout();
        this.clearNewAlertsSubscription();
    }

    public ngAfterViewInit(): void
    {
        setTimeout(() => ContguardPlugin.hideSplashScreen(), Constants.SPLASH_DELAY_DURATION);

        ContguardPlugin.addListener('backButtonPressed', () =>
        {
            if (this._isExitModalMessgaeShowing)
            {
                return;
            }

            if (!this._routingHistoryService.canNavigateBack)
            {
                this._ngZone.run(() =>
                {
                    this._isExitModalMessgaeShowing = true;

                    this._modalMessageService.show(
                        {
                            title: Constants.APP_TITLE,
                            message: 'Are you sure you want to close the application?',
                            modalType: ModalType.Regular,
                            modalIcon: ModalIconType.Question,
                            modalButton: ModalButtonType.YesNo
                        }).then((modalResult: ModalResultType) =>
                        {
                            if (modalResult === ModalResultType.Yes)
                            {
                                ContguardPlugin.exitApp();
                            }

                            this._isExitModalMessgaeShowing = false;
                        });
                });
            }
            else
            {
                this._routingHistoryService.navigateBack();
            }
        });
    }

    public onCloseAlertMessageButtonClick(event: MouseEvent): void
    {
        event.stopImmediatePropagation();
        event.preventDefault();

        this._showAlertMessage = false;
    }

    public onSettingsMenuClosed(element: HTMLElement | null): void
    {
        if (element === null || !this._settingsButtonElement?.nativeElement?.contains(element))
        {
            this.showSettingsMenu = false;
        }
    }

    public onAppMenuItemMouseEnter(appMenuItem: AppMenuItem): void
    {
        appMenuItem.isExpanded = true;
        this._changeDetectorRef.detectChanges();
    }

    public onAppMenuItemMouseLeave(appMenuItem: AppMenuItem): void
    {
        appMenuItem.isExpanded = false;
        this._changeDetectorRef.detectChanges();
    }

    public onSidebarMenuResized(sidebarMenuContainerElement: HTMLElement): void
    {
        if (sidebarMenuContainerElement.firstElementChild === null)
        {
            return;
        }

        const menuItemHeight: number = sidebarMenuContainerElement.firstElementChild.clientHeight;
        const menuItemsCount: number = this._appMenuItems.length + this._extraAppMenuItems.length;

        if (sidebarMenuContainerElement.clientHeight < menuItemsCount * menuItemHeight)
        {
            const overflowMenuItems: number = menuItemsCount - Math.floor(sidebarMenuContainerElement.clientHeight / menuItemHeight);
            if (overflowMenuItems !== this._extraAppMenuItems.length)
            {
                const allMenuItems: AppMenuItem[] = [...this._appMenuService.allAppMenuItems];
                this._extraAppMenuItems = [...allMenuItems.splice(allMenuItems.length - overflowMenuItems, overflowMenuItems)];
                this._appMenuItems = [...allMenuItems];
                this.updateCurrentAppMenuItemPosition();
                this._changeDetectorRef.detectChanges();
            }
        }
        else if (this._extraAppMenuItems.length > 0)
        {
            this._appMenuItems = [...this._appMenuItems, ...this._extraAppMenuItems];
            this._extraAppMenuItems = [];
            this._changeDetectorRef.detectChanges();
        }
    }

    public onAppMenuItemClick(routeType: RouteType): void
    {
        this.showExtraMenuItems = false;
        this._appMenuService.handleAppMenuItemClick(routeType);
    }

    public async onAlertMessageClick(routerOutlet: RouterOutlet): Promise<void>
    {
        this._showAlertMessage = false;

        if (!(routerOutlet.component instanceof MiniControlCenterComponent))
        {
            await this._router.navigate([`/${RouteType.MiniControlCenter}`]);
        }

        if (routerOutlet.component instanceof MiniControlCenterComponent)
        {
            routerOutlet.component.selectAlert(this._newAlerts[0].id!);
        }
    }

    // #endregion

    // #region Private Methods

    private getNewAlertsByTimeout(): void
    {
        this.clearNewAlertsTimeout();
        this._newAlertsTimeoutHandleId = setTimeout(() => this.getNewAlerts(), this.NEW_ALERTS_GET_INTERVAL);
    }

    private initializeNewAlerts(): void
    {
        if (Constants.IS_MOBILE || this._loginModel.accessToken === null)
        {
            return;
        }

        this.clearNewAlertsTimeout();
        this.clearNewAlertsSubscription();

        this._currentRequestAlertDate = null;
        this._lastRequestAlertDate = null;

        if (!this._loginModel.userInsightPermissions.includes(UserInsightPermission.MiniControlCenter))
        {
            return;
        }

        this._miniControlCenterModel.alertsResponseObservable.subscribe((response: IApiResponse) =>
        {
            if (response.isSuccess)
            {
                if (response.isComplete)
                {
                    if (this._miniControlCenterModel.newAlerts.length > 0)
                    {
                        this._newAlerts = [...this._miniControlCenterModel.newAlerts];
                        this._showAlertMessage = true;

                        try
                        {
                            this._audioElement.play();
                        }
                        catch
                        {
                        }

                        if (this._alertsButtonElement !== undefined)
                        {
                            this._alertsButtonElement.nativeElement.classList.remove(Constants.ACTIVE_CLASSNAME);
                            setTimeout(() => this._alertsButtonElement!.nativeElement.classList.add(Constants.ACTIVE_CLASSNAME));
                        }
                    }

                    this._lastRequestAlertDate = this._currentRequestAlertDate;
                }

                this._changeDetectorRef.detectChanges();
            }

            if (!response.isSuccess || response.isComplete)
            {
                this.getNewAlertsByTimeout();
            }
        });

        this.getNewAlerts();
    }

    private getNewAlerts(): void
    {
        this.clearNewAlertsSubscription();

        this._currentRequestAlertDate = new Date();

        if (this._lastRequestAlertDate === null)
        {
            this._newAlertsSubscription = this._miniControlCenterModel.getManagerItems().subscribe();
            this._changeDetectorRef.detectChanges();
        }
        else
        {
            this._newAlertsSubscription = this._miniControlCenterModel.getAlertsCountBetweenDates(
                this._lastRequestAlertDate, new Date()).subscribe((response: IApiResponseData<number>) =>
                {
                    if (response.isSuccess)
                    {
                        this._lastRequestAlertDate = this._currentRequestAlertDate;

                        if (response.data !== undefined && response.data > 0)
                        {
                            this.clearNewAlertsSubscription();

                            this._currentRequestAlertDate = new Date();
                            this._newAlertsSubscription = this._miniControlCenterModel.getManagerItems().subscribe();
                            this._changeDetectorRef.detectChanges();
                        }
                        else
                        {
                            this.getNewAlertsByTimeout();
                        }
                    }
                });
        }
    }

    private updateCurrentAppMenuItemPosition(): void
    {
        const currentAppMenuItem: AppMenuItem | undefined = this._extraAppMenuItems.find((appMenuItem: AppMenuItem) =>
            appMenuItem.routeType === this.activeRouteType);

        if (currentAppMenuItem !== undefined)
        {
            this._extraAppMenuItems.splice(this._extraAppMenuItems.indexOf(currentAppMenuItem), 1);
            this._extraAppMenuItems.unshift(this._appMenuItems.pop()!);
            this._appMenuItems.push(currentAppMenuItem);
        }
    }

    private addEventListnerForRippleElement(rippleEffectElement: HTMLElement): void
    {
        if (Constants.IS_MOBILE)
        {
            rippleEffectElement.addEventListener("touchstart", (event: TouchEvent) =>
            {
                this.createElementRippleEffect(event.currentTarget as HTMLElement, event.changedTouches[0].pageX, event.changedTouches[0].pageY);
            });
        }
        else
        {
            rippleEffectElement.addEventListener("mousedown", (event: MouseEvent) =>
            {
                this.createElementRippleEffect(event.currentTarget as HTMLElement, event.pageX, event.pageY);
            });
        }
    }

    private handleRippleEffects(): void
    {
        const observer: MutationObserver = new MutationObserver((mutations: MutationRecord[]) =>
        {
            const addedNodes: Node[] = [];
            for (const mutation of mutations)
            {
                mutation.addedNodes.forEach((node: Node) =>
                {
                    if (node instanceof HTMLElement)
                    {
                        if (node.classList.contains(this.RIPPLE_EFFECT_CLASS))
                        {
                            if (!addedNodes.includes(node))
                            {
                                addedNodes.push(node);
                                this.addEventListnerForRippleElement(node);
                            }
                        }
                        else
                        {
                            node.querySelectorAll(this.RIPPLE_EFFECT_SELECTOR).forEach((rippleEffectElement: Element) =>
                            {
                                if (!addedNodes.includes(rippleEffectElement))
                                {
                                    addedNodes.push(rippleEffectElement);
                                    this.addEventListnerForRippleElement(rippleEffectElement as HTMLElement);
                                }
                            });
                        }
                    }
                });
            }
        });

        observer.observe(document.body, { attributes: false, childList: true, subtree: true, characterData: false });
    }

    private createElementRippleEffect(pressedElement: HTMLElement, x: number, y: number): void
    {
        const pressedElementRect: DOMRect = pressedElement.getBoundingClientRect();

        const isRippleFill: boolean = pressedElement.classList.contains(this.RIPPLE_FILL_CLASSNAME);
        const isRippleCenter: boolean = pressedElement.classList.contains(this.RIPPLE_CENTER_CLASSNAME);
        const isRippleInverseColor: boolean = pressedElement.classList.contains(this.RIPPLE_INVERSE_COLOR_CLASSNAME);
        const isRippleSmall: boolean = pressedElement.classList.contains(this.RIPPLE_SMALL_CLASSNAME);

        const rippleElement: HTMLElement = this._renderer.createElement('span');

        const pressedElementCssStyleDeclaration: CSSStyleDeclaration = getComputedStyle(pressedElement);
        if (pressedElementCssStyleDeclaration.position === 'static')
        {
            pressedElement.style.position = 'relative';
        }

        let diameter: number = this.RIPPLE_MIN_SIZE / (isRippleSmall ? 2 : 1);
        if (isRippleFill)
        {
            pressedElement.style.overflow = 'hidden';
            rippleElement.classList.add(this.RIPPLE_FILL_CLASSNAME);
            diameter = Math.max(pressedElement.clientWidth, pressedElement.clientHeight);
        }

        rippleElement.style.width = rippleElement.style.height = `${diameter}px`;
        rippleElement.style.left = `${isRippleCenter ? (pressedElementRect.width - diameter) / 2 : x - pressedElementRect.left - diameter / 2}px`;
        rippleElement.style.top = `${isRippleCenter ? (pressedElementRect.height - diameter) / 2 : y - pressedElementRect.top - diameter / 2}px`;

        rippleElement.classList.add(this.RIPPLE_CLASSNAME);
        if (isRippleInverseColor)
        {
            rippleElement.classList.add(this.RIPPLE_INVERSE_CLASSNAME);
        }

        rippleElement.onanimationend = () =>
        {
            if (isRippleFill)
            {
                pressedElement.style.overflow = '';
            }

            rippleElement.remove();
        }

        pressedElement.appendChild(rippleElement);
    }

    private clearNewAlertsSubscription(): void
    {
        if (this._newAlertsSubscription !== null)
        {
            this._newAlertsSubscription.unsubscribe();
            this._newAlertsSubscription = null;
        }
    }

    private clearPreloadFontTimeout(): boolean
    {
        if (this._preloadFontsTimeoutHandleId === null)
        {
            return false;
        }

        clearTimeout(this._preloadFontsTimeoutHandleId);
        this._preloadFontsTimeoutHandleId = null;

        return true;
    }

    private clearNewAlertsTimeout(): void
    {
        if (this._newAlertsTimeoutHandleId !== null)
        {
            clearTimeout(this._newAlertsTimeoutHandleId);
            this._newAlertsTimeoutHandleId = null;
        }
    }

    private setFontsPreloadDone(): void
    {
        this._viewIsInitialized = true;
        this._changeDetectorRef.detectChanges();

        this.setViewState(true);
        this._changeDetectorRef.markForCheck();
    }

    // #endregion
}
