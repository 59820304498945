<!DOCTYPE html>

<div class="barcode-scanner-frame">
    <div class="barcode-scanner">
        <div class="barcode-scanner-corners"></div>
    </div>
</div>
<button type="button" class="image-button close-button" (click)="onCloseButtonClick()"><i class="icon-cancel"></i></button>
@if (isTorchAvailable) {
<button type="button" class="image-button torch-button" [class.on]="isTorchOn" (click)="onTorchButtonClick()">
    <i [class]="isTorchOn ? 'icon-lightbulb-on' : 'icon-lightbulb'"></i>
</button>
}

