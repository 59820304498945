import { Perimeter } from "../../demurrage/model/demurrage-model.class";

export enum DemurrageColumnType { CarrierName, FromDate, ToDate, PortsCount, Delete }

export class Carrier
{
    public carrierId: number | null = null;
    public carrierName: string | null = null;
    public carrierType: string | null = null;
}

export class DemurrageLists
{
    public perimeters: Perimeter[] = [];
    public carriers: Carrier[] = [];
}

export const DemurrageConstants =
{
    MISSING_VALUE: 'Unknown',
    NO_VALUE: 'None'
}