<!DOCTYPE html>

<div class="page-missing">
	<div class="page-missing-icon icon-face-frown"></div>
	<div class="page-missing-text">
		Whoops!
		<p>Page not found</p>
	</div>
	<div class="page-missing-text-remark">Sorry, we can't seem to find the page you are looking for.<br /> Try going back to the previous page.</div>
</div>
