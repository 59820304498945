export class VirtualListInfo
{
    // #region Properties

	public scrollTop: number | null = null;
	public sortColumn: number | null = null;
	public sortOrder: number | null = null;
	public filter: string | null = null;
	public scrollToItem: any | null = null;
	public itemHeight: any | null = null;

    // #endregion

    // #region Constructors
	constructor()
	{
		this.clear();
	}

	// #endregion

    // #region Public Methods

	public clear(): void
	{
		this.scrollTop = 0;
		this.sortColumn = -1;
		this.sortOrder = 0;
		this.filter = '';
		this.itemHeight = null;
	}

	// #endregion
}
