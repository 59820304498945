import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { LoginModel } from "../../user/login/model/login.model";
import { AppSettingsService } from "../../services/app-settings.service";
import { DateTimeFormatType, Utils } from "../../utils/utils";
import { MonitoringItemsBaseModel } from "../../base/models/monitoring-items-base.model";
import
    {
        DemurrageContractDeviationType, DemurrageMonitoringDataLists, DemurrageMonitoringFilters,
        ShipmentMonitoringDemurrageInfo
    } from "./demurrage-monitoring-model.class";
import { Constants, IApiResponse } from "../../utils/globals";
import { Observable, Observer, forkJoin } from "rxjs";
import { HttpHelper } from "../../utils/http-helper";
import { cloneDeep, isEqual } from "lodash";
import { DemurrageLists } from "../../demurrage-manager/demurrage-manager/model/demurrage-manager-model.class";
import { ManagerConstants, MonitoringUtils } from "../../utils/monitoring-utils";
import { MonitoringSegmentType } from "../../base/models/manager-base-model.class";
import { DemurrageContract, DemurrageContractPort, Perimeter } from "../../demurrage-manager/demurrage/model/demurrage-model.class";
import { DisplayShipmentMonitoring, ShipmentMonitoring } from "../../shipments-monitoring/shipment-monitoring/model/shipment-monitoring-model.class";

@Injectable({ providedIn: 'root' })
export class DemurrageMonitoringModel extends MonitoringItemsBaseModel<DisplayShipmentMonitoring, DemurrageMonitoringFilters, DemurrageMonitoringDataLists,
    ShipmentMonitoringDemurrageInfo>
{
    // #region Properties

    public override get lastSearchMonitoringFilters(): DemurrageMonitoringFilters | null
    {
        return this._appSettingsService.appSettings.demurrageMonitoringSettingsData.lastSearchDemurrageMonitoringFilters;
    }

    public override set lastSearchMonitoringFilters(value: DemurrageMonitoringFilters | null)
    {
        this._appSettingsService.appSettings.demurrageMonitoringSettingsData.lastSearchDemurrageMonitoringFilters = value;
    }

    // #endregion

    // #region Contructors

    constructor(private _httpClient: HttpClient, private _appSettingsService: AppSettingsService, _loginModel: LoginModel)
    {
        super(_loginModel, DemurrageMonitoringFilters, DemurrageMonitoringDataLists, ShipmentMonitoringDemurrageInfo);
    }

    // #endregion

    // #region Public Methods

    public override getMonitoringItemKey(monitoringItem: DisplayShipmentMonitoring): number | null
    {
        return monitoringItem.shipmentKey;
    }

    public override getMonitoringItemStatusId(monitoringItem: DisplayShipmentMonitoring): number | null
    {
        return monitoringItem.statusId;
    }

    public override getMonitoringItemLocation(monitoringItem: DisplayShipmentMonitoring): any
    {
        return { lat: monitoringItem.currentLat, lng: monitoringItem.currentLon };
    }

    public updateDisplayShipmentMonitoringProperties(displayShipmentMonitoring: DisplayShipmentMonitoring): void
    {
        MonitoringUtils.updateDisplayShipmentMonitoringProperties(displayShipmentMonitoring, this._loginModel.userInfo.isDemo);

        displayShipmentMonitoring.currentPortStartTimeFormatted = displayShipmentMonitoring.currentPortStartTime !== null ?
            Utils.getFormattedDateTime(new Date(displayShipmentMonitoring.currentPortStartTime), DateTimeFormatType.DateTime) :
            ManagerConstants.MISSING_VALUE;

        displayShipmentMonitoring.demurragePortDuration =
            Utils.getMoment(displayShipmentMonitoring.currentPortEndTime ?? new Date()).diff(Utils.getMoment(
                displayShipmentMonitoring.currentPortStartTime), 'days');

        displayShipmentMonitoring.demurragePortDurationFormatted =
            Utils.formatDurationUnits(displayShipmentMonitoring.demurragePortDuration, 'day');
    }

    public override getMonitoringItems(): Observable<IApiResponse>
    {
        this._isBusy = true;

        return new Observable((observer: Observer<IApiResponse>) =>
        {
            const joinObservables =
            {
                demurrageShipmentsMonitoring: this._httpClient.get<ShipmentMonitoring[]>('demurrage-monitoring/shipments',
                    { headers: HttpHelper.GetHttpFormUrlencodedHeaders() }),
                demurrages: this._httpClient.get<DemurrageContract[]>('demurrage-manager/demurrages',
                    { headers: HttpHelper.GetHttpFormUrlencodedHeaders() }),
                demurrageLists: this._httpClient.get<DemurrageLists>('demurrage-manager/demurrage/lists',
                    { headers: HttpHelper.GetHttpFormUrlencodedHeaders() })
            };

            forkJoin(joinObservables).subscribe(
                {
                    next: (join) =>
                    {
                        const perimeterIdToPerimeterMap: Map<string, Perimeter> = new Map();
                        for (const perimeter of join.demurrageLists.perimeters)
                        {
                            perimeterIdToPerimeterMap.set(perimeter.perimeterId!, perimeter);
                        }

                        const carrierIdToCarrierNameMap: Map<number, string> = new Map();
                        for (const carrier of join.demurrageLists.carriers)
                        {
                            carrierIdToCarrierNameMap.set(carrier.carrierId!, carrier.carrierName!);
                        }

                        this._monitoringItems = [];

                        for (const shipmentMonitoring of join.demurrageShipmentsMonitoring)
                        {
                            const displayShipmentMonitoring: DisplayShipmentMonitoring = new DisplayShipmentMonitoring();
                            Utils.copyObjectByTargetProperties(shipmentMonitoring, displayShipmentMonitoring);
                            this._monitoringItems.push(displayShipmentMonitoring);

                            this.updateDisplayShipmentMonitoringProperties(displayShipmentMonitoring);

                            displayShipmentMonitoring.carrierName = carrierIdToCarrierNameMap.get(displayShipmentMonitoring.carrierId!)!;

                            this.initializeShipmentDemurrageContract(displayShipmentMonitoring, join.demurrages);
                            this.initializeShipmentPerimeter(displayShipmentMonitoring, perimeterIdToPerimeterMap);
                        }

                        this._monitoringItemsSourceInfo = this.updateMonitoringItemsInfo(this._monitoringItems);

                        this._monitoringDataLists = this.updateMonitoringDataLists(this._monitoringItems);
                        this._filteredMonitoringItems = this.updateMonitoringItemsByFilters();
                        this.updateLastSearchMonitoringFilters();

                        this.updateMonitoringDataListsByFilters();

                        this._isBusy = false;

                        observer.next({ isSuccess: true });
                        observer.complete();
                    },
                    error: (error: HttpErrorResponse) =>
                    {
                        this._isBusy = false;
                        console.error(error);

                        observer.next({ isSuccess: false, message: Constants.DATA_SERVICE_ERROR_STRING });
                        observer.complete();
                    }
                });
        });
    }

    // #endregion

    // #region Protected Methods

    protected override filterShipmentsDataLists(): void
    {
        if (this._editMonitoringFilters.selectedShipmentMonitoringKeys.length > 0)
        {
            this._filteredMonitoringDataLists.shipmentMonitoringKeys = [...this._monitoringDataLists.shipmentMonitoringKeys];
        }
        else if (this._editMonitoringFilters.selectedShipmentMonitoringCargosUnits.length > 0)
        {
            this._filteredMonitoringDataLists.shipmentMonitoringCargosUnits = [...this._monitoringDataLists.shipmentMonitoringCargosUnits];
        }
        else if (this._editMonitoringFilters.selectedShipmentMonitoringTrailersNumbers.length > 0)
        {
            this._filteredMonitoringDataLists.shipmentMonitoringTrailersNumbers = [...this._monitoringDataLists.shipmentMonitoringTrailersNumbers];
        }
        else if (this._editMonitoringFilters.selectedShipmentMonitoringCarriers.length > 0)
        {
            this._filteredMonitoringDataLists.shipmentMonitoringCarriers = [...this._monitoringDataLists.shipmentMonitoringCarriers];
        }
        else if (this._editMonitoringFilters.selectedShipmentMonitoringPorts.length > 0)
        {
            this._filteredMonitoringDataLists.shipmentMonitoringPorts = [...this._monitoringDataLists.shipmentMonitoringPorts];
        }
        else if (this._editMonitoringFilters.selectedShipmentMonitoringOriginsRoutes.length > 0)
        {
            this._filteredMonitoringDataLists.shipmentMonitoringOriginsRoutes = [...this._monitoringDataLists.shipmentMonitoringOriginsRoutes];
        }
        else if (this._editMonitoringFilters.selectedShipmentMonitoringDestinationsRoutes.length > 0)
        {
            this._filteredMonitoringDataLists.shipmentMonitoringDestinationsRoutes = [...this._monitoringDataLists.shipmentMonitoringDestinationsRoutes];
        }
        else if (this._editMonitoringFilters.selectedShipmentMonitoringSegments.length > 0)
        {
            this._filteredMonitoringDataLists.shipmentMonitoringSegments = [...this._monitoringDataLists.shipmentMonitoringSegments];
        }
    }

    protected override updateMonitoringInfoGeneralData(monitoringItemsInfo: ShipmentMonitoringDemurrageInfo, monitoringItems: DisplayShipmentMonitoring[]): void
    {
        monitoringItemsInfo.count = monitoringItems.length;
        monitoringItemsInfo.routesCount = MonitoringUtils.getShipmentsRoutsCount(monitoringItems);
    }

    protected override updateMonitoringItemsInfo(monitoringItems: DisplayShipmentMonitoring[]): ShipmentMonitoringDemurrageInfo
    {
        const monitoringItemsInfo: ShipmentMonitoringDemurrageInfo = new ShipmentMonitoringDemurrageInfo();

        for (const shipmentMonitoring of monitoringItems)
        {
            this.updateShipmentsMonitoringInfoDemurrageStatus(monitoringItemsInfo, shipmentMonitoring);
        }

        this.updateMonitoringInfoGeneralData(monitoringItemsInfo, monitoringItems);

        return monitoringItemsInfo;
    }

    protected override updateMonitoringItemsByFilters(demurrageMonitoringFilters: DemurrageMonitoringFilters | null = null): DisplayShipmentMonitoring[]
    {
        if (demurrageMonitoringFilters === null)
        {
            demurrageMonitoringFilters = this._monitoringFilters;
        }

        if (isEqual(demurrageMonitoringFilters, new DemurrageMonitoringFilters()))
        {
            Utils.copyObjectByTargetProperties(this._monitoringItemsSourceInfo, this._monitoringItemsInfo);
            return [...this._monitoringItems];
        }
        else
        {
            this._monitoringItemsInfo = new ShipmentMonitoringDemurrageInfo();

            if (demurrageMonitoringFilters.selectedShipmentMonitoringDemurrageStatuses.length > 0)
            {
                if (!demurrageMonitoringFilters.selectedShipmentMonitoringDemurrageStatuses.includes(DemurrageContractDeviationType.Late))
                {
                    demurrageMonitoringFilters.minDeviation = null;
                }

                if (!demurrageMonitoringFilters.selectedShipmentMonitoringDemurrageStatuses.includes(DemurrageContractDeviationType.Early))
                {
                    demurrageMonitoringFilters.maxBeforeDeviation = null;
                }
            }

            let filteredMonitoringItems: DisplayShipmentMonitoring[] = this._monitoringItems.filter((shipmentMonitoring: DisplayShipmentMonitoring) =>
            {
                if (!this.isMonitoringItemFilteredByPropertyValue(shipmentMonitoring.shipmentKey, demurrageMonitoringFilters!.selectedShipmentMonitoringKeys) ||
                    !this.isMonitoringItemFilteredByPropertyValue(shipmentMonitoring.containerId, demurrageMonitoringFilters!.selectedShipmentMonitoringCargosUnits) ||
                    !this.isMonitoringItemFilteredByPropertyValue(shipmentMonitoring.carrierName, demurrageMonitoringFilters!.selectedShipmentMonitoringCarriers) ||
                    !this.isMonitoringItemFilteredByPropertyValue(shipmentMonitoring.perimeterName, demurrageMonitoringFilters!.selectedShipmentMonitoringPorts) ||
                    !this.isMonitoringItemFilteredByPropertyValue(shipmentMonitoring.originName, demurrageMonitoringFilters!.selectedShipmentMonitoringOriginsRoutes) ||
                    !this.isMonitoringItemFilteredByPropertyValue(shipmentMonitoring.destinationName, demurrageMonitoringFilters!.selectedShipmentMonitoringDestinationsRoutes) ||
                    !this.isMonitoringItemFilteredByPropertyValue(shipmentMonitoring.currentSegment, demurrageMonitoringFilters!.selectedShipmentMonitoringSegments) ||
                    !this.isMonitoringItemFilteredByMinimumNumericPropertyValue(shipmentMonitoring.demurragePortDuration, demurrageMonitoringFilters!.minDuration) ||
                    !this.isMonitoringItemFilteredByMinimumNumericPropertyValue(shipmentMonitoring.demurrageDeviation, demurrageMonitoringFilters!.minDeviation))
                {
                    return false;
                }

                this.updateShipmentsMonitoringInfoDemurrageStatus(this._monitoringItemsInfo, shipmentMonitoring);

                if (!this.isMonitoringItemFilteredByMaximumNumericPropertyValue(shipmentMonitoring.demurrageDeviation !== null ?
                    (shipmentMonitoring.demurrageDeviation > 0 ? null : -shipmentMonitoring.demurrageDeviation) : null, demurrageMonitoringFilters!.maxBeforeDeviation !== null ?
                    demurrageMonitoringFilters!.maxBeforeDeviation : null))
                {
                    return false;
                }

                if (!this.isMonitoringItemFilteredByPropertyValue(shipmentMonitoring.demurrageDeviationId,
                    demurrageMonitoringFilters!.selectedShipmentMonitoringDemurrageStatuses))
                {
                    return false;
                }

                return true;
            });

            this.updateMonitoringInfoGeneralData(this._monitoringItemsInfo, filteredMonitoringItems);

            return filteredMonitoringItems;
        }
    }

    protected override updateMonitoringDataLists(monitoringItems: DisplayShipmentMonitoring[]): DemurrageMonitoringDataLists
    {
        const monitoringDataLists: DemurrageMonitoringDataLists = new DemurrageMonitoringDataLists();
        const shipmentMonitoring: DisplayShipmentMonitoring = new DisplayShipmentMonitoring();

        monitoringDataLists.shipmentMonitoringKeys = this.getMonitoringItemsPropertyList(monitoringItems,
            Utils.getPropertyNameof<DisplayShipmentMonitoring>(shipmentMonitoring, shipmentMonitoring => shipmentMonitoring.shipmentKey));

        monitoringDataLists.shipmentMonitoringCargosUnits = this.getMonitoringItemsPropertyList(monitoringItems,
            Utils.getPropertyNameof<DisplayShipmentMonitoring>(shipmentMonitoring, shipmentMonitoring => shipmentMonitoring.containerId));

        monitoringDataLists.shipmentMonitoringTrailersNumbers = this.getMonitoringItemsPropertyList(monitoringItems,
            Utils.getPropertyNameof<DisplayShipmentMonitoring>(shipmentMonitoring, shipmentMonitoring => shipmentMonitoring.trailerNumber));

        monitoringDataLists.shipmentMonitoringCarriers = this.getMonitoringItemsPropertyList(monitoringItems,
            Utils.getPropertyNameof<DisplayShipmentMonitoring>(shipmentMonitoring, shipmentMonitoring => shipmentMonitoring.carrierName));

        monitoringDataLists.shipmentMonitoringPorts = this.getMonitoringItemsPropertyList(monitoringItems,
            Utils.getPropertyNameof<DisplayShipmentMonitoring>(shipmentMonitoring, shipmentMonitoring => shipmentMonitoring.perimeterName));

        monitoringDataLists.shipmentMonitoringOriginsRoutes = this.getMonitoringItemsPropertyList(monitoringItems,
            Utils.getPropertyNameof<DisplayShipmentMonitoring>(shipmentMonitoring, shipmentMonitoring => shipmentMonitoring.originName));

        monitoringDataLists.shipmentMonitoringDestinationsRoutes = this.getMonitoringItemsPropertyList(monitoringItems,
            Utils.getPropertyNameof<DisplayShipmentMonitoring>(shipmentMonitoring, shipmentMonitoring => shipmentMonitoring.destinationName));

        monitoringDataLists.shipmentMonitoringSegments = this.getMonitoringItemsPropertyList(monitoringItems,
            Utils.getPropertyNameof<DisplayShipmentMonitoring>(shipmentMonitoring, shipmentMonitoring => shipmentMonitoring.currentSegment));

        this.sortMonitoringDataLists(monitoringDataLists);

        return monitoringDataLists;
    }

    // #endregion

    // #region Private Methods

    private updateShipmentsMonitoringInfoDemurrageStatus(monitoringItemsInfo: ShipmentMonitoringDemurrageInfo, shipmentMonitoring: DisplayShipmentMonitoring): void
    {
        if (shipmentMonitoring.demurrageDeviationId === DemurrageContractDeviationType.Late)
        {
            monitoringItemsInfo.deviationLateCount++;
        }
        else if (shipmentMonitoring.demurrageDeviationId === DemurrageContractDeviationType.Early)
        {
            monitoringItemsInfo.deviationEarlyCount++;
        }
        else
        {
            monitoringItemsInfo.deviationUnknownCount++;
        }

        if (shipmentMonitoring.demurragePortDuration !== null && shipmentMonitoring.demurragePortDuration > monitoringItemsInfo.maxPortDuration)
        {
            monitoringItemsInfo.maxPortDuration = shipmentMonitoring.demurragePortDuration;
        }

        if (shipmentMonitoring.demurrageDeviation !== null)
        {
            if (shipmentMonitoring.demurrageDeviation > 0)
            {
                if (shipmentMonitoring.demurrageDeviation > monitoringItemsInfo.maxDeviation)
                {
                    monitoringItemsInfo.maxDeviation = shipmentMonitoring.demurrageDeviation;
                }
            }
            else if (shipmentMonitoring.demurrageDeviation < 0)
            {
                if (-shipmentMonitoring.demurrageDeviation > monitoringItemsInfo.maxBeforeDeviation)
                {
                    monitoringItemsInfo.maxBeforeDeviation = -shipmentMonitoring.demurrageDeviation;
                }
            }
        }
    }

    private initializeShipmentDemurrageContract(displayShipmentMonitoring: DisplayShipmentMonitoring, demurrageContracts: DemurrageContract[]): void
    {
        for (const demurrageCointract of demurrageContracts)
        {
            const nowTime: number = (displayShipmentMonitoring.currentPortEndTime ?? new Date()).getTime();

            if (demurrageCointract.carrierId === displayShipmentMonitoring.carrierId &&
                demurrageCointract.fromDate !== null && new Date(demurrageCointract.fromDate).getTime() <= nowTime && demurrageCointract.toDate !== null &&
                new Date(demurrageCointract.toDate).getTime() >= nowTime)
            {
                const demurragePort: DemurrageContractPort | undefined = demurrageCointract.demurrageContractPorts.find(
                    (demurragePort: DemurrageContractPort) =>
                        demurragePort.perimeterId === displayShipmentMonitoring.perimeterId);

                if (demurragePort !== undefined)
                {
                    displayShipmentMonitoring.demurrageFreeDays = displayShipmentMonitoring.segmentId === MonitoringSegmentType.PortOfDestination ?
                        demurragePort.podFreeDays : demurragePort.polFreeDays;

                    displayShipmentMonitoring.demurrageFreeDaysFormatted =
                        Utils.formatDurationUnits(displayShipmentMonitoring.demurrageFreeDays, 'day');

                    displayShipmentMonitoring.demurrageDeviation = (displayShipmentMonitoring.demurragePortDuration ?? 0) -
                        (displayShipmentMonitoring.demurrageFreeDays)!;

                    displayShipmentMonitoring.demurrageSortDeviation = displayShipmentMonitoring.demurrageDeviation +
                        (displayShipmentMonitoring.demurrageDeviation <= 0 ? -1 : 0);

                    displayShipmentMonitoring.demurrageDeviationId = displayShipmentMonitoring.demurrageDeviation > 0 ?
                        DemurrageContractDeviationType.Late : DemurrageContractDeviationType.Early;

                    displayShipmentMonitoring.demurrageDeviationFormatted = `${displayShipmentMonitoring.demurrageDeviation <= 0 ? 'In ' : ''}${''
                    }${Utils.formatDurationUnits(Math.abs(displayShipmentMonitoring.demurrageDeviation), 'day')}`;
                        
                    break;
                }
            }
            else
            {
                displayShipmentMonitoring.demurrageSortDeviation = 0;
                displayShipmentMonitoring.demurrageFreeDaysFormatted = Constants.EMPTY_FIELD_VALUE;
                displayShipmentMonitoring.demurrageDeviationFormatted = Constants.EMPTY_FIELD_VALUE;
            }
        }
    }

    private initializeShipmentPerimeter(displayShipmentMonitoring: DisplayShipmentMonitoring, perimeterIdToPerimeterMap: Map<string, Perimeter>): void
    {
        const perimeter: Perimeter | undefined = perimeterIdToPerimeterMap.get(displayShipmentMonitoring.perimeterId!);
        if (perimeter === undefined)
        {
            return;
        }

        displayShipmentMonitoring.perimeterCountryName = perimeter.countryName;
        displayShipmentMonitoring.perimeterCountryCode = perimeter.countryCode;
        displayShipmentMonitoring.perimeterPortCode = perimeter.portCode;
        displayShipmentMonitoring.perimeterLatitute = perimeter.latitude;
        displayShipmentMonitoring.perimeterLongitude = perimeter.longitude;
    }

    private updateLastSearchMonitoringFilters(): void
    {
        if (this._appSettingsService.appSettings.demurrageMonitoringSettingsData.lastSearchDemurrageMonitoringFilters === null)
        {
            return;
        }

        const lastSearchDemurrageMonitoringFilters: DemurrageMonitoringFilters =
            cloneDeep(this._appSettingsService.appSettings.demurrageMonitoringSettingsData.lastSearchDemurrageMonitoringFilters);

        lastSearchDemurrageMonitoringFilters.selectedShipmentMonitoringKeys =
            this.updateLastSeachMonitoringItemFilteredByPropertyValue(this._monitoringDataLists.shipmentMonitoringKeys,
                lastSearchDemurrageMonitoringFilters.selectedShipmentMonitoringKeys);

        lastSearchDemurrageMonitoringFilters.selectedShipmentMonitoringCargosUnits =
            this.updateLastSeachMonitoringItemFilteredByPropertyValue(this._monitoringDataLists.shipmentMonitoringCargosUnits,
                lastSearchDemurrageMonitoringFilters.selectedShipmentMonitoringCargosUnits);

        lastSearchDemurrageMonitoringFilters.selectedShipmentMonitoringTrailersNumbers =
            this.updateLastSeachMonitoringItemFilteredByPropertyValue(this._monitoringDataLists.shipmentMonitoringTrailersNumbers,
                lastSearchDemurrageMonitoringFilters.selectedShipmentMonitoringTrailersNumbers);

        lastSearchDemurrageMonitoringFilters.selectedShipmentMonitoringCarriers =
            this.updateLastSeachMonitoringItemFilteredByPropertyValue(this._monitoringDataLists.shipmentMonitoringCarriers,
                lastSearchDemurrageMonitoringFilters.selectedShipmentMonitoringCarriers);

        lastSearchDemurrageMonitoringFilters.selectedShipmentMonitoringPorts =
            this.updateLastSeachMonitoringItemFilteredByPropertyValue(this._monitoringDataLists.shipmentMonitoringPorts,
                lastSearchDemurrageMonitoringFilters.selectedShipmentMonitoringPorts);

        lastSearchDemurrageMonitoringFilters.selectedShipmentMonitoringOriginsRoutes =
            this.updateLastSeachMonitoringItemFilteredByPropertyValue(this._monitoringDataLists.shipmentMonitoringOriginsRoutes,
                lastSearchDemurrageMonitoringFilters.selectedShipmentMonitoringOriginsRoutes);

        lastSearchDemurrageMonitoringFilters.selectedShipmentMonitoringDestinationsRoutes =
            this.updateLastSeachMonitoringItemFilteredByPropertyValue(this._monitoringDataLists.shipmentMonitoringDestinationsRoutes,
                lastSearchDemurrageMonitoringFilters.selectedShipmentMonitoringDestinationsRoutes);

        lastSearchDemurrageMonitoringFilters.selectedShipmentMonitoringSegments =
            this.updateLastSeachMonitoringItemFilteredByPropertyValue(this._monitoringDataLists.shipmentMonitoringSegments,
                lastSearchDemurrageMonitoringFilters.selectedShipmentMonitoringSegments);

        if (lastSearchDemurrageMonitoringFilters.selectedShipmentMonitoringDemurrageStatuses.length > 0 &&
            (lastSearchDemurrageMonitoringFilters.selectedShipmentMonitoringDemurrageStatuses[0] === DemurrageContractDeviationType.Early &&
            this._monitoringItemsSourceInfo.deviationEarlyCount === 0 ||
            lastSearchDemurrageMonitoringFilters.selectedShipmentMonitoringDemurrageStatuses[0] === DemurrageContractDeviationType.Late &&
            this._monitoringItemsSourceInfo.deviationLateCount === 0 ||
            lastSearchDemurrageMonitoringFilters.selectedShipmentMonitoringDemurrageStatuses[0] === DemurrageContractDeviationType.Unknown &&
            this._monitoringItemsSourceInfo.deviationUnknownCount === 0))
        {
            lastSearchDemurrageMonitoringFilters.selectedShipmentMonitoringDemurrageStatuses = [];
        }

        if (lastSearchDemurrageMonitoringFilters.minDeviation !== null && lastSearchDemurrageMonitoringFilters.minDeviation >
            this._monitoringItemsSourceInfo.maxDeviation)
        {
            lastSearchDemurrageMonitoringFilters.minDeviation = null;
        }

        if (lastSearchDemurrageMonitoringFilters.maxBeforeDeviation !== null && lastSearchDemurrageMonitoringFilters.maxBeforeDeviation >
            this._monitoringItemsSourceInfo.maxBeforeDeviation)
        {
            lastSearchDemurrageMonitoringFilters.maxBeforeDeviation = null;
        }

        if (lastSearchDemurrageMonitoringFilters.minDuration !== null && lastSearchDemurrageMonitoringFilters.minDuration >
            this._monitoringItemsSourceInfo.maxPortDuration)
        {
            lastSearchDemurrageMonitoringFilters.minDuration = null;
        }

        if (!isEqual(lastSearchDemurrageMonitoringFilters,
            this._appSettingsService.appSettings.demurrageMonitoringSettingsData.lastSearchDemurrageMonitoringFilters))
        {
            this._appSettingsService.appSettings.demurrageMonitoringSettingsData.lastSearchDemurrageMonitoringFilters = lastSearchDemurrageMonitoringFilters;
        }

        this.updateEmptyLastSearchFilters();
    }

    // #endregion
}