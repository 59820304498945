export enum MonitoringDetailedEventType
{
    None = 0,
    StrongImpact = 1,
    WeakImpact = 2,
    HighTemperature = 3,
    LowTemperature = 4,
    HighHumidity = 5,
    LowHumidity = 6,
    LightOn = 7,
    DoorOpen = 8,
    Security = 9,
    Stop = 10,
    TimeBasedHighTemperature = 11,
    TimeBasedLowTemperature = 12,
    TimeBasedHighHumidity = 13,
    TimeBasedLowHumidity = 14,
    TimeExceededAtPort = 15
}

export class FilterElement
{
    public filterType: number = 0;
    public value: number = 0;
    public class: string = '';
    public iconClassName: string = '';
    public tooltip: string = '';
}

export class MonitoringBaseInfo
{
    public count: number = 0;
    public routesCount: number = 0;
}
