import { ChangeDetectorRef, Component, OnInit } from "@angular/core";
import { BaseView } from "../classes/base-view";
import * as models from 'powerbi-models';
import { IReportEmbedConfiguration, service, Embed } from 'powerbi-client';
import { PBIData, PowerBiService } from "../../services/powerbi.service";
import { Constants } from "../../utils/globals";

type EventHandler = (event?: service.ICustomEvent<any>, embeddedEntity?: Embed) => void | null;

@Component({ template: '' })
export class PowerBIBaseComponent extends BaseView implements OnInit
{
    // #region Protected Members

    protected _reportEmbedConfiguration: IReportEmbedConfiguration =
        {
            type: 'report',
            embedUrl: 'https://app.powerbi.com/reportEmbed',
            tokenType: models.TokenType.Embed,
            filters: [],
            settings:
            {
                filterPaneEnabled: false,
                navContentPaneEnabled: false
            }
        };

    protected _reportName: string = '';

    protected _eventHandlers: Map<string, EventHandler>;

    // #endregion

    // #region Properties

    public get reportEmbedConfiguration(): IReportEmbedConfiguration
    {
        return this._reportEmbedConfiguration;
    }

    public get eventHandlers(): Map<string, EventHandler>
    {
        return this._eventHandlers;
    }

    // #endregion

    // #region Constructors

    constructor(private _powerBiService: PowerBiService, protected _changeDetectorRef: ChangeDetectorRef)
    {
        super();

        this._eventHandlers = new Map([
            ['rendered', () =>
            {
                this._viewIsReady = true;
                this._changeDetectorRef.detectChanges();
            }]
        ]);
    }

    // #endregion

    // #region Event Handlers

    public ngOnInit(): void
    {
        this._powerBiService.getPowerBIReportData(this._reportName).subscribe((pbiData: PBIData | null) =>
        {
            if (pbiData !== null)
            {
                this._reportEmbedConfiguration.accessToken = pbiData.token;
                this._reportEmbedConfiguration.pageName = pbiData.pageId;
                this._reportEmbedConfiguration.id = pbiData.reportId;

                this._viewIsInitialized = true;
                this._changeDetectorRef.detectChanges();
            }
            else
            {
                this.setViewState(false, Constants.DATA_SERVICE_ERROR_STRING);
            }
        });
    }

    // #endregion
}