export class ChangePasswordRequest
{
    public oldPassword: string | null = null;
    public newPassword: string | null = null;
}

export class ChangePasswordData extends ChangePasswordRequest
{
    public confirmedNewPassword: string | null = null;
}
