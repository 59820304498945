import { Constants } from '../../utils/globals';

export class BaseView
{
	// #region Private Members

	protected _statusMessage: string = Constants.LOADING_DATA_STRING;

	protected _viewIsInitialized: boolean = false;
	protected _viewIsReady: boolean = false;
	protected _showSpinner: boolean = true;
	protected _progressValue: number | null = null;

	// #endregion

	// #region Properties

	public get statusMessage(): string
	{
		return this._statusMessage;
	}

	public get viewIsInitialized(): boolean
	{
		return this._viewIsInitialized;
	}

	public get viewIsReady(): boolean
	{
		return this._viewIsReady;
	}

	public set viewIsReady(value: boolean)
	{
		this._viewIsReady = value;
		this._showSpinner = !value;
	}

	public get showSpinner(): boolean
	{
		return this._showSpinner;
	}

	public get progressValue(): number | null
	{
		return this._progressValue;
	}

	// #endregion

	// #region Public Methods

	public setViewState(success: boolean, errorMessage: string |null = null): void
	{
		this._showSpinner = false;

		if (success)
		{
			this._viewIsInitialized = true;
			this.viewIsReady = true;
			this._statusMessage = '';
		}
		else
		{
			this._statusMessage = errorMessage !== null ? errorMessage : Constants.DATA_SERVICE_ERROR_STRING;
		}
	}

	// #endregion
}