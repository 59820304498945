<!DOCTYPE html>

<div class="page-container">
    @if (('IS_MOBILE' | globals)) {
    <top-bar></top-bar>
    }
    @if (viewIsInitialized) {
    <div class="page-content" [class.grid-view]="isGridView" [class.mobile-standalone]="('IS_MOBILE' | globals)" @fadeInAnimation>
        <div class="page-inner-content">
            <div class="page-padding">
                <div class="form-group">
                    <clearable-input [(ngModel)]="managerModel.searchFilter" label="Filter Shipments" icon="icon-search"
                                     class="search-control" [disabled]="!managerVirtualList || managerVirtualList.viewPortItems.length === 0 &&
                                     (managerModel.searchFilter === null || managerModel.searchFilter.length === 0)">
                    </clearable-input>
                </div>
                <div class="manager-controls-container">
                    <div class="manager-type-container">
                        <label class="radio-container">
                            <input type="radio" name="shipment-type" [checked]="managerModel.shipmentsStatus === managerModel.ManagerItemStatus.Live"
                                   (click)="onDisplayStatusClick($event, managerModel.ManagerItemStatus.Live)" />
                            <span class="radio-checkmark ripple-effect ripple-fill ripple-inverse-color"></span>
                            <span class="radio-text"><i class="icon-check"></i>Live</span>
                        </label>
                        <label class="radio-container busy-support" [class.busy]="managerModel.isBackgroundFetchBusy">
                            <input type="radio" name="shipment-type" [checked]="managerModel.shipmentsStatus === managerModel.ManagerItemStatus.Completed"
                                   (click)="onDisplayStatusClick($event, managerModel.ManagerItemStatus.Completed)" />
                            <span class="radio-checkmark ripple-effect ripple-fill ripple-inverse-color"></span>
                            <span class="radio-text"><i class="icon-check"></i>Completed</span>
                            @if (managerModel.isBackgroundFetchBusy) {
                            <i class="icon-spinner" @fadeInOutAnimation></i>
                            }
                        </label>
                    </div>
                    <div class="manager-buttons-container">
                        <button type="button" class="add-button ripple-effect ripple-fill" tooltipPopup="Add shipment"
                                (click)="onAddManagerItemButtonClick()">
                            <span>+</span>
                        </button>
                        @if (!('IS_MOBILE' | globals)) {
                        <div class="manager-items-view-type-container">
                            <label class="radio-container" tooltipPopup="Table view">
                                <input type="radio" name="manager-item-view-type" [(ngModel)]="isGridView" [value]="false" />
                                <span class="radio-checkmark ripple-effect ripple-center ripple-inverse-color icon-list-ul"></span>
                            </label>
                            <label class="radio-container" tooltipPopup="Tiles view">
                                <input type="radio" name="manager-item-view-type" [(ngModel)]="isGridView" [value]="true" />
                                <span class="radio-checkmark ripple-effect ripple-center ripple-inverse-color icon-grid"></span>
                            </label>
                        </div>
                        }
                    </div>
                    @if (!('IS_MOBILE' | globals)) {
                    <div class="dates-filter">
                        <datetime-picker label="From" name="filterFromDate" #filterFromDate="ngModel" [(ngModel)]="managerModel.filterFromDate"
                                         [maxDate]="managerModel.filterToDate" [autoSelect]="false">
                        </datetime-picker>
                        <datetime-picker label="To" name="filterToDate" #filterToDate="ngModel" [(ngModel)]="managerModel.filterToDate"
                                         [minDate]="managerModel.filterFromDate" [autoSelect]="false">
                        </datetime-picker>
                    </div>
                    }
                    <div class="sort-container">
                        @if (isGridView && !('IS_MOBILE' | globals)) {
                        <div class="animation-overflow sort-by" @foldFadeHorizontalAnimation>
                            <select-list placeholder="Sort by" [items]="managerTableSortColumns" [clearable]="false"
                                         [disabled]="!managerVirtualList || managerVirtualList.viewPortItems.length === 0"
                                         [(ngModel)]="managerModel.sortColumn"
                                         bindLabel="text" bindValue="columnType">
                            </select-list>
                        </div>
                        }
                        @if (isGridView) {
                        <button type="button" class="image-button sort-button ripple-effect ripple-center ripple-inverse-color"
                                @foldFadeHorizontalAnimation [class.descending]="managerModel.isSortDescending"
                                [disabled]="!managerVirtualList || managerVirtualList.viewPortItems.length === 0"
                                (click)="onSortDirectionButtonClick()" tooltipPopup="Sort">
                            <i class="icon-sort-direction"></i>
                        </button>
                        }
                    </div>
                    <div class="manager-behaviour-controls">
                        @if (!('IS_MOBILE' | globals)) {
                        <label class="checkbox-container no-device ripple-effect ripple-center ripple-inverse-color"
                               [class.active]="managerModel.isFilterNoDevice !== null" tooltipPopup="With device/Deviceless/All">
                            <input name="no-device" type="checkbox" [(ngModel)]="managerModel.isFilterNoDevice">
                            <span class="container-background"></span>
                            <i [class.icon-no-device]="managerModel.isFilterNoDevice" [class.icon-router]="!managerModel.isFilterNoDevice"
                               class="no-device-checkmark"></i>
                        </label>
                        }
                        @if (!('IS_MOBILE' | globals) || ('IS_MOBILE' | globals) && managerModel.shipmentsStatus === managerModel.ManagerItemStatus.Live) {
                        <label class="checkbox-container arrived ripple-effect ripple-center ripple-inverse-color" tooltipPopup="Opened at destination"
                               [class.disabled]="managerModel.shipmentsStatus === managerModel.ManagerItemStatus.Completed">
                            <input name="arrived" type="checkbox" [(ngModel)]="managerModel.isFilterArrived">
                            <span class="container-background"></span>
                            <i class="icon-flag-checkered arrived-checkmark"></i>
                        </label>
                        }
                        @if (!('IS_MOBILE' | globals) || ('IS_MOBILE' | globals) && managerModel.shipmentsStatus === managerModel.ManagerItemStatus.Completed) {
                        <label class="checkbox-container auto-close ripple-effect ripple-center ripple-inverse-color" tooltipPopup="Closed automatically"
                               [class.disabled]="managerModel.shipmentsStatus === managerModel.ManagerItemStatus.Live">
                            <input name="auto-close" type="checkbox" [(ngModel)]="managerModel.isFilterAutoClosed">
                            <span class="container-background"></span>
                            <i class="icon-search-auto-close auto-close-checkmark"></i>
                        </label>
                        }
                        @if (loginModel.isAccountTypeAmazon && (!('IS_MOBILE' | globals) || ('IS_MOBILE' | globals) && managerModel.shipmentsStatus === managerModel.ManagerItemStatus.Completed)) {
                        <label class="checkbox-container anomalies ripple-effect ripple-center ripple-inverse-color" tooltipPopup="Anomalies"
                               [class.disabled]="managerModel.shipmentsStatus === managerModel.ManagerItemStatus.Live">
                            <input name="anomalies" type="checkbox" [(ngModel)]="managerModel.isFilterAnomalies">
                            <span class="container-background"></span>
                            <span class="anomalies-checkmark"></span>
                        </label>
                        }
                        @if (!('IS_MOBILE' | globals)) {
                        <button type="button" class="image-button excel-button ripple-effect ripple-center"
                                (click)="onExportToExcelButtonClick()" tooltipPopup="Export to Excel">
                            <i class="icon-excel"></i>
                        </button>
                        }
                        <button type="button" class="image-button refresh-button ripple-effect ripple-center" tooltipPopup="Refresh"
                                (click)="onRefreshButtonClick($event)">
                            <i class="icon-refresh"></i>
                        </button>
                    </div>
                </div>
                <div class="manager-elements-count">{{ (!managerVirtualList ? 0 : managerVirtualList.filteredSortedItems.length) }} Shipments</div>
            </div>
            <div class="virtual-list-container">
                <div class="manager-table-container" [class.page-padding]="!isGridView || ('IS_MOBILE' | globals)">
                    @if (isGridView) {
                    <ovarlay-scrollbar>
                        <virtual-list #managerItemsList [items]="managerModel.filteredSortedManagerItems"
                                      [filter]="managerModel.searchFilter" [isScrollSnap]="false"
                                      class="grid-view" [virtualListInfo]="managerItemsListInfo" [itemsPerRow]="gridViewItemsPerRow"
                                      [filterProperties]="managerSearchProperties"
                                      pointerEvents [useMouseWheel]="false" (swipe)="onManagerItemsListSwipe($event)"
                                      (itemsChange)="onManagerItemsListChange($event)" [class.active]="managerItemsList.viewPortItems.length > 0"
                                      [style.--grid-view-items-per-row]="gridViewItemsPerRow" (resized)="onManagerItemsListResized()">
                            @for (displayShipment of managerItemsList.viewPortItems; track displayShipment.shipmentKey) {
                            <div #body
                                 class="manager-item select-none" [class.selected]="managerModel.selectedManagerItem === displayShipment">
                                <div class="manager-item-content-container ripple-effect ripple-fill ripple-inverse-color"
                                     (click)="onManagerItemClick(displayShipment)">
                                    <div class="manager-item-content">
                                        @for (column of managerListColumns; track column.columnType) {
                                        <div class="manager-element-details-item" [class]="column.classes ?? ''">
                                            <span class="manager-element-details-name"><span [innerHTML]="column.text"></span>:</span>
                                            <span class="manager-element-details-value"
                                                  [tooltipPopup]="column.propertyName ? displayShipment[column.propertyName] : ''" [showTooltipOnVerflow]="true">
                                                <span>{{ column.propertyName ? displayShipment[column.propertyName] : '' }}</span>
                                            </span>
                                        </div>
                                        }
                                        <div class="manager-item-details-open">
                                            @if (managerModel.shipmentsStatus === managerModel.ManagerItemStatus.Live) {
                                            <button type="button" class="image-button ripple-effect ripple-inverse-color" tooltipPopup="Track"
                                                    [disabled]="displayShipment.untrackable || displayShipment.isTest"
                                                    (click)="onShipmentMonitoringButtonClick(displayShipment)">
                                                <i class="icon-route"></i>
                                            </button>
                                            }
                                            <!--@if (displayShipment.deviceId !== null) {
                                            <button type="button" class="image-button ripple-effect ripple-inverse-color">
                                                <i class="icon-lock"></i>
                                            </button>
                                            }-->
                                            @if (managerModel.shipmentsStatus === managerModel.ManagerItemStatus.Live && displayShipment.isReportArrived) {
                                            <div class="shipment-details-arrived icon-flag-checkered">
                                            </div>
                                            }
                                            @if (managerModel.shipmentsStatus === managerModel.ManagerItemStatus.Completed && displayShipment.isReportAutoClose) {
                                            <div class="shipment-details-auto-close icon-auto-close">
                                            </div>
                                            }
                                            @if (managerModel.shipmentsStatus === managerModel.ManagerItemStatus.Completed && displayShipment.isReportAnomaly) {
                                            <div class="item-details-anomaly">
                                            </div>
                                            }
                                            @if (('IS_MOBILE' | globals)) {
                                            <i class="icon-chevron-right"></i>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            }
                        </virtual-list>
                    </ovarlay-scrollbar>
                    }
                    @if (!isGridView) {
                    <div class="manager-table-view-container">
                        @if (managerModel.shipmentsStatus === managerModel.ManagerItemStatus.Live) {
                        <ng-container [ngTemplateOutlet]="managerTableTemplate" [ngTemplateOutletContext]="{ id: 'shipment-manager-table-live' }">
                        </ng-container>
                        }
                        @else if (managerModel.shipmentsStatus === managerModel.ManagerItemStatus.Completed) {
                        <ng-container [ngTemplateOutlet]="managerTableTemplate" [ngTemplateOutletContext]="{ id: 'shipment-manager-table-completed' }">
                        </ng-container>
                        }
                    </div>
                    }
                </div>
                @if (!managerVirtualList || managerVirtualList.viewPortItems.length === 0) {
                <div class="list-empty-placeholder" @fadeInOutAnimation>
                    <i class="icon-container-crane"></i>
                    <i class="icon-container-alt"></i>
                    <i class="icon-container-alt"></i>
                </div>
                }
            </div>
        </div>
        @if (!('IS_MOBILE' | globals)) {
        <div class="page-inner-content">
            <div class="manager-details-pane">
                @if (!managerModel.selectedManagerItem) {
                <div class="manager-details-placeholder">
                    <div class="title">No shipment selected</div>
                    <div>Please select a shipment from the list<br />to view or edit one</div>
                    <i class="icon-edit"></i>
                </div>
                }
                @if (managerModel.selectedManagerItem) {
                <div class="manager-element-container" @shortFadeInOutAnimation>
                    <div class="manager-element-title">
                        <span>Shipment {{ managerModel.selectedManagerItem.shipmentKey }}</span>
                        @if (managerModel.selectedManagerItem.autoClose) {
                        <i class="icon-auto-close" @shortFadeInOutAnimation></i>
                        }
                    </div>
                    <shipment class="manager-element" #managerItem (submitingShipment)="onSubmitingManagerItem($event)"></shipment>
                </div>
                }
            </div>
        </div>
        }
        @if (showAddNewManagerElement) {
        <div tabindex="-1" class="modal-backdrop new-element" (click)="onCloseAddNewManagerElementClick()" @fadeInOutAnimation></div>
        }
        @if (showAddNewManagerElement) {
        <div class="manager-new-element new-element" tabindex="-1" @fadeSlideDownInOutAnimation>
            <div class="modal-content-container">
                <div class="modal-content page-container page-image">
                    <div class="modal-header">
                        <div class="modal-title">New Shipment</div>
                        <button type="button" class="image-button close-button" (click)="onCloseAddNewManagerElementClick()"><i class="icon-cancel"></i></button>
                    </div>
                    <div class="modal-body">
                        <shipment #managerItem (submitingShipment)="onSubmitingManagerItem($event)"></shipment>
                    </div>
                </div>
            </div>
        </div>
        }
    </div>
    }

    @if (!viewIsReady || managerModel.isBusy) {
    <loader [@.disabled]="!viewIsInitialized" @fadeInOutAnimation
            [loadingMessage]="statusMessage" [showSpinner]="showSpinner || managerModel.isBusy"
            [isBusyLoader]="viewIsReady && managerModel.isBusy" [isBusyLoaderOverlay]="true">
    </loader>
    }

    <!-- managerTableTemplate -->
    <ng-template #managerTableTemplate let-id="id">
        <virtual-list @fadeInOutAnimation #managerItemsList [items]="managerModel.filteredSortedManagerItems" [id]="id"
                      class="manager-table" [filter]="managerModel.searchFilter" tabindex="-1" [isSortable]="true"
                      [class.vertical-scrollable]="managerItemsList.element.scrollHeight > managerItemsList.element.clientHeight"
                      [filterProperties]="managerSearchProperties"
                      [virtualListInfo]="managerTableListInfo" [isAutoColumnsWidths]="true"
                      (itemsChange)="onManagerItemsListChange($event)" (sorted)="onManagerItemsListSorted()"
                      [class.active]="managerItemsList.viewPortItems.length > 0">
            <div #header class="list-header">
                @for (column of managerTableColumns; track column.columnType) {
                <div [class]="'col-list ' + (column.classes ?? '')" [attr.property-name]="column.propertyName"
                     [attr.sort-property-name]="column.sortPropertyName" [attr.fixed-width]="column.isFixedWidth ? 'true' : null">
                    @switch (column.columnType) {
                    @case (ShipmentColumnType.Track) {
                    }
                    @default {
                    <span [innerHTML]="column.text"></span>
                    }
                    }
                </div>
                }
            </div>
            @for (displayShipment of managerItemsList.viewPortItems; track displayShipment.shipmentKey) {
            <div #body class="list-row ripple-effect ripple-fill ripple-inverse-color"
                 (click)="onManagerItemClick(displayShipment)"
                 [ngClass]="{ 'odd-row': managerItemsList.filteredSortedItems.indexOf(displayShipment) % 2,
                           'selected': displayShipment === managerModel.selectedManagerItem }">
                @for (column of managerTableColumns; track column.columnType) {
                <div [class]="'col-list ' + (column.classes ?? '')" [attr.property-name]="column.propertyName">
                    @switch (column.columnType) {
                    @case (ShipmentColumnType.IsArrived) {
                    @if (displayShipment.isReportArrived) {
                    <i class="icon-flag-checkered shipment-details-arrived"></i>
                    }
                    @else {
                    <default-column [column]="column" [item]="displayShipment"></default-column>
                    }
                    }
                    @case (ShipmentColumnType.IsAutoClose) {
                    @if (displayShipment.isReportAutoClose) {
                    <i class="icon-auto-close shipment-details-auto-close"></i>
                    }
                    @else {
                    <default-column [column]="column" [item]="displayShipment"></default-column>
                    }
                    }
                    @case (ShipmentColumnType.IsReportAnomaly) {
                    @if (displayShipment.isReportAnomaly) {
                    <div class="item-details-anomaly"></div>
                    }
                    @else {
                    <default-column [column]="column" [item]="displayShipment"></default-column>
                    }
                    }
                    @case (ShipmentColumnType.Track) {
                    <button type="button" class="image-button ripple-effect ripple-center ripple-inverse-color"
                            (click)="onShipmentMonitoringButtonClick(displayShipment)" tooltipPopup="Track"
                            [disabled]="displayShipment.untrackable || displayShipment.isTest">
                        <i class="icon-route"></i>
                    </button>
                    }
                    @default {
                    <default-column [column]="column" [item]="displayShipment"></default-column>
                    }
                    }
                </div>
                }
            </div>
            }
        </virtual-list>
    </ng-template>
</div>

