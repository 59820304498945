<!DOCTYPE html>

<div class="page-container">
  @if (viewIsInitialized) {
    <powerbi-report [embedConfig]="reportEmbedConfiguration" [eventHandlers]="eventHandlers" [phasedEmbedding]="false"></powerbi-report>
  }
  @if (showNavigationButton) {
    <button type="button" class="navigation-button" [disabled]="isNavigationButtonDisabled" @fadeInOutAnimation
      (click)="onNavigationButtonClick()" tooltipPopup="Tracking">
      <i class="icon-route"></i>
    </button>
  }
  @if (!viewIsReady) {
    <loader @fadeOutAnimation [loadingMessage]="statusMessage" [showSpinner]="showSpinner"></loader>
  }
</div>