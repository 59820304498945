@if (showTreetView) {
  <div class="street-view-container"></div>
}
@if (showTreetView) {
  <button type="button" class="street-view-close-button floating-button ripple-effect ripple-fill" tabindex="-1"
    (click)="onStreetViewCloseButtonClick()">
    <i class="icon-collapse-left"></i>
  </button>
}
<div class="map-container" [class.satellite-view]="isSatelliteView"></div>
<ng-content></ng-content>
@if (showMapRailways) {
  <div class="open-railway-copyrights" @fadeInOutAnimation>
    <div class="copyrights-text">
      <span>Railway data </span>
      <a href="https://www.openstreetmap.org/copyright" target="_blank">© OpenStreetMap contributors</a>
      <span>, Style: </span>
      <a href="http://creativecommons.org/licenses/by-sa/2.0/" target="_blank">CC-BY-SA 2.0 </a>
      <a href="http://www.openrailwaymap.org/" target="_blank">OpenRailwayMap</a>
    </div>
  </div>
}
