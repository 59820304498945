<!DOCTYPE html>

<div class="page-container page-image">
    <div class="page-content">
        <div class="page-navigate-back">
            <button class="image-button ripple-effect ripple-center" (click)="onBackButtonClick()">
                <i class="icon-collapse-left"></i>Back
            </button>
        </div>
        <img id="login-logo" src="assets/vectors/logo.svg">
        <form #formChangePassword="ngForm" (ngSubmit)="formChangePassword.form.valid && onChangePasswordSubmit()"
              [class.form-validated]="formChangePassword.submitted" novalidate
              [matchPasswordsValidator]="['oldPassword', 'newPassword', 'confirmPassword']">
            <fieldset class="page-padding" [disabled]="changePasswordModel.isBusy">
                <div class="form-group">
                    <password-input label="Current Password" name="oldPassword" #oldPassword="ngModel"
                                    [(ngModel)]="changePasswordModel.changePasswordData.oldPassword" requiredValidator>
                    </password-input>
                    @if (formChangePassword.submitted && oldPassword.invalid) {
                    <div class="invalid-feedback" @foldAnimation>This field is required</div>
                    }
                </div>
                <div class="form-group">
                    <password-input label="New Password" name="newPassword" #newPassword="ngModel"
                                    [(ngModel)]="changePasswordModel.changePasswordData.newPassword" requiredValidator passwordValidator>
                    </password-input>
                    @if (formChangePassword.submitted && !newPassword.valid) {
                    <div class="invalid-feedback" @foldAnimation>
                        @if (newPassword.errors?.['requiredValidator']) {
                        <div>This field is required</div>
                        }
                        @if (newPassword.errors?.['passwordValidator']) {
                        <div>Password must include at least one digit, one symbol, one lowercae letter, one uppercase letter and at least 8 characters</div>
                        }
                        @if (newPassword.errors?.['matchPasswordsValidator']) {
                        <div>New password must be differect from the old password</div>
                        }
                    </div>
                    }
                </div>
                <div class="form-group">
                    <password-input label="Confirm Password" name="confirmPassword" #confirmPassword="ngModel"
                                    [(ngModel)]="changePasswordModel.changePasswordData.confirmedNewPassword" requiredValidator>
                    </password-input>
                    @if (formChangePassword.submitted && !confirmPassword.valid) {
                    <div class="invalid-feedback" @foldAnimation>
                        @if (confirmPassword.errors?.['requiredValidator']) {
                        <div>This field is required</div>
                        }
                        @if (confirmPassword.errors?.['matchPasswordsValidator']) {
                        <div>Passwords must match</div>
                        }
                    </div>
                    }
                </div>

                <div class="form-group">
                    <button type="submit" class="primary-button ripple-effect ripple-fill">
                        <span class="spinner-container">
                            @if (changePasswordModel.isBusy) {
                            <i @fadeInOutAnimation class="icon-spinner"></i>
                            }
                            Change Password
                        </span>
                    </button>
                </div>
            </fieldset>
        </form>
    </div>
</div>
