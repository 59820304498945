export interface CGIOverlayView extends google.maps.OverlayView { }

export class CGIOverlayView
{
    constructor()
    {
        for (const property in google.maps.OverlayView.prototype)
        {
            (CGIOverlayView as any).prototype[property] = (google.maps.OverlayView as any).prototype[property];
        }
    }
}
