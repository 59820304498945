import { Observable, Observer, catchError, mergeMap, of, retry, throwError, timer } from "rxjs";
import { BaseModel } from "../classes/base-model";
import { IApiResponse } from "../../utils/globals";
import { Renderer2, RendererFactory2 } from "@angular/core";
import { AttachmentFileData, AttachmentsInfo } from "./attachments-model.class";
import { HttpErrorCodes } from "../../utils/http-error-codes";
import { HttpClient, HttpErrorResponse, HttpEvent, HttpEventType } from "@angular/common/http";

export class AttachmentsModel extends BaseModel
{
    // #region Constants

    private readonly MEGA_BYTE_SIZE: number = 1024 * 1024;
    private readonly UPLOAD_MAX_RETRIES: number = 3;
    private readonly UPLOAD_RETRY_DELAY: number = 1000;

    private readonly UPLOAD_SERVICE_ERROR_STRING: string = '<b>An error occurred while uploading attachments to the server.</b><br>Please try again later.';

    private readonly ATTACHMENT_MAX_SIZE_BYTES: number = 4 * this.MEGA_BYTE_SIZE;
    protected readonly TOTAL_ATTACHMENT_MAX_SIZE_MEGA_BYTES: number = 25;

    // #endregion

    // #region Private Members

    private _renderer: Renderer2;

    // #endregion

    // #region Protected Members

    protected _attachmentsInfo: AttachmentsInfo[] = [];

    // #endregion

    // #region Properties

    public get attachmentUniqueKey(): number | null
    {
        return 0;
    }

    public get attachmentsInfo(): AttachmentsInfo[]
    {
        return this._attachmentsInfo;
    }

    public get isDirty(): boolean
    {
        return this.isAttachmentsNeedsUpload();
    }

    // #endregion

    // #region Constructors

    constructor(protected _httpClient: HttpClient, rendererFactory: RendererFactory2)
    {
        super();

        this._renderer = rendererFactory.createRenderer(null, null);

        this.initializeAttachmentsInfo();
    }

    // #endregion

    // #region Public Methods

    public override clear(): void
    {
        super.clear();

        this.initializeAttachmentsInfo();
    }

    public isAttachmentsNeedsUpload(attachmentType: number = 0): boolean
    {
        return this._attachmentsInfo[attachmentType].uploadAttachmentsFilesData.length > 0 &&
            this._attachmentsInfo[attachmentType].uploadAttachmentsFilesData.length >
            this._attachmentsInfo[attachmentType].alreadyUploadedAttachmentsFileNames.length;
    }

    public addAttachmentFiles(files: File[], attachmentType: number = 0): Observable<IApiResponse>
    {
        return new Observable((observer: Observer<IApiResponse>) =>
        {
            const currentUploadAttachmentFilesDataLength: number = this._attachmentsInfo[attachmentType].uploadAttachmentsFilesData.length;

            let filesAddedCount: number = 0;

            for (const file of files)
            {
                if (this.isAttachmentFileExists(file.name, currentUploadAttachmentFilesDataLength, attachmentType))
                {
                    observer.next({ isSuccess: false, message: `The file '${file.name}' already exists!`, isComplete: ++filesAddedCount === files.length });
                    if (filesAddedCount === files.length)
                    {
                        observer.complete();
                    }
                }
                else
                {
                    const attachmentFileData: AttachmentFileData = new AttachmentFileData();
                    attachmentFileData.attachmentFileUrl = '';
                    this._attachmentsInfo[attachmentType].uploadAttachmentsFilesData =
                        [...this._attachmentsInfo[attachmentType].uploadAttachmentsFilesData, attachmentFileData];

                    observer.next({ isSuccess: true, isComplete: false });

                    this.compressFileSize(file).then((compressedFile: File) =>
                    {
                        const isComplete: boolean = ++filesAddedCount === files.length;
                        if (this._attachmentsInfo[attachmentType].availableAttachmentSizeToAdd < compressedFile.size / this.MEGA_BYTE_SIZE)
                        {
                            this._attachmentsInfo[attachmentType].uploadAttachmentsFilesData.
                                splice(this._attachmentsInfo[attachmentType].uploadAttachmentsFilesData.indexOf(attachmentFileData), 1);

                            observer.next({ isSuccess: true, isComplete: isComplete, message: compressedFile.name });
                        }
                        else
                        {
                            attachmentFileData.attachmentFile = compressedFile;
                            attachmentFileData.attachmentFileUrl = URL.createObjectURL(compressedFile);

                            this.updateAttachmentsAvailableSize(attachmentType);

                            observer.next({ isSuccess: true, isComplete: isComplete });
                        }

                        if (isComplete)
                        {
                            observer.complete();
                        }
                    });
                }
            }
        });
    }

    public uploadAttachmentFile(attachmentIndex: number, attachmentType: number = 0): Observable<IApiResponse>
    {
        this._isBusy = true;

        return new Observable((observer: Observer<IApiResponse>) =>
        {
            const attachmentFileData: AttachmentFileData = this._attachmentsInfo[attachmentType].uploadAttachmentsFilesData[attachmentIndex];

            if (this._attachmentsInfo[attachmentType].alreadyUploadedAttachmentsFileNames.includes(attachmentFileData.attachmentFile.name))
            {
                observer.next({ isSuccess: true });
                observer.complete();
                return;
            }

            const formData: FormData = new FormData();
            formData.append('upload', attachmentFileData.attachmentFile, attachmentFileData.attachmentFile.name);

            this._httpClient.post(`file/upload/${this.attachmentUniqueKey}`, formData,
                { observe: 'events', reportProgress: true }).pipe(
                    mergeMap((event: HttpEvent<any>) => of(event)),
                    retry(
                        {
                            count: this.UPLOAD_MAX_RETRIES,
                            delay: (error: HttpErrorResponse, retryCount: number) =>
                            {
                                if (error.status != HttpErrorCodes.CONFLICT && retryCount < this.UPLOAD_MAX_RETRIES)
                                {
                                    return timer(this.UPLOAD_RETRY_DELAY);
                                }

                                this._isBusy = false;

                                console.error(error);

                                if (error.status === HttpErrorCodes.CONFLICT)
                                {
                                    this._attachmentsInfo[attachmentType].alreadyUploadedAttachmentsFileNames.push(attachmentFileData.attachmentFile.name);
                                    observer.next({ isSuccess: true });
                                }
                                else
                                {
                                    observer.next({ isSuccess: false, message: this.UPLOAD_SERVICE_ERROR_STRING });
                                }

                                observer.complete();

                                throw error;
                            }
                        }),
                    catchError(error => error.status != HttpErrorCodes.CONFLICT ? of(error) : throwError(() => error))
                ).subscribe(
                    {
                        next: (event: HttpEvent<any>) =>
                        {
                            if (event.type == HttpEventType.UploadProgress)
                            {
                                observer.next({
                                    isSuccess: true,
                                    progress: Math.round(event.total === undefined ? 100 : 100 * event.loaded / event.total)
                                });
                            }
                            else if (event.type == HttpEventType.Response)
                            {
                                this._isBusy = false;

                                this._attachmentsInfo[attachmentType].alreadyUploadedAttachmentsFileNames.push(attachmentFileData.attachmentFile.name);

                                observer.next({ isSuccess: event.ok, message: event.ok ? '' : this.UPLOAD_SERVICE_ERROR_STRING });
                                observer.complete();
                            }
                        }
                    });
        });
    }

    public deleteAttachmentFile(attachmentIndex: number, attachmentType: number = 0): void
    {
        this._attachmentsInfo[attachmentType].uploadAttachmentsFilesData.splice(attachmentIndex, 1);
        this._attachmentsInfo[attachmentType].uploadAttachmentsFilesData = [...this._attachmentsInfo[attachmentType].uploadAttachmentsFilesData];

        this.updateAttachmentsAvailableSize(attachmentType);
    }

    // #endregion

    // #region Protected Methods

    protected initializeAttachmentsInfo(): void
    {
        this._attachmentsInfo = [];
        this._attachmentsInfo.push(new AttachmentsInfo());

        this._attachmentsInfo[0].availableAttachmentSizeToAdd = this.TOTAL_ATTACHMENT_MAX_SIZE_MEGA_BYTES;
    }

    protected isAttachmentFileExists(fileName: string, currentUploadAttachmentFilesDataLength: number, attachmentType: number = 0): boolean
    {
        for (let i: number = 0; i < currentUploadAttachmentFilesDataLength; i++)
        {
            if (this._attachmentsInfo[attachmentType].uploadAttachmentsFilesData[i].attachmentFile.name === fileName)
            {
                return true;
            }
        }

        return false;
    }

    protected updateAttachmentsAvailableSize(attachmentType: number = 0): void
    {
        let totalSize: number = 0;

        for (const attachmentFileData of this._attachmentsInfo[attachmentType].uploadAttachmentsFilesData)
        {
            if (attachmentFileData.attachmentFile !== undefined && attachmentFileData.attachmentFile.size !== undefined)
            {
                totalSize += attachmentFileData.attachmentFile.size;
            }
        }

        for (const attachmentFileData of this._attachmentsInfo[attachmentType].attachmentsFilesData)
        {
            if (attachmentFileData.attachmentFile !== undefined && attachmentFileData.attachmentFile.size !== undefined)
            {
                totalSize += attachmentFileData.attachmentFile.size;
            }
        }

        this._attachmentsInfo[attachmentType].availableAttachmentSizeToAdd = Math.floor(this.TOTAL_ATTACHMENT_MAX_SIZE_MEGA_BYTES -
            totalSize / this.MEGA_BYTE_SIZE);
    }

    // #endregion

    // #region Private Methods

    private async compressFileSize(file: File): Promise<File>
    {
        if (file.size < this.ATTACHMENT_MAX_SIZE_BYTES)
        {
            return file;
        }

        const imageBitmap: ImageBitmap = await createImageBitmap(file);

        const resizeRatio: number = this.ATTACHMENT_MAX_SIZE_BYTES / file.size;

        const resizeWidth: number = imageBitmap.width * resizeRatio;
        const resizeHeight: number = imageBitmap.height * resizeRatio;

        const canvasElement: HTMLCanvasElement = this._renderer.createElement('canvas');
        canvasElement.width = resizeWidth;
        canvasElement.height = resizeHeight;

        const canvasRenderingContext: CanvasRenderingContext2D | null = canvasElement.getContext('2d');
        canvasRenderingContext?.drawImage(imageBitmap, 0, 0, resizeWidth, resizeHeight);

        return new Promise<File>((resolve) =>
        {
            canvasElement.toBlob((blob: Blob | null) =>
            {
                if (blob !== null)
                {
                    resolve(new File([blob], file.name, { type: file.type }));
                }
            }, file.type);
        });
    }

    // #endregion
}