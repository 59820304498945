import { MonitoringArrivalStatusType } from "../../../base/models/manager-base-model.class";
import { MonitoringBaseInfo, MonitoringDetailedEventType } from "../../../base/models/monitoring-items-base-model.class";
import { IIdValue } from "../../../utils/globals";

export enum LiveShipmentColumnType
{
    ContainerId, RouteDescription, RouteProgress, Segment, InstallDate, Eta, ArrivalStatus, Temperature, Humidity, Impact, Security,
    BookingReference, ShipmentKey, DeviceId, Copy, Share, Favorites, Track
}
export enum CompletedShipmentColumnType
{
    ContainerId, RouteDescription, InstallDate, RemoveDate, Eta, ArrivalStatus, Temperature, Humidity, Impact, Security,
    BookingReference, ShipmentKey, DeviceId, Copy, Share, Favorites, Track
}

export class ShipmentMonitoringFavorite
{
    public shipmentKey: number | null = null;
    public name: string | null = null;
}

export class ShipmentsMonitoringFilters
{
    public selectedShipmentMonitoringKeys: number[] = [];
    public selectedShipmentMonitoringCargosUnits: string[] = [];
    public selectedShipmentMonitoringTrailersNumbers: string[] = [];
    public selectedShipmentMonitoringDevices: number[] = [];
    public selectedShipmentMonitoringBookingReferences: number[] = [];
    public selectedShipmentMonitoringOriginsRoutes: string[] = [];
    public selectedShipmentMonitoringDestinationsRoutes: string[] = [];
    public selectedShipmentMonitoringArrivalStatuses: MonitoringArrivalStatusType[] = [];
    public selectedShipmentMonitoringEvents: MonitoringDetailedEventType[] = [];
    public selectedShipmentMonitoringFavorites: number[] = [];
}

export class ShipmentsMonitoringDataLists
{
    public shipmentMonitoringKeys: number[] = [];
    public shipmentMonitoringCargosUnits: string[] = [];
    public shipmentMonitoringTrailersNumbers: string[] = [];
    public shipmentMonitoringDevices: number[] = [];
    public shipmentMonitoringBookingReferences: number[] = [];
    public shipmentMonitoringOriginsRoutes: string[] = [];
    public shipmentMonitoringDestinationsRoutes: string[] = [];
    public monitoringItemsArrivalStatuses: IIdValue[] = [];
    public monitoringItemsEvents: IIdValue[] = [];
    public shipmentMonitoringFavorites: ShipmentMonitoringFavorite[] = [];
}

export class ShipmentsMonitoringInfo extends MonitoringBaseInfo
{
    public lateCount: number = 0;
    public earlyOrOnTimeCount: number = 0;
    public unknownCount: number = 0;
    public tempratureEventsCount: number = 0;
    public humidityEventsCount: number = 0;
    public impactEventsCount: number = 0;
    public securityEventsCount: number = 0;
    public qualityEventsCount: number = 0;
}
