<!DOCTYPE html>

<div class="page-container">
  <top-bar [barTitle]="inventory.managerItemModel.managerItem.inventoryId !== null ? 'Edit Inventory' : 'New Inventory'"></top-bar>
  <div class="page-content mobile-standalone">
    <div class="page-header">
      <button class="back-button ripple-effect ripple-inverse-color" (click)="onBackButtonClick()">
        <i class="icon-collapse-left"></i>Back
      </button>
    </div>
    <inventory #inventory (submitingManagerItem)="onSubmitingInventory()"></inventory>
  </div>
  @if (!inventory.viewIsReady) {
    <loader @fadeInOutAnimation [loadingMessage]="inventory.statusMessage" [loadingProgress]="inventory.progressValue"></loader>
  }
</div>

