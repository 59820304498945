<!DOCTYPE html>

<button class="back-button image-button ripple-effect ripple-center ripple-inverse-color" (click)="onBackButtonClick()">
    <i class="icon-collapse-left"></i>
</button>
<div class="settings-header">
    <i class="icon-gear"></i>
    <div class="app-version">Version {{ appVersion }}</div>
</div>
<div class="settings-content page-scroll-padding" overlayScrollbar>
    <div class="settings-section">
        <div class="section-title">User Information</div>
        <div class="item-label">Customer Id</div>
        <div class="item-value">
            <span>{{ userInfo.customerId }}</span>
            @if (userInfo.isTest) {
            <span> (Test)</span>
            }
            @if (userInfo.isAdministrator) {
            <span> (Admin)</span>
            }
        </div>
        <div class="item-label">Corp Id</div>
        <div class="item-value">{{ userInfo.corpId ?? ('EMPTY_FIELD_VALUE' | globals) }}</div>
        <div class="item-label">Name</div>
        <div class="item-value">{{ userInfo.fullName }}</div>
        <div class="item-label">Email</div>
        <div class="item-value">{{ userInfo.email }}</div>
    </div>
    <div class="settings-section">
        <div class="section-title">General</div>
        <div class="form-group">
            <div class="item-label">Select between local time and UTC</div>
            <label class="checkbox-container ripple-effect ripple-inverse-color" [class.checked]="isCurrentlyUsingUTCTime">
                <span>Local Time</span>
                <input type="checkbox" [(ngModel)]="isCurrentlyUsingUTCTime" (change)="onUsingUTCTimeChange()">
                <span class="checkbox-switch"></span>
                <span>UTC</span>
            </label>
            @if (appSettingsService.appSettings.isUsingUTCTime !== isCurrentlyUsingUTCTime) {
            <div class="invalid-feedback" @foldAnimation>* Pending Restart</div>
            }
        </div>
        <div class="form-group">
            <div class="item-label">Select between 12-hour and 24-hour time format</div>
            <label class="checkbox-container ripple-effect ripple-inverse-color" [class.checked]="isCurrentlyUsingTime24Hours">
                <span>12-hour</span>
                <input type="checkbox" [(ngModel)]="isCurrentlyUsingTime24Hours" (change)="onUsingTime24HoursChange()">
                <span class="checkbox-switch"></span>
                <span>24-hour</span>
            </label>
            @if (appSettingsService.appSettings.isUsingTime24Hours !== isCurrentlyUsingTime24Hours) {
            <div class="invalid-feedback" @foldAnimation>* Pending Restart</div>
            }
        </div>
        <div class="form-group">
            <div class="item-label">Select between satellite view and map view</div>
            <label class="checkbox-container ripple-effect ripple-inverse-color" [class.checked]="appSettingsService.appSettings.isSatelliteView">
                <span>Map View</span>
                <input type="checkbox" [(ngModel)]="appSettingsService.appSettings.isSatelliteView">
                <span class="checkbox-switch"></span>
                <span>Satellite View</span>
            </label>
        </div>
    </div>
    <div class="settings-section">
        <div class="section-title">Color Theme</div>
        <div class="form-group">
            <select-list #appColorThemeModesList [items]="appColorThemeModes" bindLabel="value" bindValue="id" [clearable]="false"
                         [(ngModel)]="appSettingsService.appSettings.appColorThemeMode">
                <ng-template select-list-label-template let-selectedItems="selectedItems">
                    <div class="dropdown-value select-none">
                        <ng-container [ngTemplateOutlet]="appColorThemeModeIconTemplate"
                                      [ngTemplateOutletContext]="{ mode: appSettingsService.appSettings.appColorThemeMode }"></ng-container>
                        <span>{{ appColorThemeModesList.selectedItemsString }}</span>
                    </div>
                </ng-template>
                <ng-template select-list-option-template let-item="item">
                    <div class="option-value select-none">
                        <ng-container [ngTemplateOutlet]="appColorThemeModeIconTemplate" [ngTemplateOutletContext]="{ mode: item.id }"></ng-container>
                        <span>{{ item.value }}</span>
                    </div>
                </ng-template>
            </select-list>
        </div>
    </div>
</div>

<ng-template #appColorThemeModeIconTemplate let-mode="mode">
    @switch (mode) {
    @case (AppColorThemeMode.System) {
    <i class="icon-palette"></i>
    }
    @case (AppColorThemeMode.Light) {
    <i class="icon-sun-bright"></i>
    }
    @case (AppColorThemeMode.Dark) {
    <i class="icon-moon"></i>
    }
    }
</ng-template>
