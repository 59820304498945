import { HttpHeaders, HttpParams } from '@angular/common/http';
import { HttpQueryEncoderHelper } from './http-query-encoder-helper';
import { Utils } from './utils';

export class HttpHelper
{
    // #region Public Methods

    public static GetHttpFormUrlencodedHeaders(): HttpHeaders
    {
        return new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded');
    }

    public static GetHttpJsonHeaders(): HttpHeaders
    {
        return new HttpHeaders().set('Content-Type', 'application/json');
    }

    public static GetParams(object: any): HttpParams
    {
        return new HttpParams({ encoder: new HttpQueryEncoderHelper(), fromString: HttpHelper.buildParams(null, object).join('&') });
    }

    // #endregion

    // #region Private Methods

    private static addParam(key: string, value: any): string
    {
        value = typeof value === 'function' ? value() : value;
        value = Utils.isNullOrUndefined(value) ? '' : value;

        return `${key}=${value}`;
    }

    private static buildParams(paramName: string | null, object: any): string[]
    {
        const stringParams: string[] = [];

        if (paramName !== null)
        {
            if (Array.isArray(object))
            {
                for (let i: number = 0; i < object.length; i++)
                {
                    //this.buildParams(`${paramName}[${(typeof object[i] === 'object' && object[i] ? i : '')}]`, object[i]);
                    if (typeof object[i] === 'object' && object[i])
                    {
                        stringParams.push(...this.buildParams(`${paramName}[${i}]`, object[i]));
                    }
                    else
                    {
                        stringParams.push(this.addParam(paramName, JSON.stringify(object)));
                    }
                }
            }
            else if (Object.prototype.toString.call(object) === '[object Object]')
            {
                for (const key in object)
                {
                    //stringParams.push(...this.buildParams(`${paramName}[${key}]`, object[key]));
                    stringParams.push(...this.buildParams(`${paramName}.${key}`, object[key]));
                }
            }
            else
            {
                stringParams.push(this.addParam(paramName, object));
            }
        }
        else if (Array.isArray(object))
        {
            for (let i: number = 0; i < object.length; i++)
            {
                stringParams.push(this.addParam(object[i].name, object[i].value));
            }
        }
        else
        {
            for (const key in object)
            {
                stringParams.push(...this.buildParams(key, object[key]));
            }
        }

        return stringParams;
    }

    // #endregion
}