<!DOCTYPE html>

<div class="page-container page-image">
    <button class="main-menu-button image-button ripple-effect ripple-center" (click)="showMenuType = ShowMenuType.MainMenu">
        <i class="icon-menu-alt"></i>
    </button>
    <div class="bar-title select-none">{{ barTitle }}</div>
    <user-menu></user-menu>
</div>

@if (showMenuType !== ShowMenuType.None) {
<div tabindex="-1" class="overlay" (click)="showMenuType = ShowMenuType.None" @fadeInOutAnimation></div>
}
@if (showMenuType === ShowMenuType.MainMenu) {
<div class="menu-container page-container page-image" @slideInOutAnimation pointerEvents [useMouseWheel]="false"
     (swipe)="onMenuSwipe($event)">
    <button class="back-button image-button ripple-effect ripple-center ripple-inverse-color" (click)="showMenuType = ShowMenuType.None">
        <i class="icon-collapse-left"></i>
    </button>
    <div class="main-menu-header">
        <img src="assets/vectors/logo-icon.svg">
    </div>
    <button type="button" class="settings-button ripple-effect ripple-center" (click)="showMenuType = ShowMenuType.SettingsMenu"><i class="icon-gear"></i></button>
    <div class="main-menu select-none page-scroll-padding" (click)="onMenuClick($event)">
        <div class="main-menu-section-title">Insights</div>
        @for (menuItem of topbarAppMenuItems; track menuItem) {
        <div class="main-menu-item ripple-effect ripple-inverse-color" (click)="onMenuItemClick(menuItem.routeType)">
            <i [class]="menuItem.iconClass"></i><span>{{ menuItem.text }}</span>
        </div>
        }
    </div>
    <div class="main-menu-footer page-padding">
        <img src="assets/vectors/logo.svg">
    </div>
</div>
}
@if (showMenuType === ShowMenuType.SettingsMenu) {
<div class="menu-container page-container page-image" @slideInOutAnimation pointerEvents [useMouseWheel]="false"
     (swipe)="onMenuSwipe($event)">
    <settings (closed)="showMenuType = ShowMenuType.None"></settings>
</div>
}
