<div class="dropdown user-info" dropdown [(isExpanded)]="showUserMenu">
    <button type="button" class="user-button ripple-effect ripple-inverse-color"><span>{{ loginModel.userInfo.initials }}</span></button>
    @if (showUserMenu && dropdownPosition === DropdownPosition.Bottom) {
    <div class="dropdown-container" @foldAnimation>
        <ng-container [ngTemplateOutlet]="userMenuTemplate"></ng-container>
    </div>
    }
    @if (showUserMenu && dropdownPosition === DropdownPosition.Right) {
    <div class="dropdown-container" @foldHorizontalAnimation>
        <ng-container [ngTemplateOutlet]="userMenuTemplate"></ng-container>
    </div>
    }
</div>

<ng-template #userMenuTemplate>
    <div class="user-menu">
        <div class="user-welcome"><i class="icon-user"></i> Hello, {{ loginModel.userInfo.firstName }}</div>
        <button type="button" class="menu-button" (click)="onSupportButtonClick()"><i class="icon-more-info"></i>Support</button>
        @if (!loginModel.isAmazonEmailLogin) {
        <button type="button" class="menu-button" (click)="onChangePasswordButtonClick()"><i class="icon-lock"></i>Change Password</button>
        }
        <button type="button" class="menu-button" (click)="onLogoutButtonClick()"><i class="icon-logout"></i>Logout</button>
    </div>
</ng-template>
