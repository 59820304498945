import { Injectable } from "@angular/core";
import { DemurrageContract, DemurrageContractPort, DemurrageContractPortItem, DisplayDemurrageContract, Perimeter } from "./demurrage-model.class";
import { Utils } from "../../../utils/utils";
import { cloneDeep, isEqual } from "lodash";
import { DemurrageLists } from "../../../demurrage-manager/demurrage-manager/model/demurrage-manager-model.class";
import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { DemurrageManagerModel } from "../../../demurrage-manager/demurrage-manager/model/demurrage-manager.model";
import { LoginModel } from "../../../user/login/model/login.model";
import { Constants, IApiResponseData } from "../../../utils/globals";
import { HttpErrorCodes } from "../../../utils/http-error-codes";
import { Observable, Observer } from "rxjs";
import { HttpHelper } from "../../../utils/http-helper";
import { ManagerItemStatus } from "../../../base/models/manager-base-model.class";
import { ManagerItemBaseModel } from "../../../base/models/managaer-item-base.model";

@Injectable({ providedIn: 'root' })
export class DemurrageModel extends ManagerItemBaseModel<DisplayDemurrageContract>
{
    // #region Private Members

    private _isContractPeriodValid: boolean = true;
    private _isSelectedPerimeterIdsFull: boolean = false;

    // #endregion

    // #region Properties

    public get isSelectedPerimeterIdsFull(): boolean
    {
        return this._isSelectedPerimeterIdsFull;
    }

    public get isContractPeriodValid(): boolean
    {
        return this._isContractPeriodValid;
    }

    public get demurrageLists(): DemurrageLists
    {
        return this._demurrageManagerModel.demurrageLists;
    }

    // #endregion

    // #region Constructors

    constructor(private _httpClient: HttpClient, private _demurrageManagerModel: DemurrageManagerModel, private _loginModel: LoginModel)
    {
        super(DisplayDemurrageContract);
    }

    // #endregion

    // #region Public Methods

    public override initialize(): void
    {
        super.initialize();

        this._isContractPeriodValid = true;
        this._isSelectedPerimeterIdsFull = false;

        if (this._demurrageManagerModel.selectedManagerItem !== null)
        {
            this._managerItem = cloneDeep(this._demurrageManagerModel.selectedManagerItem);
            this.initializeDemurrageContractFreeDaysPortIds();
        }
        else
        {
            this._managerItem.corpId = this._loginModel.userInfo.corpId;
        }

        this.updateDemurrageContractPerimetersLists();
        this.setValidManagerItem();
    }

    public override submitManagerItem(isDelete: boolean): Observable<IApiResponseData<DisplayDemurrageContract>>
    {
        this._isBusy = true;

        return new Observable((observer: Observer<IApiResponseData<DisplayDemurrageContract>>) =>
        {
            let httpUpdater: Observable<any>;

            const demurrageContract: DemurrageContract =
                Utils.clearObjectEmptyStrings(Utils.copyObjectByTargetProperties(this._managerItem, new DemurrageContract()));

            demurrageContract.demurrageContractPorts = this.getDemurrageContractPorts();

            if (isDelete)
            {
                demurrageContract.statusId = ManagerItemStatus.Canceled;
            }

            const demurrageUrl: string = 'demurrage-manager/demurrage';
            const body: string = JSON.stringify(demurrageContract);
            const httpHeaders: HttpHeaders = HttpHelper.GetHttpJsonHeaders();

            if (this._managerItem.contractId == null)
            {
                httpUpdater = this._httpClient.post<DemurrageContract>(demurrageUrl, body, { headers: httpHeaders });
            }
            else
            {
                httpUpdater = this._httpClient.put<DemurrageContract>(demurrageUrl, body, { headers: httpHeaders });
            }

            httpUpdater.subscribe(
                {
                    next: (demurrageContract: DemurrageContract) =>
                    {
                        this._isBusy = false;

                        if (demurrageContract !== null && this._managerItem.contractId === null)
                        {
                            this._managerItem.contractId = demurrageContract.contractId;
                        }

                        observer.next({ isSuccess: true, isComplete: true, data: this._managerItem });
                        observer.complete();
                    },
                    error: (error: HttpErrorResponse) =>
                    {
                        this._isBusy = false;
                        console.error(error);

                        observer.next(
                            {
                                isSuccess: false,
                                isComplete: true,
                                message: error.status === HttpErrorCodes.NOT_ACCEPTABLE ? 'Editing exceeded the time allowd!' :
                                    Constants.DATA_SERVICE_ERROR_STRING
                            });
                        observer.complete();
                    }
                });
        });
    }

    public addPortData(): void
    {
        this._managerItem.demurrageContractPortsItems =
            [...this._managerItem.demurrageContractPortsItems, new DemurrageContractPortItem()];

        this.updateDemurrageContractPerimetersLists();
    }

    public isPortDataEmpty(portDataIndex: number): boolean
    {
        const demurrageContractPortsItem: DemurrageContractPortItem = cloneDeep(this._managerItem.demurrageContractPortsItems[portDataIndex]);
        demurrageContractPortsItem.perimeters = [];

        return isEqual(demurrageContractPortsItem, new DemurrageContractPortItem());
    }

    public deletePortData(portDataIndex: number): void
    {
        this._managerItem.demurrageContractPortsItems.splice(portDataIndex, 1);
        this._managerItem.demurrageContractPortsItems = [...this._managerItem.demurrageContractPortsItems];

        this.updateDemurrageContractPerimetersLists();
    }

    public updateDemurrageContractPerimetersLists(): void
    {
        let usedPerimetersIdsCount: number = 0;
        for (let i: number = 0; i < this._managerItem.demurrageContractPortsItems.length; i++)
        {
            const usedPerimeterIds: string[] = [];
            for (let j: number = 0; j < this._managerItem.demurrageContractPortsItems.length; j++)
            {
                if (j === i)
                {
                    continue;
                }

                usedPerimeterIds.push(...this._managerItem.demurrageContractPortsItems[j].portIds);
            }

            usedPerimetersIdsCount += this._managerItem.demurrageContractPortsItems[i].portIds.length;

            this._managerItem.demurrageContractPortsItems[i].perimeters = this._demurrageManagerModel.demurrageLists.perimeters.filter(
                (perimeter: Perimeter) => !usedPerimeterIds.includes(perimeter.perimeterId!));
        }

        this._isSelectedPerimeterIdsFull = usedPerimetersIdsCount === this._demurrageManagerModel.demurrageLists.perimeters.length;
    }

    public validateContractPeriod(): void
    {
        if (this._managerItem.fromDate === null || this._managerItem.toDate === null)
        {
            this._isContractPeriodValid = true;
            return;
        }

        for (const demurrageContract of this._demurrageManagerModel.managerItems)
        {
            if (demurrageContract.contractId !== this._managerItem.contractId &&
                demurrageContract.carrierId === this._managerItem.carrierId)
            {
                if ((this._managerItem.fromDate.getTime() >= demurrageContract.fromDate!.getTime() ||
                    this._managerItem.toDate.getTime() >= demurrageContract.fromDate!.getTime()) &&
                    (this._managerItem.fromDate.getTime() <= demurrageContract.toDate!.getTime() ||
                    this._managerItem.toDate.getTime() <= demurrageContract.toDate!.getTime()))
                {
                    this._isContractPeriodValid = false;
                    return;
                }
            }
        }

        this._isContractPeriodValid = true;
    }

    // #endregion

    // #region Private Methods

    private getDemurrageContractPorts(): DemurrageContractPort[]
    {
        const demurrageContractPorts: DemurrageContractPort[] = [];
        for (const demurrageContractPortsItem of this._managerItem.demurrageContractPortsItems)
        {
            demurrageContractPorts.push(...demurrageContractPortsItem.portIds.map((demurrageContractPortId: string) =>
            {
                return {
                    contractId: this._managerItem.contractId,
                    polFreeDays: demurrageContractPortsItem.polFreeDays,
                    podFreeDays: demurrageContractPortsItem.podFreeDays,
                    perimeterId: demurrageContractPortId,
                    modified: null
                };
            }));
        }

        return demurrageContractPorts;
    }

    private initializeDemurrageContractFreeDaysPortIds(): void
    {
        this._managerItem.demurrageContractPortsItems = [];

        const demurrageContractFreeDaysPortIdsMap: Map<string, string[]> = new Map();
        for (const demurrageContractPort of this._managerItem.demurrageContractPorts)
        {
            if (demurrageContractPort.polFreeDays !== null && demurrageContractPort.podFreeDays !== null && demurrageContractPort.perimeterId !== null)
            {
                let demurrageContractPortIds: string[] | undefined =
                    demurrageContractFreeDaysPortIdsMap.get(JSON.stringify([demurrageContractPort.polFreeDays, demurrageContractPort.podFreeDays]));

                if (demurrageContractPortIds === undefined)
                {
                    demurrageContractPortIds = [];
                    demurrageContractFreeDaysPortIdsMap.set(JSON.stringify([demurrageContractPort.polFreeDays, demurrageContractPort.podFreeDays]),
                        demurrageContractPortIds);
                }

                demurrageContractPortIds.push(demurrageContractPort.perimeterId);
            }

        }

        for (const demurrageContractFreeDaysPortIds of demurrageContractFreeDaysPortIdsMap)
        {
            const freeDays: [number, number] = JSON.parse(demurrageContractFreeDaysPortIds[0]);

            this._managerItem.demurrageContractPortsItems.push(
                {
                    polFreeDays: freeDays[0],
                    podFreeDays: freeDays[1],
                    portIds: demurrageContractFreeDaysPortIds[1],
                    perimeters: []
                });
        }
    }

    // #endregion
}