<!DOCTYPE html>

<input class="form-control has-icon clearable" #inputElement placeholder=" " [(ngModel)]="value" [attr.type]="showPassword ? 'text' : 'password'" 
       [attr.autocomplete]="isAutoFill ? null : 'new-password'"/>
<label class="control-label" [class.required]="isRequired">{{ label }}</label>
@if (showPassword) {
  <ng-container [ngTemplateOutlet]="passwordIconTemplate"></ng-container>
}
@if (!showPassword) {
  <ng-container [ngTemplateOutlet]="passwordIconTemplate"></ng-container>
}
@if (value !== null && value.length > 0) {
  <i class="icon-cancel control-icon press-icon ripple-effect ripple-center"
    @fadeInOutAnimation (click)="value = null">
  </i>
}

<ng-template #passwordIconTemplate>
  <i class="control-icon ripple-effect ripple-center ripple-inverse-color" @fadeInOutAnimation [class]="showPassword ? 'icon-eye' : 'icon-eye-off'"
    (mousedown)="onShowPasswordIconMouseDown($event)">
  </i>
</ng-template>

