import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, Observer } from "rxjs";
import { HttpHelper } from "../utils/http-helper";

export class PBIData
{
    public token: string | undefined = undefined;
    public reportId: string | undefined = undefined;
    public pageId: string | undefined = undefined;
    public filterString: string | undefined = undefined;
    public expiration: string | undefined = undefined;
}

@Injectable({ providedIn: 'root' })
export class PowerBiService
{
    // #region Constructors

    constructor(private _httpClient: HttpClient)
    {
    }

    // #endregion

    // #region Public Methods

    public getPowerBIReportData(reportName: string): Observable<PBIData | null>
    {
        return new Observable((observer: Observer<PBIData | null>) =>
        {
            this._httpClient.get<PBIData>(`pbi/data/${reportName}`, { headers: HttpHelper.GetHttpFormUrlencodedHeaders() }).subscribe(
                {
                    next: (pbiData: PBIData) =>
                    {
                        observer.next(pbiData);
                        observer.complete();
                    },
                    error: (error: HttpErrorResponse) =>
                    {
                        console.error(error);

                        observer.next(null);
                        observer.complete();
                    }
                });
        });
    }

    // #endregion
}