import { trigger, animate, transition, style, state, query, animateChild, group, AnimationTriggerMetadata, keyframes } from '@angular/animations';
import { AnimationsConstants } from './constant';

export enum SlideAnimationStateType { LeftIn = "LeftIn", LeftOut = "LeftOut", RightIn = "RightIn", RightOut = "RightOut" };

export const slideInOutStateAnimation: AnimationTriggerMetadata =
	trigger('slideInOutStateAnimation',
		[
			state(SlideAnimationStateType.LeftIn, style({ transform: AnimationsConstants.SLIDE_IN_STYLE, visibility: 'visible' })),
			state(SlideAnimationStateType.RightIn, style({ transform: AnimationsConstants.SLIDE_IN_STYLE, visibility: 'visible' })),
			state(SlideAnimationStateType.LeftOut, style({ transform: AnimationsConstants.SLIDE_LEFT_STYLE, visibility: 'hidden' })),
			state(SlideAnimationStateType.RightOut, style({ transform: AnimationsConstants.SLIDE_RIGHT_STYLE, visibility: 'hidden' })),
			transition('RightOut => LeftIn', animate(AnimationsConstants.ANIMATION_LONG_TIMING, style({ transform: AnimationsConstants.SLIDE_IN_STYLE, visibility: 'visible' }))),
			transition('LeftOut => RightIn', animate(AnimationsConstants.ANIMATION_LONG_TIMING, style({ transform: AnimationsConstants.SLIDE_IN_STYLE, visibility: 'visible' }))),
			transition('LeftIn => LeftOut', animate(AnimationsConstants.ANIMATION_LONG_TIMING, style({ transform: AnimationsConstants.SLIDE_LEFT_STYLE, visibility: 'visible' }))),
			transition('RightIn => LeftOut', animate(AnimationsConstants.ANIMATION_LONG_TIMING, style({ transform: AnimationsConstants.SLIDE_LEFT_STYLE, visibility: 'visible' }))),
			transition('RightIn => RightOut', animate(AnimationsConstants.ANIMATION_LONG_TIMING, style({ transform: AnimationsConstants.SLIDE_RIGHT_STYLE, visibility: 'visible' }))),
			transition('LeftIn => RightOut', animate(AnimationsConstants.ANIMATION_LONG_TIMING, style({ transform: AnimationsConstants.SLIDE_RIGHT_STYLE, visibility: 'visible' })))
		]);

export const slideZoomInOutStateAnimation: AnimationTriggerMetadata =
	trigger('slideZoomInOutStateAnimation',
		[
			state(SlideAnimationStateType.LeftIn, style({ transform: AnimationsConstants.SLIDE_IN_TRANSFORM_STYLE, visibility: 'visible' })),
			state(SlideAnimationStateType.RightIn, style({ transform: AnimationsConstants.SLIDE_IN_TRANSFORM_STYLE, visibility: 'visible' })),
			state(SlideAnimationStateType.LeftOut, style({ transform: AnimationsConstants.SLIDE_ZOOM_OUT_TRANSFORM_STYLE, visibility: 'hidden' })),
			state(SlideAnimationStateType.RightOut, style({ transform: AnimationsConstants.SLIDE_ZOOM_OUT_TRANSFORM_STYLE, visibility: 'hidden' })),
			transition('RightOut => LeftIn',
				[
					style({ visibility: 'visible', zIndex: 1 }),
					animate(AnimationsConstants.ANIMATION_ZOOM_SLIDE_TIMING, keyframes(
						[
							style({ transform: 'scale(0) translateX(50%)', opacity: 0, offset: 0 }),
							style({ transform: 'scale(0.5) translateX(33%)', opacity: 1, offset: 0.5 }),
							style({ transform: 'scale(1) translateX(0%)', opacity: 1, offset: 1 })
						]))
				]),
			transition('LeftOut => RightIn',
				[
					style({ visibility: 'visible', zIndex: 1 }),
					animate(AnimationsConstants.ANIMATION_ZOOM_SLIDE_TIMING, keyframes(
						[
							style({ transform: 'scale(0) translateX(-50%)', opacity: 0, offset: 0 }),
							style({ transform: 'scale(0.5) translateX(-33%)', opacity: 1, offset: 0.5 }),
							style({ transform: 'scale(1) translateX(0%)', opacity: 1, offset: 1 })
						]))
				]),
			transition('LeftIn => LeftOut',
				[
					animate(AnimationsConstants.ANIMATION_ZOOM_SLIDE_TIMING, keyframes(
						[
							style({ transform: 'scale(1) translateX(0%)', opacity: 1, offset: 0 }),
							style({ transform: 'scale(0.5) translateX(-33%)', opacity: 1, offset: 0.5 }),
							style({ transform: 'scale(0) translateX(-50%)', opacity: 0, offset: 1 })
						]))
				]),
			transition('RightIn => LeftOut',
				[
					animate(AnimationsConstants.ANIMATION_ZOOM_SLIDE_TIMING, keyframes(
						[
							style({ transform: 'scale(1) translateX(0%)', opacity: 1, offset: 0 }),
							style({ transform: 'scale(0.5) translateX(-33%)', opacity: 1, offset: 0.5 }),
							style({ transform: 'scale(0) translateX(-50%)', opacity: 0, offset: 1 })
						]))
				]),
			transition('RightIn => RightOut',
				[
					animate(AnimationsConstants.ANIMATION_ZOOM_SLIDE_TIMING, keyframes(
						[
							style({ transform: 'scale(1) translateX(0%)', opacity: 1, offset: 0 }),
							style({ transform: 'scale(0.5) translateX(33%)', opacity: 1, offset: 0.5 }),
							style({ transform: 'scale(0) translateX(50%)', opacity: 0, offset: 1 })
						]))
				]),
			transition('LeftIn => RightOut',
				[
					animate(AnimationsConstants.ANIMATION_ZOOM_SLIDE_TIMING, keyframes(
						[
							style({ transform: 'scale(1) translateX(0%)', opacity: 1, offset: 0 }),
							style({ transform: 'scale(0.5) translateX(33%)', opacity: 1, offset: 0.5 }),
							style({ transform: 'scale(0) translateX(50%)', opacity: 0, offset: 1 })
						]))
				])
		]);

export const slideOutAnimation: AnimationTriggerMetadata =
	trigger('slideOutAnimation',
		[
			transition(':enter',
				[
					style({ transform: AnimationsConstants.SLIDE_IN_STYLE })
				]
			),
			transition(':leave',
				[
					style({ transform: AnimationsConstants.SLIDE_IN_STYLE }),
					animate(AnimationsConstants.ANIMATION_LONG_TIMING, style({ transform: AnimationsConstants.SLIDE_LEFT_STYLE }))
				]
			)
		]);

export const slideInAnimation: AnimationTriggerMetadata =
	trigger('slideInAnimation',
		[
			transition(':enter',
				[
					style({ transform: AnimationsConstants.SLIDE_LEFT_STYLE })
				]
			),
			transition(':leave',
				[
					style({ transform: AnimationsConstants.SLIDE_LEFT_STYLE }),
					animate(AnimationsConstants.ANIMATION_LONG_TIMING, style({ transform: AnimationsConstants.SLIDE_IN_STYLE }))
				]
			)
		]);

export const slideInOutAnimation: AnimationTriggerMetadata =
	trigger('slideInOutAnimation',
		[
			transition(':enter',
				[
					style({ transform: AnimationsConstants.SLIDE_LEFT_STYLE }),
					animate(AnimationsConstants.ANIMATION_LONG_TIMING, style({ transform: AnimationsConstants.SLIDE_IN_STYLE }))
				]
			),
			transition(':leave',
				[
					style({ transform: AnimationsConstants.SLIDE_IN_STYLE }),
					animate(AnimationsConstants.ANIMATION_LONG_TIMING, style({ transform: AnimationsConstants.SLIDE_LEFT_STYLE }))
				]
			)
		]);

export const slideInOutRightAnimation: AnimationTriggerMetadata =
	trigger('slideInOutRightAnimation',
		[
			transition(':enter',
				[
					style({ transform: AnimationsConstants.SLIDE_LEFT_STYLE }),
					animate(AnimationsConstants.ANIMATION_LONG_TIMING, style({ transform: AnimationsConstants.SLIDE_IN_STYLE }))
				]
			),
			transition(':leave',
				[
					style({ transform: AnimationsConstants.SLIDE_IN_STYLE }),
					animate(AnimationsConstants.ANIMATION_LONG_TIMING, style({ transform: AnimationsConstants.SLIDE_RIGHT_STYLE }))
				]
			)
		]);

export const slideInOutReverseAnimation: AnimationTriggerMetadata =
	trigger('slideInOutReverseAnimation',
		[
			transition(':enter',
				[
					style({ transform: AnimationsConstants.SLIDE_RIGHT_STYLE }),
					animate(AnimationsConstants.ANIMATION_LONG_TIMING, style({ transform: AnimationsConstants.SLIDE_IN_STYLE }))
				]
			),
			transition(':leave',
				[
					style({ transform: AnimationsConstants.SLIDE_IN_STYLE }),
					animate(AnimationsConstants.ANIMATION_LONG_TIMING, style({ transform: AnimationsConstants.SLIDE_RIGHT_STYLE }))
				]
			)
		]);

export const slideUpDownAnimation: AnimationTriggerMetadata =
	trigger('slideUpDownAnimation',
		[
			transition(':enter',
				[
					style({ transform: AnimationsConstants.SLIDE_DOWN_STYLE }),
					animate(AnimationsConstants.ANIMATION_LONG_TIMING, style({ transform: AnimationsConstants.SLIDE_IN_STYLE }))
				]
			),
			transition(':leave',
				[
					style({ transform: AnimationsConstants.SLIDE_IN_STYLE }),
					animate(AnimationsConstants.ANIMATION_LONG_TIMING, style({ transform: AnimationsConstants.SLIDE_DOWN_STYLE }))
				]
			)
		]);

export const slideOutletAnimation: AnimationTriggerMetadata =

	trigger('slideOutletAnimation',
		[
			transition('* => *',
				[
					query(':enter',
						[
							style({ transform: AnimationsConstants.SLIDE_RIGHT_STYLE })
						],
						{ optional: true }
					),
					query(':leave', animateChild(), { optional: true }),
					group(
						[
							query(':leave',
								[
									animate(AnimationsConstants.ANIMATION_LONG_TIMING, style({ transform: AnimationsConstants.SLIDE_LEFT_STYLE }))
								],
								{ optional: true }),
							query(':enter',
								[
									animate(AnimationsConstants.ANIMATION_LONG_TIMING, style({ transform: AnimationsConstants.SLIDE_IN_STYLE }))
								],
								{ optional: true })
						]),
					query(':enter', animateChild(), { optional: true }),
				])
		]);