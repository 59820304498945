import
{
    ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EmbeddedViewRef, HostListener, Input, NgZone, OnDestroy, OnInit,
    QueryList, TemplateRef, ViewChild, ViewChildren, ViewContainerRef
} from '@angular/core';
import { ShipmentMonitoringModel } from './model/shipment-monitoring.model';
import { Subscription } from 'rxjs';
import { Constants, IApiResponse, RouteType } from '../../utils/globals';
import { fadeInAnimation, fadeInOutAnimation, fadeVisibilityAnimation, shortFadeInOutAnimation } from '../../animations/fade.animation';
import { foldAnimation, foldHorizontalAnimation, foldHorizontalStateAnimation } from '../../animations/fold.animation';
import { Router } from '@angular/router';
import { DateTimeFormatType, Utils } from '../../utils/utils';
import
{
    ApexAxisChartSeries, ApexChart, ApexDataLabels, ApexPlotOptions, ApexYAxis, ApexStroke, ApexXAxis, ApexTooltip, ApexStates, ApexTitleSubtitle, ApexAnnotations,
    YAxisAnnotations, XAxisAnnotations, ChartComponent, ApexMarkers, NgApexchartsModule
} from "ng-apexcharts";
import
{
    ChartSelection,
    ChartXAxisMinMaxZoom,
    DisplayShipmentMonitoringEvent, DisplayShipmentMonitoringPlanSegment, DisplayShipmentMonitoringPlanSubSegment, DisplayShipmentMonitoringTelemetry,
    MonitoringEventTypeOverview,
    ShipmentMonitoringPerimeterType,
    ShipmentMonitoringPlanSegmentStatusType,
    ShipmentMonitoringTelemetry,
    ShipmentMonitoringTelemetryLogicStateType,
    ShipmentMonitoringTelemetryReasonType,
    ShipmentMonitoringTelemetrySourceType
} from './model/shipment-monitoring-model.class';
import { isEqual } from 'lodash';
import { CGIMarkerClusterer, onClusterClickHandler } from '../../controls/google-map/utils/cgi-marker-clusterer';
import { CGIEventsClusterRenderer } from './map/cgi-events-cluster-renderer';
import { ModalMessageService } from '../../controls/modal-message/services/modal-message.service';
import { CGIMarkerItemMonitoringIconFactory } from "./map/cgi-marker-item-monitoring-icon-factory";
import { RoutingHistoryService } from '../../services/routing-history.service';
import { AnimationsConstants } from '../../animations/constant';
import { AppSettingsService } from '../../services/app-settings.service';
import { StringTableUtils } from '../../utils/string-table-utils';
import { LoginModel } from '../../user/login/model/login.model';
import { GoogleMapUtils } from '../../controls/google-map/utils/google-map-utils';
import { VirtualListInfo } from '../../controls/virtual-list/model/virtual-list-info';
import { CGICluster } from '../../controls/google-map/utils/cgi-cluster';
import { MonitoringBaseComponent } from '../../base/components/monitoring-base.component';
import { ManagerItemStatus } from '../../base/models/manager-base-model.class';
import { ICGIMarker, ICGIMarkerLocation } from '../../base/classes/cgi-marker';
import { MonitoringUtils, DisplayProperties, ManagerConstants } from '../../utils/monitoring-utils';
import { CGIClusterBaseRenderer } from '../../controls/google-map/utils/cgi-cluster-base-renderer';
import { CGIShipmentsClusterRenderer } from '../../shipments-monitoring/shipments-monitoring/map/cgi-shipments-cluster-renderer';
import { FilterElement, MonitoringDetailedEventType } from '../../base/models/monitoring-items-base-model.class';
import * as XLSX from 'xlsx';
import { GlobalsPipe } from '../../pipes/globals.pipe';
import { LoaderComponent } from '../../controls/loader/loader.component';
import { GoogleMapComponent } from '../../controls/google-map/google-map.component';
import { TooltipDirective } from '../../directives/tooltip.directive';
import { NgTemplateOutlet } from '@angular/common';
import { OverlayScrollbarDirective } from '../../directives/overlay-scrollbar/overlay-scrollbar.directive';
import { VirtualListComponent } from '../../controls/virtual-list/virtual-list.component';
import { FormsModule } from '@angular/forms';
import { AutofocusDirective } from '../../directives/autofocus.directive';
import { ClearableInputComponent } from '../../controls/clearable-input/clearable-input.component';
import { DropdownDirective } from '../../directives/dropdown.directive';

type MapArrowCreationData =
    {
        coordinate: google.maps.LatLngLiteral;
        rotation: number;
        telemetry: ShipmentMonitoringTelemetry;
    };

type ChartOptions =
    {
        chart: ApexChart;
        dataLabels: ApexDataLabels;
        title: ApexTitleSubtitle,
        plotOptions: ApexPlotOptions;
        markers: ApexMarkers;
        colors: any[];
        yaxis: ApexYAxis;
        xaxis: ApexXAxis;
        annotations: ApexAnnotations;
        stroke: ApexStroke;
        tooltip: ApexTooltip;
        states: ApexStates;
    };

type ChartDetails =
    {
        chartOptions: ChartOptions;
        chartSeries: ApexAxisChartSeries;
    };

@Component({
    selector: 'shipment-monitoring',
    templateUrl: './shipment-monitoring.component.html',
    styleUrls: ['../../base/styles/monitoring-base.component.css', './shipment-monitoring.component.css', '../../base/styles/manager-table.css'],
    animations: [fadeInAnimation, fadeInOutAnimation, shortFadeInOutAnimation, foldHorizontalAnimation, foldAnimation, fadeVisibilityAnimation,
        foldHorizontalStateAnimation],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [DropdownDirective, ClearableInputComponent, AutofocusDirective, FormsModule, VirtualListComponent, OverlayScrollbarDirective, NgTemplateOutlet,
        TooltipDirective, NgApexchartsModule, GoogleMapComponent, LoaderComponent, GlobalsPipe]
})
export class ShipmentMonitoringComponent extends MonitoringBaseComponent<DisplayShipmentMonitoringEvent> implements OnInit, OnDestroy
{
    // #region Constants

    protected override readonly SHARING_MAP_TITLE: string = 'Shipment Monitoring';

    private readonly EXCEL_FILE_PREFIX: string = 'Monitoring_';

    private readonly CHART_THRESHOLD_CLASSNAME: string = 'chart-threshold-annotaion';
    private readonly CHART_THRESHOLD_MIN_ANNOTATION_ID_PREFIX: string = 'threshold-min-annotation-';
    private readonly CHART_THRESHOLD_MAX_ANNOTATION_ID_PREFIX: string = 'threshold-max-annotation-';
    private readonly CURRENT_PLAN_SEGMENT_CLASS_SELECTOR: string = '.plan-segment.current';
    private readonly SELECTED_PLAN_SEGMENT_CLASS_SELECTOR: string = '.plan-segment.selected > tr:first-child';
    private readonly CGIMARKER_MAP_INFO_TELEMETRY_CLASSNAME: string = 'cgimarker-info-telemetry';
    private readonly CGIMARKER_CHART_TELEMETRY_CLASSNAME: string = 'cgimarker-chart-telemetry';
    private readonly CGIMARKER_CHART_TELEMETRY_CURRENT_CLASSNAME: string = 'cgimarker-chart-telemetry-current';
    private readonly ZOOM_AREA_CHART_COLOR_PROPERTY: string = '--color-chart-zoom-area';
    private readonly MAP_TIMELINE_LINE_COLOR_PROPERTY: string = '--color-map-timeline-line';

    private readonly CHART_EVENT_ANNOTATION_ID_PREFIX: string = 'annotation-';
    private readonly CHART_GRID_SELECTOR: string = '.apexcharts-grid';

    private readonly SHARING_EVENT_INFO_TITLE: string = 'Contguard Insights Event Info:';

    private readonly PLANE_SEGMENT_TBD_PERIMETER_LATITUDE: number = -90;

    private readonly MAP_POLYLINE_WIDTH: number = 3;
    private readonly MAP_EVENT_POLYLINE_WIDTH: number = 8;
    private readonly MAP_PLAN_POLYLINE_WIDTH: number = 8;
    private readonly MAP_PLAN_ARROW_MIN_DISTANCE: number = 45;

    private readonly PLAN_PATH_ZINDEX: number = 1;
    private readonly PLAN_MARKER_ZINDEX: number = 2;
    private readonly PLAN_DESTINATION_MARKER_ZINDEX: number = 3;
    private readonly PLAN_CURRENT_MARKER_ZINDEX: number = 4;
    private readonly EVENT_MARKER_ZINDEX: number = 5;
    private readonly PLAN_CURRENT_POSITION_MARKER_ZINDEX: number = CGIMarkerClusterer.MARKER_TOPMOST_ZINDEX - 1;
    private readonly CHART_TELEMETRY_MAP_MARKER_ZINDEX: number = CGIMarkerClusterer.MARKER_TOPMOST_ZINDEX + 1;
    private readonly PLAN_SEGMENT_ZOOM: number = 17;
    private readonly PLAN_SEGMENT_SHOW_PATH_DURATION: number = 2000;
    private readonly CHART_ZOOM_RANGE_DIVIDER_STEP: number = 5;
    private readonly CHART_ZOOM_RANGE_MIN: number = 3000;
    private readonly CHART_ZOOM_RANGE_OFFSET: number = 30000000;

    // #endregion

    // #region Private Members

    private static _collapseShipmentMonitoringCharts: boolean = false;
    private static _collapseShipmentMonitoringMap: boolean = false;
    private static _availableCharts: ChartSelection[] = [];

    private _filterEventElements: FilterElement[] = [];
    private _getShipmentMonitoringOverviewSubscription: Subscription | null = null;
    private _shipmentKey: string = '';
    private _isArchived: boolean = false;

    private _isShipmentMonitoringPlanSubSegmentsExpanded: boolean = true;

    private _chartsDetails: ChartDetails[] = [];
    private _chartXAxisMinMaxZoom: ChartXAxisMinMaxZoom | null = null;

    private _chartThresholdAnnotaionIndex: number = 0;

    private _cgiMarkerItemMonitoringIconFactory: CGIMarkerItemMonitoringIconFactory = new CGIMarkerItemMonitoringIconFactory();

    private _chartClickTimeoutHandleId: NodeJS.Timeout | null = null;

    private _mapMarkers: google.maps.marker.AdvancedMarkerElement[] = [];
    private _chartTelemetryMapMarker: google.maps.marker.AdvancedMarkerElement | null = null;

    private _mapArrowMarkers: google.maps.marker.AdvancedMarkerElement[] = [];
    private _mapArrowMarkersCreationData: MapArrowCreationData[] = [];
    private _mapPolylines: google.maps.Polyline[] = [];
    private _mapPlanMarkers: google.maps.marker.AdvancedMarkerElement[] = [];
    private _mapCurrentTelemetryMarker: google.maps.marker.AdvancedMarkerElement | null = null;

    private _mapContentBounds: google.maps.LatLngBounds | null = null;

    private _showCurrentTelemetryLocationMarker: boolean = true;

    private readonly _eventsIconsDisplayProperties: DisplayProperties[];

    @ViewChild('infoWindowPlanMarkerTemplate', { read: TemplateRef }) private _infoWindowPlanMarkerTemplateRef!: TemplateRef<any>;
    @ViewChild('infoWindowMarkerTelemetryTemplate', { read: TemplateRef }) private _infoWindowMarkerTelemetryTemplateRef!: TemplateRef<any>;

    private _charts: QueryList<ChartComponent> | undefined = undefined
    @ViewChildren(ChartComponent) set charts(charts: QueryList<ChartComponent>)
    {
        this._charts = charts;

        requestAnimationFrame(() =>
        {
            if (this._charts === undefined)
            {
                return;
            }

            for (const chart of this._charts)
            {
                if (this._chartXAxisMinMaxZoom !== null)
                {
                    chart.zoomX(this._chartXAxisMinMaxZoom.current.min, this._chartXAxisMinMaxZoom.current.max);
                }

                this.updateChartYAxisTicksAmount(chart, chart.yaxis as ApexYAxis);
            }
        });
    }

    // #endregion

    // #region Properties

    public isChartsSectionResizing: boolean = false;

    public get eventsIconsDisplayProperties(): DisplayProperties[]
    {
        return this._eventsIconsDisplayProperties;
    }

    public get shipmentEventsStyleProperties(): DisplayProperties[]
    {
        return MonitoringUtils.SHIPMENT_EVENTS_DISPLAY_PROPERTIES;
    }

    public get shipmentArrivalStatusesStyleProperties(): DisplayProperties[]
    {
        return MonitoringUtils.SHIPMENT_ARRIVAL_STATUSES_DISPLAY_PROPERTIES;
    }

    public get loginModel(): LoginModel
    {
        return this._loginModel;
    }

    public get showCharts(): boolean
    {
        return this._chartsDetails.length > 0;
    }

    public get canNavigateBack(): boolean
    {
        return this._routingHistoryService.canNavigateBack;
    }

    public get availableCharts(): ChartSelection[]
    {
        return ShipmentMonitoringComponent._availableCharts;
    }

    public get selectedChartsCount(): number
    {
        return ShipmentMonitoringComponent._availableCharts.filter((chart: ChartSelection) => chart.isSelected).length;
    }

    public get showCurrentTelemetryLocationMarker(): boolean
    {
        return this._showCurrentTelemetryLocationMarker;
    }

    public set showCurrentTelemetryLocationMarker(show: boolean)
    {
        this._showCurrentTelemetryLocationMarker = show;
        if (this._mapCurrentTelemetryMarker !== null)
        {
            this._mapCurrentTelemetryMarker.map = show ? this._googleMap?.map : null;
        }
    }

    public get ManagerItemStatus()
    {
        return ManagerItemStatus;
    }

    public get ShipmentMonitoringTelemetrySourceType()
    {
        return ShipmentMonitoringTelemetrySourceType;
    }

    public get ShipmentMonitoringPerimeterType()
    {
        return ShipmentMonitoringPerimeterType;
    }

    public get collapseShipmentMonitoringCharts(): boolean
    {
        return ShipmentMonitoringComponent._collapseShipmentMonitoringCharts;
    }

    public set collapseShipmentMonitoringCharts(value: boolean)
    {
        ShipmentMonitoringComponent._collapseShipmentMonitoringCharts = value;
    }

    public get collapseShipmentMonitoringMap(): boolean
    {
        return ShipmentMonitoringComponent._collapseShipmentMonitoringMap;
    }

    public set collapseShipmentMonitoringMap(value: boolean)
    {
        ShipmentMonitoringComponent._collapseShipmentMonitoringMap = value;
    }

    public get monitoringItemsEventsStyleProperties(): DisplayProperties[]
    {
        return MonitoringUtils.SHIPMENT_EVENTS_DISPLAY_PROPERTIES;
    }

    public get chartsDetails(): ChartDetails[]
    {
        return this._chartsDetails;
    }

    public get isShipmentMonitoringPlanSubSegmentsExpanded(): boolean
    {
        return this._isShipmentMonitoringPlanSubSegmentsExpanded;
    }

    public get filterEventElements(): FilterElement[]
    {
        return this._filterEventElements;
    }

    public get shipmentMonitoringModel(): ShipmentMonitoringModel
    {
        return this._shipmentMonitoringModel;
    }

    // #endregion

    // #region Inputs

    @Input()
    public get shipmentKey(): string
    {
        return this._shipmentKey;
    }

    public set shipmentKey(shipmentKey: string)
    {
        if (this._shipmentKey !== shipmentKey)
        {
            this._shipmentKey = shipmentKey;

            if (this._shipmentMonitoringModel.isInitialized && this._routingHistoryService.isPopState &&
                this._routingHistoryService.lastRouteUrl.startsWith(`/${RouteType.ShipmentMonitoring}`))
            {
                this.clearCharts();
                this.initializeShipmentContent();
            }
        }
    }

    // #endregion

    // #region Constructors

    constructor(private _shipmentMonitoringModel: ShipmentMonitoringModel, private _elementRef: ElementRef<HTMLElement>, _appSettingsService: AppSettingsService,
        _changeDetectorRef: ChangeDetectorRef, _routingHistoryService: RoutingHistoryService, _ngZone: NgZone, _modalMessageService: ModalMessageService,
        _viewContainerRef: ViewContainerRef, _router: Router, _loginModel: LoginModel)
    {
        super(_appSettingsService, _changeDetectorRef, _routingHistoryService, _ngZone, _modalMessageService, _viewContainerRef, _router, _loginModel);

        this._isArchived = this._router.url.indexOf('archive') > 0;

        const eventsIconsDisplayProperties: DisplayProperties[] = [];

        const uniqueSet = new Set();
        for (let i: number = 0; i < MonitoringUtils.SHIPMENT_EVENTS_DISPLAY_PROPERTIES.length; i++)
        {
            const displayProperties = MonitoringUtils.SHIPMENT_EVENTS_DISPLAY_PROPERTIES[i];
            const eventType: MonitoringDetailedEventType | undefined = this._shipmentMonitoringModel.getUpdatedEventType(i);
            if (eventType === undefined)
            {
                continue;
            }

            if (displayProperties.iconClassName !== '' && !uniqueSet.has(displayProperties.iconClassName))
            {
                uniqueSet.add(displayProperties.iconClassName);
                eventsIconsDisplayProperties.push(displayProperties);
            }
        }

        this._eventsIconsDisplayProperties = [...eventsIconsDisplayProperties];
    }

    // #endregion

    // #region Event Handlers

    public ngOnInit(): void
    {
        if (this._routingHistoryService.isPopState && this._shipmentMonitoringModel.isInitialized)
        {
            this.updateFilterEventElements();

            this.updateIsShipmentMonitoringPlanSubSegmentsExpanded();

            this.initializeChartsData();

            this._viewIsInitialized = true;
            this._changeDetectorRef.detectChanges();
        }
        else
        {
            ShipmentMonitoringComponent._externalShipmentsFilterTableListInfo = new VirtualListInfo();

            this.clearCharts();

            this._shipmentMonitoringModel.clear();
            this.loadShipmentOverview();
        }
    }

    public ngOnDestroy(): void
    {
        this.clearGetShipmentMonitoringOverviewSubscription();
        this.clearChartClickTimeout();
    }

    @HostListener('document:keydown.escape') onKeyUpHandler(): void
    {
        if (this.showSearchShipments)
        {
            this.showSearchShipments = false;
        }
    }

    public onRefreshButtonClick(): void
    {
        this.initializeLoading();
        this._changeDetectorRef.detectChanges();

        setTimeout(() => this.initializeShipmentContent());
    }

    public onExportToExcelButtonClick(): void
    {
        const exportData: any[] = [];

        const shipmnetDetails: string = `CG-ID: ${this._shipmentMonitoringModel.displayShipmentMonitoring.shipmentKey}, Cargo ID: ${''
            }${this._shipmentMonitoringModel.displayShipmentMonitoring.containerId}, Route: ${''
            }${this._shipmentMonitoringModel.displayShipmentMonitoring.routeDescription}, Device: ${''
            }${this._shipmentMonitoringModel.displayShipmentMonitoring.deviceDescription}`;

        const timeColumnName: string = `Time${this._appSettingsService.appSettings.isUsingUTCTime ? ' (UTC)' : ''}`;
        const locationTimeColumnName: string = `Location Time${this._appSettingsService.appSettings.isUsingUTCTime ? ' (UTC)' : ''}`;
        const impactColumnName: string = MonitoringUtils.getUpdatedEventDisplayPropertiesByDeviceType(MonitoringDetailedEventType.StrongImpact,
            this._shipmentMonitoringModel.displayShipmentMonitoring.deviceType).tooltip;

        for (const telemetry of this._shipmentMonitoringModel.shipmentMonitoringOverview.telemetries)
        {
            if (telemetry.telemetryTime === null)
            {
                continue;
            }

            const rowData: any = {};

            rowData[timeColumnName] = Utils.getFormattedDateTime(telemetry.telemetryTime, DateTimeFormatType.DateTime);
            rowData[locationTimeColumnName] = Utils.getFormattedDateTime(telemetry.locationUpdateTime, DateTimeFormatType.DateTime);
            rowData['Source'] = telemetry.telemetrySource === null ? '' : ShipmentMonitoringTelemetrySourceType[telemetry.telemetrySource];
            rowData['Device Location Source'] = telemetry.locationMethod ?? '';
            rowData['Latitude'] = telemetry.latitude;
            rowData['Longitude'] = telemetry.longitude;
            rowData[`${MonitoringUtils.TEMPERATURE_EVENT_TITLE} ${MonitoringUtils.TEMPERATURE_UNITS}`] = telemetry.temperature ?? '';
            rowData[`${MonitoringUtils.TEMPERATURE_EVENT_TITLE} max Threshold ${MonitoringUtils.TEMPERATURE_UNITS}`] =
                this._shipmentMonitoringModel.displayShipmentMonitoring.maxTemperatureThreshold ?? '';
            rowData[`${MonitoringUtils.TEMPERATURE_EVENT_TITLE} min Threshold ${MonitoringUtils.TEMPERATURE_UNITS}`] =
                this._shipmentMonitoringModel.displayShipmentMonitoring.minTemperatureThreshold ?? '';
            rowData[`${MonitoringUtils.HUMIDITY_EVENT_TITLE} ${MonitoringUtils.HUMIDITY_UNITS}`] = telemetry.humidity ?? '';
            rowData[`${MonitoringUtils.HUMIDITY_EVENT_TITLE} max Threshold ${MonitoringUtils.HUMIDITY_UNITS}`] =
                this._shipmentMonitoringModel.displayShipmentMonitoring.maxHumidityThreshold ?? '';
            rowData[`${MonitoringUtils.HUMIDITY_EVENT_TITLE} min Threshold ${MonitoringUtils.HUMIDITY_UNITS}`] =
                this._shipmentMonitoringModel.displayShipmentMonitoring.minHumidityThreshold ?? '';
            rowData[`${impactColumnName}`] = this._shipmentMonitoringModel.getRoundedGForceValue(telemetry.gforce) ?? '';
            rowData['Light'] = telemetry.reasonId === ShipmentMonitoringTelemetryReasonType.LightOn ? 'On' :
                (telemetry.reasonId === ShipmentMonitoringTelemetryReasonType.LightOff ? 'Off' : '');
            rowData['Lock Status'] = telemetry.reasonId === ShipmentMonitoringTelemetryReasonType.Opened ||
                telemetry.reasonId === ShipmentMonitoringTelemetryReasonType.Closed || telemetry.reasonId === ShipmentMonitoringTelemetryReasonType.Unlocked ||
                telemetry.reasonId === ShipmentMonitoringTelemetryReasonType.BreakIn || telemetry.reasonId === ShipmentMonitoringTelemetryReasonType.Maintenance ?
                Utils.getEnumParsedDescription(ShipmentMonitoringTelemetryReasonType, telemetry.reasonId) : '';
            rowData['Stop'] = telemetry.movingFlag !== null ? (1 - telemetry.movingFlag ? 'Yes' : 'No') : '';
            rowData['Light (Lux)'] = telemetry.light ?? '';

            rowData['Shipment Details'] = shipmnetDetails;

            exportData.push(rowData);
        }

        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportData);
        const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };

        XLSX.writeFile(workbook, Utils.getFileDateName(this.EXCEL_FILE_PREFIX, '.xlsx', new Date()), { compression: true });
    }

    public onShipmentMonitoringEventsFilterChange(): void
    {
        this.updateEventsFilteredMarkers();
    }

    public onBackButtonClick(): void
    {
        this._routingHistoryService.navigateBack();
    }

    public onShipmentMonitoringFilterTableItemClick(shipmentKey: number): void
    {
        this._shipmentKey = shipmentKey.toString();

        this._router.navigate([`/${RouteType.ShipmentMonitoring}/${this._shipmentKey}`]);

        this.clearCharts();
        this.initializeShipmentContent();
    }

    public onReCenterMapButtonClick(): void
    {
        this.recenterMap();
    }

    public onShipmentMonitoringPlanAllSubSegmentsCollapseClick(): void
    {
        this._isShipmentMonitoringPlanSubSegmentsExpanded = !this._isShipmentMonitoringPlanSubSegmentsExpanded;

        for (const shipmentMonitoringPlanSegment of this._shipmentMonitoringModel.displayShipmentMonitoringPlanSegments)
        {
            shipmentMonitoringPlanSegment.isExpanded = this._isShipmentMonitoringPlanSubSegmentsExpanded;
        }

        this._changeDetectorRef.detectChanges();
    }

    public onShipmentMonitoringPlanSubSegmentCollapseClick(event: MouseEvent, displayShipmentMonitoringPlanSegment: DisplayShipmentMonitoringPlanSegment): void
    {
        event.stopImmediatePropagation();

        displayShipmentMonitoringPlanSegment.isExpanded = !displayShipmentMonitoringPlanSegment.isExpanded;

        this.updateIsShipmentMonitoringPlanSubSegmentsExpanded();

        this._changeDetectorRef.detectChanges();
    }

    public onPlanSegmentClick(displayShipmentMonitoringPlanSegment: DisplayShipmentMonitoringPlanSegment): void
    {
        if (displayShipmentMonitoringPlanSegment.perimeterLatitude !== null && displayShipmentMonitoringPlanSegment.perimeterLongitude !== null)
        {
            if (displayShipmentMonitoringPlanSegment.marker !== null && !this.isMarkerPopupOpen(displayShipmentMonitoringPlanSegment.marker))
            {
                this._googleMap?.setMapZoom(this.PLAN_SEGMENT_ZOOM);
                this._googleMap?.mapPanToPosition({ lat: displayShipmentMonitoringPlanSegment.perimeterLatitude, lng: displayShipmentMonitoringPlanSegment.perimeterLongitude });
            }

            this.togglePlanSegmentMarker(displayShipmentMonitoringPlanSegment);
            return;
        }

        if (displayShipmentMonitoringPlanSegment.telemetries.length === 0)
        {
            return;
        }

        const segmentTelemetriesBounds: google.maps.LatLngBounds = new google.maps.LatLngBounds();
        const segmentTelemetriesCoordinates: google.maps.LatLngLiteral[] = [];

        for (const telemetry of displayShipmentMonitoringPlanSegment.telemetries)
        {
            if (telemetry.latitude !== null && telemetry.longitude !== null)
            {
                const coordinate: google.maps.LatLngLiteral =
                {
                    lat: telemetry.latitude,
                    lng: telemetry.longitude
                };

                segmentTelemetriesCoordinates.push(coordinate);
                segmentTelemetriesBounds.extend(coordinate);
            }
        }

        if (segmentTelemetriesCoordinates.length === 0)
        {
            return;
        }

        const polyLine: google.maps.Polyline = new google.maps.Polyline({
            path: segmentTelemetriesCoordinates,
            strokeColor: this._bodyCssStyleDeclaration.getPropertyValue(this.MAP_TIMELINE_LINE_COLOR_PROPERTY),
            strokeOpacity: 1,
            strokeWeight: this.MAP_PLAN_POLYLINE_WIDTH,
            map: this._googleMap?.map
        });

        this.fadePolyline(polyLine, true, this.PLAN_SEGMENT_SHOW_PATH_DURATION);

        this._googleMap?.mapFitBounds(segmentTelemetriesBounds);
    }

    public onShipmentMonitoringEventMarkerClick(displayShipmentMonitoringEvent: DisplayShipmentMonitoringEvent, isPartOfCluster: boolean): void
    {
        if (!isPartOfCluster || this._cgiMarkerClusterer === null)
        {
            return;
        }

        this.zoomChartOnEvent(displayShipmentMonitoringEvent);

        const marker: google.maps.marker.AdvancedMarkerElement | null = this._cgiMarkerClusterer.findMarkerByData(displayShipmentMonitoringEvent);
        if (marker !== null)
        {
            this.selectAndExtractMonitoringMarkerFromCluster(displayShipmentMonitoringEvent, marker);
        }
    }

    public override async onShareSelectedMonitoringLocation(shareCopyMonitoringInfo: DisplayShipmentMonitoringEvent, copyOnlyToClipboard: boolean): Promise<void>
    {
        super.onShareSelectedMonitoringLocation(shareCopyMonitoringInfo, copyOnlyToClipboard);

        const shareUrl: string =
            `http://maps.google.com/?q=${shareCopyMonitoringInfo.location.lat},${shareCopyMonitoringInfo.location.lng}`;

        await this.shareInfo(this.SHARING_LOCATION_TITLE, shareUrl, copyOnlyToClipboard);
    }

    public override async onShareSelectedMonitoringInfo(shareCopyMonitoringInfo: DisplayShipmentMonitoringEvent, copyOnlyToClipboard: boolean): Promise<void>
    {
        const shareText: string = `${this.SHARING_EVENT_INFO_TITLE}\r\n${''
            }${StringTableUtils.getContainerIdColumnName(this._loginModel.isAccountTypeAmazon)}: ${this._shipmentMonitoringModel.displayShipmentMonitoring.containerId}\r\n${''
            }Origin Route: ${this._shipmentMonitoringModel.displayShipmentMonitoring.originName}\r\n${''
            }Destination Route: ${this._shipmentMonitoringModel.displayShipmentMonitoring.destinationName}\r\n${''
            }Event Type: ${shareCopyMonitoringInfo.eventDesc}\r\n${''
            }Start Time: ${this._appSettingsService.appSettings.isUsingUTCTime ? ' (UTC)' : ''}: ${this.getFormattedDateTime(shareCopyMonitoringInfo.fromDate)}\r\n${''
            }Start Coordinates: ${shareCopyMonitoringInfo.location.lat}\xb0, ${shareCopyMonitoringInfo.location.lng}\xb0\r\n${''
            }Duration: ${shareCopyMonitoringInfo.durationFormatted}\r\n${''
            }${shareCopyMonitoringInfo.minValue !== null && shareCopyMonitoringInfo.maxValue !== null ? `${this._shipmentMonitoringModel.shipmentMonitoringEventTypeOverviews[shareCopyMonitoringInfo.eventType].telemetryValueTitle}: ${shareCopyMonitoringInfo.minValue}${this._shipmentMonitoringModel.shipmentMonitoringEventTypeOverviews[shareCopyMonitoringInfo.eventType].telemetryUnits}${shareCopyMonitoringInfo.minValue !== shareCopyMonitoringInfo.maxValue ? ` to ${shareCopyMonitoringInfo.maxValue}${this._shipmentMonitoringModel.shipmentMonitoringEventTypeOverviews[shareCopyMonitoringInfo.eventType].telemetryUnits}` : ''}\r\n` : ''}${''
            }${shareCopyMonitoringInfo.minThreshold !== null || shareCopyMonitoringInfo.maxThreshold !== null ? `Threshold: ${shareCopyMonitoringInfo.minThreshold !== null ? `${shareCopyMonitoringInfo.minThreshold}${this._shipmentMonitoringModel.shipmentMonitoringEventTypeOverviews[shareCopyMonitoringInfo.eventType].telemetryUnits}` : ''}${shareCopyMonitoringInfo.minThreshold !== null && shareCopyMonitoringInfo.maxThreshold !== null ? ' to ' : ''}${shareCopyMonitoringInfo.maxThreshold !== null ? `${shareCopyMonitoringInfo.maxThreshold}${this._shipmentMonitoringModel.shipmentMonitoringEventTypeOverviews[shareCopyMonitoringInfo.eventType].telemetryUnits}\r\n` : ''}` : ''}${''
            }${shareCopyMonitoringInfo.timeBasedMinThreshold !== null || shareCopyMonitoringInfo.timeBasedMaxThreshold !== null ? `Threshold±: ${shareCopyMonitoringInfo.timeBasedMinThreshold !== null ? `${shareCopyMonitoringInfo.timeBasedMinThreshold}${this._shipmentMonitoringModel.shipmentMonitoringEventTypeOverviews[shareCopyMonitoringInfo.eventType].telemetryUnits}` : ''}${shareCopyMonitoringInfo.timeBasedMinThreshold !== null && shareCopyMonitoringInfo.timeBasedMaxThreshold !== null ? ' to ' : ''}${shareCopyMonitoringInfo.timeBasedMaxThreshold !== null ? `${shareCopyMonitoringInfo.timeBasedMaxThreshold}${this._shipmentMonitoringModel.shipmentMonitoringEventTypeOverviews[shareCopyMonitoringInfo.eventType].telemetryUnits}\r\n` : ''}` : ''}${''
            }${StringTableUtils.getDeviceIdColumnName(this._loginModel.isAccountTypeAmazonUS)}: ${this._shipmentMonitoringModel.displayShipmentMonitoring.deviceId}\r\n${''
            }CG-ID: ${this._shipmentMonitoringModel.displayShipmentMonitoring.shipmentKey}`

        await this.shareInfo(this.SHARING_EVENT_INFO_TITLE, shareText, copyOnlyToClipboard);
    }

    public onChartWheel(event: WheelEvent): void
    {
        if (this._charts === undefined || this._charts.length === 0 || this._chartXAxisMinMaxZoom === null)
        {
            return;
        }

        const apexChartsGridElement: HTMLElement | null = (event.currentTarget as HTMLElement).querySelector(this.CHART_GRID_SELECTOR);
        if (apexChartsGridElement === null)
        {
            return;
        }

        const rectChartAxis: DOMRect = apexChartsGridElement.getBoundingClientRect();

        const positionRatio: number = (event.clientX - rectChartAxis.left) / rectChartAxis.width;
        const zoomStep: number = (this._chartXAxisMinMaxZoom.current.max - this._chartXAxisMinMaxZoom.current.min) / this.CHART_ZOOM_RANGE_DIVIDER_STEP *
            (event.deltaY > 0 ? 1 : -1);

        const minValue: number = Math.min(Math.max(this._chartXAxisMinMaxZoom.current.min + positionRatio * zoomStep,
            this._chartXAxisMinMaxZoom.initial.min), this._chartXAxisMinMaxZoom.initial.max);

        const maxValue: number = Math.max(Math.min(this._chartXAxisMinMaxZoom.current.max - (1 - positionRatio) * zoomStep,
            this._chartXAxisMinMaxZoom.initial.max), this._chartXAxisMinMaxZoom.initial.min);

        if (maxValue - minValue < this.CHART_ZOOM_RANGE_MIN)
        {
            return;
        }

        this._charts.first.zoomX(minValue, maxValue);
    }

    private onChartMouseMove(chartData: [number, number | null][], dataPointIndex: number): void
    {
        if (dataPointIndex == -1)
        {
            return;
        }

        const shipmentLocation: google.maps.LatLngLiteral | undefined =
            this._shipmentMonitoringModel.telemetryTimeToLocationMap.get(chartData[dataPointIndex][0]);

        if (shipmentLocation !== undefined)
        {
            if (this._chartTelemetryMapMarker === null)
            {
                const markerElement: HTMLElement = document.createElement("div");
                markerElement.className = this.CGIMARKER_CHART_TELEMETRY_CLASSNAME;

                this._chartTelemetryMapMarker = new google.maps.marker.AdvancedMarkerElement(
                    {
                        content: markerElement,
                        zIndex: this.CHART_TELEMETRY_MAP_MARKER_ZINDEX
                    });
            }

            this._chartTelemetryMapMarker.position = shipmentLocation;
            this._chartTelemetryMapMarker.map = this._googleMap?.map;
        }
    }

    private async onChartClick(chartData: [number, number | null][], chartId: string, dataPointIndex: number): Promise<void>
    {
        if (this._googleMap === undefined || this._googleMap.map === null || dataPointIndex == -1)
        {
            return;
        }

        if (this._chartClickTimeoutHandleId !== null)
        {
            this.clearChartClickTimeout();

            const locationTime: number = chartData[dataPointIndex][0];

            const shipmentLocation: google.maps.LatLngLiteral | undefined = this._shipmentMonitoringModel.telemetryTimeToLocationMap.get(locationTime);
            if (shipmentLocation === undefined)
            {
                return;
            }

            const maxZoom: number | null = await this._googleMap?.getMaxZoomAtLatLng(shipmentLocation);
            if (maxZoom !== null)
            {
                this._googleMap?.map?.setCenter(shipmentLocation);
                this._googleMap?.setMapZoom(maxZoom);
            }
        }
        else
        {
            this._chartClickTimeoutHandleId = setTimeout(() => this._chartClickTimeoutHandleId = null, Constants.CLICK_TIMEOUT_DURATION);
        }
    }

    private onChartMouseLeave(): void
    {
        this.clearMapTelemetryMarker();
    }

    // #endregion

    // #region Public Methods

    public isShipmentMonitoringPlanSegmentIndexOdd(currentShipmentMonitoringPlanSegment: DisplayShipmentMonitoringPlanSegment | DisplayShipmentMonitoringPlanSubSegment): boolean
    {
        const isPlanSegment: boolean = currentShipmentMonitoringPlanSegment instanceof DisplayShipmentMonitoringPlanSegment;

        let index: number = 0;
        for (const shipmentMonitoringPlanSegment of this._shipmentMonitoringModel.displayShipmentMonitoringPlanSegments)
        {
            if (isPlanSegment)
            {
                if (shipmentMonitoringPlanSegment === currentShipmentMonitoringPlanSegment)
                {
                    return index % 2 !== 0;
                }

                if (shipmentMonitoringPlanSegment.isExpanded)
                {
                    index += shipmentMonitoringPlanSegment.subSegments.length;
                }
            }
            else if (shipmentMonitoringPlanSegment.isExpanded)
            {
                for (const shipmentMonitoringPlanSubSegment of shipmentMonitoringPlanSegment.subSegments)
                {
                    index++;

                    if (shipmentMonitoringPlanSubSegment === currentShipmentMonitoringPlanSegment)
                    {
                        return index % 2 !== 0;
                    }
                }
            }

            index++;
        }

        return false;
    }

    // #endregion

    // #region Protected Methods

    protected override buildClusterInfowWindow(showClusterMarkersSummary: boolean, cluster: CGICluster, isCollapsable: boolean): void
    {
        let embeddedViewRef: EmbeddedViewRef<any>;

        if (showClusterMarkersSummary)
        {
            let eventsOccurrences: number[] = Array(MonitoringUtils.SHIPMENT_EVENT_TYPES_COUNT).fill(0);

            for (const marker of cluster.markers)
            {
                const displayShipmentMonitoringEvent: DisplayShipmentMonitoringEvent = this.getMarkerData(marker) as DisplayShipmentMonitoringEvent;
                eventsOccurrences[displayShipmentMonitoringEvent.eventType] = eventsOccurrences[displayShipmentMonitoringEvent.eventType] + 1;
            }

            embeddedViewRef = this._viewContainerRef.createEmbeddedView(this._infoWindowMarkerClusterTemplateRef,
                { $implicit: eventsOccurrences });
        }
        else
        {
            let maxInfoWindowExtraRows: number = 0;
            for (const marker of cluster.markers)
            {
                const displayShipmentMonitoringEvent: DisplayShipmentMonitoringEvent = this.getMarkerData(marker) as DisplayShipmentMonitoringEvent;
                displayShipmentMonitoringEvent.infoWindowExtraRows = [];
                if (displayShipmentMonitoringEvent.minValue !== null && displayShipmentMonitoringEvent.maxValue !== null)
                {
                    displayShipmentMonitoringEvent.infoWindowExtraRows.push(0);
                }

                if (displayShipmentMonitoringEvent.minThreshold !== null || displayShipmentMonitoringEvent.maxThreshold !== null)
                {
                    displayShipmentMonitoringEvent.infoWindowExtraRows.push(0);
                }

                if (displayShipmentMonitoringEvent.timeBasedMinThreshold !== null || displayShipmentMonitoringEvent.timeBasedMaxThreshold !== null)
                {
                    displayShipmentMonitoringEvent.infoWindowExtraRows.push(0);
                }

                if (displayShipmentMonitoringEvent.infoWindowExtraRows.length > maxInfoWindowExtraRows)
                {
                    maxInfoWindowExtraRows = displayShipmentMonitoringEvent.infoWindowExtraRows.length;
                }
            }

            for (const marker of cluster.markers)
            {
                const displayShipmentMonitoringEvent: DisplayShipmentMonitoringEvent = this.getMarkerData(marker) as DisplayShipmentMonitoringEvent;
                displayShipmentMonitoringEvent.infoWindowExtraRows = Array(maxInfoWindowExtraRows - displayShipmentMonitoringEvent.infoWindowExtraRows.length);
            }

            embeddedViewRef = this._viewContainerRef.createEmbeddedView(this._infoWindowMarkerTemplateRef,
                {
                    info: cluster.markers.map((marker: google.maps.marker.AdvancedMarkerElement | ICGIMarkerLocation) =>
                        (this.getMarkerData(marker) as DisplayShipmentMonitoringEvent)).sort((a: DisplayShipmentMonitoringEvent, b: DisplayShipmentMonitoringEvent) =>
                            (a.fromDate !== null ? a.fromDate.getTime() : 0) - (b.fromDate !== null ? b.fromDate.getTime() : 0)),
                    isCollapsable: isCollapsable
                });
        }

        this.openMarkerPopup(cluster.marker!, embeddedViewRef.rootNodes[0], showClusterMarkersSummary);
    }

    protected override updateActiveMarker(activeMarker: google.maps.marker.AdvancedMarkerElement | null): void
    {
        this.clearChartsAddedEventAnnotations();

        if (this._cgiMarkerClusterer?.activeMarker !== null)
        {
            let displayShipmentMonitoringEvent: DisplayShipmentMonitoringEvent | undefined =
                (this._cgiMarkerClusterer?.activeMarker as ICGIMarker<DisplayShipmentMonitoringEvent>).data;

            if (displayShipmentMonitoringEvent !== undefined && displayShipmentMonitoringEvent.polyline !== null)
            {
                this.fadePolyline(displayShipmentMonitoringEvent.polyline, false);
            }
        }

        if (activeMarker !== null)
        {
            let displayShipmentMonitoringEvent: DisplayShipmentMonitoringEvent | undefined = (activeMarker as ICGIMarker<DisplayShipmentMonitoringEvent>).data;
            if (displayShipmentMonitoringEvent !== undefined)
            {
                if (displayShipmentMonitoringEvent.polyline !== null)
                {
                    displayShipmentMonitoringEvent.polyline.setMap(this._googleMap!.map);

                    this.fadePolyline(displayShipmentMonitoringEvent.polyline, true);

                    const bounds: google.maps.LatLngBounds = new google.maps.LatLngBounds();
                    displayShipmentMonitoringEvent.polyline.getPath().forEach((pointPosition) =>
                    {
                        bounds.extend(pointPosition);
                    });

                    this._googleMap?.mapFitBounds(bounds);
                }

                this.zoomChartOnEvent(displayShipmentMonitoringEvent);
            }
        }

        super.updateActiveMarker(activeMarker);

        this.updateActivePlanMarkers();
    }

    protected override getClusterRenderer(): CGIClusterBaseRenderer
    {
        return new CGIShipmentsClusterRenderer();
    }

    protected override initializeGoogleMap(): void
    {
        super.initializeGoogleMap();

        this._mapContentBounds = new google.maps.LatLngBounds();
        this._cgiMarkerClusterer = new CGIMarkerClusterer(
            {
                map: this._googleMap?.map!,
                renderer: new CGIEventsClusterRenderer(),
                createMarkerCallback: this.ctreateGoogleMapMarker.bind(this)
            });

        this.createMapContent(false);

        const onClusterClick: onClusterClickHandler = this._cgiMarkerClusterer.onClusterClick;

        this.handleMarkerClustererClickEvent();
        this.handleMarkerClustererDblClickEvent(onClusterClick);

        if (this._googleMap !== undefined && this._googleMap.map !== null)
        {
            let isDuringZoom: boolean = false;

            google.maps.event.addListener(this._googleMap?.map, 'zoom_changed', () =>
            {
                isDuringZoom = true;

                if (this._cgiMarkerClusterer?.activeMarker && this._cgiMarkerClusterer.activeMarker.map == null)
                {
                    this.closeMarkerPopup(true);
                }

                this._ngZone.runOutsideAngular(() => this.createMapTimelinePathArrowsByMapBounds(this._googleMap?.map?.getBounds()!));
                this._googleMap?.waitForMapIdle().then(() => isDuringZoom = false);
            });

            google.maps.event.addListener(this._googleMap?.map, 'bounds_changed', () =>
            {
                if (!isDuringZoom)
                {
                    this._ngZone.runOutsideAngular(() => this.createMapTimelinePathArrowsByMapBoundsChange(this._googleMap?.map?.getBounds()!));
                }
            });
        }
    }

    protected override onClusterClick(event: google.maps.MapMouseEvent, cluster: CGICluster): boolean
    {
        if (!super.onClusterClick(event, cluster))
        {
            return false;
        }

        if (this._charts === undefined || this._charts.length === 0)
        {
            return true;
        }

        let eventsClusterMaxTelemetryTime: number = -Number.MAX_SAFE_INTEGER;
        let eventsClusterMinTelemetryTime: number = Number.MAX_SAFE_INTEGER;

        for (const marker of cluster.markers)
        {
            const displayShipmentMonitoringEvent: DisplayShipmentMonitoringEvent = marker instanceof google.maps.marker.AdvancedMarkerElement ?
                (marker as ICGIMarker<DisplayShipmentMonitoringEvent>).data : marker as DisplayShipmentMonitoringEvent;

            if (displayShipmentMonitoringEvent === undefined || displayShipmentMonitoringEvent.telemetries.length === 0)
            {
                continue;
            }

            const firstTelemetery: ShipmentMonitoringTelemetry = displayShipmentMonitoringEvent.telemetries[0];
            if (firstTelemetery.telemetryTime !== null && firstTelemetery.telemetryTime.getTime() < eventsClusterMinTelemetryTime)
            {
                eventsClusterMinTelemetryTime = firstTelemetery.telemetryTime.getTime();
            }

            const lastTelemetery: ShipmentMonitoringTelemetry =
                displayShipmentMonitoringEvent.telemetries[displayShipmentMonitoringEvent.telemetries.length - 1];
            if (lastTelemetery.telemetryTime !== null && lastTelemetery.telemetryTime.getTime() > eventsClusterMaxTelemetryTime)
            {
                eventsClusterMaxTelemetryTime = lastTelemetery.telemetryTime.getTime();
            }
        }

        this._charts.first.zoomX(eventsClusterMinTelemetryTime, eventsClusterMaxTelemetryTime);

        return true;
    }

    protected override closeMarkerPopup(clrearData: boolean = true): void
    {
        super.closeMarkerPopup(clrearData, Utils.isNullOrUndefined(this._cgiMarkerClusterer?.activeMarker) ||
            (this._cgiMarkerClusterer?.activeMarker as ICGIMarker<DisplayShipmentMonitoringEvent>).data === undefined ? null :
            Utils.getPropertyNameof<ICGIMarker<DisplayShipmentMonitoringEvent>>(this._cgiMarkerClusterer?.activeMarker as ICGIMarker<DisplayShipmentMonitoringEvent>,
                activeMarker => activeMarker.data));

        if (clrearData)
        {
            this.selectShipmentMonitoringPlanSegmentBySegmentIndex(null);
        }
    }

    protected override selectAndExtractMonitoringMarkerFromCluster(displayShipmentMonitoringEvent: DisplayShipmentMonitoringEvent,
        marker: google.maps.marker.AdvancedMarkerElement): void
    {
        this.selectShipmentMonitoringPlanSegmentByTelemtryTime(displayShipmentMonitoringEvent.fromDate);

        super.selectAndExtractMonitoringMarkerFromCluster(displayShipmentMonitoringEvent, marker);
    }

    protected override ctreateGoogleMapMarker(location: ICGIMarkerLocation): google.maps.marker.AdvancedMarkerElement
    {
        const displayShipmentMonitoringEvent: DisplayShipmentMonitoringEvent = location as DisplayShipmentMonitoringEvent;

        const eventColor: string =
            this._bodyCssStyleDeclaration.getPropertyValue(MonitoringUtils.SHIPMENT_EVENTS_DISPLAY_PROPERTIES[displayShipmentMonitoringEvent.eventType].color);

        const marker: google.maps.marker.AdvancedMarkerElement = new google.maps.marker.AdvancedMarkerElement(
            {
                content: this._cgiMarkerItemMonitoringIconFactory.createCGIMarkerEventElement(eventColor,
                    MonitoringUtils.SHIPMENT_EVENTS_DISPLAY_PROPERTIES[displayShipmentMonitoringEvent.eventType].iconClassName),
                position: displayShipmentMonitoringEvent.location,
                zIndex: this.EVENT_MARKER_ZINDEX,
            });

        (marker as ICGIMarker<DisplayShipmentMonitoringEvent>).data = displayShipmentMonitoringEvent;

        this._googleMap?.addMarkerClickEvent(marker, () =>
        {
            if (this.isMarkerPopupOpen(marker))
            {
                this.closeMarkerPopup();
            }
            else
            {
                this.selectAndExtractMonitoringMarkerFromCluster(displayShipmentMonitoringEvent, marker);
            }
        });

        return marker;
    }

    // #endregion

    // #region Private Methods

    private clearMapTelemetryMarker(): void
    {
        if (this._chartTelemetryMapMarker !== null)
        {
            this._chartTelemetryMapMarker.map = null;
        }
    }

    private clearMapArrowMarkers(): void
    {
        for (const marker of this._mapArrowMarkers)
        {
            marker.map = null;
        }

        this._mapArrowMarkers = [];

        this._mapArrowMarkersCreationData = [];
    }

    private clearCharts(): void
    {
        this._chartsDetails = [];
        ShipmentMonitoringComponent._availableCharts = [];
    }

    private fadePolyline(polyLine: google.maps.Polyline, isFadeIn: boolean, holdDuration: number | null = null): void
    {
        const from: number = isFadeIn ? 0 : 1;
        const to: number = isFadeIn ? 1 : 0;

        Utils.smoothTransition(from, to, AnimationsConstants.ANIMATION_LONG_DURATION).subscribe((opacity: number) =>
        {
            polyLine.setOptions({ strokeOpacity: opacity });

            if (opacity === to)
            {
                if (holdDuration !== null && to === 1)
                {
                    setTimeout(() => this.fadePolyline(polyLine, !isFadeIn), holdDuration);
                }
                else if (!isFadeIn)
                {
                    polyLine.setMap(null);
                }
            }
        });
    }

    private zoomChartOnTimeRange(fromTime: number, toTime: number): void
    {
        if (fromTime === toTime)
        {
            fromTime = Math.max(this._chartXAxisMinMaxZoom !== null ? this._chartXAxisMinMaxZoom.initial.min : 0, fromTime - this.CHART_ZOOM_RANGE_OFFSET);
            toTime = Math.min(this._chartXAxisMinMaxZoom !== null ? this._chartXAxisMinMaxZoom.initial.max : 0, toTime + this.CHART_ZOOM_RANGE_OFFSET);
        }

        this._charts?.first.zoomX(fromTime, toTime);
    }

    private zoomChartOnEvent(displayShipmentMonitoringEvent: DisplayShipmentMonitoringEvent): void
    {
        if (ShipmentMonitoringComponent._collapseShipmentMonitoringCharts || this._charts === undefined || this._charts.length === 0 ||
            displayShipmentMonitoringEvent.fromDate === null || displayShipmentMonitoringEvent.toDate === null)
        {
            return;
        }

        this.clearChartsAddedEventAnnotations();

        const isEventHasNoDuration: boolean = displayShipmentMonitoringEvent.fromDate.getTime() === displayShipmentMonitoringEvent.toDate.getTime();

        let chartFound: boolean = false;
        for (const chart of this._charts)
        {
            if (chart.chart.id === MonitoringDetailedEventType[displayShipmentMonitoringEvent.eventType])
            {
                this.addChartEventAnnotation(chart, 0, displayShipmentMonitoringEvent.fromDate.getTime());
                if (!isEventHasNoDuration)
                {
                    this.addChartEventAnnotation(chart, 1, displayShipmentMonitoringEvent.toDate.getTime());
                }

                chartFound = true;
                break;
            }
        }

        if (!chartFound)
        {
            for (const chart of this._charts)
            {
                this.addChartEventAnnotation(chart, 0, displayShipmentMonitoringEvent.fromDate.getTime());
                if (!isEventHasNoDuration)
                {
                    this.addChartEventAnnotation(chart, 1, displayShipmentMonitoringEvent.toDate.getTime());
                }
            }
        }

        this.zoomChartOnTimeRange(displayShipmentMonitoringEvent.fromDate.getTime(), displayShipmentMonitoringEvent.toDate.getTime());
    }

    private clearChartClickTimeout(): void
    {
        if (this._chartClickTimeoutHandleId !== null)
        {
            clearTimeout(this._chartClickTimeoutHandleId);
            this._chartClickTimeoutHandleId = null;
        }
    }

    private clearChartsAddedEventAnnotations(): void
    {
        if (this._charts !== undefined)
        {
            try
            {
                for (const chart of this._charts)
                {
                    chart.removeAnnotation(`${this.CHART_EVENT_ANNOTATION_ID_PREFIX}${0}`);
                    chart.removeAnnotation(`${this.CHART_EVENT_ANNOTATION_ID_PREFIX}${1}`);
                }
            }
            catch
            {
            }
        }
    }

    private addChartEventAnnotation(chart: ChartComponent, annotationId: number, position: number): void
    {
        chart.addXaxisAnnotation(
            {
                id: `${this.CHART_EVENT_ANNOTATION_ID_PREFIX}${annotationId}`,
                x: position,
                strokeDashArray: 4,
                opacity: 1
            });
    }

    private waitForMapReady(): void
    {
        this._googleMap?.waitForMapIdle().then(() =>
        {
            this._ngZone.run(() =>
            {
                this.setViewState(true);
                this._cgiMarkerClusterer!.viewIsReady = true;

                this._changeDetectorRef.markForCheck();
            });
        });
    }

    private createMapContent(isRefresh: boolean): void
    {
        this._ngZone.runOutsideAngular(() =>
        {
            this.createMapTimeline();

            this.createMapEvents();

            this.createMapTimelinePath();

            this._googleMap?.waitForMapIdle().then(() =>
            {
                this._cgiMarkerClusterer?.waitForMapClusteringEnd().then(() =>
                {
                    if (isRefresh)
                    {
                        this.waitForMapReady();
                    }
                    else
                    {
                        this._googleMap?.waitForMapTilesLoaded().then(() => this.waitForMapReady());
                    }

                    this.recenterMap();
                });

                this.updateEventsFilteredMarkers();
            });
        });
    }

    private recenterMap(): void
    {
        if (this._googleMap === undefined || this._mapContentBounds === null)
        {
            return;
        }

        const isMarkerPopupOpen: boolean = this._cgiMarkerPopup !== null && this._cgiMarkerPopup.marker !== null;

        this._googleMap.mapFitBounds(this._mapContentBounds, !isMarkerPopupOpen);

        if (isMarkerPopupOpen)
        {
            this._googleMap.mapPanToPosition(this._cgiMarkerPopup?.marker?.position as google.maps.LatLngLiteral);
        }
    }

    private updateActivePlanMarkers(): void
    {
        for (const marker of this._mapPlanMarkers)
        {
            this._cgiMarkerClusterer?.updateActiveMarker(marker);
        }
    }

    private createCurrentTelemetryMarker(): void
    {
        const markerElement: HTMLElement = document.createElement("div");
        markerElement.className = this.CGIMARKER_CHART_TELEMETRY_CURRENT_CLASSNAME;

        for (let i: number = this._shipmentMonitoringModel.shipmentMonitoringOverview.telemetries.length - 1; i >= 0; i--)
        {
            const shipmentMonitoringTelemetry: ShipmentMonitoringTelemetry = this._shipmentMonitoringModel.shipmentMonitoringOverview.telemetries[i];
            if (shipmentMonitoringTelemetry.latitude !== null && shipmentMonitoringTelemetry.longitude !== null)
            {
                this._mapCurrentTelemetryMarker = new google.maps.marker.AdvancedMarkerElement(
                    {
                        content: markerElement,
                        position: { lat: shipmentMonitoringTelemetry.latitude, lng: shipmentMonitoringTelemetry.longitude },
                        map: this._googleMap?.map,
                        zIndex: this.PLAN_CURRENT_POSITION_MARKER_ZINDEX
                    });

                this._mapMarkers.push(this._mapCurrentTelemetryMarker);

                this._googleMap?.addMarkerClickEvent(this._mapCurrentTelemetryMarker, () => this.showMarkerTelmeteryInfo(this._mapCurrentTelemetryMarker!,
                    shipmentMonitoringTelemetry));

                break;
            }
        }
    }

    private togglePlanSegmentMarker(displayShipmentMonitoringPlanSegment: DisplayShipmentMonitoringPlanSegment): void
    {
        if (displayShipmentMonitoringPlanSegment.marker === null)
        {
            return;
        }

        if (this.isMarkerPopupOpen(displayShipmentMonitoringPlanSegment.marker))
        {
            this.closeMarkerPopup();
        }
        else
        {
            this.selectShipmentMonitoringPlanSegmentBySegmentIndex(displayShipmentMonitoringPlanSegment.segmentIndex);

            const embeddedViewRef: EmbeddedViewRef<any> = this._viewContainerRef.createEmbeddedView(this._infoWindowPlanMarkerTemplateRef,
                { $implicit: displayShipmentMonitoringPlanSegment });

            this.openMarkerPopup(displayShipmentMonitoringPlanSegment.marker, embeddedViewRef.rootNodes[0]);

            this.updateActivePlanMarkers();
        }
    }

    private createMapTimeline(): void
    {
        if (this._googleMap === undefined || this._googleMap.map === null || this._mapContentBounds === null)
        {
            return;
        }

        this._mapPlanMarkers = [];

        for (let i: number = 0; i < this._shipmentMonitoringModel.displayShipmentMonitoringPlanSegments.length; i++)
        {
            const displayShipmentMonitoringPlanSegment: DisplayShipmentMonitoringPlanSegment = this._shipmentMonitoringModel.displayShipmentMonitoringPlanSegments[i];

            if (displayShipmentMonitoringPlanSegment.perimeterLatitude === null || displayShipmentMonitoringPlanSegment.perimeterLongitude === null ||
                displayShipmentMonitoringPlanSegment.perimeterLatitude === this.PLANE_SEGMENT_TBD_PERIMETER_LATITUDE)
            {
                continue;
            }

            const coordinate: google.maps.LatLngLiteral =
            {
                lat: displayShipmentMonitoringPlanSegment.perimeterLatitude,
                lng: displayShipmentMonitoringPlanSegment.perimeterLongitude
            };

            const shipmentMonitoringPlanSegmentStatusType: ShipmentMonitoringPlanSegmentStatusType = displayShipmentMonitoringPlanSegment.currentSegment ?
                ShipmentMonitoringPlanSegmentStatusType.Current : (i === this._shipmentMonitoringModel.displayShipmentMonitoringPlanSegments.length - 1 ?
                    ShipmentMonitoringPlanSegmentStatusType.Destination : ShipmentMonitoringPlanSegmentStatusType.Normal);

            const iconClassname: string = MonitoringUtils.getShipmentIconClassName(displayShipmentMonitoringPlanSegment.segmentId,
                displayShipmentMonitoringPlanSegment.perimeterType);

            const marker: google.maps.marker.AdvancedMarkerElement = new google.maps.marker.AdvancedMarkerElement(
                {
                    content: this._cgiMarkerItemMonitoringIconFactory.createCGIMarkerPlanSegmentElement(iconClassname, shipmentMonitoringPlanSegmentStatusType),
                    position: coordinate,
                    map: this._googleMap.map,
                    zIndex: shipmentMonitoringPlanSegmentStatusType === ShipmentMonitoringPlanSegmentStatusType.Current ? this.PLAN_CURRENT_MARKER_ZINDEX :
                        (shipmentMonitoringPlanSegmentStatusType === ShipmentMonitoringPlanSegmentStatusType.Destination ? this.PLAN_DESTINATION_MARKER_ZINDEX :
                            this.PLAN_MARKER_ZINDEX)
                });

            displayShipmentMonitoringPlanSegment.marker = marker;
            this._mapPlanMarkers.push(marker);
            this._mapMarkers.push(marker);

            this._googleMap?.addMarkerClickEvent(marker, () =>
            {
                this.togglePlanSegmentMarker(displayShipmentMonitoringPlanSegment);
            });

            this._mapContentBounds.extend(coordinate);
        }

        this.createCurrentTelemetryMarker();
    }

    private showMarkerTelmeteryInfo(marker: google.maps.marker.AdvancedMarkerElement, shipmentMonitoringTelemetry: ShipmentMonitoringTelemetry): void
    {
        const displayShipmentMonitoringTelemetry: DisplayShipmentMonitoringTelemetry = new DisplayShipmentMonitoringTelemetry();
        Utils.copyObjectBySourceProperties(shipmentMonitoringTelemetry, displayShipmentMonitoringTelemetry);

        displayShipmentMonitoringTelemetry.telemetryTimeFormatted = displayShipmentMonitoringTelemetry.telemetryTime !== null ?
            Utils.getFormattedDateTime(new Date(displayShipmentMonitoringTelemetry.telemetryTime), DateTimeFormatType.DateTime) :
            ManagerConstants.MISSING_VALUE;

        this.selectShipmentMonitoringPlanSegmentByTelemtryTime(displayShipmentMonitoringTelemetry.telemetryTime);

        const embeddedViewRef: EmbeddedViewRef<any> = this._viewContainerRef.createEmbeddedView(this._infoWindowMarkerTelemetryTemplateRef,
            { $implicit: displayShipmentMonitoringTelemetry });

        this.openMarkerPopup(marker, embeddedViewRef.rootNodes[0]);

        if (displayShipmentMonitoringTelemetry.telemetryTime !== null && this._charts !== undefined)
        {
            for (const chart of this._charts)
            {
                this.addChartEventAnnotation(chart, 0, displayShipmentMonitoringTelemetry.telemetryTime.getTime());
            }

            const telemetryTime: number = displayShipmentMonitoringTelemetry.telemetryTime.getTime();

            this.zoomChartOnTimeRange(telemetryTime, telemetryTime);
        }
    }

    private addMapTimelinePathArrowMarkerAtCoordinateByMapBounds(mapBounds: google.maps.LatLngBounds, mapArrowCreationData: MapArrowCreationData): void
    {
        if (this._googleMap === undefined || this._googleMap.map === null)
        {
            return;
        }

        if (!mapBounds.contains(mapArrowCreationData.coordinate))
        {
            return;
        }

        const markerElement: Element = this._cgiMarkerItemMonitoringIconFactory.createCGIMarkerArrowElement(mapArrowCreationData.rotation);
        markerElement.className = this.CGIMARKER_MAP_INFO_TELEMETRY_CLASSNAME;

        const marker = new google.maps.marker.AdvancedMarkerElement(
            {
                content: markerElement,
                position: mapArrowCreationData.coordinate,
                zIndex: this.PLAN_PATH_ZINDEX,
                map: this._googleMap.map
            });

        marker.addListener('click', () => this.showMarkerTelmeteryInfo(marker, mapArrowCreationData.telemetry));
        this._mapArrowMarkers.push(marker);
    }

    private createMapTimelinePathArrowsByMapBoundsChange(mapBounds: google.maps.LatLngBounds): void
    {
        if (this._googleMap === undefined || this._googleMap.map === null)
        {
            return;
        }

        if (this._mapArrowMarkers.length === 0)
        {
            this.createMapTimelinePathArrowsByMapBounds(mapBounds);
        }

        const existingMarkersJsonCoordinates: string[] = [];

        for (let i: number = this._mapArrowMarkers.length - 1; i >= 0; i--)
        {
            const marker: google.maps.marker.AdvancedMarkerElement = this._mapArrowMarkers[i];
            const markerPosition: google.maps.LatLngLiteral =
            {
                lat: (marker.position as google.maps.LatLngLiteral).lat,
                lng: (marker.position as google.maps.LatLngLiteral).lng
            };

            if (!mapBounds.contains(marker.position!))
            {
                marker.map = null;
                this._mapArrowMarkers.splice(i, 1);
            }
            else
            {
                existingMarkersJsonCoordinates.push(JSON.stringify(markerPosition));
            }
        }

        for (const mapArrowCreationData of this._mapArrowMarkersCreationData)
        {
            if (!existingMarkersJsonCoordinates.includes(JSON.stringify(mapArrowCreationData.coordinate)))
            {
                this.addMapTimelinePathArrowMarkerAtCoordinateByMapBounds(mapBounds, mapArrowCreationData);
            }
        }
    }

    private createMapTimelinePathArrowsByMapBounds(mapBounds: google.maps.LatLngBounds): void
    {
        if (this._googleMap === undefined || this._googleMap.map === null)
        {
            return;
        }

        this.clearMapArrowMarkers();

        let lastCoordinate: google.maps.LatLngLiteral | null = null;
        let currentLineDistance: number = 0;

        const mapZoom: number = this._googleMap.map.getZoom()!;

        for (const telemetry of this._shipmentMonitoringModel.shipmentMonitoringOverview.telemetries)
        {
            if (telemetry.latitude === null || telemetry.longitude === null)
            {
                continue;
            }

            const coordinate: google.maps.LatLngLiteral =
            {
                lat: telemetry.latitude,
                lng: telemetry.longitude
            };

            if (lastCoordinate !== null && !isEqual(lastCoordinate, coordinate))
            {
                currentLineDistance += GoogleMapUtils.getCoordinatesDistanceAtZoomLevel(this._googleMap.map, lastCoordinate, coordinate, mapZoom);
                if (currentLineDistance > this.MAP_PLAN_ARROW_MIN_DISTANCE)
                {
                    currentLineDistance = 0;

                    const rotation: number = google.maps.geometry.spherical.computeHeading(lastCoordinate, coordinate);

                    const mapArrowCreationData: MapArrowCreationData = { coordinate: coordinate, rotation: rotation, telemetry: telemetry };
                    this._mapArrowMarkersCreationData.push(mapArrowCreationData);

                    this.addMapTimelinePathArrowMarkerAtCoordinateByMapBounds(mapBounds, mapArrowCreationData);
                }
            }

            lastCoordinate = coordinate;
        }
    }

    private createMapTimelinePath(): void
    {
        if (this._googleMap === undefined || this._googleMap.map === null || this._mapContentBounds === null)
        {
            return;
        }

        const timelineCoordinates: google.maps.LatLngLiteral[] = [];

        for (const telemetry of this._shipmentMonitoringModel.shipmentMonitoringOverview.telemetries)
        {
            if (telemetry.latitude === null || telemetry.longitude === null)
            {
                continue;
            }

            const coordinate: google.maps.LatLngLiteral =
            {
                lat: telemetry.latitude,
                lng: telemetry.longitude
            };

            timelineCoordinates.push(coordinate);
            this._mapContentBounds.extend(coordinate);
        }

        const polyLine: google.maps.Polyline = new google.maps.Polyline({
            path: timelineCoordinates,
            strokeColor: this._bodyCssStyleDeclaration.getPropertyValue(this.MAP_TIMELINE_LINE_COLOR_PROPERTY),
            strokeOpacity: 1.0,
            zIndex: this.PLAN_PATH_ZINDEX,
            strokeWeight: this.MAP_POLYLINE_WIDTH,
            clickable: false,
            map: this._googleMap.map
        });

        this._mapPolylines.push(polyLine);
    }

    private selectShipmentMonitoringPlanSegmentBySegmentIndex(segmentIndex: number | null): void
    {
        this._shipmentMonitoringModel.selectedShipmentMonitoringPlanSegmentIndex = segmentIndex;
        this._changeDetectorRef.detectChanges();

        if (segmentIndex === null)
        {
            return;
        }

        const selectedPlanSegmentElement: HTMLElement | null =
            this._elementRef.nativeElement.querySelector(this.SELECTED_PLAN_SEGMENT_CLASS_SELECTOR);

        if (selectedPlanSegmentElement !== null)
        {
            Utils.scrollElementsBounderiesIntoView([selectedPlanSegmentElement], false, selectedPlanSegmentElement.clientHeight);
        }
    }

    private selectShipmentMonitoringPlanSegmentByTelemtryTime(telemetryTime: Date | null): void
    {
        if (telemetryTime === null)
        {
            return;
        }

        for (const displayShipmentMonitoringPlanSegment of this._shipmentMonitoringModel.displayShipmentMonitoringPlanSegments)
        {
            if (displayShipmentMonitoringPlanSegment.telemetries.length > 0 && displayShipmentMonitoringPlanSegment.telemetries[0].telemetryTime !== null &&
                displayShipmentMonitoringPlanSegment.telemetries[displayShipmentMonitoringPlanSegment.telemetries.length - 1].telemetryTime !== null)
            {
                if (displayShipmentMonitoringPlanSegment.telemetries[0].telemetryTime.getTime() <= telemetryTime.getTime() &&
                    displayShipmentMonitoringPlanSegment.telemetries[displayShipmentMonitoringPlanSegment.telemetries.length - 1].telemetryTime!.getTime() >=
                telemetryTime.getTime())
                {
                    this.selectShipmentMonitoringPlanSegmentBySegmentIndex(displayShipmentMonitoringPlanSegment.segmentIndex);
                    break;
                }
            }
        }
    }

    private createMapEvent(displayShipmentMonitoringEvent: DisplayShipmentMonitoringEvent): void
    {
        if (this._googleMap === undefined || this._googleMap.map === null)
        {
            return;
        }

        const eventColor: string =
            this._bodyCssStyleDeclaration.getPropertyValue(MonitoringUtils.SHIPMENT_EVENTS_DISPLAY_PROPERTIES[displayShipmentMonitoringEvent.eventType].color);

        const eventCoordinates: google.maps.LatLngLiteral[] = [];

        for (const telemetry of displayShipmentMonitoringEvent.telemetries)
        {
            if (telemetry.latitude !== null && telemetry.longitude !== null)
            {
                const coordinate: google.maps.LatLngLiteral =
                {
                    lat: telemetry.latitude,
                    lng: telemetry.longitude
                };

                eventCoordinates.push(coordinate);
            }
        }

        const polyLine: google.maps.Polyline = new google.maps.Polyline({
            path: eventCoordinates,
            strokeColor: eventColor,
            strokeOpacity: 1,
            strokeWeight: this.MAP_EVENT_POLYLINE_WIDTH
        });

        displayShipmentMonitoringEvent.polyline = polyLine;
        this._mapPolylines.push(polyLine);
    }

    private createMapEvents(): void
    {
        for (const shipmentMonitoringEventTypeOverview of this._shipmentMonitoringModel.shipmentMonitoringEventTypeOverviews)
        {
            for (const displayShipmentMonitoringEvent of shipmentMonitoringEventTypeOverview.events)
            {
                this.createMapEvent(displayShipmentMonitoringEvent);
            }
        }
    }

    private updateIsShipmentMonitoringPlanSubSegmentsExpanded(): void
    {
        for (const shipmentMonitoringPlanSegment of this._shipmentMonitoringModel.displayShipmentMonitoringPlanSegments)
        {
            if (shipmentMonitoringPlanSegment.subSegments.length > 0 && shipmentMonitoringPlanSegment.isExpanded === this._isShipmentMonitoringPlanSubSegmentsExpanded)
            {
                return;
            }
        }

        this._isShipmentMonitoringPlanSubSegmentsExpanded = !this._isShipmentMonitoringPlanSubSegmentsExpanded;
    }

    private updateEventsFilteredMarkers(): void
    {
        const filteredEventsMarkersLocations: ICGIMarkerLocation[] = [];

        for (let eventType: number = 0; eventType < this._shipmentMonitoringModel.shipmentMonitoringEventTypeOverviews.length; eventType++)
        {
            const shipmentMonitoringEventTypeOverview: MonitoringEventTypeOverview = this._shipmentMonitoringModel.shipmentMonitoringEventTypeOverviews[eventType];
            if (shipmentMonitoringEventTypeOverview.isFiltered)
            {
                filteredEventsMarkersLocations.push(...shipmentMonitoringEventTypeOverview.events);
            }
        }

        this.closeMarkerPopup();

        this._cgiMarkerClusterer?.replaceMarkers(filteredEventsMarkersLocations);
    }

    private clearMapPolylines(): void
    {
        for (const polyline of this._mapPolylines)
        {
            polyline.setMap(null);
        }

        this._mapPolylines = [];
    }

    private clearMapMarkers(): void
    {
        for (const marker of this._mapMarkers)
        {
            marker.map = null;
        }

        this._mapMarkers = [];
    }

    private initializeShipmentContent(): void
    {
        this.showSearchShipments = false;
        this.monitoringSearchFilter = null;

        this.clearMapMarkers();
        this.clearMapPolylines();

        this._cgiMarkerClusterer?.clear();

        this._shipmentMonitoringModel.clear();
        this.loadShipmentOverview();
    }

    private updateFilterEventElements(): void
    {
        this._filterEventElements = [
            {
                filterType: MonitoringDetailedEventType.HighTemperature,
                class: MonitoringUtils.SHIPMENT_EVENTS_DISPLAY_PROPERTIES[MonitoringDetailedEventType.HighTemperature].class,
                iconClassName: MonitoringUtils.SHIPMENT_EVENTS_DISPLAY_PROPERTIES[MonitoringDetailedEventType.HighTemperature].iconClassName,
                value: this._shipmentMonitoringModel.eventsOccurrences[MonitoringDetailedEventType.HighTemperature],
                tooltip: MonitoringUtils.SHIPMENT_EVENTS_DISPLAY_PROPERTIES[MonitoringDetailedEventType.HighTemperature].tooltip
            },
            {
                filterType: MonitoringDetailedEventType.HighHumidity,
                class: MonitoringUtils.SHIPMENT_EVENTS_DISPLAY_PROPERTIES[MonitoringDetailedEventType.HighHumidity].class,
                iconClassName: MonitoringUtils.SHIPMENT_EVENTS_DISPLAY_PROPERTIES[MonitoringDetailedEventType.HighHumidity].iconClassName,
                value: this._shipmentMonitoringModel.eventsOccurrences[MonitoringDetailedEventType.HighHumidity],
                tooltip: MonitoringUtils.SHIPMENT_EVENTS_DISPLAY_PROPERTIES[MonitoringDetailedEventType.HighHumidity].tooltip
            },
            {
                filterType: MonitoringDetailedEventType.StrongImpact,
                class: MonitoringUtils.SHIPMENT_EVENTS_DISPLAY_PROPERTIES[MonitoringDetailedEventType.StrongImpact].class,
                iconClassName: MonitoringUtils.SHIPMENT_EVENTS_DISPLAY_PROPERTIES[MonitoringDetailedEventType.StrongImpact].iconClassName,
                value: this._shipmentMonitoringModel.eventsOccurrences[MonitoringDetailedEventType.StrongImpact],
                tooltip: MonitoringUtils.SHIPMENT_EVENTS_DISPLAY_PROPERTIES[MonitoringDetailedEventType.StrongImpact].tooltip
            },
            {
                filterType: MonitoringDetailedEventType.Security,
                class: MonitoringUtils.SHIPMENT_EVENTS_DISPLAY_PROPERTIES[MonitoringDetailedEventType.Security].class,
                iconClassName: MonitoringUtils.SHIPMENT_EVENTS_DISPLAY_PROPERTIES[MonitoringDetailedEventType.Security].iconClassName,
                value: this._shipmentMonitoringModel.eventsOccurrences[MonitoringDetailedEventType.Security],
                tooltip: MonitoringUtils.SHIPMENT_EVENTS_DISPLAY_PROPERTIES[MonitoringDetailedEventType.Security].tooltip
            },
            {
                filterType: MonitoringDetailedEventType.Stop,
                class: MonitoringUtils.SHIPMENT_EVENTS_DISPLAY_PROPERTIES[MonitoringDetailedEventType.Stop].class,
                iconClassName: MonitoringUtils.SHIPMENT_EVENTS_DISPLAY_PROPERTIES[MonitoringDetailedEventType.Stop].iconClassName,
                value: this._shipmentMonitoringModel.eventsOccurrences[MonitoringDetailedEventType.Stop],
                tooltip: MonitoringUtils.SHIPMENT_EVENTS_DISPLAY_PROPERTIES[MonitoringDetailedEventType.Stop].tooltip
            }
        ];
    }

    private clearGetShipmentMonitoringOverviewSubscription(): void
    {
        if (this._getShipmentMonitoringOverviewSubscription !== null)
        {
            this._getShipmentMonitoringOverviewSubscription.unsubscribe();
            this._getShipmentMonitoringOverviewSubscription = null;
        }
    }

    private getChartOptions(): ChartOptions
    {
        return {
            chart:
            {
                type: 'line',
                width: '100%',
                height: '100%',
                group: 'events',
                animations:
                {
                    enabled: false
                },
                toolbar:
                {
                    show: true,
                    offsetX: 0,
                    offsetY: 0,
                    tools:
                    {
                        download: false,
                        selection: false,
                        zoomin: false,
                        zoomout: false,
                        zoom: '<i class="chart-toolbar-icon icon-zoom-in"></i>',
                        pan: '<i class="chart-toolbar-icon icon-drag"></i>',
                        reset: '<i class="chart-toolbar-icon icon-refresh"></i>'
                    },
                    autoSelected: 'zoom'
                },
                zoom:
                {
                    zoomedArea:
                    {
                        fill:
                        {
                            color: this._bodyCssStyleDeclaration.getPropertyValue(this.ZOOM_AREA_CHART_COLOR_PROPERTY),
                            opacity: 0.1
                        },
                        stroke:
                        {
                            color: this._bodyCssStyleDeclaration.getPropertyValue(this.ZOOM_AREA_CHART_COLOR_PROPERTY),
                            opacity: 0.6,
                            width: 1
                        }
                    }
                },
                stacked: false
            },
            markers:
            {
                size: 0
            },
            colors: [],
            title:
            {
                floating: true,
                offsetY: -10,
                align: 'left'
            },
            plotOptions:
            {
            },
            dataLabels:
            {
                enabled: false
            },
            stroke:
            {
                curve: 'straight',
                width: 2,
            },
            xaxis:
            {
                axisTicks:
                {
                    show: true,
                },
                axisBorder:
                {
                    show: true,
                },
                type: 'datetime',
                labels:
                {
                    datetimeUTC: this._appSettingsService.appSettings.isUsingUTCTime,
                    datetimeFormatter:
                    {
                        year: 'yyyy',
                        month: 'MMM \'yy',
                        day: 'dd MMM',
                        hour: 'hh:mm TT'
                    }
                },
                tooltip:
                {
                    enabled: false
                }
            },
            yaxis:
            {
                tickAmount: 2,
                forceNiceScale: true,
                labels:
                {
                    minWidth: 24,
                    formatter: (value: number) =>
                    {
                        return Utils.getFormattedNumber(value);
                    }
                },
                axisTicks:
                {
                    show: true,
                },
                axisBorder:
                {
                    show: true,
                },
                tooltip:
                {
                    enabled: false
                }
            },
            annotations:
            {
                xaxis: [],
                yaxis: []
            },
            tooltip:
            {
                x:
                {
                    format: 'MMM dd, yyyy, hh:mm TT',
                    formatter: (value: number) => Utils.getFormattedDateTime(new Date(value), DateTimeFormatType.DateTime, true)
                },
                marker:
                {
                    show: false
                }
            },
            states:
            {
                hover:
                {
                    filter:
                    {
                        type: 'none',
                    }
                },
                normal:
                {
                    filter:
                    {
                        type: 'none',
                    }
                },
                active:
                {
                    filter:
                    {
                        type: 'none',
                    }
                }
            },
        };
    }

    private getTootipDetailHtmlContent(title: string, value: string | null): string
    {
        if (value === null)
        {
            return '';
        }

        return `<div class="apexcharts-tooltip-series-group"><div class="apexcharts-tooltip-text">${''
            }<div class="apexcharts-tooltip-y-group"><span class="apexcharts-tooltip-text-y-label">${title}: ${''
            }</span><span class="apexcharts-tooltip-text-y-value">${value}</span></div></div></div>`;
    }

    private updateChartXAxisMinMax(min: number | undefined, max: number | undefined): void
    {
        if (this._chartXAxisMinMaxZoom !== null)
        {
            this._chartXAxisMinMaxZoom.current.min = min === undefined ? this._chartXAxisMinMaxZoom.initial.min : min;
            this._chartXAxisMinMaxZoom.current.max = max === undefined ? this._chartXAxisMinMaxZoom.initial.max : max;
        }
    }

    private updateChartYAxisTicksAmount(apexChart: ChartComponent, yaxis: ApexYAxis): void
    {
        let yAxisTickAmount: number = 5;
        switch (this.selectedChartsCount)
        {
            case 2:
                {
                    yAxisTickAmount = 4;
                }
                break

            case 3:
                {
                    yAxisTickAmount = 3;
                }
                break
        }

        yaxis.tickAmount = yAxisTickAmount;
        apexChart.updateOptions({ yaxis: yaxis }, false, false, false);
    }

    private getChartEventLockStateDescription(displayShipmentMonitoringEvent: DisplayShipmentMonitoringEvent, eventUnits: string | undefined, time: number): string | null
    {
        let securutyLockStateDescription: string | null = null;

        if (displayShipmentMonitoringEvent.eventType === MonitoringDetailedEventType.Security && eventUnits === undefined)
        {
            for (const telemetry of displayShipmentMonitoringEvent.telemetries)
            {
                if (telemetry.telemetryTime !== null && telemetry.telemetryTime.getTime() == time && telemetry.logicState !== null)
                {
                    securutyLockStateDescription = this.getTootipDetailHtmlContent('Lock State',
                        Utils.getEnumParsedDescription(ShipmentMonitoringTelemetryLogicStateType, telemetry.logicState));

                    break;
                }
            }
        }

        return securutyLockStateDescription;
    }

    private initializeChartDetails(chartOptions: ChartOptions, shipmentMonitoringEventType: MonitoringDetailedEventType): ChartDetails
    {
        const shipmentMonitoringEventTypeOverview: MonitoringEventTypeOverview =
            this._shipmentMonitoringModel.shipmentMonitoringEventTypeOverviews[shipmentMonitoringEventType];

        const valueTitle: string | null = shipmentMonitoringEventTypeOverview.telemetryValueTitle;
        const title: string | null = `${shipmentMonitoringEventTypeOverview.telemetryTitle}${valueTitle === '' ||
            valueTitle === shipmentMonitoringEventTypeOverview.telemetryTitle ? '' : ` - ${valueTitle}`}`;
        const unit: string | undefined = shipmentMonitoringEventTypeOverview.telemetryUnits;
        const color: string =
            this._bodyCssStyleDeclaration.getPropertyValue(MonitoringUtils.SHIPMENT_EVENTS_DISPLAY_PROPERTIES[shipmentMonitoringEventType].color);
        const chartData: [number, number | null][] = shipmentMonitoringEventTypeOverview.telemtryChartData;

        chartOptions.chart.id = MonitoringDetailedEventType[shipmentMonitoringEventType];
        chartOptions.title.text = `${title}${unit !== undefined ? ` (${unit})` : ''}`;
        chartOptions.colors = [color];

        chartOptions.tooltip.custom = ({ dataPointIndex, w }) =>
        {
            let tooltipContent: string = '';

            const chartPoint: [number, number | null] = (this._shipmentMonitoringModel.shipmentMonitoringEventTypeOverviews[shipmentMonitoringEventType].
                telemtryChartData[dataPointIndex] as [number, number | null]);

            const value: number | null = shipmentMonitoringEventType === MonitoringDetailedEventType.StrongImpact ?
                this._shipmentMonitoringModel.getRoundedGForceValue(chartPoint[1]) : chartPoint[1];

            let tooltipValue: string = !Utils.isNullOrEmpty(unit) && value !== null ? this.getTootipDetailHtmlContent(`${valueTitle === '' ? title : valueTitle}`,
                `${value === null ? '' : value}${unit}`) : '';

            const displayShipmentMonitoringEvent: DisplayShipmentMonitoringEvent | undefined =
                this._shipmentMonitoringModel.shipmentMonitoringEventTypeOverviews[shipmentMonitoringEventType].telemetryTimeToEventsMap.get(chartPoint[0]);

            if (displayShipmentMonitoringEvent !== undefined)
            {
                const securutyLockStateDescription: string | null = this.getChartEventLockStateDescription(displayShipmentMonitoringEvent, unit, chartPoint[0]);

                tooltipContent = `${this.getTootipDetailHtmlContent('Event',
                    `<i class='${MonitoringUtils.SHIPMENT_EVENTS_DISPLAY_PROPERTIES[displayShipmentMonitoringEvent.eventType].iconClassName}' style='color: var(${''
                    }${MonitoringUtils.SHIPMENT_EVENTS_DISPLAY_PROPERTIES[displayShipmentMonitoringEvent.eventType].color})'></i>${''
                    }${displayShipmentMonitoringEvent.eventDesc}`)}${tooltipValue}${''
                    }${securutyLockStateDescription !== null ? securutyLockStateDescription : ''}${''
                    }${this.getTootipDetailHtmlContent('Duration', displayShipmentMonitoringEvent.durationFormatted)}`;
            }
            else
            {
                tooltipContent = tooltipValue;
            }

            return `${w.globals.tooltip.tooltipTitle.outerHTML}${tooltipContent}`;
        };

        chartOptions.chart.events =
        {
            mouseMove: (_event, _chartContext, config) => this.onChartMouseMove(chartData, config.dataPointIndex),
            mouseLeave: () => this.onChartMouseLeave(),
            mounted: (chartContext, config) => this.updateChartYAxisTicksAmount(chartContext, config.config.yaxis[0]),
            click: (_event, _chartContext, config) => this.onChartClick(chartData, config.globals.chartID, config.dataPointIndex),
            zoomed: (_chartContext, { xaxis }) => this.updateChartXAxisMinMax(xaxis.min, xaxis.max),
            scrolled: (_chartContext, { xaxis }) => this.updateChartXAxisMinMax(xaxis.min, xaxis.max),
            beforeResetZoom: () => this._charts!.first.zoomX(this._chartXAxisMinMaxZoom!.initial.min, this._chartXAxisMinMaxZoom!.initial.max)
        };

        const chartDetails: ChartDetails =
        {
            chartOptions: chartOptions,
            chartSeries:
                [
                    {
                        name: title,
                        data: chartData
                    }
                ]
        };

        return chartDetails;
    }

    private getThresholdAnnotation(threshold: number, thresholdSymbol: string, idPrefix: string): YAxisAnnotations
    {
        return {
            y: threshold,
            id: `${idPrefix}${this._chartThresholdAnnotaionIndex++}`,
            strokeDashArray: 4,
            opacity: 1,
            label:
            {
                borderWidth: 0,
                text: `${threshold}${thresholdSymbol}`,
                style:
                {
                    cssClass: this.CHART_THRESHOLD_CLASSNAME,
                    background: '#00000000'
                }
            }
        };
    }

    private getThresholdsAnnotations(chartOptions: ChartOptions, chartData: [number, number | null][], minThreshold: number | null,
        maxThreshold: number | null, isTimeBasedThreshold: boolean): YAxisAnnotations[]
    {
        const yAxisAnnotations: YAxisAnnotations[] = [];

        if (minThreshold !== null || maxThreshold !== null)
        {
            let minValue: number = chartOptions.yaxis.min !== undefined ? chartOptions.yaxis.min as number : Number.MAX_VALUE;
            let maxValue: number = chartOptions.yaxis.max !== undefined ? chartOptions.yaxis.max as number : -Number.MAX_VALUE;
            for (const data of chartData)
            {
                if (data[1] === null)
                {
                    continue;
                }

                if (data[1] > maxValue)
                {
                    maxValue = data[1];
                }
                else if (data[1] < minValue)
                {
                    minValue = data[1];
                }
            }

            const thresholdSymbol: string = isTimeBasedThreshold ? '±' : '';

            if (minThreshold !== null)
            {
                yAxisAnnotations.push(this.getThresholdAnnotation(minThreshold, thresholdSymbol, this.CHART_THRESHOLD_MIN_ANNOTATION_ID_PREFIX));
                if (minThreshold < minValue)
                {
                    chartOptions.yaxis.min = minThreshold;
                }
            }

            if (maxThreshold !== null)
            {
                yAxisAnnotations.push(this.getThresholdAnnotation(maxThreshold, thresholdSymbol, this.CHART_THRESHOLD_MAX_ANNOTATION_ID_PREFIX));
                if (maxThreshold > maxValue)
                {
                    chartOptions.yaxis.max = maxThreshold;
                }
            }
        }

        return yAxisAnnotations;
    }

    private getEventAnnotation(fromDate: Date, toDate: Date | null, color: string): XAxisAnnotations
    {
        const fromTime: number = fromDate.getTime();
        const toTime: number = (toDate ?? (new Date((new Date()).toUTCString()))).getTime();

        return {
            x: fromTime,
            x2: toTime === fromTime ? undefined : toTime,
            borderColor: color,
            fillColor: color,
            opacity: 0.2
        };
    }

    private initializeChartsData(): void
    {
        this._chartsDetails = [];
        this._chartThresholdAnnotaionIndex = 0;

        const isSelectedChartsInitialized: boolean = ShipmentMonitoringComponent._availableCharts.length > 0;

        const chartsEventTypes: MonitoringDetailedEventType[] =
            [
                MonitoringDetailedEventType.HighTemperature, MonitoringDetailedEventType.HighHumidity, MonitoringDetailedEventType.StrongImpact,
                MonitoringDetailedEventType.Security, MonitoringDetailedEventType.Stop
            ];

        for (const eventType of chartsEventTypes)
        {
            const shipmentMonitoringEventTypeOverview: MonitoringEventTypeOverview = this._shipmentMonitoringModel.shipmentMonitoringEventTypeOverviews[eventType];
            if (shipmentMonitoringEventTypeOverview.telemtryChartData.length === 0)
            {
                continue;
            }

            if (!isSelectedChartsInitialized)
            {
                ShipmentMonitoringComponent._availableCharts.push(
                    {
                        index: ShipmentMonitoringComponent._availableCharts.length,
                        type: eventType,
                        class: MonitoringUtils.SHIPMENT_EVENTS_DISPLAY_PROPERTIES[eventType].class,
                        iconClassName: MonitoringUtils.SHIPMENT_EVENTS_DISPLAY_PROPERTIES[eventType].iconClassName,
                        tooltip: MonitoringUtils.SHIPMENT_EVENTS_DISPLAY_PROPERTIES[eventType].tooltip,
                        isSelected: ShipmentMonitoringComponent._availableCharts.length < 3
                    });
            }

            const chartOptions: ChartOptions = this.getChartOptions();
            const chartDetails: ChartDetails = this.initializeChartDetails(chartOptions, eventType);

            for (const displayShipmentMonitoringEvent of shipmentMonitoringEventTypeOverview.events)
            {
                chartDetails.chartOptions.annotations.xaxis?.push(this.getEventAnnotation(displayShipmentMonitoringEvent.fromDate!,
                    displayShipmentMonitoringEvent.toDate,
                    this._bodyCssStyleDeclaration.getPropertyValue(MonitoringUtils.SHIPMENT_EVENTS_DISPLAY_PROPERTIES[eventType].color)));
            }

            if (eventType === MonitoringDetailedEventType.HighTemperature)
            {
                chartDetails.chartOptions.annotations.yaxis?.push(...this.getThresholdsAnnotations(chartOptions,
                    shipmentMonitoringEventTypeOverview.telemtryChartData, this._shipmentMonitoringModel.displayShipmentMonitoring.minTemperatureThreshold,
                    this._shipmentMonitoringModel.displayShipmentMonitoring.maxTemperatureThreshold, false));

                chartDetails.chartOptions.annotations.yaxis?.push(...this.getThresholdsAnnotations(chartOptions,
                    shipmentMonitoringEventTypeOverview.telemtryChartData, this._shipmentMonitoringModel.displayShipmentMonitoring.tbMinTemperatureThreshold,
                    this._shipmentMonitoringModel.displayShipmentMonitoring.tbMaxTemperatureThreshold, true));
            }
            else if (eventType === MonitoringDetailedEventType.HighHumidity)
            {
                chartDetails.chartOptions.annotations.yaxis?.push(...this.getThresholdsAnnotations(chartOptions,
                    shipmentMonitoringEventTypeOverview.telemtryChartData, this._shipmentMonitoringModel.displayShipmentMonitoring.minHumidityThreshold,
                    this._shipmentMonitoringModel.displayShipmentMonitoring.maxHumidityThreshold, false));

                chartDetails.chartOptions.annotations.yaxis?.push(...this.getThresholdsAnnotations(chartOptions,
                    shipmentMonitoringEventTypeOverview.telemtryChartData, this._shipmentMonitoringModel.displayShipmentMonitoring.tbMinHumidityThreshold,
                    this._shipmentMonitoringModel.displayShipmentMonitoring.tbMaxHumidityThreshold, true));
            }
            else if (this._shipmentMonitoringModel.shipmentMonitoringEventTypeOverviews[eventType].telemetryUnits === '')
            {
                chartDetails.chartOptions.yaxis.labels!.style = { colors: ['#00000000'] };
                chartDetails.chartOptions.yaxis.max = 2;
            }

            this._chartsDetails.push(chartDetails);
        }

        if (this._chartsDetails.length === 0)
        {
            return;
        }

        this._chartXAxisMinMaxZoom = new ChartXAxisMinMaxZoom();
        this._chartXAxisMinMaxZoom.initial.min = (this._chartsDetails[0].chartSeries[0].data[0] as [number, number])[0];
        this._chartXAxisMinMaxZoom.initial.max = (this._chartsDetails[0].chartSeries[0].data[this._chartsDetails[0].chartSeries[0].data.length - 1] as [number, number])[0];
        this._chartXAxisMinMaxZoom.current.min = this._chartXAxisMinMaxZoom.initial.min;
        this._chartXAxisMinMaxZoom.current.max = this._chartXAxisMinMaxZoom.initial.max;
    }

    private initializeLoading(): void
    {
        this.viewIsReady = false;
        this._statusMessage = Constants.LOADING_DATA_STRING;
    }

    private loadShipmentOverview(): void
    {
        this.clearGetShipmentMonitoringOverviewSubscription();

        this.initializeLoading();

        this._modalMessageService.instance?.closeModal();

        this._getShipmentMonitoringOverviewSubscription =
            this._shipmentMonitoringModel.getShipmentMonitoringOverview(this._shipmentKey, this._isArchived).subscribe((response: IApiResponse) =>
        {
            if (response.isSuccess)
            {
                this.updateFilterEventElements();

                this.updateIsShipmentMonitoringPlanSubSegmentsExpanded();

                this._ngZone.runOutsideAngular(() => this.initializeChartsData());

                if (this._viewIsInitialized)
                {
                    this.createMapContent(true);
                }
                else
                {
                    this._viewIsInitialized = true;
                    this._changeDetectorRef.detectChanges();

                    if (this._googleMap !== undefined && this._googleMap.map !== null)
                    {
                        this.initializeGoogleMap();
                    }
                }

                const currentPlanSegmentElement: HTMLElement | null = this._elementRef.nativeElement.querySelector(this.CURRENT_PLAN_SEGMENT_CLASS_SELECTOR);
                if (currentPlanSegmentElement !== null)
                {
                    Utils.scrollElementsBounderiesIntoView([currentPlanSegmentElement], true);
                }
            }
            else if (!this._viewIsReady)
            {
                this.setViewState(false, response.message);
                this._ngZone.run(() => this._changeDetectorRef.detectChanges());
            }
        });

        this._changeDetectorRef.detectChanges();
    }

    // #endregion
}
