<!DOCTYPE html>

<input [type]="type" #clerableInput class="form-control clearable" [class.has-icon]="icon !== null" placeholder=" " [(ngModel)]="value" [disabled]="disabled"
       [readonly]="readonly" [attr.maxLength]="maxLength" name="clearable-input" (paste)="onPaste($event)" [tooltipPopup]="value"
       [showTooltipOnVerflow]="true" [min]="min" [max]="max" />
<div class="control-label" [class.required]="isRequired">{{ label }}</div>
@if (icon !== null) {
<i class="control-icon" [class]="icon"></i>
}
@if (!readonly && value !== null && value !== undefined && value.length > 0) {
<i class="icon-cancel control-icon press-icon ripple-effect ripple-center"
   @fadeInOutAnimation (click)="value = null">
</i>
}

