import { Constants } from "../../../utils/globals";
import { DropdownPosition, IDropdownPosition } from "../../../base/components/dropdown-base.component";
import { CGIOverlayView } from "./cgi-overlay-view";

export type onCloseHandler = () => void;

export enum MarkerPopupIconType { Circle, Pin }

export interface ICGIMarkerPopupShowOptions
{
    map: google.maps.Map;
    marker: google.maps.marker.AdvancedMarkerElement;
    contentElement: HTMLElement;
    markerPopupIconType: MarkerPopupIconType;
}

export class CGIMarkerPopup extends CGIOverlayView
{
    // #region Private Members

    private _containerElement: HTMLDivElement;
    private _popupBubbleElement: HTMLElement;
    private _popupPosition: IDropdownPosition = { dropdownVerticalPosition: null, dropdownHorizontalPosition: null };
    private _markerIconAnchor: google.maps.Point | null = null;
    private _markerPopupIconType: MarkerPopupIconType = MarkerPopupIconType.Circle;
    private _isVisible: boolean = true;

    // #endregion

    // #region Properties

    public marker: google.maps.marker.AdvancedMarkerElement | null = null;
    public onClose: onCloseHandler | null = null;

    // #endregion

    // #region Constructors

    constructor()
    {
        super();

        this._popupBubbleElement = document.createElement('div');
        this._popupBubbleElement.className = Constants.POPUP_BUBBLE_CLASSNAME;

        this._containerElement = document.createElement('div');
        this._containerElement.className = Constants.POPUP_CONTAINER_CLASSNAME;
        this._containerElement.appendChild(this._popupBubbleElement);

        google.maps.OverlayView.preventMapHitsAndGesturesFrom(this._containerElement);
    }

    // #endregion

    // #region Public Methods

    public open(cgiMarkerPopupShowOptions: ICGIMarkerPopupShowOptions): void
    {
        this._popupPosition = { dropdownVerticalPosition: null, dropdownHorizontalPosition: null };
        this._markerPopupIconType = cgiMarkerPopupShowOptions.markerPopupIconType;
        this.marker = cgiMarkerPopupShowOptions.marker;

        this._popupBubbleElement.appendChild(cgiMarkerPopupShowOptions.contentElement as HTMLElement);
        this.setMap(cgiMarkerPopupShowOptions.map);
    }

    public setVisible(isVisible: boolean): void
    {
        this._isVisible = isVisible;

        if (this._isVisible)
        {
            this.draw();
        }
    }

    public close(): void
    {
        this.setMap(null);

        this.marker = null;
        this._markerIconAnchor = null;

        if (this.onClose !== null)
        {
            this.onClose();
        }
    }

    // #endregion

    // #region Override Methods

    public override onAdd()
    {
        const mapPanes: google.maps.MapPanes | null = this.getPanes();
        if (mapPanes !== null)
        {
            mapPanes.floatPane.appendChild(this._containerElement);
        }

        if (this.marker === null)
        {
            return;
        }

        const markerContentElement: HTMLElement = this.marker.content as HTMLElement;
        if (markerContentElement.clientWidth === 0)
        {
            this.showPopup(false);

            const intersectionObserver: IntersectionObserver = new IntersectionObserver((entries: IntersectionObserverEntry[]) =>
            {
                for (const entry of entries)
                {
                    if (entry.isIntersecting)
                    {
                        intersectionObserver.disconnect();

                        this.updateMarkerIconAnchor();
                        this.draw();

                        break;
                    }
                }
            });

            intersectionObserver.observe(markerContentElement);
        }
        else
        {
            this.updateMarkerIconAnchor();
            this.draw();
        }
    }

    public override onRemove()
    {
        this._containerElement.addEventListener('transitionend', () =>
        {
            if (this._containerElement.parentElement !== null)
            {
                this._containerElement.parentElement.removeChild(this._containerElement);
            }
        }, { once: true });

        this.showPopup(false);
    }

    public override draw()
    {
        if (!this._isVisible)
        {
            this.showPopup(false);
            return;
        }

        const map: google.maps.Map | null | undefined = this.getMap() as google.maps.Map | null | undefined;

        if (map === null || map === undefined || this.marker === null)
        {
            this.close();
            return;
        }

        if (this.marker.map === null || this._markerIconAnchor === null)
        {
            this.showPopup(false);
            return;
        }

        const position: google.maps.LatLng = this.marker.position as google.maps.LatLng;

        const mapBounds: google.maps.LatLngBounds | undefined = map.getBounds();
        if (mapBounds !== undefined && !mapBounds.contains(position))
        {
            this.showPopup(false);
            return;
        }

        const mapCenterPosition: google.maps.LatLng | undefined = map.getCenter();
        if (mapCenterPosition === undefined)
        {
            this.close();
            return;
        }

        const divPixelMapCenter: google.maps.Point | null = this.getProjection().fromLatLngToDivPixel(mapCenterPosition);
        const divPixelMarkerPosition: google.maps.Point | null = this.getProjection().fromLatLngToDivPixel(position);

        if (divPixelMapCenter === null || divPixelMarkerPosition === null)
        {
            this.close();
            return;
        }

        if (divPixelMarkerPosition.y - this._markerIconAnchor.y > divPixelMapCenter.y + map.getDiv().clientHeight / 2 ||
            divPixelMarkerPosition.y + this._markerIconAnchor.y < divPixelMapCenter.y - map.getDiv().clientHeight / 2 ||
            divPixelMarkerPosition.x - this._markerIconAnchor.x > divPixelMapCenter.x + map.getDiv().clientWidth / 2 ||
            divPixelMarkerPosition.x + this._markerIconAnchor.x < divPixelMapCenter.x - map.getDiv().clientWidth / 2)
        {
            this.showPopup(false);
            return;
        }

        const rectPopupBubbleElement: DOMRect = this._popupBubbleElement.getBoundingClientRect();

        const popupBubbleAnchorAfterElementCssStyleDeclaration: CSSStyleDeclaration = getComputedStyle(this._popupBubbleElement, ':after');
        const popupBubbleAnchorHeight: number = parseFloat(popupBubbleAnchorAfterElementCssStyleDeclaration.height);
        const popupBubbleAnchorWidth: number = parseFloat(popupBubbleAnchorAfterElementCssStyleDeclaration.width);

        const updatePopupPosition: IDropdownPosition = { dropdownVerticalPosition: null, dropdownHorizontalPosition: null };
        this.getUpdatedPopupPosition(updatePopupPosition, map.getDiv(), divPixelMapCenter, this._markerIconAnchor, divPixelMarkerPosition,
            rectPopupBubbleElement.width, rectPopupBubbleElement.height, popupBubbleAnchorWidth, popupBubbleAnchorHeight);

        this.updateMarkerPopupDivPixelPosition(divPixelMarkerPosition, updatePopupPosition, this._markerIconAnchor, rectPopupBubbleElement.width,
            rectPopupBubbleElement.height, popupBubbleAnchorWidth, popupBubbleAnchorHeight);

        if (this.isPopupShowing())
        {
            if ((this._popupPosition.dropdownVerticalPosition !== null || this._popupPosition.dropdownHorizontalPosition !== null) &&
                (this._popupPosition.dropdownVerticalPosition !== updatePopupPosition.dropdownVerticalPosition ||
                    this._popupPosition.dropdownHorizontalPosition !== updatePopupPosition.dropdownHorizontalPosition))
            {
                this._containerElement.addEventListener('transitionend', () => this._containerElement.classList.remove(Constants.ANIMATE_CLASSNAME), { once: true });
                this._containerElement.classList.add(Constants.ANIMATE_CLASSNAME);
            }
        }
        else
        {
            this.showPopup(true);
        }

        this._popupPosition = updatePopupPosition;

        this._containerElement.style.left = `${divPixelMarkerPosition.x}px`;
        this._containerElement.style.top = `${divPixelMarkerPosition.y}px`;
    }

    // #endregion

    // #region Private Methods

    private updateMarkerIconAnchor(): void
    {
        if (this.marker === null)
        {
            return;
        }

        const markerContentElement: HTMLElement = this.marker.content as HTMLElement;
        const cssStyleDeclaration: CSSStyleDeclaration = getComputedStyle(markerContentElement);
        const markerWidth: number = markerContentElement.clientWidth + parseFloat(cssStyleDeclaration.borderTopWidth) +
            parseFloat(cssStyleDeclaration.borderBottomWidth);
        const markerHeight: number = markerContentElement.clientHeight + parseFloat(cssStyleDeclaration.borderRightWidth) +
            parseFloat(cssStyleDeclaration.borderLeftWidth);

        this._markerIconAnchor = new google.maps.Point(markerWidth / 2, markerHeight / 2);
    }

    private isPopupShowing(): boolean
    {
        return this._containerElement.classList.contains(Constants.ACTIVE_CLASSNAME);
    }

    private showPopup(show: boolean): void
    {
        if (show)
        {
            this._containerElement.classList.add(Constants.ACTIVE_CLASSNAME);
        }
        else
        {
            this._containerElement.classList.remove(Constants.ACTIVE_CLASSNAME);
        }
    }

    private getUpdatedPopupPosition(updatePopupPosition: IDropdownPosition, mapElement: HTMLElement, divPixelMapCenter: google.maps.Point,
        markerIconAnchor: google.maps.Point, divPixelMarkerPosition: google.maps.Point, popupWidth: number, popupHeight: number,
        popupBubbleAnchorWidth: number, popupBubbleAnchorHeight: number): IDropdownPosition
    {
        if (divPixelMarkerPosition.y - markerIconAnchor.y * (this._markerPopupIconType === MarkerPopupIconType.Circle ? 1 : 2) -
            (popupHeight + popupBubbleAnchorHeight) > divPixelMapCenter.y - mapElement.clientHeight / 2)
        {
            updatePopupPosition.dropdownVerticalPosition = DropdownPosition.Top;
        }
        else if (divPixelMarkerPosition.y + markerIconAnchor.y * (this._markerPopupIconType === MarkerPopupIconType.Circle ? 1 : 0) +
            (popupHeight + popupBubbleAnchorHeight) < divPixelMapCenter.y + mapElement.clientHeight / 2)
        {
            updatePopupPosition.dropdownVerticalPosition = DropdownPosition.Bottom;
        }

        if (divPixelMarkerPosition.x + markerIconAnchor.x + (popupWidth + popupBubbleAnchorWidth) < divPixelMapCenter.x + mapElement.clientWidth / 2)
        {
            updatePopupPosition.dropdownHorizontalPosition = DropdownPosition.Right;
        }
        else if (divPixelMarkerPosition.x - markerIconAnchor.x - (popupWidth + popupBubbleAnchorWidth) > divPixelMapCenter.x - mapElement.clientWidth / 2)
        {
            updatePopupPosition.dropdownHorizontalPosition = DropdownPosition.Left;
        }

        if (updatePopupPosition.dropdownVerticalPosition !== null && updatePopupPosition.dropdownHorizontalPosition !== null)
        {
            if (divPixelMarkerPosition.x - popupWidth / 2 > divPixelMapCenter.x - mapElement.clientWidth / 2 &&
                divPixelMarkerPosition.x + popupWidth / 2 < divPixelMapCenter.x + mapElement.clientWidth / 2)
            {
                updatePopupPosition.dropdownHorizontalPosition = null;
            }
            else if (divPixelMarkerPosition.y - popupHeight / 2 - (this._markerPopupIconType === MarkerPopupIconType.Circle ? 0 : markerIconAnchor.y) >
                divPixelMapCenter.y - mapElement.clientHeight / 2 && divPixelMarkerPosition.y + popupHeight / 2 -
                (this._markerPopupIconType === MarkerPopupIconType.Circle ? 0 : markerIconAnchor.y + markerIconAnchor.x) <
                divPixelMapCenter.y + mapElement.clientHeight / 2)
            {
                updatePopupPosition.dropdownVerticalPosition = null;
            }
        }
        else if (updatePopupPosition.dropdownVerticalPosition === null && updatePopupPosition.dropdownHorizontalPosition === null)
        {
            updatePopupPosition.dropdownVerticalPosition = DropdownPosition.Top;
        }

        return updatePopupPosition;
    }

    private updateMarkerPopupDivPixelPosition(divPixelMarkerPosition: google.maps.Point, updatePopupPosition: IDropdownPosition,
        markerIconAnchor: google.maps.Point, popupWidth: number, popupHeight: number, popupBubbleAnchorWidth: number, popupBubbleAnchorHeight: number)
    {
        const popupPositionNames: string[] = Object.values(DropdownPosition).filter((value: any) => typeof (value) === 'string') as string[];
        for (const popupPositionName of popupPositionNames)
        {
            this._popupBubbleElement.classList.remove(popupPositionName.toLowerCase());
        }

        if (updatePopupPosition.dropdownVerticalPosition === DropdownPosition.Top)
        {
            divPixelMarkerPosition.y -= markerIconAnchor.y * (this._markerPopupIconType === MarkerPopupIconType.Circle ? 1 : 2) + popupBubbleAnchorHeight;
            this._popupBubbleElement.classList.add((DropdownPosition[DropdownPosition.Top] as string).toLowerCase());
        }
        else if (updatePopupPosition.dropdownVerticalPosition === DropdownPosition.Bottom)
        {
            divPixelMarkerPosition.y += (updatePopupPosition.dropdownHorizontalPosition === null ?
                (this._markerPopupIconType === MarkerPopupIconType.Circle ? markerIconAnchor.y : 0) :
                (this._markerPopupIconType === MarkerPopupIconType.Circle ? 0 : - markerIconAnchor.y)) + (popupHeight + popupBubbleAnchorHeight);

            this._popupBubbleElement.classList.add((DropdownPosition[DropdownPosition.Bottom] as string).toLowerCase());
        }

        if (updatePopupPosition.dropdownHorizontalPosition !== null)
        {
            if (updatePopupPosition.dropdownVerticalPosition === null)
            {
                divPixelMarkerPosition.y += (popupHeight -
                    (this._markerPopupIconType === MarkerPopupIconType.Circle ? 0 : 2.5 * markerIconAnchor.y)) / 2;
            }

            if (updatePopupPosition.dropdownHorizontalPosition === DropdownPosition.Right)
            {
                divPixelMarkerPosition.x += (popupWidth + popupBubbleAnchorWidth) / 2 + markerIconAnchor.x;
                this._popupBubbleElement.classList.add((DropdownPosition[DropdownPosition.Right] as string).toLowerCase());
            }
            else
            {
                divPixelMarkerPosition.x -= (popupWidth + popupBubbleAnchorWidth) / 2 + markerIconAnchor.x;
                this._popupBubbleElement.classList.add((DropdownPosition[DropdownPosition.Left] as string).toLowerCase());
            }
        }
    }

    // #endregion
}
