import { Injectable } from "@angular/core";
import { DemurrageColumnType, DemurrageConstants, DemurrageLists } from "./demurrage-manager-model.class";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { DateTimeFormatType, Utils } from "../../../utils/utils";
import { Observable, Observer, forkJoin } from "rxjs";
import { Constants, IApiResponse } from "../../../utils/globals";
import { HttpHelper } from "../../../utils/http-helper";
import { ManagerBaseModel } from "../../../base/models/manager-base.model";
import { DemurrageContract, DisplayDemurrageContract } from "../../demurrage/model/demurrage-model.class";

@Injectable({ providedIn: 'root' })
export class DemurrageManagerModel extends ManagerBaseModel<DisplayDemurrageContract, DemurrageColumnType>
{
    // #region Private Members

    private _demurrageLists: DemurrageLists = new DemurrageLists();

    // #endregion

    // #region Properties

    public get demurrageLists(): DemurrageLists
    {
        return this._demurrageLists;
    }

    // #endregion

    // #region Constructors

    constructor(_httpClient: HttpClient)
    {
        super(_httpClient);

        this._sortColumn = DemurrageColumnType.CarrierName;

        this._managerItemKeyPropertyName = Utils.getPropertyNameof<DisplayDemurrageContract>(new DisplayDemurrageContract(),
            displayDemurrageContract => displayDemurrageContract.contractId);
    }

    // #endregion

    // #region Public Methods

    public override clear(): void
    {
        super.clear();

        this._demurrageLists = new DemurrageLists();
    }

    public override getManagerItems(): Observable<IApiResponse>
    {
        this._isBusy = true;

        return new Observable((observer: Observer<IApiResponse>) =>
        {
            const joinObservables =
            {
                demurrageContracts: this._httpClient.get<DemurrageContract[]>('demurrage-manager/demurrages',
                    { headers: HttpHelper.GetHttpFormUrlencodedHeaders() }),
                demurrageLists: this._httpClient.get<DemurrageLists>('demurrage-manager/demurrage/lists',
                    { headers: HttpHelper.GetHttpFormUrlencodedHeaders() })
            };

            forkJoin(joinObservables).subscribe(
                {
                    next: (join) =>
                    {
                        this._demurrageLists = join.demurrageLists;

                        this._managerItems = [];
                        for (const demurrageContract of join.demurrageContracts)
                        {
                            this._managerItems.push(this.convertDemurrageContractToDisplayDemurrageContract(demurrageContract));
                        }

                        this.applyFilters();
                        this.sortManagerItems();

                        this._isBusy = false;
                        observer.next({ isSuccess: true, isComplete: true });
                        observer.complete();
                    },
                    error: (error: HttpErrorResponse) =>
                    {
                        this._isBusy = false;
                        console.error(error);

                        observer.next({ isSuccess: false, isComplete: true, message: Constants.DATA_SERVICE_ERROR_STRING });
                        observer.complete();
                    }
                });
        });
    }

    // #endregion

    // #region Protected Methods

    protected override getSortColumnProperty(managerItem: DisplayDemurrageContract): any
    {
        switch (this._sortColumn)
        {
            case DemurrageColumnType.CarrierName:
                {
                    return managerItem.carrierName;
                }

            case DemurrageColumnType.FromDate:
                {
                    return managerItem.fromDate;
                }

            case DemurrageColumnType.ToDate:
                {
                    return managerItem.toDate;
                }

            case DemurrageColumnType.PortsCount:
                {
                    return managerItem.portsCount;
                }
        }
    }

    protected override applyFilters(): void
    {
        if (this._filterFromDate === null && this._filterToDate === null)
        {
            this._filteredManagerItems = [...this._managerItems];
        }
        else
        {
            this._filteredManagerItems = this._managerItems.filter((managerItem: DisplayDemurrageContract) =>
            {
                if (managerItem.fromDate === null || managerItem.toDate === null)
                {
                    return false;
                }

                if (this._filterFromDate !== null && Utils.getMoment(this._filterFromDate).isAfter(Utils.getMoment(managerItem.fromDate), 'days'))
                {
                    return false;
                }

                if (this._filterToDate !== null && Utils.getMoment(this._filterToDate).isBefore(Utils.getMoment(managerItem.toDate), 'days'))
                {
                    return false;
                }

                return true;
            });
        }

        this.updateSelectedManagerItem();
    }

    // #endregion

    // #region Private Methods

    private convertDemurrageContractToDisplayDemurrageContract(demurrageContract: DemurrageContract): DisplayDemurrageContract
    {
        const displayDemurrageContract: DisplayDemurrageContract = new DisplayDemurrageContract();
        Object.assign(displayDemurrageContract, demurrageContract);

        if (displayDemurrageContract.fromDate !== null)
        {
            displayDemurrageContract.fromDateFormatted = Utils.getFormattedDateTime(new Date(displayDemurrageContract.fromDate), DateTimeFormatType.Date);
        }

        if (displayDemurrageContract.toDate !== null)
        {
            displayDemurrageContract.toDateFormatted = Utils.getFormattedDateTime(new Date(displayDemurrageContract.toDate), DateTimeFormatType.Date);
        }

        displayDemurrageContract.portsCount = displayDemurrageContract.demurrageContractPorts.length;

        for (const carrier of this._demurrageLists.carriers)
        {
            if (carrier.carrierId === displayDemurrageContract.carrierId)
            {
                displayDemurrageContract.carrierName = carrier.carrierName;
                break;
            }
        }

        displayDemurrageContract.carrierName = displayDemurrageContract.carrierName ?? DemurrageConstants.NO_VALUE;

        return displayDemurrageContract;
    }

    // #endregion
}