import { ManagerItemStatus } from "../../../base/models/manager-base-model.class";

export class Inventory
{
    public inventoryId: number | null = null;
    public warehouseId: number | null = null;
    public date: Date | null = null;
    public notes: string | null = null;
    public statusId: ManagerItemStatus = ManagerItemStatus.Live;
    public firstName: string | null = null;
    public lastName: string | null = null;
    public position: string | null = null;
    public userMail: string | null = null;
    public locks: number | null = null;
    public shackles: number | null = null;
    public cables: number | null = null;
    public chargers: number | null = null;
}

export class DisplayInventory extends Inventory
{
    public dateFormatted: string | null = null;
    public warehouseName: string | null = null;
}


