<!DOCTYPE html>

@if (label) {
  <div class="slider-title">{{ label }}</div>
}
<div class="slider-container">
  <div class="slider-label">{{ minValue }}</div>
  <div class="slider-content">
    <div class="selection-container">
      <div class="selection-background"></div>
      <div class="selection-content">
        <div #selection class="selection"></div>
      </div>
    </div>
    <div class="switch-container select-none">
      <div #switch class="switch" (mousedown)="onSwitchMouseDown($event)">
        <div class="switch-label">{{ value }}</div>
      </div>
    </div>
  </div>
  <div class="slider-label">{{ maxValue }}</div>
</div>
