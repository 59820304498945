import { ManagerItemStatus } from "../../../base/models/manager-base-model.class";

export enum TrailerType { Hard = 1, Hybrid = 2, Soft = 3 }
export enum LockMethodType { None = 1, Shackle = 2, Cable = 3 }
export enum CustomAttributeType
{
    truck_carrier = 2,
    truck_plate2 = 3,
    truck_plate1 = 4,
    carton_sn = 5,
    pallet_sn = 6,
    pos_in_trailer = 7,
    trailer_plate = 8,
    trailer_type = 10,
    first_name_origin = 11,
    last_name_origin = 12,
    position_origin = 13,
    user_mail_origin = 14,
    attachments_origin = 15,
    first_name_dest = 16,
    last_name_dest = 17,
    position_dest = 18,
    user_mail_dest = 19,
    attachments_dest = 20,
    review = 21,
    deviceId_dest = 22,
    cancellation_reason = 23,
    report_anomalies = 24,
    slot_dest = 25,
    lock_method = 26,
    driver_name = 27,
    sub_carrier = 28,
    deviceless_reason = 29,
    alias_origin = 30,
    alias_dest = 31,
    run_structure = 32,
    review_origin = 33
}

export class Shipment
{
    public shipmentKey: number | null = null;
    public shipmentId: string | null = null;
    public deviceId: string | null = null;
    public installDate: Date | null = null;
    public removeDate: Date | null = null;
    public deviceInstallPosition: string | null = null;
    public statusId: ManagerItemStatus = ManagerItemStatus.Live;
    public routeId: number | null = null;
    public containerId: string | null = null;
    public carrierId: number | null = null;
    public lspId: number | null = null;
    public customerId: number | null = null;
    public minTemperatureThreshold: number | null = null;
    public maxTemperatureThreshold: number | null = null;
    public minHumidityThreshold: number | null = null;
    public maxHumidityThreshold: number | null = null;
    public cargoValue: number | null = null;
    public bookingReference: string | null = null;
    public bookingNumber: string | null = null;
    public tags: string | null = null;
    public isTest: number | null = null;
    public relatedGonId: number | null = null;
    public createdById: string | null = null;
    public modifiedById: string | null = null;
    public originPerimeterId: string | null = null;
    public destinationPerimeterId: string | null = null;
    public modified: Date | null = null;
    public customAttributes: CustomAttribute[] = [];
    public autoClose: number | null = null;
    public untrackable: number | null = null;
    public isArrived: boolean | null = null;
    public trailerNumber: string | null = null;
}

export class DisplayShipment extends Shipment
{
    public deviceDescription: string | null = null;
    public installDateFormatted: string | null = null;
    public routeDescription: string | null = null;
    public isReportAnomaly: boolean = false;
    public isReportAutoClose: boolean = false;
    public isReportArrived: boolean = false;
}

export class CustomAttribute
{
    public attributeId: CustomAttributeType | null = null;
    public value: any | undefined = undefined;
    public attributeKey: string | null = null;
}

/*
export class GenericField
{
    public fieldType: GenericFieldType | null = null;
    public fieldValue: any | null = null;
    //public filedValueDescriptiom: string | null = null;
}

export enum GenericFieldValueType { Text, Email, Number, Select, Date, Attachments, Title, Summary }

export class GenericFieldInfo
{
    public fieldType: GenericFieldType | null = null;
    public fieldValueType: GenericFieldValueType | null = null;
    public fieldStatusType: ManagerItemStatus = ManagerItemStatus.Active;
    public fieldLabel: string | null = null;
    public filedSelectOptions: IIdValue[] = [];
    public filedSelectOptionFormat: string | null = null;
    public isRequired: boolean = true;
}
*/
