export enum MiniControlCenterAlertColumnType
{
    Select,
    Status,
    CreateTime,
    EventTime,
    EventType,
    Customer,
    Route,
    CargoId,
    IsReportAnomaly,
    Segment,
    Location,
    Device,
    ShipmentKey,
    Operator,
    Escalation,
    Copy,
    Track
}

export class ControlCenterAlertsCountRequest
{
    public fromDate: Date | null = null;
    public toDate: Date | null = null;
}