@if (viewIsInitialized) {
<form class="manager-item-from" #formManagerItem="ngForm" (ngSubmit)="onManagerItemSubmit(formManagerItem.form.valid)"
      @fadeInOutAnimation [ngClass]="{ 'form-validated': formManagerItem.submitted }" ignoreEnter novalidate>
    <fieldset [disabled]="managerItemModel.isBusy">
        <div class="form-content-wrapper">
            <div #pageScroller class="form-content page-scroll-padding" overlayScrollbar>
                <div class="form-group">
                    <select-list name="statusId" #statusId="ngModel" placeholder="Status" [items]="managerItemModel.alertStatuses" [clearable]="false"
                                 [(ngModel)]="managerItemModel.managerItem.statusId" bindLabel="value" bindValue="id">
                    </select-list>
                </div>
                @if (managerItemModel.managerItem.statusId !== null) {
                <ng-container [ngTemplateOutlet]="managerItemModel.managerItem.statusId !== managerItemModel.AlertStatusType.Done ? alertSummaryContentTemplate : alertDoneContentemplate"></ng-container>
                }
            </div>
            <div class="form-group manager-buttons action-button-container">
                <button type="button" class="secondary-button submit-button ripple-effect ripple-fill" [disabled]="!managerItemModel.isDirty"
                        (click)="loadManagerItem(false)">
                    Revert
                </button>
                <button type="submit" class="primary-button ripple-effect ripple-fill" [disabled]="!managerItemModel.isDirty">
                    <span class="spinner-container">
                        @if (managerItemModel.isBusy) {
                        <i @fadeInOutAnimation class="icon-spinner"></i>
                        }
                        <span>Update</span>
                    </span>
                </button>
            </div>
        </div>
    </fieldset>

    <!-- alertSummaryContentTemplate -->
    <ng-template #alertSummaryContentTemplate>
        <div class="view-only">
            <div class="form-group">
                <label class="attribute-label">Create Time</label>
                <div class="attribute-value">{{ managerItemModel.managerItem.createTimeFormatted }}</div>
            </div>
            <div class="form-group">
                <label class="attribute-label">Event Time</label>
                <div class="attribute-value">{{ managerItemModel.managerItem.eventTimeFormatted }}</div>
            </div>
            <div class="form-group">
                <label class="attribute-label">Event Type</label>
                <div class="attribute-value">{{ managerItemModel.managerItem.alertReasonName }}</div>
            </div>
            <div class="form-group">
                <label class="attribute-label">Customer</label>
                <div class="attribute-value">{{ managerItemModel.managerItem.payingAccountName }}</div>
            </div>
            <div class="form-group">
                <label class="attribute-label">Route</label>
                <div class="attribute-value">{{ managerItemModel.managerItem.routeName }}</div>
            </div>
            <div class="form-group">
                <label class="attribute-label">Cargo ID</label>
                <div class="attribute-value">{{ managerItemModel.managerItem.containerId }}</div>
            </div>
            <div class="form-group">
                <label class="attribute-label">Segment</label>
                <div class="attribute-value">{{ managerItemModel.managerItem.segmentName }}</div>
            </div>
            <div class="form-group">
                <label class="attribute-label">Location</label>
                <div class="attribute-value">{{ managerItemModel.managerItem.eventLocation }}</div>
            </div>
            <div class="form-group">
                <label class="attribute-label">Device</label>
                <div class="attribute-value">{{ managerItemModel.managerItem.deviceId }}</div>
            </div>
            <div class="form-group">
                <label class="attribute-label">Shipment Key</label>
                <div class="attribute-value">{{ managerItemModel.managerItem.shipmentKey }}</div>
            </div>
        </div>
    </ng-template>

    <!-- alertDoneContentemplate -->
    <ng-template #alertDoneContentemplate>
        <div class="form-group">
            <div class="expander">
                <button type="button" #expanderButton aria-expanded="false" class="ripple-effect ripple-inverse-color"
                        (click)="expanderButton.ariaExpanded = expanderButton.ariaExpanded === 'true' ? 'false' : 'true'">
                    <span class="expander-title">
                        <i class="icon-list-ul"></i>Alert Details
                    </span>
                    <i class="icon-arrow-down-light"></i>
                </button>
                @if (expanderButton.ariaExpanded === 'true') {
                <div class="expander-content-container" @foldLongAnimation>
                    <div class="expander-content view-only" @foldLongAnimation>
                        <ng-container [ngTemplateOutlet]="alertSummaryContentTemplate"></ng-container>
                    </div>
                </div>
                }
            </div>
        </div>
        <div class="form-group">
            <clearable-input name="operatorName" #operatorNameText #operatorName="ngModel" [isRequired]="true" requiredValidator
                             [(ngModel)]="managerItemModel.managerItem.operatorName" [maxLength]="50" label="Operator Name">
            </clearable-input>
            @if (formManagerItem.submitted && operatorName.invalid) {
            <div class="invalid-feedback" @foldAnimation>This field is required</div>
            }
            <text-characters-counter [text]="managerItemModel.managerItem.operatorName" [maxLength]="operatorNameText.maxLength"></text-characters-counter>
        </div>
        <div class="form-group">
            <textarea placeholder=" " name="review" #reviewText class="form-control"
                      autosize [(ngModel)]="managerItemModel.managerItem.review"
                      maxlength="200">
          </textarea>
            <label class="control-label">Review</label>
            <text-characters-counter [text]="managerItemModel.managerItem.review" [maxLength]="reviewText.maxLength"></text-characters-counter>
        </div>
        @if (managerItemModel.managerItem.alertReasonId !== managerItemModel.AlertReasonType.ShipmentCreated &&
        managerItemModel.managerItem.alertReasonId !== managerItemModel.AlertReasonType.ShipmentEnded) {
        <div class="form-group">
            <select-list name="escalationRequirement" #escalationRequirement="ngModel" placeholder="Escalation Requirement"
                         [items]="managerItemModel.escalationRequirements" [isRequired]="true" [(ngModel)]="managerItemModel.managerItem.escalationRequirement"
                         bindLabel="value" bindValue="id" requiredValidator>
            </select-list>
            @if (formManagerItem.submitted && escalationRequirement.invalid) {
            <div class="invalid-feedback" @foldAnimation>This field is required</div>
            }
        </div>
        @if (managerItemModel.managerItem.escalationRequirement === managerItemModel.AlertEscalationRequestType.NotRequireEscalation) {
        <div class="form-group overflow-hidden" @foldAnimation>
            <select-list name="reasonNotEscalated" #reasonNotEscalated="ngModel" placeholder="Reason not Escalated" [items]="managerItemModel.noEscalationResons"
                         [isRequired]="true" [(ngModel)]="managerItemModel.managerItem.reasonNotEscalated" bindLabel="value" bindValue="id" requiredValidator>
            </select-list>
            @if (formManagerItem.submitted && reasonNotEscalated.invalid) {
            <div class="invalid-feedback" @foldAnimation>This field is required</div>
            }
        </div>
        @if (managerItemModel.managerItem.reasonNotEscalated === managerItemModel.NotEscalatedReasonType.Other) {
        <div class="form-group overflow-hidden" @foldAnimation>
            <textarea placeholder=" " name="notEscalatedReview" #notEscalatedReviewText #notEscalatedReview="ngModel" class="form-control"
                      requiredValidator autosize [(ngModel)]="managerItemModel.managerItem.notEscalatedReview"
                      maxlength="200">
            </textarea>
            <label class="control-label required">Other Reason</label>
            @if (formManagerItem.submitted && notEscalatedReview.invalid) {
            <div class="invalid-feedback" @foldAnimation>This field is required</div>
            }
            <text-characters-counter [text]="managerItemModel.managerItem.notEscalatedReview" [maxLength]="notEscalatedReviewText.maxLength"></text-characters-counter>
        </div>
        }
        }
        @else {
        <div class="form-group animation-overflow" @foldAnimation>
            <select-list name="selectedEscalationActionTypes" #selectedEscalationActionTypes="ngModel"
                         placeholder="Action Types" [items]="managerItemModel.escalationActionTypes" [multiple]="true"
                         [isRequired]="true" [(ngModel)]="managerItemModel.selectedEscalationActionTypes" bindLabel="value" bindValue="id" requiredValidator>
            </select-list>
            @if (formManagerItem.submitted && selectedEscalationActionTypes.invalid) {
            <div class="invalid-feedback" @foldAnimation>This field is required</div>
            }
        </div>
        @if (managerItemModel.selectedEscalationActionTypes.includes(managerItemModel.EscalationActionType.PhoneCall)) {
        <div class="form-group animation-overflow" @foldAnimation>
            <textarea placeholder=" " name="actionPhoneCall" #actionPhoneCallText #actionPhoneCall="ngModel" class="form-control"
                      requiredValidator autosize [(ngModel)]="managerItemModel.managerItem.actionTypePhone"
                      maxlength="200">
            </textarea>
            <label class="control-label required">Phone Call Action</label>
            @if (formManagerItem.submitted && actionPhoneCall.invalid) {
            <div class="invalid-feedback" @foldAnimation>This field is required</div>
            }
            <text-characters-counter [text]="managerItemModel.managerItem.actionTypePhone" [maxLength]="actionPhoneCallText.maxLength"></text-characters-counter>
        </div>
        }
        @if (managerItemModel.selectedEscalationActionTypes.includes(managerItemModel.EscalationActionType.Email)) {
        <div class="form-group animation-overflow" @foldAnimation>
            <textarea placeholder=" " name="actionEmail" #actionEmailText #actionEmail="ngModel" class="form-control"
                      requiredValidator autosize [(ngModel)]="managerItemModel.managerItem.actionTypeMail"
                      maxlength="200">
            </textarea>
            <label class="control-label required">Email Action</label>
            @if (formManagerItem.submitted && actionEmail.invalid) {
            <div class="invalid-feedback" @foldAnimation>This field is required</div>
            }
            <text-characters-counter [text]="managerItemModel.managerItem.actionTypeMail" [maxLength]="actionEmailText.maxLength"></text-characters-counter>
        </div>
        }
        @if (managerItemModel.selectedEscalationActionTypes.includes(managerItemModel.EscalationActionType.WhatsApp)) {
        <div class="form-group animation-overflow" @foldAnimation>
            <textarea placeholder=" " name="actionWhatsApp" #actionWhatsAppText #actionWhatsApp="ngModel" class="form-control"
                      requiredValidator autosize [(ngModel)]="managerItemModel.managerItem.actionTypeWhatsapp"
                      maxlength="200">
            </textarea>
            <label class="control-label required">WhatsApp/Chime Action</label>
            @if (formManagerItem.submitted && actionWhatsApp.invalid) {
            <div class="invalid-feedback" @foldAnimation>This field is required</div>
            }
            <text-characters-counter [text]="managerItemModel.managerItem.actionTypeWhatsapp" [maxLength]="actionWhatsAppText.maxLength"></text-characters-counter>
        </div>
        }
        }
        <div class="form-group">
            <textarea placeholder=" " name="customerFeedback" #customerFeedbackText class="form-control"
                      autosize [(ngModel)]="managerItemModel.managerItem.customerFeedback"
                      maxlength="200">
          </textarea>
            <label class="control-label">Customer Feedback</label>
            <text-characters-counter [text]="managerItemModel.managerItem.customerFeedback" [maxLength]="customerFeedbackText.maxLength"></text-characters-counter>
        </div>
        <div class="form-group flex">
            <label class="checkbox-container ripple-effect ripple-inverse-color">
                <input name="reportAnomalies" #reportAnomalies="ngModel" type="checkbox"
                       [(ngModel)]="managerItemModel.managerItem.isReportAnomaly">
                <span class="checkbox-switch"></span>
                Report Anomalies (<span class="anomalies-symbol">!</span>)
            </label>
        </div>
        }
    </ng-template>

</form>
}
