export enum AlertStatusType { Pending = 1, InProcess = 2, Done = 3 }
export enum AlertEscalationRequestType { RequiresEscalation = 1, NotRequireEscalation = 2 }
export enum NotEscalatedReasonType { OccurredInAuthorizedLocation = 1, AlreadyReported = 2, LeftPerimeterInaccurateCellOrWIFILocation = 3, Other = 4 }
export enum EscalationActionType { PhoneCall = 1, Email = 2, WhatsApp = 3 }
export enum AlertReasonType { ShipmentCreated = 1, ShipmentEnded = 2 }

export class ControlCenterAlertData
{
    public id: number | null = null;
    public alertHistoryId: number | null = null;
    public shipmentKey: number | null = null;
    public eventTime: Date | null = null;
    public alertReasonName: string | null = null;
    public payingAccountName: string | null = null;
    public payingAccountId: number | null = null;
    public routeId: number | null = null;
    public routeName: string | null = null;
    public containerId: string | null = null;
    public segmentName: string | null = null;
    public eventLocation: string | null = null;
    public deviceId: string | null = null;
    public statusId: number | null = null;
    public operatorName: string | null = null;
    public escalationRequirement: number | null = null;
    public reasonNotEscalated: number | null = null;
    public notEscalatedReview: string | null = null;
    public actionTypeMail: string | null = null;
    public actionTypePhone: string | null = null;
    public actionTypeWhatsapp: string | null = null;
    public customerFeedback: string | null = null;
    public isReportAnomaly: boolean | null = null;
    public review: string | null = null;
    public created: Date | null = null;
    public modified: Date | null = null;
    public doneTime: Date | null = null;
    public eventSummary: string | null = null;
    public alertReasonId: number | null = null;
    public alertVersion: number | null = null;
    public useActionTypePhone: boolean | null = null;
    public useActionTypeWhatsapp: boolean | null = null;
    public useActionTypeMail: boolean | null = null;
}

export class DisplayControlCenterAlertData extends ControlCenterAlertData
{
    public createTimeFormatted: string | null = null;
    public eventTimeFormatted: string | null = null;
    public escalationRequirementFormatted: string | null = null;
    public isSelected: boolean = false;
}

export class ControlCenterAlertConstants
{
    public static readonly CONFLICT_MESSAGE: string = 'The selected alert has been updated by another user.';
    public static readonly CONFLICT_WITH_CHANGES_MESSAGE: string = `${this.CONFLICT_MESSAGE}<br>Any changes may have been lost.`;
}