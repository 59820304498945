import
{
    ChangeDetectionStrategy, ChangeDetectorRef, Component, HostListener, ViewChild, NgZone, TemplateRef,
    Renderer2, EmbeddedViewRef, ViewContainerRef, Input
} from "@angular/core";
import { fadeInOutAnimation, fadeSlideDownInOutAnimation } from "../../animations/fade.animation";
import { slideInOutAnimation } from "../../animations/slide.animation";
import { Constants, IListColumn, IOptionalListColumn } from "../../utils/globals";
import { foldAnimation, foldBothAnimation, foldHorizontalAnimation, foldVerticalStateAnimation } from "../../animations/fold.animation";
import { ShipmentsMonitoringModel } from "./model/shipments-monitoring.model";
import { Utils } from "../../utils/utils";
import { SwipeDirection, PointerEventsDirective } from "../../directives/pointer-events.directive";
import
{
    LiveShipmentColumnType, CompletedShipmentColumnType, ShipmentsMonitoringFilters, ShipmentsMonitoringDataLists, ShipmentsMonitoringInfo
} from "./model/shipments-monitoring-model.class";
import { ModalMessageService } from "../../controls/modal-message/services/modal-message.service";
import { ModalResultType } from "../../controls/modal-message/modal-message.component";
import { Router } from "@angular/router";
import { RoutingHistoryService } from "../../services/routing-history.service";
import { AppSettingsService } from "../../services/app-settings.service";
import { CGIMarkerShipmentsIconFactory } from "./map/cgi-marker-shipments-icon-factory";
import { VirtualListInfo } from "../../controls/virtual-list/model/virtual-list-info";
import { CGICluster } from "../../controls/google-map/utils/cgi-cluster";
import { LoginModel } from "../../user/login/model/login.model";
import { ICGIMarker, ICGIMarkerLocation } from "../../base/classes/cgi-marker";
import { MonitoringItemsBaseComponent } from "../../base/components/monitoring-items-base.component";
import * as XLSX from 'xlsx';
import { MonitoringUtils } from "../../utils/monitoring-utils";
import { CGIShipmentsClusterRenderer } from "./map/cgi-shipments-cluster-renderer";
import { CGIClusterBaseRenderer } from "../../controls/google-map/utils/cgi-cluster-base-renderer";
import { FilterElement, MonitoringDetailedEventType } from "../../base/models/monitoring-items-base-model.class";
import { MonitoringArrivalStatusType, MonitoringSegmentType } from "../../base/models/manager-base-model.class";
import { DisplayShipmentMonitoring } from "../shipment-monitoring/model/shipment-monitoring-model.class";
import { GlobalsPipe } from "../../pipes/globals.pipe";
import { RequiredValidatorDirective } from "../../validators/required.validator";
import { SelectListComponent } from "../../controls/select-list/select-list.component";
import { AutofocusDirective } from "../../directives/autofocus.directive";
import { ClearableInputComponent } from "../../controls/clearable-input/clearable-input.component";
import { OverlayScrollbarDirective } from "../../directives/overlay-scrollbar/overlay-scrollbar.directive";
import { FormsModule } from "@angular/forms";
import { LoaderComponent } from "../../controls/loader/loader.component";
import { TooltipDirective } from "../../directives/tooltip.directive";
import { VirtualListComponent } from "../../controls/virtual-list/virtual-list.component";
import { DropdownDirective } from "../../directives/dropdown.directive";
import { GoogleMapComponent } from "../../controls/google-map/google-map.component";
import { NgTemplateOutlet, NgClass, NgFor, NgSwitch, NgIf, NgSwitchCase, NgSwitchDefault } from "@angular/common";
import { TopBarComponent } from "../../controls/top-bar/top-bar.component";

@Component({
    selector: 'shipments-monitoring',
    templateUrl: './shipments-monitoring.component.html',
    styleUrls: ['../../base/styles/monitoring-items-base.component.css', '../../base/styles/monitoring-base.component.css',
        './shipments-monitoring.component.css', '../../base/styles/manager-table.css'],
    animations: [fadeInOutAnimation, slideInOutAnimation, foldAnimation, foldBothAnimation, fadeSlideDownInOutAnimation, foldHorizontalAnimation,
        foldVerticalStateAnimation],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [TopBarComponent, NgTemplateOutlet, GoogleMapComponent, DropdownDirective, VirtualListComponent, NgClass, NgFor, NgSwitch, NgIf, NgSwitchCase,
        TooltipDirective, NgSwitchDefault, LoaderComponent, FormsModule, OverlayScrollbarDirective, PointerEventsDirective, ClearableInputComponent,
        AutofocusDirective, SelectListComponent, RequiredValidatorDirective, GlobalsPipe]
})
export class ShipmentsMonitoringComponent extends MonitoringItemsBaseComponent<DisplayShipmentMonitoring, ShipmentsMonitoringFilters, ShipmentsMonitoringDataLists,
    ShipmentsMonitoringInfo>
{
    // #region Private Constants

    private readonly FAVORITES_SELECTOR: string = 'input.favorites, .list-row.selected input.table-favorites';

    // #endregion

    // #region Protected Constants

    protected override readonly SHARING_MAP_TITLE: string = 'Shipments Monitoring';

    // #endregion

    // #region Public Constants

    public readonly NO_EVENTS_VALUE: string = 'None';

    // #endregion

    // #region Private Members

    private static _completedShipmentsMonitoringTableListInfo: VirtualListInfo = new VirtualListInfo();
    private static _liveShipmentsMonitoringTableListInfo: VirtualListInfo = new VirtualListInfo();
    private static _collapseMonitoringItemsFilters: boolean = true;
    private static _collapseShipmentsMonitoringTable: boolean = true;
    private static _collapseShipmentsMonitoringMapSection: boolean = false;

    private _tableShipmentsMonitoringBaseColumns: IListColumn[] = [];
    private _tableShipmentsMonitoringOptionalColumns: IListColumn[] = [];
    private _tableShipmentsMonitoringButtonsColumns: IListColumn[] = [];

    private _filterEventElements: FilterElement[] = [];
    private _filterArrivalStatusElements: FilterElement[] = [];

    private _cgiMarkerShipmentsIconFactory: CGIMarkerShipmentsIconFactory = new CGIMarkerShipmentsIconFactory();

    @ViewChild('shipmentMoreInfoTemplate', { read: TemplateRef }) private _shipmentMoreInfoModalContentTemplateRef!: TemplateRef<any>;
    @ViewChild('shipmentFriendlyNameTemplate', { read: TemplateRef }) private _shipmentFriendlyNameModalContentTemplateRef!: TemplateRef<any>;
    @ViewChild('shipmentRemoveFromFavoritesTemplate', { read: TemplateRef }) private _shipmentRemoveFromFavoritesModalContentTemplateRef!: TemplateRef<any>;

    // #endregion

    // #region Properties

    public showShipmentsTableColumnSelection: boolean = false;

    public get isTableShipmentOptionalColumnsSelectAll(): boolean
    {
        for (const optionalShipmentColumn of this._appSettingsService.appSettings.shipmentsMonitoringSettingsData.tableOptionalShipmentColumnsToSelect)
        {
            if (!optionalShipmentColumn.selected)
            {
                return false;
            }
        }

        return true;
    }

    public set isTableShipmentOptionalColumnsSelectAll(value: boolean)
    {
        for (const optionalShipmentColumn of this._appSettingsService.appSettings.shipmentsMonitoringSettingsData.tableOptionalShipmentColumnsToSelect)
        {
            optionalShipmentColumn.selected = value;
        }

        this._appSettingsService.appSettings.shipmentsMonitoringSettingsData.tableOptionalShipmentColumnsToSelect =
            [...this._appSettingsService.appSettings.shipmentsMonitoringSettingsData.tableOptionalShipmentColumnsToSelect];

        this.updateTableShipmentOptionalColumns();
    }

    public override get collapseMonitoringItemsFilters(): boolean
    {
        return ShipmentsMonitoringComponent._collapseMonitoringItemsFilters;
    }

    public override set collapseMonitoringItemsFilters(value: boolean)
    {
        ShipmentsMonitoringComponent._collapseMonitoringItemsFilters = value;
    }

    public get collapseShipmentsMonitoringTable(): boolean
    {
        return ShipmentsMonitoringComponent._collapseShipmentsMonitoringTable;
    }

    public set collapseShipmentsMonitoringTable(value: boolean)
    {
        if (!value)
        {
            if (this.monitoringItemsModel.showCompletedMonitoringItems)
            {
                ShipmentsMonitoringComponent._completedShipmentsMonitoringTableListInfo.scrollToItem = this.monitoringItemsModel.selectedMonitoringItem;
            }
            else
            {
                ShipmentsMonitoringComponent._liveShipmentsMonitoringTableListInfo.scrollToItem = this.monitoringItemsModel.selectedMonitoringItem;
            }
        }

        ShipmentsMonitoringComponent._collapseShipmentsMonitoringTable = value;
    }

    public get collapseShipmentsMonitoringMapSection(): boolean
    {
        return ShipmentsMonitoringComponent._collapseShipmentsMonitoringMapSection;
    }

    public set collapseShipmentsMonitoringMapSection(value: boolean)
    {
        ShipmentsMonitoringComponent._collapseShipmentsMonitoringMapSection = value;
    }

    public get shipmentsMonitoringTableListInfo(): VirtualListInfo
    {
        return this.monitoringItemsModel.showCompletedMonitoringItems ? ShipmentsMonitoringComponent._completedShipmentsMonitoringTableListInfo :
            ShipmentsMonitoringComponent._liveShipmentsMonitoringTableListInfo;
    }

    public override get monitoringItemsModel(): ShipmentsMonitoringModel
    {
        return this._monitoringItemsModel as ShipmentsMonitoringModel;
    }

    public get MonitoringSegmentType()
    {
        return MonitoringSegmentType;
    }

    public get ShipmentColumnType()
    {
        return this.monitoringItemsModel.showCompletedMonitoringItems ? CompletedShipmentColumnType : LiveShipmentColumnType;
    }

    public get LiveShipmentColumnType()
    {
        return LiveShipmentColumnType;
    }

    public get tableShipmentsMonitoringOptionalColumns(): IOptionalListColumn[]
    {
        return this._appSettingsService.appSettings.shipmentsMonitoringSettingsData.tableOptionalShipmentColumnsToSelect;
    }

    public get filterEventElements(): FilterElement[]
    {
        return this._filterEventElements;
    }

    public get filterArrivalStatusElements(): FilterElement[]
    {
        return this._filterArrivalStatusElements;
    }

    // #endregion

    // #region Inputs

    @Input() public shipmentKey: string = '';

    // #endregion

    // #region Constructors

    constructor(_monitoringItemsModel: ShipmentsMonitoringModel, _renderer: Renderer2, _appSettingsService: AppSettingsService,
        _changeDetectorRef: ChangeDetectorRef, _routingHistoryService: RoutingHistoryService, _ngZone: NgZone, _modalMessageService: ModalMessageService,
        _viewContainerRef: ViewContainerRef, _router: Router, _loginModel: LoginModel)
    {
        super(_monitoringItemsModel, _renderer, _appSettingsService, _changeDetectorRef, _routingHistoryService, _ngZone, _modalMessageService,
            _viewContainerRef, _router, _loginModel);

        this._filterEventElements = [
            {
                filterType: MonitoringDetailedEventType.HighTemperature,
                class: MonitoringUtils.SHIPMENT_EVENTS_DISPLAY_PROPERTIES[MonitoringDetailedEventType.HighTemperature].class,
                iconClassName: MonitoringUtils.SHIPMENT_EVENTS_DISPLAY_PROPERTIES[MonitoringDetailedEventType.HighTemperature].iconClassName,
                value: 0,
                tooltip: MonitoringUtils.SHIPMENT_EVENTS_DISPLAY_PROPERTIES[MonitoringDetailedEventType.HighTemperature].tooltip
            },
            {
                filterType: MonitoringDetailedEventType.HighHumidity,
                class: MonitoringUtils.SHIPMENT_EVENTS_DISPLAY_PROPERTIES[MonitoringDetailedEventType.HighHumidity].class,
                iconClassName: MonitoringUtils.SHIPMENT_EVENTS_DISPLAY_PROPERTIES[MonitoringDetailedEventType.HighHumidity].iconClassName,
                value: 0,
                tooltip: MonitoringUtils.SHIPMENT_EVENTS_DISPLAY_PROPERTIES[MonitoringDetailedEventType.HighHumidity].tooltip
            },
            {
                filterType: MonitoringDetailedEventType.StrongImpact,
                class: MonitoringUtils.SHIPMENT_EVENTS_DISPLAY_PROPERTIES[MonitoringDetailedEventType.StrongImpact].class,
                iconClassName: MonitoringUtils.SHIPMENT_EVENTS_DISPLAY_PROPERTIES[MonitoringDetailedEventType.StrongImpact].iconClassName,
                value: 0,
                tooltip: MonitoringUtils.SHIPMENT_EVENTS_DISPLAY_PROPERTIES[MonitoringDetailedEventType.StrongImpact].tooltip
            },
            {
                filterType: MonitoringDetailedEventType.Security,
                class: MonitoringUtils.SHIPMENT_EVENTS_DISPLAY_PROPERTIES[MonitoringDetailedEventType.Security].class,
                iconClassName: MonitoringUtils.SHIPMENT_EVENTS_DISPLAY_PROPERTIES[MonitoringDetailedEventType.Security].iconClassName,
                value: 0,
                tooltip: MonitoringUtils.SHIPMENT_EVENTS_DISPLAY_PROPERTIES[MonitoringDetailedEventType.Security].tooltip
            }
        ];

        this._filterArrivalStatusElements = [
            {
                filterType: MonitoringArrivalStatusType.Late,
                class: MonitoringUtils.SHIPMENT_ARRIVAL_STATUSES_DISPLAY_PROPERTIES[MonitoringArrivalStatusType.Late].class,
                iconClassName: MonitoringUtils.SHIPMENT_ARRIVAL_STATUSES_DISPLAY_PROPERTIES[MonitoringArrivalStatusType.Late].iconClassName,
                value: 0,
                tooltip: MonitoringUtils.SHIPMENT_ARRIVAL_STATUSES_DISPLAY_PROPERTIES[MonitoringArrivalStatusType.Late].tooltip
            },
            {
                filterType: MonitoringArrivalStatusType.Early,
                class: MonitoringUtils.SHIPMENT_ARRIVAL_STATUSES_DISPLAY_PROPERTIES[MonitoringArrivalStatusType.Early].class,
                iconClassName: MonitoringUtils.SHIPMENT_ARRIVAL_STATUSES_DISPLAY_PROPERTIES[MonitoringArrivalStatusType.Early].iconClassName,
                value: 0,
                tooltip: MonitoringUtils.SHIPMENT_ARRIVAL_STATUSES_DISPLAY_PROPERTIES[MonitoringArrivalStatusType.Early].tooltip
            },
            {
                filterType: MonitoringArrivalStatusType.Unknown,
                class: MonitoringUtils.SHIPMENT_ARRIVAL_STATUSES_DISPLAY_PROPERTIES[MonitoringArrivalStatusType.Unknown].class,
                iconClassName: MonitoringUtils.SHIPMENT_ARRIVAL_STATUSES_DISPLAY_PROPERTIES[MonitoringArrivalStatusType.Unknown].iconClassName,
                value: 0,
                tooltip: MonitoringUtils.SHIPMENT_ARRIVAL_STATUSES_DISPLAY_PROPERTIES[MonitoringArrivalStatusType.Unknown].tooltip
            }
        ];
    }

    // #endregion

    // #region Event Handlers

    public override ngOnInit(): void
    {
        const isPopState: boolean = this._routingHistoryService.isPopState && this._monitoringItemsModel.isInitialized;

        super.ngOnInit();

        if (!isPopState)
        {
            ShipmentsMonitoringComponent._externalShipmentsFilterTableListInfo = new VirtualListInfo();

            ShipmentsMonitoringComponent._liveShipmentsMonitoringTableListInfo = new VirtualListInfo();
            ShipmentsMonitoringComponent._liveShipmentsMonitoringTableListInfo.sortColumn = LiveShipmentColumnType.InstallDate;
            ShipmentsMonitoringComponent._liveShipmentsMonitoringTableListInfo.sortOrder = -1;

            ShipmentsMonitoringComponent._collapseMonitoringItemsFilters = Constants.IS_MOBILE;
            ShipmentsMonitoringComponent._collapseShipmentsMonitoringTable = false;

            ShipmentsMonitoringComponent._completedShipmentsMonitoringTableListInfo = new VirtualListInfo();
            ShipmentsMonitoringComponent._completedShipmentsMonitoringTableListInfo.sortOrder = -1;
            ShipmentsMonitoringComponent._completedShipmentsMonitoringTableListInfo.sortColumn = CompletedShipmentColumnType.RemoveDate;
        }
    }

    @HostListener('document:keyup.escape') onKeyUpHandler(): void
    {
        if (Constants.IS_MOBILE)
        {
            this.collapseMonitoringItemsFilters = true;
        }
    }

    public onMarkerShipmentMoreInfoButtonClick(event: MouseEvent, displayShipmentMonitoring: DisplayShipmentMonitoring): void
    {
        event.stopImmediatePropagation();

        this._modalMessageService.show({ modalContentTemplateRef: this._shipmentMoreInfoModalContentTemplateRef, modalContentTemplateData: displayShipmentMonitoring });
    }

    public onTableShipmentOptionalColumnsSelectChange(): void
    {
        this._appSettingsService.appSettings.shipmentsMonitoringSettingsData.tableOptionalShipmentColumnsToSelect =
            [...this._appSettingsService.appSettings.shipmentsMonitoringSettingsData.tableOptionalShipmentColumnsToSelect];

        this.updateTableShipmentOptionalColumns();
    }

    public override onShipmentTypeChange(): void
    {
        super.onShipmentTypeChange();

        this.initializeMonitoringItemsTableColumns();

        if (this._monitoringItemsVirtualList !== undefined)
        {
            this._monitoringItemsVirtualList.scrollTop = (this.monitoringItemsModel.showCompletedMonitoringItems ?
                ShipmentsMonitoringComponent._completedShipmentsMonitoringTableListInfo.scrollTop :
                ShipmentsMonitoringComponent._liveShipmentsMonitoringTableListInfo.scrollTop) ?? 0;
        }
    }

    public onExportToExcelButtonClick(): void
    {
        const exportTableColumns: IListColumn[] = [...this._tableShipmentsMonitoringBaseColumns, ...this._tableShipmentsMonitoringOptionalColumns];

        const exportData: any[] = this.monitoringItemsModel.filteredMonitoringItems.map((displayShipmentMonitoring: DisplayShipmentMonitoring) =>
        {
            const rowData: any = {};

            for (const shipmentColumn of exportTableColumns)
            {
                if (shipmentColumn.text !== undefined && shipmentColumn.propertyName !== undefined)
                {
                    rowData[Utils.removeTextHtmlTags(shipmentColumn.text)] = (displayShipmentMonitoring as any)[shipmentColumn.propertyName] +
                        (shipmentColumn.columnType === this.getShipmentColumnType(LiveShipmentColumnType[LiveShipmentColumnType.RouteProgress]) ? '%' : '');
                }
            }

            rowData['Current Latitude'] = displayShipmentMonitoring.currentLat;
            rowData['Current Longitude'] = displayShipmentMonitoring.currentLon;

            return rowData;
        });

        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportData);
        const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };

        XLSX.writeFile(workbook, Utils.getFileDateName(this.EXCEL_FILE_PREFIX, '.xlsx', new Date()), { compression: true });
    }

    public onFiltersSwipe(swipeDirection: SwipeDirection): void
    {
        if (swipeDirection === SwipeDirection.Left)
        {
            this.collapseMonitoringItemsFilters = true;
        }
    }

    public onShipmentFriendlyNameSubmit(): void
    {
        this._modalMessageService.instance?.closeModal(ModalResultType.Yes);
    }

    public onRemoveFromFavoriesButtonClick(): void
    {
        this._modalMessageService.instance?.closeModal(ModalResultType.Yes);
    }

    public onShipmentMonitoringFavoriteClick(event: MouseEvent, shipmentMonitoring: DisplayShipmentMonitoring, forceStopPropagation: boolean): void
    {
        if (forceStopPropagation || this.monitoringItemsModel.selectedMonitoringItem === shipmentMonitoring)
        {
            event.stopImmediatePropagation();
        }

        if (event.currentTarget === null)
        {
            return;
        }

        event.preventDefault();

        if (this.monitoringItemsModel.isShipmentMonitoringInFavorites(shipmentMonitoring.shipmentKey))
        {
            this._modalMessageService.show({ modalContentTemplateRef: this._shipmentRemoveFromFavoritesModalContentTemplateRef }).then((result: ModalResultType) =>
            {
                if (result === ModalResultType.Yes)
                {
                    this.checkFavoritesCheckboxes(false);

                    if (this.monitoringItemsModel.removeShipmentMonitoringFromFavorites(shipmentMonitoring.shipmentKey!))
                    {
                        this.applyMonitoringFilters(true);
                        this.monitoringItemsModel.updateMonitoringDataListsByFilters();
                    }

                    this._changeDetectorRef.detectChanges();
                }
            });
        }
        else
        {
            this.monitoringItemsModel.shipmentFriendlyName = `${shipmentMonitoring.containerId} - ${shipmentMonitoring.deviceId}`;
            this._modalMessageService.show({ modalContentTemplateRef: this._shipmentFriendlyNameModalContentTemplateRef }).then((result: ModalResultType) =>
            {
                if (result === ModalResultType.Yes && this.monitoringItemsModel.shipmentFriendlyName !== null)
                {
                    if (this.monitoringItemsModel.addShipmentMonitoringToFavorite(shipmentMonitoring.shipmentKey!, this.monitoringItemsModel.shipmentFriendlyName))
                    {
                        this.checkFavoritesCheckboxes(true);

                        this.monitoringItemsModel.updateMonitoringDataListsByFilters();

                        this._changeDetectorRef.detectChanges();
                    }
                }
            });
        }
    }

    public override async onShareSelectedMonitoringInfo(shareCopyMonitoringInfo: DisplayShipmentMonitoring, copyOnlyToClipboard: boolean): Promise<void>
    {
        this.collapseShareOptions();

        const shareText: string = `${this.SHARING_INFO_TITLE}\r\n${''
            }Arrival Status: ${shareCopyMonitoringInfo.delayString}\r\n${''
            }${this._loginModel.isAccountTypeAmazonUS ? 'Trailer Number' : this.containerIdColumnName}: ${this._loginModel.isAccountTypeAmazonUS ? shareCopyMonitoringInfo.trailerNumber : shareCopyMonitoringInfo.containerId} \r\n${''
            }${this.installDateColumnName}${this._appSettingsService.appSettings.isUsingUTCTime ? ' (UTC)' : ''}: ${this.getFormattedDateTime(shareCopyMonitoringInfo.installDate)}\r\n${''
            }Last Update${this._appSettingsService.appSettings.isUsingUTCTime ? ' (UTC)' : ''}: ${this.getFormattedDateTime(shareCopyMonitoringInfo.lastUpdate)}\r\n${''
            }ETA${this._appSettingsService.appSettings.isUsingUTCTime ? ' (UTC)' : ''}: ${this.getFormattedDateTime(shareCopyMonitoringInfo.eta)}\r\n${''
            }Reference: ${shareCopyMonitoringInfo.bookingReference}\r\n${''
            }${this.deviceIdColumnName}: ${shareCopyMonitoringInfo.deviceDescription}\r\n${''
            }CG-ID: ${shareCopyMonitoringInfo.shipmentKey}\r\n${''
            }Coordinates: ${shareCopyMonitoringInfo.currentLat}\xb0, ${shareCopyMonitoringInfo.currentLon}\xb0\r\n${''
            }Events:${!shareCopyMonitoringInfo.humidityEvents && !shareCopyMonitoringInfo.impactEvents && !shareCopyMonitoringInfo.temperatureEvents &&
                !shareCopyMonitoringInfo.securityEvents ? ' ' + this.NO_EVENTS_VALUE :
                ((shareCopyMonitoringInfo.humidityEvents ? ' Humidity: ' + shareCopyMonitoringInfo.humidityEvents : '') +
                    (shareCopyMonitoringInfo.impactEvents ? ' Impact: ' + shareCopyMonitoringInfo.impactEvents : '') +
                    (shareCopyMonitoringInfo.temperatureEvents ? ' Temperature: ' + shareCopyMonitoringInfo.temperatureEvents : '') +
                    (shareCopyMonitoringInfo.securityEvents ? ' Security: ' + shareCopyMonitoringInfo.securityEvents : ''))}\r\n${''
            }Origin Route: ${shareCopyMonitoringInfo.originName}\r\n${''
            }Destination Route: ${shareCopyMonitoringInfo.destinationName}\r\n${''
            }Route Segment: ${shareCopyMonitoringInfo.currentLocDes}\r\n${''
            }Route Progress: ${shareCopyMonitoringInfo.progress}%`;

        await this.shareInfo(this.SHARING_INFO_TITLE, shareText, copyOnlyToClipboard);
    }

    public onFilterEventSelectChange(shipmentMonitoringEventType: MonitoringDetailedEventType): void
    {
        this.monitoringItemsModel.updateEventFilter(shipmentMonitoringEventType);
        this.applyMonitoringFilters();

        this.monitoringItemsModel.updateMonitoringDataListsByFilters();
    }

    public onFilterEventSelectClear(): void
    {
        this.monitoringItemsModel.editMonitoringFilters.selectedShipmentMonitoringEvents = [];
        this.applyMonitoringFilters();

        this.monitoringItemsModel.updateMonitoringDataListsByFilters();
    }

    public onFilterArrivalStatusSelectChange(shipmentMonitoringArrivalStatusType: MonitoringArrivalStatusType): void
    {
        this.monitoringItemsModel.updateArrivalStatusFilter(shipmentMonitoringArrivalStatusType);
        this.applyMonitoringFilters();

        this.monitoringItemsModel.updateMonitoringDataListsByFilters();
    }

    public onFilterArrivalStatusSelectClear(): void
    {
        this.monitoringItemsModel.editMonitoringFilters.selectedShipmentMonitoringArrivalStatuses = [];
        this.applyMonitoringFilters();

        this.monitoringItemsModel.updateMonitoringDataListsByFilters();
    }

    // #endregion

    // #region Protected Methods

    protected override buildClusterInfowWindow(showClusterMarkersSummary: boolean, cluster: CGICluster, isCollapsable: boolean): void
    {
        let embeddedViewRef: EmbeddedViewRef<any>;

        if (showClusterMarkersSummary)
        {
            let lateShipmentsCount = 0;
            let onTimeShipmentsCount = 0;
            let unknownShipmentsCount = 0;
            for (const marker of cluster.markers)
            {
                const displayShipmentMonitoring: DisplayShipmentMonitoring = this.getMarkerData(marker) as DisplayShipmentMonitoring;
                if (displayShipmentMonitoring.arrivalStatusId === MonitoringArrivalStatusType.Late)
                {
                    lateShipmentsCount++;
                }
                else if (displayShipmentMonitoring.arrivalStatusId === MonitoringArrivalStatusType.Early ||
                    displayShipmentMonitoring.arrivalStatusId === MonitoringArrivalStatusType.OnTime)
                {
                    onTimeShipmentsCount++;
                }
                else
                {
                    unknownShipmentsCount++;
                }
            }

            embeddedViewRef = this._viewContainerRef.createEmbeddedView(this._infoWindowMarkerClusterTemplateRef,
                { $implicit: { lateShipmentsCount: lateShipmentsCount, onTimeShipmentsCount: onTimeShipmentsCount, unknownShipmentsCount: unknownShipmentsCount } });
        }
        else
        {
            embeddedViewRef = this._viewContainerRef.createEmbeddedView(this._infoWindowMarkerTemplateRef,
                {
                    info: cluster.markers.map((marker: google.maps.marker.AdvancedMarkerElement | ICGIMarkerLocation) =>
                        (this.getMarkerData(marker) as DisplayShipmentMonitoring)).sort((a: DisplayShipmentMonitoring, b: DisplayShipmentMonitoring) =>
                            (a.eta !== null ? a.eta.getTime() : 0) - (b.eta !== null ? b.eta.getTime() : 0)),
                    isCollapsable: isCollapsable
                });
        }

        this.openMarkerPopup(cluster.marker!, embeddedViewRef.rootNodes[0], showClusterMarkersSummary);
    }

    protected override getClusterRenderer(): CGIClusterBaseRenderer
    {
        return new CGIShipmentsClusterRenderer();
    }

    protected override updateFilterElementsInfo(): void
    {
        for (const filterEventElement of this._filterEventElements)
        {
            switch (filterEventElement.filterType)
            {
                case MonitoringDetailedEventType.HighTemperature:
                    {
                        filterEventElement.value = this.monitoringItemsModel.monitoringItemsInfo.tempratureEventsCount;
                    }
                    break;

                case MonitoringDetailedEventType.HighHumidity:
                    {
                        filterEventElement.value = this.monitoringItemsModel.monitoringItemsInfo.humidityEventsCount;
                    }
                    break;

                case MonitoringDetailedEventType.StrongImpact:
                    {
                        filterEventElement.value = this.monitoringItemsModel.monitoringItemsInfo.impactEventsCount;
                    }
                    break;

                case MonitoringDetailedEventType.Security:
                    {
                        filterEventElement.value = this.monitoringItemsModel.monitoringItemsInfo.securityEventsCount;
                    }
                    break;
            }
        }

        for (const filterArrivalStatusElement of this._filterArrivalStatusElements)
        {
            switch (filterArrivalStatusElement.filterType)
            {
                case MonitoringArrivalStatusType.Unknown:
                    {
                        filterArrivalStatusElement.value = this.monitoringItemsModel.monitoringItemsInfo.unknownCount;
                    }
                    break;

                case MonitoringArrivalStatusType.Late:
                    {
                        filterArrivalStatusElement.value = this.monitoringItemsModel.monitoringItemsInfo.lateCount;
                    }
                    break;

                case MonitoringArrivalStatusType.Early:
                case MonitoringArrivalStatusType.OnTime:
                    {
                        filterArrivalStatusElement.value = this.monitoringItemsModel.monitoringItemsInfo.earlyOrOnTimeCount;
                    }
                    break;
            }
        }
    }

    protected override loadMonitoringItems(recenterMap: boolean): void
    {
        super.loadMonitoringItems(recenterMap, () =>
        {
            this.initializeMonitoringItemsTableColumns();

            if (this.shipmentKey !== undefined && this.shipmentKey !== '')
            {
                const shipmentKey: number = Number(this.shipmentKey);
                this.monitoringItemsModel.selectedMonitoringItem = this.monitoringItemsModel.findMonitoringItem(shipmentKey);
                if (this.monitoringItemsModel.selectedMonitoringItem === null)
                {
                    this.setViewState(false, MonitoringUtils.MISSING_SHIPMENT);
                    return;
                }

                this.monitoringItemsModel.editMonitoringFilters.selectedShipmentMonitoringKeys = [shipmentKey];
                this.applyMonitoringFilters();
            }
        });
    }

    protected override ctreateGoogleMapMarker(shipmentMonitoring: DisplayShipmentMonitoring): ICGIMarker<DisplayShipmentMonitoring>
    {
        const marker: ICGIMarker<DisplayShipmentMonitoring> = new google.maps.marker.AdvancedMarkerElement({
            position: { lat: shipmentMonitoring.currentLat, lng: shipmentMonitoring.currentLon },
            content: this._cgiMarkerShipmentsIconFactory.createCGIMarkerArrivalStatusElement(MonitoringUtils.getShipmentIconClassName(
                shipmentMonitoring.segmentId, shipmentMonitoring.perimeterType), shipmentMonitoring.arrivalStatusId),
            map: null
        }) as ICGIMarker<DisplayShipmentMonitoring>;

        marker.data = shipmentMonitoring;

        this.addMarkerClickEvent(marker);

        return marker;
    }

    protected override initializeMonitoringItemsTableColumns(): void
    {
        this.initializeShipmentsMonitoringTableBaseColumns();
        this.initializeShipmentsMonitoringTableButtonsColumns();
        this.initializeShipmentsMonitoringTableOptionalColumns();

        if (this._appSettingsService.appSettings.shipmentsMonitoringSettingsData.tableOptionalShipmentColumnsToSelect.length === 0 ||
            this._appSettingsService.appSettings.shipmentsMonitoringSettingsData.tableOptionalShipmentColumnsToSelect.length !==
            this._tableShipmentsMonitoringOptionalColumns.length)
        {
            this._appSettingsService.appSettings.shipmentsMonitoringSettingsData.tableOptionalShipmentColumnsToSelect =
                this._tableShipmentsMonitoringOptionalColumns.map((shipmentColumn: IListColumn) =>
                {
                    const optionalShipmentColumn: IOptionalListColumn =
                    {
                        columnType: shipmentColumn.columnType,
                        text: shipmentColumn.text,
                        selected: shipmentColumn.columnType !== this.getShipmentColumnType(LiveShipmentColumnType[LiveShipmentColumnType.ShipmentKey])
                    };

                    return optionalShipmentColumn;
                });
        }

        this.updateTableShipmentOptionalColumns();

        this.initializeTableExternalShipmentsMonitoringSearchColumns();
    }

    // #endregion

    // #region Private Methods

    private getShipmentColumnType(columnType: string): number
    {
        return this.monitoringItemsModel.showCompletedMonitoringItems ? (CompletedShipmentColumnType as any)[columnType] : (LiveShipmentColumnType as any)[columnType];
    }

    private initializeShipmentsMonitoringTableBaseColumns(): void
    {
        const displayShipmentMonitoring: DisplayShipmentMonitoring = new DisplayShipmentMonitoring();

        this._tableShipmentsMonitoringBaseColumns =
            [
                {
                    columnType: this.getShipmentColumnType(LiveShipmentColumnType[LiveShipmentColumnType.ShipmentKey]),
                    text: this._loginModel.isAccountTypeAmazonUS ? 'Trailer No.' : this.containerIdColumnName,
                    classes: 'col-container-id',
                    propertyName: Utils.getPropertyNameof<DisplayShipmentMonitoring>(displayShipmentMonitoring, displayShipmentMonitoring =>
                        this._loginModel.isAccountTypeAmazonUS ? displayShipmentMonitoring.trailerNumber : displayShipmentMonitoring.containerId)
                },
                {
                    columnType: this.getShipmentColumnType(LiveShipmentColumnType[LiveShipmentColumnType.RouteDescription]),
                    text: 'Route',
                    classes: 'col-route',
                    propertyName: Utils.getPropertyNameof<DisplayShipmentMonitoring>(displayShipmentMonitoring, displayShipmentMonitoring => displayShipmentMonitoring.routeDescription)
                }
            ];

        if (!this.monitoringItemsModel.showCompletedMonitoringItems)
        {
            this._tableShipmentsMonitoringBaseColumns.push(
                {
                    columnType: this.getShipmentColumnType(LiveShipmentColumnType[LiveShipmentColumnType.Segment]),
                    text: 'Segment',
                    propertyName: Utils.getPropertyNameof<DisplayShipmentMonitoring>(displayShipmentMonitoring, displayShipmentMonitoring => displayShipmentMonitoring.segmentDescription)
                },
                {
                    columnType: this.getShipmentColumnType(LiveShipmentColumnType[LiveShipmentColumnType.RouteProgress]),
                    text: 'Progress',
                    classes: 'col-route-progress',
                    propertyName: Utils.getPropertyNameof<DisplayShipmentMonitoring>(displayShipmentMonitoring, displayShipmentMonitoring => displayShipmentMonitoring.progress)
                }
            )
        }

        this._tableShipmentsMonitoringBaseColumns.push(
            {
                columnType: this.getShipmentColumnType(LiveShipmentColumnType[LiveShipmentColumnType.InstallDate]),
                text: `Started<span class="small-units">${this._appSettingsService.appSettings.isUsingUTCTime ? ' (UTC)' : ''}</span>`,
                propertyName: Utils.getPropertyNameof<DisplayShipmentMonitoring>(displayShipmentMonitoring, displayShipmentMonitoring =>
                    displayShipmentMonitoring.installDateShortFormatted),
                sortPropertyName: Utils.getPropertyNameof<DisplayShipmentMonitoring>(displayShipmentMonitoring, displayShipmentMonitoring => displayShipmentMonitoring.installDate)
            });

        if (this.monitoringItemsModel.showCompletedMonitoringItems)
        {
            this._tableShipmentsMonitoringBaseColumns.push(
                {
                    columnType: CompletedShipmentColumnType.RemoveDate,
                    text: `Ended<span class="small-units">${this._appSettingsService.appSettings.isUsingUTCTime ? ' (UTC)' : ''}</span>`,
                    propertyName: Utils.getPropertyNameof<DisplayShipmentMonitoring>(displayShipmentMonitoring, displayShipmentMonitoring =>
                        displayShipmentMonitoring.removeDateShortFormatted),
                    sortPropertyName: Utils.getPropertyNameof<DisplayShipmentMonitoring>(displayShipmentMonitoring, displayShipmentMonitoring => displayShipmentMonitoring.removeDate)
                });
        }

        this._tableShipmentsMonitoringBaseColumns.push(
            {
                columnType: this.getShipmentColumnType(LiveShipmentColumnType[LiveShipmentColumnType.Eta]),
                text: `ETA<span class="small-units">${this._appSettingsService.appSettings.isUsingUTCTime ? ' (UTC)' : ''}</span>`,
                propertyName: Utils.getPropertyNameof<DisplayShipmentMonitoring>(displayShipmentMonitoring, displayShipmentMonitoring => displayShipmentMonitoring.etaShortFormatted),
                sortPropertyName: Utils.getPropertyNameof<DisplayShipmentMonitoring>(displayShipmentMonitoring, displayShipmentMonitoring => displayShipmentMonitoring.eta)
            },
            {
                columnType: this.getShipmentColumnType(LiveShipmentColumnType[LiveShipmentColumnType.ArrivalStatus]),
                text: 'Arrival Status',
                propertyName: Utils.getPropertyNameof<DisplayShipmentMonitoring>(displayShipmentMonitoring, displayShipmentMonitoring => displayShipmentMonitoring.delayString),
                sortPropertyName: Utils.getPropertyNameof<DisplayShipmentMonitoring>(displayShipmentMonitoring, displayShipmentMonitoring => displayShipmentMonitoring.delayTime)
            }
        );
    }

    private initializeShipmentsMonitoringTableOptionalColumns(): void
    {
        this._tableShipmentsMonitoringOptionalColumns = [];

        const displayShipmentMonitoring: DisplayShipmentMonitoring = new DisplayShipmentMonitoring();

        if (this.monitoringItemsModel.currentMonitoringItemsSourceInfo.tempratureEventsCount > 0)
        {
            this._tableShipmentsMonitoringOptionalColumns.push(
                {
                    columnType: this.getShipmentColumnType(LiveShipmentColumnType[LiveShipmentColumnType.Temperature]),
                    text: 'Temperature',
                    classes: 'col-icon center',
                    propertyName: Utils.getPropertyNameof<DisplayShipmentMonitoring>(displayShipmentMonitoring, displayShipmentMonitoring => displayShipmentMonitoring.temperatureEvents),
                    isFixedWidth: true
                });
        }

        if (this.monitoringItemsModel.currentMonitoringItemsSourceInfo.humidityEventsCount > 0)
        {
            this._tableShipmentsMonitoringOptionalColumns.push(
                {
                    columnType: this.getShipmentColumnType(LiveShipmentColumnType[LiveShipmentColumnType.Humidity]),
                    text: 'Humidity',
                    classes: 'col-icon center',
                    propertyName: Utils.getPropertyNameof<DisplayShipmentMonitoring>(displayShipmentMonitoring, displayShipmentMonitoring => displayShipmentMonitoring.humidityEvents),
                    isFixedWidth: true
                });
        }

        if (this.monitoringItemsModel.currentMonitoringItemsSourceInfo.impactEventsCount > 0)
        {
            this._tableShipmentsMonitoringOptionalColumns.push(
                {
                    columnType: this.getShipmentColumnType(LiveShipmentColumnType[LiveShipmentColumnType.Impact]),
                    text: 'Impact',
                    classes: 'col-icon center',
                    propertyName: Utils.getPropertyNameof<DisplayShipmentMonitoring>(displayShipmentMonitoring, displayShipmentMonitoring => displayShipmentMonitoring.impactEvents),
                    isFixedWidth: true
                });
        }

        if (this.monitoringItemsModel.currentMonitoringItemsSourceInfo.securityEventsCount > 0)
        {
            this._tableShipmentsMonitoringOptionalColumns.push(
                {
                    columnType: this.getShipmentColumnType(LiveShipmentColumnType[LiveShipmentColumnType.Security]),
                    text: 'Security',
                    classes: 'col-icon center',
                    propertyName: Utils.getPropertyNameof<DisplayShipmentMonitoring>(displayShipmentMonitoring, displayShipmentMonitoring => displayShipmentMonitoring.securityEvents),
                    isFixedWidth: true
                });
        }

        this._tableShipmentsMonitoringOptionalColumns.push(
            {
                columnType: this.getShipmentColumnType(LiveShipmentColumnType[LiveShipmentColumnType.BookingReference]),
                text: 'Reference',
                classes: 'col-reference',
                propertyName: Utils.getPropertyNameof<DisplayShipmentMonitoring>(displayShipmentMonitoring, displayShipmentMonitoring => displayShipmentMonitoring.bookingReference)
            },
            {
                columnType: this.getShipmentColumnType(LiveShipmentColumnType[LiveShipmentColumnType.ShipmentKey]),
                text: 'CG-ID',
                classes: 'col-shipment-key',
                propertyName: Utils.getPropertyNameof<DisplayShipmentMonitoring>(displayShipmentMonitoring, displayShipmentMonitoring => displayShipmentMonitoring.shipmentKey)
            },
            {
                columnType: this.getShipmentColumnType(LiveShipmentColumnType[LiveShipmentColumnType.DeviceId]),
                text: this.deviceIdColumnName,
                classes: 'col-device-id',
                propertyName: Utils.getPropertyNameof<DisplayShipmentMonitoring>(displayShipmentMonitoring, displayShipmentMonitoring => displayShipmentMonitoring.deviceDescription)
            }
        );
    }

    private initializeShipmentsMonitoringTableButtonsColumns(): void
    {
        this._tableShipmentsMonitoringButtonsColumns =
            [
                {
                    columnType: this.getShipmentColumnType(LiveShipmentColumnType[LiveShipmentColumnType.Copy]),
                    classes: 'col-button center',
                    isFixedWidth: true
                },
                {
                    columnType: this.getShipmentColumnType(LiveShipmentColumnType[LiveShipmentColumnType.Share]),
                    classes: 'col-button center',
                    isFixedWidth: true
                },
                {
                    columnType: this.getShipmentColumnType(LiveShipmentColumnType[LiveShipmentColumnType.Favorites]),
                    classes: 'col-button center',
                    isFixedWidth: true
                },
                {
                    columnType: this.getShipmentColumnType(LiveShipmentColumnType[LiveShipmentColumnType.Track]),
                    classes: 'col-button center',
                    isFixedWidth: true
                }
            ];
    }

    private updateTableShipmentOptionalColumns(): void
    {
        this._tableMonitoringItemsColumns = [...this._tableShipmentsMonitoringBaseColumns,
        ...this._tableShipmentsMonitoringOptionalColumns.filter((shipmentColumn: IListColumn) =>
        {
            for (const optionalShipmentColumn of this._appSettingsService.appSettings.shipmentsMonitoringSettingsData.tableOptionalShipmentColumnsToSelect)
            {
                if (this.getShipmentColumnType(LiveShipmentColumnType[optionalShipmentColumn.columnType]) === shipmentColumn.columnType)
                {
                    return optionalShipmentColumn.selected;
                }
            }

            return false;
        }), ...this._tableShipmentsMonitoringButtonsColumns];
    }

    private checkFavoritesCheckboxes(isChecked: boolean): void
    {
        for (const connectedFavoritesChecboxElement of Array.from(document.querySelectorAll(this.FAVORITES_SELECTOR)))
        {
            (connectedFavoritesChecboxElement as HTMLInputElement).checked = isChecked;
        }
    }

    // #endregion
}