import { MonitoringBaseInfo } from "../../base/models/monitoring-items-base-model.class";

export enum DemurrageContractDeviationType { Late, Early, Unknown }

export enum DemurrageMonitoringColumnType
{
    ContainerId, RouteDescription, Carrier, Port, PortCode, Country, EnteringDate, Duration, FreeDays, Deviation, Copy, Share, Track
}

export class DemurrageMonitoringFilters
{
    public selectedShipmentMonitoringKeys: number[] = [];
    public selectedShipmentMonitoringCargosUnits: string[] = [];
    public selectedShipmentMonitoringTrailersNumbers: string[] = [];
    public selectedShipmentMonitoringCarriers: string[] = [];
    public selectedShipmentMonitoringPorts: string[] = [];
    public selectedShipmentMonitoringOriginsRoutes: string[] = [];
    public selectedShipmentMonitoringDestinationsRoutes: string[] = [];
    public selectedShipmentMonitoringSegments: string[] = [];
    public selectedShipmentMonitoringDemurrageStatuses: DemurrageContractDeviationType[] = [];
    public minDeviation: number | null = null;
    public maxBeforeDeviation: number | null = null;
    public minDuration: number | null = null;
}

export class DemurrageMonitoringDataLists
{
    public shipmentMonitoringKeys: number[] = [];
    public shipmentMonitoringCargosUnits: string[] = [];
    public shipmentMonitoringTrailersNumbers: string[] = [];
    public shipmentMonitoringCarriers: string[] = [];
    public shipmentMonitoringPorts: string[] = [];
    public shipmentMonitoringOriginsRoutes: string[] = [];
    public shipmentMonitoringDestinationsRoutes: string[] = [];
    public shipmentMonitoringSegments: string[] = [];
}
export class ShipmentMonitoringDemurrageInfo extends MonitoringBaseInfo
{
    public deviationLateCount: number = 0;
    public deviationEarlyCount: number = 0;
    public deviationUnknownCount: number = 0;
    public maxPortDuration: number = 0;
    public maxBeforeDeviation: number = 0;
    public maxDeviation: number = 0;
}