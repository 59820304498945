import { Directive, HostListener } from '@angular/core';

@Directive({
    selector: 'form[ignoreEnter]',
    standalone: true
})
export class FormIgnoreEnterDirective
{
    // #region Event Handlers

    @HostListener('keydown.enter', ['$event']) onKeyDownEnterHandler(event: KeyboardEvent): void
    {
        if (!(event.target instanceof HTMLTextAreaElement))
        {
            event.preventDefault();
        }
    }

    // #endregion
}