export enum ManagerItemStatus { NotStarted = 1, Live = 2, Completed = 3, Canceled = 4, NA = 5 }

export enum MonitoringPopupType { More, Info, Help }

export enum MonitoringArrivalStatusType { Unknown, Early, OnTime, Late }

export enum MonitoringSegmentType
{
    Unknown = 0,
    Origin = 1,
    PortOfLoading = 2,
    PortOfDestination = 3,
    Destination = 4,
    Waypoint = 5,
    Way = 6,
    Transhipment = 7,
    PreRoute = 8,
    PostRoute = 9
}

