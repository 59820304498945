import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { fadeInOutAnimation, fadeOutAnimation } from '../animations/fade.animation';
import { StartFormModel } from './model/start-form.model';
import { RoutingHistoryService } from '../services/routing-history.service';
import { ModalMessageService } from '../controls/modal-message/services/modal-message.service';
import { Constants, IApiResponse } from '../utils/globals';
import { LoginModel } from '../user/login/model/login.model';
import { Utils } from '../utils/utils';
import { foldAnimation, foldBothAnimation } from '../animations/fold.animation';
import { CorpIdType } from '../user/login/model/login-model.class';
import { AttachmentsComponent } from '../base/components/attachments.component';
import { ModalType } from '../controls/modal-message/modal-message.component';
import { NgForm, FormsModule } from '@angular/forms';
import { SafePipe } from '../pipes/safe.pipe';
import { LoaderComponent } from '../controls/loader/loader.component';
import { CameraComponent } from '../controls/camera/camera.component';
import { DragDropDirective } from '../directives/drag-drop.directive';
import { TextAreaAutosizeDirective } from '../directives/textarea-autoresize.directive';
import { DateTimePickerComponent } from '../controls/datetime-picker/datetime-picker.component';
import { SelectListComponent } from '../controls/select-list/select-list.component';
import { EmailValidatorDirective } from '../validators/email.validator';
import { RequiredValidatorDirective } from '../validators/required.validator';
import { ClearableInputComponent } from '../controls/clearable-input/clearable-input.component';
import { OverlayScrollbarDirective } from '../directives/overlay-scrollbar/overlay-scrollbar.directive';
import { FormIgnoreEnterDirective } from '../directives/form-ignore-enter.directive';

@Component({
    selector: 'start-form',
    templateUrl: './start-form.component.html',
    styleUrls: ['../controls/camera/camera.component.css', '../base/styles/manager-item-base.css', './start-form.component.css'],
    animations: [fadeOutAnimation, fadeInOutAnimation, foldAnimation, foldBothAnimation],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [FormsModule, FormIgnoreEnterDirective, OverlayScrollbarDirective, ClearableInputComponent, RequiredValidatorDirective, EmailValidatorDirective,
        SelectListComponent, DateTimePickerComponent, TextAreaAutosizeDirective, DragDropDirective, CameraComponent, LoaderComponent, SafePipe]
})
export class StartFormComponent extends AttachmentsComponent implements OnInit, OnDestroy
{
    // #region Private Members

    @ViewChild('formStartForm', { read: NgForm, static: false }) private _formStartForm: NgForm | undefined = undefined;

    // #endregion

    // #region Properties

    public override get isDirty(): boolean
    {
        return this.startFormModel.isDirty;
    }

    public get CorpIdType()
    {
        return CorpIdType;
    }

    public get startFormModel(): StartFormModel
    {
        return this._attachmentsModel as StartFormModel;
    }

    public get corpId(): number | null
    {
        return this._loginModel.userInfo.corpId;
    }

    // #endregion

    // #region Constructor

    constructor(_startFormModel: StartFormModel, _changeDetectorRef: ChangeDetectorRef, private _loginModel: LoginModel,
        private _routingHistoryService: RoutingHistoryService, _modalMessageService: ModalMessageService)
    {
        super(_startFormModel, _changeDetectorRef, _modalMessageService);
    }

    // #endregion

    // #region Event Handlers

    public ngOnInit(): void
    {
        if (this._routingHistoryService.isPopState && this.startFormModel.isInitialized)
        {
            this.setViewState(true);
            this._changeDetectorRef.markForCheck();
        }
        else
        {
            this.startFormModel.clear();
            this.getStartFormLists();
        }
    }

    public async onStartFormSubmit(isStartFormDataValid: boolean): Promise<void>
    {
        if (!isStartFormDataValid)
        {
            Utils.scrollToInvalidFormElement();
            return;
        }

        this._statusMessage = `<b>Sending shipment form.</b> Please wait...`;
        this.viewIsReady = false;
        this._changeDetectorRef.detectChanges();

        this.viewIsReady = false;

        this.clearComponentSubscription();

        this._componentSubscription = this.startFormModel.submitStartForm().subscribe(
            (response: IApiResponse) =>
            {
                this.viewIsReady = true;
                this._changeDetectorRef.detectChanges();

                if (response.isSuccess)
                {
                    this.clearStartForm();
                    this._modalMessageService.show({ title: Constants.APP_TITLE, message: 'New shipping form was sent successfully' });
                }
                else
                {
                    this._modalMessageService.show({ title: Constants.APP_TITLE, message: response.message, modalType: ModalType.Error });
                }
            });

        this._changeDetectorRef.detectChanges();
    }

    public onClearStartFormButtonClick(): void
    {
        this.clearStartForm();
    }

    // #endregion

    // #region Private Methods

    private clearStartForm(): void
    {
        this._formStartForm?.resetForm();
        this.startFormModel.clearStartForm();
    }

    private getStartFormLists(): void
    {
        this._componentSubscription = this.startFormModel.getStartFormLists().subscribe((response: IApiResponse) =>
        {
            this.setViewState(response.isSuccess, response.message);
            this._changeDetectorRef.detectChanges();
        });

        this._changeDetectorRef.detectChanges();
    }

    // #endregion
}
