import { inject } from '@angular/core';
import { Router, Routes } from '@angular/router';
import { ShipmentsMonitoringComponent } from './shipments-monitoring/shipments-monitoring/shipments-monitoring.component';
import { LoginComponent } from './user/login/login.component';
import { UserInsightPermission } from './user/login/model/login-model.class';
import { LoginModel } from './user/login/model/login.model';
import { PageNotFoundComponent } from './page-not-found/page-not-found.component';
import { ResetPasswordComponent } from './user/reset-password/reset-password.component';
import { ShipmentDetailsComponent } from './shipment-manager/shipment-details/shipment-details.component';
import { ShipmentManagerComponent } from './shipment-manager/shipment-manager/shipment-manager.component';
import { Constants, RouteType } from './utils/globals';
import { ChangePasswordComponent } from './user/change-password/change-password.component';
import { InventoryManagerComponent } from './inventory-manager/inventory-manager/inventory-manager.component';
import { InventoryDetailsComponent } from './inventory-manager/inventory-details/inventory-details.component';
import { DemurrageManagerComponent } from './demurrage-manager/demurrage-manager/demurrage-manager.component';
import { DemurrageMonitoringComponent } from './demurrage-monitoring/demurrage-monitoring.component';
import { EventsMonitoringComponent } from './events-monitoring/events-monitoring.component';
import { AnalyticsComponent } from './analytics/analytics.component';
import { DevicesComponent } from './devices/devices.component';
import { ForecastComponent } from './forecast/forecast.component';
import { StartFormComponent } from './start-form/start-form.component';
import { ShipmentMonitoringComponent } from './shipments-monitoring/shipment-monitoring/shipment-monitoring.component';
import { MiniControlCenterComponent } from './mini-control-center/mini-control-center/mini-control-center.component';

const canActivate = (userInsightPermission: UserInsightPermission | null = null, loginModel = inject(LoginModel)) =>
{
    if (loginModel.accessToken !== null && (userInsightPermission === null || loginModel.userInsightPermissions.includes(userInsightPermission)))
    {
        return true;
    }

    loginModel.logout();

    return false;
}

export const routes: Routes = [];

export function initializeRoutes(router: Router, loginModel: LoginModel): Function
{
    return () =>
    {
        let loginAnimationState: number = 0;
        let ShipmentsMonitoringAnimationState: number = 10;
        let EventsMonitoringAnimationState: number = 20;
        let demurrageMonitoringAnimationState: number = 30;
        let analyticsAnimationState: number = 40;
        let shipmentManagerAnimationState: number = 50;
        let demurrageManagerAnimationState: number = 60;
        let inventoryManagerAnimationState: number = 70;
        let devicesAnimationState: number = 80;
        let forecastAnimationState: number = 90;
        let startFormAnimationState: number = 100;
        let miniControlCenterAnimationState: number = 110;

        routes.push(
            {
                path: RouteType.Login,
                component: LoginComponent,
                data: { animationState: loginAnimationState++ }
            },
            {
                path: RouteType.ResetPassword,
                component: ResetPasswordComponent,
                data: { animationState: loginAnimationState++ }
            },
            {
                path: RouteType.ChangePassword,
                component: ChangePasswordComponent,
                canActivate: [() => canActivate()],
                data: { animationState: loginAnimationState++ }
            },
            {
                path: RouteType.ShipmentsMonitoring,
                component: ShipmentsMonitoringComponent,
                canActivate: [() => canActivate(UserInsightPermission.ShipmetsMonitoring)],
                data: { animationState: ShipmentsMonitoringAnimationState++ }
            },
            {
                path: RouteType.ShipmentManager,
                component: ShipmentManagerComponent,
                canActivate: [() => canActivate(loginModel.shipmentManagerPermission)],
                canDeactivate: [(component: ShipmentManagerComponent) => component.canDeactivate()],
                data: { animationState: shipmentManagerAnimationState }
            },
            {
                path: `${RouteType.ShipmentManager}/:shipmentKey`,
                component: ShipmentManagerComponent,
                canActivate: [() => canActivate(loginModel.shipmentManagerPermission)],
                canDeactivate: [(component: ShipmentManagerComponent) => component.canDeactivate()],
                data: { animationState: shipmentManagerAnimationState++ }
            },
            {
                path: RouteType.InventoryManager,
                component: InventoryManagerComponent,
                canActivate: [() => canActivate(UserInsightPermission.InventoryManager)],
                canDeactivate: [(component: InventoryManagerComponent) => component.canDeactivate()],
                data: { animationState: inventoryManagerAnimationState++ }
            });

        if (Constants.IS_MOBILE)
        {
            routes.push(
                {
                    path: `${RouteType.ShipmentsMonitoring}/:shipmentKey`,
                    component: ShipmentsMonitoringComponent,
                    canActivate: [() => canActivate(UserInsightPermission.ShipmetsMonitoring)],
                    data: { animationState: ShipmentsMonitoringAnimationState++ }
                },
                {
                    path: RouteType.ShipmentDetails,
                    component: ShipmentDetailsComponent,
                    canActivate: [() => canActivate(loginModel.shipmentManagerPermission)],
                    canDeactivate: [(component: ShipmentDetailsComponent) => component.canDeactivate()],
                    data: { animationState: shipmentManagerAnimationState++ }
                },
                {
                    path: RouteType.InventoryDetails,
                    component: InventoryDetailsComponent,
                    canActivate: [() => canActivate(UserInsightPermission.InventoryManager)],
                    canDeactivate: [(component: InventoryDetailsComponent) => component.canDeactivate()],
                    data: { animationState: inventoryManagerAnimationState++ }
                }
            );
        }
        else
        {
            routes.push(
                {
                    path: `${RouteType.ShipmentMonitoring}/:shipmentKey`,
                    component: ShipmentMonitoringComponent,
                    canActivate: [() => canActivate(UserInsightPermission.ShipmetsMonitoring)],
                    data: { animationState: ShipmentsMonitoringAnimationState }
                },
                {
                    path: `${RouteType.ShipmentMonitoringArchive}/:shipmentKey`,
                    component: ShipmentMonitoringComponent,
                    canActivate: [() => canActivate(UserInsightPermission.ShipmetsMonitoring)],
                    data: { animationState: ShipmentsMonitoringAnimationState++ }
                },
                {
                    path: RouteType.EventsMonitoring,
                    component: EventsMonitoringComponent,
                    canActivate: [() => canActivate(UserInsightPermission.ShipmetsMonitoring)],
                    data: { animationState: EventsMonitoringAnimationState++ }
                },
                {
                    path: RouteType.DemurrageMonitoring,
                    component: DemurrageMonitoringComponent,
                    canActivate: [() => canActivate(UserInsightPermission.DemurrageMonitoring)],
                    data: { animationState: demurrageMonitoringAnimationState++ }
                },
                {
                    path: RouteType.Analytics,
                    component: AnalyticsComponent,
                    canActivate: [() => canActivate(UserInsightPermission.Analytics)],
                    data: { animationState: analyticsAnimationState++ }
                },
                {
                    path: RouteType.DemurrageManager,
                    component: DemurrageManagerComponent,
                    canActivate: [() => canActivate(UserInsightPermission.DemurrageManager)],
                    canDeactivate: [(component: DemurrageManagerComponent) => component.canDeactivate()],
                    data: { animationState: demurrageManagerAnimationState++ }
                },
                {
                    path: RouteType.Devices,
                    component: DevicesComponent,
                    canActivate: [() => canActivate(UserInsightPermission.Devices)],
                    data: { animationState: devicesAnimationState++ }
                },
                {
                    path: RouteType.Forecast,
                    component: ForecastComponent,
                    canActivate: [() => canActivate(UserInsightPermission.AmazonForecast)],
                    data: { animationState: forecastAnimationState++ }
                },
                {
                    path: RouteType.StartForm,
                    component: StartFormComponent,
                    canActivate: [() => canActivate(UserInsightPermission.StartForm)],
                    canDeactivate: [(component: StartFormComponent) => component.canDeactivate()],
                    data: { animationState: startFormAnimationState++ }
                },
                {
                    path: RouteType.MiniControlCenter,
                    component: MiniControlCenterComponent,
                    canActivate: [() => canActivate(UserInsightPermission.MiniControlCenter)],
                    canDeactivate: [(component: MiniControlCenterComponent) => component.canDeactivate()],
                    data: { animationState: miniControlCenterAnimationState++ }
                }
            );
        }

        routes.push(
            {
                path: '',
                redirectTo: '/login',
                pathMatch: 'full'
            },
            {
                path: '**',
                component: PageNotFoundComponent,
                data: { animationState: 999999 }
            }
        );

        router.resetConfig(routes);

        loginModel.loadSavedData();
    }
}
