import { AnimationBuilder } from '@angular/animations';
import
{
    Component, Input, Output, EventEmitter, ViewChild, ChangeDetectionStrategy, ContentChild, TemplateRef, Directive, ElementRef, ChangeDetectorRef, HostListener
} from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, FormsModule } from '@angular/forms';
import { fadeInOutAnimation } from '../../animations/fade.animation';
import { DropdownBaseComponent, DropdownPosition, DropdownState } from '../../base/components/dropdown-base.component';
import { VirtualListInfo } from '../virtual-list/model/virtual-list-info';
import { KeyValue } from '../../utils/globals';
import { VirtualListComponent } from '../virtual-list/virtual-list.component';
import { OverlayScrollbarDirective } from '../../directives/overlay-scrollbar/overlay-scrollbar.directive';
import { NgFor, NgTemplateOutlet } from '@angular/common';
import { TooltipDirective } from '../../directives/tooltip.directive';

@Directive({
    selector: '[select-list-option-template]',
    standalone: true
})
export class SelectListOptionTemplateDirective
{
    constructor(public template: TemplateRef<any>) { }
}

@Directive({
    selector: '[select-list-label-template]',
    standalone: true
})
export class SelectListLabelTemplateDirective
{
    constructor(public template: TemplateRef<any>) { }
}

@Component({
    selector: 'select-list',
    templateUrl: './select-list.component.html',
    styleUrls: ['../../base/styles/picker.css', './select-list.component.css'],
    animations: [fadeInOutAnimation],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: SelectListComponent, multi: true }
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [TooltipDirective, NgTemplateOutlet, FormsModule, VirtualListComponent, OverlayScrollbarDirective, NgFor]
})

export class SelectListComponent extends DropdownBaseComponent implements ControlValueAccessor   
{
    // #region Private Memebrs

    private readonly SELECT_ALL_HIGHLIGHT_INDEX: number = -1;

    // #endregion

    // #region Private Memebrs

    private _innerValue: any = null;

    private _changed = new Array<(value: any) => void>();
    private _touched = new Array<() => void>();

    private _virtualListInfo: VirtualListInfo = new VirtualListInfo();
    private _items: any[] = [];
    private _selectedItems: any[] = [];
    private _selectedItemsString: string = '';
    private _selectedFullItemsString: string = '';
    private _multiple: boolean = false;
    private _highlightIndex: number | null = null;
    private _highlightItem: any | null = null;

    @ViewChild('inputFilter', { read: ElementRef, static: false }) private _inputFilterElementRef: ElementRef<HTMLInputElement> | undefined = undefined;
    @ViewChild('itemsList', { read: VirtualListComponent, static: false }) private _itemsList: VirtualListComponent | undefined = undefined;

    // #endregion

    // #region Properties

    @ContentChild(SelectListLabelTemplateDirective, { read: TemplateRef }) public labelTemplateRef!: TemplateRef<any>;
    @ContentChild(SelectListOptionTemplateDirective, { read: TemplateRef }) public optionTemplateRef!: TemplateRef<any>;
    public filter: string = '';

    public get value(): any
    {
        return this._innerValue;
    }

    public set value(value: any)
    {
        this._innerValue = value;
        this._changed.forEach(f => f(value));
        this._touched.forEach(f => f());
        this.change.emit(value);
    }

    public get selectedItems(): any[]
    {
        return this._selectedItems;
    }

    public get selectedItemsString(): string
    {
        return this._selectedItemsString === '' ? (this.emptyText !== null ? this.emptyText : '') : this._selectedItemsString;
    }

    public get selectedFullItemsString(): string
    {
        return this._selectedFullItemsString === '' ? (this.emptyText !== null ? this.emptyText : '') : this._selectedFullItemsString;
    }

    public get virtualListInfo(): VirtualListInfo
    {
        return this._virtualListInfo;
    }

    public get isSearchable(): boolean
    {
        return this.items.length > this.minSerchableItems;
    }

    public get highlightIndex(): number | null
    {
        return this._highlightIndex;
    }

    private set highlightIndex(index: number | null)
    {
        this._highlightIndex = index;

        if (this._highlightIndex === null || this._highlightIndex === this.SELECT_ALL_HIGHLIGHT_INDEX)
        {
            this._highlightItem = null;
        }
        else if (this._itemsList !== undefined)
        {
            this._highlightItem = this._itemsList.filteredSortedItems[this._highlightIndex];
        }
    }

    // #endregion

    // #region Inputs

    @Input() public disabled: boolean = false;
    @Input() public placeholder: string = "";
    @Input() public bindLabel: string | null = null;
    @Input() public bindValue: string | null = null;
    @Input() public emptyText: string | null = null;
    @Input() public maxSelectedItems: number | null = null;
    @Input() public minSelectedItems: number | null = null;
    @Input() public isRequired: boolean = false;
    @Input() public clearable: boolean = true;
    @Input() public maxSelectedItemString: number | null = 2;
    @Input() public maxViewportItems: number = 4;
    @Input() public minSerchableItems: number = 10;
    @Input() public isKeyValuePair: boolean = false;
    @Input() public get multiple(): boolean
    {
        return this._multiple;
    }

    public set multiple(value: boolean)
    {
        this._multiple = value;

        if (this._multiple && this._innerValue === null)
        {
            this._innerValue = [];
        }
    }

    @Input() public get items(): any[]
    {
        return this._items;
    }

    public set items(value: any[])
    {
        this._items = value;

        this.updateSelectedItems();
    }

    // #endregion

    // #region Events

    @Output() private change: EventEmitter<any> = new EventEmitter<any>();

    // #endregion

    // #region Constructors

    constructor(_elementRef: ElementRef<HTMLElement>, _changeDetectorRef: ChangeDetectorRef, _animationBuilder: AnimationBuilder)
    {
        super(_elementRef, _changeDetectorRef, _animationBuilder);
    }

    // #endregion

    // #region Event Handlers

    @HostListener('keydown', ['$event']) onKeyDown(event: KeyboardEvent): void
    {
        if (this._dropdownState === DropdownState.Open)
        {
            switch (event.key)
            {
                case KeyValue.ArrowUp:
                case KeyValue.ArrowDown:
                case KeyValue.PageUp:
                case KeyValue.PageDown:
                    {
                        if (this._itemsList !== undefined && this._dropdownState === DropdownState.Open)
                        {
                            event.stopImmediatePropagation();
                            event.preventDefault();

                            if (this._itemsList.filteredSortedItems.length === 0)
                            {
                                return;
                            }

                            if (event.key === KeyValue.ArrowDown || event.key === KeyValue.ArrowUp)
                            {
                                const isWithSelectAllOption: boolean = this.multiple && this._itemsList.filteredSortedItems.length > 1;

                                if (this.highlightIndex === null && (this._dropdownPosition === DropdownPosition.Bottom && event.key === KeyValue.ArrowDown ||
                                    this._dropdownPosition === DropdownPosition.Top && event.key === KeyValue.ArrowUp))
                                {
                                    const lastItemInViewport: number = Math.min(this._itemsList.filteredSortedItems.length, this.maxViewportItems) - 1;

                                    if (isWithSelectAllOption)
                                    {
                                        this.highlightIndex = this._dropdownPosition === DropdownPosition.Bottom ? this.SELECT_ALL_HIGHLIGHT_INDEX :
                                            lastItemInViewport;
                                    }
                                    else
                                    {
                                        const viewportIndexOffset: number = this._dropdownPosition === DropdownPosition.Bottom ? 0 : lastItemInViewport;
                                        this.highlightIndex = this.getViewportFirstItemIndex(viewportIndexOffset);
                                    }

                                    return;
                                }

                                if (this.highlightIndex !== null)
                                {
                                    if (event.key === KeyValue.ArrowDown)
                                    {
                                        this.highlightIndex = Math.min(this._itemsList.filteredSortedItems.length - 1, this.highlightIndex + 1);
                                    }
                                    else
                                    {
                                        this.highlightIndex = Math.max(isWithSelectAllOption ? this.SELECT_ALL_HIGHLIGHT_INDEX : 0, this.highlightIndex - 1);
                                    }

                                    if (this.isHighlightIndexInViewport())
                                    {
                                        return;
                                    }
                                }
                            }

                            this._itemsList.element.dispatchEvent(new KeyboardEvent('keydown', { key: event.key }));
                        }
                    }
                    break;

                case KeyValue.Enter:
                    {
                        event.stopImmediatePropagation();
                        event.preventDefault();

                        if (this._itemsList !== undefined && this.highlightIndex !== null)
                        {
                            if (this.multiple && this.highlightIndex === this.SELECT_ALL_HIGHLIGHT_INDEX)
                            {
                                this.onSelectAllClick();
                            }
                            else
                            {
                                this.onItemClick(this._itemsList.filteredSortedItems[this.highlightIndex]);
                            }
                        }
                    }
                    break;
            }
        }
    }

    public onSelectAllClick(): void
    {
        if (this._selectedItems.length === this._items.length)
        {
            this._selectedItems = [];
        }
        else
        {
            this._selectedItems = [...this._items];
        }

        this.updateValue();
        this.updateSelectedItemsString();

        this._changeDetectorRef.detectChanges();
    }

    public onItemClick(item: any): void
    {
        if (this.multiple)
        {
            let isExists: boolean = false;
            for (let i = 0; i < this._selectedItems.length; i++)
            {
                if (this.isItemValueEqualsSelectedValue(this.getItemValue(item), this.getItemValue(this._selectedItems[i])))
                {
                    this._selectedItems.splice(i, 1);
                    isExists = true;
                    break;
                }
            }

            if (!isExists)
            {
                this._selectedItems = [...this._selectedItems, item];
            }

            this.updateValue();
        }
        else
        {
            this._selectedItems = [item];
            this.value = this.getItemValue(item);

            this.close(true);
        }

        this.updateSelectedItemsString();
    }

    public onInputFilterInput(): void
    {
        this.open();
    }

    public onInputGroupMouseDown(event: MouseEvent): void
    {
        if (this._dropdownState === DropdownState.Open)
        {
            event.preventDefault();

            this.close(true);
        }
        else
        {
            this.open();
        }
    }

    public onListItemsChange(): void
    {
        if (this._itemsList !== undefined && this._highlightItem !== null)
        {
            if (this._itemsList.filteredSortedItems.includes(this._highlightItem))
            {
                this.highlightIndex = this._itemsList.filteredSortedItems.indexOf(this._highlightItem);
                if (!this.isHighlightIndexInViewport())
                {
                    this.highlightIndex = null;
                }
            }
            else
            {
                this.highlightIndex = null;
            }
        }

        if (this._dropdownState === DropdownState.Opening)
        {
            super.animateOpen();
        }
    }

    public onListScrollChange(): void
    {
        if (this.highlightIndex === null)
        {
            return;
        }

        const viewportFirstItemIndex: number | null = this.getViewportFirstItemIndex();
        if (viewportFirstItemIndex !== null &&
            (this.highlightIndex < viewportFirstItemIndex || this.highlightIndex > viewportFirstItemIndex + this.maxViewportItems - 1))
        {
            this.highlightIndex = null;
        }
    }

    public onClearValueMouseDown(event: MouseEvent): void
    {
        event.preventDefault();
        event.stopPropagation();

        this.value = this.multiple ? [] : null;
        this.updateSelectedItems();

        this._changeDetectorRef.detectChanges();
    }

    // #endregion

    // #region Public Methods

    public trackByItem(_index: number, item: any): any
    {
        return this.bindValue !== null ? item[this.bindValue] : item;
    }

    public touch(): void
    {
        this._touched.forEach(f => f());
    }

    public writeValue(value: any): void
    {
        this._innerValue = value;
        this.updateSelectedItems();
    }

    public registerOnChange(fn: (value: any) => void): void
    {
        this._changed.push(fn);
    }

    public registerOnTouched(fn: () => void): void
    {
        this._touched.push(fn);
    }

    public getItemLabel(item: any): any
    {
        return this.bindLabel !== null ? item[this.bindLabel] : (this.isKeyValuePair ? Object.entries(item)[0][1] : item);
    }

    public focus(): void
    {
        this.animateOpen();
    }

    // #endregion

    // #region Protected Methods

    protected override animateOpen(): void
    {
        if (this._inputFilterElementRef !== undefined)
        {
            this._inputFilterElementRef.nativeElement.focus();
        }
    }

    protected override close(retainFocus: boolean): void
    {
        this.filter = '';

        super.close(retainFocus);

        if (!retainFocus && this._inputFilterElementRef !== undefined)
        {
            this._inputFilterElementRef.nativeElement.blur();
        }

        this._changeDetectorRef.detectChanges();
    }

    protected override open(): void
    {
        this._virtualListInfo.scrollToItem = this._selectedItems.length > 0 ? this._selectedItems[0] : null;

        super.open();
    }

    protected override onDropdownPanelClosed(): void
    {
        this.highlightIndex = null;
        super.onDropdownPanelClosed();
    }

    // #endregion

    // #region Private Method

    private getViewportFirstItemIndex(indexOffset: number = 0): number | null
    {
        if (this._itemsList === undefined || this._virtualListInfo.itemHeight === null)
        {
            return null;
        }

        return Math.round(this._itemsList.element.scrollTop / this._virtualListInfo.itemHeight) + indexOffset;
    }

    private isHighlightIndexInViewport(): boolean
    {
        if (this.highlightIndex === null)
        {
            return false;
        }
        const viewportFirstItemIndex: number | null = this.getViewportFirstItemIndex();
        return viewportFirstItemIndex !== null && this.highlightIndex >= viewportFirstItemIndex &&
            this.highlightIndex <= viewportFirstItemIndex + this.maxViewportItems - 1;
    }

    private isItemValueEqualsSelectedValue(itemValue: any, selectedValue: any): boolean
    {
        if (typeof itemValue === 'string' && typeof selectedValue === 'string')
        {
            itemValue = itemValue.toLowerCase();
            selectedValue = selectedValue.toLowerCase();
        }

        return itemValue === selectedValue;
    }

    private updateValue(): void
    {
        this.value = this._selectedItems.length === 0 && !this.multiple ? null : this._selectedItems.map((selectedItem: any) => this.getItemValue(selectedItem));
    }

    private getItemValue(item: any): any
    {
        return this.bindValue !== null ? item[this.bindValue] : (this.isKeyValuePair ? Object.entries(item)[0][0] : item);
    }

    private updateSelectedItemsString(): void
    {
        if (this._selectedItems.length === 0)
        {
            this._selectedFullItemsString = '';
            this._selectedItemsString = '';
            return;
        }

        this._selectedFullItemsString = this._selectedItems.map((selectedItem: any) => this.getItemLabel(selectedItem)).join(' &#9679; ');

        this._selectedItemsString = this.maxSelectedItemString !== null && this._selectedItems.length >= this.maxSelectedItemString ?
            `${this._selectedItems.length} �����` : this._selectedFullItemsString;
    }

    private updateSelectedItems(): void
    {
        const selectedItems: any[] = [];
        const selectedValues: any[] = this._innerValue !== null ? (Array.isArray(this._innerValue) ? [...this._innerValue] : [this._innerValue]) : [null];
        if (this._items.length > 0 && selectedValues.length > 0)
        {
            for (let itemsIndex: number = 0; itemsIndex < this._items.length; itemsIndex++)
            {
                for (let selectedValuesIndex: number = 0; selectedValuesIndex < selectedValues.length; selectedValuesIndex++)
                {
                    if (this.isItemValueEqualsSelectedValue(this.getItemValue(this._items[itemsIndex]), selectedValues[selectedValuesIndex]))
                    {
                        selectedItems.push(this._items[itemsIndex]);
                        selectedValues.splice(selectedValuesIndex, 1);
                        break;
                    }
                }

                if (selectedValues.length === 0)
                {
                    break;
                }
            }
        }

        this._selectedItems = [...selectedItems];

        this.updateSelectedItemsString();
    }

    // #endregion
}