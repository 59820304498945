import { Injectable } from "@angular/core";
import { AppStorage } from "../utils/app-storage";
import { Utils } from "../utils/utils";
import { Observable, Subject } from "rxjs";
import ContguardPlugin, { IGetAppVersionResponse } from "../../plugins/contguard.plugin";
import { ShipmentMonitoringFavorite, ShipmentsMonitoringFilters } from "../shipments-monitoring/shipments-monitoring/model/shipments-monitoring-model.class";
import { DemurrageMonitoringFilters } from "../demurrage-monitoring/model/demurrage-monitoring-model.class";
import { EventsMonitoringFilters } from "../events-monitoring/model/events-monitoring-model.class";
import { IOptionalListColumn } from "../utils/globals";
import { compareVersions } from 'compare-versions';

export class ShipmentsMonitoringSettingsData
{
    public tableOptionalShipmentColumnsToSelect: IOptionalListColumn[] = [];
    public shipmentMonitoringFavorites: ShipmentMonitoringFavorite[] = [];
    public lastSearchLiveShipmentsMonitoringFilters: ShipmentsMonitoringFilters | null = null;
    public lastSearchCompletedShipmentsMonitoringFilters: ShipmentsMonitoringFilters | null = null;
}

export class MiniCallCenterSettingsData
{
    public tableOptionalPendingInProcessAlertsColumnsToSelect: IOptionalListColumn[] = [];
    public tableOptionalDoneAlertsColumnsToSelect: IOptionalListColumn[] = [];
}

export class DemurrageMonitoringSettingsData
{
    public lastSearchDemurrageMonitoringFilters: DemurrageMonitoringFilters | null = null;
}

export class EventsMonitoringSettingsData
{
    public lastSearchLiveEventsMonitoringFilters: EventsMonitoringFilters | null = null;
    public lastSearchCompletedEventsMonitoringFilters: EventsMonitoringFilters | null = null;
}

export enum AppColorThemeMode { System, Light, Dark }

export class AppSettings
{
    public isSidebarMenuMinimized: boolean = false;
    public isSatelliteView: boolean = true;
    public isUsingUTCTime: boolean = false;
    public isUsingTime24Hours: boolean = false;
    public localeId: string = 'en-US';
    public version: string = '';
    public appColorThemeMode: AppColorThemeMode = AppColorThemeMode.System;
    public shipmentsMonitoringSettingsData: ShipmentsMonitoringSettingsData = new ShipmentsMonitoringSettingsData();
    public miniCallCenterSettingsData: MiniCallCenterSettingsData = new MiniCallCenterSettingsData();
    public demurrageMonitoringSettingsData: DemurrageMonitoringSettingsData = new DemurrageMonitoringSettingsData();
    public eventsMonitoringSettingsData: EventsMonitoringSettingsData = new EventsMonitoringSettingsData();
}

@Injectable({ providedIn: 'root' })
export class AppSettingsService
{
    // #region Private Members

    private _appSettings: AppSettings = new AppSettings();
    private _appSettingsPending: AppSettings = {} as AppSettings;

    private _proxyAppSettings: AppSettings = new AppSettings();

    private _satelliteViewUpdatedSubject: Subject<boolean> = new Subject<boolean>();

    private _isDarkMode: boolean = false;

    // #endregion

    // #region Properties

    public get isDarkMode(): boolean
    {
        return this._isDarkMode;
    }

    public get appSettings(): AppSettings
    {
        return this._proxyAppSettings;
    }

    public get satelliteViewUpdatedObservable(): Observable<boolean>
    {
        return this._satelliteViewUpdatedSubject;
    }

    public get isUsingUTCTimePending(): boolean | null
    {
        if (this._appSettingsPending.isUsingUTCTime === undefined)
        {
            return null;
        }

        return this._appSettingsPending.isUsingUTCTime;
    }

    public set isUsingUTCTimePending(value: boolean | null)
    {
        if (value === null || this._appSettingsPending.isUsingUTCTime === value)
        {
            delete (this._appSettingsPending as any).isUsingUTCTime;
        }
        else
        {
            this._appSettingsPending.isUsingUTCTime = value;
        }

        this.saveSettingsPending();
    }

    public get isUsingTime24HoursPending(): boolean | null
    {
        if (this._appSettingsPending.isUsingTime24Hours === undefined)
        {
            return null;
        }

        return this._appSettingsPending.isUsingTime24Hours;
    }

    public set isUsingTime24HoursPending(value: boolean | null)
    {
        if (value === null || this._appSettingsPending.isUsingTime24Hours === value)
        {
            delete (this._appSettingsPending as any).isTime24Hours;
        }
        else
        {
            this._appSettingsPending.isUsingTime24Hours = value;
        }

        this.saveSettingsPending();
    }

    // #endregion

    // #region Constructors

    constructor()
    {
        const appSettingsJson: string | null = AppStorage.getStorageItem(AppStorage.APP_SETTINGS_KEY_NAME);
        Utils.copyObjectBySourceProperties(appSettingsJson !== null ? JSON.parse(appSettingsJson) as AppSettings : new AppSettings(), this._appSettings);

        const appSettingsPendingJson: string | null = AppStorage.getStorageItem(AppStorage.APP_SETTINGS_PENDING_KEY_NAME);
        if (appSettingsPendingJson !== null)
        {
            Utils.copyObjectBySourceProperties(JSON.parse(appSettingsPendingJson) as AppSettings, this._appSettings);
            this.saveSettings();

            AppStorage.setStorageItem(AppStorage.APP_SETTINGS_PENDING_KEY_NAME, null);
        }

        if (!Utils.isNullOrEmpty(this._appSettings.version) && compareVersions(this._appSettings.version, '1.0.74') < 0)
        {
            this._appSettings.miniCallCenterSettingsData = new MiniCallCenterSettingsData();
            this.saveSettings();
        }

        this.validateSavedData(new AppSettings(), this._appSettings);

        this.createAppSettingsProxies();

        this.updateUtilsDateTimeFormat();

        this.updateAppColorThemeMode();

        ContguardPlugin.getAppVersion().then((response: IGetAppVersionResponse) =>
        {
            if (this._appSettings.version !== response.appVersion)
            {
                this._appSettings.version = response.appVersion;
                this.saveSettings();
            }
        });
    }

    // #endregion

    // #region Private Methods

    private updateAppColorThemeMode(): void
    {
        const htmlElement: HTMLElement = document.getElementsByTagName('html')[0];
        const appColorThemeModeNames: string[] = Object.values(AppColorThemeMode).filter((value: any) => typeof (value) === 'string') as string[];
        for (const appColorThemeModeName of appColorThemeModeNames)
        {
            htmlElement.classList.remove(appColorThemeModeName.toLowerCase());
        }

        this._isDarkMode = this._appSettings.appColorThemeMode === AppColorThemeMode.System ? window.matchMedia('(prefers-color-scheme: dark)').matches :
            this._appSettings.appColorThemeMode === AppColorThemeMode.Dark;

        htmlElement.classList.add(AppColorThemeMode[this._isDarkMode ? AppColorThemeMode.Dark : AppColorThemeMode.Light].toLowerCase());

        ContguardPlugin.setThemeMode({ isDarkMode: this._isDarkMode });
    }

    private createAppSettingsProxies(): void
    {
        this._appSettings.shipmentsMonitoringSettingsData =
            Utils.createProxyOnSetNotifier(this._appSettings.shipmentsMonitoringSettingsData, () => this.saveSettings());
        this._appSettings.miniCallCenterSettingsData =
            Utils.createProxyOnSetNotifier(this._appSettings.miniCallCenterSettingsData, () => this.saveSettings());
        this._appSettings.demurrageMonitoringSettingsData =
            Utils.createProxyOnSetNotifier(this._appSettings.demurrageMonitoringSettingsData, () => this.saveSettings());
        this._appSettings.eventsMonitoringSettingsData =
            Utils.createProxyOnSetNotifier(this._appSettings.eventsMonitoringSettingsData, () => this.saveSettings());

        this._proxyAppSettings = Utils.createProxyOnSetNotifier(this._appSettings, (target: any, propertyKey: string, value: any) =>
        {
            if (propertyKey === Utils.getPropertyNameof<AppSettings>(target, appSettings => appSettings.isSatelliteView))
            {
                this._satelliteViewUpdatedSubject.next(value);
            }

            if (propertyKey === Utils.getPropertyNameof<AppSettings>(target, appSettings => appSettings.isUsingUTCTime) ||
                propertyKey === Utils.getPropertyNameof<AppSettings>(target, appSettings => appSettings.isUsingTime24Hours))
            {
                this.updateUtilsDateTimeFormat();
            }

            if (propertyKey === Utils.getPropertyNameof<AppSettings>(target, appSettings => appSettings.appColorThemeMode))
            {
                this.updateAppColorThemeMode();
            }

            this.saveSettings();
        });
    }

    private validateSavedData(data: any, savedData: any): void
    {
        for (const propertyName of Object.getOwnPropertyNames(data))
        {
            const value: any = data[propertyName];
            const savedValue: any = savedData[propertyName];

            if (savedValue === undefined)
            {
                savedData[propertyName] = value;
            }
            else if (Object.prototype.toString.call(value) === '[object Object]')
            {
                this.validateSavedData(value, savedValue);
            }
        }
    }

    private saveSettingsPending(): void
    {
        AppStorage.setStorageItem(AppStorage.APP_SETTINGS_PENDING_KEY_NAME, Object.keys.length > 0 ? JSON.stringify(this._appSettingsPending) : null);
    }

    private saveSettings(): void
    {
        AppStorage.setStorageItem(AppStorage.APP_SETTINGS_KEY_NAME, JSON.stringify(this._appSettings));
    }

    private updateUtilsDateTimeFormat(): void
    {
        Utils.initializeDateTimeFormat(this._appSettings.localeId, this._appSettings.isUsingUTCTime, this._appSettings.isUsingTime24Hours);
    }

    // #endregion
}