import { Injectable } from "@angular/core";
import { cloneDeep, isEqual } from "lodash";
import { Constants, IApiResponse, IIdValue } from "../../../utils/globals";
import { Observable, Observer } from "rxjs";
import { Utils } from "../../../utils/utils";
import { HttpHelper } from "../../../utils/http-helper";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import
{
    ShipmentMonitoringFavorite, ShipmentsMonitoringInfo, ShipmentsMonitoringDataLists, ShipmentsMonitoringFilters
} from "./shipments-monitoring-model.class";
import { LoginModel } from "../../../user/login/model/login.model";
import { ManagerItemStatus, MonitoringArrivalStatusType } from "../../../base/models/manager-base-model.class";
import { AppSettingsService, ShipmentsMonitoringSettingsData } from "../../../services/app-settings.service";
import { MonitoringItemsBaseModel } from "../../../base/models/monitoring-items-base.model";
import { MonitoringUtils } from "../../../utils/monitoring-utils";
import { DisplayShipmentMonitoring, ShipmentMonitoring } from "../../shipment-monitoring/model/shipment-monitoring-model.class";
import { MonitoringDetailedEventType } from "../../../base/models/monitoring-items-base-model.class";

@Injectable({ providedIn: 'root' })
export class ShipmentsMonitoringModel extends MonitoringItemsBaseModel<DisplayShipmentMonitoring, ShipmentsMonitoringFilters, ShipmentsMonitoringDataLists,
    ShipmentsMonitoringInfo>
{
    // #region Properties

    public shipmentFriendlyName: string | null = null;

    public override get lastSearchMonitoringFilters(): ShipmentsMonitoringFilters | null
    {
        return this._showCompletedMonitoringItems ?
            this._appSettingsService.appSettings.shipmentsMonitoringSettingsData.lastSearchCompletedShipmentsMonitoringFilters :
            this._appSettingsService.appSettings.shipmentsMonitoringSettingsData.lastSearchLiveShipmentsMonitoringFilters;
    }

    public override set lastSearchMonitoringFilters(value: ShipmentsMonitoringFilters | null)
    {
        if (this._showCompletedMonitoringItems)
        {
            this._appSettingsService.appSettings.shipmentsMonitoringSettingsData.lastSearchCompletedShipmentsMonitoringFilters = value;
        }
        else
        {
            this._appSettingsService.appSettings.shipmentsMonitoringSettingsData.lastSearchLiveShipmentsMonitoringFilters = value;
        }
    }

    // #endregion

    // #region Contructors

    constructor(private _httpClient: HttpClient, private _appSettingsService: AppSettingsService, _loginModel: LoginModel)
    {
        super(_loginModel, ShipmentsMonitoringFilters, ShipmentsMonitoringDataLists, ShipmentsMonitoringInfo);
    }

    // #endregion

    // #region Public Methods

    public override getMonitoringItemKey(monitoringItem: DisplayShipmentMonitoring): number | null
    {
        return monitoringItem === undefined ? null : monitoringItem.shipmentKey;
    }

    public override getMonitoringItemStatusId(monitoringItem: DisplayShipmentMonitoring): number | null
    {
        return monitoringItem.statusId;
    }

    public override getMonitoringItemLocation(monitoringItem: DisplayShipmentMonitoring): any
    {
        return { lat: monitoringItem.currentLat, lng: monitoringItem.currentLon };
    }

    public updateEventFilter(shipmentMonitoringEventType: MonitoringDetailedEventType): void
    {
        this._editMonitoringFilters.selectedShipmentMonitoringEvents = [shipmentMonitoringEventType];
    }

    public updateArrivalStatusFilter(shipmentMonitoringArrivalStatusType: MonitoringArrivalStatusType): void
    {
        this._editMonitoringFilters.selectedShipmentMonitoringArrivalStatuses = shipmentMonitoringArrivalStatusType === MonitoringArrivalStatusType.Early ?
            [MonitoringArrivalStatusType.OnTime, MonitoringArrivalStatusType.Early] : [shipmentMonitoringArrivalStatusType];
    }

    public isShipmentMonitoringInFavorites(shipmentKey: number | null): boolean
    {
        if (shipmentKey === null)
        {
            return false;
        }

        return this.getShipmentMonitoringFavoritesIndex(shipmentKey) !== null;
    }

    public isShipmentMonitoringFavoriteFriendlyNameExists(name: string | null): boolean
    {
        if (name === null)
        {
            return false;
        }
        return this._appSettingsService.appSettings.shipmentsMonitoringSettingsData.shipmentMonitoringFavorites.find(
            (shipmentMonitoringFavorite: ShipmentMonitoringFavorite) => shipmentMonitoringFavorite.name === name) !== undefined;
    }

    public addShipmentMonitoringToFavorite(shipmentKey: number, name: string): boolean
    {
        if (this.isShipmentMonitoringFavoriteFriendlyNameExists(name))
        {
            return false;
        }

        const shipmentMonitoring: DisplayShipmentMonitoring | null = this.findMonitoringItem(shipmentKey);
        if (shipmentMonitoring !== null)
        {
            shipmentMonitoring.isInFovarites = true;
        }

        const shipmentMonitoringFavorite: ShipmentMonitoringFavorite = { shipmentKey: shipmentKey, name: name };

        this._appSettingsService.appSettings.shipmentsMonitoringSettingsData.shipmentMonitoringFavorites = [shipmentMonitoringFavorite,
            ...this._appSettingsService.appSettings.shipmentsMonitoringSettingsData.shipmentMonitoringFavorites];

        this._monitoringDataLists.shipmentMonitoringFavorites =
            [shipmentMonitoringFavorite, ...this._monitoringDataLists.shipmentMonitoringFavorites];

        return true;
    }

    public removeShipmentMonitoringFromFavorites(shipmentKey: number): boolean
    {
        const shipmentMonitoring: DisplayShipmentMonitoring | null = this.findMonitoringItem(shipmentKey);
        if (shipmentMonitoring !== null)
        {
            shipmentMonitoring.isInFovarites = false;
        }

        const shipmentMonitoringFavoritesIndex: number | null = this.getShipmentMonitoringFavoritesIndex(shipmentKey);
        if (shipmentMonitoringFavoritesIndex === null)
        {
            return false;
        }

        this._appSettingsService.appSettings.shipmentsMonitoringSettingsData.shipmentMonitoringFavorites.splice(shipmentMonitoringFavoritesIndex, 1);
        this._appSettingsService.appSettings.shipmentsMonitoringSettingsData.shipmentMonitoringFavorites =
            [...this._appSettingsService.appSettings.shipmentsMonitoringSettingsData.shipmentMonitoringFavorites];

        for (let i: number = 0; i < this._monitoringDataLists.shipmentMonitoringFavorites.length; i++)
        {
            if (this._monitoringDataLists.shipmentMonitoringFavorites[i].shipmentKey === shipmentKey)
            {
                this._monitoringDataLists.shipmentMonitoringFavorites.splice(i, 1);
                this._monitoringDataLists.shipmentMonitoringFavorites = [...this._monitoringDataLists.shipmentMonitoringFavorites];
                break;
            }
        }

        for (let i: number = 0; i < this._editMonitoringFilters.selectedShipmentMonitoringFavorites.length; i++)
        {
            if (this._editMonitoringFilters.selectedShipmentMonitoringFavorites[i] === shipmentKey)
            {
                this._editMonitoringFilters.selectedShipmentMonitoringFavorites.splice(i, 1);
                this._editMonitoringFilters.selectedShipmentMonitoringFavorites = [...this._editMonitoringFilters.selectedShipmentMonitoringFavorites];
                break;
            }
        }

        this.updateLastSearchMonitoringFilters(this.getSettingsLastSearchFiltersPropertyName(this._showCompletedMonitoringItems));

        return true;
    }

    public override getMonitoringItems(): Observable<IApiResponse>
    {
        this._isBusy = true;
        this._isBackgroundFetchBusy = !this._showCompletedMonitoringItems && !Constants.IS_MOBILE;

        const completedMonitoringItems: ShipmentMonitoring[] = [];

        return new Observable((observer: Observer<IApiResponse>) =>
        {
            this._httpClient.get<ShipmentMonitoring[]>(`shipments-monitoring/${Constants.IS_MOBILE || this._isBackgroundFetchBusy ? 'live-shipments' : 'shipments'}`,
                { headers: HttpHelper.GetHttpFormUrlencodedHeaders() }).subscribe(
                    {
                        next: (monitoringItems: ShipmentMonitoring[]) =>
                        {
                            this._monitoringItems = [];
                            this._completedMonitoringItems = [];

                            const shipments: ShipmentMonitoring[] = [...monitoringItems, ...completedMonitoringItems];

                            this._isBusy = false;
                            this.updateShipmentsResponse(observer, shipments, !this._isBackgroundFetchBusy);
                        },
                        error: (error: HttpErrorResponse) =>
                        {
                            this._isBusy = false;
                            console.error(error);

                            observer.next({ isSuccess: false, message: Constants.DATA_SERVICE_ERROR_STRING });
                            observer.complete();
                        }

                    });

            if (this._isBackgroundFetchBusy)
            {
                this._httpClient.get<ShipmentMonitoring[]>('shipments-monitoring/completed-shipments',
                    { headers: HttpHelper.GetHttpFormUrlencodedHeaders() }).subscribe(
                        {
                            next: (monitoringItems: ShipmentMonitoring[]) =>
                            {
                                this._isBackgroundFetchBusy = false;

                                if (this._isBusy)
                                {
                                    completedMonitoringItems.push(...monitoringItems);
                                }
                                else
                                {
                                    this.updateShipmentsResponse(observer, monitoringItems, true);
                                }
                            },
                            error: (error: HttpErrorResponse) =>
                            {
                                this._isBackgroundFetchBusy = false;
                                console.error(error);

                                observer.next({ isSuccess: false, message: Constants.DATA_SERVICE_ERROR_STRING });
                                observer.complete();
                            }

                        });
            }
        });
    }

    public override filterMonitoringItem(displayShipmentMonitoring: DisplayShipmentMonitoring): boolean
    {
        this.clearMonitoringItemsFilters();

        this._editMonitoringFilters.selectedShipmentMonitoringKeys = [displayShipmentMonitoring.shipmentKey!];

        const showCompletedMonitoringItems = !this._monitoringItems.includes(displayShipmentMonitoring);
        if (showCompletedMonitoringItems !== this.showCompletedMonitoringItems)
        {
            this.showCompletedMonitoringItems = showCompletedMonitoringItems;
            return true;
        }

        return false;
    }

    // #endregion

    // #region Protected Methods

    protected override filterShipmentsDataLists(): void
    {
        if (this._editMonitoringFilters.selectedShipmentMonitoringKeys.length > 0)
        {
            this._filteredMonitoringDataLists.shipmentMonitoringKeys = [...this._monitoringDataLists.shipmentMonitoringKeys];
        }
        else if (this._editMonitoringFilters.selectedShipmentMonitoringCargosUnits.length > 0)
        {
            this._filteredMonitoringDataLists.shipmentMonitoringCargosUnits = [...this._monitoringDataLists.shipmentMonitoringCargosUnits];
        }
        else if (this._editMonitoringFilters.selectedShipmentMonitoringTrailersNumbers.length > 0)
        {
            this._filteredMonitoringDataLists.shipmentMonitoringTrailersNumbers = [...this._monitoringDataLists.shipmentMonitoringTrailersNumbers];
        }
        else if (this._editMonitoringFilters.selectedShipmentMonitoringDevices.length > 0)
        {
            this._filteredMonitoringDataLists.shipmentMonitoringDevices = [...this._monitoringDataLists.shipmentMonitoringDevices];
        }
        else if (this._editMonitoringFilters.selectedShipmentMonitoringBookingReferences.length > 0)
        {
            this._filteredMonitoringDataLists.shipmentMonitoringBookingReferences = [...this._monitoringDataLists.shipmentMonitoringBookingReferences];
        }
        else if (this._editMonitoringFilters.selectedShipmentMonitoringOriginsRoutes.length > 0)
        {
            this._filteredMonitoringDataLists.shipmentMonitoringOriginsRoutes = [...this._monitoringDataLists.shipmentMonitoringOriginsRoutes];
        }
        else if (this._editMonitoringFilters.selectedShipmentMonitoringDestinationsRoutes.length > 0)
        {
            this._filteredMonitoringDataLists.shipmentMonitoringDestinationsRoutes = [...this._monitoringDataLists.shipmentMonitoringDestinationsRoutes];
        }
        else if (this._editMonitoringFilters.selectedShipmentMonitoringArrivalStatuses.length > 0)
        {
            this._filteredMonitoringDataLists.monitoringItemsArrivalStatuses = [...this._monitoringDataLists.monitoringItemsArrivalStatuses];
        }
        else if (this._editMonitoringFilters.selectedShipmentMonitoringEvents.length > 0)
        {
            this._filteredMonitoringDataLists.monitoringItemsEvents = [...this._monitoringDataLists.monitoringItemsEvents];
        }
    }

    protected override cloneEditMonitoringFiltersForFilteringDataLists(): ShipmentsMonitoringFilters
    {
        const editMonitoringFilters: ShipmentsMonitoringFilters = cloneDeep(this._editMonitoringFilters);
        editMonitoringFilters.selectedShipmentMonitoringFavorites = [];

        return editMonitoringFilters;
    }

    protected override updateMonitoringItemsByFilters(monitoringFilters: ShipmentsMonitoringFilters | null = null): DisplayShipmentMonitoring[]
    {
        if (monitoringFilters === null)
        {
            monitoringFilters = this._monitoringFilters;
        }

        if (isEqual(monitoringFilters, new ShipmentsMonitoringFilters()))
        {
            Utils.copyObjectByTargetProperties(this._showCompletedMonitoringItems ? this._completedMonitoringItemsSourceInfo :
                this._monitoringItemsSourceInfo, this._monitoringItemsInfo);

            return [...this._currentMonitoringItems];
        }
        else
        {
            this._monitoringItemsInfo = new ShipmentsMonitoringInfo();

            const selectedShipmentMonitoringKeys: number[] = [...monitoringFilters!.selectedShipmentMonitoringKeys];
            if (monitoringFilters!.selectedShipmentMonitoringFavorites !== null && monitoringFilters!.selectedShipmentMonitoringFavorites.length > 0)
            {
                selectedShipmentMonitoringKeys.push(...monitoringFilters!.selectedShipmentMonitoringFavorites);
            }

            let filteredMonitoringItems: DisplayShipmentMonitoring[] = this._currentMonitoringItems.filter((shipmentMonitoring: DisplayShipmentMonitoring) =>
            {
                if (!this.isMonitoringItemFilteredByPropertyValue(shipmentMonitoring.shipmentKey, selectedShipmentMonitoringKeys) ||
                    !this.isMonitoringItemFilteredByPropertyValue(shipmentMonitoring.deviceId, monitoringFilters!.selectedShipmentMonitoringDevices) ||
                    !this.isMonitoringItemFilteredByPropertyValue(shipmentMonitoring.containerId, monitoringFilters!.selectedShipmentMonitoringCargosUnits) ||
                    !this.isMonitoringItemFilteredByPropertyValue(shipmentMonitoring.bookingReference, monitoringFilters!.selectedShipmentMonitoringBookingReferences) ||
                    !this.isMonitoringItemFilteredByPropertyValue(shipmentMonitoring.originName, monitoringFilters!.selectedShipmentMonitoringOriginsRoutes) ||
                    !this.isMonitoringItemFilteredByPropertyValue(shipmentMonitoring.destinationName, monitoringFilters!.selectedShipmentMonitoringDestinationsRoutes))
                {
                    return false;
                }

                if (!this.isShipmentMonitoringFilteredByEvents(shipmentMonitoring))
                {
                    return false;
                }

                this.updateShipmentsMonitoringInfoArrivalStatus(this._monitoringItemsInfo, shipmentMonitoring);

                if (!this.isMonitoringItemFilteredByPropertyValue(shipmentMonitoring.arrivalStatusId, monitoringFilters!.selectedShipmentMonitoringArrivalStatuses))
                {
                    return false;
                }

                this.updateShipmentsMonitoringInfoEvents(this._monitoringItemsInfo, shipmentMonitoring);

                return true;
            });

            this.updateMonitoringInfoGeneralData(this._monitoringItemsInfo, filteredMonitoringItems);

            return filteredMonitoringItems;
        }
    }

    protected override updateMonitoringDataLists(monitoringItems: DisplayShipmentMonitoring[]): ShipmentsMonitoringDataLists
    {
        const monitoringDataLists: ShipmentsMonitoringDataLists = new ShipmentsMonitoringDataLists();
        const shipmentMonitoring: DisplayShipmentMonitoring = new DisplayShipmentMonitoring();

        monitoringDataLists.shipmentMonitoringKeys = this.getMonitoringItemsPropertyList(monitoringItems,
            Utils.getPropertyNameof<DisplayShipmentMonitoring>(shipmentMonitoring, shipmentMonitoring => shipmentMonitoring.shipmentKey));

        monitoringDataLists.shipmentMonitoringCargosUnits = this.getMonitoringItemsPropertyList(monitoringItems,
            Utils.getPropertyNameof<DisplayShipmentMonitoring>(shipmentMonitoring, shipmentMonitoring => shipmentMonitoring.containerId));

        monitoringDataLists.shipmentMonitoringTrailersNumbers = this.getMonitoringItemsPropertyList(monitoringItems,
            Utils.getPropertyNameof<DisplayShipmentMonitoring>(shipmentMonitoring, shipmentMonitoring => shipmentMonitoring.trailerNumber));

        monitoringDataLists.shipmentMonitoringDevices = this.getMonitoringItemsPropertyList(monitoringItems,
            Utils.getPropertyNameof<DisplayShipmentMonitoring>(shipmentMonitoring, shipmentMonitoring => shipmentMonitoring.deviceId));

        monitoringDataLists.shipmentMonitoringBookingReferences = this.getMonitoringItemsPropertyList(monitoringItems,
            Utils.getPropertyNameof<DisplayShipmentMonitoring>(shipmentMonitoring, shipmentMonitoring => shipmentMonitoring.bookingReference));

        monitoringDataLists.shipmentMonitoringOriginsRoutes = this.getMonitoringItemsPropertyList(monitoringItems,
            Utils.getPropertyNameof<DisplayShipmentMonitoring>(shipmentMonitoring, shipmentMonitoring => shipmentMonitoring.originName));

        monitoringDataLists.shipmentMonitoringDestinationsRoutes = this.getMonitoringItemsPropertyList(monitoringItems,
            Utils.getPropertyNameof<DisplayShipmentMonitoring>(shipmentMonitoring, shipmentMonitoring => shipmentMonitoring.destinationName));

        const monitoringItemsArrivalStatuses: MonitoringArrivalStatusType[] = this.getMonitoringItemsPropertyList(monitoringItems,
            Utils.getPropertyNameof<DisplayShipmentMonitoring>(shipmentMonitoring, shipmentMonitoring => shipmentMonitoring.arrivalStatusId));

        monitoringDataLists.monitoringItemsArrivalStatuses = [];
        for (const shipmentMonitoringArrivalStatus of monitoringItemsArrivalStatuses)
        {
            const arrivalStatus: IIdValue =
            {
                id: shipmentMonitoringArrivalStatus,
                value: MonitoringUtils.SHIPMENT_ARRIVAL_STATUSES_DISPLAY_PROPERTIES[shipmentMonitoringArrivalStatus].tooltip
            }

            if (!monitoringDataLists.monitoringItemsArrivalStatuses.includes(arrivalStatus))
            {
                monitoringDataLists.monitoringItemsArrivalStatuses.push(arrivalStatus);
            }
        }

        const uniqueSet = new Set();
        monitoringDataLists.monitoringItemsEvents = [];

        for (const monitoringItem of monitoringItems)
        {
            this.addNewEventToShipmentMonitoringEventsList(
                [
                    { id: MonitoringDetailedEventType.HighTemperature, value: monitoringItem.temperatureEvents },
                    { id: MonitoringDetailedEventType.HighHumidity, value: monitoringItem.humidityEvents },
                    { id: MonitoringDetailedEventType.StrongImpact, value: monitoringItem.impactEvents },
                    { id: MonitoringDetailedEventType.Security, value: monitoringItem.securityEvents }
                ],
                monitoringDataLists.monitoringItemsEvents, uniqueSet);
        }

        monitoringDataLists.shipmentMonitoringFavorites =
            this._appSettingsService.appSettings.shipmentsMonitoringSettingsData.shipmentMonitoringFavorites.filter(
                (shipmentMonitoringFavorite: ShipmentMonitoringFavorite) => monitoringItems.find((displayShipmentMonitoring: DisplayShipmentMonitoring) =>
                    (this._showCompletedMonitoringItems && displayShipmentMonitoring.statusId === ManagerItemStatus.Completed ||
                        !this._showCompletedMonitoringItems) && displayShipmentMonitoring.shipmentKey === shipmentMonitoringFavorite.shipmentKey) !== undefined);

        this.sortMonitoringDataLists(monitoringDataLists);

        return monitoringDataLists;
    }

    protected override updateMonitoringInfoGeneralData(monitoringItemsInfo: ShipmentsMonitoringInfo, monitoringItems: DisplayShipmentMonitoring[]): void
    {
        monitoringItemsInfo.count = monitoringItems.length;
        monitoringItemsInfo.routesCount = MonitoringUtils.getShipmentsRoutsCount(monitoringItems);
    }

    protected override updateMonitoringItemsInfo(monitoringItems: DisplayShipmentMonitoring[]): ShipmentsMonitoringInfo
    {
        const monitoringItemsInfo: ShipmentsMonitoringInfo = new ShipmentsMonitoringInfo();

        for (const shipmentMonitoring of monitoringItems)
        {
            this.updateShipmentsMonitoringInfoArrivalStatus(monitoringItemsInfo, shipmentMonitoring);
            this.updateShipmentsMonitoringInfoEvents(monitoringItemsInfo, shipmentMonitoring);
        }


        this.updateMonitoringInfoGeneralData(monitoringItemsInfo, monitoringItems);

        return monitoringItemsInfo;
    }

    // #endregion

    // #region Private Methods

    private getSettingsLastSearchFiltersPropertyName(isCompletedMonitoringItems: boolean): string
    {
        return Utils.getPropertyNameof<ShipmentsMonitoringSettingsData>(
            this._appSettingsService.appSettings.shipmentsMonitoringSettingsData, shipmentsMonitoringSettingsData =>
        (isCompletedMonitoringItems ? shipmentsMonitoringSettingsData.lastSearchCompletedShipmentsMonitoringFilters :
            shipmentsMonitoringSettingsData.lastSearchLiveShipmentsMonitoringFilters));
    }

    private updateShipmentsFavorites(monitoringItems: ShipmentMonitoring[], isComplete: boolean): void
    {
        if (this._appSettingsService.appSettings.shipmentsMonitoringSettingsData.shipmentMonitoringFavorites.length === 0)
        {
            return;
        }

        let isUpdated: boolean = false;
        for (let i: number = this._appSettingsService.appSettings.shipmentsMonitoringSettingsData.shipmentMonitoringFavorites.length - 1; i >= 0; i--)
        {
            for (const monitoringItem of monitoringItems)
            {
                if ((isComplete || monitoringItem.statusId === ManagerItemStatus.Live) &&
                    monitoringItem.shipmentKey === this._appSettingsService.appSettings.shipmentsMonitoringSettingsData.shipmentMonitoringFavorites[i].shipmentKey)
                {
                    isUpdated = true;
                    break;
                }
            }
        }

        if (isUpdated)
        {
            this._appSettingsService.appSettings.shipmentsMonitoringSettingsData.shipmentMonitoringFavorites =
                [...this._appSettingsService.appSettings.shipmentsMonitoringSettingsData.shipmentMonitoringFavorites];
        }
    }

    private updateShipmentsResponse(observer: Observer<IApiResponse>, monitoringItems: ShipmentMonitoring[], isComplete: boolean): void
    {
        for (const shipmentMonitoring of monitoringItems)
        {
            const displayShipmentMonitoring: DisplayShipmentMonitoring = new DisplayShipmentMonitoring();
            Utils.copyObjectByTargetProperties(shipmentMonitoring, displayShipmentMonitoring);

            if (displayShipmentMonitoring.statusId === ManagerItemStatus.Live)
            {
                this._monitoringItems.push(displayShipmentMonitoring);
            }
            else
            {
                this._completedMonitoringItems.push(displayShipmentMonitoring);
            }

            displayShipmentMonitoring.isInFovarites = this.isShipmentMonitoringInFavorites(displayShipmentMonitoring.shipmentKey);

            MonitoringUtils.updateDisplayShipmentMonitoringProperties(displayShipmentMonitoring, this._loginModel.userInfo.isDemo);
        }

        this._allMonitoringItems = [...this._monitoringItems, ...this._completedMonitoringItems];
        this._currentMonitoringItems = [...(this._showCompletedMonitoringItems ? this._completedMonitoringItems : this._monitoringItems)];

        this.updateShipmentsFavorites(this._monitoringItems, isComplete);

        this._monitoringItemsSourceInfo = this.updateMonitoringItemsInfo(this._monitoringItems);
        this._completedMonitoringItemsSourceInfo = this.updateMonitoringItemsInfo(this._completedMonitoringItems);

        this._monitoringDataLists = this.updateMonitoringDataLists(this._currentMonitoringItems);
        this._filteredMonitoringItems = this.updateMonitoringItemsByFilters();

        this.updateLastSearchMonitoringFilters(this.getSettingsLastSearchFiltersPropertyName(false));

        if (isComplete)
        {
            this.updateLastSearchMonitoringFilters(this.getSettingsLastSearchFiltersPropertyName(true));
        }

        this.updateMonitoringDataListsByFilters();

        observer.next({ isSuccess: true, isComplete: true });

        if (!this._isBackgroundFetchBusy && !this._isBusy)
        {
            observer.complete();
        }
    }

    private isShipmentMonitoringFilteredByEvents(shipmentMonitoring: DisplayShipmentMonitoring): boolean
    {
        if (this._monitoringFilters.selectedShipmentMonitoringEvents === null || this._monitoringFilters.selectedShipmentMonitoringEvents.length === 0)
        {
            return true;
        }

        for (const selectedShipmentMonitoringEvent of this._monitoringFilters.selectedShipmentMonitoringEvents)
        {
            switch (selectedShipmentMonitoringEvent)
            {
                case MonitoringDetailedEventType.None:
                    {
                        if (shipmentMonitoring.humidityEvents === null || shipmentMonitoring.humidityEvents === undefined || shipmentMonitoring.humidityEvents === 0)
                        {
                            return true;
                        }
                    }
                    break;

                case MonitoringDetailedEventType.HighHumidity:
                    {
                        if (shipmentMonitoring.humidityEvents !== null && shipmentMonitoring.humidityEvents !== undefined && shipmentMonitoring.humidityEvents !== 0)
                        {
                            return true;
                        }
                    }
                    break;

                case MonitoringDetailedEventType.StrongImpact:
                    {
                        if (shipmentMonitoring.impactEvents !== null && shipmentMonitoring.impactEvents !== undefined && shipmentMonitoring.impactEvents !== 0)
                        {
                            return true;
                        }
                    }
                    break;

                case MonitoringDetailedEventType.Security:
                    {
                        if (shipmentMonitoring.securityEvents !== null && shipmentMonitoring.securityEvents !== undefined && shipmentMonitoring.securityEvents !== 0)
                        {
                            return true;
                        }
                    }
                    break;

                case MonitoringDetailedEventType.HighTemperature:
                    {
                        if (shipmentMonitoring.temperatureEvents !== null && shipmentMonitoring.temperatureEvents !== undefined && shipmentMonitoring.temperatureEvents !== 0)
                        {
                            return true;
                        }
                    }
                    break;
            }
        }

        return false;
    }

    private updateLastSearchMonitoringFilters(lastSearchFiltersPropertyName: string): void
    {
        if ((this._appSettingsService.appSettings.shipmentsMonitoringSettingsData as any)[lastSearchFiltersPropertyName] === null)
        {
            return;
        }

        const lastSearchShipmentsMonitoringFilters: ShipmentsMonitoringFilters =
            cloneDeep((this._appSettingsService.appSettings.shipmentsMonitoringSettingsData as any)[lastSearchFiltersPropertyName]);

        lastSearchShipmentsMonitoringFilters.selectedShipmentMonitoringKeys =
            this.updateLastSeachMonitoringItemFilteredByPropertyValue(this._monitoringDataLists.shipmentMonitoringKeys,
                lastSearchShipmentsMonitoringFilters.selectedShipmentMonitoringKeys);

        lastSearchShipmentsMonitoringFilters.selectedShipmentMonitoringDevices =
            this.updateLastSeachMonitoringItemFilteredByPropertyValue(this._monitoringDataLists.shipmentMonitoringDevices,
                lastSearchShipmentsMonitoringFilters.selectedShipmentMonitoringDevices);

        lastSearchShipmentsMonitoringFilters.selectedShipmentMonitoringCargosUnits =
            this.updateLastSeachMonitoringItemFilteredByPropertyValue(this._monitoringDataLists.shipmentMonitoringCargosUnits,
                lastSearchShipmentsMonitoringFilters.selectedShipmentMonitoringCargosUnits);

        lastSearchShipmentsMonitoringFilters.selectedShipmentMonitoringTrailersNumbers =
            this.updateLastSeachMonitoringItemFilteredByPropertyValue(this._monitoringDataLists.shipmentMonitoringTrailersNumbers,
                lastSearchShipmentsMonitoringFilters.selectedShipmentMonitoringTrailersNumbers);

        lastSearchShipmentsMonitoringFilters.selectedShipmentMonitoringBookingReferences =
            this.updateLastSeachMonitoringItemFilteredByPropertyValue(this._monitoringDataLists.shipmentMonitoringBookingReferences,
                lastSearchShipmentsMonitoringFilters.selectedShipmentMonitoringBookingReferences);

        lastSearchShipmentsMonitoringFilters.selectedShipmentMonitoringOriginsRoutes =
            this.updateLastSeachMonitoringItemFilteredByPropertyValue(this._monitoringDataLists.shipmentMonitoringOriginsRoutes,
                lastSearchShipmentsMonitoringFilters.selectedShipmentMonitoringOriginsRoutes);

        lastSearchShipmentsMonitoringFilters.selectedShipmentMonitoringDestinationsRoutes =
            this.updateLastSeachMonitoringItemFilteredByPropertyValue(this._monitoringDataLists.shipmentMonitoringDestinationsRoutes,
                lastSearchShipmentsMonitoringFilters.selectedShipmentMonitoringDestinationsRoutes);

        lastSearchShipmentsMonitoringFilters.selectedShipmentMonitoringEvents =
            this.updateLastSeachMonitoringItemFilteredByPropertyValue(this._monitoringDataLists.monitoringItemsEvents.map((event: IIdValue) => event.id),
                lastSearchShipmentsMonitoringFilters.selectedShipmentMonitoringEvents);

        if (lastSearchShipmentsMonitoringFilters.selectedShipmentMonitoringFavorites !== null)
        {
            lastSearchShipmentsMonitoringFilters.selectedShipmentMonitoringFavorites =
                lastSearchShipmentsMonitoringFilters.selectedShipmentMonitoringFavorites.filter((shipmentKey: number) =>
                {
                    return this._appSettingsService.appSettings.shipmentsMonitoringSettingsData.shipmentMonitoringFavorites.find(
                        (shipmentMonitoringFavorite: ShipmentMonitoringFavorite) => shipmentMonitoringFavorite.shipmentKey === shipmentKey) !== undefined;
                });
        }

        if (!isEqual(lastSearchShipmentsMonitoringFilters,
            (this._appSettingsService.appSettings.shipmentsMonitoringSettingsData as any)[lastSearchFiltersPropertyName]))
        {
            (this._appSettingsService.appSettings.shipmentsMonitoringSettingsData as any)[lastSearchFiltersPropertyName] = lastSearchShipmentsMonitoringFilters;
        }

        this.updateEmptyLastSearchFilters();
    }

    private updateShipmentsMonitoringInfoArrivalStatus(monitoringItemsInfo: ShipmentsMonitoringInfo, shipmentMonitoring: DisplayShipmentMonitoring): void
    {
        if (shipmentMonitoring.arrivalStatusId === MonitoringArrivalStatusType.Late)
        {
            monitoringItemsInfo.lateCount++;
        }
        else if (shipmentMonitoring.arrivalStatusId === MonitoringArrivalStatusType.Early || shipmentMonitoring.arrivalStatusId === MonitoringArrivalStatusType.OnTime)
        {
            monitoringItemsInfo.earlyOrOnTimeCount++;
        }
        else
        {
            monitoringItemsInfo.unknownCount++;
        }
    }

    private updateShipmentsMonitoringInfoEvents(monitoringItemsInfo: ShipmentsMonitoringInfo, shipmentMonitoring: DisplayShipmentMonitoring): void
    {
        if (shipmentMonitoring.securityEvents !== null && shipmentMonitoring.securityEvents > 0)
        {
            monitoringItemsInfo.securityEventsCount++;
        }

        if (shipmentMonitoring.impactEvents !== null && shipmentMonitoring.impactEvents > 0)
        {
            monitoringItemsInfo.qualityEventsCount++;
            monitoringItemsInfo.impactEventsCount++;
        }

        if (shipmentMonitoring.humidityEvents !== null && shipmentMonitoring.humidityEvents > 0)
        {
            monitoringItemsInfo.qualityEventsCount++;
            monitoringItemsInfo.humidityEventsCount++;
        }

        if (shipmentMonitoring.temperatureEvents !== null && shipmentMonitoring.temperatureEvents > 0)
        {
            monitoringItemsInfo.qualityEventsCount++;
            monitoringItemsInfo.tempratureEventsCount++;
        }
    }

    private addNewEventToShipmentMonitoringEventsList(eventsTypesOccurrences: IIdValue[], monitoringItemsEvents: IIdValue[], uniqueSet: any): void
    {
        for (const eventTypeOccurrences of eventsTypesOccurrences)
        {
            if (eventTypeOccurrences.id === null || eventTypeOccurrences.id === 0 || uniqueSet.has(eventTypeOccurrences.id))
            {
                continue;
            }

            uniqueSet.add(eventTypeOccurrences.id);

            monitoringItemsEvents.push(
                {
                    id: eventTypeOccurrences.id,
                    value: MonitoringUtils.SHIPMENT_EVENTS_DISPLAY_PROPERTIES[eventTypeOccurrences.id as number].tooltip
                });
        }

        if (monitoringItemsEvents.length === 0 || monitoringItemsEvents[0].id !== MonitoringDetailedEventType.None)
        {
            monitoringItemsEvents.unshift(
                {
                    id: MonitoringDetailedEventType.None,
                    value: MonitoringUtils.SHIPMENT_EVENTS_DISPLAY_PROPERTIES[MonitoringDetailedEventType.None].tooltip
                });
        }
    }

    private getShipmentMonitoringFavoritesIndex(shipmentKey: number): number | null
    {
        for (let i: number = 0; i < this._appSettingsService.appSettings.shipmentsMonitoringSettingsData.shipmentMonitoringFavorites.length; i++)
        {
            if (this._appSettingsService.appSettings.shipmentsMonitoringSettingsData.shipmentMonitoringFavorites[i].shipmentKey === shipmentKey)
            {
                return i;
            }
        }

        return null;
    }


    // #endregion
}