import { ManagerItemStatus } from "../../../base/models/manager-base-model.class";

export class Perimeter
{
    public perimeterId: string | null = null;
    public perimeterName: string | null = null;
    public perimeterType: string | null = null;
    public perimeterCode: string | null = null;
    public portCode: string | null = null;
    public longitude: number | null = null;
    public latitude: number | null = null;
    public radius: number | null = null;
    public countryName: string | null = null;
    public countryCode: string | null = null;
}

export class DemurrageContractPort
{
    public contractId: number | null = null;
    public perimeterId: string | null = null;
    public polFreeDays: number | null = null;
    public podFreeDays: number | null = null;
    public modified: Date | null = null;
}

export class DemurrageContract
{
    public contractId: number | null = null;
    public fromDate: Date | null = null;
    public toDate: Date | null = null;
    public carrierId: number | null = null;
    public corpId: number | null = null;
    public statusId: ManagerItemStatus = ManagerItemStatus.Live;
    public modified: Date | null = null;
    public demurrageContractPorts: DemurrageContractPort[] = [];
}

export class DemurrageContractPortItem
{
    public polFreeDays: number | null = null;
    public podFreeDays: number | null = null;
    public portIds: string[] = [];
    public perimeters: Perimeter[] = [];
}

export class DisplayDemurrageContract extends DemurrageContract
{
    public demurrageContractPortsItems: DemurrageContractPortItem[] = [];
    public fromDateFormatted: string | null = null;
    public toDateFormatted: string | null = null;
    public portsCount: number = 0;
    public carrierName: string | null = null;
}