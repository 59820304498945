import { cloneDeep, isEqual } from "lodash";
import { IApiResponseData, TypeConstructor } from "../../utils/globals";
import { BaseModel } from "../classes/base-model";
import { Utils } from "../../utils/utils";
import { Observable, Observer } from "rxjs";

export class ManagerItemBaseModel<ManagerItemType> extends BaseModel
{
    // #region Protected

    protected _managerItem: ManagerItemType;
    protected _sourceManagerItem: ManagerItemType;

    // #endregion

    // #region Properties

    public get isDirty(): boolean
    {
        const targetManagerItem: ManagerItemType = Utils.clearObjectEmptyStrings(cloneDeep(this._managerItem));
        return !isEqual(this._sourceManagerItem, targetManagerItem);
    }

    public get managerItem(): ManagerItemType
    {
        return this._managerItem;
    }

    // #endregion

    // #region Contructors

    constructor(private _managerItemTypeConstructor: TypeConstructor<ManagerItemType>)
    {
        super();

        this._managerItem = new this._managerItemTypeConstructor();
        this._sourceManagerItem = new this._managerItemTypeConstructor();
    }

    // #endregion

    // #region Public Methods

    public initialize(): void
    {
        this.clear();

        this._managerItem = new this._managerItemTypeConstructor();
        this._sourceManagerItem = new this._managerItemTypeConstructor();
    }

    public setValidManagerItem(): void
    {
        this._sourceManagerItem = Utils.clearObjectEmptyStrings(cloneDeep(this._managerItem));
    }

    public submitManagerItem(_isDelete: boolean): Observable<IApiResponseData<ManagerItemType>>
    {
        return new Observable((observer: Observer<IApiResponseData<ManagerItemType>>) => 
        {
            observer.complete();
        });
    }

    // #endregion

    // #region protected Methods

    protected initializeManagerItem(managerItem: ManagerItemType): void
    {
        this._managerItem = cloneDeep(managerItem);
        this.setValidManagerItem();
    }

    // #endregion
}