import { CGIMarkerIconFactory } from "../../../controls/google-map/utils/cgi-marker-icon-factory";
import { ShipmentMonitoringPlanSegmentStatusType } from "../model/shipment-monitoring-model.class";
import { Constants } from "../../../utils/globals";
import { MonitoringUtils, DisplayProperties } from "../../../utils/monitoring-utils";
import { Utils } from "../../../utils/utils";

export class CGIMarkerItemMonitoringIconFactory extends CGIMarkerIconFactory
{
    // #region Private Constants

    private readonly CGIMARKER_EVENT_CLASSNAME: string = 'cgimarker-event';
    private readonly CGIMARKER_EVENT_CLUSTER_CLASSNAME: string = 'cgimarker-event-cluster';
    private readonly PLAN_MARKER_COLOR_PROPERTY: string = '--color-plan-marker';
    private readonly PLAN_MARKER_DESTINATION_COLOR_PROPERTY: string = '--color-plan-end';
    private readonly PLAN_MARKER_CURRENT_COLOR_PROPERTY: string = '--color-plan-current';
    private readonly MIN_FORMAT_CLUSTER_COUNT: number = 10000;

    // #endregion

    // #region Private Members

    private _planSegmentColor: string;
    private _planSegmentCurrentColor: string;
    private _planSegmentDestinationColor: string;

    // #endregion

    // #region Constructors

    constructor()
    {
        super();

        this._planSegmentColor = this._bodyCssStyleDeclaration.getPropertyValue(this.PLAN_MARKER_COLOR_PROPERTY);
        this._planSegmentCurrentColor = this._bodyCssStyleDeclaration.getPropertyValue(this.PLAN_MARKER_CURRENT_COLOR_PROPERTY);
        this._planSegmentDestinationColor = this._bodyCssStyleDeclaration.getPropertyValue(this.PLAN_MARKER_DESTINATION_COLOR_PROPERTY);
    }

    // #endregion

    // #region Public Methods

    public createCGIMarkerEventElement(backgroundColor: string, iconClassName: string): Element
    {
        return this.createCGIMarkerElement(backgroundColor, this.CGIMARKER_EVENT_CLASSNAME, iconClassName);
    }

    public createCGIMarkerPlanSegmentElement(iconClassName: string, shipmentMonitoringPlanSegmentStatusType: ShipmentMonitoringPlanSegmentStatusType): Element
    {
        const backgroundColor: string = shipmentMonitoringPlanSegmentStatusType === ShipmentMonitoringPlanSegmentStatusType.Current ? this._planSegmentCurrentColor :
            (shipmentMonitoringPlanSegmentStatusType === ShipmentMonitoringPlanSegmentStatusType.Destination ? this._planSegmentDestinationColor : this._planSegmentColor);

        return this.createCGIMarkerElement(backgroundColor, this.CGIMARKER_CLASSNAME, iconClassName);
    }

    public createCGIClusterMarkerEventsElement(eventsOccurrences: number[], clusterCount: number): Element
    {
        const markerElement: HTMLElement = document.createElement("div");
        markerElement.className = `${this.CGIMARKER_EVENT_CLUSTER_CLASSNAME} ${this.CGIMARKER_CIRCLE_CLASSNAME} ${Constants.ACTIVE_CLASSNAME}`;
        markerElement.style.background = `conic-gradient(${this.getCGIClusterMarkerGradient(eventsOccurrences, clusterCount, this.getShipmentEventsColors())})`;

        markerElement.innerHTML = `<div class="${this.CGIMARKER_DATA_CLASSNAME}"><span>${clusterCount >= this.MIN_FORMAT_CLUSTER_COUNT ?
            Utils.getFormattedNumber(clusterCount, true) : clusterCount}</span></div>`;

        return markerElement;
    }

    public createCGIClusterPinMarkerEventsElement(eventsOccurrences: number[], clusterCount: number): Element
    {
        const markerElement: HTMLElement = document.createElement("div");
        markerElement.className = `${this.CGIMARKER_EVENT_CLUSTER_CLASSNAME} ${CGIMarkerIconFactory.CGIMARKER_PIN_CLASSNAME} ${Constants.ACTIVE_CLASSNAME}`;
        const background: string = `conic-gradient(${this.getCGIClusterMarkerGradient(eventsOccurrences, clusterCount, this.getShipmentEventsColors())})`;

        markerElement.innerHTML = this.createClusterPinHtmlContent(background, clusterCount);

        return markerElement;
    }

    // #endregion

    // #region Private Methods

    private getShipmentEventsColors(): string[]
    {
        return MonitoringUtils.SHIPMENT_EVENTS_DISPLAY_PROPERTIES.map((styleProperties: DisplayProperties) => styleProperties.color);
    }

    // #endregion
}