import { CGIClusterBaseRenderer } from "../../controls/google-map/utils/cgi-cluster-base-renderer";
import { CGICluster } from "../../controls/google-map/utils/cgi-cluster";
import { ICGIMarker } from "../../base/classes/cgi-marker";
import { CGIMarkerDemurrageIconFactory } from "./cgi-marker-demurrage-icon-factory";
import { DemurrageContractDeviationType } from "../model/demurrage-monitoring-model.class";
import { DisplayShipmentMonitoring } from "../../shipments-monitoring/shipment-monitoring/model/shipment-monitoring-model.class";

export class CGIDemurrageClusterRenderer extends CGIClusterBaseRenderer
{
    // #region Constants

    private readonly DEMMURAGE_CONTRACT_DEVIATION_TYPES_COUNT: number =
        (Object.values(DemurrageContractDeviationType).filter((value: any) => typeof (value) === 'number') as number[]).length;

    // #endregion

    // #region Private Members

    private _cgiMarkerDemurrageIconFactory: CGIMarkerDemurrageIconFactory = new CGIMarkerDemurrageIconFactory();

    // #endregion

    // #region Renderer Interface

    public override render(cluster: CGICluster, map: google.maps.Map): google.maps.marker.AdvancedMarkerElement
    {
        if (cluster.markers !== undefined && cluster.markers.length === 1)
        {
            return cluster.marker!;
        }

        const demurrageDeviations: number[] = Array(this.DEMMURAGE_CONTRACT_DEVIATION_TYPES_COUNT).fill(0);

        if (cluster.markers !== undefined && cluster.markers.length > 0)
        {
            for (const marker of cluster.markers)
            {
                const displayShipmentMonitoring: DisplayShipmentMonitoring = marker instanceof google.maps.marker.AdvancedMarkerElement ?
                    (marker as ICGIMarker<DisplayShipmentMonitoring>).data : marker as DisplayShipmentMonitoring;

                demurrageDeviations[displayShipmentMonitoring.demurrageDeviationId] = demurrageDeviations[displayShipmentMonitoring.demurrageDeviationId] + 1;
            }
        }

        return this.createClusterMarkerElement(map, cluster, demurrageDeviations);
    }

    // #endregion

    // #region Protected Methods

    protected override createClusterMarkerContentElement(cluster: CGICluster, demurrageDeviations: number[],
        isClusterBoundsAroundSameLocation: boolean): Element
    {
        return isClusterBoundsAroundSameLocation ?
            this._cgiMarkerDemurrageIconFactory.createCGIClusterPinMarkerDemmurageContractDeviationElement(demurrageDeviations,
                cluster.markers.length) : 
            this._cgiMarkerDemurrageIconFactory.createCGIClusterDemmurageContractDeviationElement(demurrageDeviations,
                cluster.markers.length);
    }

    // #endregion
}