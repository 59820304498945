import { Pipe, PipeTransform } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Pipe({
    name: 'safe',
    standalone: true
})
export class SafePipe implements PipeTransform
{
	constructor(private _domSanitizer: DomSanitizer)
	{
	}

	public transform(value: string): SafeResourceUrl
	{
		return this._domSanitizer.bypassSecurityTrustResourceUrl(value);
	}
}