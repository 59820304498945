import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { UserInsightPermission } from '../user/login/model/login-model.class';
import { PowerBiService } from '../services/powerbi.service';
import { fadeOutAnimation } from '../animations/fade.animation';
import { PowerBIBaseComponent } from '../base/components/powerbi-base.component';
import { LoaderComponent } from '../controls/loader/loader.component';
import { PowerBIEmbedModule } from 'powerbi-client-angular';

@Component({
    selector: 'forecast',
    templateUrl: '../base/htmls/powerbi-base.component.html',
    styleUrl: '../base/styles/powerbi-base.component.css',
    animations: [fadeOutAnimation],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [PowerBIEmbedModule, LoaderComponent]
})
export class ForecastComponent extends PowerBIBaseComponent
{
    // #region Constructors

    constructor(_powerBiService: PowerBiService, _changeDetectorRef: ChangeDetectorRef)
    {
        super(_powerBiService, _changeDetectorRef);
        this._reportName = UserInsightPermission.AmazonForecast;
    }

    // #endregion
}
