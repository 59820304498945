<!DOCTYPE html>

<div class="page-container">
    @if (viewIsInitialized) {
    <div class="page-content" @fadeInAnimation>
        <div class="page-inner-content">
            <div class="page-padding">
                <div class="form-group">
                    <clearable-input [(ngModel)]="managerModel.searchFilter" label="Filter Alerts" icon="icon-search"
                                     class="search-control" [disabled]="!managerVirtualList || managerVirtualList.viewPortItems.length === 0 &&
                                     (managerModel.searchFilter === null || managerModel.searchFilter.length === 0)">
                    </clearable-input>
                </div>
                <div class="manager-controls-container">
                    <div class="manager-type-container">
                        <label class="radio-container">
                            <input type="radio" name="alert-type"
                                   [checked]="managerModel.alertsStatus === managerModel.AlertStatusType.Pending || managerModel.alertsStatus === managerModel.AlertStatusType.InProcess"
                                   (click)="onDisplayStatusClick($event, managerModel.AlertStatusType.Pending)" />
                            <span class="radio-checkmark ripple-effect ripple-fill ripple-inverse-color"></span>
                            <span class="radio-text"><i class="icon-check"></i>Pending / In Process</span>
                        </label>
                        <label class="radio-container busy-support" [class.busy]="managerModel.isBackgroundFetchBusy">
                            <input type="radio" name="alert-type" [checked]="managerModel.alertsStatus === managerModel.AlertStatusType.Done"
                                   (click)="onDisplayStatusClick($event, managerModel.AlertStatusType.Done)" />
                            <span class="radio-checkmark ripple-effect ripple-fill ripple-inverse-color"></span>
                            <span class="radio-text"><i class="icon-check"></i>Done</span>
                            @if (managerModel.isBackgroundFetchBusy) {
                            <i class="icon-spinner" @fadeInOutAnimation></i>
                            }
                        </label>
                    </div>
                    <div class="dates-filter">
                        <datetime-picker label="From" name="filterFromDate" #filterFromDate="ngModel" [(ngModel)]="managerModel.filterFromDate"
                                         [maxDate]="managerModel.filterToDate" [autoSelect]="false" [withTime]="true">
                        </datetime-picker>
                        <datetime-picker label="To" name="filterToDate" #filterToDate="ngModel" [(ngModel)]="managerModel.filterToDate"
                                         [minDate]="managerModel.filterFromDate" [autoSelect]="false" [withTime]="true">
                        </datetime-picker>
                    </div>
                    <div class="manager-behaviour-controls">
                        <label class="checkbox-container pending ripple-effect ripple-center ripple-inverse-color" tooltipPopup="Pending"
                               [class.disabled]="managerModel.alertsStatus === managerModel.AlertStatusType.Done">
                            <input name="pending" type="checkbox" [(ngModel)]="managerModel.isFilterPending">
                            <span class="container-background"></span>
                            <i class="icon-search-pending"></i>
                        </label>
                        <label class="checkbox-container anomalies ripple-effect ripple-center ripple-inverse-color" tooltipPopup="Anomalies"
                               [class.disabled]="managerModel.alertsStatus !== managerModel.AlertStatusType.Done">
                            <input name="anomalies" type="checkbox" [(ngModel)]="managerModel.isFilterAnomalies">
                            <span class="container-background"></span>
                            <span class="anomalies-checkmark"></span>
                        </label>
                        <button type="button" class="image-button excel-button ripple-effect ripple-center"
                                (click)="onExportToExcelButtonClick()" tooltipPopup="Export to Excel">
                            <i class="icon-excel"></i>
                        </button>
                        <button type="button" class="image-button refresh-button ripple-effect ripple-center" tooltipPopup="Refresh"
                                (click)="onRefreshButtonClick($event)">
                            <i class="icon-refresh"></i>
                        </button>
                    </div>
                </div>
                <div class="manager-elements-count">
                    <span>{{ (!managerVirtualList ? 0 : managerVirtualList.filteredSortedItems.length) }} Alerts </span>
                    @if (managerModel.selectedAlerts.length > 0) {
                    <span>({{ managerModel.selectedAlerts.length }} Selected)</span>
                    }
                </div>
            </div>
            <div class="virtual-list-container">
                <div class="manager-table-container page-padding">
                    <div class="manager-table-view-container">
                        @if (managerModel.alertsStatus === managerModel.AlertStatusType.Pending ||
                        managerModel.alertsStatus === managerModel.AlertStatusType.InProcess) {
                        <ng-container [ngTemplateOutlet]="managerTableTemplate" [ngTemplateOutletContext]="{ id: 'alerts-table-pending-in-process' }">
                        </ng-container>
                        }
                        @else if (managerModel.alertsStatus === managerModel.AlertStatusType.Done) {
                        <ng-container [ngTemplateOutlet]="managerTableTemplate" [ngTemplateOutletContext]="{ id: 'alerts-table-done' }">
                        </ng-container>
                        }
                    </div>
                    <div class="dropdown columns-select" dropdown [(isExpanded)]="showTableColumnSelection" [autoClose]="false"
                         [horizontalPosition]="DropdownPosition.Right" [attachToBodyTemplateRef]="dropdownTableColumnsSelectTemplate">
                        <button type="button" class="image-button ripple-effect ripple-center ripple-inverse-color" [class.active]="showTableColumnSelection">
                            <i class="icon-more"></i>
                        </button>
                    </div>
                </div>
                @if (!managerVirtualList || managerVirtualList.viewPortItems.length === 0) {
                <div class="list-empty-placeholder" @fadeInOutAnimation>
                    <i class="icon-bell-regular"></i>
                </div>
                }
            </div>
        </div>
        <div class="page-inner-content">
            <div class="manager-details-pane">
                @if (!managerModel.selectedManagerItem) {
                <div class="manager-details-placeholder">
                    <div class="title">No alert selected</div>
                    <div>Please select an alert from the list<br />to view or edit one</div>
                    <i class="icon-edit"></i>
                </div>
                }
                @if (managerModel.selectedManagerItem) {
                <div class="manager-element-container" @shortFadeInOutAnimation>
                    <div class="manager-element-title">
                        <span>Alert {{ managerModel.selectedManagerItem.alertHistoryId }}</span>
                    </div>
                    <control-center-alert class="manager-element" #managerItem (submitingManagerItem)="onSubmitingManagerItem($event)"></control-center-alert>
                </div>
                }
            </div>
        </div>
    </div>
    }

    @if (!viewIsReady || managerModel.isBusy) {
    <loader [@.disabled]="!viewIsInitialized" @fadeInOutAnimation
            [loadingMessage]="statusMessage" [showSpinner]="showSpinner || managerModel.isBusy"
            [isBusyLoader]="viewIsReady && managerModel.isBusy" [isBusyLoaderOverlay]="true">
    </loader>
    }
</div>

<!-- managerTableTemplate -->
<ng-template #managerTableTemplate let-id="id">
    <virtual-list @fadeInOutAnimation #managerItemsList [items]="managerModel.filteredSortedManagerItems" [id]="id"
                  class="manager-table" [filter]="managerModel.searchFilter" tabindex="-1" [isSortable]="true"
                  [class.vertical-scrollable]="managerItemsList.element.scrollHeight > managerItemsList.element.clientHeight"
                  [filterProperties]="managerSearchProperties" [virtualListInfo]="managerTableListInfo" [isAutoColumnsWidths]="true"
                  (itemsChange)="onManagerItemsListChange($event)" (sorted)="onManagerItemsListSorted()"
                  [class.active]="managerItemsList.viewPortItems.length > 0">
        <div #header class="list-header">
            @for (column of managerTableColumns; track column.columnType) {
            <div [class]="'col-list ' + (column.classes ?? '')" [attr.property-name]="column.propertyName"
                 [attr.sort-property-name]="column.sortPropertyName" [attr.fixed-width]="column.isFixedWidth ? 'true' : null">
                @switch (column.columnType) {
                @case (MiniControlCenterAlertColumnType.Select) {
                <fieldset [disabled]="managerModel.groupedAlerts.length === 0">
                    <label class="checkbox-container no-text ripple-effect ripple-inverse-color" (click)="onSelectAllAlertsClick($event)">
                        <input type="checkbox" name="isSelectAll"
                               [checked]="managerModel.groupedAlerts.length > 0 && managerModel.groupedAlerts.length === managerModel.selectedAlerts.length">
                        <span class="checkmark"></span>
                    </label>
                </fieldset>
                }
                @case (MiniControlCenterAlertColumnType.Status) {
                <i class="icon-inbox"></i>
                }
                @case (MiniControlCenterAlertColumnType.Copy) {
                }
                @case (MiniControlCenterAlertColumnType.Track) {
                }
                @default {
                <span [innerHTML]="column.text"></span>
                }
                }
            </div>
            }
        </div>
        @for (alert of managerItemsList.viewPortItems; track alert.id) {
        <div #body class="list-row ripple-effect ripple-fill ripple-inverse-color"
             (click)="onManagerItemClick(alert)"
             [ngClass]="{ 'odd-row': managerItemsList.filteredSortedItems.indexOf(alert) % 2,
                          'selected': alert === managerModel.selectedManagerItem, 'pending': alert.statusId === managerModel.AlertStatusType.Pending }">
            @for (column of managerTableColumns; track column.columnType) {
            <div [class]="'col-list ' + (column.classes ?? '')" [attr.property-name]="column.propertyName">
                @switch (column.columnType) {
                @case (MiniControlCenterAlertColumnType.Select) {
                <fieldset [disabled]="managerModel.groupedAlerts.length > 0 && !managerModel.groupedAlerts.includes(alert)"
                          (click)="onSelectAlertClick($event, alert)">
                    <label class="checkbox-container no-text ripple-effect ripple-inverse-color">
                        <input type="checkbox" name="isSelected" [(ngModel)]="alert.isSelected">
                        <span class="checkmark"></span>
                    </label>
                </fieldset>
                }
                @case (MiniControlCenterAlertColumnType.Status) {
                <i [class]="alert.statusId === managerModel.AlertStatusType.Pending ? 'icon-envelope' : 'icon-envelope-open'"></i>
                }
                @case (MiniControlCenterAlertColumnType.IsReportAnomaly) {
                @if (alert.isReportAnomaly) {
                <div class="item-details-anomaly"></div>
                }
                @else {
                <default-column [column]="column" [item]="alert"></default-column>
                }
                }
                @case (MiniControlCenterAlertColumnType.Copy) {
                <button type="button" class="image-button ripple-effect ripple-center ripple-inverse-color" tooltipPopup="Copy"
                        [disabled]="alert.eventSummary === null" (click)="onCopyEventSummaryButtonClick($event, alert.eventSummary)">
                    <i class="icon-copy"></i>
                </button>
                }
                @case (MiniControlCenterAlertColumnType.Track) {
                <button type="button" class="image-button ripple-effect ripple-center ripple-inverse-color"
                        (click)="onShipmentMonitoringButtonClick($event, alert.shipmentKey)" tooltipPopup="Track">
                    <i class="icon-route"></i>
                </button>
                }
                @default {
                <default-column [column]="column" [item]="alert"></default-column>
                }
                }
            </div>
            }
        </div>
        }
    </virtual-list>
</ng-template>

<!-- dropdownTableColumnsSelectTemplate -->
<ng-template #dropdownTableColumnsSelectTemplate>
    <div class="dropdown-container columns body">
        <div class="add-remove-columns-title">Add/Remove Columns</div>
        <label class="checkbox-container sellect-all-columns ripple-effect ripple-inverse-color">
            <input type="checkbox" [(ngModel)]="isTableOptionalColumnsSelectAll">
            <span class="checkmark"></span>
            <span>Sellect All</span>
        </label>
        <div class="dropdown-container-content" overlayScrollbar>
            @for (column of managerOptionalTableColumnsToSelect; track column.columnType) {
            <label class="checkbox-container ripple-effect ripple-inverse-color">
                <input type="checkbox" [(ngModel)]="column.selected" (change)="onTableOptionalColumnsSelectChange()">
                <span class="checkmark"></span>
                <span>{{ column.text }}</span>
            </label>
            }
        </div>
    </div>
</ng-template>
