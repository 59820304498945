import { Pipe, PipeTransform } from '@angular/core';
import { Constants } from '../utils/globals';

@Pipe({
    name: 'globals',
    standalone: true
})
export class GlobalsPipe implements PipeTransform
{
	public transform(value: string): any
	{
		return (Constants as any)[value];
	}
}