import { HttpClient } from "@angular/common/http";
import { Utils } from "../../utils/utils";
import { orderBy } from "lodash";
import { Observable, Observer } from "rxjs";
import { IApiResponse } from "../../utils/globals";
import { BaseModel } from "../classes/base-model";
import { default as moment } from "moment";

export class ManagerBaseModel<BaseDataType, BaseSortColumnType> extends BaseModel
{
    // #region Protected Members

    protected _managerItems: BaseDataType[] = [];
    protected _filteredManagerItems: BaseDataType[] = [];
    protected _filteredSortedManagerItems: BaseDataType[] = [];
    protected _isSortDescending: boolean = true;
    protected _sortColumn!: BaseSortColumnType;
    protected _managerItemKeyPropertyName: string = '';
    protected _managerItemDatePropertyName: string = '';
    protected _filterFromDate: Date | null = null;
    protected _filterToDate: Date | null = null;

    // #endregion

    // #region Properties

    public searchFilter: string | null = null;

    public selectedManagerItem: BaseDataType | null = null;

    public get managerItems(): BaseDataType[]
    {
        return this._managerItems;
    }

    public get filteredSortedManagerItems(): BaseDataType[]
    {
        return this._filteredSortedManagerItems;
    }

    public get filteredManagerItems(): BaseDataType[]
    {
        return this._filteredManagerItems;
    }

    public get isSortDescending(): boolean
    {
        return this._isSortDescending;
    }

    public set isSortDescending(value: boolean)
    {
        this._isSortDescending = value;
        this.sortManagerItems();
    }

    public get sortColumn(): BaseSortColumnType
    {
        return this._sortColumn;
    }

    public set sortColumn(value: BaseSortColumnType)
    {
        this._sortColumn = value;
        this.sortManagerItems();
    }

    public get filterToDate(): Date | null
    {
        return this._filterToDate;
    }

    public set filterToDate(value: Date | null)
    {
        this._filterToDate = value;
        this.applyFilters();
        this.sortManagerItems();
    }

    public get filterFromDate(): Date | null
    {
        return this._filterFromDate;
    }

    public set filterFromDate(value: Date | null)
    {
        this._filterFromDate = value;
        this.applyFilters();
        this.sortManagerItems();
    }

    // #endregion

    // #region Constructors

    constructor(protected _httpClient: HttpClient)
    {
        super();
    }

    // #endregion

    // #region Public Methods

    public override clear(): void
    {
        super.clear();

        this._managerItems = [];
        this.selectedManagerItem = null;
        this._filteredManagerItems = [];
        this._filteredSortedManagerItems = [];
        this.searchFilter = null;
    }

    public findManagerItem(managerItemKey: any): BaseDataType | null
    {
        if (this._filteredManagerItems.length === 0)
        {
            return null;
        }

        for (const managerItem of this._filteredManagerItems)
        {
            if ((managerItem as any)[this._managerItemKeyPropertyName] === managerItemKey)
            {
                return managerItem;
            }
        }

        return null;
    }

    public updateSelectedManagerItem(): void
    {
        if (this.selectedManagerItem === null)
        {
            return;
        }

        if (this._filteredManagerItems.length === 0)
        {
            this.selectedManagerItem = null;
            return;
        }

        this.selectedManagerItem = this.findManagerItem((this.selectedManagerItem as any)[this._managerItemKeyPropertyName]);
    }

    public updateManagerItemsSort(isSortDescending: boolean, sortColumn: BaseSortColumnType): void
    {
        if (this._isSortDescending !== isSortDescending || this._sortColumn !== sortColumn)
        {
            this._isSortDescending = isSortDescending;
            this._sortColumn = sortColumn;

            this.sortManagerItems();
        }
    }

    public getManagerItems(): Observable<IApiResponse>
    {
        return new Observable((_observer: Observer<IApiResponse>) => {});
    }

    public deleteManagerItem(_managerItem: BaseDataType): Observable<IApiResponse>
    {
        return new Observable((_observer: Observer<IApiResponse>) => { });
    }

    // #endregion

    // #region Protected Methods

    protected getSortColumnProperty(_managerItem: BaseDataType): any
    {
    }

    protected sortManagerItems(): void
    {
        if (this._filteredManagerItems.length === 0)
        {
            this._filteredSortedManagerItems = [];
            return;
        }

        this._filteredSortedManagerItems = orderBy(this._filteredManagerItems,
            [Utils.getPropertyNameof<BaseDataType>(this._filteredManagerItems[0], managerItem =>
            {
                return this.getSortColumnProperty(managerItem as BaseDataType);
            })],
            [!this._isSortDescending ? 'asc' : 'desc']) as BaseDataType[];
    }

    protected isDateRangeFiltered(managerItem: BaseDataType, unitsOfTime: moment.unitOfTime.StartOf = 'days'): boolean
    {
        if (this._filterFromDate === null && this._filterToDate === null)
        {
            return true;
        }

        const managerItemDate: Date | null = (managerItem as any)[this._managerItemDatePropertyName];
        if (managerItemDate === null)
        {
            return false;
        }

        if (this._filterFromDate !== null && Utils.getMoment(this._filterFromDate).isAfter(Utils.getMoment(managerItemDate), unitsOfTime))
        {
            return false;
        }

        if (this._filterToDate !== null && Utils.getMoment(this._filterToDate).isBefore(Utils.getMoment(managerItemDate), unitsOfTime))
        {
            return false;
        }

        return true;
    }

    protected applyFilters(updateSelectedManagerItem: boolean = true): void
    {
        if (this._filterFromDate === null && this._filterToDate === null)
        {
            this._filteredManagerItems = [...this._managerItems];
        }
        else
        {
            this._filteredManagerItems = this._managerItems.filter((managerItem: BaseDataType) => this.isDateRangeFiltered(managerItem));
        }

        if (updateSelectedManagerItem)
        {
            this.updateSelectedManagerItem();
        }
    }

    // #endregion
}