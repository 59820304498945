import { Injectable, RendererFactory2 } from "@angular/core";
import { StartFormLists, StartFormSubmitRequest } from "./start-form-model.class";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Observable, Observer } from "rxjs";
import { Constants, IApiResponse } from "../../utils/globals";
import { HttpHelper } from "../../utils/http-helper";
import { isEqual } from "lodash";
import { AttachmentsModel } from "../../base/models/attachments.model";
import { AttachmentFileData } from "../../base/models/attachments-model.class";
import { Utils } from "../../utils/utils";

@Injectable({ providedIn: 'root' })
export class StartFormModel extends AttachmentsModel
{
    // #region Private Members

    private _startFormLists: StartFormLists = new StartFormLists();
    private _startFormSubmitRequest: StartFormSubmitRequest = new StartFormSubmitRequest();
    private readonly _containerTypes: string[] = ['Unknown', 'Dry', 'Reefer'];
    private readonly _deliveryTypes: string[] = ['Domestic', 'Duty Free'];
    private readonly _shippingMethods: string[] = ['LCL', 'FCL', 'FTL', 'LTL'];

    // #endregion

    // #region Properties

    public override get isDirty(): boolean
    {
        const emptySubmitStartFormRequest: StartFormSubmitRequest = new StartFormSubmitRequest();

        return super.isDirty || !isEqual(this._startFormSubmitRequest, emptySubmitStartFormRequest);
    }

    public get containerTypes(): string[]
    {
        return this._containerTypes;
    }

    public get deliveryTypes(): string[]
    {
        return this._deliveryTypes;
    }

    public get shippingMethods(): string[]
    {
        return this._shippingMethods;
    }

    public get startFormLists(): StartFormLists
    {
        return this._startFormLists;
    }

    public get startFormSubmitRequest(): StartFormSubmitRequest
    {
        return this._startFormSubmitRequest;
    }

    // #endregion

    // #region Constructors

    constructor(_httpClient: HttpClient, rendererFactory: RendererFactory2)
    {
        super(_httpClient, rendererFactory);
    }

    // #endregion

    // #region Public Methods

    public override clear(): void
    {
        super.clear();
        this.clearStartForm();
        this._startFormLists = new StartFormLists();
    }

    public clearStartForm(): void
    {
        this._startFormSubmitRequest = new StartFormSubmitRequest();
        this.initializeAttachmentsInfo();
    }

    public getStartFormLists(): Observable<IApiResponse>
    {
        this._isBusy = true;

        return new Observable((observer: Observer<IApiResponse>) =>
        {
            this._httpClient.get<StartFormLists>('start-form/lists', { headers: HttpHelper.GetHttpFormUrlencodedHeaders() }).subscribe(
                {
                    next: (startFormLists: StartFormLists) =>
                    {
                        this._startFormLists = startFormLists;

                        this._isBusy = false;
                        observer.next({ isSuccess: true });
                        observer.complete();
                    },
                    error: (error: HttpErrorResponse) =>
                    {
                        this._isBusy = false;
                        console.error(error);

                        observer.next({ isSuccess: false, message: Constants.DATA_SERVICE_ERROR_STRING });
                        observer.complete();
                    }
                });
        });
    }

    public submitStartForm(): Observable<IApiResponse>
    {
        this._isBusy = true;

        return new Observable((observer: Observer<IApiResponse>) =>
        {
            const formData: FormData = new FormData();
            this._attachmentsInfo[0].uploadAttachmentsFilesData.forEach((attachmentFileData: AttachmentFileData) =>
                formData.append('files', attachmentFileData.attachmentFile, attachmentFileData.attachmentFile.name));

            formData.append('start-form', new Blob([JSON.stringify(Utils.clearObjectEmptyStrings(this._startFormSubmitRequest))], { type: 'application/json' })); 

            this._httpClient.post('start-form/submit', formData).subscribe(
                    {
                        next: () =>
                        {
                            this._isBusy = false;
                            observer.next({ isSuccess: true });
                            observer.complete();
                        },
                        error: (error: HttpErrorResponse) =>
                        {
                            this._isBusy = false;
                            console.error(error);

                            observer.next({ isSuccess: false, message: Constants.DATA_SERVICE_ERROR_STRING });
                            observer.complete();
                        }
                    });
        });
    }

    // #endregion
}