import { MonitoringArrivalStatusType } from "../../../base/models/manager-base-model.class";
import { CGIMarkerIconFactory } from "../../../controls/google-map/utils/cgi-marker-icon-factory";
import { Constants } from "../../../utils/globals";
import { MonitoringUtils, DisplayProperties } from "../../../utils/monitoring-utils";

export class CGIMarkerShipmentsIconFactory extends CGIMarkerIconFactory
{
    // #region Public Methods

    public createCGIMarkerArrivalStatusElement(iconClassName: string, shipmentMonitoringArrivalStatusType: MonitoringArrivalStatusType): Element
    {
        return this.createCGIMarkerElement(this._bodyCssStyleDeclaration.getPropertyValue(
            MonitoringUtils.SHIPMENT_ARRIVAL_STATUSES_DISPLAY_PROPERTIES[shipmentMonitoringArrivalStatusType].color), this.CGIMARKER_CLASSNAME, iconClassName);
    }

    public createCGIClusterMarkerArrivalStatusElement(arrivalStatusesOccurrences: number[], clusterCount: number): Element
    {
        const markerElement: HTMLElement = document.createElement("div");
        markerElement.className = `${this.CGIMARKER_CLUSTER_CLASSNAME} ${this.CGIMARKER_CIRCLE_CLASSNAME} ${Constants.ACTIVE_CLASSNAME}`;
        markerElement.style.background =
            `conic-gradient(${this.getCGIClusterMarkerGradient(arrivalStatusesOccurrences, clusterCount, this.getShipmentAriivalStatusesColors())})`;

        markerElement.innerHTML = `<div class="${this.CGIMARKER_DATA_CLASSNAME}"><span>${clusterCount}</span></div>`;

        return markerElement;
    }

    public createCGIClusterPinMarkerArrivalStatusElement(arrivalStatusesOccurrences: number[], clusterCount: number): Element
    {
        const markerElement: HTMLElement = document.createElement("div");
        markerElement.className = `${this.CGIMARKER_CLUSTER_CLASSNAME} ${CGIMarkerIconFactory.CGIMARKER_PIN_CLASSNAME} ${Constants.ACTIVE_CLASSNAME}`;
        const background: string = `conic-gradient(${this.getCGIClusterMarkerGradient(arrivalStatusesOccurrences, clusterCount, 
            this.getShipmentAriivalStatusesColors())})`;

        markerElement.innerHTML = this.createClusterPinHtmlContent(background, clusterCount);

        return markerElement;
    }

    // #endregion

    // #region Private Methods

    private getShipmentAriivalStatusesColors(): string[]
    {
        return MonitoringUtils.SHIPMENT_ARRIVAL_STATUSES_DISPLAY_PROPERTIES.map((styleProperties: DisplayProperties) => styleProperties.color);
    }

    // #endregion
}