import { ChangeDetectorRef, Component, ElementRef, OnDestroy, QueryList, ViewChild, ViewChildren } from "@angular/core";
import { Observable, Subject, Subscription } from "rxjs";
import { PorfolioViewerComponent } from "../../controls/porfolio-viewer/porfolio-viewer.component";
import { Utils } from "../../utils/utils";
import { SafeResourceUrl } from "@angular/platform-browser";
import ContguardPlugin from "../../../plugins/contguard.plugin";
import { AttachmentsModel } from "../models/attachments.model";
import { ModalMessageService } from "../../controls/modal-message/services/modal-message.service";
import { ModalButtonType, ModalIconType, ModalResultType, ModalType } from "../../controls/modal-message/modal-message.component";
import { Constants, IApiResponse } from "../../utils/globals";
import { SavableBaseComponent } from "./savable-base.component";
import { AttachmentFileData } from "../models/attachments-model.class";

@Component({ template: ''})
export class AttachmentsComponent extends SavableBaseComponent implements OnDestroy
{
    // #region Protected Constants

    protected readonly IMAGE_LOAD_CLASSNAME: string = 'load';
    protected readonly IMAGE_ERROR_CLASSNAME: string = 'error';

    // #endregion

    // #region Private Members

    private _attachmentResponseSubject: Subject<IApiResponse> = new Subject<IApiResponse>();

    @ViewChild(PorfolioViewerComponent, { read: PorfolioViewerComponent, static: false }) private _porfolioViewer: PorfolioViewerComponent | undefined = undefined;

    @ViewChildren('attachment') set attachmentElementsRef(attachmentsElement: QueryList<ElementRef<HTMLElement>>)
    {
        if (!Utils.isNullOrUndefined(attachmentsElement) && !Utils.isNullOrUndefined(attachmentsElement.last))
        {
            Utils.scrollElementsBounderiesIntoView([attachmentsElement.last.nativeElement], false, 0, false);
        }
    }

    // #endregion

    // #region Protected Members

    protected _componentSubscription: Subscription | null = null;

    // #endregion

    // #region Properties

    public get attachmentResponseObservable(): Observable<IApiResponse>
    {
        return this._attachmentResponseSubject;
    }

    public override get isDirty(): boolean
    {
        return this.attachmentsModel.isDirty;
    }

    public get attachmentsModel(): AttachmentsModel
    {
        return this._attachmentsModel;
    }

    // #endregion

    // #region Constructor

    constructor(protected _attachmentsModel: AttachmentsModel, protected _changeDetectorRef: ChangeDetectorRef,
        _modalMessageService: ModalMessageService)
    {
        super(_modalMessageService);
    }

    // #endregion

    // #region Event Handlers

    public ngOnDestroy(): void
    {
        ContguardPlugin.setOrientationPortrait();

        this.clearComponentSubscription();
    }

    public onAttachmetsWheel(event: WheelEvent): void
    {
        const element: HTMLElement = event.currentTarget as HTMLElement;
        if (element !== null && element.scrollWidth > element.clientWidth)
        {
            event.preventDefault();
            element.scrollLeft += event.deltaY;
        }
    }

    public onAttachmentClick(event: Event, attachmentType: number = 0): void
    {
        if (this._porfolioViewer === undefined)
        {
            return;
        }

        const attachemntElement: HTMLElement = event.currentTarget as HTMLElement;
        if (attachemntElement.parentElement === null)
        {
            return;
        }

        const attachmentsUrls: string[] = this.getExtraAttachmentsUrls(attachemntElement);

        attachmentsUrls.push(...this.attachmentsModel.attachmentsInfo[attachmentType].uploadAttachmentsFilesData.map(
            (AttachmentFileData: AttachmentFileData) => URL.createObjectURL(AttachmentFileData.attachmentFile)));

        const elementIndex = Array.prototype.indexOf.call(attachemntElement.parentElement.children, attachemntElement) -
            (attachemntElement.parentElement.children.length - attachmentsUrls.length);

        this._porfolioViewer.closing.subscribe(() =>
        {
            ContguardPlugin.setOrientationPortrait();
        });

        this._porfolioViewer.showPorfolio(attachmentsUrls, elementIndex);
        ContguardPlugin.setOrientationSensor();
    }

    public onImageError(event: Event, url: string | SafeResourceUrl): void
    {
        if (url !== undefined && url.toString().length > 0)
        {
            (event.target as HTMLImageElement).parentElement?.classList.add(this.IMAGE_ERROR_CLASSNAME);
        }
    }

    public onImageLoad(event: Event): void
    {
        (event.target as HTMLImageElement).parentElement?.classList.add(this.IMAGE_LOAD_CLASSNAME);
    }

    public onDeleteAttachmentButtonClick(event: Event, attachmentIndex: number, attachmentType: number = 0): void
    {
        event.stopPropagation();

        this.showDeleteImageVerificationMessage().then((result: ModalResultType) =>
        {
            if (result === ModalResultType.Yes)
            {
                this.attachmentsModel.deleteAttachmentFile(attachmentIndex, attachmentType);
                this._changeDetectorRef.detectChanges();
            }
        });
    }

    public onAddCapturedAttachmentsChange(file: File | null, attachmentType: number = 0): void
    {
        if (file instanceof File)
        {
            this.addAttachments([file], attachmentType);
        }
    }

    public onAttachmentsFilesDrop(files: FileList, attachmentType: number = 0): void
    {
        this.addAttachments(Array.from(files), attachmentType);
    }

    public onAddAttachmentsChange(event: Event, attachmentType: number = 0): void
    {
        const fileInputElement: HTMLInputElement = event.target as HTMLInputElement;
        if (fileInputElement !== null && fileInputElement.files !== null)
        {
            this.addAttachments(Array.from(fileInputElement.files), attachmentType);

            fileInputElement.value = '';
        }
    }

    // #endregion

    // #region Protected Methods

    protected clearComponentSubscription(): void
    {
        if (this._componentSubscription !== null)
        {
            this._componentSubscription.unsubscribe();
            this._componentSubscription = null;
        }
    }

    protected getExtraAttachmentsUrls(_attachemntElement: HTMLElement): string[]
    {
        return [];
    }

    protected showDeleteImageVerificationMessage(): Promise<ModalResultType>
    {
        return this._modalMessageService.show(
            {
                title: Constants.APP_TITLE,
                message: 'Are you sure you want to delete this image?',
                modalType: ModalType.Error,
                modalIcon: ModalIconType.Question,
                modalButton: ModalButtonType.YesNo
            });
    }

    protected uploadAttachment(attachmentIndex: number, attachmentType: number = 0): Promise<boolean>
    {
        return new Promise<boolean>((resolve) =>
        {
            this.clearComponentSubscription();

            this._componentSubscription = this.attachmentsModel.uploadAttachmentFile(attachmentIndex, attachmentType).subscribe((response: IApiResponse) =>
                {
                    this._progressValue = response.progress !== null && response.progress !== undefined ?
                        response.progress * (attachmentIndex + 1) /
                        this.attachmentsModel.attachmentsInfo[attachmentIndex].uploadAttachmentsFilesData.length : null;

                    if (this._progressValue !== null)
                    {
                        this._changeDetectorRef.detectChanges();

                        this._attachmentResponseSubject.next({ isSuccess: true, isComplete: false });
                    }
                    else if (response.isSuccess)
                    {
                        resolve(true);

                        this._attachmentResponseSubject.next({ isSuccess: true, isComplete: false });
                    }
                    else
                    {
                        this._progressValue = null;
                        this.viewIsReady = true;
                        this._changeDetectorRef.detectChanges();

                        this._attachmentResponseSubject.next({ isSuccess: false, isComplete: false });

                        this._modalMessageService.show({ title: Constants.APP_TITLE, message: response.message, modalType: ModalType.Error }).then(() =>
                            resolve(false));
                    }
                });
        });
    }

    // #endregion

    // #region Private Methods

    private addAttachments(files: File[], attachmentType: number = 0): void
    {
        this.clearComponentSubscription();

        let filesSizeLimitSkipped: number = 0;

        this._componentSubscription = this.attachmentsModel.addAttachmentFiles(files, attachmentType).subscribe(
            (response: IApiResponse) =>
            {
                if (response.isComplete === true)
                {
                    this.clearComponentSubscription();
                }

                this._changeDetectorRef.detectChanges();

                if (response.isSuccess)
                {
                    if (response.message !== undefined)
                    {
                        filesSizeLimitSkipped++;
                    }

                    if (response.isComplete === true && filesSizeLimitSkipped > 0)
                    {
                        this._modalMessageService.show(
                            {
                                title: Constants.APP_TITLE, message: filesSizeLimitSkipped === 1 ?
                                    'One file was skipped due to upload size limit' : 'Some files were skipped due to upload size limit',
                                modalType: ModalType.Error
                            });
                    }
                }
                else
                {
                    this._modalMessageService.show({ title: Constants.APP_TITLE, message: response.message, modalType: ModalType.Error });
                }
            });
    }

    // #endregion
}