<!DOCTYPE html>

<div class="form-group ripple-effect ripple-inverse-color" (mousedown)="onInputGroupMouseDown($event)" [class.disabled]="disabled">
    <div class="form-control has-icon" [class.clearable]="clearable" [class.not-empty]="selectedItemsString.length > 0">
        <div class="dropdown-value-container">
            @if (filter.length === 0) {
            <div class="dropdown-value-wrapper">
                <ng-template #defaultLabelTemplate>
                    <div class="dropdown-value select-none" [innerHTML]="selectedItemsString" [tooltipPopup]="selectedFullItemsString"
                         [showTooltipOnVerflow]="selectedFullItemsString === selectedItemsString" [tooltipEventsElement]="inputFilter"></div>
                </ng-template>
                <ng-template [ngTemplateOutlet]="labelTemplateRef || defaultLabelTemplate"
                             [ngTemplateOutletContext]="{ selectedItems: selectedItems }">
                </ng-template>
            </div>
            }
        </div>
        <div class="dropdown-input-container">
            <input name="inputFilter" #inputFilter type="text" (keydown)="onKeyDown($event)" (input)="onInputFilterInput()" [(ngModel)]="filter"
                   [readonly]="!isSearchable" [disabled]="disabled" autocomplete="off" />
        </div>
    </div>
    <div class="control-label" [class.required]="isRequired">{{ placeholder }}</div>
    <i class="control-icon press-icon icon-arrow-down-light"></i>
    @if (clearable && selectedItems.length > 0) {
    <i class="icon-cancel control-icon press-icon ripple-effect ripple-center" (mousedown)="onClearValueMouseDown($event)"
       @fadeInOutAnimation>
    </i>
    }
</div>
  @if (dropdownState !== DropdownState.Closed) {
<div #dropdownPanel class="picker-dropdown" [class.multiple]="multiple">
    @if (multiple && maxSelectedItems === null && items.length > 1) {
    <div class="option-select-all select-none" [class.checked]="selectedItems.length === items.length" [class.highlighted]="highlightIndex === -1"
         (click)="onSelectAllClick()">
        Select All
    </div>
    }
    <virtual-list #itemsList [items]="items" (itemsChange)="onListItemsChange()" (scrollChange)="onListScrollChange()"
                  [maxViewportItems]="maxViewportItems" [virtualListInfo]="virtualListInfo"
                  [filterProperties]="bindLabel ? [bindLabel] : []" [filter]="filter" class="picker-frame" overlayScrollbar>
        <div *ngFor="let item of itemsList.viewPortItems; trackBy: trackByItem"
             #body class="option-value-container" [class.selected]="selectedItems.includes(item)"
             [class.highlighted]="highlightIndex === itemsList.filteredSortedItems.indexOf(item)"
             [class.disabled]="body.firstElementChild && body.firstElementChild.classList.contains('disabled') ||
             maxSelectedItems !== null && selectedItems.length === maxSelectedItems &&  !selectedItems.includes(item) ||
             minSelectedItems !== null && selectedItems.length === minSelectedItems && selectedItems.includes(item)"
             (click)="onItemClick(item)">
            <ng-template #defaultOptionTemplate>
                <div class="option-value select-none" [tooltipPopup]="getItemLabel(item)" [showTooltipOnVerflow]="true"
                     [innerHTML]="getItemLabel(item)">
                </div>
            </ng-template>
            <ng-template [ngTemplateOutlet]="optionTemplateRef || defaultOptionTemplate" [ngTemplateOutletContext]="{ item: item }"></ng-template>
        </div>
    </virtual-list>
</div>
}