import { Component, Input } from "@angular/core";
import { IListColumn } from "../../utils/globals";
import { Utils } from "../../utils/utils";
import { GlobalsPipe } from "../../pipes/globals.pipe";
import { TooltipDirective } from "../../directives/tooltip.directive";

@Component({
    selector: 'default-column',
    template: `<div class="default-column" [tooltipPopup]="column.propertyName ? item[column.propertyName] : ''" [showTooltipOnVerflow]="true">${''}<span>{{ !column.propertyName || !this.getColumnPropertyValue(item, column.propertyName) ? ('EMPTY_FIELD_VALUE' | globals) : ${''}this.getColumnPropertyValue(item, column.propertyName)}}</span><ng-content></ng-content></div>`,
    standalone: true,
    imports: [TooltipDirective, GlobalsPipe]
})
export class DefaultColumnComponent
{
    // #region Inputs

    @Input() public column!: IListColumn;
    @Input() public item!: any;

    // #endregion

    // #region Public Methods

    public getColumnPropertyValue(item: any, propertyName: string): any
    {
        return Utils.getItemNestedPropertyValue(item, propertyName);
    }

    // #endregion
}