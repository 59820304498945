<!DOCTYPE html>

<div class="page-container">
  <top-bar [barTitle]="shipment.shipmentModel.displayShipment.shipmentId !== null ? 'Edit Shipment' : 'New Shipment'"></top-bar>
  <div class="page-content mobile-standalone">
    <div class="page-header">
      <button class="back-button ripple-effect ripple-inverse-color" (click)="onBackButtonClick()">
        <i class="icon-collapse-left"></i>Back
      </button>
      @if (shipment.shipmentModel.displayShipment.autoClose) {
        <i class="icon-auto-close"></i>
      }
    </div>
    <shipment #shipment (submitingShipment)="onSubmitingShipment()"></shipment>
  </div>
  @if (!shipment.viewIsReady) {
    <loader @fadeInOutAnimation [loadingMessage]="shipment.statusMessage" [loadingProgress]="shipment.progressValue"></loader>
  }
</div>

