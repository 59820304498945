export class AppStorage
{
    // #region Constants

    private static readonly STORAGE_APP_NAME: string = 'cgi-mobile';

    public static readonly AUTH_KEY_NAME: string = 'auth';
    public static readonly LOGIN_KEY_NAME: string = 'login';
    public static readonly APP_SETTINGS_KEY_NAME: string = 'app-settings';
    public static readonly APP_SETTINGS_PENDING_KEY_NAME: string = 'app-settings-pending';

    // #endregion

    // #region Public Methods

    public static setStorageItem(itemName: string, itemValue: string | null): void
    {
        if (itemValue === null)
        {
            localStorage.removeItem(this.getFullStorageItemName(itemName));
        }
        else
        {
            localStorage.setItem(this.getFullStorageItemName(itemName), itemValue);
        }
    }

    public static getStorageItem(itemName: string): string | null
    {
        return localStorage.getItem(this.getFullStorageItemName(itemName));
    }

    // #endregion

    // #region Private Methods

    private static getFullStorageItemName(itemName: string): string
    {
        return `${AppStorage.STORAGE_APP_NAME}.${itemName}`;
    }

    // #endregion
}