import { Injectable } from "@angular/core";
import { NavigationEnd, Router } from "@angular/router";
import { LoginModel } from "../user/login/model/login.model";
import { UserInsightPermission } from "../user/login/model/login-model.class";
import { Observable, Subject, filter } from "rxjs";
import { environment } from "../../environments/environment";
import { RouteType } from "../utils/globals";

export class AppMenuItem
{
    public text: string = '';
    public iconClass: string = '';
    public routeType: RouteType = RouteType.None;
    public isExpanded: boolean = false;
}

@Injectable({ providedIn: 'root' })
export class AppMenuService
{
    // #region Private Members

    private _allAppMenuItems: AppMenuItem[] = [];
    private _appMenuUpdatedSubject: Subject<boolean> = new Subject<boolean>();

    // #endregion

    // #region Properties

    public activeRouteType: RouteType = RouteType.None;

    public get appMenuUpdatedObservable(): Observable<boolean>
    {
        return this._appMenuUpdatedSubject;
    }

    public get activeAppMenuItem(): AppMenuItem | null
    {
        for (const appMenuItem of this._allAppMenuItems)
        {
            if (appMenuItem.routeType === this.activeRouteType)
            {
                return appMenuItem;
            }
        }

        return null;
    }

    public get allAppMenuItems(): AppMenuItem[]
    {
        return this._allAppMenuItems;
    }

    // #endregion

    // #region Constructor

    constructor(private _router: Router, private _loginModel: LoginModel)
    {
        this.initializeAppMenuItems();

        this.handleRouterEvents();
    }

    // #endregion

    // #region Public Methods

    public handleAppMenuItemClick(routeType: RouteType): void
    {
        switch (routeType)
        {
            case RouteType.TransitManager:
            case RouteType.AlertsManager:
                {
                    window.location.href = `${environment.legacyBaseUrl}${routeType}`;
                }
                break;

            default:
                {
                    this._router.navigate([`/${routeType}`]);
                }
                break;
        }
    }

    // #endregion

    // #region Private Methods

    private handleRouterEvents(): void
    {
        this._router.events.pipe(filter((event: any) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) =>
        {
            const menuItemsInitialized: boolean = this._allAppMenuItems.length === 0;

            if (this._loginModel.accessToken === null)
            {
                this._allAppMenuItems = [];
            }
            else if (this._allAppMenuItems.length === 0)
            {
                this.initializeAppMenuItems();
            }

            if (event.url === '/')
            {
                this.activeRouteType = (this._router.config[this._router.config.length - 2].redirectTo as string) as RouteType;
            }
            else
            {
                for (const route of Object.values(RouteType))
                {
                    if (event.url.startsWith(`/${route}`))
                    {
                        this.activeRouteType = route;
                    }
                    else if (event.url.startsWith(`/${RouteType.ShipmentMonitoring}`))
                    {
                        this.activeRouteType = RouteType.ShipmentsMonitoring;
                    }
                }
            }

            this._appMenuUpdatedSubject.next(menuItemsInitialized);
        });
    }

    private initializeAppMenuItems(): void
    {
        if (this._loginModel.userInsightPermissions.includes(UserInsightPermission.ShipmetsMonitoring))
        {
            this._allAppMenuItems.push(
                {
                    text: 'Shipments Monitoring',
                    iconClass: 'icon-signal-stream',
                    routeType: RouteType.ShipmentsMonitoring,
                    isExpanded: false
                },
                {
                    text: 'Events Monitoring',
                    iconClass: 'icon-event-monitoring',
                    routeType: RouteType.EventsMonitoring,
                    isExpanded: false
                }
            );
        }

        if (this._loginModel.userInsightPermissions.includes(UserInsightPermission.DemurrageMonitoring))
        {
            this._allAppMenuItems.push(
                {
                    text: 'Demurrage Monitoring',
                    iconClass: 'icon-demurrage-monitoring',
                    routeType: RouteType.DemurrageMonitoring,
                    isExpanded: false
                });
        }

        if (this._loginModel.userInsightPermissions.includes(UserInsightPermission.Analytics))
        {
            this._allAppMenuItems.push(
                {
                    text: 'Analytics',
                    iconClass: 'icon-chart-mixed',
                    routeType: RouteType.Analytics,
                    isExpanded: false
                });
        }

        if (this._loginModel.userInsightPermissions.includes(this._loginModel.shipmentManagerPermission))
        {
            this._allAppMenuItems.push(
                {
                    text: 'Shipment Manager',
                    iconClass: 'icon-shipment-manager',
                    routeType: RouteType.ShipmentManager,
                    isExpanded: false
                });
        }

        if (this._loginModel.userInsightPermissions.includes(UserInsightPermission.TransitManager))
        {
            this._allAppMenuItems.push(
                {
                    text: 'Transit Manager',
                    iconClass: 'icon-truck-container',
                    routeType: RouteType.TransitManager,
                    isExpanded: false
                });
        }

        if (this._loginModel.userInsightPermissions.includes(UserInsightPermission.DemurrageManager))
        {
            this._allAppMenuItems.push(
                {
                    text: 'Demurrage Manager',
                    iconClass: 'icon-demurrage',
                    routeType: RouteType.DemurrageManager,
                    isExpanded: false
                });
        }

        if (this._loginModel.userInsightPermissions.includes(UserInsightPermission.InventoryManager))
        {
            this._allAppMenuItems.push(
                {
                    text: 'Inventory Manager',
                    iconClass: 'icon-inventory',
                    routeType: RouteType.InventoryManager,
                    isExpanded: false
                });
        }

        if (this._loginModel.userInsightPermissions.includes(UserInsightPermission.MiniControlCenter))
        {
            this._allAppMenuItems.push(
                {
                    text: 'Mini Control Center',
                    iconClass: 'icon-headset',
                    routeType: RouteType.MiniControlCenter,
                    isExpanded: false
                });
        }

        if (this._loginModel.userInsightPermissions.includes(UserInsightPermission.Alerts))
        {
            this._allAppMenuItems.push(
                {
                    text: 'Alert Manager',
                    iconClass: 'icon-alert-manager',
                    routeType: RouteType.AlertsManager,
                    isExpanded: false
                });
        }

        if (this._loginModel.userInsightPermissions.includes(UserInsightPermission.Devices))
        {
            this._allAppMenuItems.push(
                {
                    text: 'Devices',
                    iconClass: 'icon-router',
                    routeType: RouteType.Devices,
                    isExpanded: false
                });
        }

        if (this._loginModel.userInsightPermissions.includes(UserInsightPermission.AmazonForecast))
        {
            this._allAppMenuItems.push(
                {
                    text: 'Forecast',
                    iconClass: 'icon-calendar-success',
                    routeType: RouteType.Forecast,
                    isExpanded: false
                });
        }

        if (this._loginModel.userInsightPermissions.includes(UserInsightPermission.StartForm))
        {
            this._allAppMenuItems.push(
                {
                    text: 'Start Shipment',
                    iconClass: 'icon-start-shipment',
                    routeType: RouteType.StartForm,
                    isExpanded: false
                });
        }
    }

    // #endregion
}