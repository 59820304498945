import { Directive, Input } from "@angular/core";
import { FormGroup, NG_VALIDATORS, ValidationErrors, Validator } from "@angular/forms";
import { Utils } from "../utils/utils";

@Directive({
    selector: '[matchPasswordsValidator]',
    providers: [{ provide: NG_VALIDATORS, useExisting: MatchPasswordsDirective, multi: true }],
    standalone: true
})
export class MatchPasswordsDirective implements Validator
{
    // #region Contants

    private readonly MATCH_PASSWORD_VALIDATION_ERROR_NAME: string = 'matchPasswordsValidator';

    // #endregion

    // #region Inputs

	@Input('matchPasswordsValidator') matchedControlsNames: string[] = [];

    // #endregion

    // #region Validator Interface

    public validate(formGroup: FormGroup): ValidationErrors | null
    {
        if (this.matchedControlsNames.length < 3)
        {
            return null;
        }

        const oldPasswordControl = formGroup.controls[this.matchedControlsNames[0]];
        const newPasswordControl = formGroup.controls[this.matchedControlsNames[1]];
        const confirmPasswordControl = formGroup.controls[this.matchedControlsNames[2]];

        if (Utils.isNullOrUndefined(oldPasswordControl) || Utils.isNullOrUndefined(newPasswordControl) || Utils.isNullOrUndefined(confirmPasswordControl))
        {
            return null;
        }

        const validationErrors: ValidationErrors = {};
        validationErrors[this.MATCH_PASSWORD_VALIDATION_ERROR_NAME] = true;

        if (!Utils.isNullOrUndefined(oldPasswordControl.value) && !Utils.isNullOrUndefined(newPasswordControl.value) &&
            newPasswordControl.value === oldPasswordControl.value)
        {
            newPasswordControl.setErrors(validationErrors);
        }
        else if (newPasswordControl.hasError(this.MATCH_PASSWORD_VALIDATION_ERROR_NAME))
        {
            newPasswordControl.setErrors(null);
        }

        if (!Utils.isNullOrUndefined(newPasswordControl.value) && !Utils.isNullOrUndefined(confirmPasswordControl.value) &&
            confirmPasswordControl.value !== newPasswordControl.value)
        {
            confirmPasswordControl.setErrors(validationErrors);
        }
        else if (confirmPasswordControl.hasError(this.MATCH_PASSWORD_VALIDATION_ERROR_NAME))
        {
            confirmPasswordControl.setErrors(null);
        }

        return null;
    }
}
