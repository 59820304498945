<!DOCTYPE html>

<div class="page-container">
    @if (viewIsInitialized) {
    <div class="page-content" @fadeInAnimation>
        <div class="page-inner-content">
            <div class="page-padding">
                <div class="form-group">
                    <clearable-input [(ngModel)]="managerModel.searchFilter" label="Filter Demorrage Contracts" icon="icon-search"
                                     class="search-control" [disabled]="!managerVirtualList || managerVirtualList.viewPortItems.length === 0 &&
                                     (managerModel.searchFilter === null ||
                                     managerModel.searchFilter.length === 0)">
                    </clearable-input>
                </div>
                <div class="manager-controls-container">
                    <div class="manager-buttons-container">
                        <button type="button" class="add-button ripple-effect ripple-fill" tooltipPopup="Add demorrage contract"
                                (click)="onAddManagerItemButtonClick()">
                            <span>+</span>
                        </button>
                    </div>
                    @if (!('IS_MOBILE' | globals)) {
                    <div class="dates-filter">
                        <datetime-picker label="From" name="filterFromDate" #filterFromDate="ngModel" [(ngModel)]="managerModel.filterFromDate"
                                         [maxDate]="managerModel.filterToDate" [autoSelect]="false">
                        </datetime-picker>
                        <datetime-picker label="To" name="filterToDate" #filterToDate="ngModel" [(ngModel)]="managerModel.filterToDate"
                                         [minDate]="managerModel.filterFromDate" [autoSelect]="false">
                        </datetime-picker>
                    </div>
                    }
                    <div class="manager-behaviour-controls">
                        <button type="button" class="image-button excel-button import ripple-effect ripple-center"
                                (click)="onImportFromExcelButtonClick()" tooltipPopup="Import from Excel">
                            <i class="icon-excel"></i>
                        </button>
                        <button type="button" class="image-button excel-button ripple-effect ripple-center"
                                (click)="onExportToExcelButtonClick()" tooltipPopup="Export to Excel">
                            <i class="icon-excel"></i>
                        </button>
                        <button type="button" class="image-button refresh-button ripple-effect ripple-center" tooltipPopup="Refresh"
                                (click)="onRefreshButtonClick($event)">
                            <i class="icon-refresh"></i>
                        </button>
                    </div>
                </div>
                <div class="manager-elements-count">{{ (!managerVirtualList ? 0 : managerVirtualList.filteredSortedItems.length) }} Demorrage Contracts</div>
            </div>
            <div class="virtual-list-container">
                <div class="manager-table-container page-padding">
                    <virtual-list #managerItemsList [items]="managerModel.filteredSortedManagerItems" id="demurrage-manager-table"
                                  class="manager-table" [filter]="managerModel.searchFilter" tabindex="-1" [isSortable]="true"
                                  [class.vertical-scrollable]="managerItemsList.element.scrollHeight > managerItemsList.element.clientHeight"
                                  [filterProperties]="managerSearchProperties"
                                  [virtualListInfo]="managerTableListInfo" [isAutoColumnsWidths]="true"
                                  (itemsChange)="onManagerItemsListChange($event)" (sorted)="onManagerItemsListSorted()"
                                  [class.active]="managerItemsList.viewPortItems.length > 0">
                        <div #header class="list-header">
                            @for (column of managerTableColumns; track column.columnType) {
                            <div [class]="'col-list ' + (column.classes ?? '')" [attr.property-name]="column.propertyName"
                                 [attr.sort-property-name]="column.sortPropertyName" [attr.fixed-width]="column.isFixedWidth ? 'true' : null">
                                @switch (column.columnType) {
                                @case (DemurrageColumnType.Delete) {
                                }
                                @default {
                                <span [innerHTML]="column.text"></span>
                                }
                                }
                            </div>
                            }
                        </div>
                        @for (displayDemurrageContract of managerItemsList.viewPortItems; track displayDemurrageContract.contractId) {
                        <div #body class="list-row ripple-effect ripple-fill ripple-inverse-color"
                             (click)="onManagerItemClick(displayDemurrageContract)"
                             [ngClass]="{ 'odd-row': managerItemsList.filteredSortedItems.indexOf(displayDemurrageContract) % 2,
                            'selected': displayDemurrageContract === managerModel.selectedManagerItem }">
                            @for (column of managerTableColumns; track column.columnType) {
                            <div [class]="'col-list ' + (column.classes ?? '')" [attr.property-name]="column.propertyName">
                                @switch (column.columnType) {
                                @case (DemurrageColumnType.Delete) {
                                <button type="button" class="image-button ripple-effect ripple-center ripple-inverse-color"
                                        (click)="onDemurrageDeleteButtonClick($event, displayDemurrageContract)">
                                    <i class="icon-delete"></i>
                                </button>
                                }
                                @default {
                                <div [tooltipPopup]="column.propertyName ? displayDemurrageContract[column.propertyName] : ''" [showTooltipOnVerflow]="true">
                                    <span>{{ column.propertyName ? displayDemurrageContract[column.propertyName] : '' }}</span>
                                </div>
                                }
                                }
                            </div>
                            }
                        </div>
                        }
                    </virtual-list>
                </div>
                @if (!managerVirtualList || managerVirtualList.viewPortItems.length === 0) {
                <div class="list-empty-placeholder" @fadeInOutAnimation>
                    <i class="icon-demurrage-placeholder"></i>
                </div>
                }
            </div>
        </div>
        <div class="page-inner-content">
            <div class="manager-details-pane">
                @if (!managerModel.selectedManagerItem) {
                <div class="manager-details-placeholder">
                    <div class="title">No demurrage contract selected</div>
                    <div>Please select a demurrage contract from the list<br />to view or edit one</div>
                    <i class="icon-edit"></i>
                </div>
                }
                @if (managerModel.selectedManagerItem) {
                <div class="manager-element-container" @shortFadeInOutAnimation>
                    <div class="manager-element-title">Demurrage Contract</div>
                    <demurrage class="manager-element" #managerItem (submitingManagerItem)="onSubmitingManagerItem($event)"></demurrage>
                </div>
                }
            </div>
        </div>
        @if (showAddNewManagerElement) {
        <div tabindex="-1" class="modal-backdrop new-element" (click)="onCloseAddNewManagerElementClick()" @fadeInOutAnimation></div>
        }
        @if (showAddNewManagerElement) {
        <div class="manager-new-element new-element" tabindex="-1" @fadeSlideDownInOutAnimation>
            <div class="modal-content-container">
                <div class="modal-content page-container page-image">
                    <div class="modal-header">
                        <div class="modal-title">New Demurrage Contract</div>
                        <button type="button" class="image-button close-button" (click)="onCloseAddNewManagerElementClick()"><i class="icon-cancel"></i></button>
                    </div>
                    <div class="modal-body">
                        <demurrage #managerItem (submitingManagerItem)="onSubmitingManagerItem($event)"></demurrage>
                    </div>
                </div>
            </div>
        </div>
        }
    </div>
    }

    @if (!viewIsReady || managerModel.isBusy) {
    <loader [@.disabled]="!viewIsInitialized" @fadeInOutAnimation
            [loadingMessage]="statusMessage" [showSpinner]="showSpinner || managerModel.isBusy"
            [isBusyLoader]="viewIsReady && managerModel.isBusy" [isBusyLoaderOverlay]="true">
    </loader>
    }
</div>


