@if (timeViewType === TimeViewType.Editor) {
  <div class="time-edit-container picker-frame" @fadeInOutAnimation>
    <div class="time-edit-view-container">
      <div class="time-edit-view">
        <div class="time-parts-wrapper">
          <div class="time-part-container">
            <button type="button" class="ripple-effect ripple-center ripple-inverse-color" (mousedown)="onHoursSpinUpButtonMouseDown($event)"
              (mouseup)="clearSpinPress()" (mouseleave)="clearSpinPress()" (touchstart)="onHoursSpinUpButtonTouchStart($event)" (touchmove)="clearSpinPress()"
              (touchend)="clearSpinPress()">
              <i class="icon-arrow-up"></i>
            </button>
            <numeric-string-input [minValue]="time24Hours ? 0 : 1" [maxValue]="time24Hours ? 23 : 12" [maxLength]="2" [(ngModel)]="hours"
              (wheel)="onHoursInputWheel($event)">
            </numeric-string-input>
            <button type="button" class="ripple-effect ripple-center ripple-inverse-color" (mousedown)="onHoursSpinDownButtonMouseDown($event)"
              (mouseup)="clearSpinPress()" (mouseleave)="clearSpinPress()" (touchstart)="onHoursSpinDownButtonTouchStart($event)" (touchmove)="clearSpinPress()"
              (touchend)="clearSpinPress()">
              <i class="icon-arrow-down"></i>
            </button>
          </div>
          <span class="seperator select-none icon-colon"></span>
          <div class="time-part-container">
            <button type="button" class="ripple-effect ripple-center ripple-inverse-color" (mousedown)="onMinutesSpinUpButtonMouseDown($event)"
              (mouseup)="clearSpinPress()" (mouseleave)="clearSpinPress()" (touchstart)="onMinutesSpinUpButtonTouchStart($event)" (touchmove)="clearSpinPress()"
              (touchend)="clearSpinPress()">
              <i class="icon-arrow-up"></i>
            </button>
            <numeric-string-input [minValue]="0" [maxValue]="59" [maxLength]="2" [(ngModel)]="minutes" (wheel)="onMinutesInputWheel($event)"></numeric-string-input>
            <button type="button" class="ripple-effect ripple-center ripple-inverse-color" (mousedown)="onMinutesSpinDownButtonMouseDown($event)"
              (mouseup)="clearSpinPress()" (mouseleave)="clearSpinPress()" (touchstart)="onMinutesSpinDownButtonTouchStart($event)" (touchmove)="clearSpinPress()"
              (touchend)="clearSpinPress()">
              <i class="icon-arrow-down"></i>
            </button>
          </div>
        </div>
        @if (!time24Hours) {
          <div class="selection-container">
            <label class="radio-container ripple-effect ripple-center ripple-inverse-color">
              <input type="radio" name="meridiem-type" [value]="MeridiemType.AM" [(ngModel)]="meridiemType" />
              <span class="radio-checkmark"></span>
              <span class="radio-text">{{ meridiemValues[MeridiemType.AM] }}</span>
            </label>
            <label class="radio-container ripple-effect ripple-center ripple-inverse-color">
              <input type="radio" name="meridiem-type" [value]="MeridiemType.PM" [(ngModel)]="meridiemType" />
              <span class="radio-checkmark"></span>
              <span class="radio-text">{{ meridiemValues[MeridiemType.PM] }}</span>
            </label>
          </div>
        }
      </div>
    </div>
    <div class="switch-view-container">
      <button type="button" class="image-button ripple-effect ripple-center ripple-inverse-color" (click)="timeViewType = TimeViewType.ClockHours">
        <i class="icon-clock"></i>
      </button>
    </div>
  </div>
}
@if (timeViewType !== TimeViewType.Editor) {
  <div class="time-clock-container picker-frame" @fadeInOutAnimation>
    <div class="time-parts-selection-container">
      <div class="selection-container">
        <label class="radio-container ripple-effect ripple-center ripple-inverse-color">
          <input type="radio" name="clock-view-type" [value]="TimeViewType.ClockHours" [(ngModel)]="timeViewType" />
          <span class="radio-checkmark"></span>
          <span class="radio-text">{{ hours }}</span>
        </label>
        <span class="seperator select-none icon-colon"></span>
        <label class="radio-container ripple-effect ripple-center ripple-inverse-color">
          <input type="radio" name="clock-view-type" [value]="TimeViewType.ClockMinutes" [(ngModel)]="timeViewType" />
          <span class="radio-checkmark"></span>
          <span class="radio-text">{{ minutes }}</span>
        </label>
      </div>
      @if (!time24Hours) {
        <div class="selection-container meridiem-type">
          <label class="radio-container ripple-effect ripple-center ripple-inverse-color">
            <input type="radio" name="clock-meridiem-type" [value]="MeridiemType.AM" [(ngModel)]="meridiemType" />
            <span class="radio-checkmark"></span>
            <span class="radio-text">{{ meridiemValues[MeridiemType.AM] }}</span>
          </label>
          <span class="seperator select-none">|</span>
          <label class="radio-container ripple-effect ripple-center ripple-inverse-color">
            <input type="radio" name="clock-meridiem-type" [value]="MeridiemType.PM" [(ngModel)]="meridiemType" />
            <span class="radio-checkmark"></span>
            <span class="radio-text">{{ meridiemValues[MeridiemType.PM] }}</span>
          </label>
        </div>
      }
    </div>
    <div class="time-clock-views-container">
      @if (timeViewType === TimeViewType.ClockHours) {
        <canvas #clockHoursCanvas (mousedown)="onClockHoursCanvasMouseDown($event)" (mousemove)="onClockHoursCanvasMouseMove($event)"
          (mouseup)="onClockHoursCanvasMouseUp()" (touchstart)="onClockHoursCanvasTouchStart($event)" (touchmove)="onClockHoursCanvasTouchMove($event)"
          (touchend)="onClockHoursCanvasTouchEnd()" (wheel)="onClockHoursWheel($event)"
          @fadeZoomInOutAnimation>
        </canvas>
      }
      @if (timeViewType === TimeViewType.ClockMinutes) {
        <canvas #clockMinutesCanvas (mousedown)="onClockMinutesCanvasMouseDown($event)" (mousemove)="onClockMinutesCanvasMouseMove($event)"
          (mouseup)="onClockMinutesCanvasMouseUp()" (touchstart)="onClockMinutesCanvasTouchStart($event)" (touchmove)="onClockMinutesCanvasTouchMove($event)"
          (wheel)="onClockMinutesWheel($event)"
          @fadeZoomInOutAnimation>
        </canvas>
      }
    </div>
    <div class="switch-view-container">
      <button type="button" class="image-button ripple-effect ripple-center ripple-inverse-color" (click)="timeViewType = TimeViewType.Editor">
        <i class="icon-keyboard"></i>
      </button>
    </div>
  </div>
}

