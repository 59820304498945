import Supercluster from "supercluster";
import { ICGIMarkerLocation } from "../../../base/classes/cgi-marker";

export class GoogleMapUtils
{
    // #region Constants

    private static readonly MAP_TOO_CLOSE_MIN_DISTANCE: number = 3;
    private static readonly EARTH_RADIUS: number = 6371000;

    // #endregion

    // #region Public Methods

    public static distanceBetweenLocations(p1: google.maps.LatLngLiteral, p2: google.maps.LatLngLiteral): number
    {
        const diameterLat: number = ((p2.lat - p1.lat) * Math.PI) / 180;
        const diameterLon: number = ((p2.lng - p1.lng) * Math.PI) / 180;
        const sinDiameterLat: number = Math.sin(diameterLat / 2);
        const sinDiameterLon: number = Math.sin(diameterLon / 2);
        const squareHalfChordLengthPoints: number = sinDiameterLat * sinDiameterLon + Math.cos((p1.lat * Math.PI) / 180) *
            Math.cos((p2.lat * Math.PI) / 180) * sinDiameterLat * sinDiameterLon;
        const angularDistanceRadians: number = 2 * Math.atan2(Math.sqrt(squareHalfChordLengthPoints), Math.sqrt(1 - squareHalfChordLengthPoints));
        return this.EARTH_RADIUS * angularDistanceRadians;
    }

    public static isBoundsAroundSameLocation(bounds: google.maps.LatLngBounds): boolean
    {
        return this.distanceBetweenLocations({ lat: bounds.getNorthEast().lat()!, lng: bounds.getNorthEast().lng()! },
            { lat: bounds.getSouthWest().lat()!, lng: bounds.getSouthWest().lng()! }) <= this.MAP_TOO_CLOSE_MIN_DISTANCE
    }

    public static getCoordinatesDistanceAtZoomLevel(map: google.maps.Map, coordinate1: google.maps.LatLngLiteral, coordinate2: google.maps.LatLngLiteral,
        mapZoom: number): number
    {
        const projection: google.maps.Projection | undefined = map.getProjection();
        if (projection === undefined || mapZoom === undefined)
        {
            return 0;
        }

        const coordinate1Point: google.maps.Point | null = projection.fromLatLngToPoint(coordinate1);
        if (coordinate1Point === null)
        {
            return 0;
        }

        const coordinate2Point: google.maps.Point | null = projection.fromLatLngToPoint(coordinate2);
        if (coordinate2Point === null)
        {
            return 0;
        }

        return this.getPointsDistanceAtZoomLevel(coordinate1Point, coordinate2Point, mapZoom)
    }

    public static getPointsDistanceAtZoomLevel(point1: google.maps.Point, point2: google.maps.Point, zoom: number): number
    {
        const pixelSize: number = Math.pow(2, -zoom);
        return Math.sqrt((point1.x - point2.x) * (point1.x - point2.x) + (point1.y - point2.y) * (point1.y - point2.y)) / pixelSize;
    }

    public static getMarkerLocation(marker: google.maps.marker.AdvancedMarkerElement | ICGIMarkerLocation): google.maps.LatLngLiteral
    {
        return (marker instanceof google.maps.marker.AdvancedMarkerElement) ?
            marker.position! as google.maps.LatLngLiteral : (marker as ICGIMarkerLocation).location;
    }

    public static getMarkerSuperclusterPoints(markers: (google.maps.marker.AdvancedMarkerElement | ICGIMarkerLocation)[]):
        Array<Supercluster.PointFeature<Supercluster.AnyProps>>
    {
        return markers.map((marker: google.maps.marker.AdvancedMarkerElement | ICGIMarkerLocation) =>
        {
            const location: google.maps.LatLngLiteral = this.getMarkerLocation(marker);
            const coordinates: [number | (() => number), number | (() => number)] = [location.lng, location.lat];
            return { type: "Feature" as const, geometry: { type: "Point" as const, coordinates }, properties: { marker } };
        }) as Array<Supercluster.PointFeature<Supercluster.AnyProps>>;
    }

    // #endregion
}