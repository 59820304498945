import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { UserInsightPermission } from '../user/login/model/login-model.class';
import { PowerBiService } from '../services/powerbi.service';
import { fadeInOutAnimation, fadeOutAnimation } from '../animations/fade.animation';
import { PowerBIBaseComponent } from '../base/components/powerbi-base.component';
import { Utils } from '../utils/utils';
import { Router } from '@angular/router';
import { LoaderComponent } from '../controls/loader/loader.component';
import { TooltipDirective } from '../directives/tooltip.directive';
import { PowerBIEmbedModule } from 'powerbi-client-angular';
import { RouteType } from '../utils/globals';

@Component({
    selector: 'analytics',
    templateUrl: './analytics.component.html',
    styleUrls: ['../base/styles/powerbi-base.component.css', './analytics.component.css'],
    animations: [fadeOutAnimation, fadeInOutAnimation],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [PowerBIEmbedModule, TooltipDirective, LoaderComponent]
})
export class AnalyticsComponent extends PowerBIBaseComponent implements OnInit
{
    // #region Constatns

    private readonly PAGES_WITH_DETAILS_BUTTON = ['Shipments', 'progress', 'measurements', 'events', 'Route Details', 'Stops'];

    // #endregion

    // #region Private Members

    private _showNavigationButton: boolean = false;
    private _isNavigationButtonDisabled: boolean = true;
    private _selectedShipmentKey: string | null = null;

    // #endregion

    // #region Properties

    public get showNavigationButton(): boolean
    {
        return this._showNavigationButton;
    }

    public get isNavigationButtonDisabled(): boolean
    {
        return this._isNavigationButtonDisabled;
    }

    // #endregion

    // #region Constructors

    constructor(_powerBiService: PowerBiService, _changeDetectorRef: ChangeDetectorRef, private _router: Router)
    {
        super(_powerBiService, _changeDetectorRef);
        this._reportName = UserInsightPermission.Analytics;
    }

    // #endregion

    // #region Event Handlers

    public override ngOnInit(): void
    {
        super.ngOnInit();

        this._eventHandlers.set('dataSelected', (event: any) =>
        {
            this._selectedShipmentKey = null;
            if (event.detail.dataPoints.length === 1)
            {
                for (const target of event.detail.dataPoints[0].identity)
                {
                    if (target && target.target && target.target.column === 'shipment_key')
                    {
                        this._selectedShipmentKey = target.equals;
                        break;
                    }
                }

                if (this._selectedShipmentKey !== null)
                {
                    this._isNavigationButtonDisabled = false;
                    this._changeDetectorRef.detectChanges();
                }
            }
            else
            {
                this._isNavigationButtonDisabled = true;
                this._changeDetectorRef.detectChanges();
            }
        });

        this._eventHandlers.set('pageChanged', (event: any) =>
        {
            if (!Utils.isNullOrUndefined(event) && !Utils.isNullOrUndefined(event.detail))
            {
                this._showNavigationButton = this.PAGES_WITH_DETAILS_BUTTON.indexOf(event.detail.newPage.displayName) >= 0;
                this._isNavigationButtonDisabled = true;
                this._changeDetectorRef.detectChanges();
            }
        });
    }

    public onNavigationButtonClick(): void
    {
        if (this._selectedShipmentKey !== null)
        {
            this._router.navigate([`${RouteType.ShipmentMonitoringArchive}/${this._selectedShipmentKey}`]);
        }
    }

    // #endregion
}
