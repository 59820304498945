import { Directive, ElementRef, HostListener, Input, NgZone, OnInit, Renderer2 } from '@angular/core';
import { ElementResizedDirective, ResizedEvent } from './element-resized.directive';
import { Utils } from '../utils/utils';

@Directive({
    selector: 'textarea[autosize]',
    host: {
        'rows': '1'
    },
    standalone: true
})
export class TextAreaAutosizeDirective extends ElementResizedDirective implements OnInit
{
    // #region Private Members

    private _lastHeight: number = 0;
    private _letterHeight: number = 0;

    private _documentCanvas: HTMLCanvasElement;
    private _documentCanvasContext: CanvasRenderingContext2D | null = null;

    // #endregion

    // #region Inputs

    @Input() minRows: number = 3;
    @Input() maxRows: number | null = null;

    // #endregion

    // #region Constructors

    constructor(_elementRef: ElementRef<HTMLElement>, private _renderer: Renderer2, _ngZone: NgZone)
    {
        super(_elementRef, _ngZone);

        this._documentCanvas = this._renderer.createElement('canvas');
        this._documentCanvasContext = this._documentCanvas.getContext('2d');

        this.updateLetterHeight();
    }

    // #endregion

    // #region Event Handlers

    public ngOnInit(): void
    {
        (this._elementRef.nativeElement as HTMLTextAreaElement).rows = this.minRows;

        setTimeout(() => this.resize({ traget: this._elementRef.nativeElement, isFontResized: false }));
    }

    @HostListener('input') onInput(): void
    {
        this.resize({ traget: this._elementRef.nativeElement, isFontResized: false });
    }

    protected override onElementResize(): void
    {
        this._lastHeight = 0;

        super.onElementResize();
    }

    // #endregion

    // #region Protected Methods

    protected override resize(event: ResizedEvent): void
    {
        if (event.isFontResized)
        {
            this.updateLetterHeight();
        }

        this._renderer.setStyle(this._elementRef.nativeElement, 'height', 'auto');

        let height: number = this._elementRef.nativeElement.scrollHeight + (this._elementRef.nativeElement.offsetHeight - this._elementRef.nativeElement.clientHeight);

        if (this._lastHeight !== height)
        {
            const cssStyleDeclaration: CSSStyleDeclaration = getComputedStyle(this._elementRef.nativeElement);

            const frameHeight: number = parseFloat(cssStyleDeclaration.borderTopWidth) + parseFloat(cssStyleDeclaration.borderBottomWidth) +
                parseFloat(cssStyleDeclaration.paddingTop) + parseFloat(cssStyleDeclaration.paddingBottom);

            height = Math.max(this._letterHeight * this.minRows + frameHeight, height);
            if (this.maxRows !== null)
            {
                height = Math.min(this._letterHeight * this.maxRows + frameHeight, height);
            }

            this._lastHeight = height;
        }

        this._renderer.setStyle(this._elementRef.nativeElement, 'height', `${height}px`);
    }

    // #endregion

    // #region Private Methods

    private updateLetterHeight(): void
    {
        if (this._documentCanvasContext !== null)
        {
            Utils.updateCanvasContextFont(this._documentCanvasContext);
        }
    }

    // #endregion
}