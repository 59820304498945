import { Directive, Input, OnDestroy } from "@angular/core";
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from "@angular/forms";
import { default as moment } from "moment";

@Directive({
    selector: '[dateRangeValidator]',
    providers: [{ provide: NG_VALIDATORS, useExisting: DateRangeValidatorDirective, multi: true }],
    standalone: true
})
export class DateRangeValidatorDirective implements Validator, OnDestroy
{
    // #region Private Constants

    private readonly DATE_RANGE_VALIDATOR_ERROR_NAME: string = 'dateRangeError';
    private readonly DATE_MAX_VALIDATOR_ERROR_NAME: string = 'dateMaxError';

    // #endregion

    // #region Private Members

    private _control: AbstractControl | null = null;

    // #endregion

    // #region Inputs

    @Input('isFutureDate') isFutureDate: boolean | null = null;

    @Input('dateRangeMin') dateRangeMinControlName: string | null = null;
    @Input('dateRangeMax') dateRangeMaxControlName: string | null = null;

    @Input('dateRangeMinValue') dateRangeMinValue: Date | null = null;
    @Input('dateRangeMaxValue') dateRangeMaxValue: Date | null = null;

    // #endregion

    // #region Validator Interface

    public validate(control: AbstractControl): ValidationErrors | null
    {
        this._control = control;

        const controlDate: Date | null = this.getControlDate(control);

        if (this.dateRangeMinControlName !== null && control.parent !== null)
        {
            const fromControl: AbstractControl | null = control.parent.get(this.dateRangeMinControlName);
            const fromControlDate: Date | null = this.getControlDate(fromControl);
            if (fromControlDate !== null && controlDate !== null)
            {
                if (moment(fromControlDate).isSameOrAfter(moment(controlDate), "minute"))
                {
                    const validationErrors: ValidationErrors = {};
                    validationErrors[this.DATE_RANGE_VALIDATOR_ERROR_NAME] = true;

                    fromControl?.setErrors(validationErrors);
                    return validationErrors;
                }

                this.updateControlMaxDateError(fromControl, false);
            }
        }
        else if (this.dateRangeMaxControlName !== null && control.parent !== null)
        {
            const toControl: AbstractControl | null = control.parent.get(this.dateRangeMaxControlName);
            const toControlDate: Date | null = this.getControlDate(toControl);
            if (toControlDate !== null && controlDate !== null)
            {
                if (moment(controlDate).isSameOrAfter(moment(toControlDate), "minute"))
                {
                    const validationErrors: ValidationErrors = {};
                    validationErrors[this.DATE_RANGE_VALIDATOR_ERROR_NAME] = true;

                    toControl?.setErrors(validationErrors);
                    return validationErrors;
                }

                this.updateControlMaxDateError(toControl, false);
            }
        }

        if (this.dateRangeMinValue != null && controlDate !== null)
        {
            if (moment(this.dateRangeMinValue).isSameOrAfter(moment(controlDate), "minute"))
            {
                const validationErrors: ValidationErrors = {};
                validationErrors[this.DATE_RANGE_VALIDATOR_ERROR_NAME] = true;

                return validationErrors;
            }
        }
        else if (this.dateRangeMaxValue != null && controlDate !== null)
        {
            if (moment(controlDate).isSameOrAfter(moment(this.dateRangeMaxValue), "minute"))
            {
                const validationErrors: ValidationErrors = {};
                validationErrors[this.DATE_RANGE_VALIDATOR_ERROR_NAME] = true;

                return validationErrors;
            }
        }

        return this.updateControlMaxDateError(control, true);
	}

    // #endregion

    // #region Events Handlers

    public ngOnDestroy(): void
    {
        if (this.dateRangeMinControlName !== null && this._control !== null && this._control.parent !== null)
        {
            const fromControl: AbstractControl | null = this._control.parent.get(this.dateRangeMinControlName);
            if (fromControl !== null && (fromControl.hasError(this.DATE_RANGE_VALIDATOR_ERROR_NAME) || fromControl.hasError(this.DATE_MAX_VALIDATOR_ERROR_NAME)))
            {
                fromControl.setErrors(null);
                fromControl.updateValueAndValidity();
            }
        }
    }

    // #endregion

    // #region Private Methods

    private getControlDate(control: AbstractControl | null): Date | null
    {
        return control !== null && control.value !== null ? (typeof control.value === 'string' ? new Date(control.value) : control.value) : null;
    }

    private updateControlMaxDateError(control: AbstractControl | null, isSourceControl: boolean): ValidationErrors | null
    {
        if (control === null)
        {
            return null;
        }

        const controlDate: Date | null = this.getControlDate(control);

        if (controlDate === null || isSourceControl && this.isFutureDate === null || isSourceControl && this.isFutureDate === true && !control.dirty ||
            isSourceControl && this.isFutureDate === true && control.dirty && moment(controlDate).isSameOrAfter(moment(), "minute") ||
            isSourceControl && this.isFutureDate === false && moment(controlDate).isSameOrBefore(moment(), "minute") ||
            !isSourceControl && moment(controlDate).isSameOrBefore(moment(), "minute"))
        {
            if (control.hasError(this.DATE_RANGE_VALIDATOR_ERROR_NAME) || control.hasError(this.DATE_MAX_VALIDATOR_ERROR_NAME))
            {
                control.setErrors(null);
                control.updateValueAndValidity();
            }
        }
        else if (controlDate !== null && controlDate !== undefined)
        {
            const validationErrors: ValidationErrors = {};
            validationErrors[this.DATE_MAX_VALIDATOR_ERROR_NAME] = true;

            if (isSourceControl)
            {
                return validationErrors;
            }

            control.setErrors(validationErrors);
        }

        return null;
    }

    // #endregion
}

