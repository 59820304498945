import
    {
        Directive,
        Output,
        EventEmitter,
        HostBinding,
        HostListener,
        Input
    } from '@angular/core';

@Directive({
    selector: '[dragDrop]',
    standalone: true
})
export class DragDropDirective
{
    // #region Properties

    @HostBinding('class.disabled') @Input() disabled: boolean = false;

    // #endregion

    // #region Outputs

    @Output() filesDropped = new EventEmitter<any>();

    // #endregion

    // #region Event Handlers

    @HostListener('dragover', ['$event']) onDragOver(event: DragEvent)
    {
        event.preventDefault();
        event.stopPropagation();
    }

    @HostListener('dragleave', ['$event']) public onDragLeave(event: DragEvent)
    {
        event.preventDefault();
        event.stopPropagation();
    }

    @HostListener('drop', ['$event']) public onDrop(event: DragEvent)
    {
        event.preventDefault();
        event.stopPropagation();

        if (this.disabled)
        {
            return;
        }

        const files: FileList | undefined = event.dataTransfer?.files;
        if (files !== undefined && files.length > 0)
        {
            this.filesDropped.emit(files);
        }
    }

    // #endregion
}
