import { Component, Input } from "@angular/core";

@Component({
    selector: 'text-characters-counter',
    template: `<div class="characters-counter select-none"><span>Characters left: </span>
                <span>{{ (maxLength ?? 0) - (text !== null && text !== undefined ? text.length : 0) }}</span>
              </div>`,
    standalone: true
})
export class TextChractersCounterComponent
{
    // #region Inputs

    @Input() public text!: string | null;
    @Input() public maxLength!: number | null;

    // #endregion
}
