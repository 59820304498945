import { Component, Input, HostListener, OnInit, Output, EventEmitter, ViewEncapsulation, Directive, TemplateRef, ContentChild, ElementRef } from '@angular/core';
import { fadeInOutAnimation, fadeSlideDownInOutAnimation } from '../../animations/fade.animation';
import { ModalBodyAlignType } from './services/modal-message.service';
import { NgTemplateOutlet } from '@angular/common';

@Directive({
    selector: '[modal-header-template]',
    standalone: true
})
export class ModalHeaderTemplateDirective
{
    constructor(public template: TemplateRef<any>) { }
}

@Directive({
    selector: '[modal-body-template]',
    standalone: true
})
export class ModalBodyTemplateDirective
{
    constructor(public template: TemplateRef<any>) { }
}

@Directive({
    selector: '[modal-footer-template]',
    standalone: true
})
export class ModalFooterTemplateDirective
{
    constructor(public template: TemplateRef<any>) { }
}

export enum ModalType
{
    Regular,
    Error
}

export enum ModalButtonType
{
    Close,
    YesNo,
    OkCancel
}

export enum ModalIconType
{
    Info,
    Error,
    Question,
    Approve,
    None
}

export enum ModalResultType
{
    Close,
    Yes,
    No
}

@Component({
    selector: 'modal-message',
    templateUrl: './modal-message.component.html',
    styleUrls: ['./modal-message.component.css'],
    animations: [fadeInOutAnimation, fadeSlideDownInOutAnimation],
    encapsulation: ViewEncapsulation.None,
    standalone: true,
    imports: [NgTemplateOutlet]
})
export class ModalMessageComponent implements OnInit
{
    // #region Private Members

    private _isVisible = false;
    private _modalResult: ModalResultType = ModalResultType.Close;

    // #endregion

    // #region Properties

    @ContentChild(ModalHeaderTemplateDirective, { read: TemplateRef }) public modalHeaderTemplateRef!: TemplateRef<any>;
    @ContentChild(ModalBodyTemplateDirective, { read: TemplateRef }) public modalBodyTemplateRef!: TemplateRef<any>;
    @ContentChild(ModalFooterTemplateDirective, { read: TemplateRef }) public modalFooterTemplateRef!: TemplateRef<any>;

    public get isVisible()
    {
        return this._isVisible;
    }

    public get ModalButtonType()
    {
        return ModalButtonType;
    }

    public get ModalResultType()
    {
        return ModalResultType;
    }

    public get ModalBodyAlignType()
    {
        return ModalBodyAlignType;
    }

    // #endregion

    // #region Inputs

    @Input() public modalIcon: ModalIconType | null = null;
    @Input() public modalType: ModalType = ModalType.Regular;
    @Input() public modalButton: ModalButtonType = ModalButtonType.Close;
    @Input() public modalTitle: string = '';
    @Input() public modalMessage: string = '';
    @Input() public modalIconClassName: string = '';
    @Input() public modalButtonsNames: string[] = [];
    @Input() public modalContentTemplateRef?: TemplateRef<any>;
    @Input() public modalContentTemplateData?: any;
    @Input() public modlaBodyAlign: ModalBodyAlignType = ModalBodyAlignType.Center;


    // #endregion

    // #region Events

    @Output() close: EventEmitter<ModalResultType> = new EventEmitter();

    // #endregion

    // #region Constructors

    constructor(private _elementRef: ElementRef<HTMLElement>)
    {
    }

    // #endregion

    // #region Event Handlers

    public ngOnInit(): void
    {
        if (this.modalType === ModalType.Regular)
        {
            this._elementRef.nativeElement.classList.add('type-info');
        }
        else if (this.modalType === ModalType.Error)
        {
            this._elementRef.nativeElement.classList.add('type-danger');
        }

        if (this.modalButtonsNames.length === 0)
        {
            if (this.modalButton === ModalButtonType.Close)
            {
                this.modalButtonsNames = ['OK'];
            }
            else if (this.modalButton === ModalButtonType.YesNo)
            {
                this.modalButtonsNames = ['No', 'Yes'];
            }
            else if (this.modalButton === ModalButtonType.OkCancel)
            {
                this.modalButtonsNames = ['Cancel', 'OK'];
            }
        }

        if (this.modalIconClassName.length === 0)
        {
            let modalIcon = this.modalIcon;
            if (modalIcon === null)
            {
                modalIcon = this.modalType === ModalType.Regular ? ModalIconType.Info : ModalIconType.Error;
            }

            switch (modalIcon)
            {
                case ModalIconType.Info:
                    {
                        this.modalIconClassName = 'icon-info';
                    }
                    break;

                case ModalIconType.Error:
                    {
                        this.modalIconClassName = 'icon-error';
                    }
                    break;

                case ModalIconType.Question:
                    {
                        this.modalIconClassName = 'icon-question';
                    }
                    break;

                case ModalIconType.Approve:
                    {
                        this.modalIconClassName = 'icon-radio-check';
                    }
                    break;
            }
        }

        this._isVisible = true;
    }

    @HostListener('document:keyup.escape') onKeyUpHandler(): void
    {
        this.closeModal(ModalResultType.Close);
    }

    public onButtonClick(modalResult: ModalResultType): void
    {
        this.closeModal(modalResult);
    }

    public onShowAnimationDone(): void
    {
        if (!this._isVisible)
        {
            this.close.emit(this._modalResult);
        }
    }

    // #endregion

    // #region Public Methods

    public closeModal(modalResult: ModalResultType = ModalResultType.Close): void
    {
        this._modalResult = modalResult;
        this._isVisible = false;
    }

    // #endregion
}