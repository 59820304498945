export class AttachmentFileData
{
    public attachmentFile!: File;
    public attachmentFileUrl!: string;
}

export class AttachmentsInfo
{
    public uploadAttachmentsFilesData: AttachmentFileData[] = [];
    public alreadyUploadedAttachmentsFileNames: string[] = [];
    public deleteAttachmentsFileNames: string[] = [];
    public attachmentsFilesData: AttachmentFileData[] = [];
    public availableAttachmentSizeToAdd: number = 0;
}

