import { Constants } from "../../../utils/globals";

export class CGIMarkerIconFactory
{
    // #region Constants

    public static readonly CGIMARKER_PIN_CLASSNAME: string = 'cgimarker-pin';

    protected readonly CGIMARKER_BACKGROUND_CLASSNAME: string = 'cgimarker-background';
    protected readonly CGIMARKER_DATA_CLASSNAME: string = 'cgimarker-data';
    protected readonly CGIMARKER_CIRCLE_CLASSNAME: string = 'cgimarker-circle';
    protected readonly CGIMARKER_CLASSNAME: string = 'cgimarker';
    protected readonly CGIMARKER_CLUSTER_CLASSNAME: string = 'cgimarker-cluster';

    protected readonly ARROW_PATH: string = 'M112.5,23.9L13.8,220.5c-9.4,18.7-0.6,29.6,19.7,24.3l96.1-25.2l93,25c20.2,5.4,29.1-5.4,19.9-24.2L146.2,24.1C137,5.3,121.9,5.2,112.5,23.9z';
    protected readonly ARROW_SVG_WIDTH: number = 256;
    protected readonly ARROW_SVG_HEIGHT: number = 256;
    protected readonly PIN_PATH: string = 'M 12.474665,36.640181 C 10.889197,34.202473 9.2203694,32.219117 7.6349773,30.318542 3.9218256,25.77362 0.95963909,22.22006 0.95963909,15.81589 1.0013636,7.6761813 7.7184136,1.1066004 16.020932,1.1066004 c 8.302403,0 15.019429,6.6109088 15.019429,14.7092906 0,6.40417 -2.920462,9.998932 -6.675289,14.543979 -1.543641,1.900575 -3.170759,3.883932 -4.756262,6.280312 -6.794493,9.122383 -1.265944,9.069475 -7.134145,-1e-6 z';
    protected readonly PIN_SVG_WIDTH: number = 32;
    protected readonly PIN_SVG_HEIGHT: number = 44.5;

    private readonly PIN_STROKE_WIDTH: number = 0.1;

    // #endregion

    // #region Protected Members

    protected _bodyCssStyleDeclaration: CSSStyleDeclaration;

    // #endregion

    // #region Constructors

    constructor()
    {
        this._bodyCssStyleDeclaration = getComputedStyle(document.body);
    }

    // #endregion

    // #region Public Methods

    public createCGIMarkerArrowElement(rotation: number): Element
    {
        const markerElement: HTMLElement = document.createElement("div");
        markerElement.innerHTML = this.createArrowHtmlContent(rotation);

        return markerElement;
    }

    public getCGIClusterMarkerGradient(occurrences: number[], clusterCount: number, colors: string[]): string
    {
        let gradientReference: string = '';

        const updatedOccurrences: number[] = [];
        const updatedColors: string[] = [];

        for (let occurrencesIndex: number = 0; occurrencesIndex < occurrences.length; occurrencesIndex++)
        {
            if (occurrences[occurrencesIndex] > 0)
            {
                updatedOccurrences.push(occurrences[occurrencesIndex]);
                updatedColors.push(colors[occurrencesIndex]);
            }
        }

        for (let occurrencesIndex: number = 0; occurrencesIndex < updatedOccurrences.length; occurrencesIndex++)
        {
            let eventReferenceCount: number = updatedOccurrences[occurrencesIndex];
            if (eventReferenceCount === 0)
            {
                continue;
            }

            for (let i: number = 0; i < occurrencesIndex; i++)
            {
                eventReferenceCount += updatedOccurrences[i];
            }

            gradientReference += `${gradientReference.length > 0 ? ', ' : ''}${''
                }${this._bodyCssStyleDeclaration.getPropertyValue(updatedColors[occurrencesIndex])}${''
                }${gradientReference.length > 0 || occurrencesIndex === updatedOccurrences.length - 1 ? ' 0 ' : ' '}${''
                }${ eventReferenceCount / (clusterCount ?? 1) * 100 }%`
        }

        return gradientReference;
    }

    public createArrowHtmlContent(rotation: number): string
    {
        return `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${this.ARROW_SVG_WIDTH} ${this.ARROW_SVG_HEIGHT}" style="transform: rotate(${rotation}deg);">${''
            }<path d="${this.ARROW_PATH}"/>${''
            }</svg>`;
    }

    public createPinHtmlContent(backgroundColor: string, strokeWidth: number, iconClassName: string): string
    {
        return `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${this.PIN_SVG_WIDTH} ${this.PIN_SVG_HEIGHT}">${''
            }<path fill="${backgroundColor}" stroke-width="${strokeWidth}" stroke="#000000" d="${this.PIN_PATH}"/>${''
            }</svg>${''
            }<div class="${this.CGIMARKER_DATA_CLASSNAME}"><i class="${iconClassName}"><i></div>`;
    }

    public createClusterPinHtmlContent(background: string, clusterCount: number, strokeWidth: number = this.PIN_STROKE_WIDTH): string
    {
        const clipPinPathId: string = 'clipPinPath';
        if (document.body.querySelector(`#${clipPinPathId}`) === null)
        {
            const svgPinClipPathElement: HTMLElement = document.createElement('svg');
            document.body.appendChild(svgPinClipPathElement);

            svgPinClipPathElement.outerHTML = `<svg xmlns="http://www.w3.org/2000/svg" width="0" height="0" style="overflow: hidden; top: -100%; left: -100%; position: absolute; opacity: 0;">${''
                }<defs>${''
                }<clipPath id="${clipPinPathId}" clipPathUnits="objectBoundingBox" transform="scale(${1 / this.PIN_SVG_WIDTH}, ${1 / this.PIN_SVG_HEIGHT})"><path d="${this.PIN_PATH}"/></clipPath>${''
                }</defs>`;
        }

        return `<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${this.PIN_SVG_WIDTH} ${this.PIN_SVG_HEIGHT}">${''
            }<path stroke-width="${strokeWidth}" stroke="#000000" d="${this.PIN_PATH}"/>${''
            }</svg>${''
            }<div class="${this.CGIMARKER_BACKGROUND_CLASSNAME}" style="background: ${background}; clip-path: url(#${clipPinPathId});"></div>${''
            }<div class="${this.CGIMARKER_DATA_CLASSNAME}">${clusterCount}</div>`;
    }

    public createCGIMarkerElement(backgroundColor: string, markerClassName: string, iconClassName: string, strokeWidth: number = this.PIN_STROKE_WIDTH): Element
    {
        const markerElement: HTMLElement = document.createElement("div");
        markerElement.className = `${markerClassName} ${CGIMarkerIconFactory.CGIMARKER_PIN_CLASSNAME} ${Constants.ACTIVE_CLASSNAME}`;
        markerElement.innerHTML = this.createPinHtmlContent(backgroundColor, strokeWidth, iconClassName);

        return markerElement;
    }

    // #endregion
}