import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, Observer, forkJoin } from "rxjs";
import { Constants, IApiResponse } from "../../../utils/globals";
import { HttpHelper } from "../../../utils/http-helper";
import
{
    DisplayShipmentMonitoringEvent, ShipmentMonitoringOverview, ShipmentMonitoringPlanSegment,
    DisplayShipmentMonitoringPlanSegment, ShipmentMonitoringTelemetryReasonType, DisplayShipmentMonitoringPlanSubSegment, MonitoringEventTypeOverview,
    ShipmentMonitoringTelemetry,
    DisplayShipmentMonitoring
} from "./shipment-monitoring-model.class";
import { ShipmentsMonitoringModel } from "../../../shipments-monitoring/shipments-monitoring/model/shipments-monitoring.model";
import { DateTimeFormatType, Utils } from "../../../utils/utils";
import { HttpErrorCodes } from "../../../utils/http-error-codes";
import { CountryDetails, DisplayProperties, ManagerConstants, MonitoringUtils } from "../../../utils/monitoring-utils";
import { LoginModel } from "../../../user/login/model/login.model";
import { BaseModel } from "../../../base/classes/base-model";
import { ManagerItemStatus } from "../../../base/models/manager-base-model.class";
import { MonitoringDetailedEventType } from "../../../base/models/monitoring-items-base-model.class";

@Injectable({ providedIn: 'root' })
export class ShipmentMonitoringModel extends BaseModel
{
    // #region Constants

    private readonly G_FORCE_DECIMAL_POINTS: number = 2;

    // #endregion

    // #region Private Members

    private readonly _monitoringDetailedEventIdToEventTypeMap: Map<number, number> = new Map(
        [
            [MonitoringDetailedEventType.HighTemperature, MonitoringDetailedEventType.HighTemperature],
            [MonitoringDetailedEventType.LowTemperature, MonitoringDetailedEventType.HighTemperature],
            [MonitoringDetailedEventType.HighHumidity, MonitoringDetailedEventType.HighHumidity],
            [MonitoringDetailedEventType.LowHumidity, MonitoringDetailedEventType.HighHumidity],
            [MonitoringDetailedEventType.StrongImpact, MonitoringDetailedEventType.StrongImpact],
            [MonitoringDetailedEventType.WeakImpact, MonitoringDetailedEventType.StrongImpact],
            [MonitoringDetailedEventType.TimeBasedHighHumidity, MonitoringDetailedEventType.HighHumidity],
            [MonitoringDetailedEventType.TimeBasedLowHumidity, MonitoringDetailedEventType.HighHumidity],
            [MonitoringDetailedEventType.TimeBasedHighTemperature, MonitoringDetailedEventType.HighTemperature],
            [MonitoringDetailedEventType.TimeBasedLowTemperature, MonitoringDetailedEventType.HighTemperature],
            [MonitoringDetailedEventType.Security, MonitoringDetailedEventType.Security],
            [MonitoringDetailedEventType.Stop, MonitoringDetailedEventType.Stop],
            [MonitoringDetailedEventType.None, MonitoringDetailedEventType.None]
        ]
    );

    private _shipmentMonitoringOverview: ShipmentMonitoringOverview = new ShipmentMonitoringOverview();
    private _displayShipmentMonitoring: DisplayShipmentMonitoring = new DisplayShipmentMonitoring();
    private _displayShipmentMonitoringPlanSegments: DisplayShipmentMonitoringPlanSegment[] = [];
    private _displayShipmentMonitoringCurrentPlanSegmentIndex: number = -1;

    private _shipmentMonitoringEventTypeOverviews: MonitoringEventTypeOverview[] = [];

    private _telemetryRowIdToTelemetryMap: Map<number, ShipmentMonitoringTelemetry> = new Map();
    private _telemetryTimeToLocationMap: Map<number, google.maps.LatLngLiteral> = new Map();

    private _eventsOccurrences: number[] = [];

    // #endregion

    // #region Properties

    public selectedShipmentMonitoringPlanSegmentIndex: number | null = null;

    public get eventsOccurrences(): number[]
    {
        return this._eventsOccurrences;
    }

    public get eventsOccurrencesIsEmpty(): boolean
    {
        return this._eventsOccurrences.every((value: number) => value === 0);
    }

    public get telemetryTimeToLocationMap(): Map<number, google.maps.LatLngLiteral>
    {
        return this._telemetryTimeToLocationMap;
    }

    public get shipmentMonitoringEventTypeOverviews(): MonitoringEventTypeOverview[]
    {
        return this._shipmentMonitoringEventTypeOverviews;
    }

    public get displayShipmentMonitoring(): DisplayShipmentMonitoring
    {
        return this._displayShipmentMonitoring;
    }

    public get shipmentMonitoringOverview(): ShipmentMonitoringOverview
    {
        return this._shipmentMonitoringOverview;
    }

    public get allMonitoringItems(): DisplayShipmentMonitoring[]
    {
        return this._monitoringItemsModel.allMonitoringItems;
    }

    public get displayShipmentMonitoringCurrentPlanSegmentIndex(): number
    {
        return this._displayShipmentMonitoringCurrentPlanSegmentIndex;
    }

    public get displayShipmentMonitoringPlanSegments(): DisplayShipmentMonitoringPlanSegment[]
    {
        return this._displayShipmentMonitoringPlanSegments;
    }

    // #endregion

    // #region Constructors

    constructor(private _monitoringItemsModel: ShipmentsMonitoringModel, private _httpClient: HttpClient, private _loginModel: LoginModel)
    {
        super();
    }

    // #endregion

    // #region Public Methods

    public override clear(): void
    {
        super.clear();

        this._shipmentMonitoringOverview = new ShipmentMonitoringOverview();
        this._displayShipmentMonitoring = new DisplayShipmentMonitoring();

        this._displayShipmentMonitoringPlanSegments = [];
        this._displayShipmentMonitoringCurrentPlanSegmentIndex = -1;
        this._telemetryTimeToLocationMap.clear();
        this._shipmentMonitoringEventTypeOverviews = Array(MonitoringUtils.SHIPMENT_EVENT_TYPES_COUNT).fill({}).map(() => new MonitoringEventTypeOverview());

        this._telemetryRowIdToTelemetryMap.clear();
        this._telemetryTimeToLocationMap.clear();

        this.selectedShipmentMonitoringPlanSegmentIndex = null;
    }

    public getShipmentMonitoringOverview(shipmentKey: string, isArchived: boolean): Observable<IApiResponse>
    {
        return new Observable((observer: Observer<IApiResponse>) =>
        {
            const joinObservables =
            {
                shipmentMonitoringOverview: this._httpClient.get<ShipmentMonitoringOverview>(
                    `shipments-monitoring/shipment/${isArchived ? 'archive/' : ''}${shipmentKey}`, { headers: HttpHelper.GetHttpFormUrlencodedHeaders() })
            };

            if (!this._monitoringItemsModel.isInitialized)
            {
                (joinObservables as any).monitoringItems = this._monitoringItemsModel.getMonitoringItems();
            }

            forkJoin(joinObservables).subscribe(
                {
                    next: (join) =>
                    {
                        this._shipmentMonitoringOverview = join.shipmentMonitoringOverview;

                        this._displayShipmentMonitoring = new DisplayShipmentMonitoring();
                        Utils.copyObjectByTargetProperties(this._shipmentMonitoringOverview.shipment, this._displayShipmentMonitoring);

                        MonitoringUtils.updateDisplayShipmentMonitoringProperties(this._displayShipmentMonitoring, this._loginModel.userInfo.isDemo);

                        if (this._displayShipmentMonitoring.installDate !== null)
                        {
                            this._displayShipmentMonitoring.currentFormattedDuration = Utils.formatDuration(this._displayShipmentMonitoring.installDate, new Date());
                        }

                        this.initializeShipmentMonitoringOverview().then((result: boolean) =>
                        {
                            const message: string | undefined = result ? undefined : (this._displayShipmentMonitoring.statusId === ManagerItemStatus.Completed ?
                                '<b>Shipment\'s data is currently missing.</b><br>Please contact your account manager.' :
                                '<b>Shipment recently eneded and is transitioning from Live to Completed.</b><br>Please try again later.');

                            observer.next(
                                {
                                    isSuccess: result,
                                    message: message
                                });
                            observer.complete();
                        });
                    },
                    error: (error: HttpErrorResponse) =>
                    {
                        console.error(error);

                        observer.next(
                            {
                                isSuccess: false, message: error.status === HttpErrorCodes.NOT_FOUND ?
                                    MonitoringUtils.MISSING_SHIPMENT : Constants.DATA_SERVICE_ERROR_STRING
                            });

                        observer.complete();
                    }
                });
        });
    }

    public getRoundedGForceValue(gforce: number | null): number | null
    {
        return gforce === null ? null : Math.round(gforce * Math.pow(10, this.G_FORCE_DECIMAL_POINTS)) / Math.pow(10, this.G_FORCE_DECIMAL_POINTS)
    }

    public getUpdatedEventType(monitoringDetailedEventType: MonitoringDetailedEventType): MonitoringDetailedEventType | undefined
    {
        return this._monitoringDetailedEventIdToEventTypeMap.get(monitoringDetailedEventType);
    }

    // #endregion

    // #region Private Methods

    private syncronizeChartDataMinTime(): void
    {
        let minTime: number = 0;
        for (const shipmentMonitoringEventTypeOverview of this._shipmentMonitoringEventTypeOverviews)
        {
            if (shipmentMonitoringEventTypeOverview.telemtryChartData.length > 0 &&
                (minTime === 0 || shipmentMonitoringEventTypeOverview.telemtryChartData[0][0] < minTime))
            {
                minTime = shipmentMonitoringEventTypeOverview.telemtryChartData[0][0];
            }
        }

        for (const shipmentMonitoringEventTypeOverview of this._shipmentMonitoringEventTypeOverviews)
        {
            if (shipmentMonitoringEventTypeOverview.telemtryChartData.length > 0 && shipmentMonitoringEventTypeOverview.telemtryChartData[0][0] > minTime)
            {
                shipmentMonitoringEventTypeOverview.telemtryChartData.unshift([minTime, null]);
            }
        }
    }

    private syncronizeChartDataMaxTime(): void
    {
        let maxTime: number = 0;
        for (const shipmentMonitoringEventTypeOverview of this._shipmentMonitoringEventTypeOverviews)
        {
            if (shipmentMonitoringEventTypeOverview.telemtryChartData.length > 0 && (maxTime === 0 ||
                shipmentMonitoringEventTypeOverview.telemtryChartData[shipmentMonitoringEventTypeOverview.telemtryChartData.length - 1][0] > maxTime))
            {
                maxTime = shipmentMonitoringEventTypeOverview.telemtryChartData[shipmentMonitoringEventTypeOverview.telemtryChartData.length - 1][0];
            }
        }

        for (const shipmentMonitoringEventTypeOverview of this._shipmentMonitoringEventTypeOverviews)
        {
            if (shipmentMonitoringEventTypeOverview.telemtryChartData.length > 0 &&
                shipmentMonitoringEventTypeOverview.telemtryChartData[shipmentMonitoringEventTypeOverview.telemtryChartData.length - 1][0] < maxTime)
            {
                shipmentMonitoringEventTypeOverview.telemtryChartData.push([maxTime, null]);
            }
        }
    }

    private updateTelemtryData(shipmentMonitoringEventType: MonitoringDetailedEventType): void
    {
        for (const valuePair of this._shipmentMonitoringEventTypeOverviews[shipmentMonitoringEventType].telemtryChartData)
        {
            if (valuePair[1] !== 0)
            {
                return;
            }
        }

        this._shipmentMonitoringEventTypeOverviews[shipmentMonitoringEventType].telemtryChartData = [];
    }

    private sortTelemtries(telemetries: ShipmentMonitoringTelemetry[]): ShipmentMonitoringTelemetry[]
    {
        return telemetries.sort((a: ShipmentMonitoringTelemetry, b: ShipmentMonitoringTelemetry) =>
            (a.telemetryTime !== null ? a.telemetryTime.getTime() : 0) - (b.telemetryTime !== null ? b.telemetryTime.getTime() : 0))
    }

    private updateShipmentMonitoringEventTypeOverview(eventType: MonitoringDetailedEventType): void
    {
        this._shipmentMonitoringEventTypeOverviews[eventType].telemetryTitle =
            MonitoringUtils.SHIPMENT_EVENTS_DISPLAY_PROPERTIES[eventType].tooltip;

        const displayProperties: DisplayProperties = MonitoringUtils.getUpdatedEventDisplayPropertiesByDeviceType(eventType,
            this._displayShipmentMonitoring.deviceType);

        this._shipmentMonitoringEventTypeOverviews[eventType].telemetryUnits = displayProperties.units!;
        this._shipmentMonitoringEventTypeOverviews[eventType].telemetryValueTitle = displayProperties.tooltip;
    }

    private analyzeShipmentMonitoringTelemetries(): void
    {
        this._telemetryRowIdToTelemetryMap.clear();

        this._shipmentMonitoringOverview.telemetries = this.sortTelemtries(this._shipmentMonitoringOverview.telemetries);

        for (const telemetry of this._shipmentMonitoringOverview.telemetries)
        {
            if (telemetry.telemetryTime !== null && telemetry.latitude !== null && telemetry.longitude !== null)
            {
                this._telemetryTimeToLocationMap.set(telemetry.telemetryTime.getTime(), { lat: telemetry.latitude, lng: telemetry.longitude });
            }

            if (telemetry.telemetryPlanKey !== null)
            {
                for (const displayShipmentMonitoringPlanSegment of this._displayShipmentMonitoringPlanSegments)
                {
                    if (telemetry.telemetryPlanKey === displayShipmentMonitoringPlanSegment.telemetryKey)
                    {
                        displayShipmentMonitoringPlanSegment.telemetries.push(telemetry);

                        if (telemetry.reasonId !== null && telemetry.reasonId >= ShipmentMonitoringTelemetryReasonType.ArrivalToWaypoint &&
                            ShipmentMonitoringTelemetryReasonType[telemetry.reasonId] !== undefined)
                        {
                            const subSegment: DisplayShipmentMonitoringPlanSubSegment = new DisplayShipmentMonitoringPlanSubSegment();
                            subSegment.description = Utils.getEnumParsedDescription(ShipmentMonitoringTelemetryReasonType, telemetry.reasonId).toLowerCase();
                            subSegment.description = `${subSegment.description.charAt(0).toUpperCase()}${subSegment.description.slice(1)}`;
                            subSegment.date = telemetry.telemetryTime;
                            subSegment.eventsOccurrences = Array(MonitoringUtils.SHIPMENT_EVENT_TYPES_COUNT).fill(0);
                            subSegment.dateFormatted = subSegment.date !== null ?
                                Utils.getFormattedDateTime(new Date(subSegment.date), DateTimeFormatType.DateTime) : ManagerConstants.MISSING_VALUE;

                            displayShipmentMonitoringPlanSegment.subSegments.push(subSegment);
                        }
                    }
                }
            }

            if (telemetry.telemetryTime !== null)
            {
                if (telemetry.rowId !== null)
                {
                    this._telemetryRowIdToTelemetryMap.set(telemetry.rowId, telemetry);
                }

                if (telemetry.temperature !== null)
                {
                    this.updateShipmentMonitoringEventTypeOverview(MonitoringDetailedEventType.HighTemperature);
                    this._shipmentMonitoringEventTypeOverviews[MonitoringDetailedEventType.HighTemperature].telemtryChartData.push(
                        [telemetry.telemetryTime.getTime(), telemetry.temperature]);
                }

                if (telemetry.humidity !== null)
                {
                    this.updateShipmentMonitoringEventTypeOverview(MonitoringDetailedEventType.HighHumidity);
                    this._shipmentMonitoringEventTypeOverviews[MonitoringDetailedEventType.HighHumidity].telemtryChartData.push(
                        [telemetry.telemetryTime.getTime(), telemetry.humidity]);
                }

                if (telemetry.security !== null)
                {
                    this.updateShipmentMonitoringEventTypeOverview(MonitoringDetailedEventType.Security);
                    this._shipmentMonitoringEventTypeOverviews[MonitoringDetailedEventType.Security].telemtryChartData.push(
                        [telemetry.telemetryTime.getTime(), telemetry.security]);
                }

                if (telemetry.gforce !== null)
                {
                    this.updateShipmentMonitoringEventTypeOverview(MonitoringDetailedEventType.StrongImpact);
                    this._shipmentMonitoringEventTypeOverviews[MonitoringDetailedEventType.StrongImpact].telemtryChartData.push(
                        [telemetry.telemetryTime.getTime(), telemetry.gforce]);
                }

                if (telemetry.movingFlag !== null)
                {
                    this.updateShipmentMonitoringEventTypeOverview(MonitoringDetailedEventType.Stop);
                    this._shipmentMonitoringEventTypeOverviews[MonitoringDetailedEventType.Stop].telemtryChartData.push(
                        [telemetry.telemetryTime.getTime(), 1 - telemetry.movingFlag]);
                }
            }
        }

        for (let eventType: number = 0; eventType < MonitoringUtils.SHIPMENT_EVENT_TYPES_COUNT; eventType++)
        {
            this.updateTelemtryData(eventType);
        }
    }

    private updateShipmentMonitoringEventMinMaxValues(displayShipmentMonitoringEvent: DisplayShipmentMonitoringEvent, value: number | null): void
    {
        if (displayShipmentMonitoringEvent.minValue === null)
        {
            displayShipmentMonitoringEvent.minValue = value;
        }
        else if (value !== null && displayShipmentMonitoringEvent.minValue > value)
        {
            displayShipmentMonitoringEvent.minValue = value;
        }

        if (displayShipmentMonitoringEvent.maxValue === null)
        {
            displayShipmentMonitoringEvent.maxValue = value;
        }
        else if (value !== null && displayShipmentMonitoringEvent.maxValue < value)
        {
            displayShipmentMonitoringEvent.maxValue = value;
        }
    }

    private initializeShipmentMonitoringEvents(): boolean
    {
        for (const shipmentMonitoringEvent of this._shipmentMonitoringOverview.events)
        {
            const eventType: MonitoringDetailedEventType | undefined = this.getUpdatedEventType(shipmentMonitoringEvent.eventId);
            if (eventType === undefined)
            {
                continue;
            }

            const displayShipmentMonitoringEvent: DisplayShipmentMonitoringEvent = new DisplayShipmentMonitoringEvent();
            Utils.copyObjectByTargetProperties(shipmentMonitoringEvent, displayShipmentMonitoringEvent);

            displayShipmentMonitoringEvent.eventType = eventType;
            if (eventType === MonitoringDetailedEventType.HighTemperature)
            {
                displayShipmentMonitoringEvent.minThreshold = this._displayShipmentMonitoring.minTemperatureThreshold;
                displayShipmentMonitoringEvent.maxThreshold = this._displayShipmentMonitoring.maxTemperatureThreshold;
                displayShipmentMonitoringEvent.timeBasedMinThreshold = this._displayShipmentMonitoring.tbMinTemperatureThreshold;
                displayShipmentMonitoringEvent.timeBasedMaxThreshold = this._displayShipmentMonitoring.tbMaxTemperatureThreshold;
            }
            else if (eventType === MonitoringDetailedEventType.HighHumidity)
            {
                displayShipmentMonitoringEvent.minThreshold = this._displayShipmentMonitoring.minHumidityThreshold;
                displayShipmentMonitoringEvent.maxThreshold = this._displayShipmentMonitoring.maxHumidityThreshold;
                displayShipmentMonitoringEvent.timeBasedMinThreshold = this._displayShipmentMonitoring.tbMinHumidityThreshold;
                displayShipmentMonitoringEvent.timeBasedMaxThreshold = this._displayShipmentMonitoring.tbMaxHumidityThreshold;
            }

            displayShipmentMonitoringEvent.fromDateFormatted = displayShipmentMonitoringEvent.fromDate !== null ?
                Utils.getFormattedDateTime(new Date(displayShipmentMonitoringEvent.fromDate), DateTimeFormatType.DateTime) : ManagerConstants.MISSING_VALUE;

            if (displayShipmentMonitoringEvent.fromDate !== null)
            {
                displayShipmentMonitoringEvent.durationFormatted =
                    Utils.formatDuration(displayShipmentMonitoringEvent.fromDate, displayShipmentMonitoringEvent.toDate ?? new Date());
            }

            if (displayShipmentMonitoringEvent.fromId !== null && displayShipmentMonitoringEvent.toId !== null)
            {
                const displayProperties: DisplayProperties = MonitoringUtils.getUpdatedEventDisplayPropertiesByDeviceType(eventType,
                    this._displayShipmentMonitoring.deviceType);

                for (let rowId: number = displayShipmentMonitoringEvent.fromId; rowId <= displayShipmentMonitoringEvent.toId; rowId++)
                {
                    let telemetry: ShipmentMonitoringTelemetry | undefined = this._telemetryRowIdToTelemetryMap.get(rowId);
                    if (telemetry === undefined)
                    {
                        console.error('event telemetry is missing!');
                        return false;
                    }

                    if (displayProperties.units !== undefined)
                    {
                        switch (eventType)
                        {
                            case MonitoringDetailedEventType.HighTemperature:
                                {
                                    this.updateShipmentMonitoringEventMinMaxValues(displayShipmentMonitoringEvent, telemetry.temperature);
                                }
                                break;

                            case MonitoringDetailedEventType.HighHumidity:
                                {
                                    this.updateShipmentMonitoringEventMinMaxValues(displayShipmentMonitoringEvent, telemetry.humidity);
                                }
                                break;

                            case MonitoringDetailedEventType.StrongImpact:
                                {
                                    this.updateShipmentMonitoringEventMinMaxValues(displayShipmentMonitoringEvent, this.getRoundedGForceValue(telemetry.gforce));
                                }
                                break;

                            case MonitoringDetailedEventType.Security:
                                {
                                    this.updateShipmentMonitoringEventMinMaxValues(displayShipmentMonitoringEvent, telemetry.security);
                                }
                                break;
                        }
                    }

                    displayShipmentMonitoringEvent.telemetries.push(telemetry);
                    this._shipmentMonitoringEventTypeOverviews[displayShipmentMonitoringEvent.eventType].telemetryTimeToEventsMap.
                        set(telemetry.telemetryTime!.getTime(), displayShipmentMonitoringEvent);
                }
            }

            displayShipmentMonitoringEvent.telemetries = this.sortTelemtries(displayShipmentMonitoringEvent.telemetries);

            displayShipmentMonitoringEvent.location =
            {
                lat: displayShipmentMonitoringEvent.telemetries[0].latitude!,
                lng: displayShipmentMonitoringEvent.telemetries[0].longitude!
            }

            this._shipmentMonitoringEventTypeOverviews[displayShipmentMonitoringEvent.eventType].events.push(displayShipmentMonitoringEvent);
        }

        return true;
    }

    private async initializeShipmentMonitoringOverview(): Promise<boolean>
    {
        return new Promise<boolean>((resolve) =>
        {
            const countryDetailsPerimeterIdToPlanSegmentMap: Map<string, DisplayShipmentMonitoringPlanSegment[]> = new Map();

            this._displayShipmentMonitoringPlanSegments = [];
            for (const planSegment of this._shipmentMonitoringOverview.planSegments)
            {
                const displayShipmentMonitoringPlanSegment: DisplayShipmentMonitoringPlanSegment = this.initializeShipmentMonitoringPlanSegment(planSegment);
                if (displayShipmentMonitoringPlanSegment.perimeterId !== null &&
                    (displayShipmentMonitoringPlanSegment.countryName === null || displayShipmentMonitoringPlanSegment.countryCode === null))
                {
                    let perimeterPlanSegments: DisplayShipmentMonitoringPlanSegment[] | undefined =
                        countryDetailsPerimeterIdToPlanSegmentMap.get(displayShipmentMonitoringPlanSegment.perimeterId);

                    if (perimeterPlanSegments === undefined)
                    {
                        perimeterPlanSegments = [];
                        countryDetailsPerimeterIdToPlanSegmentMap.set(displayShipmentMonitoringPlanSegment.perimeterId, perimeterPlanSegments);
                    }

                    perimeterPlanSegments.push(displayShipmentMonitoringPlanSegment);
                }

                this._displayShipmentMonitoringPlanSegments.push(displayShipmentMonitoringPlanSegment);
            }

            this.analyzeShipmentMonitoringTelemetries();

            if (!this.initializeShipmentMonitoringEvents())
            {
                resolve(false);
                return;
            }

            this.syncronizeChartDataMinTime();
            this.syncronizeChartDataMaxTime();

            this.updateEventsOccurrences();

            if (countryDetailsPerimeterIdToPlanSegmentMap.size === 0)
            {
                resolve(true);
            }

            let countryDetailsCounter: number = 0;
            for (const displayShipmentMonitoringPlanSegments of countryDetailsPerimeterIdToPlanSegmentMap.values())
            {
                MonitoringUtils.getCountryDetailsFromLocation(displayShipmentMonitoringPlanSegments[0].perimeterLatitude,
                    displayShipmentMonitoringPlanSegments[0].perimeterLongitude, this._httpClient).then(
                        (countryDetails: CountryDetails) =>
                        {
                            for (const displayShipmentMonitoringPlanSegment of displayShipmentMonitoringPlanSegments)
                            {
                                displayShipmentMonitoringPlanSegment.countryCode = countryDetails.countryCode;
                                displayShipmentMonitoringPlanSegment.countryName = countryDetails.countryName;
                            }

                            if (++countryDetailsCounter === countryDetailsPerimeterIdToPlanSegmentMap.size)
                            {
                                resolve(true);
                            }
                        });
            }
        });
    }

    private initializeShipmentMonitoringPlanSegment(planSegment: ShipmentMonitoringPlanSegment): DisplayShipmentMonitoringPlanSegment
    {
        const lastPlanSegment: DisplayShipmentMonitoringPlanSegment | null = this._displayShipmentMonitoringPlanSegments.length > 0 ?
            this._displayShipmentMonitoringPlanSegments[this._displayShipmentMonitoringPlanSegments.length - 1] : null;

        const displayShipmentMonitoringPlanSegment: DisplayShipmentMonitoringPlanSegment = new DisplayShipmentMonitoringPlanSegment();

        Utils.copyObjectByTargetProperties(planSegment, displayShipmentMonitoringPlanSegment);

        displayShipmentMonitoringPlanSegment.eventsOccurrences = Array(MonitoringUtils.SHIPMENT_EVENT_TYPES_COUNT).fill(0);

        displayShipmentMonitoringPlanSegment.iconClass = MonitoringUtils.getShipmentIconClassName(displayShipmentMonitoringPlanSegment.segmentId,
            displayShipmentMonitoringPlanSegment.perimeterType);

        displayShipmentMonitoringPlanSegment.perimeterName = MonitoringUtils.getShipmentPerimeterName(displayShipmentMonitoringPlanSegment.segmentId,
            displayShipmentMonitoringPlanSegment.perimeterName);

        displayShipmentMonitoringPlanSegment.title = `${this._loginModel.userInfo.isDemo && displayShipmentMonitoringPlanSegment.perimeterType !== null ?
            displayShipmentMonitoringPlanSegment.perimeterType : displayShipmentMonitoringPlanSegment.perimeterName}${''
            }${displayShipmentMonitoringPlanSegment.shipDataUrl !== null ? ': ' : ''}${''
            }${displayShipmentMonitoringPlanSegment.shipDataUrl !== null ? `<a href="${displayShipmentMonitoringPlanSegment.shipDataUrl}" target="_blank">${''
                }${displayShipmentMonitoringPlanSegment.shipNameAndImo}</a>` : ''}`;

        if (displayShipmentMonitoringPlanSegment.currentSegment === 1)
        {
            this._displayShipmentMonitoringCurrentPlanSegmentIndex = displayShipmentMonitoringPlanSegment.segmentIndex ?? 0;
        }

        displayShipmentMonitoringPlanSegment.etaFormatted = displayShipmentMonitoringPlanSegment.eta !== null ?
            Utils.getFormattedDateTime(new Date(displayShipmentMonitoringPlanSegment.eta), DateTimeFormatType.DateTime) : ManagerConstants.MISSING_VALUE;

        if (lastPlanSegment !== null && lastPlanSegment.eta !== null && displayShipmentMonitoringPlanSegment.eta !== null)
        {
            lastPlanSegment.durationFormatted = Utils.formatDuration(lastPlanSegment.eta, displayShipmentMonitoringPlanSegment.eta);
        }

        if (displayShipmentMonitoringPlanSegment.countryName !== null)
        {
            displayShipmentMonitoringPlanSegment.countryName =
                `${displayShipmentMonitoringPlanSegment.countryName.charAt(0).toUpperCase()}${displayShipmentMonitoringPlanSegment.countryName.slice(1).toLowerCase()}`;
        }

        if (displayShipmentMonitoringPlanSegment.countryCode !== null)
        {
            displayShipmentMonitoringPlanSegment.countryCode = displayShipmentMonitoringPlanSegment.countryCode.toLowerCase();
        }

        return displayShipmentMonitoringPlanSegment;
    }

    private updateShipmentMonitoringPlanSegmentEventSummeries(displayShipmentMonitoringEvent: DisplayShipmentMonitoringEvent): void
    {
        if (displayShipmentMonitoringEvent.telemetries.length === 0)
        {
            return;
        }

        for (const displayShipmentMonitoringPlanSegment of this._displayShipmentMonitoringPlanSegments)
        {
            for (const telemetry of displayShipmentMonitoringEvent.telemetries)
            {
                if (displayShipmentMonitoringPlanSegment.telemetryKey !== telemetry.telemetryPlanKey)
                {
                    continue;
                }

                displayShipmentMonitoringPlanSegment.eventsOccurrences[displayShipmentMonitoringEvent.eventType]++;

                break;
            }
        }
    }

    private updateEventsOccurrences(): void
    {
        this._eventsOccurrences = Array(MonitoringUtils.SHIPMENT_EVENT_TYPES_COUNT).fill(0);

        for (const shipmentMonitoringEventTypeOverview of this._shipmentMonitoringEventTypeOverviews)
        {
            for (const displayShipmentMonitoringEvent of shipmentMonitoringEventTypeOverview.events)
            {
                this.updateShipmentMonitoringPlanSegmentEventSummeries(displayShipmentMonitoringEvent);

                const eventType: MonitoringDetailedEventType | undefined =
                    this._monitoringDetailedEventIdToEventTypeMap.get(displayShipmentMonitoringEvent.eventId);

                if (eventType !== undefined)
                {
                    this._eventsOccurrences[eventType] = this._eventsOccurrences[eventType] + 1;
                }
            }
        }
    }

    // #endregion
}