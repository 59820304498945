import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, Observer } from 'rxjs';
import { Constants, IApiResponse } from '../../../utils/globals';
import { HttpHelper } from '../../../utils/http-helper';
import { ChangePasswordData, ChangePasswordRequest } from './change-password-model.class';
import { HttpErrorCodes } from '../../../utils/http-error-codes';
import { LoginModel } from '../../../user/login/model/login.model';
import { Utils } from '../../../utils/utils';
import { BaseModel } from '../../../base/classes/base-model';

@Injectable({ providedIn: 'root' })
export class ChangePasswordModel extends BaseModel
{
    // #region Private Members

    private _changePasswordData: ChangePasswordData = new ChangePasswordData();

    // #endregion

    // #region Properties

    public get changePasswordData(): ChangePasswordData
    {
        return this._changePasswordData;
    }

    // #endregion

    // #region Constructor

    constructor(private _httpClient: HttpClient, private _loginMode: LoginModel)
    {
        super();
    }

    // #endregion

    // #region Public Methods

    public override clear(): void
    {
        super.clear();

        this._changePasswordData = new ChangePasswordData();
    }

    public changePassword(): Observable<IApiResponse>
    {
        this._isBusy = true;

        return new Observable((observer: Observer<IApiResponse>) =>
        {
            const changePasswordRequest: ChangePasswordRequest = new ChangePasswordRequest();
            Utils.copyObjectByTargetProperties(this._changePasswordData, changePasswordRequest);

            this._httpClient.post('auth/user/password/change', HttpHelper.GetParams(changePasswordRequest), 
                { headers: HttpHelper.GetHttpFormUrlencodedHeaders() }).subscribe(
                    {
                        next: () =>
                        {
                            this._isBusy = false;
                            observer.next({ isSuccess: true });
                            observer.complete();
                        },
                        error: (error: HttpErrorResponse) =>
                        {
                            this._isBusy = false;
                            console.error(error);

                            observer.next(
                                {
                                    isSuccess: false, message: error.status === HttpErrorCodes.NOT_FOUND ?
                                        'This user is not registered.\r\nPlease try again with a different user.' :
                                        (error.status === HttpErrorCodes.BAD_REQUEST ?
                                            'The password you entered has been used before.\r\nPlease try again with a new password.' : 
                                            Constants.DATA_SERVICE_ERROR_STRING)
                                });

                            observer.complete();
                        }
                    });
        });
    }

    // #endregion
}