<!DOCTYPE html>

@if (viewIsInitialized) {
<form class="manager-item-from" #formManagerItem="ngForm" (ngSubmit)="onManagerItemSubmit(formManagerItem.form.valid)"
      @fadeInOutAnimation [ngClass]="{ 'form-validated': formManagerItem.submitted }" ignoreEnter novalidate>
    <fieldset [disabled]="managerItemModel.isBusy">
        <div class="form-content-wrapper">
            <div #pageScroller class="form-content page-scroll-padding" overlayScrollbar [class.view-only]="isViewOnly">
                <ng-container [ngTemplateOutlet]="isViewOnly ? viewInventoryTemplate: editInventoryTemplate"></ng-container>
            </div>
            @if (!isViewOnly) {
            <div class="form-group manager-buttons action-button-container">
                @if (managerItemModel.managerItem.inventoryId === null) {
                <button type="button" class="secondary-button submit-button ripple-effect ripple-fill"
                        (click)="onCancelManagerItemButtonClick()">
                    Close
                </button>
                }
                @if (managerItemModel.managerItem.inventoryId !== null) {
                <button type="button" class="secondary-button submit-button ripple-effect ripple-fill" [disabled]="!managerItemModel.isDirty"
                        (click)="loadManagerItem(false)">
                    Revert
                </button>
                }
                <button type="submit" class="primary-button ripple-effect ripple-fill"
                        [disabled]="!managerItemModel.isDirty && managerItemModel.managerItem.inventoryId !== null">
                    <span class="spinner-container">
                        @if (managerItemModel.isBusy) {
                        <i @fadeInOutAnimation class="icon-spinner"></i>
                        }
                        {{ managerItemModel.managerItem.inventoryId !== null ? 'Update' : 'Add' }}
                    </span>
                </button>
            </div>
            }
        </div>
    </fieldset>

    <!-- editInventoryTemplate -->
    <ng-template #editInventoryTemplate>
        <div class="section-title">User Details</div>
        <div class="form-group">
            <clearable-input name="firstName" #firstNameText #firstName="ngModel" [(ngModel)]="managerItemModel.managerItem.firstName"
                             requiredValidator [isRequired]="true" label="First Name" [maxLength]="255">
            </clearable-input>
            @if (formManagerItem.submitted && firstName.invalid) {
            <div class="invalid-feedback" @foldAnimation>This field is required</div>
            }
            <text-characters-counter [text]="managerItemModel.managerItem.firstName" [maxLength]="firstNameText.maxLength"></text-characters-counter>
        </div>
        <div class="form-group">
            <clearable-input name="lastName" #lastNameText #lastName="ngModel" [(ngModel)]="managerItemModel.managerItem.lastName"
                             requiredValidator [isRequired]="true" label="Last Name" [maxLength]="255">
            </clearable-input>
            @if (formManagerItem.submitted && lastName.invalid) {
            <div class="invalid-feedback" @foldAnimation>This field is required</div>
            }
            <text-characters-counter [text]="managerItemModel.managerItem.lastName" [maxLength]="lastNameText.maxLength"></text-characters-counter>
        </div>
        <div class="form-group">
            <clearable-input name="position" #positionText #position="ngModel" [(ngModel)]="managerItemModel.managerItem.position"
                             requiredValidator [isRequired]="true" label="Position" [maxLength]="255">
            </clearable-input>
            @if (formManagerItem.submitted && position.invalid) {
            <div class="invalid-feedback" @foldAnimation>This field is required</div>
            }
            <text-characters-counter [text]="managerItemModel.managerItem.position" [maxLength]="positionText.maxLength"></text-characters-counter>
        </div>
        <div class="form-group">
            <clearable-input name="userMail" #userMailText #userMail="ngModel" type="email" [(ngModel)]="managerItemModel.managerItem.userMail"
                             requiredValidator emailValidator [isRequired]="true" label="Email Address" [maxLength]="255">
            </clearable-input>
            @if (formManagerItem.submitted && userMail.invalid) {
            <div class="invalid-feedback" @foldAnimation>
                @if (userMail.errors?.['requiredValidator']) {
                <div>This field is required</div>
                }
                @if (userMail.errors?.['emailValidator']) {
                <div>Valid Email format is required</div>
                }
            </div>
            }
            <text-characters-counter [text]="managerItemModel.managerItem.userMail" [maxLength]="userMailText.maxLength"></text-characters-counter>
        </div>
        <div class="section-title">Inventory Details</div>
        <div class="form-group">
            <select-list name="warehouse" #warehouse="ngModel" placeholder="Warehouse" [items]="managerItemModel.inventoryLists.warehouses" [isRequired]="true"
                         [(ngModel)]="managerItemModel.managerItem.warehouseId" bindLabel="warehouseName" bindValue="warehouseId" requiredValidator>
            </select-list>
            @if (formManagerItem.submitted && warehouse.invalid) {
            <div class="invalid-feedback" @foldAnimation>This field is required</div>
            }
        </div>
        @if (managerItemModel.managerItem.date) {
        <div class="form-group">
            <datetime-picker label="Date" name="date" #date="ngModel" [(ngModel)]="managerItemModel.managerItem.date"
                             [withTime]="true" [disabled]="true">
            </datetime-picker>
        </div>
        }
        <div class="form-group">
            <numeric-input name="locks" #locks="ngModel" [(ngModel)]="managerItemModel.managerItem.locks"
                           requiredValidator [isRequired]="true" label="Security Locks">
            </numeric-input>
            @if (formManagerItem.submitted && locks.invalid) {
            <div class="invalid-feedback" @foldAnimation>This field is required</div>
            }
        </div>
        <div class="form-group">
            <numeric-input name="shackles" #shackles="ngModel" [(ngModel)]="managerItemModel.managerItem.shackles"
                           requiredValidator [isRequired]="true" label="Shackles">
            </numeric-input>
            @if (formManagerItem.submitted && shackles.invalid) {
            <div class="invalid-feedback" @foldAnimation>This field is required</div>
            }
        </div>
        <div class="form-group">
            <numeric-input name="cables" #cables="ngModel" [(ngModel)]="managerItemModel.managerItem.cables"
                           requiredValidator [isRequired]="true" label="Cables">
            </numeric-input>
            @if (formManagerItem.submitted && cables.invalid) {
            <div class="invalid-feedback" @foldAnimation>This field is required</div>
            }
        </div>
        <div class="form-group">
            <numeric-input name="chargers" #chargers="ngModel" [(ngModel)]="managerItemModel.managerItem.chargers"
                           requiredValidator [isRequired]="true" label="Chargers">
            </numeric-input>
            @if (formManagerItem.submitted && chargers.invalid) {
            <div class="invalid-feedback" @foldAnimation>This field is required</div>
            }
        </div>
        <div class="section-title">Additional Info</div>
        <div class="form-group">
            <textarea placeholder=" " #notesText name="notes" #notes="ngModel" class="form-control" autosize maxLength="255"
                      [(ngModel)]="managerItemModel.managerItem.notes">
        </textarea>
            <label class="control-label">Notes</label>
            <text-characters-counter [text]="managerItemModel.managerItem.notes" [maxLength]="notesText.maxLength"></text-characters-counter>
        </div>
    </ng-template>

    <!-- viewInventoryTemplate -->
    <ng-template #viewInventoryTemplate>
        <div class="section-title">User Details</div>
        <div class="form-group">
            <label class="attribute-label">First Name</label>
            <div class="attribute-value">{{ managerItemModel.managerItem.firstName }}</div>
        </div>
        <div class="form-group">
            <label class="attribute-label">Last Name</label>
            <div class="attribute-value">{{ managerItemModel.managerItem.lastName }}</div>
        </div>
        <div class="form-group">
            <label class="attribute-label">Position</label>
            <div class="attribute-value">{{ managerItemModel.managerItem.position }}</div>
        </div>
        <div class="form-group">
            <label class="attribute-label">Email Address</label>
            <div class="attribute-value">{{ managerItemModel.managerItem.userMail }}</div>
        </div>
        <div class="section-title">Inventory Details</div>
        <div class="form-group">
            <label class="attribute-label">Warehouse</label>
            <div class="attribute-value">{{ managerItemModel.managerItem.warehouseName }}</div>
        </div>
        <div class="form-group">
            <label class="attribute-label">Date</label>
            <div class="attribute-value">{{ managerItemModel.managerItem.dateFormatted }}</div>
        </div>
        <div class="form-group">
            <label class="attribute-label">Locks</label>
            <div class="attribute-value">{{ managerItemModel.managerItem.locks }}</div>
        </div>
        <div class="form-group">
            <label class="attribute-label">Shackles</label>
            <div class="attribute-value">{{ managerItemModel.managerItem.shackles }}</div>
        </div>
        <div class="form-group">
            <label class="attribute-label">Cables</label>
            <div class="attribute-value">{{ managerItemModel.managerItem.cables }}</div>
        </div>
        <div class="form-group">
            <label class="attribute-label">Chargers</label>
            <div class="attribute-value">{{ managerItemModel.managerItem.chargers }}</div>
        </div>
        <div class="section-title">Additional Info</div>
        <div class="form-group">
            <label class="attribute-label">Notes</label>
            <div class="attribute-value textarea">{{ managerItemModel.managerItem.notes }}</div>
        </div>
    </ng-template>

</form>
}
