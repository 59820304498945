import { trigger, animate, transition, style, AnimationTriggerMetadata, query, animateChild, AnimationMetadata, state } from '@angular/animations';
import { AnimationsConstants, AnimationDurationType } from './constant';

function getFoldTransitionAnimationMetadta(animationDurationType: AnimationDurationType, includeOpacity: boolean = false): AnimationMetadata[]
{
	const animationTiming: string = AnimationsConstants.getAnimationTiming(animationDurationType);

	return [
		transition(':enter',
			[
				style({ height: 0, 'margin-top': 0, 'margin-bottom': 0, 'padding-top': 0, 'padding-bottom': 0, opacity: includeOpacity ? 0 : 1 }),
				animate(animationTiming, style({ height: '*', 'margin-top': '*', 'margin-bottom': '*', 'padding-top': '*', 'padding-bottom': '*', opacity: 1 }))
			]
		),
		transition(':leave',
			[
				style({ height: '*', 'margin-top': '*', 'margin-bottom': '*', 'padding-top': '*', 'padding-bottom': '*', opacity: 1 }),
				animate(animationTiming, style({ height: 0, 'margin-top': 0, 'margin-bottom': 0, 'padding-top': 0, 'padding-bottom': 0, opacity: includeOpacity ? 0 : 1 }))
			]
		),
		transition(':enter, :leave',
			[
				query('@*', animateChild(), { optional: true })
			])
	];
}

export const foldAnimation: AnimationTriggerMetadata = trigger('foldAnimation', getFoldTransitionAnimationMetadta(AnimationDurationType.Normal));
export const foldLongAnimation: AnimationTriggerMetadata = trigger('foldLongAnimation', getFoldTransitionAnimationMetadta(AnimationDurationType.Long));
export const foldShortAnimation: AnimationTriggerMetadata = trigger('foldShortAnimation', getFoldTransitionAnimationMetadta(AnimationDurationType.Short));

export const foldFadeAnimation: AnimationTriggerMetadata = trigger('foldFadeAnimation', getFoldTransitionAnimationMetadta(AnimationDurationType.Normal, true));

function getFoldHorizontalTransitionAnimationMetadta(animationDurationType: AnimationDurationType, includeOpacity: boolean = false): AnimationMetadata[]
{
	const animationTiming: string = AnimationsConstants.getAnimationTiming(animationDurationType);

	return [
		transition(':enter',
			[
				style({ width: 0, 'margin-left': 0, 'margin-right': 0, opacity: includeOpacity ? 0 : 1 }),
				animate(animationTiming, style({ width: '*', 'margin-left': '*', 'margin-right': '*', opacity: 1 }))
			]
		),
		transition(':leave',
			[
				style({ width: '*', 'margin-left': '*', 'margin-right': '*', opacity: 1 }),
				animate(animationTiming, style({ width: 0, 'margin-left': 0, 'margin-right': 0, opacity: includeOpacity ? 0 : 1 }))
			]
		)
	];
}

export const foldHorizontalAnimation: AnimationTriggerMetadata = trigger('foldHorizontalAnimation',
	getFoldHorizontalTransitionAnimationMetadta(AnimationDurationType.Normal));

export const foldFadeHorizontalAnimation: AnimationTriggerMetadata = trigger('foldFadeHorizontalAnimation',
	getFoldHorizontalTransitionAnimationMetadta(AnimationDurationType.Normal, true));

export const foldLongHorizontalAnimation: AnimationTriggerMetadata = trigger('foldLongHorizontalAnimation',
	getFoldHorizontalTransitionAnimationMetadta(AnimationDurationType.Long));

export const foldShortHorizontalAnimation: AnimationTriggerMetadata = trigger('foldShortHorizontalAnimation',
	getFoldHorizontalTransitionAnimationMetadta(AnimationDurationType.Short));

function getFoldBothTransitionAnimationMetadta(animationDurationType: AnimationDurationType, includeOpacity: boolean = false): AnimationMetadata[]
{
	const animationTiming: string = AnimationsConstants.getAnimationTiming(animationDurationType);

	return [
		transition(':enter',
			[
				style({ width: 0, height: 0, margin: 0, opacity: includeOpacity ? 0 : 1 }),
				animate(animationTiming, style({ width: '*', height: '*', margin: '*', opacity: 1 }))
			]
		),
		transition(':leave',
			[
				style({ width: '*', height: '*', margin: '*', opacity: 1 }),
				animate(animationTiming, style({ width: 0, height: 0, margin: 0, opacity: includeOpacity ? 0 : 1 }))
			]
		)
	];
}

export const foldBothAnimation: AnimationTriggerMetadata = trigger('foldBothAnimation', getFoldBothTransitionAnimationMetadta(AnimationDurationType.Normal));
export const foldLongBothAnimation: AnimationTriggerMetadata = trigger('foldLongBothAnimation', getFoldBothTransitionAnimationMetadta(AnimationDurationType.Long));
export const foldShortBothAnimation: AnimationTriggerMetadata = trigger('foldShortBothAnimation', getFoldBothTransitionAnimationMetadta(AnimationDurationType.Short));

export const foldFadeBothAnimation: AnimationTriggerMetadata = trigger('foldFadeBothAnimation', getFoldBothTransitionAnimationMetadta(AnimationDurationType.Normal, true));

function getFoldHorizontalStateTransitionAnimationMetadta(animationDurationType: AnimationDurationType): AnimationMetadata[]
{
	return [
		state('false', style({ width: 0, 'min-width': 0, 'margin-left': 0, 'margin-right': 0 })),
		state('true', style({ width: '*', 'min-width': '*', 'margin-left': '*', 'margin-right': '*' })),
		transition('true <=> false', animate(AnimationsConstants.getAnimationTiming(animationDurationType)))
	];
}

export const foldHorizontalStateAnimation: AnimationTriggerMetadata =
	trigger('foldHorizontalStateAnimation', getFoldHorizontalStateTransitionAnimationMetadta(AnimationDurationType.Normal));

function getFoldVerticalStateTransitionAnimationMetadta(animationDurationType: AnimationDurationType): AnimationMetadata[]
{
	return [
		state('false', style({ height: 0, 'min-height': 0, 'margin-top': 0, 'margin-bottom': 0 })),
		state('true', style({ height: '*', 'min-height': '*', 'margin-top': '*', 'margin-bottom': '*' })),
		transition('true <=> false', animate(AnimationsConstants.getAnimationTiming(animationDurationType)))
	];
}

export const foldVerticalStateAnimation: AnimationTriggerMetadata =
	trigger('foldVerticalStateAnimation', getFoldVerticalStateTransitionAnimationMetadta(AnimationDurationType.Normal));
