import
{
    Component,
    ContentChild,
    ElementRef,
    EventEmitter,
    Input,
    NgZone,
    OnDestroy,
    OnInit,
    Output,
    Renderer2,
    ChangeDetectorRef,
    ChangeDetectionStrategy
} from '@angular/core';

import { orderBy } from 'lodash'
import { Subscription, Observable, Observer } from 'rxjs';
import { ElementResizedDirective, ResizedEvent } from '../../directives/element-resized.directive';

import { Utils } from '../../utils/utils';
import { ItemChangePostion, ListColumnInfo, ListViewPortInfo, ScrollInfo } from './model/virtual-list-mode.class';
import { AnimationsConstants } from '../../animations/constant';
import { Constants, KeyValue } from '../../utils/globals';
import { VirtualListInfo } from './model/virtual-list-info';

const enum AutoScrollVerticalDirection { None, Up, Down }
const enum RefreshState { ItemsChange, ItemsCalculate, ItemsScroll, ItemsResize }

@Component({
    selector: 'virtual-list',
    templateUrl: './virtual-list.component.html',
    styleUrls: ['./virtual-list.component.css'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true
})
export class VirtualListComponent extends ElementResizedDirective implements OnInit, OnDestroy
{
    // #region Private Constants

    private readonly SNAP_KEYBOARD_SCROLL_TIMEOUT_DURATION: number = 400;
    private readonly UPDATE_VIEW_DELAY_TIMEOUT_DURATION: number = 25;

    private readonly AUTO_SCROLL_PROXIMITY_THRESHOLD = 0.05;

    private readonly CONTENT_CLASS_NAME: string = 'content';
    private readonly INVISIBLE_PADDING_CLASS_NAME: string = 'invisible-padding';

    private readonly SORT_DIRECTION_NAME: string = 'sort-direction';
    private readonly SORT_CLASS_NAME: string = 'icon-sort';
    private readonly SORT_DOWN_CLASS_NAME: string = 'icon-sort-down';

    private readonly COLUMN_CLASSNAME: string = 'col-list';
    private readonly COLUMN_PROPERTY: string = 'property-name';
    private readonly COLUMN_EXTRA_WIDTH: string = 'extra-width';
    private readonly COLUMN_SORT_PROPERTY: string = 'sort-property-name';
    private readonly COLUMN_FIXED_WIDTH_PROPERTY: string = 'fixed-width';

    private readonly DRAGGING_CLASS_NAME: string = 'dragging';
    private readonly DRAG_OVER_BEFORE_CLASS_NAME: string = 'drag-over-before';
    private readonly DRAG_OVER_AFTER_CLASS_NAME: string = 'drag-over-after';

    private readonly HEADER_FONT_SIZE_MULTIPLIER: number = 20 / 17;

    private readonly EXTRA_VIEWPORT_ITEMS: number = 3;

    // #endregion

    // #region Private Members

    private _smoothScrollToPositionSubscription: Subscription | null = null;
    private _mergeMultipleScrollItemsSubscription: Subscription | null = null;

    private _listViewPortInfo: ListViewPortInfo = new ListViewPortInfo();
    private _isScrollTopInitialized: boolean = false;

    private _filteredItems: any[] = [];
    private _filteredSortedItems: any[] = [];

    private _columnInfoList: ListColumnInfo[] = [];

    private _contentOffset: number | null = null;
    private _offsetHeight: number | null = null;
    private _offsetWidth: number | null = null;

    private _contentElement: HTMLElement | null = null;
    private _invisiblePaddingElement: HTMLElement | null = null;

    private _items: any[] = [];
    private _itemsColumnsMinWidths: any[] = [];
    private _viewPortItems: any[] = [];

    private _snapScrollTimeoutHandleId: NodeJS.Timeout | null = null;
    private _updateViewDelayTimeoutHandleId: NodeJS.Timeout | null = null;

    private _disposeScrollHandler: (() => void) | null = null;
    private _disposeMouseUpHandler: (() => void) | null = null;
    private _disposeMouseWheelHandler: (() => void) | null = null;
    private _disposeMouseDownHandler: (() => void) | null = null;
    private _disposeWindowKeyDownHandler: (() => void) | null = null;
    private _disposeWindowDragMouseUpHandler: (() => void) | null = null;
    private _disposeWindowDragMouseMoveHandler: (() => void) | null = null;
    private _disposeTouchStartHandler: (() => void) | null = null;
    private _disposeTouchEndHandler: (() => void) | null = null;
    private _disposeTouchCancelHandler: (() => void) | null = null;

    private _verticalAutoScrollDirection: AutoScrollVerticalDirection = AutoScrollVerticalDirection.None;

    private _isStartDragging: boolean = false;
    private _dragOverElement: HTMLElement | null = null;
    private _draggingElement: HTMLElement | null = null;
    private _draggingElementStartX: number = 0;
    private _draggingElementStartY: number = 0;
    private _dragOverElementRect: DOMRect | null = null;
    private _listDraggingRect: DOMRect | null = null;
    private _dragStartDelayTimeout: NodeJS.Timeout | null = null;
    private _draggingStartIndex: number | null = null;
    private _autoScrollOffset: number = 0;
    private _itemsPerRow: number = 1;

    private _isMouseScroll: boolean = false;

    private _headerHeight: number | null = null;

    private _documentCanvas: HTMLCanvasElement;
    private _documentCanvasContext: CanvasRenderingContext2D | null = null;

    private _columnsHeaderObserver: MutationObserver | null = null;

    private _contentResizeObserver: ResizeObserver | null = null;

    private _headerElement: HTMLElement | null = null;
    @ContentChild('header', { read: ElementRef, static: false }) set headerElement(headerElementRef: ElementRef)
    {
        if (this._headerElement !== null || Utils.isNullOrUndefined(headerElementRef))
        {
            return;
        }

        this._headerElement = headerElementRef.nativeElement;
        if (this._headerElement === null)
        {
            return;
        }

        const listColumnsElements: HTMLElement[] = this.getListColumnsElements();
        if (listColumnsElements.length > 0)
        {
            if (this.isSortable)
            {
                this._headerElement.setAttribute(this.SORT_DIRECTION_NAME, '0');

                for (const columnElement of listColumnsElements)
                {
                    if (!Utils.isNullOrEmpty(columnElement.getAttribute(this.COLUMN_PROPERTY)))
                    {
                        this.addSorttingButton(columnElement);
                    }
                }

                this.refreshSorting();
            }
        }

        this._columnsHeaderObserver = new MutationObserver((mutations: MutationRecord[]) =>
        {
            for (const mutation of mutations)
            {
                if (mutation.addedNodes.length > 0 || mutation.removedNodes.length > 0)
                {
                    this.refreshListMinWidth();
                    break;
                }
            }
        });

        this._columnsHeaderObserver.observe(this._headerElement, { attributes: false, childList: true, subtree: false, characterData: false });

        if (this._items.length === 0)
        {
            this.refreshListMinWidth();
        }
    }

    @ContentChild('body', { read: ElementRef, static: false })
    private _bodyElementRef: ElementRef<HTMLElement> | undefined = undefined;

    // #endregion

    // #region Properties

    public get filteredSortedItems(): any[]
    {
        return this._filteredSortedItems;
    }

    public get viewPortItems(): any[]
    {
        return this._viewPortItems;
    }

    public get scrollTop(): number
    {
        return this.element.scrollTop;
    }

    public set scrollTop(value: number)
    {
        this._renderer.setProperty(this.element, 'scrollTop', value);
    }

    // #endregion

    // #region Inputs

    @Input() public virtualListInfo: VirtualListInfo = new VirtualListInfo();
    @Input() public isSortable: boolean = false;
    @Input() public isAutoColumnsWidths: boolean = false;
    @Input() public isAutoWidthByRow: boolean = false;
    @Input() public isAutoSizeToContent: boolean = false;
    @Input() public isDraggable: boolean = false;
    @Input() public isDragContained: boolean = true;
    @Input() public isAutoScrollToTop: boolean = false;
    @Input() public isScrollSnap: boolean = true;
    @Input() public isMouseWheelScrollSnap: boolean = true;
    @Input() public maxViewportItems: number | null = null;
    @Input() public emptyPlaceholder: string | null = null;
    @Input() public filterProperties: string[] = [];

    @Input()
    public get itemsPerRow(): number
    {
        return this._itemsPerRow
    }

    public set itemsPerRow(value: number)
    {
        if (this._itemsPerRow !== value)
        {
            this._itemsPerRow = value

            this.refreshItems(RefreshState.ItemsCalculate);
        }
    }

    @Input()
    public get filter(): string | null
    {
        return this.virtualListInfo.filter;
    }

    public set filter(value: string | null)
    {
        if (this.virtualListInfo.filter === value)
        {
            return;
        }

        this.virtualListInfo.filter = value;

        this._filteredItems = this.getFilteredItems();
        this._filteredSortedItems = [...this._filteredItems];

        this.refreshSorting();

        this.refreshItems(RefreshState.ItemsChange, RefreshState.ItemsChange);
    }

    @Input()
    public get items(): any[]
    {
        return this._items;
    }

    public set items(values: any[])
    {
        if (values === this._items)
        {
            return;
        }

        const lastFilteredItemsLength: number = this._filteredItems.length;

        this._items = Utils.isNullOrUndefined(values) ? [] : [...values];
        this.clearItemsWidths();

        this._filteredItems = this.getFilteredItems();
        this._filteredSortedItems = [...this._filteredItems];

        this.refreshSorting();

        if (this.isAutoScrollToTop)
        {
            this.scrollTop = 0;
        }
        else if (this.scrollTop > 0 && this._filteredItems.length !== lastFilteredItemsLength)
        {
            this.scrollTop = this._filteredItems.length === 0 ? 0 :
                Math.floor(this._filteredItems.length / lastFilteredItemsLength * this.scrollTop);

            if (this.isScrollSnap)
            {
                this.snapScrollPosition(true);
            }
        }

        this.refreshItems(RefreshState.ItemsChange, RefreshState.ItemsChange);
    }

    public get element(): HTMLElement
    {
        return this._elementRef.nativeElement;
    }

    public get invisiblePaddingElement(): HTMLElement | null
    {
        if (this._invisiblePaddingElement === null)
        {
            this._invisiblePaddingElement = this._elementRef.nativeElement.querySelector(`.${this.INVISIBLE_PADDING_CLASS_NAME}`)
        }

        return this._invisiblePaddingElement;
    }

    private get offsetHeight(): number
    {
        if (this._offsetHeight === null)
        {
            const cssStyleDeclaration: CSSStyleDeclaration = getComputedStyle(this.element);

            this._offsetHeight = Math.max(this.element.offsetHeight, cssStyleDeclaration.maxHeight !== "none" ? parseFloat(cssStyleDeclaration.maxHeight) : 0);
        }

        return this._offsetHeight;
    }

    private get offsetWidth(): number
    {
        if (this._offsetWidth === null)
        {
            const cssStyleDeclaration: CSSStyleDeclaration = getComputedStyle(this.element);

            this._offsetWidth = Math.max(this.element.offsetWidth, cssStyleDeclaration.maxWidth !== "none" ? parseFloat(cssStyleDeclaration.maxWidth) : 0);
        }

        return this._offsetWidth;
    }

    private get contentElement(): HTMLElement | null
    {
        if (this._contentElement === null)
        {
            this._contentElement = this._elementRef.nativeElement.querySelector(`.${this.CONTENT_CLASS_NAME}`);
        }

        return this._contentElement;
    }

    private get contentOffset(): number
    {
        if (this._contentOffset !== null)
        {
            return this._contentOffset;
        }

        if (this._bodyElementRef !== undefined && this.contentElement !== null)
        {
            this._contentOffset = this.getElementActualHeight(this.contentElement.children[0] as HTMLElement) -
                this.getElementActualHeight(this._bodyElementRef.nativeElement);
        }
        else
        {
            this._contentOffset = 0;
        }

        return this._contentOffset;
    }

    // #endregion

    // #region Events

    @Output() public scrollChange: EventEmitter<ScrollInfo> = new EventEmitter<ScrollInfo>();
    @Output() public itemsChange: EventEmitter<any[]> = new EventEmitter<any[]>();
    @Output() public itemPositionChange: EventEmitter<ItemChangePostion> = new EventEmitter<any>();
    @Output() public dragStart: EventEmitter<any> = new EventEmitter();
    @Output() public dragEnd: EventEmitter<any> = new EventEmitter();
    @Output() public sorted: EventEmitter<any> = new EventEmitter();

    // #endregion

    // #region Constructors

    constructor(_elementRef: ElementRef<HTMLElement>, private _renderer: Renderer2, _ngZone: NgZone, private _changeDetectorRef: ChangeDetectorRef)
    {
        super(_elementRef, _ngZone);

        this.delayResizeEvents = true;

        this._documentCanvas = this._renderer.createElement('canvas');
        this._documentCanvasContext = this._documentCanvas.getContext('2d');

        this.updateFontSize();
    }

    // #endregion

    // #region Event Handlers

    public ngOnInit(): void
    {
        this._ngZone.runOutsideAngular(() =>
        {
            this._disposeScrollHandler = this._renderer.listen(this.element, 'scroll', this.onScroll.bind(this));
            this._disposeMouseUpHandler = this._renderer.listen(window, 'mouseup', this.onMouseUp.bind(this));
            this._disposeMouseDownHandler = this._renderer.listen(this.element, 'mousedown', this.onMouseDown.bind(this));

            if (this.isScrollSnap)
            {
                if (this.isMouseWheelScrollSnap)
                {
                    this._disposeMouseWheelHandler = this._renderer.listen(this.element, 'wheel', this.onMouseWheel.bind(this));
                }

                this._disposeWindowKeyDownHandler = this._renderer.listen(this.element, 'keydown', this.onKeyDown.bind(this));
            }

            if (this.isAutoSizeToContent && this.contentElement !== null)
            {
                this._contentResizeObserver = new ResizeObserver(() => 
                {
                    this._renderer.setStyle(this.element, 'width', `${Math.round(this.getElementActualWidth(this.contentElement!))}px`);
                });

                this._contentResizeObserver.observe(this.contentElement);
            }
        });

        if (this.isDraggable)
        {
            this._ngZone.runOutsideAngular(() =>
            {
                this._disposeTouchStartHandler = this._renderer.listen(this.element, 'touchstart', this.onTouchStart.bind(this));
            });
        }
    }

    public override ngOnDestroy(): void
    {
        super.ngOnDestroy();

        this._verticalAutoScrollDirection = AutoScrollVerticalDirection.None;

        this.clearSmoothScrollToPositionSubscription();
        this.clearMergeMultipleScrollItemsSubscription();
        this.clearSnapScrollTimeout();
        this.clearDragStartDelayTimeout();

        if (this._contentResizeObserver !== null)
        {
            this._contentResizeObserver.disconnect();
            this._contentResizeObserver = null;
        }

        if (this._columnsHeaderObserver !== null)
        {
            this._columnsHeaderObserver.disconnect();
            this._columnsHeaderObserver = null;
        }

        if (this._disposeScrollHandler !== null)
        {
            this._disposeScrollHandler();
            this._disposeScrollHandler = null;
        }

        if (this._disposeMouseUpHandler !== null)
        {
            this._disposeMouseUpHandler();
            this._disposeMouseUpHandler = null;
        }

        if (this._disposeWindowKeyDownHandler !== null)
        {
            this._disposeWindowKeyDownHandler();
            this._disposeWindowKeyDownHandler = null;
        }

        if (this._disposeMouseWheelHandler !== null)
        {
            this._disposeMouseWheelHandler();
            this._disposeMouseWheelHandler = null;
        }

        if (this._disposeMouseDownHandler !== null)
        {
            this._disposeMouseDownHandler();
            this._disposeMouseDownHandler = null;
        }

        if (this._disposeTouchStartHandler !== null)
        {
            this._disposeTouchStartHandler();
            this._disposeTouchStartHandler = null;
        }

        this.removeDragEventListeners();

        if (this._headerElement !== null && this.isSortable)
        {
            const listColumnsElements: HTMLElement[] = this.getListColumnsElements();
            for (const columnElement of listColumnsElements)
            {
                columnElement.removeEventListener('click', this.onColumnHeaderClick);
            }
        }
    }

    private onMouseDown(event: MouseEvent): void
    {
        this._isMouseScroll = true;

        this.clearSnapScrollTimeout();
        this.clearSmoothScrollToPositionSubscription();

        if (!this.isDraggable)
        {
            return;
        }

        if (this._isStartDragging)
        {
            this.updateDraggingEnd(true);
        }

        if (this._contentElement === null || !this._contentElement.contains(event.target as HTMLElement))
        {
            return;
        }

        if (event.button === 0)
        {
            this._disposeWindowDragMouseMoveHandler = this._renderer.listen('window', 'mousemove', this.onWindowDragMouseMove.bind(this));
            this._disposeWindowDragMouseUpHandler = this._renderer.listen('window', 'mouseup', this.onWindowDragMouseUp.bind(this));

            this.clearDragStartDelayTimeout();

            this._dragStartDelayTimeout = setTimeout(() =>
            {
                this._isStartDragging = true;
                this._dragStartDelayTimeout = null;
            }, Constants.DRAGGING_START_DELAY);
        }
    }

    private onMouseUp(): void
    {
        this._isMouseScroll = false;

        if (this.isScrollSnap)
        {
            this.initiateSnapScrollPosition();
        }
    }

    private onWindowDragMouseMove(event: MouseEvent): void
    {
        if (this._isStartDragging)
        {
            if (this._draggingElement === null)
            {
                this.initializeDraggingMove(event.target as HTMLElement, event.clientX, event.clientY);
            }

            this.updateDraggingMove(event.clientX, event.clientY);
        }
        else 
        {
            this.clearDragStartDelayTimeout();
        }
    }

    private onWindowDragMouseUp(): void
    {
        this.updateDraggingEnd(false);
    }

    private onTouchStart(event: TouchEvent): void
    {
        if (this._isStartDragging)
        {
            this.updateDraggingEnd(true);
        }

        const targetElement: HTMLElement = event.target as HTMLElement;
        if (this._contentElement === null || !this._contentElement.contains(targetElement))
        {
            return;
        }

        this._disposeTouchEndHandler = this._renderer.listen(targetElement, 'touchend', this.onTouchEnd.bind(this));
        this._disposeTouchCancelHandler = this._renderer.listen(targetElement, 'touchcancel', this.onTouchCancel.bind(this));

        targetElement.addEventListener('touchmove', this.onTouchMove.bind(this), { passive: false, capture: true });

        this.clearDragStartDelayTimeout();

        this._dragStartDelayTimeout = setTimeout(() =>
        {
            this._isStartDragging = true;
            this._dragStartDelayTimeout = null;
        }, Constants.DRAGGING_START_DELAY);
    }

    private onTouchMove(event: TouchEvent): void
    {
        event.preventDefault();

        if (this._isStartDragging)
        {
            event.stopImmediatePropagation();

            if (this._draggingElement === null)
            {
                this.initializeDraggingMove(event.target as HTMLElement, event.changedTouches[0].clientX, event.changedTouches[0].clientY);
            }

            this.updateDraggingMove(event.changedTouches[0].clientX, event.changedTouches[0].clientY);
        }
        else 
        {
            this.clearDragStartDelayTimeout();
        }
    }

    private onTouchEnd(): void
    {
        this.updateDraggingEnd(false);
    }

    private onTouchCancel(): void
    {
        this.updateDraggingEnd(true);
    }

    private onMouseWheel(event: WheelEvent): void
    {
        event.preventDefault();
        event.stopPropagation();

        if (this._listViewPortInfo.itemHeight === null)
        {
            return;
        }

        this.clearSnapScrollTimeout();
        this.clearSmoothScrollToPositionSubscription();
        this.updateElementScrollY(this.getSnapScrollTop(this.scrollTop + Math.sign(event.deltaY) * this._listViewPortInfo.itemHeight));
    }

    private onKeyDown(event: KeyboardEvent): void
    {
        switch (event.key)
        {
            case KeyValue.ArrowUp:
            case KeyValue.ArrowDown:
            case KeyValue.PageUp:
            case KeyValue.PageDown:
                {
                    event.preventDefault();

                    if (this._listViewPortInfo.itemHeight === null)
                    {
                        return;
                    }

                    let scrollY: number = 0;

                    if (event.key === KeyValue.ArrowUp || event.key === KeyValue.ArrowDown)
                    {
                        scrollY = (event.key === KeyValue.ArrowDown ? this._listViewPortInfo.itemHeight : -this._listViewPortInfo.itemHeight);
                    }
                    else
                    {
                        scrollY = Math.floor(this._listViewPortInfo.viewPortHeight / this._listViewPortInfo.itemHeight) *
                            (event.key === KeyValue.PageDown ? this._listViewPortInfo.itemHeight : -this._listViewPortInfo.itemHeight);
                    }

                    this.clearSnapScrollTimeout();
                    this.clearSmoothScrollToPositionSubscription();
                    this.updateElementScrollY(this.getSnapScrollTop(this.scrollTop + scrollY));
                }
                break;

            case KeyValue.Home:
                {
                    event.preventDefault();
                    this.scrollTop = 0;
                }
                break;

            case KeyValue.End:
                {
                    event.preventDefault();
                    this.scrollTop = this.element.scrollHeight - this.element.clientHeight;
                }
                break;
        }
    }

    private sendScrollInfoEvent(): void
    {
        const scrollInfo = new ScrollInfo();
        scrollInfo.scrollHeight = this._listViewPortInfo.scrollHeight - this.offsetHeight;
        scrollInfo.scrollTop = this.scrollTop;
        scrollInfo.scrollWidth = this._listViewPortInfo.scrollWidth - this.offsetWidth;
        scrollInfo.scrollLeft = this.element.scrollLeft;
        this._ngZone.run(() => this.scrollChange.emit(scrollInfo));
    }

    private onScroll(): void
    {
        if (this.virtualListInfo.scrollTop === this.scrollTop)
        {
            return;
        }

        this.virtualListInfo.scrollTop = this.scrollTop;

        this.clearMergeMultipleScrollItemsSubscription();

        this._mergeMultipleScrollItemsSubscription = new Observable((observer: Observer<any>) =>
        {
            requestAnimationFrame(() =>
            {
                observer.next(null);
                observer.complete();
            });

        }).subscribe(() =>
        {
            this.calculateItems(RefreshState.ItemsScroll, null);

            this.sendScrollInfoEvent();
        });

        if (this.isScrollSnap)
        {
            this.initiateSnapScrollPosition(true);
        }
    }

    private onColumnHeaderClick(event: MouseEvent): void
    {
        const element: HTMLElement = event.currentTarget as HTMLElement;
        if (Utils.isNullOrUndefined(element) || this._headerElement === null)
        {
            return;
        }

        let sortOrder: number = Number(this._headerElement.getAttribute(this.SORT_DIRECTION_NAME));
        if (sortOrder === 0)
        {
            sortOrder = 1;
        }
        else if (this.virtualListInfo.sortColumn !== null)
        {
            const listColumnsElements: HTMLElement[] = this.getListColumnsElements();

            if (listColumnsElements.length > this.virtualListInfo.sortColumn && listColumnsElements[this.virtualListInfo.sortColumn] === element)
            {
                sortOrder = -sortOrder;
            }
        }

        this.sortColumnElement(element, sortOrder);
    }

    // #endregion

    // #region Public Methods

    public addItem(item: any, index: number | null = null): void
    {
        if (this._items.length === 0)
        {
            this.items = [item];
            return;
        }

        if (this.isItemFiltered(item, this.getFilterText()))
        {
            const filteredIndex: number | null = index === null ? null : this._filteredItems.indexOf(this._items[index]);
            this.addItemToArray(this._filteredItems, item, filteredIndex);

            const filteredSortedIndex: number | null = index === null ? null : this._filteredSortedItems.indexOf(this._items[index]);
            this.addItemToArray(this._filteredSortedItems, item, filteredSortedIndex);

            this.refreshSorting();
        }

        const itemColumnsMinWidths: any = {};

        this.addItemToArray(this._items, item, index);
        this.addItemToArray(this._itemsColumnsMinWidths, itemColumnsMinWidths, index);

        if (this.updateMinWidthByItem(item, itemColumnsMinWidths))
        {
            this.updateListMinWidth();
        }

        this._changeDetectorRef.detectChanges();

        this.refreshItems(RefreshState.ItemsChange);
    }

    public removeItem(item: any, refresh: boolean = true): void
    {
        let index: number = this._items.indexOf(item);
        if (index === -1)
        {
            return;
        }

        this._items.splice(index, 1);
        this._itemsColumnsMinWidths.splice(index, 1);

        index = this._filteredItems.indexOf(item);
        if (index === -1)
        {
            return;
        }

        this._filteredItems.splice(index, 1);
        this._filteredSortedItems.splice(this._filteredSortedItems.indexOf(item), 1);

        if (this._listViewPortInfo.itemHeight !== null)
        {
            if (this.scrollTop > this._listViewPortInfo.scrollHeight - this._listViewPortInfo.itemHeight - this._listViewPortInfo.viewPortHeight)
            {
                this.scrollToPosition(this.scrollTop - this._listViewPortInfo.itemHeight, !refresh, () =>
                {
                    if (refresh)
                    {
                        this.refreshAfterItemRemoved();
                    }
                });

                return;
            }
        }

        if (refresh)
        {
            this.refreshAfterItemRemoved();
        }
    }

    public isScrolledToTop(): boolean
    {
        return this.scrollTop === 0;
    }

    public isScrolledToBottom(): boolean
    {
        return this._listViewPortInfo.scrollHeight <= this._listViewPortInfo.viewPortHeight ||
            this.scrollTop >= (this._listViewPortInfo.scrollHeight - this._listViewPortInfo.viewPortHeight - 1);
    }

    public scrollIntoView(item: any, isImmediate: boolean = false): boolean
    {
        if (Utils.isNullOrUndefined(item) || this._listViewPortInfo.itemHeight === null)
        {
            return false;
        }

        const itemIndex: number = Math.floor(this._filteredSortedItems.indexOf(item) / this.itemsPerRow);
        if (itemIndex < 0)
        {
            return false;
        }

        const itemPosition: number = itemIndex * this._listViewPortInfo.itemHeight;
        const headerHeight: number = this._headerElement !== null ? this.getElementActualHeight(this._headerElement) : 0;

        if (itemPosition <= this.scrollTop ||
            itemPosition + this._listViewPortInfo.itemHeight >= this.scrollTop + this.element.clientHeight - headerHeight)
        {
            this.scrollToPosition(itemPosition, isImmediate);
        }

        return true;
    }

    public scrollToTop(isImmediate: boolean = false): void
    {
        this.scrollToPosition(0, isImmediate);
    }

    public scrollToBottom(isImmediate: boolean = false): void
    {
        this.scrollToPosition(this.element.scrollHeight - this.element.clientHeight, isImmediate);
    }

    // #endregion

    // #region Protected Methods

    protected override resize(event: ResizedEvent): void
    {
        if (event.isFontResized)
        {
            this.updateFontSize();
            this.clearItemsWidths();
        }

        this.refreshSizeUpdated();
    }

    // #endregion

    // #region private Methods

    private updateFontSize(): void
    {
        if (this._documentCanvasContext !== null)
        {
            Utils.updateCanvasContextFont(this._documentCanvasContext);
        }
    }

    private clearItemsWidths(): void
    {
        this._itemsColumnsMinWidths = Array(this._items.length).fill(undefined).map(() => ({}));
    }

    private updateElementScrollY(scrollY: number): void
    {
        if (scrollY < 0)
        {
            this.scrollTop = 0;
            return;
        }
        else if (scrollY > this.element.scrollHeight - this.element.clientHeight)
        {
            this.scrollTop = this.element.scrollHeight - this.element.clientHeight;
            return;
        }

        this.scrollTop = scrollY;
    }

    private initiateSnapScrollPosition(isDuringScroll: boolean = false): void
    {
        this.clearSnapScrollTimeout();
        if (!isDuringScroll)
        {
            this.clearSmoothScrollToPositionSubscription();
        }

        this._snapScrollTimeoutHandleId = setTimeout(() =>
        {
            this.clearSnapScrollTimeout();
            this.snapScrollPosition();
        }, this.SNAP_KEYBOARD_SCROLL_TIMEOUT_DURATION);
    }

    private getSnapScrollTop(position: number): number
    {
        if (this._listViewPortInfo.itemHeight === null)
        {
            return position;
        }

        const diffFromSnap: number = position % this._listViewPortInfo.itemHeight;
        if (diffFromSnap === 0)
        {
            return position;
        }

        return position - diffFromSnap + (diffFromSnap > this._listViewPortInfo.itemHeight / 2 ? this._listViewPortInfo.itemHeight : 0);
    }

    private snapScrollPosition(immidiate: boolean = false): void
    {
        if ((this.element.scrollHeight - this.element.clientHeight) - this.scrollTop > 0 && this.scrollTop > 0 &&
            this._listViewPortInfo.itemHeight !== null)
        {
            const snapPosition: number = this.getSnapScrollTop(this.scrollTop);
            if (Math.round(snapPosition) !== this.scrollTop)
            {
                this.scrollToPosition(snapPosition, immidiate);
            }
        }
    }

    private refreshAfterItemRemoved(): void
    {
        this.refreshItems(RefreshState.ItemsChange);

        this.refreshListMinWidth();
    }

    private removeDragEventListeners(): void
    {
        if (this._disposeWindowDragMouseMoveHandler !== null)
        {
            this._disposeWindowDragMouseMoveHandler();
            this._disposeWindowDragMouseMoveHandler = null;
        }

        if (this._disposeWindowDragMouseUpHandler !== null)
        {
            this._disposeWindowDragMouseUpHandler();
            this._disposeWindowDragMouseUpHandler = null;
        }

        if (this._disposeTouchEndHandler !== null)
        {
            this._disposeTouchEndHandler();
            this._disposeTouchEndHandler = null;
        }

        if (this._disposeTouchCancelHandler !== null)
        {
            this._disposeTouchCancelHandler();
            this._disposeTouchCancelHandler = null;
        }

        if (this._draggingElement !== null)
        {
            this._draggingElement.removeEventListener('touchmove', this.onTouchMove);
        }
    }

    private updateDraggingEnd(canceled: boolean): void
    {
        this._isStartDragging = false;

        this.clearDragStartDelayTimeout();

        if (this._draggingElement === null)
        {
            return;
        }

        this.removeDragEventListeners();

        this._verticalAutoScrollDirection = AutoScrollVerticalDirection.None;

        this.element.classList.remove(this.DRAGGING_CLASS_NAME);

        this._draggingElement.remove();
        this._draggingElement = null;

        if (!this.isDragContained)
        {
            document.body.style.overflow = '';
        }

        if (this._dragOverElement !== null)
        {
            if (!canceled && this._contentElement !== null)
            {
                let dropIndex: number = this._listViewPortInfo.startIndex + Array.prototype.indexOf.call(this._contentElement.children, this._dragOverElement);
                if (this._dragOverElement.classList.contains(this.DRAG_OVER_AFTER_CLASS_NAME))
                {
                    dropIndex++;
                }

                if (this._draggingStartIndex !== null && this._draggingStartIndex !== dropIndex)
                {
                    const currentScrollTop: number = this.scrollTop;

                    const dragItem: any = this.items[this._draggingStartIndex];
                    this.removeItem(dragItem, false);
                    this.addItem(dragItem, dropIndex > this._draggingStartIndex ? dropIndex - 1 : dropIndex);

                    this.scrollTop = currentScrollTop;

                    this.itemPositionChange.emit({ item: dragItem, targetIndex: dropIndex });
                }
            }

            this._dragOverElement.classList.remove(this.DRAG_OVER_BEFORE_CLASS_NAME);
            this._dragOverElement.classList.remove(this.DRAG_OVER_AFTER_CLASS_NAME);
            this._dragOverElement = null;
        }

        this.dragEnd.emit();
    }

    private initializeDraggingMove(targetElement: HTMLElement, clientX: number, clientY: number): void
    {
        const targetRootElement: HTMLElement | null = this.getDraggingRootElement(targetElement);
        if (targetRootElement === null || this._contentElement === null)
        {
            return;
        }

        this.dragStart.emit();

        this._listDraggingRect = this.element.getBoundingClientRect();

        this._draggingElement = targetRootElement.cloneNode(true) as HTMLElement;
        this._draggingElement.style.pointerEvents = 'none';
        this._draggingElement.style.touchAction = 'none';
        this._draggingElement.style.setProperty('webkitUserDrag', 'none');

        document.body.append(this._draggingElement);
        if (!this.isDragContained)
        {
            document.body.style.overflow = 'hidden';
        }

        this._draggingStartIndex = this._listViewPortInfo.startIndex + Array.prototype.indexOf.call(this._contentElement.children, targetRootElement);

        const rect: DOMRect = targetRootElement.getBoundingClientRect();
        this._draggingElement.style.width = `${rect.width}px`

        this._draggingElementStartX = this.isDragContained ? rect.left : clientX - rect.left;
        this._draggingElementStartY = clientY - rect.top;

        this._draggingElement.classList.add(this.DRAGGING_CLASS_NAME);
        this.element.classList.add(this.DRAGGING_CLASS_NAME);
    }

    private getDraggingRootElement(element: HTMLElement): HTMLElement | null
    {
        if (this._contentElement !== null && this._contentElement.contains(element))
        {
            let currentElement: HTMLElement | null = element;
            while (currentElement !== null)
            {
                if (currentElement.parentElement === this._contentElement)
                {
                    return currentElement;
                }

                currentElement = currentElement.parentElement;
            }
        }

        return null;
    }

    private updateDragOverElement(clientX: number, clientY: number): void
    {
        let dragOverElement: HTMLElement | null = null;
        if (this._verticalAutoScrollDirection === AutoScrollVerticalDirection.None)
        {
            dragOverElement = document.elementFromPoint(clientX, clientY) as HTMLElement;
            const dragOverRootElement: HTMLElement | null = this.getDraggingRootElement(dragOverElement);
            if (dragOverRootElement !== null)
            {
                if (this._dragOverElement !== dragOverRootElement && dragOverRootElement !== null)
                {
                    this._dragOverElement?.classList.remove(this.DRAG_OVER_BEFORE_CLASS_NAME);
                    this._dragOverElement?.classList.remove(this.DRAG_OVER_AFTER_CLASS_NAME);

                    this._dragOverElement = dragOverRootElement;
                    this._dragOverElementRect = this._dragOverElement.getBoundingClientRect();

                    return;
                }

                if (this._dragOverElementRect !== null && clientY - this._dragOverElementRect.top < this._dragOverElementRect.height / 2)
                {
                    this._dragOverElement?.classList.add(this.DRAG_OVER_BEFORE_CLASS_NAME);
                    this._dragOverElement?.classList.remove(this.DRAG_OVER_AFTER_CLASS_NAME);
                }
                else
                {
                    this._dragOverElement?.classList.remove(this.DRAG_OVER_BEFORE_CLASS_NAME);
                    this._dragOverElement?.classList.add(this.DRAG_OVER_AFTER_CLASS_NAME);
                }

                return;
            }
        }

        if (this._dragOverElement !== null && dragOverElement !== this._contentElement)
        {
            this._dragOverElement.classList.remove(this.DRAG_OVER_BEFORE_CLASS_NAME);
            this._dragOverElement.classList.remove(this.DRAG_OVER_AFTER_CLASS_NAME);
            this._dragOverElement = null;
        }
    }

    private updateDraggingMove(clientX: number, clientY: number): void
    {
        const transformValue: string = `translate3d(${this.isDragContained ? this._draggingElementStartX : clientX - this._draggingElementStartX}px, ${''
            }${clientY - this._draggingElementStartY} px, 0px)`;

        this._renderer.setStyle(this._draggingElement, 'transform', transformValue);
        this._renderer.setStyle(this._draggingElement, 'webkitTransform', transformValue);

        this.updateDragOverElement(clientX, clientY);

        this.updateDraggingAutoScroll(clientX, clientY);
    }

    private startAutoScrollInterval(): void
    {
        const stepAutoScrollAnimation = () =>
        {
            if (this._verticalAutoScrollDirection === AutoScrollVerticalDirection.Up)
            {
                this.element.scrollBy(0, -this._autoScrollOffset);
            }
            else if (this._verticalAutoScrollDirection === AutoScrollVerticalDirection.Down)
            {
                this.element.scrollBy(0, this._autoScrollOffset);
            }
            else
            {
                return;
            }

            requestAnimationFrame(stepAutoScrollAnimation);
        };

        requestAnimationFrame(stepAutoScrollAnimation);
    }

    private updateDraggingAutoScroll(clientX: number, clientY: number): void
    {
        if (this._draggingElement === null || this._listDraggingRect === null)
        {
            return;
        }

        let verticalAutoScrollDirection = AutoScrollVerticalDirection.None;

        if (clientX >= this._listDraggingRect.left && clientX <= this._listDraggingRect.right)
        {
            const yThreshold: number = this._listDraggingRect.height * this.AUTO_SCROLL_PROXIMITY_THRESHOLD;

            if (clientY >= this._listDraggingRect.top - yThreshold && clientY <= this._listDraggingRect.top + yThreshold)
            {
                if (this.scrollTop > 0)
                {
                    this._autoScrollOffset = yThreshold - (clientY - this._listDraggingRect.top);
                    verticalAutoScrollDirection = AutoScrollVerticalDirection.Up;
                }
            }
            else if (clientY >= this._listDraggingRect.bottom - yThreshold && clientY <= this._listDraggingRect.bottom + yThreshold)
            {
                if (this.scrollTop + this.element.offsetHeight < this.element.scrollHeight)
                {
                    this._autoScrollOffset = yThreshold - (this._listDraggingRect.bottom - clientY);
                    verticalAutoScrollDirection = AutoScrollVerticalDirection.Down;
                }
            }
        }

        if (verticalAutoScrollDirection !== this._verticalAutoScrollDirection)
        {
            this._verticalAutoScrollDirection = verticalAutoScrollDirection;
            if (this._verticalAutoScrollDirection !== AutoScrollVerticalDirection.None)
            {
                this._ngZone.runOutsideAngular(() => this.startAutoScrollInterval());
            }
        }
    }

    private getElementActualHeight(element: HTMLElement): number
    {
        const rect: DOMRect = element.getBoundingClientRect();
        const cssStyleDeclaration: CSSStyleDeclaration = getComputedStyle(element);
        const marginTop: number = parseFloat(cssStyleDeclaration.marginTop) || 0;
        const marginBottom: number = parseFloat(cssStyleDeclaration.marginBottom) || 0;

        return rect.height + marginTop + marginBottom;
    }

    private getElementActualWidth(element: HTMLElement): number
    {
        const rect: DOMRect = element.getBoundingClientRect();
        const cssStyleDeclaration: CSSStyleDeclaration = getComputedStyle(element);
        const marginLeft: number = parseFloat(cssStyleDeclaration.marginLeft) || 0;
        const marginRight: number = parseFloat(cssStyleDeclaration.marginRight) || 0;

        return rect.width + marginLeft + marginRight;
    }

    private refreshSizeUpdated(): void
    {
        if (this.contentElement === null || this.contentElement.children.length === 0 || this._listViewPortInfo.itemHeight === null)
        {
            return;
        }

        const itemHeight = this.getElementActualHeight(this.contentElement.children[0] as HTMLElement);
        if (itemHeight !== this._listViewPortInfo.itemHeight)
        {
            this._headerHeight = null;
            this._offsetHeight = null;
            this._offsetWidth = null;

            this._listViewPortInfo.itemHeight = itemHeight;
            this.virtualListInfo.itemHeight = itemHeight;
            this.refreshItems(RefreshState.ItemsResize);
            return;
        }

        this._offsetHeight = null;

        if (this._listViewPortInfo.viewPortHeight !== this.offsetHeight && this.offsetHeight !== null)
        {
            const scrollTop: number = this.scrollTop * (this._listViewPortInfo.viewPortHeight / this.offsetHeight);
            if (this.scrollTop === scrollTop)
            {
                this.refreshItems(RefreshState.ItemsScroll);
            }
            else
            {
                this.scrollTop = scrollTop;
            }
        }

        this._offsetWidth = null;

        if (this._listViewPortInfo.viewPortWidth !== this.offsetWidth)
        {
            this.refreshItems(RefreshState.ItemsResize);
        }
    }

    private observeAddedElementsIntoView(refreshStartState: RefreshState): void
    {
        if (this.contentElement === null)
        {
            return;
        }

        const observer: MutationObserver = new MutationObserver((mutations: MutationRecord[]) =>
        {
            for (const mutation of mutations)
            {
                if (mutation.addedNodes.length > 0)
                {
                    this.refreshItems(RefreshState.ItemsCalculate, refreshStartState);
                    break;
                }
            }

            observer.disconnect();
        });

        observer.observe(this.contentElement, { attributes: false, childList: true, subtree: false, characterData: false });
    }

    private refreshItems(refreshState: RefreshState, refreshStartState?: RefreshState): void
    {
        this._ngZone.runOutsideAngular(() =>
        {
            requestAnimationFrame(() =>
            {
                if (refreshState === RefreshState.ItemsChange)
                {
                    if (this._filteredSortedItems.length === 0)
                    {
                        this._viewPortItems = [];
                        this.calculateItems(refreshState, refreshStartState);

                        this._listViewPortInfo = new ListViewPortInfo();
                    }
                    else if (this._viewPortItems.length === 0)
                    {
                        this.observeAddedElementsIntoView(refreshState);

                        this._viewPortItems = [this._filteredSortedItems[0]];
                        this._listViewPortInfo = new ListViewPortInfo();
                        this._listViewPortInfo.endIndex = 1;

                        this._ngZone.run(() => this._changeDetectorRef.markForCheck());
                    }
                    else
                    {
                        this._listViewPortInfo.startIndex = 0;
                        this._listViewPortInfo.endIndex = 0;

                        this.calculateItems(refreshState, refreshStartState);
                    }
                }
                else if (refreshState === RefreshState.ItemsCalculate || refreshState === RefreshState.ItemsScroll || refreshState === RefreshState.ItemsResize)
                {
                    this.calculateItems(refreshState, refreshStartState);

                    if (!this._isScrollTopInitialized)
                    {
                        this._isScrollTopInitialized = true;

                        if (this.virtualListInfo.scrollToItem !== null)
                        {
                            setTimeout(() =>
                            {
                                this.scrollIntoView(this.virtualListInfo.scrollToItem, true);
                                this.virtualListInfo.scrollToItem = null;

                                this.calculateItems(RefreshState.ItemsScroll);
                            });
                        }
                        else
                        {
                            setTimeout(() =>
                            {
                                this.scrollTop = this.virtualListInfo.scrollTop ? this.virtualListInfo.scrollTop : 0;
                                this.calculateItems(RefreshState.ItemsScroll);
                            });
                        }
                    }
                }
            });
        });
    }

    private clearDragStartDelayTimeout(): void
    {
        if (this._dragStartDelayTimeout !== null)
        {
            clearTimeout(this._dragStartDelayTimeout);
            this._dragStartDelayTimeout = null;
        }
    }

    private clearSnapScrollTimeout(): void
    {
        if (this._snapScrollTimeoutHandleId !== null)
        {
            clearTimeout(this._snapScrollTimeoutHandleId);
            this._snapScrollTimeoutHandleId = null;
        }
    }

    private clearUpdateViewDelayTimeout(): void
    {
        if (this._updateViewDelayTimeoutHandleId !== null)
        {
            clearTimeout(this._updateViewDelayTimeoutHandleId);
            this._updateViewDelayTimeoutHandleId = null;
        }
    }

    private clearMergeMultipleScrollItemsSubscription(): void
    {
        if (this._mergeMultipleScrollItemsSubscription !== null)
        {
            this._mergeMultipleScrollItemsSubscription.unsubscribe();
            this._mergeMultipleScrollItemsSubscription = null;
        }
    }

    private updateViewPortInfo(): void
    {
        if (this.contentElement === null)
        {
            return;
        }

        if (this._listViewPortInfo.itemHeight === null && this.contentElement.children.length > 0)
        {
            this._listViewPortInfo.itemHeight = this.getElementActualHeight(this.contentElement.children[0] as HTMLElement);
            this.virtualListInfo.itemHeight = this._listViewPortInfo.itemHeight;

            if (this.maxViewportItems !== null)
            {
                const cssStyleDeclaration: CSSStyleDeclaration = getComputedStyle(this.element);
                const borderSize: number = parseFloat(cssStyleDeclaration.borderTopWidth) + parseFloat(cssStyleDeclaration.borderBottomWidth);

                this._renderer.setStyle(this.element, 'max-height',
                    `${this._listViewPortInfo.itemHeight * this.maxViewportItems / this.itemsPerRow + borderSize}px`);
                this._renderer.setStyle(this.element, 'height', 'auto');
            }
            else if (this.isAutoSizeToContent)
            {
                this._renderer.setStyle(this.element, 'height', `${Math.round(this._listViewPortInfo.itemHeight)}px`);
            }
        }

        let scrollHeight: number = 0;
        let visibleItemsStartIndex: number = 0;
        let visibleItemsEndIndex: number = 0;
        let contentOffset: number = 0;

        if (this._headerHeight === null)
        {
            if (this._headerElement !== null)
            {
                this._headerHeight = this.getElementActualHeight(this._headerElement);
            }
            else
            {
                this._headerHeight = 0;
            }
        }

        if (this._listViewPortInfo.itemHeight !== null)
        {
            scrollHeight = Math.round(Math.max(1, Math.round(this._filteredSortedItems.length / this.itemsPerRow)) * this._listViewPortInfo.itemHeight) + this._headerHeight;
            visibleItemsStartIndex = Math.max(0, Math.floor(this.scrollTop / this._listViewPortInfo.itemHeight) -
                this.EXTRA_VIEWPORT_ITEMS) * this.itemsPerRow;
            visibleItemsEndIndex = Math.min(this._filteredSortedItems.length, Math.floor((this.scrollTop + this.offsetHeight) /
                this._listViewPortInfo.itemHeight) + this.EXTRA_VIEWPORT_ITEMS) * this.itemsPerRow;

            contentOffset = Math.floor(visibleItemsStartIndex / this.itemsPerRow * this._listViewPortInfo.itemHeight);
        }

        this._listViewPortInfo.viewPortWidth = this.offsetWidth;
        this._listViewPortInfo.viewPortHeight = this.offsetHeight;
        this._listViewPortInfo.scrollTop = this.scrollTop;
        this._listViewPortInfo.scrollLeft = this.element.scrollLeft;
        this._listViewPortInfo.startIndex = visibleItemsStartIndex;
        this._listViewPortInfo.endIndex = visibleItemsEndIndex;
        this._listViewPortInfo.scrollHeight = scrollHeight;
        this._listViewPortInfo.scrollWidth = this.element.scrollWidth;
        this._listViewPortInfo.contentOffset = contentOffset;
    }

    private calculateItems(refreshState: RefreshState, refreshStartState: RefreshState | null = null): void
    {
        if (this.contentElement === null || this.invisiblePaddingElement === null)
        {
            return;
        }

        const oldViewPortInfo: ListViewPortInfo = Object.assign({}, this._listViewPortInfo);

        this.updateViewPortInfo();

        this._renderer.setStyle(this.invisiblePaddingElement, 'height', `${this._listViewPortInfo.scrollHeight + this.contentOffset}px`);

        const transformValue: string = `translate3d(0px, ${this._listViewPortInfo.contentOffset}px, 0px)`;

        this._renderer.setStyle(this.contentElement, 'transform', transformValue);
        this._renderer.setStyle(this.contentElement, 'webkitTransform', transformValue);

        if (!Utils.isNullOrUndefined(this._headerElement))
        {
            this._renderer.setStyle(this._headerElement, 'top', `${-this._listViewPortInfo.contentOffset}px`);
        }

        if (oldViewPortInfo.startIndex !== this._listViewPortInfo.startIndex || oldViewPortInfo.endIndex !== this._listViewPortInfo.endIndex)
        {
            this._viewPortItems = this._filteredSortedItems.slice(this._listViewPortInfo.startIndex, this._listViewPortInfo.endIndex);

            this._ngZone.run(() =>
            {
                if (this._isMouseScroll)
                {
                    this.clearUpdateViewDelayTimeout();

                    this._updateViewDelayTimeoutHandleId = setTimeout(() =>
                    {
                        this.clearUpdateViewDelayTimeout();
                        this._changeDetectorRef.markForCheck();
                    }, this.UPDATE_VIEW_DELAY_TIMEOUT_DURATION);
                }
                else
                {
                    this._changeDetectorRef.markForCheck();

                    if (refreshState === RefreshState.ItemsScroll && refreshStartState === null)
                    {
                        const scrollTop: number = this.scrollTop;
                        setTimeout(() => this.scrollTop = scrollTop);
                    }
                }
            });
        }

        if (refreshState === RefreshState.ItemsScroll || refreshStartState === RefreshState.ItemsScroll)
        {
            this.sendScrollInfoEvent();
        }

        if (refreshStartState === RefreshState.ItemsChange || refreshState === RefreshState.ItemsResize)
        {
            this.refreshListMinWidth();

            if (refreshStartState === RefreshState.ItemsChange)
            {
                this._ngZone.run(() => this.itemsChange.emit(this._filteredSortedItems));
            }

            if (this.isScrollSnap)
            {
                this.initiateSnapScrollPosition();
            }
        }
    }

    private addItemToArray(items: any[], item: any, index: number | null = null)
    {
        if (index === null)
        {
            items.push(item);
        }
        else
        {
            items.splice(index, 0, item);
        }
    }

    private clearSmoothScrollToPositionSubscription(): void
    {
        if (this._smoothScrollToPositionSubscription !== null)
        {
            this._smoothScrollToPositionSubscription.unsubscribe();
            this._smoothScrollToPositionSubscription = null;
        }
    }

    private scrollToPosition(targetPosition: number, immidiate: boolean = false, scrollToPositionCallback: Function | null = null): void
    {
        if (targetPosition < 0 || this._listViewPortInfo.scrollHeight === 0 || this._filteredSortedItems.length === 0)
        {
            if (scrollToPositionCallback !== null)
            {
                scrollToPositionCallback();
            }

            return;
        }

        if (immidiate)
        {
            this.scrollTop = targetPosition;

            if (scrollToPositionCallback !== null)
            {
                scrollToPositionCallback();
            }
        }
        else
        {
            this.clearSmoothScrollToPositionSubscription();

            this._ngZone.runOutsideAngular(() =>
            {
                this._smoothScrollToPositionSubscription = Utils.smoothTransition(this.scrollTop, targetPosition,
                    AnimationsConstants.SMOOTH_SCROLL_DURATION).subscribe((position: number) =>
                    {
                        this.scrollTop = position;

                        if (position === targetPosition)
                        {
                            this.clearSmoothScrollToPositionSubscription();
                        }
                    });
            });
        }
    }

    private refreshSorting(): void
    {
        const listColumnsElements: HTMLElement[] = this.getListColumnsElements();
        if (listColumnsElements.length === 0 || !this.isSortable)
        {
            return;
        }

        if (this.virtualListInfo.sortColumn !== null && listColumnsElements.length <= this.virtualListInfo.sortColumn)
        {
            this.virtualListInfo.sortColumn = null;
        }
        else if (!Utils.isNullOrUndefined(this.virtualListInfo.sortColumn) && this.virtualListInfo.sortColumn !== -1 && this.virtualListInfo.sortOrder !== null)
        {
            this.sortColumnElement(listColumnsElements[this.virtualListInfo.sortColumn!], this.virtualListInfo.sortOrder, false);
        }
    }

    private sortColumnElement(element: HTMLElement, sortOrder: number, fireSortEvent: boolean = true): void
    {
        if (this._headerElement === null)
        {
            return;
        }

        this.virtualListInfo.sortOrder = sortOrder;

        this._headerElement.setAttribute(this.SORT_DIRECTION_NAME, sortOrder.toString());

        const listColumnsElements: HTMLElement[] = this.getListColumnsElements();

        for (let i = 0; i < listColumnsElements.length; i++)
        {
            const columnElement: HTMLElement = listColumnsElements[i];
            if (columnElement.firstElementChild !== null)
            {
                const sortSignElement: HTMLElement = columnElement.querySelector(`[class*=${this.SORT_CLASS_NAME}]`) as HTMLElement;
                if (sortSignElement !== null)
                {
                    if (columnElement === element)
                    {
                        this.virtualListInfo.sortColumn = i;
                        sortSignElement.classList.remove(this.SORT_CLASS_NAME);
                        sortSignElement.classList.add(this.SORT_DOWN_CLASS_NAME);
                    }
                    else
                    {
                        sortSignElement.classList.remove(this.SORT_DOWN_CLASS_NAME);
                        sortSignElement.classList.add(this.SORT_CLASS_NAME);
                    }
                }
            }
        }

        this._filteredSortedItems = orderBy(this._filteredItems,
            [element.getAttribute(element.hasAttribute(this.COLUMN_SORT_PROPERTY) ? this.COLUMN_SORT_PROPERTY : this.COLUMN_PROPERTY) ?? ''],
            [sortOrder === 1 ? 'asc' : 'desc']);

        this.refreshItems(RefreshState.ItemsChange);

        if (fireSortEvent)
        {
            this.sorted.emit();
        }
    }

    private addSorttingButton(columnElement: HTMLElement): void
    {
        const node = this._renderer.createElement("i");
        node.classList.add(this.SORT_CLASS_NAME);
        columnElement.appendChild(node);

        columnElement.addEventListener('click', this.onColumnHeaderClick.bind(this));
    }

    private getListColumnsElements(): HTMLElement[]
    {
        if (this._headerElement === null)
        {
            return [];
        }

        return Array.from(this._headerElement.querySelectorAll(`.${this.COLUMN_CLASSNAME}`));
    }

    private refreshListMinWidth(): void
    {
        const listColumnsElements: HTMLElement[] = this.getListColumnsElements();
        if (listColumnsElements.length === 0)
        {
            return;
        }

        this.clearStyleSheetRules(listColumnsElements.length);

        this._columnInfoList = [];

        for (const columnElement of listColumnsElements)
        {
            const listColumnInfo: ListColumnInfo = new ListColumnInfo();

            const cssStyleDeclaration: CSSStyleDeclaration = getComputedStyle(columnElement);

            listColumnInfo.padding = Number(cssStyleDeclaration.paddingLeft.slice(0, -2)) * 2 + Number(cssStyleDeclaration.paddingRight.slice(0, -2));

            let colMinWidth: number = cssStyleDeclaration.minWidth !== 'auto' && cssStyleDeclaration.minWidth !== 'none' ?
                parseFloat(cssStyleDeclaration.minWidth) + listColumnInfo.padding : 0;
            let colMaxWidth: number = cssStyleDeclaration.maxWidth !== 'auto' && cssStyleDeclaration.maxWidth !== 'none' ?
                parseFloat(cssStyleDeclaration.maxWidth) : 0;

            listColumnInfo.isFixedWidth = columnElement.hasAttribute(this.COLUMN_FIXED_WIDTH_PROPERTY);
            listColumnInfo.propertyName = columnElement.getAttribute(this.COLUMN_PROPERTY);
            listColumnInfo.extraWidth = parseFloat(columnElement.getAttribute(this.COLUMN_EXTRA_WIDTH) ?? '0');

            if (!Utils.isNullOrUndefined(listColumnInfo.propertyName))
            {
                let sortWidth: number = 0;
                if (this.isSortable)
                {
                    let sortSignElement: HTMLElement = columnElement.querySelector(`[class*=${this.SORT_CLASS_NAME}]`) as HTMLElement;
                    if (Utils.isNullOrUndefined(sortSignElement))
                    {
                        this.addSorttingButton(columnElement);
                        sortSignElement = columnElement.querySelector(`[class*=${this.SORT_CLASS_NAME}]`) as HTMLElement;
                    }

                    sortWidth = sortSignElement.clientWidth;

                    if (colMinWidth > 0)
                    {
                        colMinWidth += sortWidth;
                    }
                }

                listColumnInfo.minWidth = (listColumnInfo.isFixedWidth && colMaxWidth > 0 ? colMaxWidth :
                    (this.getTextWidth(columnElement.innerText) * this.HEADER_FONT_SIZE_MULTIPLIER + listColumnInfo.padding + sortWidth)) +
                    listColumnInfo.extraWidth;
            }
            else
            {
                listColumnInfo.minWidth = columnElement.clientWidth;
            }

            listColumnInfo.minHeaderWidth = colMinWidth;

            this._columnInfoList.push(listColumnInfo);
        }

        for (let i: number = 0; i < this._items.length; i++)
        {
            this.updateMinWidthByItem(this._items[i], this._itemsColumnsMinWidths[i]);
        }

        this.updateListMinWidth();
    }

    private updateMinWidthByItem(item: any, itemColumnsMinWidths: any): boolean
    {
        let isUpdated: boolean = false;

        for (const columnInfo of this._columnInfoList)
        {
            if (columnInfo.isFixedWidth || columnInfo.propertyName === null)
            {
                continue;
            }

            const value: any = Utils.getItemNestedPropertyValue(item, columnInfo.propertyName);
            if (!Utils.isNullOrUndefined(value))
            {
                let valueWidth = itemColumnsMinWidths[columnInfo.propertyName];
                if (valueWidth === undefined)
                {
                    valueWidth = this.getTextWidth(value.toString()) + columnInfo.padding + columnInfo.extraWidth;
                    itemColumnsMinWidths[columnInfo.propertyName] = valueWidth;
                }

                if (valueWidth > columnInfo.minWidth)
                {
                    columnInfo.minWidth = valueWidth;
                    isUpdated = true;
                }
            }
        }

        return isUpdated;
    }

    private updateListMinWidth(): void
    {
        let rowMinWidth: number = 0;
        for (const columnInfo of this._columnInfoList)
        {
            rowMinWidth += columnInfo.minWidth;
        }

        const cssRowMinWidth: string = `${rowMinWidth.toString()}px`;

        if (this.contentElement !== null)
        {
            this.contentElement.style.minWidth = cssRowMinWidth;
        }

        if (this.isAutoWidthByRow)
        {
            this.element.style.width = cssRowMinWidth;
        }

        if (this.isAutoColumnsWidths)
        {
            this.updateStyleSheetWidths(rowMinWidth);
        }
    }

    private getListStyleSheet(): CSSStyleSheet | null
    {
        let cssListStyleSheet: CSSStyleSheet | null = null;

        for (let i = 0; i < document.styleSheets.length; i++)
        {
            const csshref: string | null = document.styleSheets[i].href;
            if (csshref !== null && !csshref.startsWith(location.origin))
            {
                continue;
            }

            const cssStyleSheet: CSSStyleSheet = document.styleSheets[i];
            for (let j = 0; j < cssStyleSheet.cssRules.length; j++)
            {
                const cssRule: CSSRule | null = cssStyleSheet.cssRules.item(j);
                if (cssRule !== null && cssRule.cssText.indexOf('#' + this.element.id) >= 0)
                {
                    cssListStyleSheet = cssStyleSheet;
                    break;
                }
            }

            if (cssListStyleSheet !== null)
            {
                break;
            }
        }

        return cssListStyleSheet;
    }

    private clearStyleSheetRules(listColumnsCount: number): void
    {
        const cssListStyleSheet: CSSStyleSheet | null = this.getListStyleSheet();

        if (cssListStyleSheet === null)
        {
            return;
        }

        for (let i: number = 0; i < listColumnsCount; i++)
        {
            const ruleCssChildValue: string = `:nth-child(${(i + 1).toString()})`;

            for (let j = 0; j < cssListStyleSheet.cssRules.length; j++)
            {
                const cssRule: CSSRule | null = cssListStyleSheet.cssRules.item(j);

                if (cssRule !== null && cssRule.cssText.startsWith('#' + this.element.id) && cssRule.cssText.indexOf(ruleCssChildValue) > 0)
                {
                    cssListStyleSheet.deleteRule(j);
                    break;
                }
            }
        }
    }

    private updateStyleSheetRules(rowMinWidth: number, rowColumnsWidths: number[]): void
    {
        const cssListStyleSheet: CSSStyleSheet | null = this.getListStyleSheet();

        if (cssListStyleSheet === null)
        {
            return;
        }

        for (let i: number = 0; i < this._columnInfoList.length; i++)
        {
            let isRuleUpdated: boolean = false;

            let ruleCssStyle: string = 'min-width: auto !important; width: ';

            if (!this._columnInfoList[i].isFixedWidth && !Utils.isNullOrUndefined(this._columnInfoList[i].propertyName))
            {
                ruleCssStyle += `${(rowColumnsWidths[i] * 100 / rowMinWidth).toString()}%;`;
            }
            else
            {
                ruleCssStyle += `${this._columnInfoList[i].minWidth.toString()}px;`;
            }

            const ruleCssChildValue: string = `:nth-child(${(i + 1).toString()})`;

            for (let j = 0; j < cssListStyleSheet.cssRules.length; j++)
            {
                const cssRule: CSSRule | null = cssListStyleSheet.cssRules.item(j);

                if (cssRule !== null && cssRule.cssText.startsWith('#' + this.element.id) && cssRule.cssText.indexOf(ruleCssChildValue) > 0)
                {
                    const ruleCssText: string = `${cssRule.cssText.substring(0, cssRule.cssText.indexOf('{'))}{ ${ruleCssStyle} }`;

                    cssListStyleSheet.deleteRule(j);
                    cssListStyleSheet.insertRule(ruleCssText, j);

                    isRuleUpdated = true;
                    break;
                }
            }

            if (!isRuleUpdated)
            {
                const ruleCss: string = `#${this.element.id} .list-header .col-list${ruleCssChildValue}, #${this.element.id} .list-row .col-list${ruleCssChildValue} { ${ruleCssStyle} }`;
                cssListStyleSheet.insertRule(ruleCss);
            }
        }
    }

    private updateStyleSheetWidths(rowMinWidth: number): void
    {
        const isListHorizontalOverflow: boolean = rowMinWidth > this.element.clientWidth;

        const rowColumnsWidths: number[] = [];
        let updatedRowMinWidth: number = 0;

        const resizableColumnsIndexes: number[] = [];
        let resizableColumnsWidths: number = 0;

        for (let i: number = 0; i < this._columnInfoList.length; i++)
        {
            const listColumnInfo: ListColumnInfo = this._columnInfoList[i];
            if (!listColumnInfo.isFixedWidth && isListHorizontalOverflow && listColumnInfo.minHeaderWidth > 0 &&
                listColumnInfo.minWidth > listColumnInfo.minHeaderWidth)
            {
                updatedRowMinWidth += listColumnInfo.minHeaderWidth;
                rowColumnsWidths.push(listColumnInfo.minHeaderWidth);
            }
            else
            {
                updatedRowMinWidth += listColumnInfo.minWidth;
                rowColumnsWidths.push(listColumnInfo.minWidth);
            }

            if (!listColumnInfo.isFixedWidth && !Utils.isNullOrUndefined(listColumnInfo.propertyName))
            {
                resizableColumnsIndexes.push(i);
                resizableColumnsWidths += listColumnInfo.minWidth;
            }
        }

        if (updatedRowMinWidth < this.element.clientWidth)
        {
            if (resizableColumnsIndexes.length > 0)
            {
                for (const index of resizableColumnsIndexes)
                {
                    rowColumnsWidths[index] = rowColumnsWidths[index] +
                        (this.element.clientWidth - updatedRowMinWidth) * this._columnInfoList[index].minWidth / resizableColumnsWidths;
                }
            }

            updatedRowMinWidth = this.element.clientWidth;
        }

        const cssRowMinWidth = `${updatedRowMinWidth}px`;

        if (this.contentElement !== null)
        {
            this.contentElement.style.minWidth = cssRowMinWidth;
        }

        this.updateStyleSheetRules(updatedRowMinWidth, rowColumnsWidths);
    }

    private getTextWidth(text: string): number
    {
        return this._documentCanvasContext === null ? 0 : this._documentCanvasContext.measureText(text).width;
    }

    private getFilteredItems(): any[]
    {
        if (this.virtualListInfo.filter === '')
        {
            return [...this._items];
        }

        const filterText: string = this.getFilterText();

        const itemsFiltered: any[] = [];

        for (const item of this._items)
        {
            if (this.isItemFiltered(item, filterText))
            {
                itemsFiltered.push(item);
            }
        }

        return itemsFiltered;
    }

    private getFilterText(): string
    {
        return this.virtualListInfo.filter !== null ? this.virtualListInfo.filter.toLowerCase() : '';
    }

    private isItemFiltered(item: any, filterText: string): boolean
    {
        if (filterText === '')
        {
            return true;
        }

        let isFiltered: boolean = false;

        let propetiesNames: string[] = Object.getOwnPropertyNames(item);
        if (this.filterProperties.length > 0)
        {
            propetiesNames = propetiesNames.filter((propetiesName: string) => this.filterProperties.includes(propetiesName));
        }
        else if (typeof item !== 'object')
        {
            return item.toString().toLowerCase().indexOf(filterText) >= 0;
        }

        for (const propetiesName of propetiesNames)
        {
            const value: any = item[propetiesName];
            if (!Utils.isNullOrUndefined(value))
            {
                const stringValue: string = value.toString();
                if (stringValue.length > 0 && stringValue.toLowerCase().indexOf(filterText) >= 0)
                {
                    isFiltered = true;
                    break;
                }
            }
        }

        return isFiltered;
    }

    // #endregion
}