import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { fadeInOutAnimation } from '../../animations/fade.animation';
import { foldAnimation } from '../../animations/fold.animation';
import { ModalType } from '../../controls/modal-message/modal-message.component';
import { ModalBodyAlignType, ModalMessageService } from '../../controls/modal-message/services/modal-message.service';
import { Constants, IApiResponse } from '../../utils/globals';
import { ChangePasswordModel } from './model/change-password.model';
import { LoginModel } from '../login/model/login.model';
import { RoutingHistoryService } from '../../services/routing-history.service';
import { RequiredValidatorDirective } from '../../validators/required.validator';
import { PasswordInputComponent } from '../../controls/password-input/password-input.component';
import { MatchPasswordsDirective } from '../../validators/match-passwords.validator';
import { FormsModule } from '@angular/forms';
import { PasswordValidatorDirective } from '../../validators/password.validator';

@Component({
    selector: 'change-password',
    templateUrl: './change-password.component.html',
    styleUrls: ['../login/login.component.css', './change-password.component.css'],
    animations: [foldAnimation, fadeInOutAnimation],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [FormsModule, MatchPasswordsDirective, PasswordInputComponent, RequiredValidatorDirective, PasswordValidatorDirective]
})
export class ChangePasswordComponent implements OnDestroy
{
    // #region Private Members

    private _changePasswordSubscription: Subscription | null = null;

    // #endregion

    // #region Properties

    public get changePasswordModel(): ChangePasswordModel
    {
        return this._changePasswordModel;
    }

    // #endregion

    // #region Constructor

    constructor(private _changePasswordModel: ChangePasswordModel, private _changeDetectorRef: ChangeDetectorRef,
        private _modalMessageService: ModalMessageService, private _loginModel: LoginModel, private _routingHistoryService: RoutingHistoryService)
    {
        this._changePasswordModel.clear();
    }

    // #endregion

    // #region Event Handlers

    public ngOnDestroy(): void
    {
        if (this._changePasswordSubscription !== null)
        {
            this._changePasswordSubscription.unsubscribe();
            this._changePasswordSubscription = null;
        }
    }

    public onBackButtonClick(): void
    {
        this._routingHistoryService.navigateBack(true);
    }

    public onChangePasswordSubmit(): void
    {
        this._changePasswordSubscription = this._changePasswordModel.changePassword().subscribe((response: IApiResponse) =>
        {
            if (response.isSuccess)
            {
                this._modalMessageService.show(
                    {
                        title: Constants.APP_TITLE,
                        message: 'Your password was updated successfully',
                        modlaBodyAlign: ModalBodyAlignType.Left
                    }).then(() => this._loginModel.logout(true));
            }
            else
            {
                this._changeDetectorRef.detectChanges();
                this._modalMessageService.show(
                    {
                        title: Constants.APP_TITLE,
                        message: response.message,
                        modalType: ModalType.Error,
                        modlaBodyAlign: ModalBodyAlignType.Left
                    });
            }
        });
    }

    // #endregion
}
