import { ChangeDetectorRef, Component, ElementRef, EventEmitter, HostBinding, Input, Output, ViewChild } from '@angular/core';
import { AnimationBuilder } from '@angular/animations';
import { ControlValueAccessor } from '@angular/forms';
import { default as moment } from "moment";
import { DateTimeFormatType, Utils } from '../../../utils/utils';
import { DropdownBaseComponent, DropdownState } from '../../../base/components/dropdown-base.component';
import { Constants } from '../../../utils/globals';
import { AppSettingsService } from '../../../services/app-settings.service';

@Component({
    template: ''
})
export class TimePickerBaseComponent extends DropdownBaseComponent implements ControlValueAccessor
{
    // #region Private Memers

    private _label: string = '';

    private _changed = new Array<(value: Date | null) => void>();
    private _touched = new Array<() => void>();

    @ViewChild('input', { read: ElementRef, static: false })
    private _inputElementRef: ElementRef<HTMLInputElement> | undefined = undefined;

    // #endregion

    // #region Protected Memers

    protected _innerValue: Date | null = null;
    protected _innerEditValue: Date | null = null;
    protected _currentMoment: moment.Moment = Utils.getMoment();

    // #endregion

    // #region Proterties

    public get value(): Date | null
    {
        return this._innerValue;
    }

    public set value(date: Date | null)
    {
        if (this._innerValue !== date)
        {
            this._innerValue = date;
            this._changed.forEach(f => f(date));
            this._touched.forEach(f => f());
            this.change.emit(date);

            this._currentMoment = date !== null && date !== undefined ? Utils.getMoment(date) : Utils.getMoment();
        }
    }

    public get editValue(): Date | null
    {
        return this._innerEditValue;
    }

    public set editValue(date: Date | null)
    {
        if (this._innerEditValue !== date)
        {
            this._innerEditValue = date;
            this.editValueChange.emit(date);

            this._currentMoment = date !== null && date !== undefined ? Utils.getMoment(date) : Utils.getMoment();

            if (!Constants.IS_MOBILE)
            {
                this.value = this._innerEditValue;
            }
        }
    }

    public get formattedTimeValue(): string | null
    {
        return this._innerEditValue !== null && this._innerEditValue !== undefined ?
            Utils.getFormattedDateTime(this._innerEditValue, DateTimeFormatType.Time, this.time24Hours) : null;
    }

    // #endregion

    // #region Inputs

    @HostBinding('class.disabled') @Input() public disabled: boolean = false;

    @Input() public time24Hours: boolean = this._appSettingsService.appSettings.isUsingTime24Hours;
    @Input() public isRequired: boolean = false;
    @Input() public autoSelect: boolean = true;

    @Input()
    public get label(): string
    {
        return this._label;
    }

    public set label(value: string)
    {
        this._label = `${value}${this._appSettingsService.appSettings.isUsingUTCTime ? ' <span class="small-units">(UTC)</span>' : ''}`;
    }

    // #endregion

    // #region Events

    @Output() private change: EventEmitter<Date | null> = new EventEmitter<Date | null>();
    @Output() private editValueChange: EventEmitter<Date | null> = new EventEmitter<Date | null>();

    // #endregion

    // #region Constractor

    constructor(_elementRef: ElementRef<HTMLElement>, _changeDetectorRef: ChangeDetectorRef, _animationBuilder: AnimationBuilder,
        protected _appSettingsService: AppSettingsService)
    {
        super(_elementRef, _changeDetectorRef, _animationBuilder);

        moment.updateLocale(this._appSettingsService.appSettings.localeId, null);
    }

    // #endregion

    // #region Event Handlers

    public onApplyClick(): void
    {
        this.value = this.editValue;
        this.close(true);
    }

    public onCloseClick(): void
    {
        this.close(true);
    }

    public onClearMouseDown(event: MouseEvent): void
    {
        event.preventDefault();
        event.stopPropagation();

        this.value = null;
        this.editValue = null;
    }

    public onInputGroupMouseDown(event: MouseEvent): void
    {
        event.preventDefault();

        if (this._dropdownState === DropdownState.Open)
        {
            this.close(true);
        }
        else
        {
            this.open();
        }
    }

    // #endregion

    // #region Public Methods

    public touch(): void
    {
        this._touched.forEach(f => f());
    }

    public writeValue(value: Date | null): void
    {
        if (value === undefined)
        {
            value = null;
        }

        this._innerValue = value;

        if (this._innerValue !== null && typeof this._innerValue === 'string')
        {
            this._innerValue = new Date(this._innerValue);
        }
    }

    public registerOnChange(fn: (value: Date | null) => void): void
    {
        this._changed.push(fn);
    }

    public registerOnTouched(fn: () => void): void
    {
        this._touched.push(fn);
    }

    // #endregion

    // #region Protected Method

    protected override close(retainFocus: boolean): void
    {
        if (this._dropdownState !== DropdownState.Closed)
        {
            if (this._inputElementRef !== undefined)
            {
                if (retainFocus)
                {
                    this._inputElementRef.nativeElement.focus();
                }
                else
                {
                    this._inputElementRef.nativeElement.blur();
                }
            }

            if (Constants.IS_MOBILE)
            {
                this._dropdownState = DropdownState.Closed;
            }
        }

        super.close(retainFocus);
    }

    protected override open(): void
    {
        if (this._dropdownState === DropdownState.Closed)
        {
            if (this._inputElementRef !== undefined)
            {
                this._inputElementRef.nativeElement.setSelectionRange(0, 0);
                this._inputElementRef.nativeElement.focus();
            }

            if (this.autoSelect)
            {
                this.editValue = this.value !== null && this.value !== undefined ? this.value : this._currentMoment.toDate();
            }

            if (Constants.IS_MOBILE)
            {
                this._dropdownState = DropdownState.Open;
                return;
            }
        }

        super.open();
    }

    // #endregion
}
