import { ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit, ViewChild } from "@angular/core";
import { VirtualListInfo } from "../../controls/virtual-list/model/virtual-list-info";
import { Constants, IApiResponse, IApiResponseData, IListColumn } from "../../utils/globals";
import { Subscription } from "rxjs";
import { VirtualListComponent } from "../../controls/virtual-list/virtual-list.component";
import { Router } from "@angular/router";
import { RoutingHistoryService } from "../../services/routing-history.service";
import { AnimationBuilder, AnimationFactory, AnimationPlayer, animate, style } from "@angular/animations";
import { AppSettingsService } from "../../services/app-settings.service";
import { AnimationsConstants } from "../../animations/constant";
import { SwipeDirection } from "../../directives/pointer-events.directive";
import { Utils } from "../../utils/utils";
import * as XLSX from 'xlsx';
import { ModalMessageService } from "../../controls/modal-message/services/modal-message.service";
import { SavableBaseComponent } from "./savable-base.component";
import { BaseView } from "../classes/base-view";
import { ManagerBaseModel } from "../models/manager-base.model";
import { ManagerItemBaseComponent } from "./manager-item-base.component";
import { ModalType } from "../../controls/modal-message/modal-message.component";

@Component({ template: '' })
export class ManagerBaseComponent<BaseDataType, BaseSortColumnType> extends SavableBaseComponent implements
    OnInit, OnDestroy
{
    // #region Constants

    private readonly _gridViewColumnMinWidthSteps: number[] = [800, 1080];
    protected readonly EXCEL_FILE_PREFIX: string = 'Manager_';

    // #endregion

    // #region Protected Members

    protected static _managerItemsListInfo: VirtualListInfo = new VirtualListInfo();
    protected static _managerTableListInfo: VirtualListInfo = new VirtualListInfo();
    protected static _isGridView: boolean = Constants.IS_MOBILE;
    protected static _gridViewItemsPerRow: number = 1;

    protected _showAddNewManagerElement: boolean = false;
    protected _getManagerItemsSubscription: Subscription | null = null;

    protected _managerVirtualList: VirtualListComponent | null = null;
    @ViewChild('managerItemsList', { read: VirtualListComponent, static: false }) set managerVirtualList(managerVirtualList: VirtualListComponent | null)
    {
        if (managerVirtualList === undefined)
        {
            this._managerVirtualList = null;
        }
        else
        {
            this._managerVirtualList = managerVirtualList;
            if (ManagerBaseComponent._isGridView)
            {
                this.updateGridViewManagerItemsListItemsPerRow();
            }
        }
    }

    @ViewChild('managerItem', { read: BaseView, static: false }) protected _managerItemComponent: BaseView | undefined = undefined;

    protected _managerListColumns: IListColumn[] = [];
    protected _managerTableColumns: IListColumn[] = [];
    protected _managerTableSortColumns: IListColumn[] = [];
    protected _managerSearchProperties: string[] = [];

    // #endregion

    // #region Properties

    private get managerItemComponent(): ManagerItemBaseComponent<BaseDataType> | undefined
    {
        return this._managerItemComponent as ManagerItemBaseComponent<BaseDataType> | undefined;
    }

    public get showAddNewManagerElement(): boolean
    {
        return this._showAddNewManagerElement;
    }

    public get managerSearchProperties(): string[]
    {
        return this._managerSearchProperties;
    }

    public get managerListColumns(): IListColumn[]
    {
        return this._managerListColumns;
    }

    public get managerTableColumns(): IListColumn[]
    {
        return this._managerTableColumns;
    }

    public set managerTableColumns(value: IListColumn[])
    {
        this._managerTableColumns = value;

        this._managerSearchProperties = [];
        for (const column of this._managerTableColumns)
        {
            if (column.propertyName !== undefined)
            {
                this._managerSearchProperties.push(column.propertyName);
            }
        }
    }

    public get managerTableSortColumns(): IListColumn[]
    {
        return this._managerTableSortColumns;
    }

    public get managerItemsListInfo(): VirtualListInfo
    {
        return ManagerBaseComponent._managerItemsListInfo;
    }

    public get managerTableListInfo(): VirtualListInfo
    {
        return ManagerBaseComponent._managerTableListInfo;
    }

    public get managerVirtualList(): VirtualListComponent | null
    {
        return this._managerVirtualList;
    }

    public get isGridView(): boolean
    {
        return ManagerBaseComponent._isGridView;
    }

    public set isGridView(value: boolean)
    {
        ManagerBaseComponent._isGridView = value;
        this.updateGridViewMode();
    }

    public get gridViewItemsPerRow(): number
    {
        return ManagerBaseComponent._gridViewItemsPerRow;
    }

    public get managerModel(): ManagerBaseModel<BaseDataType, BaseSortColumnType> 
    {
        return this._managerModel;
    }

    // #endregion

    // #region Constructor

    constructor(protected _managerModel: ManagerBaseModel<BaseDataType, BaseSortColumnType>, protected _router: Router,
        protected _changeDetectorRef: ChangeDetectorRef, protected _routingHistoryService: RoutingHistoryService,
        protected _animationBuilder: AnimationBuilder, protected _appSettingsService: AppSettingsService,
        _modalMessageService: ModalMessageService)
    {
        super(_modalMessageService);

        ManagerBaseComponent._managerTableListInfo.sortOrder = this._managerModel.isSortDescending ? -1 : 1;
        ManagerBaseComponent._managerTableListInfo.sortColumn = this._managerModel.sortColumn as (number | null);
    }

    // #endregion

    // #region Event Handlers

    public ngOnInit(): void
    {
        if (this._routingHistoryService.isPopState && this.managerModel.isInitialized)
        {
            this.setViewState(true);
            this._changeDetectorRef.markForCheck();
        }
        else
        {
            ManagerBaseComponent._isGridView = Constants.IS_MOBILE;

            ManagerBaseComponent._managerItemsListInfo.scrollTop = null;
            ManagerBaseComponent._managerTableListInfo.scrollTop = null;

            this.managerItemsListInfo.clear();
            this.managerModel.clear();

            this.loadManagerElements();
        }
    }

    public ngOnDestroy(): void
    {
        this.clearGetMnagerItemsSubscription();
    }

    @HostListener('document:keydown.escape') onKeyUpHandler(): void
    {
        if (this._showAddNewManagerElement && this.managerItemComponent !== undefined && this.managerItemComponent.viewIsReady)
        {
            this.onCloseAddNewManagerElementClick();
        }
    }

    public onManagerItemsListResized(): void
    {
        this.updateGridViewManagerItemsListItemsPerRow();
    }

    public onManagerItemsListSwipe(swipeDirection: SwipeDirection): void
    {
        if (swipeDirection === SwipeDirection.Down && ManagerBaseComponent._managerItemsListInfo.scrollTop === 0)
        {
            this.loadManagerElements();
        }
    }

    public onCloseAddNewManagerElementClick(): void
    {
        this._showAddNewManagerElement = false;
    }

    public async onRefreshButtonClick(event: MouseEvent): Promise<void>
    {
        if (!await this.canDeactivate())
        {
            return;
        }

        const rotateAnimation: AnimationFactory = this._animationBuilder.build([
            style({ transform: 'rotate(0deg)' }),
            animate(AnimationsConstants.ROTATE_ANIMATION_DURATION, style({ transform: 'rotate(360deg)' }))
        ]);

        const animationPlayer: AnimationPlayer = rotateAnimation.create(event.currentTarget);
        animationPlayer.play();

        this._showAddNewManagerElement = false;

        this.loadManagerElements();
    }

    public onManagerItemsListChange(_items: any[]): void
    {
        this.scrollSelectedManagerItemIntoView();
    }

    public onSortDirectionButtonClick(): void
    {
        this._managerModel.isSortDescending = !this._managerModel.isSortDescending;
    }

    public onManagerItemsListSorted(): void
    {
        this.scrollSelectedManagerItemIntoView();
    }

    public onSubmitingManagerItem(response: IApiResponseData<BaseDataType>): void
    {
        this.viewIsReady = response.isComplete === true;
        if (response.message !== undefined)
        {
            this._statusMessage = response.message;
        }

        if (response.isSuccess)
        {
            if (response.isComplete)
            {
                this._showAddNewManagerElement = false;
                this.viewIsReady = false;

                if (response.data !== undefined)
                {
                    this._managerModel.selectedManagerItem = response.data;
                }

                this.loadManagerElements();
            }
        }
        else if (response.isComplete)
        {
            if (response.message !== undefined)
            {
                this._modalMessageService.show({ title: Constants.APP_TITLE, message: response.message, modalType: ModalType.Error }).then(() =>
                {
                    if (response.data !== undefined)
                    {
                        this._showAddNewManagerElement = false;
                        this.viewIsReady = false;

                        this._managerModel.selectedManagerItem = response.data;

                        this.loadManagerElements();

                        this._changeDetectorRef.detectChanges();
                    }
                });
            }
        }
        else
        {
            this._showAddNewManagerElement = false;
            this.viewIsReady = true;
        }

        this._changeDetectorRef.detectChanges();
    }

    public async onAddManagerItemButtonClick(): Promise<void>
    {
        if (!await this.canDeactivate())
        {
            return;
        }

        if (!Constants.IS_MOBILE)
        {
            this._showAddNewManagerElement = true;
            this._changeDetectorRef.detectChanges();
        }

        this.selectManagerItem(null);
    }

    public async onManagerItemClick(managerItem: BaseDataType, forceSelect: boolean = false): Promise<void>
    {
        if (!await this.canDeactivate())
        {
            return;
        }

        this.selectManagerItem(managerItem, forceSelect);
    }

    public onExportToExcelButtonClick(): void
    {
        this.exportToExel(this._managerTableColumns);
    }

    // #endregion

    // #region Protected Methods

    protected loadManagerItem(): void
    {
        this.managerItemComponent?.loadManagerItem();
    }

    protected updateGridViewMode(): void
    {
        if (ManagerBaseComponent._isGridView)
        {
            this._managerModel.updateManagerItemsSort(ManagerBaseComponent._managerTableListInfo.sortOrder === -1,
                (ManagerBaseComponent._managerTableListInfo.sortColumn ?? 0) as BaseSortColumnType);

            ManagerBaseComponent._managerItemsListInfo.scrollToItem = this._managerModel.selectedManagerItem;
        }
        else
        {
            ManagerBaseComponent._managerTableListInfo.sortOrder = this._managerModel.isSortDescending ? -1 : 1;
            ManagerBaseComponent._managerTableListInfo.sortColumn = this._managerModel.sortColumn as number | null;
            ManagerBaseComponent._managerTableListInfo.scrollToItem = this._managerModel.selectedManagerItem;
        }
    }

    protected updateLoadedSelectedManagerItem(_selectedManagerItem: BaseDataType): void
    {
    }

    protected loadManagerElements(loadManagerElementsCompletedCallback: (() => void) | null = null): void
    {
        this.clearGetMnagerItemsSubscription();

        this._statusMessage = Constants.LOADING_DATA_STRING;

        let selectedManagerItem: BaseDataType | null = this.managerModel.selectedManagerItem;

        this._getManagerItemsSubscription = this.managerModel.getManagerItems().subscribe((response: IApiResponse) =>
        {
            if (response.isSuccess)
            {
                if (!this._viewIsInitialized)
                {
                    this._viewIsInitialized = true;
                    this._changeDetectorRef.detectChanges();
                }

                if (response.isComplete)
                {
                    if (selectedManagerItem !== null)
                    {
                        this.updateLoadedSelectedManagerItem(selectedManagerItem);
                    }

                    if (selectedManagerItem !== null && this.managerModel.selectedManagerItem !== null)
                    {
                        selectedManagerItem = null;

                        if (Constants.IS_MOBILE)
                        {
                            this.scrollSelectedManagerItemIntoView();
                        }
                        else
                        {
                            this.loadManagerItem();
                        }
                    }

                    if (response.isSuccess)
                    {
                        if (loadManagerElementsCompletedCallback !== null)
                        {
                            loadManagerElementsCompletedCallback();
                        }

                        this.setViewState(true);
                    }
                }

                if (!response.isSuccess)
                {
                    this.setViewState(false, response.message);
                }
            }
            else if (!this._viewIsReady)
            {
                this.setViewState(response.isSuccess, response.message);
            }

            this._changeDetectorRef.detectChanges();
        });

        this._changeDetectorRef.detectChanges();
    }

    protected clearGetMnagerItemsSubscription(): void
    {
        if (this._getManagerItemsSubscription !== null)
        {
            this._getManagerItemsSubscription.unsubscribe();
            this._getManagerItemsSubscription = null;
        }
    }

    protected scrollSelectedManagerItemIntoView(): void
    {
        if (this._managerVirtualList !== null && this.managerModel.selectedManagerItem !== null)
        {
            this._managerVirtualList.scrollIntoView(this.managerModel.selectedManagerItem, true);
        }
    }

    protected selectManagerItem(managerItem: BaseDataType | null, forceSelect: boolean = false): void
    {
        if (!forceSelect && !Constants.IS_MOBILE && this.managerModel.selectedManagerItem === managerItem)
        {
            managerItem = null;
        }

        this.managerModel.selectedManagerItem = managerItem;
        this._changeDetectorRef.detectChanges();

        if (!Constants.IS_MOBILE)
        {
            this.loadManagerItem();
        }
    }

    protected initializeManagerTableColumns(): void
    {
    }

    protected exportToExel(exportManagerTableColumns: IListColumn[]): void
    {
        const exportData: any[] = this._managerModel.filteredSortedManagerItems.map((managerItem: BaseDataType) =>
        {
            const rowData: any = {};

            for (const shipmentColumn of exportManagerTableColumns)
            {
                if (shipmentColumn.text !== undefined && shipmentColumn.propertyName !== undefined)
                {
                    rowData[Utils.removeTextHtmlTags(shipmentColumn.text)] = (managerItem as any)[shipmentColumn.propertyName];
                }
            }

            return rowData;
        });

        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportData);
        const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };

        XLSX.writeFile(workbook, Utils.getFileDateName(this.EXCEL_FILE_PREFIX, '.xlsx', new Date()), { compression: true });
    }

    // #endregion

    // #region Private Members

    private updateGridViewManagerItemsListItemsPerRow(): void
    {
        if (!Constants.IS_MOBILE && this._managerVirtualList !== null)
        {
            let itemsPerRow: number = this._gridViewColumnMinWidthSteps.length + 1;
            for (let i: number = 0; i < this._gridViewColumnMinWidthSteps.length; i++)
            {
                if (this._managerVirtualList.element.clientWidth < this._gridViewColumnMinWidthSteps[i])
                {
                    itemsPerRow = i + 1;
                    break;
                }
            }

            if (ManagerBaseComponent._gridViewItemsPerRow !== itemsPerRow)
            {
                ManagerBaseComponent._gridViewItemsPerRow = itemsPerRow;
                this._changeDetectorRef.detectChanges();
            }
        }
    }

    // #endregion
}