import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, EventEmitter, HostListener, Output } from '@angular/core';
import { LoginModel } from '../user/login/model/login.model';
import { UserInfo } from '../user/login/model/login-model.class';
import { AppColorThemeMode, AppSettingsService } from '../services/app-settings.service';
import { Router } from '@angular/router';
import { ModalMessageService } from '../controls/modal-message/services/modal-message.service';
import { ModalButtonType, ModalIconType, ModalResultType } from '../controls/modal-message/modal-message.component';
import { Constants, IIdValue, KeyValue, RouteType } from '../utils/globals';
import { foldAnimation } from '../animations/fold.animation';
import ContguardPlugin, { IGetAppVersionResponse } from '../../plugins/contguard.plugin';
import { GlobalsPipe } from '../pipes/globals.pipe';
import { NgTemplateOutlet } from '@angular/common';
import { SelectListComponent, SelectListLabelTemplateDirective, SelectListOptionTemplateDirective } from '../controls/select-list/select-list.component';
import { FormsModule } from '@angular/forms';
import { OverlayScrollbarDirective } from '../directives/overlay-scrollbar/overlay-scrollbar.directive';

@Component({
    selector: 'settings',
    templateUrl: './settings.component.html',
    styleUrls: ['../controls/top-bar/top-bar.component.css', './settings.component.css'],
    animations: [foldAnimation],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [OverlayScrollbarDirective, FormsModule, SelectListComponent, SelectListLabelTemplateDirective, NgTemplateOutlet, SelectListOptionTemplateDirective,
        GlobalsPipe]
})
export class SettingsComponent
{
    // #region Private Members

    private _appColorThemeModes: IIdValue[] =
        [
            { id: AppColorThemeMode.System, value: 'System deafult' },
            { id: AppColorThemeMode.Light, value: 'Light' },
            { id: AppColorThemeMode.Dark, value: 'Dark' }
        ];

    private _appVersion: string = '';

    // #endregion

    // #region Properties

    public isCurrentlyUsingUTCTime: boolean = false;
    public isCurrentlyUsingTime24Hours: boolean = false;

    public get AppColorThemeMode()
    {
        return AppColorThemeMode;
    }

    public get appColorThemeModes(): IIdValue[]
    {
        return this._appColorThemeModes;
    }

    public get userInfo(): UserInfo
    {
        return this._loginModel.userInfo;
    }

    public get appSettingsService(): AppSettingsService
    {
        return this._appSettingsService;
    }

    public get appVersion(): string
    {
        return this._appVersion;
    }

    // #endregion

    // #region Events

    @Output() public closed: EventEmitter<HTMLElement | null> = new EventEmitter();

    // #endregion

    // #region Constructor

    constructor(private _appSettingsService: AppSettingsService, private _loginModel: LoginModel, private _router: Router,
        private _modalMessageService: ModalMessageService, private _elementRef: ElementRef<HTMLElement>, private _changeDetectorRef: ChangeDetectorRef)
    {
        this.isCurrentlyUsingUTCTime = this._appSettingsService.isUsingUTCTimePending !== null ? this._appSettingsService.isUsingUTCTimePending :
            this._appSettingsService.appSettings.isUsingUTCTime;

        this.isCurrentlyUsingTime24Hours = this._appSettingsService.isUsingTime24HoursPending !== null ? this._appSettingsService.isUsingTime24HoursPending :
            this._appSettingsService.appSettings.isUsingTime24Hours;

        ContguardPlugin.getAppVersion().then((response: IGetAppVersionResponse) =>
        {
            this._appVersion = response.appVersion;
            this._changeDetectorRef.markForCheck();
        });
    }

    // #endregion

    // #region Event Handlers

    @HostListener('document:mousedown', ['$event']) onDocumentMouseDownHandler(event: MouseEvent): void
    {
        if (!this._elementRef.nativeElement.contains(event.target as HTMLElement))
        {
            this.closed.emit(event.target as HTMLElement);
        }
    }

    @HostListener('window:keydown', ['$event']) onWindowKeyDownHandler(event: KeyboardEvent): void
    {
        if (event.key === KeyValue.Escape)
        {
            this.closed.emit(null);
        }
    }

    public onBackButtonClick(): void
    {
        this.closed.emit(null);
    }

    public onLogoutButtonClick(): void
    {
        this._loginModel.logout();
    }

    public onChangePasswordButtonClick(): void
    {
        this._router.navigate([`/${RouteType.ChangePassword}`]);
    }

    public async onUsingUTCTimeChange(): Promise<void>
    {
        if (this._appSettingsService.appSettings.isUsingUTCTime !== this.isCurrentlyUsingUTCTime)
        {
            if (await this.isRestartEnabled())
            {
                this._appSettingsService.isUsingUTCTimePending = null;
                this._appSettingsService.appSettings.isUsingUTCTime = this.isCurrentlyUsingUTCTime;
                window.location.reload();
            }
            else
            {
                this._appSettingsService.isUsingUTCTimePending = this.isCurrentlyUsingUTCTime;
            }
        }
        else
        {
            this._appSettingsService.isUsingUTCTimePending = null;
        }
    }

    public async onUsingTime24HoursChange(): Promise<void>
    {
        if (this._appSettingsService.appSettings.isUsingTime24Hours !== this.isCurrentlyUsingTime24Hours)
        {
            if (await this.isRestartEnabled())
            {
                this._appSettingsService.isUsingTime24HoursPending = null;
                this._appSettingsService.appSettings.isUsingTime24Hours = this.isCurrentlyUsingTime24Hours;
                window.location.reload();
            }
            else
            {
                this._appSettingsService.isUsingTime24HoursPending = this.isCurrentlyUsingTime24Hours;
            }
        }
        else
        {
            this._appSettingsService.isUsingTime24HoursPending = null;
        }
    }

    // #endregion

    // #region Private Methods

    private async isRestartEnabled(): Promise<boolean>
    {
        return await this._modalMessageService.show(
            {
                title: Constants.APP_TITLE,
                message: 'In order for this change to take effect, you need to restart the application.<br>Otherwise the changes will only take effect the next time you restart the application.<br>Do you want to restart now?',
                modalButton: ModalButtonType.YesNo,
                modalIcon: ModalIconType.Question
            }) === ModalResultType.Yes;
    }

    // #endregion
}
