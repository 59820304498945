import { Injectable } from "@angular/core";
import { NavigationEnd, NavigationStart, Router, RouterEvent } from "@angular/router";
import { Location } from "@angular/common";
import { filter } from "rxjs";
import { Utils } from "../utils/utils";
import { RouteType } from "../utils/globals";

@Injectable({ providedIn: 'root' })
export class RoutingHistoryService
{
    // #region Private Members

    private _routingHistory: string[] = [];
    private _isPopState: boolean = false;

    // #endregion

    // #region Properties

    public get lastRouteUrl(): string
    {
        return this._routingHistory.length > 0 ? this._routingHistory[this._routingHistory.length - 1] : '';
    }

    public get isPopState(): boolean
    {
        return this._isPopState;
    }

    public get canNavigateBack(): boolean
    {
        return this._routingHistory.length > 1;
    }

    public get isEmpty(): boolean
    {
        return this._routingHistory.length === 0;
    }

    // #endregion

    // #region Constructor

    constructor(private _location: Location, private _router: Router)
    {
        this._router.events.pipe(filter((event: any) => event instanceof NavigationStart || event instanceof NavigationEnd)).subscribe((event: RouterEvent) =>
        {
            if (event instanceof NavigationStart)
            {
                this._isPopState = !Utils.isNullOrUndefined(event.restoredState);
                if (this._isPopState)
                {
                    if (this._routingHistory.length > 1 && event.url === this._routingHistory[0])
                    {
                        this._routingHistory = [];
                    }
                }
                else
                {
                    if (this._routingHistory.length > 1 && event.url === this._routingHistory[this._routingHistory.length - 2])
                    {
                        this._routingHistory.splice(this._routingHistory.length - 2, 2);
                    }
                }
            }
            else if (event instanceof NavigationEnd)
            {
                if (!event.urlAfterRedirects.startsWith(`/${RouteType.Login}`) && !event.urlAfterRedirects.startsWith(`/${RouteType.ChangePassword}`))
                {
                    if (this._routingHistory[this._routingHistory.length - 1] !== event.urlAfterRedirects)
                    {
                        this._routingHistory.push(event.urlAfterRedirects);
                    }
                }
            }
        });
    }

    // #endregion

    // #region Public Methods

    public addNavigationUrl(url: string): void
    {
        this._routingHistory.push(url);
    }

    public navigateBack(isReplaceUrl: boolean = false, forceNavigation: boolean = false): void
    {
        if (!forceNavigation && this._routingHistory.length > 1)
        {
            this._location.back();
        }
        else
        {
            this._router.navigate([(this._routingHistory.length === 1 || forceNavigation) &&
                this.lastRouteUrl !== '' && this._router.url !== this.lastRouteUrl ? this.lastRouteUrl : '/'], { replaceUrl: isReplaceUrl });
        }
    }

    // #endregion
}