import { HttpClient, HttpErrorResponse, HttpHeaders } from "@angular/common/http";
import { Injectable, RendererFactory2 } from "@angular/core";
import { cloneDeep, isEqual } from "lodash";
import { Observable, Observer } from "rxjs";
import { LoginModel } from "../../../user/login/model/login.model";
import { ShipmentLists } from "../../../shipment-manager/shipment-manager/model/shipment-manager-model.class";
import { ShipmentManagerModel } from "../../../shipment-manager/shipment-manager/model/shipment-manager.model";
import { Constants, IApiResponse, IIdValue } from "../../../utils/globals";
import { HttpErrorCodes } from "../../../utils/http-error-codes";
import { HttpHelper } from "../../../utils/http-helper";
import { DateTimeFormatType, Utils } from "../../../utils/utils";
import 
{
    CustomAttributeType, TrailerType, CustomAttribute, Shipment, LockMethodType, DisplayShipment
} from "./shipment-model.class";
import { StringTableUtils } from "../../../utils/string-table-utils";
import { ManagerItemStatus } from "../../../base/models/manager-base-model.class";
import { AttachmentsModel } from "../../../base/models/attachments.model";
import { AttachmentFileData, AttachmentsInfo } from "../../../base/models/attachments-model.class";
import { ManagerConstants } from "../../../utils/monitoring-utils";
import { Router } from "@angular/router";

@Injectable({ providedIn: 'root' })
export class ShipmentModel extends AttachmentsModel
{
    // #region Private Constants

    private readonly CUSTOM_ATTRIBUTES_SIZE: number = (Object.values(CustomAttributeType).filter((value: any) => typeof (value) === 'number') as number[]).length + 3;

    // #endregion

    // #region Private Members

    private _displayShipment: DisplayShipment = new DisplayShipment();
    private _sourceDisplayShipment: DisplayShipment = new DisplayShipment();

    private _routes: IIdValue[] = [];

    private readonly _shipmentStatuses: IIdValue[] =
        [
            { id: ManagerItemStatus.Live, value: 'Live' },
            { id: ManagerItemStatus.Completed, value: 'Completed' },
            { id: ManagerItemStatus.Canceled, value: 'Canceled' }
        ];

    private _customAttributs: CustomAttribute[] = [];
    private _displayCustomAttributs: CustomAttribute[] = [];
    private _sourceCustomAttributs: CustomAttribute[] = [];

    private _devices: IIdValue[] = [];
    private _lockMethods: IIdValue[] = [];
    private _sourceLockMethods: IIdValue[] = [];

    // #endregion

    // #region Properties

    public get TrailerType()
    {
        return TrailerType;
    }

    public override get attachmentUniqueKey(): number | null
    {
        return this._displayShipment.shipmentKey;
    }

    public get isAdministrator(): boolean
    {
        return this._loginModel.userInfo.isAdministrator;
    }

    public get customAttributs(): CustomAttribute[]
    {
        return this._customAttributs;
    }

    public get displayCustomAttributs(): CustomAttribute[]
    {
        return this._displayCustomAttributs;
    }

    public get displayShipment(): DisplayShipment
    {
        return this._displayShipment;
    }

    public get shipmentLists(): ShipmentLists
    {
        return this._shipmentsManagerModel.shipmentLists;
    }

    public get shipmentStatuses(): IIdValue[]
    {
        return this._shipmentStatuses;
    }

    public get CustomAttributeType()
    {
        return CustomAttributeType;
    }

    public get ManagerItemStatus()
    {
        return ManagerItemStatus;
    }

    public get shipmentStatus(): ManagerItemStatus
    {
        return this._displayShipment.statusId;
    }

    public set shipmentStatus(value: ManagerItemStatus)
    {
        if (this._displayShipment.statusId != value)
        {
            this.initializeAttachmentsInfo();

            if (this._shipmentsManagerModel.selectedManagerItem !== null)
            {
                this.initializeShipment(this._shipmentsManagerModel.selectedManagerItem);
            }

            if (this._displayShipment.statusId === ManagerItemStatus.Completed && value === ManagerItemStatus.Live)
            {
                this._displayShipment.removeDate = null;
            }

            this._displayShipment.statusId = value;

            if (this._displayShipment.statusId === ManagerItemStatus.Completed && this._loginModel.isAccountTypeAmazon)
            {
                this._displayShipment.removeDate = new Date();
                this._customAttributs[CustomAttributeType.alias_dest].value = this._loginModel.userInfo.email;
            }
        }
    }

    public get sourceShipmentStatus(): ManagerItemStatus
    {
        return (this._shipmentsManagerModel.selectedManagerItem === null || this._shipmentsManagerModel.selectedManagerItem.statusId === null) ?
            ManagerItemStatus.Live : this._shipmentsManagerModel.selectedManagerItem.statusId;
    }

    public get routes(): IIdValue[]
    {
        return this._routes;
    }

    public get devices(): IIdValue[]
    {
        return this._devices;
    }

    public get lockMethods(): IIdValue[]
    {
        return this._lockMethods;
    }

    public override get isDirty(): boolean
    {
        const sourceDisplayShipment: DisplayShipment = cloneDeep(this._sourceDisplayShipment);

        if (this._displayShipment.shipmentKey == null)
        {
            sourceDisplayShipment.statusId = ManagerItemStatus.Live;
        }

        sourceDisplayShipment.customAttributes = [];

        const targetDisplayShipment: DisplayShipment = Utils.clearObjectEmptyStrings(cloneDeep(this._displayShipment));
        targetDisplayShipment.customAttributes = [];

        if (!isEqual(sourceDisplayShipment, targetDisplayShipment))
        {
            return true;
        }

        if (this.isAttachmentsNeedsUpload())
        {
            return true;
        }

        for (let i: number = 0; i < this._sourceCustomAttributs.length; i++)
        {
            if (this._sourceCustomAttributs[i].value === undefined && Utils.isNullOrUndefined(this._customAttributs[i].value))
            {
                continue;
            }
            else if (!isEqual(this._customAttributs[i].value, this._sourceCustomAttributs[i].value))
            {
                return true;
            }
        }

        return false;
    }

    // #endregion

    // #region Constructors

    constructor(_httpClient: HttpClient, private _shipmentsManagerModel: ShipmentManagerModel, private _loginModel: LoginModel, private _router: Router,
        rendererFactory: RendererFactory2)
    {
        super(_httpClient, rendererFactory);
    }

    // #endregion

    // #region Public Methods

    public setValidShipment(): void
    {
        this._sourceDisplayShipment = Utils.clearObjectEmptyStrings(cloneDeep(this._displayShipment));
        this._sourceCustomAttributs = cloneDeep(this._customAttributs);
    }

    public deleteExistingShipmentAttachmentFile(attachmentIndex: number, customAttributeType: CustomAttributeType): void
    {
        const customAttributeAttachmentsValue: string[] = this.getCustomAttributeAttachmentsValue(customAttributeType);
        if (customAttributeAttachmentsValue.length > attachmentIndex)
        {
            this._attachmentsInfo[customAttributeType].deleteAttachmentsFileNames.push(customAttributeAttachmentsValue[attachmentIndex]);

            customAttributeAttachmentsValue.splice(attachmentIndex, 1);

            this._attachmentsInfo[customAttributeType].attachmentsFilesData.splice(attachmentIndex, 1);
            this._attachmentsInfo[customAttributeType].attachmentsFilesData = [...this._attachmentsInfo[customAttributeType].attachmentsFilesData];

            this._customAttributs[customAttributeType].value = customAttributeAttachmentsValue;

            this.updateAttachmentsAvailableSize(customAttributeType);
        }
    }

    public isShipmentCanceled(): boolean
    {
        return this._displayShipment.statusId === ManagerItemStatus.Canceled && this._shipmentsManagerModel.selectedManagerItem !== null &&
            this._shipmentsManagerModel.selectedManagerItem.statusId !== ManagerItemStatus.Canceled;
    }

    public isShipmentMissingFiles(customAttributeType: CustomAttributeType | null = null): boolean
    {
        if (this._loginModel.isAccountTypeAmazonEU && this._customAttributs[CustomAttributeType.trailer_type] !== undefined &&
            this._customAttributs[CustomAttributeType.trailer_type].value === TrailerType.Soft)
        {
            return false;
        }

        if (!this._loginModel.isAccountTypeAmazon || this._displayShipment.statusId === ManagerItemStatus.Canceled)
        {
            return false;
        }

        if (customAttributeType === null)
        {
            return this.isShipmentMissingFiles(this._displayShipment.statusId === ManagerItemStatus.Live ? CustomAttributeType.attachments_origin :
                CustomAttributeType.attachments_dest);
        }

        return this.getCustomAttributeAttachmentsValue(customAttributeType).length === 0 &&
            this._attachmentsInfo[customAttributeType].uploadAttachmentsFilesData.length === 0;
    }

    public initialize(): void
    {
        this.clear();

        this._customAttributs = [];
        this._displayCustomAttributs = [];
        this._sourceCustomAttributs = [];
        this._devices = [];
        this._lockMethods = [];
        this._sourceLockMethods = [];

        this.initializeAttachmentsInfo();

        this._displayShipment = new DisplayShipment();
        this._sourceDisplayShipment = new DisplayShipment();
        this._routes = [...this._shipmentsManagerModel.shipmentLists.routes];
        this._sourceLockMethods = [...this._shipmentsManagerModel.shipmentLists.lockMethods];
        this._lockMethods = [...this._lockMethods];
        this._devices = this._shipmentsManagerModel.shipmentLists.devices.map((device: IIdValue) =>
        {
            return { id: (device.id !== null ? device.id.toString() : null), value: device.value };
        });

        if (this._shipmentsManagerModel.selectedManagerItem !== null)
        {
            this.initializeShipment(this._shipmentsManagerModel.selectedManagerItem);

            if (this._displayShipment.deviceId !== null &&
                this._devices.filter((device: IIdValue) => device.id === this._displayShipment.deviceId).length === 0)
            {
                this._devices.splice(1, 0, { id: this._displayShipment.deviceId, value: this._displayShipment.deviceId });
            }

            this.updateDeviceIdElements();

            if (this._displayShipment.routeId !== null && this._routes.filter((route: IIdValue) => route.id === this._displayShipment.routeId).length === 0)
            {
                this._routes = [{ id: this._displayShipment.routeId, value: ManagerConstants.MISSING_ROUTE_DESCRIPTION }, ...this._routes];
            }
        }
        else
        {
            this.initializeShipment(this._displayShipment);

            this._displayShipment.statusId = ManagerItemStatus.Live;
            if (this._loginModel.isAccountTypeAmazon)
            {
                this._displayShipment.deviceId = null;
                this._displayShipment.installDate = new Date();

                this._customAttributs[CustomAttributeType.alias_origin].value = this._loginModel.userInfo.email;
            }
        }
    }

    public initializeShipmentAttachments(): Observable<boolean>
    {
        return new Observable((observer: Observer<boolean>) =>
        {
            let isCompletedAllOriginAttachments: boolean = false;
            let isCompletedAllDestAttachments: boolean = false;

            this.getAttachments(CustomAttributeType.attachments_origin, (isCompleted: boolean) =>
            {
                isCompletedAllOriginAttachments = isCompleted;
                observer.next(isCompletedAllOriginAttachments && isCompletedAllDestAttachments);
                if (isCompletedAllOriginAttachments && isCompletedAllDestAttachments)
                {
                    this.updateAttachmentsAvailableSize(CustomAttributeType.attachments_origin);
                    observer.complete();
                }
            });

            this.getAttachments(CustomAttributeType.attachments_dest, (isCompleted: boolean) =>
            {
                isCompletedAllDestAttachments = isCompleted;
                observer.next(isCompletedAllOriginAttachments && isCompletedAllDestAttachments);
                if (isCompletedAllOriginAttachments && isCompletedAllDestAttachments)
                {
                    this.updateAttachmentsAvailableSize(CustomAttributeType.attachments_dest);
                    observer.complete();
                }
            });
        });
    }

    public override isAttachmentsNeedsUpload(_attachmentType: number = 0): boolean
    {
        return super.isAttachmentsNeedsUpload(CustomAttributeType.attachments_origin) || super.isAttachmentsNeedsUpload(CustomAttributeType.attachments_dest);
    }

    public deleteShipmentAttachmentFile(deleteAttachmentIndex: number, customAttributeType: CustomAttributeType): Observable<IApiResponse>
    {
        this._isBusy = true;

        return new Observable((observer: Observer<IApiResponse>) =>
        {
            if (this._attachmentsInfo[customAttributeType].deleteAttachmentsFileNames.length === 0)
            {
                observer.next({ isSuccess: true });
                observer.complete();
                return;
            }

            const filename: string = this._attachmentsInfo[customAttributeType].deleteAttachmentsFileNames[deleteAttachmentIndex];

            this._httpClient.delete(`file/delete/${this._displayShipment.shipmentKey}`,
                { headers: HttpHelper.GetHttpJsonHeaders(), body: JSON.stringify({ 'fileName': filename }) }).subscribe(
                    {
                        next: () =>
                        {
                            this._attachmentsInfo[customAttributeType].deleteAttachmentsFileNames.splice(deleteAttachmentIndex, 1);

                            observer.next({ isSuccess: true });
                            observer.complete();
                        },
                        error: (error: HttpErrorResponse) =>
                        {
                            console.error(error);

                            observer.next({
                                isSuccess: true,
                                message: error.status === HttpErrorCodes.CONFLICT ? `The file '${filename}' was not found on the server!` :
                                    Constants.DATA_SERVICE_ERROR_STRING
                            });

                            observer.complete();
                        }
                    });
        });
    }

    public updateDeviceIdElements(): void
    {
        if (!this._loginModel.isAccountTypeAmazonEU)
        {
            return;
        }

        if (this._customAttributs[CustomAttributeType.trailer_type] !== undefined &&
            this._customAttributs[CustomAttributeType.trailer_type].value === TrailerType.Soft)
        {
            this._displayShipment.deviceId = null;
        }

        if (this._displayShipment.deviceId === null)
        {
            this._lockMethods = this._sourceLockMethods.filter((value: IIdValue) => value.id === LockMethodType.None);
            this._customAttributs[CustomAttributeType.lock_method].value = LockMethodType.None;
        }
        else
        {
            this._lockMethods = this._sourceLockMethods.filter((value: IIdValue) => value.id === LockMethodType.Cable || value.id === LockMethodType.Shackle);
            if (this._customAttributs[CustomAttributeType.lock_method].value !== LockMethodType.Cable &&
                this._customAttributs[CustomAttributeType.lock_method].value !== LockMethodType.Shackle)
            {
                this._customAttributs[CustomAttributeType.lock_method].value = null;
            }
        }
    }

    public submitShipment(isNewShipment: boolean): Observable<IApiResponse>
    {
        this._isBusy = true;

        return new Observable((observer: Observer<IApiResponse>) =>
        {
            let httpUpdater: Observable<any>;

            const shipment: Shipment = Utils.clearObjectEmptyStrings(Utils.copyObjectByTargetProperties(this._displayShipment, new Shipment()));
            shipment.customAttributes = this._loginModel.isAccountTypeAmazon ? this.getSubmitShipmentCustomAttributes() : [];

            const shipmentUrl: string = `shipment-manager/shipment?isNew=${isNewShipment}`;

            const body: string = JSON.stringify(shipment);
            const httpHeaders: HttpHeaders = HttpHelper.GetHttpJsonHeaders();

            if (this._displayShipment.shipmentKey == null)
            {
                httpUpdater = this._httpClient.post<Shipment>(shipmentUrl, body, { headers: httpHeaders });
            }
            else
            {
                httpUpdater = this._httpClient.put<Shipment>(shipmentUrl, body, { headers: httpHeaders });
            }

            httpUpdater.subscribe(
                {
                    next: (shipment: Shipment) =>
                    {
                        this._isBusy = false;

                        if (shipment !== null && this._displayShipment.shipmentKey === null)
                        {
                            this._displayShipment.shipmentKey = shipment.shipmentKey;
                            this._displayShipment.shipmentId = shipment.shipmentId;
                            this._displayShipment.customerId = shipment.customerId;
                            this._displayShipment.modified = shipment.modified;
                        }

                        observer.next({ isSuccess: true });
                        observer.complete();
                    },
                    error: (error: HttpErrorResponse) =>
                    {
                        this._isBusy = false;
                        console.error(error);

                        let message: string = Constants.DATA_SERVICE_ERROR_STRING;

                        switch (error.status)
                        {
                            case HttpErrorCodes.CONFLICT:
                                {
                                    message = `There is already a shipment with this ${StringTableUtils.getContainerIdColumnName(this._loginModel.isAccountTypeAmazon)}!`;
                                }
                                break;

                            case HttpErrorCodes.NO_CONTENT:
                                {
                                    message = 'Either the device does not exists or already beeing used!';
                                }
                                break;
                        }

                        observer.next({ isSuccess: false, message: message });
                        observer.complete();
                    }
                });
        });
    }

    // #endregion

    // #region Protected Methods

    protected override initializeAttachmentsInfo(): void
    {
        this._attachmentsInfo = Array(this.CUSTOM_ATTRIBUTES_SIZE).fill(undefined);
        this._attachmentsInfo[CustomAttributeType.attachments_origin] = new AttachmentsInfo();
        this._attachmentsInfo[CustomAttributeType.attachments_dest] = new AttachmentsInfo();

        this._attachmentsInfo[CustomAttributeType.attachments_origin].availableAttachmentSizeToAdd = this.TOTAL_ATTACHMENT_MAX_SIZE_MEGA_BYTES;
        this._attachmentsInfo[CustomAttributeType.attachments_dest].availableAttachmentSizeToAdd = this.TOTAL_ATTACHMENT_MAX_SIZE_MEGA_BYTES;
    }

    protected override isAttachmentFileExists(fileName: string, currentUploadAttachmentFilesDataLength: number, attachmentType: number = 0): boolean
    {
        if (super.isAttachmentFileExists(fileName, currentUploadAttachmentFilesDataLength, attachmentType))
        {
            return true;
        }

        const customAttributeAttachmentsValue: string[] = this.getCustomAttributeAttachmentsValue(attachmentType);
        for (const attachmentFileName of customAttributeAttachmentsValue)
        {
            if (attachmentFileName === fileName)
            {
                return true;
            }
        }

        return false;
    }

    // #endregion

    // #region Private Methods

    private getCustomAttributeAttachmentsValue(customAttributeType: CustomAttributeType): string[]
    {
        return [...(this._customAttributs[customAttributeType].value ?? [])];
    }

    private getAttachments(customAttributeType: CustomAttributeType, getAttachmentCallback: (isCompleted: boolean) => void): void
    {
        const attachmentsFileNames: string[] = this.getCustomAttributeAttachmentsValue(customAttributeType);
        if (attachmentsFileNames.length === 0)
        {
            getAttachmentCallback(true);
            return;
        }

        let attachmentDownloadIndex: number = 0;

        const attachmentsFilesData: AttachmentFileData[] = [];
        for (let i: number = 0; i < attachmentsFileNames.length; i++)
        {
            attachmentsFilesData.push({ attachmentFile: {} as File, attachmentFileUrl: '' });
        }

        this._attachmentsInfo[customAttributeType].attachmentsFilesData = attachmentsFilesData;

        getAttachmentCallback(false);

        for (let i: number = 0; i < attachmentsFileNames.length; i++)
        {
            this._httpClient.post<Blob>(`file/download/${this._displayShipment.shipmentKey}`,
                JSON.stringify({ 'fileName': attachmentsFileNames[i] }), { headers: HttpHelper.GetHttpJsonHeaders(), responseType: 'blob' as 'json' }).subscribe(
                    {
                        next: (response: Blob) =>
                        {
                            const imageFile: File = new File([response], attachmentsFileNames[i], { type: response.type });
                            attachmentsFilesData[i].attachmentFile = imageFile;
                            attachmentsFilesData[i].attachmentFileUrl = URL.createObjectURL(imageFile);

                            getAttachmentCallback(++attachmentDownloadIndex === attachmentsFileNames.length);
                        },
                        error: (error: HttpErrorResponse) =>
                        {
                            console.error(error);

                            attachmentsFilesData[i].attachmentFileUrl = attachmentsFileNames[i];
                            getAttachmentCallback(++attachmentDownloadIndex === attachmentsFileNames.length);
                        }
                    });
        }
    }

    private initializeShipment(displayShipment: DisplayShipment): void
    {
        this._displayShipment = cloneDeep(displayShipment);

        this._customAttributs = [];
        this._displayCustomAttributs = [];

        this.setValidShipment();

        for (let i: number = 0; i < this.CUSTOM_ATTRIBUTES_SIZE; i++)
        {
            const customAttribute: CustomAttribute = new CustomAttribute();
            customAttribute.attributeId = i;
            customAttribute.attributeKey = CustomAttributeType[i];
            if (i === CustomAttributeType.attachments_origin || i === CustomAttributeType.attachments_dest)
            {
                customAttribute.value = [];
            }

            this._sourceCustomAttributs.push(customAttribute);
        }

        if (this._loginModel.isAccountTypeAmazon)
        {
            for (const customAttribute of this._displayShipment.customAttributes)
            {
                if (customAttribute.attributeId === CustomAttributeType.report_anomalies && customAttribute.value !== null)
                {
                    customAttribute.value = customAttribute.value === 'true';
                }

                if (customAttribute.attributeId !== null)
                {
                    this._sourceCustomAttributs[customAttribute.attributeId] = customAttribute;
                }
            }
        }

        this._customAttributs = cloneDeep(this._sourceCustomAttributs);
        this._displayCustomAttributs = cloneDeep(this._sourceCustomAttributs);

        for (const customAttribute of this._displayCustomAttributs)
        {
            switch (customAttribute.attributeId)
            {
                case CustomAttributeType.trailer_type:
                    {
                        customAttribute.value = this.shipmentLists.trailerTypes.find((iidVlue: IIdValue) => iidVlue.id === customAttribute.value)?.value;
                    }
                    break;

                case CustomAttributeType.lock_method:
                    {
                        customAttribute.value = this.shipmentLists.lockMethods.find((iidVlue: IIdValue) => iidVlue.id === customAttribute.value)?.value;
                    }
                    break;

                case CustomAttributeType.deviceless_reason:
                    {
                        customAttribute.value = this.shipmentLists.devicelessReasons.find((iidVlue: IIdValue) => iidVlue.id === customAttribute.value)?.value;
                    }
                    break;
            }

            customAttribute.value = customAttribute.value !== null && customAttribute.value !== undefined && (Array.isArray(customAttribute.value) ||
                customAttribute.value.toString().trim().length > 0) ? (customAttribute.attributeId === CustomAttributeType.slot_dest ?
                    Utils.getFormattedDateTime(new Date(customAttribute.value), DateTimeFormatType.DateTime) : customAttribute.value) :
                Constants.EMPTY_FIELD_VALUE;
        }
    }

    private getSubmitAttachmentsCustomAttribute(customAttribute: CustomAttribute): string[]
    {
        const customAttributeAttachmentsFileNames: string[] = customAttribute.value;

        if (customAttributeAttachmentsFileNames.length === 0 && this._attachmentsInfo[customAttribute.attributeId!].uploadAttachmentsFilesData.length === 0)
        {
            return [];
        }

        const attachmentsFilesNames: string[] = [...customAttributeAttachmentsFileNames];

        attachmentsFilesNames.push(...this._attachmentsInfo[customAttribute.attributeId!].uploadAttachmentsFilesData.map(
            (attachmentFileData: AttachmentFileData) => attachmentFileData.attachmentFile.name));

        return attachmentsFilesNames;
    }

    private getSubmitShipmentCustomAttributes(): CustomAttribute[]
    {
        const customAttributes: CustomAttribute[] = [];
        for (let i: number = 0; i < this._customAttributs.length; i++)
        {
            const customAttribute: CustomAttribute = cloneDeep(this._customAttributs[i]);

            if (i === CustomAttributeType.attachments_origin || i === CustomAttributeType.attachments_dest)
            {
                if (this._displayShipment.statusId === ManagerItemStatus.Live && i === CustomAttributeType.attachments_origin ||
                    this._displayShipment.statusId === ManagerItemStatus.Completed)
                {
                    customAttribute.value = this.getSubmitAttachmentsCustomAttribute(customAttribute);
                }
                else
                {
                    continue;
                }
            }
            else if (this._customAttributs[i].value !== undefined)
            {
                if (i !== CustomAttributeType.trailer_type && i !== CustomAttributeType.lock_method && i !== CustomAttributeType.deviceless_reason)
                {
                    customAttribute.value = Utils.isNullOrEmpty(customAttribute.value) ? null : (customAttribute.value instanceof Date ?
                        customAttribute.value.toISOString() : (customAttribute.value.toString()));
                }
            }
            else
            {
                continue;
            }

            customAttributes.push(customAttribute);
        }

        return customAttributes;
    }

    // #endregion
}