import { Directive } from "@angular/core";
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from "@angular/forms";
import { Utils } from "../utils/utils";

@Directive({
    selector: '[emailValidator][ngModel]',
    providers: [{ provide: NG_VALIDATORS, useExisting: EmailValidatorDirective, multi: true }],
    standalone: true
})
export class EmailValidatorDirective implements Validator
{
    // #region Validator Interface

    public validate(control: AbstractControl): ValidationErrors | null
    {
        if (Utils.isNullOrEmpty(control.value))
        {
            return null;
        }

        return /^[\Sa-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$/.test(control.value) ? null : { 'emailValidator': true };
    }

    // #endregion
}

