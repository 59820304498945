import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, ElementRef, Output, ViewChild, OnDestroy, HostListener } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { fadeInOutAnimation } from '../../animations/fade.animation';
import { Utils } from '../../utils/utils';
import { GlobalsPipe } from '../../pipes/globals.pipe';

@Component({
    selector: 'camera',
    templateUrl: './camera.component.html',
    styleUrls: ['./camera.component.css'],
    animations: [fadeInOutAnimation],
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: CameraComponent, multi: true }
    ],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [GlobalsPipe]
})
export class CameraComponent implements ControlValueAccessor, OnDestroy
{
    // #region Private Constants

    private readonly CAMERA_CAPTURE_PREFIX: string = 'CAPTURE_';

    // #endregion

    // #region Private Members

    private _innerValue: File | null = null;

    private _changed = new Array<(value: File | null) => void>();
    private _touched = new Array<() => void>();

    private _audioElement: HTMLAudioElement = new Audio('assets/sounds/camera.mp3');
    private _captureCanvasElement: HTMLCanvasElement | null = null;

    private _mediaStream: MediaStream | null = null;

    private _isLoadingWebcam: boolean = false;
    private _showWebcam: boolean = false;

    private _approveImageUrl: string | null = null;
    private _approveImage: File | null = null;

    @ViewChild('fileInput', { read: ElementRef, static: false })
    private _fileInputElementRef: ElementRef<HTMLInputElement> | undefined = undefined;

    @ViewChild('videoCapture', { read: ElementRef, static: false })
    private _videoCaptureElementRef: ElementRef<HTMLVideoElement> | undefined = undefined;

    // #endregion

    // #region Properties

    public get approveImageUrl(): string | null
    {
        return this._approveImageUrl;
    }

    public get showWebcam(): boolean
    {
        return this._showWebcam;
    }

    public get isLoadingWebcam(): boolean
    {
        return this._isLoadingWebcam;
    }

    public get value(): File | null
    {
        return this._innerValue;
    }

    public set value(value: File | null)
    {
        if (this._innerValue !== value)
        {
            this._innerValue = value;
            this._changed.forEach(f => f(value));
            this._touched.forEach(f => f());
            this.change.emit(value);
        }
    }

    // #endregion

    // #region Constructor

    constructor(private _changeDetectorRef: ChangeDetectorRef)
    {
    }

    // #endregion

    // #region Events

    @Output() change: EventEmitter<File | null> = new EventEmitter<File | null>();

    // #endregion

    // #region Event Handlers

    public ngOnDestroy(): void
    {
        this.closeMediaStream();
    }

    @HostListener('keydown.escape', ['$event']) onKeyDownHandler(event: KeyboardEvent): void
    {
        if (this._showWebcam)
        {
            event.stopImmediatePropagation();

            this.hideWebcam();
        }
    }

    public onCloseApproveImageButtonClick(): void
    {
        this._approveImageUrl = null;
        this._approveImage = null;

        this._changeDetectorRef.detectChanges();
    }

    public onCloseWebcamButtonClick(): void
    {
        this.hideWebcam();
    }

    public onImageCaptureButtonClick(): void
    {
        this._audioElement.play();

        if (this._videoCaptureElementRef !== undefined)
        {
            if (this._captureCanvasElement === null)
            {
                this._captureCanvasElement = document.createElement('canvas');
            }

            this._captureCanvasElement.height = this._videoCaptureElementRef.nativeElement.videoHeight;
            this._captureCanvasElement.width = this._videoCaptureElementRef.nativeElement.videoWidth;
            const context2D: CanvasRenderingContext2D | null = this._captureCanvasElement.getContext('2d');
            if (context2D !== null)
            {
                context2D.drawImage(this._videoCaptureElementRef.nativeElement, 0, 0, this._captureCanvasElement.width, this._captureCanvasElement.height);

                const fileType: string = 'image/png';
                this._captureCanvasElement.toBlob((blob: Blob | null) =>
                {
                    if (blob !== null)
                    {
                        this._approveImage = new File([blob], Utils.getFileDateName(this.CAMERA_CAPTURE_PREFIX, 'png', new Date()), { type: fileType });

                        if (this._captureCanvasElement !== null)
                        {
                            this._approveImageUrl = this._captureCanvasElement.toDataURL();
                            this._changeDetectorRef.detectChanges();
                        }
                    }
                }, fileType);
            }
        }
    }

    public onApproveButtonClick(): void
    {
        if (this._approveImage !== null)
        {
            this.value = this._approveImage;
        }

        if (this._videoCaptureElementRef !== undefined)
        {
            this._videoCaptureElementRef.nativeElement.srcObject = null;
        }

        this._approveImageUrl = null;
        this._approveImage = null;

        this.hideWebcam();

        this._changeDetectorRef.detectChanges();
    }

    public onShowWebcamButtonClick(): void
    {
        if (navigator.mediaDevices)
        {
            this._isLoadingWebcam = true;

            navigator.mediaDevices.getUserMedia({ video: true, audio: false }).then((mediaStream: MediaStream) =>
            {
                this._mediaStream = mediaStream;

                this._isLoadingWebcam = false;

                this._showWebcam = true;
                this._changeDetectorRef.detectChanges();

                setTimeout(() =>
                {
                    if (this._videoCaptureElementRef !== undefined)
                    {
                        this._videoCaptureElementRef.nativeElement.srcObject = this._mediaStream;
                        this._videoCaptureElementRef.nativeElement.play();
                    }
                });
            }).catch(() =>
            {
                this._isLoadingWebcam = false;
                this._changeDetectorRef.detectChanges();

                if (this._fileInputElementRef !== undefined)
                {
                    this._fileInputElementRef.nativeElement.click();
                }
            });
        }
    }

    public onImageCaptureChange(event: any): void
    {
        if (event.target === null || event.target.files.length === 0)
        {
            return;
        }

        const capturedImage: File = event.target.files[0];

        this.value = new File([capturedImage], Utils.getFileDateName(this.CAMERA_CAPTURE_PREFIX, capturedImage.name.split('.').pop() ?? '', new Date()),
            { type: capturedImage.type });

        this.hideWebcam();
    }

    // #endregion

    // #region Public Methods

    public touch(): void
    {
        this._touched.forEach(f => f());
    }

    public writeValue(value: File | null): void
    {
        this._innerValue = value;
    }

    public registerOnChange(fn: (value: File | null) => void): void
    {
        this._changed.push(fn);
    }

    public registerOnTouched(fn: () => void): void
    {
        this._touched.push(fn);
    }

    // #endregion

    // #region Private Methods

    private hideWebcam(): void
    {
        this.closeMediaStream();
        this._showWebcam = false;
    }

    private closeMediaStream(): void
    {
        if (this._mediaStream === null)
        {
            return;
        }

        this._mediaStream.getTracks().forEach((mediaStreamTrack: MediaStreamTrack) =>
        {
            mediaStreamTrack.stop();
        });

        this._mediaStream = null;
    }

    // #endregion
}
