import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { HttpHelper } from '../../../utils/http-helper';
import
    {
        IUserAuthData, UserInsightPermission, UserInfo, UserCredetials, UserExternalLogin, UserExternalLoginResponse,
        UserExternalLoginAccessCodeRequest,
        CustomerIdType
    } from './login-model.class';
import { Constants, IApiResponse, IApiResponseData, RouteType } from '../../../utils/globals';
import { catchError, Observable, Observer, tap, throwError } from 'rxjs';
import { Router } from '@angular/router';
import { Location } from "@angular/common";
import { jwtDecode } from 'jwt-decode';
import { AppStorage } from '../../../utils/app-storage';
import { HttpErrorCodes } from '../../../utils/http-error-codes';
import { Utils } from '../../../utils/utils';
import { RoutingHistoryService } from '../../../services/routing-history.service';
import { BaseModel } from '../../../base/classes/base-model';

@Injectable({ providedIn: 'root' })
export class LoginModel extends BaseModel
{
    // #region Constants

    private readonly AMAZON_IDENTITY_PROVIDER: string = 'amazon';
    private readonly AMAZON_LOGIN_EMAILS: string[] =
        [
            'jnesani@amazon.com', 'hcovanvo@amazon.com', 'tranfran@amazon.com', 'hetheriw@amazon.com', 'sbbidion@amazon.com', 'robnatal@amazon.com',
            'campjane@amazon.com', 'pamialle@amazon.com', 'abrahaki@amazon.com', 'sagrene@amazon.com', 'aldrinb@amazon.com', 'brightos@amazon.com',
            'galwalte@amazon.com', 'clatamik@amazon.com', 'crden@amazon.com', 'hiteshkd@amazon.com', 'framptmi@amazon.com', 'wzaar@amazon.com'
        ];
    private readonly AMAZON_AUTO_LOGOUT_TIMEOUT: number = 20 * 60 * 60 * 1000;

    //#endregion

    // #region Private Members

    private _userInfo: UserInfo = new UserInfo();
    private _userAuthData: IUserAuthData | null = null;
    private _userInsightPermissions: UserInsightPermission[] = [];
    private _loginExternalIdentityProvider: string | null = null;
    private _username: string | null = null;
    private _isAmazonEmailLogin: boolean = false;

    private _autoLogoutTimeoutHandleId: NodeJS.Timeout | null = null;

    // #endregion

    // #region Properties

    public password: string | null = null;

    public get username(): string | null
    {
        return this._username;
    }

    public set username(value: string | null)
    {
        this._username = value;
        Utils.clearValueEmptyStrings(this._username);

        this.updateLoginExternalIdentityProvider(this._username);
    }

    public get loginExternalIdentityProvider(): string | null
    {
        return this._loginExternalIdentityProvider;
    }

    public get isAmazonEmailLogin(): boolean
    {
        return this._isAmazonEmailLogin;
    }

    public get isAccountTypeAmazon(): boolean
    {
        return this._userInsightPermissions.includes(UserInsightPermission.ShipmentManagerAmazonEU) ||
            this._userInsightPermissions.includes(UserInsightPermission.ShipmentManagerAmazonUS);
    }

    public get isAccountTypeAmazonEU(): boolean
    {
        return this._userInsightPermissions.includes(UserInsightPermission.ShipmentManagerAmazonEU);
    }

    public get isAccountTypeAmazonUS(): boolean
    {
        return this._userInsightPermissions.includes(UserInsightPermission.ShipmentManagerAmazonUS);
    }

    public get shipmentManagerPermission(): UserInsightPermission
    {
        if (this._userInsightPermissions.includes(UserInsightPermission.ShipmentManagerAmazonEU))
        {
            return UserInsightPermission.ShipmentManagerAmazonEU;
        }
        else if (this._userInsightPermissions.includes(UserInsightPermission.ShipmentManagerAmazonUS))
        {
            return UserInsightPermission.ShipmentManagerAmazonUS;
        }

        return UserInsightPermission.ShipmentManager;
    }

    public get userInfo(): UserInfo
    {
        return this._userInfo;
    }

    public get UserInsightPermission()
    {
        return UserInsightPermission;
    }

    public get userInsightPermissions(): UserInsightPermission[]
    {
        return this._userInsightPermissions;
    }

    private get userAuthData(): IUserAuthData | null
    {
        return this._userAuthData;
    }

    private set userAuthData(userAuthData: IUserAuthData | null)
    {
        this._userAuthData = userAuthData;
        if (this._userAuthData !== null)
        {
            AppStorage.setStorageItem(AppStorage.AUTH_KEY_NAME, JSON.stringify(this.userAuthData));

            const decodedToken: any = jwtDecode(this._userAuthData.access_token);

            if (Utils.isNullOrEmpty(decodedToken.given_name))
            {
                decodedToken.given_name.charAt(0).toUpperCase() + decodedToken.given_name.slice(1);
            }

            this._userInfo.firstName = this.capitalizeName(decodedToken.given_name);
            this._userInfo.lastName = this.capitalizeName(decodedToken.family_name);
            this._userInfo.fullName = `${decodedToken.given_name ?? '?'} ${decodedToken.family_name ?? '?'}`;
            this._userInfo.initials = `${!Utils.isNullOrEmpty(decodedToken.given_name) ? decodedToken.given_name.charAt(0) : '?'}${''
                }${ !Utils.isNullOrEmpty(decodedToken.given_name) ? decodedToken.family_name.charAt(0) : '?' }`;
            this._userInfo.customerId = decodedToken.customer_id;
            this._userInfo.email = decodedToken.email;
            this._userInfo.isTest = decodedToken.isTest === 'true';
            this._userInfo.isAdministrator = decodedToken.groups != null && decodedToken.groups.indexOf('admin') > -1;
            this._userInfo.isDemo = decodedToken.groupIds != null && decodedToken.groupIds.includes('DEMO');

            this._isAmazonEmailLogin = this._userInfo.email !== null && this.AMAZON_LOGIN_EMAILS.includes(this._userInfo.email);

            if (!Utils.isNullOrEmpty(decodedToken.corp_id))
            {
                this._userInfo.corpId = parseInt(decodedToken.corp_id);
            }

            if (Array.isArray(decodedToken.pbiReports))
            {
                this._userInsightPermissions = [...decodedToken.pbiReports];
            }

            let defaultRouteRedirectTo: string = '';
            if (this.userInsightPermissions.includes(UserInsightPermission.ShipmetsMonitoring))
            {
                defaultRouteRedirectTo = RouteType.ShipmentsMonitoring;
            }
            else if (this.userInsightPermissions.includes(UserInsightPermission.DemurrageMonitoring))
            {
                defaultRouteRedirectTo = RouteType.DemurrageMonitoring;
            }
            else if (this.userInsightPermissions.includes(this.shipmentManagerPermission))
            {
                defaultRouteRedirectTo = RouteType.ShipmentManager;
            }
            else if (this.userInsightPermissions.includes(UserInsightPermission.StartForm))
            {
                defaultRouteRedirectTo = RouteType.StartForm
            }
            else if (this.userInsightPermissions.includes(UserInsightPermission.DemurrageManager))
            {
                defaultRouteRedirectTo = RouteType.DemurrageManager;
            }
            else if (this.userInsightPermissions.includes(UserInsightPermission.InventoryManager))
            {
                defaultRouteRedirectTo = RouteType.InventoryManager;
            }
            else if (this.userInsightPermissions.includes(UserInsightPermission.Devices))
            {
                defaultRouteRedirectTo = RouteType.Devices;
            }
            else if (this.userInsightPermissions.includes(UserInsightPermission.Analytics))
            {
                defaultRouteRedirectTo = RouteType.Analytics
            }
            else if (this.userInsightPermissions.includes(UserInsightPermission.MiniControlCenter))
            {
                defaultRouteRedirectTo = RouteType.MiniControlCenter
            }
            else
            {
                return;
            }

            this._router.config[this._router.config.length - 2].redirectTo = defaultRouteRedirectTo;
            this._router.resetConfig(this._router.config);
        }
        else
        {
            AppStorage.setStorageItem(AppStorage.AUTH_KEY_NAME, null);
        }
    }

    public get accessToken(): string | null
    {
        return this.userAuthData !== null ? this.userAuthData.access_token : null;
    }

    // #endregion

    // #region Constructors

    constructor(private _httpClient: HttpClient, private _router: Router, private _location: Location, private _routingHistoryService: RoutingHistoryService)
    {
        super();
    }

    // #endregion

    // #region Public Methods

    public override clear(): void
    {
        super.clear();

        this._username = null;
        this.password = null;
        this.userAuthData = null;
    }

    public logout(isReplaceUrl: boolean = false): void
    {
        if (this.userAuthData !== null)
        {
            this.userLogout();
        }

        this.clearAutoLogoutTimeout();

        const loginPath: string = `/${RouteType.Login}`;

        if (this._routingHistoryService.isEmpty && this._location.path().length > 0 && !this._location.path().startsWith(loginPath) &&
            this._location.path() !== '/')
        {
            this._routingHistoryService.addNavigationUrl(this._location.path());
        }

        this._router.navigate([loginPath], { replaceUrl: isReplaceUrl });
    }

    public loginUser(): Observable<IApiResponse>
    {
        if (this._loginExternalIdentityProvider !== null)
        {
            return this.externalLoginUser();
        }

        this._isBusy = true;

        return new Observable((observer: Observer<IApiResponse>) =>
        {
            const userCredetials: UserCredetials = new UserCredetials();
            userCredetials.username = this._username;
            userCredetials.password = this.password;

            this._httpClient.post<IUserAuthData>('auth/user/login', HttpHelper.GetParams(userCredetials),
                { headers: HttpHelper.GetHttpFormUrlencodedHeaders() }).subscribe(
                    {
                        next: (userAuthData: IUserAuthData) =>
                        {
                            this._isBusy = false;

                            this.userAuthData = userAuthData;

                            this.initializeAmazonAutoLogoutTimeout();

                            observer.next({ isSuccess: true });
                            observer.complete();
                        },
                        error: (error: HttpErrorResponse) =>
                        {
                            const errorMessage: string = error.status === HttpErrorCodes.NOT_FOUND ? 'The login failed.\r\nThe username or password are incorrect.' :
                                Constants.DATA_SERVICE_ERROR_STRING;

                            this._isBusy = false;
                            console.error(error);

                            observer.next({ isSuccess: false, message: errorMessage });
                            observer.complete();
                        }
                    });
        });
    }

    public getAccessToken(code: string): Observable<IApiResponse>
    {
        this._isBusy = true;

        return new Observable((observer: Observer<IApiResponse>) =>
        {
            const userExternalLoginAccessCodeRequest: UserExternalLoginAccessCodeRequest = new UserExternalLoginAccessCodeRequest();
            userExternalLoginAccessCodeRequest.code = code;
            userExternalLoginAccessCodeRequest.redirectBaseUrl = this.getRedirectUrl();

            this._httpClient.post<IUserAuthData>('auth/user/token', HttpHelper.GetParams(userExternalLoginAccessCodeRequest),
                { headers: HttpHelper.GetHttpFormUrlencodedHeaders() }).subscribe(
                    {
                        next: (userAuthData: IUserAuthData) =>
                        {
                            this._isBusy = false;

                            this.userAuthData = userAuthData;

                            observer.next({ isSuccess: true });
                            observer.complete();
                        },
                        error: (error: HttpErrorResponse) =>
                        {
                            this._isBusy = false;
                            console.error(error);

                            observer.next({ isSuccess: false, message: Constants.DATA_SERVICE_ERROR_STRING });
                            observer.complete();
                        }
                    });
        });
    }

    public refreshUserToken(): Observable<IUserAuthData>
    {
        return this._httpClient.post<IUserAuthData>('auth/user/refresh', HttpHelper.GetParams({ refreshToken: this.userAuthData?.refresh_token }),
            { headers: HttpHelper.GetHttpFormUrlencodedHeaders() }).pipe(
                tap((userAuthData: IUserAuthData) =>
                {
                    this.userAuthData = userAuthData;
                }),
                catchError((error: HttpErrorResponse) =>
                {
                    this.clear();
                    this.logout();

                    return throwError(() => error);
                }));
    }

    public loadSavedData(): void
    {
        const userAuthDataJson: string | null = AppStorage.getStorageItem(AppStorage.AUTH_KEY_NAME);
        this.userAuthData = userAuthDataJson !== null ? JSON.parse(userAuthDataJson) as IUserAuthData : null;

        this.initializeAmazonAutoLogoutTimeout();
    }

    // #endregion

    // #region Private Methods

    private initializeAmazonAutoLogoutTimeout(): void
    {
        const customerId: number = Number(this._userInfo.customerId);
        if (customerId === CustomerIdType.AmazonEurope || customerId === CustomerIdType.AmazonUS)
        {
            let loginTime: number;
            const loginTimeString: string | null = AppStorage.getStorageItem(AppStorage.LOGIN_KEY_NAME);
            if (loginTimeString === null)
            {
                loginTime = new Date().getTime();
                AppStorage.setStorageItem(AppStorage.LOGIN_KEY_NAME, loginTime.toString());
            }
            else
            {
                loginTime = Number(loginTimeString);
            }

            this._autoLogoutTimeoutHandleId = setTimeout(() => this.logout(), Math.max(0, loginTime - new Date().getTime() + this.AMAZON_AUTO_LOGOUT_TIMEOUT));
        }
        else
        {
            AppStorage.setStorageItem(AppStorage.LOGIN_KEY_NAME, null);
        }
    }

    private clearAutoLogoutTimeout(): void
    {
        AppStorage.setStorageItem(AppStorage.LOGIN_KEY_NAME, null);

        if (this._autoLogoutTimeoutHandleId !== null)
        {
            clearTimeout(this._autoLogoutTimeoutHandleId);
            this._autoLogoutTimeoutHandleId = null;
        }
    }

    private updateLoginExternalIdentityProvider(email: string | null): void
    {
        if (email !== null && this.AMAZON_LOGIN_EMAILS.includes(email))
        {
            this._loginExternalIdentityProvider = this.AMAZON_IDENTITY_PROVIDER;
        }
        else
        {
            this._loginExternalIdentityProvider = null;
        }
    }

    private getRedirectUrl(): string
    {
        return `${window.location.origin}/${RouteType.Login}`;
    }

    private externalLoginUser(): Observable<IApiResponseData<string | null>>
    {
        this._isBusy = true;

        return new Observable((observer: Observer<IApiResponseData<string | null>>) =>
        {
            const userExternalLogin: UserExternalLogin = new UserExternalLogin();
            userExternalLogin.identityProvider = this._loginExternalIdentityProvider;
            userExternalLogin.redirectBaseUrl = this.getRedirectUrl();

            this._httpClient.post<UserExternalLoginResponse>('auth/user/external-login', HttpHelper.GetParams(userExternalLogin),
                { headers: HttpHelper.GetHttpFormUrlencodedHeaders() }).subscribe(
                    {
                        next: (userExternalLoginResponse: UserExternalLoginResponse) =>
                        {
                            this._isBusy = false;

                            observer.next({ isSuccess: true, data: userExternalLoginResponse.redirectUrl });
                            observer.complete();
                        },
                        error: (error: HttpErrorResponse) =>
                        {
                            const errorMessage: string = error.status === HttpErrorCodes.NOT_FOUND ? 'The login failed.\r\nThe username or password are incorrect.' :
                                Constants.DATA_SERVICE_ERROR_STRING;

                            this._isBusy = false;
                            console.error(error);

                            observer.next({ isSuccess: false, message: errorMessage });
                            observer.complete();
                        }
                    });
        });
    }

    private userLogout(): void
    {
        this._httpClient.post('auth/user/logout', HttpHelper.GetParams({ refreshToken: this.userAuthData?.refresh_token }),
            { headers: HttpHelper.GetHttpFormUrlencodedHeaders() }).subscribe(
                {
                    error: (error: HttpErrorResponse) => console.error(error)
                });
    }

    private capitalizeName(name: string): string
    {
        if (Utils.isNullOrEmpty(name))
        {
            return '';
        }

        return `${name.charAt(0).toUpperCase()}${name.slice(1)}`;
    }

    // #endregion
}
