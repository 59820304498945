<!DOCTYPE html>

<div class="page-container page-image">
  <div class="page-content">
    <div class="page-navigate-back">
      <button class="image-button ripple-effect ripple-center" (click)="onBackButtonClick()">
        <i class="icon-collapse-left"></i>Back
      </button>
    </div>
    <img id="login-logo" src="assets/vectors/logo.svg">
    <div class="form-container">
      @if (showResetPasswordMessage) {
        <div class="reset-password-message" @fadeInAnimation>
          An email with instructions has just been sent to your email address.
          Please carefully follow all instructions and then come back to log in.
          <a [routerLink]="['/login']">Back to Login</a>
        </div>
      }
      <div class="from-wrapper" [@fadeVisibilityAnimation]="!showResetPasswordMessage">
        <div class="page-padding page-subtitle">Please enter the email address associated with your account.</div>
        <form #formResetPassword="ngForm" (ngSubmit)="formResetPassword.form.valid && onResetPasswordSubmit()"
          [class.form-validated]="formResetPassword.submitted" novalidate>
          <fieldset class="page-padding" [disabled]="resetPasswordModel.isBusy">
            <div class="form-group">
              <clearable-input name="userEmail" #userEmail="ngModel" [(ngModel)]="resetPasswordModel.userEmail" requiredValidator emailValidator [isRequired]="true"
                label="Email">
              </clearable-input>
              @if (formResetPassword.submitted && userEmail.invalid) {
                <div class="invalid-feedback" @foldAnimation>
                  @if (userEmail.errors?.['requiredValidator']) {
                    <div>This field is required</div>
                  }
                  @if (userEmail.errors?.['emailValidator']) {
                    <div>Valid Email format is required</div>
                  }
                </div>
              }
            </div>
            <div class="form-group">
              <button type="submit" class="primary-button ripple-effect ripple-fill">
                <span class="spinner-container">
                  @if (resetPasswordModel.isBusy) {
                    <i @fadeInOutAnimation class="icon-spinner"></i>
                  }
                  Reset Password
                </span>
              </button>
            </div>
          </fieldset>
        </form>
      </div>
    </div>
  </div>
</div>
