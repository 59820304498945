import { Component, Input, ViewChild, ElementRef, forwardRef, EventEmitter, Output } from '@angular/core';
import { NG_VALUE_ACCESSOR, ControlValueAccessor, FormsModule } from '@angular/forms';
import { KeyValue } from '../../utils/globals';

@Component({
    selector: 'numeric-string-input',
    template: `<input type="text" inputmode="numeric" #numericInput [(ngModel)]="value" class="form-control" [placeholder]="placeholder" [disabled]="disabled" 
			   [readonly]="readonly" [maxLength]="maxLength" (keydown)="onKeyDown($event)" (paste)="onPaste($event)" (focusout)="onFocusOut()" />`,
    providers: [
        { provide: NG_VALUE_ACCESSOR, useExisting: forwardRef(() => NumericStringInputComponent), multi: true }
    ],
    standalone: true,
    imports: [FormsModule],
})
export class NumericStringInputComponent implements ControlValueAccessor
{
	// #region Private Memebrs

	private _lastValidValue: string | null = null;

	private _innerValue: string | null = null;

	private _changed = new Array<(value: string | null) => void>();
	private _touched = new Array<() => void>();

	@ViewChild('numericInput', { read: ElementRef, static: false }) private _inputElementRef: ElementRef<HTMLInputElement> | undefined = undefined;

	// #endregion

	// #region Properties

	public get value(): string | null
	{
		return this._innerValue;
	}

	public set value(value: string | null)
	{
		if (this._innerValue !== value)
		{
			if (value === null || value === undefined)
			{
				this._lastValidValue = null;
			}
			else if (value.toString().length === 0)
			{
				value = null
			}
			else
			{
				if (this.validateValue(value))
				{
					this._lastValidValue = value;
				}
			}

			this._innerValue = this.maxLength !== null && value !== null && value !== undefined ? value.padStart(this.maxLength, '0') : value;

			this._changed.forEach(f => f(value));
			this._touched.forEach(f => f());
			this.change.emit(value);
		}
	}

	// #endregion

    // #region Inputs

	@Input() public disabled: boolean = false;
	@Input() public readonly: boolean = false;
	@Input() public placeholder: string = "";
	@Input() public maxLength: number = 10;
	@Input() public minValue: number | null = null;
	@Input() public maxValue: number | null = null;

	// #endregion

	// #region Events

	@Output() private change: EventEmitter<string | null> = new EventEmitter<string | null>();

	// #endregion

	// #region Event Handlers

	public onFocusOut(): void
	{
		if (this._lastValidValue !== null)
		{
			this.value = this._lastValidValue;
		}
		else if (!this.validateValue(this.value))
		{
			this.value = null;
		}
	}

	public onPaste(event: ClipboardEvent): void
	{
		event.preventDefault();

		if (event.clipboardData === null)
		{
			return;
		}

		const inputText: string = this.getInputText(event.clipboardData.getData('Text').trim());
		if (this.validateValue(inputText))
		{
			this.value = inputText;
		}
	}

	public onKeyDown(event: KeyboardEvent): void
	{
		if (event.key === KeyValue.Enter || event.key === KeyValue.Delete || event.key === KeyValue.Backspace)
		{
			return;
		}

		event.preventDefault();

		let inputText: string = parseInt(this.getInputText(event.key)).toString();
		if (!this.validateValue(inputText))
		{
			inputText = event.key;
		}

		if (this.validateValue(inputText))
		{
			this.value = inputText;
		}
	}

	// #endregion

	// #region Public Methods

	public touch(): void
	{
		this._touched.forEach(f => f());
	}

	public writeValue(value: string | null): void
	{
		this._innerValue = value;
	}

	public registerOnChange(fn: (value: string | null) => void): void
	{
		this._changed.push(fn);
	}

	public registerOnTouched(fn: () => void): void
	{
		this._touched.push(fn);
	}

	// #endregion

	// #region Private Methods

	private getInputText(newText: string): string
	{
		if (this._inputElementRef === undefined || !/^[0-9]+$/.test(newText))
		{
			return '';
		}

		let prefixText: string = '', suffixText: string = '';
		const innerValue = this._innerValue !== null && this._innerValue !== undefined ? this._innerValue.toString() : null;

		if (innerValue !== null)
		{
			if (this._inputElementRef.nativeElement.selectionStart !== this._inputElementRef.nativeElement.selectionEnd)
			{
				prefixText = innerValue.substring(0, this._inputElementRef.nativeElement.selectionStart ?? 0);
				suffixText = innerValue.substring(this._inputElementRef.nativeElement.selectionEnd ?? 0, innerValue.length);
			}
			else
			{
				prefixText = innerValue.substring(0, this._inputElementRef.nativeElement.selectionStart ?? 0);
				suffixText = innerValue.substring(this._inputElementRef.nativeElement.selectionStart ?? 0, innerValue.length);
			}
		}

		return prefixText + newText + suffixText;
	}

	private validateValue(value: string | null): boolean
	{
		if (value === null)
		{
			return true;
		}

		if (value.length > this.maxLength)
		{
			return false;
		}

		const numericValue: number = Number(value);
		if (!Number.isInteger(numericValue) || numericValue < 0)
		{
			return false;
        }

		if (this.minValue !== null && numericValue < this.minValue)
		{
			return false;
		}

		if (this.maxValue !== null && numericValue > this.maxValue)
		{
			return false;
		}

		return true;
	}

	// #endregion
}