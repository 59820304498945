<!DOCTYPE html>

@if (viewIsInitialized) {
<form class="manager-item-from" #formShipment="ngForm" (ngSubmit)="onShipmentSubmit(formShipment.form.valid &&
      !shipmentModel.isShipmentMissingFiles())"
      @fadeInOutAnimation [class.form-validated]="formShipment.submitted" ignoreEnter novalidate>
    <fieldset [disabled]="shipmentModel.isBusy">
        <div class="form-content-wrapper">
            <div #pageScroller class="form-content page-scroll-padding" overlayScrollbar>
                <div class="form-group">
                    <select-list name="statusId" #statusId="ngModel" placeholder="Status" [items]="shipmentModel.shipmentStatuses" [clearable]="false"
                                 [disabled]="shipmentModel.displayShipment.shipmentKey === null ||
                                 shipmentModel.sourceShipmentStatus === shipmentModel.ManagerItemStatus.Completed && !shipmentModel.isAdministrator ||
                                 shipmentModel.displayShipment.destinationPerimeterId === 'TBD' && !shipmentModel.isAdministrator"
                                 [(ngModel)]="shipmentModel.shipmentStatus" bindLabel="value" bindValue="id" (change)="onShipmentStatusChange()">
                    </select-list>
                </div>
                <ng-container [ngTemplateOutlet]="loginModel.isAccountTypeAmazon ? (loginModel.isAccountTypeAmazonUS ? amazonShipmentUSTemplate : amazonShipmentEUTemplate) : defualtShipmentTemplate"></ng-container>
            </div>
            <div class="form-group manager-buttons action-button-container">
                @if (shipmentModel.displayShipment.shipmentKey === null) {
                <button type="button" class="secondary-button submit-button ripple-effect ripple-fill"
                        (click)="onCancelShipmentButtonClick()">
                    Close
                </button>
                }
                @if (shipmentModel.displayShipment.shipmentKey !== null) {
                <button type="button" class="secondary-button submit-button ripple-effect ripple-fill" [disabled]="!shipmentModel.isDirty"
                        (click)="loadShipment(false)">
                    Revert
                </button>
                }
                <button type="submit" class="primary-button ripple-effect ripple-fill"
                        [disabled]="!shipmentModel.isDirty && shipmentModel.displayShipment.shipmentKey !== null">
                    <span class="spinner-container">
                        @if (shipmentModel.isBusy) {
                        <i @fadeInOutAnimation class="icon-spinner"></i>
                        }
                        {{ shipmentModel.displayShipment.shipmentKey !== null ? 'Update' : 'Add' }}
                    </span>
                </button>
            </div>
        </div>
    </fieldset>

    <!-- barcodeButtonTemplate -->
    <ng-template #barcodeButtonTemplate>
        @if (('IS_MOBILE' | globals)) {
        <button type="button" class="image-button barcode-button ripple-effect ripple-center" tabindex="-1" (mousedown)="onDeviceBarcodeButtonMouseDown($event)">
            <i class="icon-barcode-read"></i>
        </button>
        }
    </ng-template>

    <!-- defualtShipmentTemplate -->
    <ng-template #defualtShipmentTemplate>
        @if (shipmentModel.shipmentStatus !== shipmentModel.ManagerItemStatus.Canceled) {
        <fieldset class="animation-overflow" @foldAnimation>
            <div class="form-group devices-group">
                <select-list #devicesList class="devices" name="deviceId" #deviceId="ngModel" placeholder="{{ deviceIdColumnName }}"
                             [items]="shipmentModel.devices" [isRequired]="true" [clearable]="false" [(ngModel)]="shipmentModel.displayShipment.deviceId"
                             bindLabel="value" bindValue="id">
                </select-list>
                <ng-container [ngTemplateOutlet]="barcodeButtonTemplate"></ng-container>
            </div>
            <div class="form-group">
                <datetime-picker label="{{ installDateColumnName }}" name="startTime" #startTime="ngModel"
                                 [(ngModel)]="shipmentModel.displayShipment.installDate" [isRequired]="true" [withTime]="true"
                                 requiredValidator dateRangeValidator dateRangeMax="endTime" [isFutureDate]="false">
                </datetime-picker>
                @if (formShipment.submitted && startTime.invalid) {
                <div class="invalid-feedback" @foldAnimation>
                    @if (startTime.errors?.['requiredValidator']) {
                    <div>This field is required</div>
                    }
                    @if (startTime.errors?.['dateRangeError']) {
                    <div>{{ installDateColumnName }} must occur before {{ removeDateColumnName }}</div>
                    }
                    @if (startTime.errors?.['dateMaxError']) {
                    <div>{{ installDateColumnName }} can't occur in the future</div>
                    }
                </div>
                }
            </div>
            @if (shipmentModel.shipmentStatus === shipmentModel.ManagerItemStatus.Completed ||
            shipmentModel.sourceShipmentStatus === shipmentModel.ManagerItemStatus.Completed) {
            <div class="form-group" @foldAnimation>
                <datetime-picker label="{{ removeDateColumnName }}" name="endTime" #endTime="ngModel"
                                 [(ngModel)]="shipmentModel.displayShipment.removeDate" [isRequired]="true"
                                 [withTime]="true" requiredValidator dateRangeValidator dateRangeMin="startTime" [isFutureDate]="false">
                </datetime-picker>
                @if (formShipment.submitted && endTime.invalid) {
                <div class="invalid-feedback" @foldAnimation>
                    @if (endTime.errors?.['requiredValidator']) {
                    <div>This field is required</div>
                    }
                    @if (endTime.errors?.['dateRangeError']) {
                    <div>{{ removeDateColumnName }} must occur after {{ installDateColumnName }}</div>
                    }
                    @if (endTime.errors?.['dateMaxError']) {
                    <div>{{ removeDateColumnName }} can't occur in the future</div>
                    }
                </div>
                }
            </div>
            }
            <div class="form-group">
                <clearable-input name="cargoid" #cargoidText #cargoid="ngModel" [(ngModel)]="shipmentModel.displayShipment.containerId" containerIdValidator
                                 [maxLength]="50" requiredValidator [isRequired]="true" label="{{ containerIdColumnName }}">
                </clearable-input>
                @if (formShipment.submitted && cargoid.invalid) {
                <div class="invalid-feedback" @foldAnimation>
                    @if (cargoid.errors?.['requiredValidator']) {
                    <div>This field is required</div>
                    }
                    @if (cargoid.errors?.['containerIdValidator']) {
                    <div>Valid ISO format is required</div>
                    }
                </div>
                }
                <text-characters-counter [text]="shipmentModel.displayShipment.containerId" [maxLength]="cargoidText.maxLength"></text-characters-counter>
            </div>
            <div class="form-group">
                <select-list name="route" #route="ngModel" placeholder="Route" [items]="shipmentModel.routes" [isRequired]="true"
                             [(ngModel)]="shipmentModel.displayShipment.routeId" bindLabel="value" bindValue="id" requiredValidator>
                </select-list>
                @if (formShipment.submitted && route.invalid) {
                <div class="invalid-feedback" @foldAnimation>This field is required</div>
                }
            </div>
            <div class="form-group">
                <select-list name="carrier" #carrier="ngModel" placeholder="Carrier" [items]="shipmentModel.shipmentLists.carriers"
                             [(ngModel)]="shipmentModel.displayShipment.carrierId" bindLabel="value" bindValue="id">
                </select-list>
            </div>
            <div class="form-group">
                <select-list name="lsp" #lsp="ngModel" placeholder="Logistic Provider" [items]="shipmentModel.shipmentLists.lsps"
                             [(ngModel)]="shipmentModel.displayShipment.lspId" bindLabel="value" bindValue="id">
                </select-list>
            </div>
            <div class="form-group">
                <clearable-input name="bookingReference" #bookingReferenceText #bookingReference="ngModel"
                                 [(ngModel)]="shipmentModel.displayShipment.bookingReference" [maxLength]="100" label="Booking Reference">
                </clearable-input>
                <text-characters-counter [text]="shipmentModel.displayShipment.bookingReference" [maxLength]="bookingReferenceText.maxLength"></text-characters-counter>
            </div>
            <div class="form-group">
                <clearable-input name="bookingNumber" #bookingNumberText #bookingNumber="ngModel"
                                 [(ngModel)]="shipmentModel.displayShipment.bookingNumber" [maxLength]="100" label="Booking Number">
                </clearable-input>
                <text-characters-counter [text]="shipmentModel.displayShipment.bookingNumber" [maxLength]="bookingNumberText.maxLength"></text-characters-counter>
            </div>
        </fieldset>
        }
    </ng-template>

    <!-- cancelTemplate -->
    <ng-template #cancelTemplate>
        @if (shipmentModel.shipmentStatus === shipmentModel.ManagerItemStatus.Canceled) {
        <div class="form-group animation-overflow" @foldAnimation>
            <textarea placeholder=" " name="cancellation_reason" #cancellationReasonText #cancellation_reason="ngModel" class="form-control"
                      requiredValidator autosize [(ngModel)]="shipmentModel.customAttributs[shipmentModel.CustomAttributeType.cancellation_reason].value"
                      maxlength="220">
          </textarea>
            <label class="control-label required">Cancelation Reason</label>
            @if (formShipment.submitted && cancellation_reason.invalid) {
            <div class="invalid-feedback" @foldAnimation>This field is required</div>
            }
            <text-characters-counter [text]="shipmentModel.customAttributs[shipmentModel.CustomAttributeType.cancellation_reason].value"
                                     [maxLength]="cancellationReasonText.maxLength">
            </text-characters-counter>
        </div>
        }
    </ng-template>

    <!-- existingAttachmentsTemplate -->
    <ng-template #existingAttachmentsTemplate let-customAttributeType="customAttributeType" let-isViewOnly="isViewOnly">
        @for (attachmentFileData of shipmentModel.attachmentsInfo[customAttributeType].attachmentsFilesData; track attachmentFileData; let attachmentIndex = $index) {
        <div class="attachment existing-attachment ripple-effect ripple-fill ripple-inverse-color" (click)="onAttachmentClick($event, customAttributeType)"
             @foldBothAnimation>
            <img [src]="attachmentFileData.attachmentFileUrl | safe" alt=" " (load)="onImageLoad($event)"
                 (error)="onImageError($event, attachmentFileData.attachmentFileUrl)" />
            @if (!isViewOnly) {
            <button type="button" class="ripple-effect ripple-center ripple-inverse-color"
                    (click)="onDeleteExistingAttachmentButtonClick($event, attachmentIndex, customAttributeType)">
                <i class="icon-cancel"></i>
            </button>
            }
        </div>
        }
    </ng-template>

    <!-- attachmentsTemplate -->
    <ng-template #attachmentsTemplate let-customAttributeType="customAttributeType">
        <div class="form-group attachments-group">
            @if (!('IS_MOBILE' | globals)) {
            <div class="attachment-placeholder select-none" dragDrop
                 [disabled]="shipmentModel.attachmentsInfo[customAttributeType].availableAttachmentSizeToAdd <= 0"
                 (filesDropped)="onAttachmentsFilesDrop($event, customAttributeType)">
                <i class="icon-drag-drop"></i><span>Drop files here</span>
            </div>
            }
            @if (!shipmentModel.isShipmentMissingFiles(customAttributeType)) {
            <div class="attachments-container" @foldAnimation
                 (wheel)="onAttachmetsWheel($event)" overlayScrollbar>
                <div class="attachments-content">
                    <ng-container [ngTemplateOutlet]="existingAttachmentsTemplate"
                                  [ngTemplateOutletContext]="{customAttributeType: customAttributeType, isViewOnly: false}">
                    </ng-container>
                    @for (attachmentFileData of shipmentModel.attachmentsInfo[customAttributeType].uploadAttachmentsFilesData; track attachmentFileData; let attachmentIndex = $index) {
                    <div #attachment class="attachment ripple-effect ripple-fill ripple-inverse-color"
                         (click)="onAttachmentClick($event, customAttributeType)" @foldBothAnimation>
                        <img [src]="attachmentFileData.attachmentFileUrl | safe" alt=" " (load)="onImageLoad($event)"
                             (error)="onImageError($event, attachmentFileData.attachmentFileUrl)" />
                        <button type="button" class="ripple-effect ripple-center ripple-inverse-color"
                                (click)="onDeleteAttachmentButtonClick($event, attachmentIndex, customAttributeType)">
                            <i class="icon-cancel"></i>
                        </button>
                    </div>
                    }
                </div>
            </div>
            }
            <div class="characters-counter select-none">
                <span>Attachments size left: {{ shipmentModel.attachmentsInfo[customAttributeType].availableAttachmentSizeToAdd }}MB</span>
            </div>
            <div class="horizontal-groups" [class.disabled]="shipmentModel.attachmentsInfo[customAttributeType].availableAttachmentSizeToAdd <= 0">
                <label for="file-input" class="file-input horizontal-group camera-button ripple-effect ripple-fill ripple-inverse-color"
                       [class.ng-invalid]="shipmentModel.isShipmentMissingFiles(customAttributeType)">
                    <i class="icon-image"></i>
                </label>
                <camera (change)="onAddCapturedAttachmentsChange($event, customAttributeType)"
                        [class.ng-invalid]="shipmentModel.isShipmentMissingFiles(customAttributeType)">
                </camera>
            </div>
            <input id="file-input" type="file" accept="image/*" multiple (change)="onAddAttachmentsChange($event, customAttributeType)" />
            @if (formShipment.submitted && shipmentModel.isShipmentMissingFiles(customAttributeType)) {
            <div class="invalid-feedback" @foldAnimation>
                <span>Shipment images are required</span>
            </div>
            }
        </div>
    </ng-template>

    <!-- amazonShipmentLiveEUTemplate -->
    <ng-template #amazonShipmentLiveEUTemplate>
        <div class="section-title">User Details</div>
        <div class="form-group">
            <clearable-input name="alias_origin" #alias_origin="ngModel"
                             [(ngModel)]="shipmentModel.customAttributs[shipmentModel.CustomAttributeType.alias_origin].value"
                             requiredValidator [isRequired]="true" label="Alias">
            </clearable-input>
            @if (formShipment.submitted && alias_origin.invalid) {
            <div class="invalid-feedback" @foldAnimation>This field is required</div>
            }
        </div>
        <div class="section-title">Shipment Details</div>
        <div class="form-group">
            <clearable-input name="cargoid" #cargoidText #cargoid="ngModel" [(ngModel)]="shipmentModel.displayShipment.containerId" containerIdValidator [maxLength]="50"
                             requiredValidator [isRequired]="true" label="VRID">
            </clearable-input>
            @if (formShipment.submitted && cargoid.invalid) {
            <div class="invalid-feedback" @foldAnimation>
                @if (cargoid.errors?.['requiredValidator']) {
                <div>This field is required</div>
                }
                @if (cargoid.errors?.['containerIdValidator']) {
                <div>Valid ISO format is required</div>
                }
            </div>
            }
            <text-characters-counter [text]="shipmentModel.displayShipment.containerId" [maxLength]="cargoidText.maxLength"></text-characters-counter>
        </div>
        <div class="form-group">
            <select-list name="trailer_type" #trailer_type="ngModel" placeholder="Trailer Type" [items]="shipmentModel.shipmentLists.trailerTypes"
                         [isRequired]="true" [(ngModel)]="shipmentModel.customAttributs[shipmentModel.CustomAttributeType.trailer_type].value"
                         bindLabel="value" bindValue="id" requiredValidator
                         (change)="shipmentModel.updateDeviceIdElements()">
            </select-list>
            @if (formShipment.submitted && trailer_type.invalid) {
            <div class="invalid-feedback" @foldAnimation>This field is required</div>
            }
        </div>
        <div class="form-group">
            <select-list name="route" #route="ngModel" placeholder="Route" [items]="shipmentModel.routes" [isRequired]="true"
                         [(ngModel)]="shipmentModel.displayShipment.routeId" bindLabel="value" bindValue="id" requiredValidator>
            </select-list>
            @if (formShipment.submitted && route.invalid) {
            <div class="invalid-feedback" @foldAnimation>This field is required</div>
            }
        </div>
        <div class="form-group">
            <clearable-input name="truck_plate1" #truck_plate1="ngModel"
                             [(ngModel)]="shipmentModel.customAttributs[shipmentModel.CustomAttributeType.truck_plate1].value" label="Truck Plate">
            </clearable-input>
        </div>
        <div class="form-group">
            <clearable-input name="trailer_plate" #trailer_plate="ngModel"
                             [(ngModel)]="shipmentModel.customAttributs[shipmentModel.CustomAttributeType.trailer_plate].value" label="Trailer Plate">
            </clearable-input>
        </div>
        <div class="form-group">
            <datetime-picker label="{{ installDateColumnName }}" name="startTime" #startTime="ngModel" [(ngModel)]="shipmentModel.displayShipment.installDate"
                             [isRequired]="true" [withTime]="true" requiredValidator dateRangeValidator [isFutureDate]="false">
            </datetime-picker>
            @if (formShipment.submitted && startTime.invalid) {
            <div class="invalid-feedback" @foldAnimation>
                @if (startTime.errors?.['requiredValidator']) {
                <div>This field is required</div>
                }
                @if (startTime.errors?.['dateRangeError']) {
                <div>{{ installDateColumnName }} must occur before {{ removeDateColumnName }}</div>
                }
                @if (startTime.errors?.['dateMaxError']) {
                <div>{{ installDateColumnName }} can't occur in the future</div>
                }
            </div>
            }
        </div>
        @if (shipmentModel.customAttributs[shipmentModel.CustomAttributeType.trailer_type].value === shipmentModel.TrailerType.Soft) {
        <div class="form-group" @foldAnimation>
            <datetime-picker label="{{ removeDateColumnName }}" name="slot_dest" #slot_dest="ngModel"
                             [(ngModel)]="shipmentModel.customAttributs[shipmentModel.CustomAttributeType.slot_dest].value"
                             [isRequired]="true" [withTime]="true" requiredValidator dateRangeValidator dateRangeMin="startTime">
            </datetime-picker>
            @if (formShipment.submitted && slot_dest.invalid) {
            <div class="invalid-feedback" @foldAnimation>
                @if (slot_dest.errors?.['requiredValidator']) {
                <div>This field is required</div>
                }
                @if (slot_dest.errors?.['dateRangeError']) {
                <div>{{ removeDateColumnName }} must occur after {{ installDateColumnName }}</div>
                }
                @if (slot_dest.errors?.['dateMaxError']) {
                <div>{{ removeDateColumnName }} must occur in the future</div>
                }
            </div>
            }
        </div>
        }
        <div class="form-group devices-group">
            <select-list #devicesList class="devices" name="deviceId" #deviceId="ngModel" placeholder="{{ deviceIdColumnName }}"
                         [items]="shipmentModel.devices" [isRequired]="true" [(ngModel)]="shipmentModel.displayShipment.deviceId" bindLabel="value"
                         bindValue="id" [clearable]="false"
                         [disabled]="shipmentModel.customAttributs[shipmentModel.CustomAttributeType.trailer_type].value === shipmentModel.TrailerType.Soft"
                         (change)="shipmentModel.updateDeviceIdElements()">
            </select-list>
            <ng-container [ngTemplateOutlet]="barcodeButtonTemplate"></ng-container>
            @if (formShipment.submitted && deviceId.invalid) {
            <div class="invalid-feedback" @foldAnimation>This field is required</div>
            }
        </div>
        <div class="form-group">
            <select-list name="lock_method" #lock_method="ngModel" placeholder="Lock Method" [items]="shipmentModel.lockMethods"
                         [isRequired]="true" [(ngModel)]="shipmentModel.customAttributs[shipmentModel.CustomAttributeType.lock_method].value"
                         [disabled]="shipmentModel.customAttributs[shipmentModel.CustomAttributeType.trailer_type].value === shipmentModel.TrailerType.Soft"
                         bindLabel="value" bindValue="id" requiredValidator>
            </select-list>
            @if (formShipment.submitted && lock_method.invalid) {
            <div class="invalid-feedback" @foldAnimation>This field is required</div>
            }
        </div>
        @if (shipmentModel.displayShipment.deviceId === null) {
        <div class="form-group" @foldAnimation>
            <select-list name="deviceless_reason" #deviceless_reason="ngModel" placeholder="Deviceless Reason"
                         [items]="shipmentModel.shipmentLists.devicelessReasons" [isRequired]="true"
                         [(ngModel)]="shipmentModel.customAttributs[shipmentModel.CustomAttributeType.deviceless_reason].value"
                         bindLabel="value" bindValue="id" requiredValidator>
            </select-list>
            @if (formShipment.submitted && deviceless_reason.invalid) {
            <div class="invalid-feedback" @foldAnimation>This field is required</div>
            }
        </div>
        }
        <div class="form-group">
            <clearable-input name="truck_carrier" #truck_carrier="ngModel"
                             [(ngModel)]="shipmentModel.customAttributs[shipmentModel.CustomAttributeType.truck_carrier].value" label="Carrier (SCAC code)"
                             requiredValidator [isRequired]="true">
            </clearable-input>
            @if (formShipment.submitted && truck_carrier.invalid) {
            <div class="invalid-feedback" @foldAnimation>This field is required</div>
            }
        </div>
        <div class="section-title"
             [class.required]="shipmentModel.customAttributs[shipmentModel.CustomAttributeType.trailer_type].value !== shipmentModel.TrailerType.Soft">
            <span>Shipment Images</span>
        </div>
        <ng-container [ngTemplateOutlet]="attachmentsTemplate"
                      [ngTemplateOutletContext]="{customAttributeType: shipmentModel.CustomAttributeType.attachments_origin}">
        </ng-container>
    </ng-template>

    <!-- amazonShipmentLiveUSTemplate -->
    <ng-template #amazonShipmentLiveUSTemplate>
        <div class="section-title">User Details</div>
        <div class="form-group">
            <clearable-input name="alias_origin" #alias_origin="ngModel"
                             [(ngModel)]="shipmentModel.customAttributs[shipmentModel.CustomAttributeType.alias_origin].value"
                             requiredValidator [isRequired]="true" label="Alias">
            </clearable-input>
            @if (formShipment.submitted && alias_origin.invalid) {
            <div class="invalid-feedback" @foldAnimation>This field is required</div>
            }
        </div>
        <div class="section-title">Shipment Details</div>
        <div class="form-group">
            <select-list name="route" #route="ngModel" placeholder="Route" [items]="shipmentModel.routes" [isRequired]="true"
                         [(ngModel)]="shipmentModel.displayShipment.routeId" bindLabel="value" bindValue="id" requiredValidator>
            </select-list>
            @if (formShipment.submitted && route.invalid) {
            <div class="invalid-feedback" @foldAnimation>This field is required</div>
            }
        </div>
        <div class="form-group devices-group">
            <select-list #devicesList class="devices" name="deviceId" #deviceId="ngModel" placeholder="{{ deviceIdColumnName }}"
                         [items]="shipmentModel.devices" [isRequired]="true" [(ngModel)]="shipmentModel.displayShipment.deviceId" bindLabel="value"
                         bindValue="id" [clearable]="false">
            </select-list>
            <ng-container [ngTemplateOutlet]="barcodeButtonTemplate"></ng-container>
            @if (formShipment.submitted && deviceId.invalid) {
            <div class="invalid-feedback" @foldAnimation>This field is required</div>
            }
        </div>
        <div class="form-group">
            <clearable-input name="trailer_number" #trailer_number="ngModel" [(ngModel)]="shipmentModel.displayShipment.trailerNumber"
                             requiredValidator [isRequired]="true" label="Trailer Number">
            </clearable-input>
            @if (formShipment.submitted && trailer_number.invalid) {
            <div class="invalid-feedback" @foldAnimation>This field is required</div>
            }
        </div>
        @if (loginModel.userInfo.isAdministrator) {
        <div class="form-group">
            <datetime-picker label="{{ installDateColumnName }}" name="startTime" #startTime="ngModel" [(ngModel)]="shipmentModel.displayShipment.installDate"
                             [isRequired]="true" [withTime]="true" requiredValidator dateRangeValidator [isFutureDate]="false">
            </datetime-picker>
            @if (formShipment.submitted && startTime.invalid) {
            <div class="invalid-feedback" @foldAnimation>
                @if (startTime.errors?.['requiredValidator']) {
                <div>This field is required</div>
                }
                @if (startTime.errors?.['dateMaxError']) {
                <div>{{ installDateColumnName }} can't occur in the future</div>
                }
            </div>
            }
        </div>
        }
        <div class="section-title required">Shipment Images</div>
        <ng-container [ngTemplateOutlet]="attachmentsTemplate"
                      [ngTemplateOutletContext]="{customAttributeType: shipmentModel.CustomAttributeType.attachments_origin}">
        </ng-container>
        <div class="section-title">Shipment Optional Details</div>
        <div class="form-group">
            <clearable-input name="cargoid" #cargoidText #cargoid="ngModel" [(ngModel)]="shipmentModel.displayShipment.containerId"
                             [maxLength]="50" label="VRID">
            </clearable-input>
            @if (formShipment.submitted && cargoid.invalid) {
            <div class="invalid-feedback" @foldAnimation>This field is required</div>
            }
            <text-characters-counter [text]="shipmentModel.displayShipment.containerId" [maxLength]="cargoidText.maxLength"></text-characters-counter>
        </div>
        <div class="form-group">
            <clearable-input name="truck_carrier" #truck_carrier="ngModel"
                             [(ngModel)]="shipmentModel.customAttributs[shipmentModel.CustomAttributeType.truck_carrier].value" label="Carrier Name">
            </clearable-input>
        </div>
        <div class="form-group">
            <clearable-input name="sub_carrier" #sub_carrier="ngModel"
                             [(ngModel)]="shipmentModel.customAttributs[shipmentModel.CustomAttributeType.sub_carrier].value" label="Sub Carrier">
            </clearable-input>
        </div>
        <div class="form-group">
            <clearable-input name="driver_name" #driver_name="ngModel"
                             [(ngModel)]="shipmentModel.customAttributs[shipmentModel.CustomAttributeType.driver_name].value" label="Driver Name">
            </clearable-input>
        </div>
        <div class="form-group">
            <clearable-input name="truck_plate1" #truck_plate1="ngModel" [(ngModel)]="shipmentModel.customAttributs[shipmentModel.CustomAttributeType.truck_plate1].value"
                             label="Truck Plate">
            </clearable-input>
        </div>
        <div class="form-group">
            <clearable-input name="trailer_plate" #trailer_plate="ngModel" [(ngModel)]="shipmentModel.customAttributs[shipmentModel.CustomAttributeType.trailer_plate].value"
                             label="Trailer Plate">
            </clearable-input>
        </div>
        <div class="form-group">
            <clearable-input name="run_structure" #run_structure="ngModel" [(ngModel)]="shipmentModel.customAttributs[shipmentModel.CustomAttributeType.run_structure].value"
                             label="Run Structure">
            </clearable-input>
        </div>
        <div class="form-group">
            <textarea placeholder=" " name="review_origin" #review_originText #review="ngModel" class="form-control" autosize maxlength="220"
                      [(ngModel)]="shipmentModel.customAttributs[shipmentModel.CustomAttributeType.review_origin].value">
            </textarea>
            <label class="control-label">Review</label>
            <text-characters-counter [text]="shipmentModel.customAttributs[shipmentModel.CustomAttributeType.review_origin].value"
                                     [maxLength]="review_originText.maxLength">
            </text-characters-counter>
        </div>
    </ng-template>

    <!-- amazonShipmentLiveSummaryContentEUTemplate -->
    <ng-template #amazonShipmentLiveSummaryContentEUTemplate>
        <div class="section-title">User Details</div>
        <div class="form-group">
            <label class="attribute-label">Alias</label>
            <div class="attribute-value">{{ shipmentModel.displayCustomAttributs[shipmentModel.CustomAttributeType.alias_origin].value }}</div>
        </div>
        <div class="section-title summary">Shipment Details</div>
        <div class="form-group">
            <label class="attribute-label">VRID</label>
            <div class="attribute-value">{{ shipmentModel.displayShipment.containerId }}</div>
        </div>
        <div class="form-group">
            <label class="attribute-label">Trailer Type</label>
            <div class="attribute-value">{{ shipmentModel.displayCustomAttributs[shipmentModel.CustomAttributeType.trailer_type].value }}</div>
        </div>
        <div class="form-group">
            <label class="attribute-label">Route</label>
            <div class="attribute-value">{{ shipmentModel.displayShipment.routeDescription }}</div>
        </div>
        <div class="form-group">
            <label class="attribute-label">Truck Plate</label>
            <div class="attribute-value">{{ shipmentModel.displayCustomAttributs[shipmentModel.CustomAttributeType.truck_plate1].value }}</div>
        </div>
        <div class="form-group">
            <label class="attribute-label">Trailer Plate</label>
            <div class="attribute-value">{{ shipmentModel.displayCustomAttributs[shipmentModel.CustomAttributeType.trailer_plate].value }}</div>
        </div>
        <div class="form-group">
            <label class="attribute-label">{{ installDateColumnName }}</label>
            <div class="attribute-value">{{ shipmentModel.displayShipment.installDateFormatted }}{{ (appSettingsService.appSettings.isUsingUTCTime ? '&nbsp;UTC' : '') }}</div>
        </div>
        @if (shipmentModel.customAttributs[shipmentModel.CustomAttributeType.trailer_type].value !== shipmentModel.TrailerType.Soft) {
        <div class="form-group" @foldAnimation>
            <label class="attribute-label">{{ removeDateColumnName }}</label>
            <div class="attribute-value">{{ shipmentModel.displayCustomAttributs[shipmentModel.CustomAttributeType.slot_dest].value }}{{ (appSettingsService.appSettings.isUsingUTCTime ? '&nbsp;UTC' : '') }}</div>
        </div>
        }
        <div class="form-group">
            <label class="attribute-label">{{ deviceIdColumnName }}</label>
            <div class="attribute-value devices">{{ shipmentModel.displayShipment.deviceDescription }}</div>
        </div>
        <div class="form-group">
            <label class="attribute-label">Lock Method</label>
            <div class="attribute-value">{{ shipmentModel.displayCustomAttributs[shipmentModel.CustomAttributeType.lock_method].value }}</div>
        </div>
        <div class="form-group">
            <label class="attribute-label">Deviceless Reason</label>
            <div class="attribute-value">{{ shipmentModel.displayCustomAttributs[shipmentModel.CustomAttributeType.deviceless_reason].value }}</div>
        </div>
        <div class="form-group">
            <label class="attribute-label">Carrier (SCAC code)</label>
            <div class="attribute-value">{{ shipmentModel.displayCustomAttributs[shipmentModel.CustomAttributeType.truck_carrier].value }}</div>
        </div>
        <div class="section-title">Shipment Images</div>
        <div class="form-group">
            @if (!shipmentModel.isShipmentMissingFiles(shipmentModel.CustomAttributeType.attachments_origin)) {
            <div class="attachments-container"
                 @foldAnimation (wheel)="onAttachmetsWheel($event)" overlayScrollbar>
                <div class="attachments-content">
                    <ng-container [ngTemplateOutlet]="existingAttachmentsTemplate"
                                  [ngTemplateOutletContext]="{customAttributeType: shipmentModel.CustomAttributeType.attachments_origin,
                                  isViewOnly: true}">
                    </ng-container>
                </div>
            </div>
            }
        </div>
    </ng-template>

    <!-- amazonShipmentLiveSummaryContentUSTemplate -->
    <ng-template #amazonShipmentLiveSummaryContentUSTemplate>
        <div class="section-title">User Details</div>
        <div class="form-group">
            <label class="attribute-label">Alias</label>
            <div class="attribute-value">{{ shipmentModel.displayCustomAttributs[shipmentModel.CustomAttributeType.alias_origin].value }}</div>
        </div>
        <div class="section-title summary">Shipment Details</div>
        <div class="form-group">
            <label class="attribute-label">Route</label>
            <div class="attribute-value">{{ shipmentModel.displayShipment.routeDescription }}</div>
        </div>
        <div class="form-group">
            <label class="attribute-label">{{ deviceIdColumnName }}</label>
            <div class="attribute-value devices">{{ shipmentModel.displayShipment.deviceDescription }}</div>
        </div>
        <div class="form-group">
            <label class="attribute-label">Trailer Number</label>
            <div class="attribute-value">{{ shipmentModel.displayShipment.trailerNumber }}</div>
        </div>
        <div class="form-group">
            <label class="attribute-label">{{ installDateColumnName }}</label>
            <div class="attribute-value">{{ shipmentModel.displayShipment.installDateFormatted }}{{ (appSettingsService.appSettings.isUsingUTCTime ? '&nbsp;UTC' : '') }}</div>
        </div>
        <div class="section-title">Shipment Images</div>
        <div class="form-group">
            @if (!shipmentModel.isShipmentMissingFiles(shipmentModel.CustomAttributeType.attachments_origin)) {
            <div class="attachments-container"
                 @foldAnimation (wheel)="onAttachmetsWheel($event)" overlayScrollbar>
                <div class="attachments-content">
                    <ng-container [ngTemplateOutlet]="existingAttachmentsTemplate"
                                  [ngTemplateOutletContext]="{customAttributeType: shipmentModel.CustomAttributeType.attachments_origin,
                                  isViewOnly: true}">
                    </ng-container>
                </div>
            </div>
            }
        </div>
        <div class="section-title summary">Shipment Optional Details</div>
        <div class="form-group">
            <label class="attribute-label">VRID</label>
            <div class="attribute-value">{{ shipmentModel.displayShipment.containerId }}</div>
        </div>
        <div class="form-group">
            <label class="attribute-label">Carrier Name</label>
            <div class="attribute-value">{{ shipmentModel.displayCustomAttributs[shipmentModel.CustomAttributeType.truck_carrier].value }}</div>
        </div>
        <div class="form-group">
            <label class="attribute-label">Sub Carrier</label>
            <div class="attribute-value">{{ shipmentModel.displayCustomAttributs[shipmentModel.CustomAttributeType.sub_carrier].value }}</div>
        </div>
        <div class="form-group">
            <label class="attribute-label">Driver Name</label>
            <div class="attribute-value">{{ shipmentModel.displayCustomAttributs[shipmentModel.CustomAttributeType.driver_name].value }}</div>
        </div>
        <div class="form-group">
            <label class="attribute-label">Truck Plate</label>
            <div class="attribute-value">{{ shipmentModel.displayCustomAttributs[shipmentModel.CustomAttributeType.truck_plate1].value }}</div>
        </div>
        <div class="form-group">
            <label class="attribute-label">Trailer Plate</label>
            <div class="attribute-value">{{ shipmentModel.displayCustomAttributs[shipmentModel.CustomAttributeType.trailer_plate].value }}</div>
        </div>
        <div class="form-group">
            <label class="attribute-label">Run Structure</label>
            <div class="attribute-value">{{ shipmentModel.displayCustomAttributs[shipmentModel.CustomAttributeType.run_structure].value }}</div>
        </div>
        <div class="form-group">
            <label class="attribute-label">Review</label>
            <div class="attribute-value">{{ shipmentModel.displayCustomAttributs[shipmentModel.CustomAttributeType.review_origin].value }}</div>
        </div>
    </ng-template>

    <!-- amazonShipmentLiveSummaryEUTemplate -->
    <ng-template #amazonShipmentLiveSummaryEUTemplate>
        <div class="form-group">
            <div class="expander">
                <button type="button" #expanderButton aria-expanded="false" class="ripple-effect ripple-inverse-color"
                        (click)="expanderButton.ariaExpanded = expanderButton.ariaExpanded === 'true' ? 'false' : 'true'">
                    <span class="expander-title">
                        <i class="icon-list-ul"></i>Live Shipment Details
                        @if (!isEditingLiveDetails && shipmentModel.isAdministrator) {
                        <i @fadeOutAnimation class="icon-edit-alt"
                           (click)="onEditLiveContentClick($event, expanderButton)"></i>
                        }
                    </span>
                    <i class="icon-arrow-down-light"></i>
                </button>
                @if (expanderButton.ariaExpanded === 'true') {
                <div class="expander-content-container" @foldLongAnimation>
                    @if (!isEditingLiveDetails) {
                    <div class="expander-content view-only" @foldLongAnimation>
                        <ng-container [ngTemplateOutlet]="amazonShipmentLiveSummaryContentEUTemplate"></ng-container>
                    </div>
                    }
                    @if (isEditingLiveDetails) {
                    <div class="expander-content edit" @foldLongAnimation>
                        <ng-container [ngTemplateOutlet]="amazonShipmentLiveEUTemplate"></ng-container>
                    </div>
                    }
                </div>
                }
            </div>
        </div>
    </ng-template>

    <!-- amazonShipmentLiveSummaryUSTemplate -->
    <ng-template #amazonShipmentLiveSummaryUSTemplate>
        <div class="form-group">
            <div class="expander">
                <button type="button" #expanderButton aria-expanded="false" class="ripple-effect ripple-inverse-color"
                        (click)="expanderButton.ariaExpanded = expanderButton.ariaExpanded === 'true' ? 'false' : 'true'">
                    <span class="expander-title">
                        <i class="icon-list-ul"></i>Live Shipment Details
                        @if (!isEditingLiveDetails && shipmentModel.isAdministrator) {
                        <i @fadeOutAnimation class="icon-edit-alt"
                           (click)="onEditLiveContentClick($event, expanderButton)"></i>
                        }
                    </span>
                    <i class="icon-arrow-down-light"></i>
                </button>
                @if (expanderButton.ariaExpanded === 'true') {
                <div class="expander-content-container" @foldLongAnimation>
                    @if (!isEditingLiveDetails) {
                    <div class="expander-content view-only" @foldLongAnimation>
                        <ng-container [ngTemplateOutlet]="amazonShipmentLiveSummaryContentUSTemplate"></ng-container>
                    </div>
                    }
                    @if (isEditingLiveDetails) {
                    <div class="expander-content edit" @foldLongAnimation>
                        <ng-container [ngTemplateOutlet]="amazonShipmentLiveUSTemplate"></ng-container>
                    </div>
                    }
                </div>
                }
            </div>
        </div>
    </ng-template>

    <!-- amazonShipmentCompletedEUTemplate -->
    <ng-template #amazonShipmentCompletedEUTemplate>
        <ng-container [ngTemplateOutlet]="amazonShipmentLiveSummaryEUTemplate"></ng-container>
        <fieldset>
            <div class="section-title">User Details</div>
            <div class="form-group">
                <clearable-input name="alias_dest" #alias_dest="ngModel"
                                 [(ngModel)]="shipmentModel.customAttributs[shipmentModel.CustomAttributeType.alias_dest].value"
                                 requiredValidator [isRequired]="true" label="Alias">
                </clearable-input>
                @if (formShipment.submitted && alias_dest.invalid) {
                <div class="invalid-feedback" @foldAnimation>This field is required</div>
                }
            </div>
            <div class="section-title">Shipment Details</div>
            <div class="form-group">
                <datetime-picker label="Completed Date" name="endTime" #endTime="ngModel" [(ngModel)]="shipmentModel.displayShipment.removeDate"
                                 [isRequired]="true" [withTime]="true" requiredValidator dateRangeValidator
                                 [dateRangeMinValue]="shipmentModel.displayShipment.installDate" [isFutureDate]="false">
                </datetime-picker>
                @if (formShipment.submitted && endTime.invalid) {
                <div class="invalid-feedback" @foldAnimation>
                    @if (endTime.errors?.['requiredValidator']) {
                    <div>This field is required</div>
                    }
                    @if (endTime.errors?.['dateRangeError']) {
                    <div>Completed Date must occur after {{ installDateColumnName }}</div>
                    }
                    @if (endTime.errors?.['dateMaxError']) {
                    <div>Completed Date can't occur in the future</div>
                    }
                </div>
                }
            </div>
            <div class="form-group">
                <textarea placeholder=" " name="review" #reviewText #review="ngModel" class="form-control" requiredValidator autosize maxlength="220"
                          [(ngModel)]="shipmentModel.customAttributs[shipmentModel.CustomAttributeType.review].value">
              </textarea>
                <label class="control-label required">Review</label>
                @if (formShipment.submitted && review.invalid) {
                <div class="invalid-feedback" @foldAnimation>This field is required</div>
                }
                <text-characters-counter [text]="shipmentModel.customAttributs[shipmentModel.CustomAttributeType.review].value"
                                         [maxLength]="reviewText.maxLength">
                </text-characters-counter>
            </div>
            <div class="form-group flex">
                <label class="checkbox-container ripple-effect ripple-inverse-color">
                    <input name="reportAnomalies" #reportAnomalies="ngModel" type="checkbox"
                           [(ngModel)]="shipmentModel.customAttributs[shipmentModel.CustomAttributeType.report_anomalies].value">
                    <span class="checkbox-switch"></span>
                    Report Anomalies (<span class="anomalies-symbol">!</span>)
                </label>
            </div>
            <div class="section-title"
                 [class.required]="shipmentModel.customAttributs[shipmentModel.CustomAttributeType.trailer_type].value !== shipmentModel.TrailerType.Soft">
                <span>Shipment Images</span>
            </div>
            <ng-container [ngTemplateOutlet]="attachmentsTemplate"
                          [ngTemplateOutletContext]="{customAttributeType: shipmentModel.CustomAttributeType.attachments_dest}">
            </ng-container>
        </fieldset>
    </ng-template>

    <!-- amazonShipmentCompletedUSTemplate -->
    <ng-template #amazonShipmentCompletedUSTemplate>
        <ng-container [ngTemplateOutlet]="amazonShipmentLiveSummaryUSTemplate"></ng-container>
        <fieldset>
            <div class="section-title">User Details</div>
            <div class="form-group">
                <clearable-input name="alias_dest" #alias_dest="ngModel"
                                 [(ngModel)]="shipmentModel.customAttributs[shipmentModel.CustomAttributeType.alias_dest].value"
                                 requiredValidator [isRequired]="true" label="Alias">
                </clearable-input>
                @if (formShipment.submitted && alias_dest.invalid) {
                <div class="invalid-feedback" @foldAnimation>This field is required</div>
                }
            </div>
            <div class="section-title">Shipment Details</div>
            @if (loginModel.userInfo.isAdministrator) {
            <div class="form-group">
                <datetime-picker label="Completed Date" name="endTime" #endTime="ngModel" [(ngModel)]="shipmentModel.displayShipment.removeDate"
                                 [isRequired]="true" [withTime]="true" requiredValidator dateRangeValidator [isFutureDate]="false">
                </datetime-picker>
                @if (formShipment.submitted && endTime.invalid) {
                <div class="invalid-feedback" @foldAnimation>
                    @if (endTime.errors?.['requiredValidator']) {
                    <div>This field is required</div>
                    }
                    @if (endTime.errors?.['dateMaxError']) {
                    <div>Completed Date can't occur in the future</div>
                    }
                </div>
                }
            </div>
            }
            <div class="form-group">
                <textarea placeholder=" " name="review" #reviewText #review="ngModel" class="form-control" requiredValidator autosize maxlength="220"
                          [(ngModel)]="shipmentModel.customAttributs[shipmentModel.CustomAttributeType.review].value">
                </textarea>
                <label class="control-label required">Review</label>
                @if (formShipment.submitted && review.invalid) {
                <div class="invalid-feedback" @foldAnimation>This field is required</div>
                }
                <text-characters-counter [text]="shipmentModel.customAttributs[shipmentModel.CustomAttributeType.review].value"
                                         [maxLength]="reviewText.maxLength">
                </text-characters-counter>
            </div>
            <div class="form-group flex">
                <label class="checkbox-container ripple-effect ripple-inverse-color">
                    <input name="reportAnomalies" #reportAnomalies="ngModel" type="checkbox"
                           [(ngModel)]="shipmentModel.customAttributs[shipmentModel.CustomAttributeType.report_anomalies].value">
                    <span class="checkbox-switch"></span>
                    Report Anomalies (<span class="anomalies-symbol">!</span>)
                </label>
            </div>
            <div class="section-title required">Shipment Images</div>
            <ng-container [ngTemplateOutlet]="attachmentsTemplate"
                          [ngTemplateOutletContext]="{customAttributeType: shipmentModel.CustomAttributeType.attachments_dest}">
            </ng-container>
        </fieldset>
    </ng-template>

    <!-- amazonShipmentEUTemplate -->
    <ng-template #amazonShipmentEUTemplate>
        @if (shipmentModel.shipmentStatus === shipmentModel.ManagerItemStatus.Live ||
        shipmentModel.shipmentStatus === shipmentModel.ManagerItemStatus.Canceled &&
        shipmentModel.sourceShipmentStatus === shipmentModel.ManagerItemStatus.Live) {
        <div class="shipment-status-section-container" @fadeInOutAnimation>
            <div class="shipment-status-section">
                <ng-container [ngTemplateOutlet]="cancelTemplate"></ng-container>
                @if (shipmentModel.shipmentStatus !== shipmentModel.ManagerItemStatus.Canceled) {
                <fieldset class="animation-overflow" @foldAnimation>
                    <ng-container [ngTemplateOutlet]="amazonShipmentLiveEUTemplate"></ng-container>
                </fieldset>
                }
            </div>
        </div>
        }
        @if (shipmentModel.shipmentStatus === shipmentModel.ManagerItemStatus.Completed ||
        shipmentModel.shipmentStatus === shipmentModel.ManagerItemStatus.Canceled &&
        shipmentModel.sourceShipmentStatus === shipmentModel.ManagerItemStatus.Completed) {
        <div class="shipment-status-section-container" @fadeInOutAnimation>
            <div class="shipment-status-section">
                <ng-container [ngTemplateOutlet]="cancelTemplate"></ng-container>
                @if (shipmentModel.shipmentStatus !== shipmentModel.ManagerItemStatus.Canceled) {
                <div class="animation-overflow" @foldAnimation>
                    <ng-container [ngTemplateOutlet]="amazonShipmentCompletedEUTemplate"></ng-container>
                </div>
                }
            </div>
        </div>
        }
    </ng-template>

    <!-- amazonShipmentUSTemplate -->
    <ng-template #amazonShipmentUSTemplate>
        @if (shipmentModel.shipmentStatus === shipmentModel.ManagerItemStatus.Live ||
        shipmentModel.shipmentStatus === shipmentModel.ManagerItemStatus.Canceled &&
        shipmentModel.sourceShipmentStatus === shipmentModel.ManagerItemStatus.Live) {
        <div class="shipment-status-section-container" @fadeInOutAnimation>
            <div class="shipment-status-section">
                <ng-container [ngTemplateOutlet]="cancelTemplate"></ng-container>
                @if (shipmentModel.shipmentStatus !== shipmentModel.ManagerItemStatus.Canceled) {
                <fieldset class="animation-overflow" @foldAnimation>
                    <ng-container [ngTemplateOutlet]="amazonShipmentLiveUSTemplate"></ng-container>
                </fieldset>
                }
            </div>
        </div>
        }
        @if (shipmentModel.shipmentStatus === shipmentModel.ManagerItemStatus.Completed ||
        shipmentModel.shipmentStatus === shipmentModel.ManagerItemStatus.Canceled &&
        shipmentModel.sourceShipmentStatus === shipmentModel.ManagerItemStatus.Completed) {
        <div class="shipment-status-section-container" @fadeInOutAnimation>
            <div class="shipment-status-section">
                <ng-container [ngTemplateOutlet]="cancelTemplate"></ng-container>
                @if (shipmentModel.shipmentStatus !== shipmentModel.ManagerItemStatus.Canceled) {
                <div class="animation-overflow" @foldAnimation>
                    <ng-container [ngTemplateOutlet]="amazonShipmentCompletedUSTemplate"></ng-container>
                </div>
                }
            </div>
        </div>
        }
    </ng-template>

</form>

<porfolio-viewer [showAutoplay]="false"></porfolio-viewer>
}

