import { trigger, animate, transition, style, query, group, animateChild, AnimationMetadata, AnimationTriggerMetadata } from '@angular/animations';
import { AnimationsConstants } from './constant';

function getOutletTransitionAnimationMetadta(isForwardDirection: boolean): AnimationMetadata[]
{
    return [
        query(':enter',
            [
                style({ transform: isForwardDirection ? AnimationsConstants.SLIDE_RIGHT_STYLE : AnimationsConstants.SLIDE_LEFT_STYLE })
            ],
            { optional: true }
        ),
        query(':leave', animateChild(), { optional: true }),
        group(
            [
                query(':leave',
                    [
                        animate(AnimationsConstants.ANIMATION_LONG_TIMING, style({
                            transform: isForwardDirection ? AnimationsConstants.SLIDE_LEFT_STYLE :
                                AnimationsConstants.SLIDE_RIGHT_STYLE
                        }))
                    ],
                    { optional: true }),
                query(':enter',
                    [
                        animate(AnimationsConstants.ANIMATION_LONG_TIMING, style({ transform: AnimationsConstants.SLIDE_IN_STYLE }))
                    ],
                    { optional: true })
            ]),
        query(':enter', animateChild(), { optional: true })
    ];
}

export const outletAnimation: AnimationTriggerMetadata =

    trigger('outletAnimation',
        [
            transition(((fromState: string, toState: string) => fromState !== 'false' && parseInt(fromState) > parseInt(toState)),
                getOutletTransitionAnimationMetadta(true)),
            transition(((fromState: string, toState: string) => fromState !== 'false' && parseInt(fromState) < parseInt(toState)),
                getOutletTransitionAnimationMetadta(false)),
            transition('* => *',
                [
                    query(':enter',
                        [
                            style({ opacity: 0 }),
                            animate(AnimationsConstants.ANIMATION_LONG_TIMING, style({ opacity: 1 }))
                        ],
                        { optional: true }
                    )
                ])
        ]);
