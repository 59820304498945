import
{
    ChangeDetectionStrategy, ChangeDetectorRef, Component, EmbeddedViewRef, NgZone, Renderer2, ViewContainerRef
} from "@angular/core";
import { fadeInOutAnimation, fadeSlideDownInOutAnimation } from "../animations/fade.animation";
import { slideInOutAnimation } from "../animations/slide.animation";
import { Constants, IListColumn } from "../utils/globals";
import { foldAnimation, foldBothAnimation, foldHorizontalAnimation, foldVerticalStateAnimation } from "../animations/fold.animation";
import { MonitoringItemsBaseComponent } from "../base/components/monitoring-items-base.component";
import { DisplayEventMonitoring, EventsMonitoringColumnType, EventsMonitoringDataLists, EventsMonitoringFilters, EventsMonitoringInfo } from "./model/events-monitoring-model.class";
import { VirtualListInfo } from "../controls/virtual-list/model/virtual-list-info";
import { EventsMonitoringModel } from "./model/events-monitoring.model";
import { LoginModel } from "../user/login/model/login.model";
import { Router } from "@angular/router";
import { RoutingHistoryService } from "../services/routing-history.service";
import { AppSettingsService } from "../services/app-settings.service";
import { ModalMessageService } from "../controls/modal-message/services/modal-message.service";
import { CGICluster } from "../controls/google-map/utils/cgi-cluster";
import { MonitoringUtils, DisplayProperties } from "../utils/monitoring-utils";
import { ICGIMarker, ICGIMarkerLocation } from "../base/classes/cgi-marker";
import { CGIClusterBaseRenderer } from "../controls/google-map/utils/cgi-cluster-base-renderer";
import { Utils } from "../utils/utils";
import * as XLSX from 'xlsx';
import { CGIMarkerItemMonitoringIconFactory } from "../shipments-monitoring/shipment-monitoring/map/cgi-marker-item-monitoring-icon-factory";
import { CGIEventsClusterRenderer } from "../shipments-monitoring/shipment-monitoring/map/cgi-events-cluster-renderer";
import { GlobalsPipe } from "../pipes/globals.pipe";
import { NumericInputComponent } from "../controls/numeric-input/numeric-input.component";
import { DateTimePickerComponent } from "../controls/datetime-picker/datetime-picker.component";
import { SelectListComponent, SelectListLabelTemplateDirective, SelectListOptionTemplateDirective } from "../controls/select-list/select-list.component";
import { FormsModule } from "@angular/forms";
import { OverlayScrollbarDirective } from "../directives/overlay-scrollbar/overlay-scrollbar.directive";
import { LoaderComponent } from "../controls/loader/loader.component";
import { DefaultColumnComponent } from "../base/components/default-column.component";
import { TooltipDirective } from "../directives/tooltip.directive";
import { VirtualListComponent } from "../controls/virtual-list/virtual-list.component";
import { DropdownDirective } from "../directives/dropdown.directive";
import { GoogleMapComponent } from "../controls/google-map/google-map.component";
import { NgTemplateOutlet, NgClass } from "@angular/common";

@Component({
    selector: 'events-monitoring',
    templateUrl: './events-monitoring.component.html',
    styleUrls: ['../base/styles/monitoring-items-base.component.css', '../base/styles/monitoring-base.component.css',
        './events-monitoring.component.css', '../base/styles/manager-table.css'],
    animations: [fadeInOutAnimation, slideInOutAnimation, foldAnimation, foldBothAnimation, fadeSlideDownInOutAnimation, foldHorizontalAnimation,
        foldVerticalStateAnimation],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgTemplateOutlet, GoogleMapComponent, DropdownDirective, VirtualListComponent, NgClass, TooltipDirective, DefaultColumnComponent,
        LoaderComponent, OverlayScrollbarDirective, FormsModule, SelectListComponent, SelectListLabelTemplateDirective, SelectListOptionTemplateDirective,
        DateTimePickerComponent, NumericInputComponent, GlobalsPipe]
})
export class EventsMonitoringComponent extends MonitoringItemsBaseComponent<DisplayEventMonitoring, EventsMonitoringFilters, EventsMonitoringDataLists,
    EventsMonitoringInfo>
{
    // #region Protected Constants

    protected override readonly SHARING_MAP_TITLE: string = 'Events Monitoring';

    // #endregion

    // #region Private Members

    private static _completedEventsMonitoringTableListInfo: VirtualListInfo = new VirtualListInfo();
    private static _liveEventsMonitoringTableListInfo: VirtualListInfo = new VirtualListInfo();
    private static _collapseMonitoringItemsFilters: boolean = true;
    private static _collapseEventsMonitoringTable: boolean = true;
    private static _collapseEventsMonitoringMapSection: boolean = false;

    private _cgiMarkerItemMonitoringIconFactory: CGIMarkerItemMonitoringIconFactory = new CGIMarkerItemMonitoringIconFactory();

    private readonly _eventsIconsDisplayProperties: DisplayProperties[];

    // #endregion

    // #region Properties

    public get eventsIconsDisplayProperties(): DisplayProperties[]
    {
        return this._eventsIconsDisplayProperties;
    }

    public get EventsMonitoringColumnType()
    {
        return EventsMonitoringColumnType;
    }

    public get shipmentEventsStyleProperties(): DisplayProperties[]
    {
        return MonitoringUtils.SHIPMENT_EVENTS_DISPLAY_PROPERTIES;
    }

    public override get collapseMonitoringItemsFilters(): boolean
    {
        return EventsMonitoringComponent._collapseMonitoringItemsFilters;
    }

    public override set collapseMonitoringItemsFilters(value: boolean)
    {
        EventsMonitoringComponent._collapseMonitoringItemsFilters = value;
    }

    public get collapseEventsMonitoringTable(): boolean
    {
        return EventsMonitoringComponent._collapseEventsMonitoringTable;
    }

    public set collapseEventsMonitoringTable(value: boolean)
    {
        if (!value)
        {
            if (this.monitoringItemsModel.showCompletedMonitoringItems)
            {
                EventsMonitoringComponent._completedEventsMonitoringTableListInfo.scrollToItem = this.monitoringItemsModel.selectedMonitoringItem;
            }
            else
            {
                EventsMonitoringComponent._liveEventsMonitoringTableListInfo.scrollToItem = this.monitoringItemsModel.selectedMonitoringItem;
            }
        }

        EventsMonitoringComponent._collapseEventsMonitoringTable = value;
    }

    public get collapseEventsMonitoringMapSection(): boolean
    {
        return EventsMonitoringComponent._collapseEventsMonitoringMapSection;
    }

    public set collapseEventsMonitoringMapSection(value: boolean)
    {
        EventsMonitoringComponent._collapseEventsMonitoringMapSection = value;
    }

    public get eventsMonitoringTableListInfo(): VirtualListInfo
    {
        return this.monitoringItemsModel.showCompletedMonitoringItems ? EventsMonitoringComponent._completedEventsMonitoringTableListInfo :
            EventsMonitoringComponent._liveEventsMonitoringTableListInfo;
    }

    public override get monitoringItemsModel(): EventsMonitoringModel
    {
        return this._monitoringItemsModel as EventsMonitoringModel;
    }

    // #region Constructors

    constructor(_monitoringItemsModel: EventsMonitoringModel, _renderer: Renderer2, _appSettingsService: AppSettingsService,
        _changeDetectorRef: ChangeDetectorRef, _routingHistoryService: RoutingHistoryService, _ngZone: NgZone, _modalMessageService: ModalMessageService,
        _viewContainerRef: ViewContainerRef, _router: Router, _loginModel: LoginModel)
    {
        super(_monitoringItemsModel, _renderer, _appSettingsService, _changeDetectorRef, _routingHistoryService, _ngZone, _modalMessageService,
            _viewContainerRef, _router, _loginModel);

        const eventsIconsDisplayProperties: DisplayProperties[] = [];

        const uniqueSet = new Set();
        for (const displayProperties of MonitoringUtils.SHIPMENT_EVENTS_DISPLAY_PROPERTIES)
        {
            if (displayProperties.iconClassName !== '' && !uniqueSet.has(displayProperties.iconClassName))
            {
                uniqueSet.add(displayProperties.iconClassName);
                eventsIconsDisplayProperties.push(displayProperties);
            }
        }

        this._eventsIconsDisplayProperties = [...eventsIconsDisplayProperties];
    }

    // #endregion

    // #region Event Handlers

    public override ngOnInit(): void
    {
        this.initializeMonitoringItemsTableColumns();

        const isPopState: boolean = this._routingHistoryService.isPopState && this._monitoringItemsModel.isInitialized;

        super.ngOnInit();

        if (!isPopState)
        {
            EventsMonitoringComponent._liveEventsMonitoringTableListInfo = new VirtualListInfo();
            EventsMonitoringComponent._liveEventsMonitoringTableListInfo.sortColumn = EventsMonitoringColumnType.Started;
            EventsMonitoringComponent._liveEventsMonitoringTableListInfo.sortOrder = -1;

            EventsMonitoringComponent._collapseMonitoringItemsFilters = Constants.IS_MOBILE;
            EventsMonitoringComponent._collapseEventsMonitoringTable = false;

            EventsMonitoringComponent._completedEventsMonitoringTableListInfo = new VirtualListInfo();
            EventsMonitoringComponent._completedEventsMonitoringTableListInfo.sortColumn = EventsMonitoringColumnType.Started;
            EventsMonitoringComponent._completedEventsMonitoringTableListInfo.sortOrder = -1;
        }
    }

    public override onShipmentTypeChange(): void
    {
        super.onShipmentTypeChange();

        if (this._monitoringItemsVirtualList !== undefined)
        {
            this._monitoringItemsVirtualList.scrollTop = (this.monitoringItemsModel.showCompletedMonitoringItems ?
                EventsMonitoringComponent._completedEventsMonitoringTableListInfo.scrollTop :
                EventsMonitoringComponent._liveEventsMonitoringTableListInfo.scrollTop) ?? 0;
        }
    }

    public onFilterFieldDurationTypeChange(): void
    {
        if (this.monitoringItemsModel.editMonitoringFilters.selectedEventMonitoringDurationData.duration !== null)
        {
            this.onFilterFieldChange();
        }
    }

    public onExportToExcelButtonClick(): void
    {
        const exportData: any[] = this.monitoringItemsModel.filteredMonitoringItems.map((eventMonitoring: DisplayEventMonitoring) =>
        {
            const rowData: any = {};

            for (const shipmentColumn of this._tableMonitoringItemsColumns)
            {
                if (shipmentColumn.text !== undefined && shipmentColumn.propertyName !== undefined)
                {
                    rowData[Utils.removeTextHtmlTags(shipmentColumn.text)] = (eventMonitoring as any)[shipmentColumn.propertyName];
                }
            }

            rowData['Current Latitude'] = eventMonitoring.latitude;
            rowData['Current Longitude'] = eventMonitoring.longitude;

            return rowData;
        });

        const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(exportData);
        const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };

        XLSX.writeFile(workbook, Utils.getFileDateName(this.EXCEL_FILE_PREFIX, '.xlsx', new Date()), { compression: true });
    }

    public override async onShareSelectedMonitoringInfo(shareCopyMonitoringInfo: DisplayEventMonitoring, copyOnlyToClipboard: boolean): Promise<void>
    {
        this.collapseShareOptions();

        const shareText: string = `${this.SHARING_INFO_TITLE}\r\n${''
            }${this._loginModel.isAccountTypeAmazonUS ? 'Trailer Number' : this.containerIdColumnName}: ${this._loginModel.isAccountTypeAmazonUS ? shareCopyMonitoringInfo.shipment.trailerNumber : shareCopyMonitoringInfo.shipment.containerId} \r\n${''
            }Origin Route: ${shareCopyMonitoringInfo.shipment.originName}\r\n${''
            }Destination Route: ${shareCopyMonitoringInfo.shipment.destinationName}\r\n${''
            }Event Type: ${shareCopyMonitoringInfo.eventDesc}\r\n${''
            }Start Time: ${this._appSettingsService.appSettings.isUsingUTCTime ? ' (UTC)' : ''}: ${this.getFormattedDateTime(shareCopyMonitoringInfo.fromDate)}\r\n${''
            }Start Coordinates: ${shareCopyMonitoringInfo.latitude}\xb0, ${shareCopyMonitoringInfo.longitude}\xb0\r\n${''
            }Duration: ${shareCopyMonitoringInfo.durationFormatted}\r\n${''
            }${shareCopyMonitoringInfo.value !== null ? `Value: ${shareCopyMonitoringInfo.value}${shareCopyMonitoringInfo.units} \r\n` : ''}${''
            }${shareCopyMonitoringInfo.minThreshold !== null || shareCopyMonitoringInfo.maxThreshold !== null ? `Threshold: ${shareCopyMonitoringInfo.minThreshold !== null ? `${shareCopyMonitoringInfo.minThreshold}${shareCopyMonitoringInfo.units}` : ''}${shareCopyMonitoringInfo.minThreshold !== null && shareCopyMonitoringInfo.maxThreshold !== null ? ' to ' : ''}${shareCopyMonitoringInfo.maxThreshold !== null ? `${shareCopyMonitoringInfo.maxThreshold}${shareCopyMonitoringInfo.units}\r\n` : ''}` : ''}${''
            }${shareCopyMonitoringInfo.timeBasedMinThreshold !== null || shareCopyMonitoringInfo.timeBasedMaxThreshold !== null ? `Threshold±: ${shareCopyMonitoringInfo.timeBasedMinThreshold !== null ? `${shareCopyMonitoringInfo.timeBasedMinThreshold}${shareCopyMonitoringInfo.units}` : ''}${shareCopyMonitoringInfo.timeBasedMinThreshold !== null && shareCopyMonitoringInfo.timeBasedMaxThreshold !== null ? ' to ' : ''}${shareCopyMonitoringInfo.timeBasedMaxThreshold !== null ? `${shareCopyMonitoringInfo.timeBasedMaxThreshold}${shareCopyMonitoringInfo.units}\r\n` : ''}` : ''}${''
            }${this.deviceIdColumnName}: ${shareCopyMonitoringInfo.shipment.deviceDescription}\r\n${''
            }CG-ID: ${shareCopyMonitoringInfo.shipmentKey}`

        await this.shareInfo(this.SHARING_INFO_TITLE, shareText, copyOnlyToClipboard);
    }

    // #endregion

    // #region Protected Methods

    protected override buildClusterInfowWindow(showClusterMarkersSummary: boolean, cluster: CGICluster, isCollapsable: boolean): void
    {
        let embeddedViewRef: EmbeddedViewRef<any>;

        if (showClusterMarkersSummary)
        {
            let eventsOccurrences: number[] = Array(MonitoringUtils.SHIPMENT_EVENT_TYPES_COUNT).fill(0);

            for (const marker of cluster.markers)
            {
                const displayEventMonitoring: DisplayEventMonitoring = this.getMarkerData(marker) as DisplayEventMonitoring;
                eventsOccurrences[displayEventMonitoring.eventType] = eventsOccurrences[displayEventMonitoring.eventType] + 1;
            }

            embeddedViewRef = this._viewContainerRef.createEmbeddedView(this._infoWindowMarkerClusterTemplateRef,
                { $implicit: eventsOccurrences });
        }
        else
        {
            let maxInfoWindowExtraRows: number = 0;
            for (const marker of cluster.markers)
            {
                const displayEventMonitoring: DisplayEventMonitoring = this.getMarkerData(marker) as DisplayEventMonitoring;
                displayEventMonitoring.infoWindowExtraRows = [];
                if (displayEventMonitoring.value !== null)
                {
                    displayEventMonitoring.infoWindowExtraRows.push(0);
                }

                if (displayEventMonitoring.minThreshold !== null || displayEventMonitoring.maxThreshold !== null)
                {
                    displayEventMonitoring.infoWindowExtraRows.push(0);
                }

                if (displayEventMonitoring.timeBasedMinThreshold !== null || displayEventMonitoring.timeBasedMaxThreshold !== null)
                {
                    displayEventMonitoring.infoWindowExtraRows.push(0);
                }

                if (displayEventMonitoring.infoWindowExtraRows.length > maxInfoWindowExtraRows)
                {
                    maxInfoWindowExtraRows = displayEventMonitoring.infoWindowExtraRows.length;
                }
            }

            for (const marker of cluster.markers)
            {
                const displayEventMonitoring: DisplayEventMonitoring = this.getMarkerData(marker) as DisplayEventMonitoring;
                displayEventMonitoring.infoWindowExtraRows = Array(maxInfoWindowExtraRows - displayEventMonitoring.infoWindowExtraRows.length);
            }

            embeddedViewRef = this._viewContainerRef.createEmbeddedView(this._infoWindowMarkerTemplateRef,
                {
                    info: cluster.markers.map((marker: google.maps.marker.AdvancedMarkerElement | ICGIMarkerLocation) =>
                        (this.getMarkerData(marker) as DisplayEventMonitoring)).sort((a: DisplayEventMonitoring, b: DisplayEventMonitoring) =>
                            (a.fromDate !== null ? a.fromDate.getTime() : 0) - (b.fromDate !== null ? b.fromDate.getTime() : 0)),
                    isCollapsable: isCollapsable
                });
        }

        this.openMarkerPopup(cluster.marker!, embeddedViewRef.rootNodes[0], showClusterMarkersSummary);
    }

    protected override getClusterRenderer(): CGIClusterBaseRenderer
    {
        return new CGIEventsClusterRenderer();
    }

    protected override ctreateGoogleMapMarker(eventMonitoring: DisplayEventMonitoring): ICGIMarker<DisplayEventMonitoring>
    {
        const eventColor: string =
            this._bodyCssStyleDeclaration.getPropertyValue(MonitoringUtils.SHIPMENT_EVENTS_DISPLAY_PROPERTIES[eventMonitoring.eventType].color);

        const marker: ICGIMarker<DisplayEventMonitoring> = new google.maps.marker.AdvancedMarkerElement({
            position: { lat: eventMonitoring.latitude!, lng: eventMonitoring.longitude! },
            content: this._cgiMarkerItemMonitoringIconFactory.createCGIMarkerEventElement(eventColor,
                MonitoringUtils.SHIPMENT_EVENTS_DISPLAY_PROPERTIES[eventMonitoring.eventType].iconClassName),
            map: null
        }) as ICGIMarker<DisplayEventMonitoring>;

        marker.data = eventMonitoring;

        this.addMarkerClickEvent(marker);

        return marker;
    }

    protected override initializeMonitoringItemsTableColumns(): void
    {
        const displayEventMonitoring: DisplayEventMonitoring = new DisplayEventMonitoring();

        const tableMonitoringItemsColumns: IListColumn[] =
            [
                {
                    columnType: EventsMonitoringColumnType.ContainerId,
                    text: this._loginModel.isAccountTypeAmazonUS ? 'Trailer No.' : this.containerIdColumnName,
                    classes: 'col-container-id',
                    propertyName: Utils.getPropertyNameof<DisplayEventMonitoring>(displayEventMonitoring, displayEventMonitoring =>
                        displayEventMonitoring.containerId)
                },
                {
                    columnType: EventsMonitoringColumnType.RouteDescription,
                    text: 'Route',
                    classes: 'col-route',
                    propertyName: Utils.getPropertyNameof<DisplayEventMonitoring>(displayEventMonitoring, displayEventMonitoring =>
                        displayEventMonitoring.routeDescription)
                },
                {
                    columnType: EventsMonitoringColumnType.Type,
                    text: 'Type',
                    classes: 'col-event-type',
                    propertyName: Utils.getPropertyNameof<DisplayEventMonitoring>(displayEventMonitoring, displayEventMonitoring =>
                        displayEventMonitoring.eventDesc)
                },
                {
                    columnType: EventsMonitoringColumnType.Started,
                    text: `Started<span class="small-units">${this._appSettingsService.appSettings.isUsingUTCTime ? ' (UTC)' : ''}</span>`,
                    propertyName: Utils.getPropertyNameof<DisplayEventMonitoring>(displayEventMonitoring, displayEventMonitoring =>
                        displayEventMonitoring.fromDateFormatted)
                },
                {
                    columnType: EventsMonitoringColumnType.Duration,
                    text: 'Duration',
                    propertyName: Utils.getPropertyNameof<DisplayEventMonitoring>(displayEventMonitoring, displayEventMonitoring =>
                        displayEventMonitoring.durationFormatted),
                    sortPropertyName: Utils.getPropertyNameof<DisplayEventMonitoring>(displayEventMonitoring, displayEventMonitoring =>
                        displayEventMonitoring.duration)
                }];

        if (!this.monitoringItemsModel.isDemo)
        {
            tableMonitoringItemsColumns.push(
                {
                    columnType: EventsMonitoringColumnType.Location,
                    text: 'Location',
                    classes: 'col-location',
                    propertyName: Utils.getPropertyNameof<DisplayEventMonitoring>(displayEventMonitoring, displayEventMonitoring =>
                        displayEventMonitoring.eventLocation)
                });
        }

        tableMonitoringItemsColumns.push(...
            [
                {
                    columnType: EventsMonitoringColumnType.ShipmentKey,
                    text: 'CG-ID',
                    classes: 'col-shipment-key',
                    propertyName: Utils.getPropertyNameof<DisplayEventMonitoring>(displayEventMonitoring, displayEventMonitoring =>
                        displayEventMonitoring.shipmentKey)
                },
                {
                    columnType: EventsMonitoringColumnType.Copy,
                    classes: 'col-button center',
                    isFixedWidth: true
                },
                {
                    columnType: EventsMonitoringColumnType.Share,
                    classes: 'col-button center',
                    isFixedWidth: true
                },
                {
                    columnType: EventsMonitoringColumnType.Track,
                    classes: 'col-button center',
                    isFixedWidth: true
                }
            ]);

        this._tableMonitoringItemsColumns = [...tableMonitoringItemsColumns];
    }

    // #endregion
}
