import { Type } from "@angular/core";
import { Routes } from "@angular/router";
import { cloneDeep } from "lodash";
import { Observable, Observer } from "rxjs";
import { Constants } from "./globals";
import { AnimationsConstants } from "../animations/constant";
import { default as moment } from "moment";

export enum EasingType { InOut, In, Out, Linear }

export enum DateTimeFormatType { DateTime, DateTimeGMT, Date, DateShort, Time, DateYear, DateDayMonth, DateDayMonthShort }

export enum DurationUnitType { Second, Minute, Hour, Day }

export class Utils
{
    // #region Private Members

    private static _dateTimeFormatsMap: Map<DateTimeFormatType, Intl.DateTimeFormat> = new Map();
    private static _numberFormat: Intl.NumberFormat | null = null;
    private static _numberRoundedFormat: Intl.NumberFormat | null = null;
    private static _isUsingUTCTime: boolean = false;
    private static _isUsingTime24Hours: boolean = false;
    private static _localeId: string = 'en-US';
    private static _currencySymbol: string | null = null;

    // #endregion

    // #region Public Methods

    public static initializeDateTimeFormat(localeId: string, isUsingUTCTime: boolean, isUsingTime24Hours: boolean): void
    {
        this._localeId = localeId;
        moment.locale(localeId.split('-')[0]);
        this._isUsingUTCTime = isUsingUTCTime;
        this._isUsingTime24Hours = isUsingTime24Hours;
        this._dateTimeFormatsMap.clear();
        this._numberFormat = null;
        this._numberRoundedFormat = null;
        this._currencySymbol = null;
    }

    public static getItemNestedPropertyValue(item: any, propertyName: string): any
    {
        const properties: string[] = propertyName.split('.');
        let value: any = item;
        for (const property of properties)
        {
            value = value[property];
            if (this.isNullOrUndefined(value))
            {
                break;
            }
        }

        return value;
    }

    public static convertDates(object: Record<string, any>)
    {
        if (Utils.isNullOrUndefined(object) || !(object instanceof Object))
        {
            return;
        }

        if (object instanceof Array)
        {
            for (const item of object)
            {
                this.convertDates(item);
            }
        }

        for (const propertyName of Object.getOwnPropertyNames(object))
        {
            const value: any = object[propertyName];
            if (value instanceof Array)
            {
                for (const item of value)
                {
                    this.convertDates(item);
                }
            }

            if (value instanceof Object)
            {
                this.convertDates(value);
            }

            if (typeof value === 'string' && /^(\d{4})-(\d{2})-(\d{2})T(\d{2}):(\d{2}):(\d{2}(?:\.\d*)?)/.test(value))
            {
                object[propertyName] = new Date(value);
            }
        }
    }

    public static removeTextHtmlTags(text: string): string
    {
        return text.replace(/(<([^>]+)>)/ig, "");
    }

    public static getEnumParsedDescription(enumType: any, enumId: any): string
    {
        if (!(enumType[enumId]))
        {
            return '';
        }

        const enumWords: string[] = enumType[enumId].split(/(?=[A-Z])/);

        let enumDescription: string = '';
        for (let i: number = 0; i < enumWords.length; i++)
        {
            enumDescription += `${i > 0 && (enumWords[i - 1].length > 1 || enumWords[i].length > 1) ? ' ' : ''}${enumWords[i]}`;
        }

        return enumDescription;
    }

    public static createProxyOnSetNotifier<T extends object>(onSetObject: T, onSet: (target: any, propertyKey: string, value: any) => void): T
    {
        return new Proxy(onSetObject,
            {
                set(target: any, propertyKey: string, value: any, receiver: any): boolean
                {
                    const result: boolean = Reflect.set(target, propertyKey, value, receiver);
                    onSet(target, propertyKey, value);
                    return result;
                }
            });
    }

    public static clearDateOnlyTimeValue(date: Date): void
    {
        date.setHours(this._isUsingUTCTime ? -date.getTimezoneOffset() / 60 : 0, 0, 0, 0);
    }

    public static getMoment(momentInput?: moment.MomentInput, format?: moment.MomentFormatSpecification, language?: string, strict?: boolean): moment.Moment
    {
        const targetMoment: moment.MomentInput = moment(momentInput, format, language ?? this._localeId, strict);
        return this._isUsingUTCTime ? targetMoment.utc() : targetMoment;
    }

    public static ReflowElement(element: HTMLElement): void
    {
        // trigger a DOM reflow 
        element.scrollBy(0, 0);
    }

    public static isNullOrUndefined(value: any): boolean
    {
        return value === null || value === undefined;
    }

    public static isNullOrEmpty(value: string | null | undefined): boolean
    {
        return value === null || value === undefined || value.toString().trim().length === 0;
    }

    public static getFileDateName(namePrefix: string, fileExtention: string, fileDate: Date): string
    {
        return `${namePrefix}${fileDate.getFullYear().toString()}${fileDate.getMonth().toString().padStart(2, '0')}${''
            }${fileDate.getDay().toString().padStart(2, '0')}_${fileDate.getHours().toString().padStart(2, '0')}${''
            }${fileDate.getMinutes().toString().padStart(2, '0')}${fileDate.getSeconds().toString().padStart(2, '0')}${''
            }${fileDate.getMilliseconds().toString().padStart(2, '3')}${fileExtention.length > 0 ? `.${fileExtention}` : ''}`;
    }

    public static getCurrencySymbol(): string | null
    {
        if (this._currencySymbol === null)
        {
            const currencyFormatPart: Intl.NumberFormatPart | undefined = new Intl.NumberFormat(this._localeId,
                { style: 'currency', currency: 'ILS' }).formatToParts().find(part => part.type === 'currency');

            if (currencyFormatPart === undefined)
            {
                return null;
            }

            this._currencySymbol = currencyFormatPart.value;
        }

        return this._currencySymbol;
    }

    public static getFormattedNumber(value: number, isRounded = false): string
    {
        if (isRounded && this._numberRoundedFormat === null)
        {
            this._numberRoundedFormat = new Intl.NumberFormat(this._localeId, { notation: "compact", maximumFractionDigits: 0 });
        }
        else if (!isRounded && this._numberFormat === null)
        {
            this._numberFormat = new Intl.NumberFormat(this._localeId, { notation: "compact", maximumFractionDigits: 2 });
        }

        return isRounded ? this._numberRoundedFormat!.format(value) : this._numberFormat!.format(value);
    }

    public static getFormattedDateTime(date: Date | null, dateTimeType: DateTimeFormatType, addUTC: boolean = false): string
    {
        if (date === null)
        {
            return '';
        }

        const dateTimeFormatOptions: Intl.DateTimeFormatOptions = {};
        dateTimeFormatOptions.timeZone = this._isUsingUTCTime && (dateTimeType === DateTimeFormatType.DateTime ||
            dateTimeType === DateTimeFormatType.DateTimeGMT || dateTimeType === DateTimeFormatType.Time) ? 'Greenwich' : undefined;

        if (dateTimeType === DateTimeFormatType.DateYear)
        {
            dateTimeFormatOptions.year = 'numeric';
        }
        else if (dateTimeType === DateTimeFormatType.DateDayMonth)
        {
            dateTimeFormatOptions.weekday = 'short';
            dateTimeFormatOptions.month = 'short';
            dateTimeFormatOptions.day = '2-digit';
        }
        else if (dateTimeType === DateTimeFormatType.DateDayMonthShort)
        {
            dateTimeFormatOptions.month = 'short';
            dateTimeFormatOptions.day = '2-digit';
        }
        else
        {
            if (dateTimeType !== DateTimeFormatType.Time)
            {
                dateTimeFormatOptions.year = 'numeric';
                dateTimeFormatOptions.month = 'short';
                dateTimeFormatOptions.day = '2-digit';
            }

            if (dateTimeType !== DateTimeFormatType.Date && dateTimeType !== DateTimeFormatType.DateShort)
            {
                dateTimeFormatOptions.hour = '2-digit';
                dateTimeFormatOptions.minute = '2-digit';
                dateTimeFormatOptions.hourCycle = this._isUsingTime24Hours ? 'h23' : 'h12';
            }

            if (dateTimeType === DateTimeFormatType.DateShort)
            {
                dateTimeFormatOptions.day = '2-digit';
                dateTimeFormatOptions.month = '2-digit';
                dateTimeFormatOptions.year = '2-digit';
            }

            if (dateTimeType === DateTimeFormatType.DateTimeGMT)
            {
                dateTimeFormatOptions.timeZoneName = 'shortOffset';
            }
        }

        let dateTimeFormat: Intl.DateTimeFormat | undefined = this._dateTimeFormatsMap.get(dateTimeType);
        if (dateTimeFormat === undefined)
        {
            dateTimeFormat = new Intl.DateTimeFormat(this._localeId, dateTimeFormatOptions);
            this._dateTimeFormatsMap.set(dateTimeType, dateTimeFormat);
        }

        return `${dateTimeFormat.format(date)}${addUTC && this._isUsingUTCTime ? ' UTC' : ''}`;
    }

    public static formatDurationUnits(formattedDuration: string | number | null, durationUnits: string): string
    {
        if (formattedDuration === null)
        {
            return Constants.EMPTY_FIELD_VALUE;
        }

        const formattedDurationValue = formattedDuration.toString();

        return `${formattedDurationValue} ${durationUnits}${formattedDurationValue.length > 1 || formattedDurationValue.charAt(0) !== '1' ? 's' : ''}`;
    }

    public static formatDuration(fromDate: Date, toDate: Date, isRounded = false): string | null
    {
        const durationSteps: number[] = [60, 60, 24]
        let durationUnit: DurationUnitType = DurationUnitType.Second;
        let duration: number = Math.max(1, moment(toDate).diff(moment(fromDate), 'seconds', true));

        for (const durationStep of durationSteps)
        {
            if (duration >= durationStep)
            {
                duration /= durationStep;
                durationUnit++;
            }
            else
            {
                break;
            }
        }

        const formattedDuration: string = this.getFormattedNumber(duration, isRounded);
        if (formattedDuration === '0')
        {
            return null;
        }

        return this.formatDurationUnits(formattedDuration, DurationUnitType[durationUnit]);
    }

    public static copyObjectByTargetProperties(source: any, target: any): any
    {
        return this.copyObjectProperties(source, target, false);
    }

    public static copyObjectBySourceProperties(source: any, target: any): any
    {
        return this.copyObjectProperties(source, target, true);
    }

    public static clearValueEmptyBooleansFromTarget(sourceValue: any, targetValue: any): any
    {
        if (Utils.isNullOrUndefined(sourceValue) || Utils.isNullOrUndefined(targetValue))
        {
            return sourceValue;
        }

        if (typeof (sourceValue) === 'boolean')
        {
            return sourceValue !== targetValue && !sourceValue && targetValue === null ? targetValue : sourceValue;
        }
        else if (Object.prototype.toString.call(sourceValue) === '[object Object]')
        {
            return this.clearObjectEmptyBooleansFromTarget(sourceValue, targetValue);
        }

        return sourceValue;
    }

    public static clearObjectEmptyBooleansFromTarget(source: any, target: any): any
    {
        if (source === null)
        {
            return null;
        }

        for (const propertyName of Object.getOwnPropertyNames(source))
        {
            const sourceValue: any = source[propertyName];
            const targetValue: any = target[propertyName];
            if (Array.isArray(sourceValue))
            {
                for (let i: number = 0; i < sourceValue.length; i++)
                {
                    sourceValue[i] = this.clearValueEmptyBooleansFromTarget(sourceValue[i], targetValue[i]);
                }
            }
            else
            {
                source[propertyName] = this.clearValueEmptyBooleansFromTarget(sourceValue, targetValue);
            }
        }

        return source;
    }

    public static clearValueEmptyStrings(value: any): any
    {
        if (typeof (value) === 'string')
        {
            return this.isNullOrEmpty(value) ? null : value.trim();
        }
        else if (Object.prototype.toString.call(value) === '[object Object]')
        {
            return this.clearObjectEmptyStrings(value);
        }

        return value;
    }

    public static clearObjectEmptyStrings(source: any): any
    {
        if (source === null)
        {
            return null;
        }

        for (const propertyName of Object.getOwnPropertyNames(source))
        {
            const value: any = source[propertyName];
            if (Array.isArray(value))
            {
                for (let i: number = 0; i < value.length; i++)
                {
                    value[i] = this.clearValueEmptyStrings(value[i]);
                }
            }
            else
            {
                source[propertyName] = this.clearValueEmptyStrings(value);
            }
        }

        return source;
    }

    public static getPropertyNameof<T>(source: T, expression: (x: { [Property in keyof T]: () => string }) => () => string): string
    {
        const result: { [Property in keyof T]: () => string } = {} as { [Property in keyof T]: () => string };

        Object.keys(source as any).map(key => result[key as keyof T] = () => key);

        return expression(result)();
    }

    public static scrollElementsBounderiesIntoView(elements: HTMLElement[], immidiate: boolean = false, scrollOffset: number = 0, isVertical: boolean = true): void
    {
        if (elements.length === 0)
        {
            return;
        }

        const scrollableContainerElement: HTMLElement = this.getParentElementScrollableContainer(elements[0].parentElement);

        const scrollPropertyName: string = isVertical ? 'scrollTop' : 'scrollLeft';
        const rectPropertyName: string = isVertical ? 'top' : 'left';
        const rectOppositePropertyName: string = isVertical ? 'bottom' : 'right';
        const scrollableContainerElementRect: DOMRect = scrollableContainerElement.getBoundingClientRect();
        const scrollableContainerElementRectEdge: number = (scrollableContainerElementRect as any)[rectPropertyName] + scrollOffset;

        let elementsRects: DOMRect[] = [];
        for (const element of elements)
        {
            elementsRects.push(element.getBoundingClientRect());
        }

        elementsRects = [...elementsRects.sort((a: DOMRect, b: DOMRect) => (a as any)[rectPropertyName] - (b as any)[rectPropertyName])];

        let targetEdge: number = 0;
        if ((elementsRects[elementsRects.length - 1] as any)[rectOppositePropertyName] + Constants.DROPDOWN_SHADOW_SIZE >
            (scrollableContainerElementRect as any)[rectOppositePropertyName])
        {
            targetEdge = Math.min((scrollableContainerElement as any)[scrollPropertyName] +
                (elementsRects[elementsRects.length - 1] as any)[rectOppositePropertyName] -
                (scrollableContainerElementRect as any)[rectOppositePropertyName] +
                Constants.DROPDOWN_SHADOW_SIZE, (elementsRects[0] as any)[rectPropertyName] - scrollableContainerElementRectEdge +
                (scrollableContainerElement as any)[scrollPropertyName] - Constants.DROPDOWN_SHADOW_SIZE);
        }
        else if ((elementsRects[0] as any)[rectPropertyName] - Constants.DROPDOWN_SHADOW_SIZE < scrollableContainerElementRectEdge)
        {
            targetEdge = (scrollableContainerElement as any)[scrollPropertyName] + (elementsRects[0] as any)[rectPropertyName] -
                scrollableContainerElementRectEdge - Constants.DROPDOWN_SHADOW_SIZE;
        }
        else if ((elementsRects[0] as any)[rectOppositePropertyName] + Constants.DROPDOWN_SHADOW_SIZE >
            (scrollableContainerElementRect as any)[rectOppositePropertyName])
        {
            targetEdge = (elementsRects[0] as any)[rectOppositePropertyName] -
                (scrollableContainerElementRect as any)[rectOppositePropertyName] - Constants.DROPDOWN_SHADOW_SIZE;
        }
        else
        {
            return;
        }

        if (immidiate)
        {
            (scrollableContainerElement as any)[scrollPropertyName] = targetEdge;
        }
        else
        {
            this.smoothTransition((scrollableContainerElement as any)[scrollPropertyName], targetEdge,
                AnimationsConstants.SMOOTH_SCROLL_DURATION).subscribe((position: number) =>
            {
                    (scrollableContainerElement as any)[scrollPropertyName] = position;
            });
        }
    }

    public static getParentElementScrollableContainer(element: HTMLElement | null): HTMLElement
    {
        if (element == null)
        {
            return document.body;
        }

        const elementSSStyleDeclaration: CSSStyleDeclaration = getComputedStyle(element);
        if (elementSSStyleDeclaration.overflowX.startsWith('auto') || elementSSStyleDeclaration.overflowY.startsWith('auto'))
        {
            return element;
        }
        else
        {
            return this.getParentElementScrollableContainer(element.parentElement);
        }
    }

    public static getChildElementScrollableContainer(element: HTMLElement | null): HTMLElement | null
    {
        if (element == null)
        {
            return null;
        }

        const elementSSStyleDeclaration: CSSStyleDeclaration = getComputedStyle(element);
        if (elementSSStyleDeclaration.overflow.startsWith('auto'))
        {
            return element;
        }
        else
        {
            return this.getChildElementScrollableContainer(element.firstElementChild as HTMLElement);
        }
    }

    public static scrollToInvalidFormElement(isSetFocus: boolean = false): void
    {
        requestAnimationFrame(() =>
        {
            const firstInvalidElement: HTMLElement | null = document.querySelector(Constants.INVALID_FEEDBACK_SELECTOR);
            if (firstInvalidElement === null)
            {
                return;
            }

            const invalidformGroupElement: HTMLElement | null = firstInvalidElement.closest(Constants.FORM_GROUP_SELECTOR);
            if (invalidformGroupElement === null)
            {
                return;
            }

            var invalidControlScrollToElement: HTMLElement | null = invalidformGroupElement.querySelector('label');
            const invalidControlElement: HTMLElement | null = invalidformGroupElement.querySelector('textarea, input, button');
            if (invalidControlElement === null)
            {
                return;
            }

            if (isSetFocus)
            {
                invalidControlElement.focus({ preventScroll: true });
            }

            if (invalidControlScrollToElement === null)
            {
                invalidControlScrollToElement = invalidControlElement;
            }

            this.scrollElementsBounderiesIntoView([invalidControlScrollToElement, invalidformGroupElement, firstInvalidElement]);
        });
    }

    public static formatPhoneNumbers(text: string | null): string | null
    {
        if (text !== null && text.length > 0)
        {
            var regex = /(?:0(?!5)(?:2|3|4|8|9))(?:-?\d){7}|(0(?=5)(?:-?\d){9})(?![^<]*>|[^<>]*<\/)/g;
            return text.replace(regex, "<a href=\"tel:$&\" dir='ltr'>$&</a>");
        }

        return text;
    }

    public static easeInQuad(elapsed: number, initialValue: number, amountOfChange: number, duration: number): number
    {
        const percent: number = elapsed / duration;

        return initialValue + amountOfChange * percent * percent;
    }

    public static easeOutQuad(elapsed: number, initialValue: number, amountOfChange: number, duration: number): number
    {
        const percent: number = elapsed / duration;

        return initialValue + amountOfChange * (1 - (1 - percent) * (1 - percent));
    }

    public static easeInOutQuad(elapsed: number, initialValue: number, amountOfChange: number, duration: number): number
    {
        const percent: number = elapsed / duration;

        return initialValue + amountOfChange * (percent < 0.5 ? 2 * percent * percent : 1 - Math.pow(-2 * percent + 2, 2) / 2);
    }

    public static smoothTransition(fromValue: number, toValue: number, duration: number, easingType: EasingType = EasingType.InOut): Observable<number>
    {
        return new Observable((observer: Observer<number>) =>
        {
            let startTime: number | null = null;

            const stepScrollAnimation = (timestamp: number) =>
            {
                startTime = startTime === null ? timestamp : startTime;

                const elapsed: number = timestamp - startTime;
                if (elapsed < duration)
                {
                    let currentValue: number;

                    switch (easingType)
                    {
                        case EasingType.InOut:
                            {
                                currentValue = this.easeInOutQuad(elapsed, fromValue, toValue - fromValue, duration);
                            }
                            break;

                        case EasingType.In:
                            {
                                currentValue = this.easeInQuad(elapsed, fromValue, toValue - fromValue, duration);
                            }
                            break;

                        case EasingType.Out:
                            {
                                currentValue = this.easeOutQuad(elapsed, fromValue, toValue - fromValue, duration);
                            }
                            break;

                        case EasingType.Linear:
                            {
                                currentValue = fromValue + (toValue - fromValue) * (elapsed / duration);
                            }
                            break;
                    }

                    observer.next(currentValue);

                    requestAnimationFrame(stepScrollAnimation);
                }
                else
                {
                    observer.next(toValue);
                    observer.complete();
                }
            };

            requestAnimationFrame(stepScrollAnimation);
        });
    }

    public static updateCanvasContextFont(canvasRenderingContext2D: CanvasRenderingContext2D): void
    {
        canvasRenderingContext2D.textAlign = 'start';
        canvasRenderingContext2D.textBaseline = 'alphabetic';

        const cssStyleDeclaration: CSSStyleDeclaration = getComputedStyle(document.body);
        canvasRenderingContext2D.font = `${cssStyleDeclaration.fontStyle} ${cssStyleDeclaration.fontVariant} ${cssStyleDeclaration.fontWeight} \
			${cssStyleDeclaration.fontSize} ${cssStyleDeclaration.fontFamily}`;
    }

    public static getAbsoluteUrl(baseUrl: string, urlPart: string): string
    {
        const absoluteUrlParts: string[] = baseUrl.split("/");
        const urlParts: string[] = urlPart.startsWith("/") ? urlPart.substring(1).split("/") : urlPart.split("/");

        absoluteUrlParts.pop();

        for (const urlPart of urlParts)
        {
            if (urlPart === ".")
            {
                continue;
            }
            if (urlPart === "..")
            {
                absoluteUrlParts.pop();
            }
            else
            {
                absoluteUrlParts.push(urlPart);
            }
        }

        return absoluteUrlParts.join("/");
    }

    public static preloadImages(imagesSources: string[] = []): Promise<void>
    {
        return new Promise<void>((resolve) =>
        {
            if (imagesSources.length === 0)
            {
                resolve();
                return;
            }

            let timeoutHandleId: NodeJS.Timeout | null = null;

            timeoutHandleId = setTimeout(() =>
            {
                timeoutHandleId = null;
                resolve();
            }, Constants.LOADING_ELEMENTS_TIMEOUT_DURATION);

            //document.body.querySelectorAll('img').forEach((imageElement: HTMLImageElement) =>
            //{
            //    if (!imagesSources.includes(imageElement.src))
            //    {
            //        imagesSources.push(imageElement.src);
            //    }
            //});

            //if (imagesSources.length === 0)
            //{
            //    clearTimeout(timeoutHandleId);
            //    timeoutHandleId = null;

            //    resolve();
            //    return;
            //}

            let loadIndex: number = 0;
            const loadCount: number = imagesSources.length;
            const images: HTMLImageElement[] = [];

            for (let i: number = 0; i < imagesSources.length; i++)
            {
                if (timeoutHandleId === null)
                {
                    break;
                }

                const image: HTMLImageElement = new Image();
                image.onload = () =>
                {
                    if (timeoutHandleId !== null)
                    {
                        if (++loadIndex === loadCount)
                        {
                            clearTimeout(timeoutHandleId);
                            timeoutHandleId = null;

                            resolve();
                        }
                    }
                };

                image.src = imagesSources[i];
                images.push(image);
            }
        });
    }

    // #endregion

    // #region Private Methods

    private static copyObjectProperties(source: any, target: any, copyFromSource: boolean): any
    {
        if (source === null || target === null)
        {
            return null;
        }

        for (const propertyName of Object.getOwnPropertyNames(copyFromSource ? source : target))
        {
            if (source.hasOwnProperty(propertyName))
            {
                target[propertyName] = cloneDeep(source[propertyName]);
            }
        }

        return target;
    }

    // #endregion
}