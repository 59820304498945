import { Directive, Input } from "@angular/core";
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from "@angular/forms";
import { Utils } from "../utils/utils";

@Directive({
    selector: '[requiredValidator][ngModel]',
    providers: [{ provide: NG_VALIDATORS, useExisting: RequiredValidatorDirective, multi: true }],
    standalone: true
})
export class RequiredValidatorDirective implements Validator
{
    // #region Inputs

    @Input("useRequiredValidator") useRequiredValidator: boolean = true;

    // #endregion

    // #region Validator Interface

    public validate(control: AbstractControl): ValidationErrors | null
    {
        return this.useRequiredValidator && Utils.isNullOrEmpty(control.value) ? { 'requiredValidator': true } : null;
	}

    // #endregion
}

