import { Directive, ElementRef, Input, NgZone, OnDestroy, OnInit, Renderer2 } from '@angular/core';
import { ElementResizedDirective, ResizedEvent } from '../element-resized.directive';
import { OverlayScrollbar, ScrollbarType } from './overlay-scrollbar';
import { Constants } from '../../utils/globals';

@Directive({
    selector: '[overlayScrollbar]',
    standalone: true
})
export class OverlayScrollbarDirective extends ElementResizedDirective implements OnInit, OnDestroy
{
    // #region Constants

    private readonly OVERLAY_SCROLLBAR_ROOT_CLASSNAME: string = 'overlay-scrollbar-root';
    private readonly OVERLAY_SCROLLBAR_CONTAINER_CLASSNAME: string = 'overlay-scrollbar-container';

    // #endregion

    // #region Private Members

    private _verticalOverlayScrollbar: OverlayScrollbar | null = null;
    private _horizontalOverlayScrollbar: OverlayScrollbar | null = null;

    private _disposeScrollbarScrollHandler: (() => void) | null = null;

    // #endregion

    // #region Constructors

    constructor(_elementRef: ElementRef<HTMLElement>, protected _renderer: Renderer2, _ngZone: NgZone)
    {
        super(_elementRef, _ngZone);

        this.disableResizeEvents = Constants.IS_MOBILE;
    }

    // #endregion

    // #region Event Handlers

    public ngOnInit(): void
    {
        if (Constants.IS_MOBILE)
        {
            return;
        }

        const cssStyleDeclaration: CSSStyleDeclaration = getComputedStyle(this.resizeElement);
        if (cssStyleDeclaration.position === 'static')
        {
            this._elementRef.nativeElement.style.position = 'relative';
        }

        if (cssStyleDeclaration.overflowY !== 'auto' && cssStyleDeclaration.overflowX !== 'auto')
        {
            return;
        }

        this.resizeElement.classList.add(this.OVERLAY_SCROLLBAR_CONTAINER_CLASSNAME);

        const scrollbarsRootElement: HTMLElement = this._renderer.createElement('div');
        scrollbarsRootElement.className = this.OVERLAY_SCROLLBAR_ROOT_CLASSNAME;

        this._elementRef.nativeElement.insertBefore(scrollbarsRootElement, this._elementRef.nativeElement.firstChild);

        if (cssStyleDeclaration.overflowY === 'auto')
        {
            this._verticalOverlayScrollbar = new OverlayScrollbar(this.resizeElement, scrollbarsRootElement, ScrollbarType.Vertical,
                this._renderer, this._ngZone);
        }

        if (cssStyleDeclaration.overflowX === 'auto')
        {
            this._horizontalOverlayScrollbar = new OverlayScrollbar(this.resizeElement, scrollbarsRootElement, ScrollbarType.Horizontal,
                this._renderer, this._ngZone);
        }

        this._ngZone.runOutsideAngular(() =>
        {
            this._disposeScrollbarScrollHandler = this._renderer.listen(this.resizeElement, 'scroll', this.onScrollbarScroll.bind(this));
        });
    }

    public override ngOnDestroy(): void
    {
        super.ngOnDestroy();

        this._verticalOverlayScrollbar?.clear();
        this._horizontalOverlayScrollbar?.clear();

        if (this._disposeScrollbarScrollHandler !== null)
        {
            this._disposeScrollbarScrollHandler();
            this._disposeScrollbarScrollHandler = null;
        }
    }

    private onScrollbarScroll(): void
    {
        requestAnimationFrame(() =>
        {
            if (this._verticalOverlayScrollbar !== null)
            {
                this._verticalOverlayScrollbar.updateScrollbarPosition();
            }

            if (this._horizontalOverlayScrollbar !== null)
            {
                this._horizontalOverlayScrollbar.updateScrollbarPosition();
            }
        });
    }

    // #endregion

    // #region Protected Methods

    protected override resize(event: ResizedEvent): void
    {
        if (this._verticalOverlayScrollbar !== null)
        {
            requestAnimationFrame(() =>
            {
                this.disableResizeEvents = true;

                this._verticalOverlayScrollbar?.updateScrollbarSize();
                this._verticalOverlayScrollbar?.updateScrollbarPosition();

                this.disableResizeEvents = false;
            });
        }

        if (this._horizontalOverlayScrollbar !== null)
        {
            requestAnimationFrame(() =>
            {
                this.disableResizeEvents = true;

                this._horizontalOverlayScrollbar?.updateScrollbarSize();
                this._horizontalOverlayScrollbar?.updateScrollbarPosition();

                this.disableResizeEvents = false;
            });
        }

        super.resize(event);
    }

    // #endregion
}