import { AfterViewInit, Directive, ElementRef, EventEmitter, Input, NgZone, OnDestroy, Output } from '@angular/core';

export interface ResizedEvent
{
    isFontResized: boolean;
    traget: HTMLElement;
}

@Directive({
    selector: '[resized]',
    standalone: true
})
export class ElementResizedDirective implements AfterViewInit, OnDestroy
{
    // #region Private Constants

    private readonly RESIZE_CHECK_INTERVAL: number = 300;

    // #endregion

    // #region Private Members

    private _resizeObservers: ResizeObserver[] = [];
    private _mutationObserver: MutationObserver | null = null;
    private _checkElementResizedIntervalHandle: NodeJS.Timeout | null = null;
    private _elementResized: boolean = false;
    private _fontSize: number = 0;
    private _contentHorizontalSize: number = 0;
    private _contentVerticalSize: number = 0;

    // #endregion

    // #region Properties

    public resizeElement: HTMLElement;

    // #endregion

    // #region Inputs

    @Input() public delayResizeEvents: boolean = false;
    @Input() public disableResizeEvents: boolean = false;

    // #endregion

    // #region Constructors
    constructor(protected readonly _elementRef: ElementRef<HTMLElement>, protected readonly _ngZone: NgZone)
    {
        this.resizeElement = this._elementRef.nativeElement;
    }

    // #endregion

    // #region Events

    @Output() public readonly resized: EventEmitter<ResizedEvent> = new EventEmitter<ResizedEvent>();

    // #endregion

    // #region Event Handlers

    public ngAfterViewInit(): void
    {
        if (this.disableResizeEvents)
        {
            return;
        }

        const resizeObserver: ResizeObserver = new ResizeObserver(() => this._ngZone.runOutsideAngular(() => this.onElementResize()));
        this._resizeObservers.push(resizeObserver);
        resizeObserver.observe(this.resizeElement);

        for (let i: number = 0; i < this.resizeElement.children.length; i++)
        {
            const resizeObserver: ResizeObserver = new ResizeObserver(() => this._ngZone.runOutsideAngular(() => this.onElementResize()));
            this._resizeObservers.push(resizeObserver);
            resizeObserver.observe(this.resizeElement.children[i]);
        }

        this._mutationObserver = new MutationObserver(() =>
        {
            setTimeout(() =>
            {
                if (this.isElementResized())
                {
                    this.onElementResize();
                }
            }, this.RESIZE_CHECK_INTERVAL);
        });

        this._mutationObserver.observe(this.resizeElement, { attributes: false, childList: true, subtree: true, characterData: false });

        const cssStyleDeclaration: CSSStyleDeclaration = getComputedStyle(this.resizeElement);
        this._fontSize = parseFloat(cssStyleDeclaration.fontSize);
    }

    public ngOnDestroy(): void
    {
        for (const resizeObserver of this._resizeObservers)
        {
            resizeObserver.disconnect();
        }

        if (this._mutationObserver !== null)
        {
            this._mutationObserver.disconnect();
        }

        this.clearCheckElementResizedInterval();
    }

    // #endregion

    // #region Protected Methods

    protected resize(event: ResizedEvent): void
    {
        this.resized.emit(event);
    }

    protected onElementResize(): void
    {
        if (this.disableResizeEvents)
        {
            return;
        }

        const event: ResizedEvent = { traget: this.resizeElement, isFontResized: false };

        const cssStyleDeclaration: CSSStyleDeclaration = getComputedStyle(document.body);
        const updateFontSize: number = parseFloat(cssStyleDeclaration.fontSize);
        if (this._fontSize != updateFontSize)
        {
            this._fontSize = updateFontSize;
            event.isFontResized = true;
            requestAnimationFrame(() => this.resize(event));
            return;
        }

        if (!this.delayResizeEvents)
        {
            this.resize(event);
            return;
        }

        this._elementResized = true;

        if (this._checkElementResizedIntervalHandle !== null)
        {
            return;
        }

        this._elementResized = false;

        this.resize(event);

        this._checkElementResizedIntervalHandle = setInterval(() =>
        {
            this.resize(event);

            if (this._elementResized)
            {
                this._elementResized = false;
            }
            else 
            {
                this.clearCheckElementResizedInterval();
            }

        }, this.RESIZE_CHECK_INTERVAL);
    }

    // #endregion

    // #region Private Methods

    private isElementResized(): boolean
    {
        let isElementResized: boolean = false;
        if (this._contentVerticalSize !== this.resizeElement.scrollHeight)
        {
            this._contentVerticalSize = this.resizeElement.scrollHeight;
            isElementResized = true;
        }

        if (this._contentHorizontalSize !== this.resizeElement.scrollWidth)
        {
            this._contentHorizontalSize = this.resizeElement.scrollWidth;
            isElementResized = true;
        }

        return isElementResized;
    }

    private clearCheckElementResizedInterval(): void
    {
        if (this._checkElementResizedIntervalHandle !== null)
        {
            clearInterval(this._checkElementResizedIntervalHandle);
            this._checkElementResizedIntervalHandle = null;
        }
    }

    // #endregion
}
