import { Directive } from "@angular/core";
import { AbstractControl, NG_VALIDATORS, ValidationErrors, Validator } from "@angular/forms";
import { Utils } from "../utils/utils";

@Directive({
    selector: '[passwordValidator][ngModel]',
    providers: [{ provide: NG_VALIDATORS, useExisting: PasswordValidatorDirective, multi: true }],
    standalone: true
})
export class PasswordValidatorDirective implements Validator
{
    // #region Validator Interface

    public validate(control: AbstractControl): ValidationErrors | null
    {
        if (Utils.isNullOrEmpty(control.value))
        {
            return null;
        }

        return /(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9])(?=.{8,})/.test(control.value) ? null : { 'passwordValidator': true };
    }

    // #endregion
}

